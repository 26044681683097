import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Loader from "react-loader-spinner";
import { Popup } from "semantic-ui-react";

class LikesDislikesChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      series: [],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: [window.clubCustomization.primary_color, "#666666"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#fafafa", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ isFetching: true });
    axios
      .get(
        `fans/likes_dislikes_chart?year=${this.props.year}&id_network=${this.props.id_network}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.values,
          isFetching: false,
        }));
      });
  };
  render() {
    return (
      <div className="card card-custom">
        <div className="dashboard-box-container">
          <div className="dashboard-box-title">
            Page likes changes monthly
            <Popup
              trigger={<div className="info-button">!</div>}
              content={
                <div>
                  The number of new people who have liked/disliked your page,
                  grouped by month
                </div>
              }
              position="right center"
              size="small"
              basic
            />
          </div>
          {!this.state.isFetching ? (
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height={350}
            />
          ) : (
            <div className="loader-container" style={{ height: 350 }}>
              <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={60} width={60} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LikesDislikesChart;
