import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import styles from "./Press.module.scss";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import { usePress } from "./PressContext";

function ArticlePopup({ languagePack }) {
  const dialogRef = useRef(null);
  const {
    onClickNextArticle,
    onClickPreviousArticle,
    articleToOpen,
    onCloseArticle,
    onClickAuthor,
    onClickSource,
  } = usePress();

  useEffect(() => {
    if (dialogRef.current)
      dialogRef.current.scrollIntoView({ behavior: "smooth" });
  }, [articleToOpen]);

  const { renderBtn: renderBtnRight } = useRenderButton(
    onClickNextArticle,
    "lightGrey",
    "arrow-right",
    languagePack.next_article,
    {},
    true,
    false,
    false,
    "black",
    false,
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    onClickPreviousArticle,
    "lightGrey",
    "arrow-left",
    languagePack.previous_article,
    {},
    false,
    false,
    false,
    "black",
    true,
  );
  const { renderBtn: renderBtnCenter } = useRenderButton(
    () => {
      window.open(articleToOpen.link, "blank");
    },
    "green",
    "newspaper",
    languagePack.see_article,
    {},
    true,
    false,
    false,
    "white",
    false,
  );

  return (
    <TFBDialog
      open={true}
      title={articleToOpen.title}
      closePopup={onCloseArticle}
      paddingX={8}
      btnCenter={renderBtnCenter}
      maxWidth="md"
      fullWidth={true}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      btnLeft={renderBtnLeft}
      btnRight={renderBtnRight}
    >
      <div ref={dialogRef} className={styles.articleDetails}>
        <div>
          {articleToOpen?.date} {articleToOpen?.author?.name && " | "}{" "}
          {articleToOpen?.author?.name?.replace(/&amp;/g, "&") && (
            <span
              onClick={(e) =>
                onClickAuthor(
                  e,
                  articleToOpen?.author?.name.replace(/&amp;/g, "&")
                )
              }
              className={styles.author}
            >
              {articleToOpen?.author?.name.replace(/&amp;/g, "&")}
            </span>
          )}{" "}
          |{" "}
          <span
            onClick={(e) => onClickSource(e, articleToOpen?.source)}
            className={styles.source}
          >
            {articleToOpen?.source}
          </span>
        </div>
        <img src={articleToOpen.source_logo} alt="source" width={100} />
      </div>
      <img
        src={articleToOpen?.image}
        alt="cover"
        style={{
          height: "300px",
          objectFit: "cover",
          marginBottom: "30px",
        }}
      />
      <div
        dangerouslySetInnerHTML={{ __html: articleToOpen.content }}
        className={styles.articleContent}
      ></div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ArticlePopup);
