import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ProgressBar } from "react-bootstrap";

import { showNotification } from "../../components/customHooks/useAxiosPost";
import TFBInput from "../../components/design-system/Input/TFBInput";
import TFBFileInput from "../../components/design-system/FileInput/TFBFileInput";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../components/customHooks/useRenderButton";


const AddVideo = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, token } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            video_name: "",
            video_description: "",
            file: [],

            file_loading: false,
            upload_percent: 0,
        }
    );

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addVideo(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        state.file_loading || _.size(state.file) == 0 || _.size(state.video_name) == 0,
    );

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.video_operation,
                languagePack.video_upload_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.video_operation,
                languagePack.video_upload_failed
            );
        }
    };

    const addVideo = () => {
        const formData = new FormData();
        formData.append("video", state.file[0]);
        formData.append("file_name", state.video_name);
        formData.append("id_club", currentUser.id_club);
        formData.append("uploaded_by", currentUser.nickname);
        formData.append("category", 'video');
        formData.append("extra_notes", state.video_description);

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                if (percent < 100) {
                    updateState({ upload_percent: percent })
                }
            },
            params: {
                token: token,
            },
            headers: {
                "content-type": "multipart/form-data",
            },
        };

        updateState({ file_loading: true })

        axios
            .post("upload/upload_video_to_digital_ocean_2", formData, options)
            .then((res) => {
                handleResponse(res.data);
            })
            .catch((e) => console.log(e));
    }

    return (
        <>
            {!state.file_loading ?
                <TFBDialog
                    className="pop-up-dialog-box prevent-select-text"
                    title={languagePack["add_video"]}
                    open={openPopup}
                    closePopup={closePopup}
                    btnLeft={BtnLeft}
                    btnRight={BtnRight}
                >
                    <TFBInput
                        label={languagePack.video_title}
                        value={state.video_name}
                        onChange={(e) => updateState({ video_name: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBFileInput
                        label={languagePack["upload_video"]}
                        files={Array.from(state.file).map(elem => elem.name)}
                        onChange={(e) => updateState({ file: e.target.files })}
                        style={{ marginBottom: 20 }}
                        accept="video/mp4"
                    />
                    <TFBInput
                        label={languagePack.video_description}
                        value={state.video_description}
                        multiline
                        onChange={(e) => updateState({ video_description: e.target.value })}
                        style={{ width: "100%" }}
                    />
                </TFBDialog> :
                <div className="video-loader" >
                    <div className="loader-container">
                        <Loader
                            type="TailSpin"
                            color={window.clubCustomization.primary_color}
                            height={50} width={50}
                        />
                        <div className="loading-message">
                            {state.file_loading > 0 &&
                                <ProgressBar
                                    style={{ width: "100%", margin: "15px 0px" }}
                                    now={state.upload_percent}
                                    active="true"
                                    label={`${state.upload_percent}%`}
                                />
                            }
                            {"The video is uploading!"}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(AddVideo);
