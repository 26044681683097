import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import NewsBox from "./NewsBox";
import TFBSearch from "../../../components/design-system/TFBSearch/TFBSearch";
import styles from "./Press.module.scss";
import CircularLoader from "../../../components/reusable/Loader";
import ArticlePopup from "./ArticlePopup";
import { usePress } from "./PressContext";

function Press({ languagePack }) {
  const {
    articleList,
    query,
    onSearch,
    loadingArticles,
    openArticleDialog,
    filteredArticleList,
  } = usePress();

  return (
    <TFBCard>
      <TFBCardHeader title="Stiri">
        <TFBSearch
          showNoResults={false}
          minCharacters={3}
          value={query}
          onSearchChange={onSearch}
          style={{ width: "400px" }}
          placeholder={languagePack.type_keyword}
        />
      </TFBCardHeader>
      <TFBCardBody className={styles.dialogBody}>
        {loadingArticles && (
          <div style={{ gridColumn: "span 2" }}>
            {" "}
            <CircularLoader />
          </div>
        )}
        {_.map(
          _.size(query) > 2 ? filteredArticleList : articleList,
          (article, i) => (
            <NewsBox key={i} article={article} />
          )
        )}
        {openArticleDialog && <ArticlePopup />}
      </TFBCardBody>
    </TFBCard>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(Press);
