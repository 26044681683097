import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../../utils/api";
import moment from "moment";
import { bindActionCreators } from "redux";
import "./../Financial.scss";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TFBTableCell,
  sxStickyColumn,
  sxStickyRow,
} from "../../../components/reusable/useStyles";
import styles from "./../../Club/PlayerProfile/PlayerProfile.module.scss"
import { convertAmountCurrency, formatAmountCurrency } from "../../../utils/utilFunctions";
import _ from "lodash";

class PaymentsToClub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      seasons: [],
      id_season: "",
      current_season: "",
      table_data: [],
      team_name: "",
      open_add_certificate_popup: false,
      add_row: "",
      expandedImage: false,
      expandedImageSrc: "",

      year: "All",
      payment_years: [],
      months: [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie",
      ],
    };
  }

  componentDidMount = () => {
    this.getYears();
    this.getPlayersPayments();
  };

  componentDidUpdate = (prevProps, _prevState) => {
    if (prevProps.languagePack != this.props.languagePack) {
      this.getYears();
    }
  };

  getYears = () => {
    axios
      .get(
        `payment/get_player_payments_years?id_player=${this.props.id_player}`
      )
      .then((res) => {
        let allOption = res.data[0];
        let { key: allKey, text: allText } = allOption;
        const paymentYears = [
          {
            key: allKey,
            value: allText,
            text: this.props.languagePack["simple_all"],
          },
          ...res.data.slice(1),
        ];
        this.setState({
          payment_years: paymentYears,
        });
      })
      .catch((err) => console.log(err));
  };

  getPlayersPayments = () => {
    axios
      .get(
        `payment/get_player_payments_2?id_player=${this.props.id_player}&year=${this.state.year == "All" ? "Toate" : this.state.year
        }`
      )
      .then((res) => {
        this.setState({
          table_data: res.data.list,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getPlayersPayments);
  };

  render() {
    const { languagePack, currentUser, currencyRates } = this.props;

    return (
      <TFBCard style={{ height: "100%" }}>
        <TFBCardHeader title={languagePack.payment_history}>
          <TFBDropdown
            placeholder={languagePack.evaluation_year}
            selection
            options={this.state.payment_years}
            name="year"
            color="gray"
            value={this.props.languagePack[this.state.year] || this.state.year}
            onChange={this.handleChangeDropdown}
          />
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.table_data.length > 0 ? (
            <>
              <TableContainer style={{ maxHeight: "70vh" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow sx={sxStickyRow}>
                      <TFBTableCell sx={[sxStickyColumn, sxStickyRow]}>
                        {languagePack.transactions}
                      </TFBTableCell>
                      <TFBTableCell sx={sxStickyRow} align="center">
                        {languagePack.transaction_type}
                      </TFBTableCell>
                      <TFBTableCell sx={sxStickyRow} align="center">
                        {languagePack.transaction_date}
                      </TFBTableCell>
                      <TFBTableCell sx={sxStickyRow} align="center">
                        {languagePack.category_cost_sum}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.table_data.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell
                          sx={sxStickyColumn}
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                          }}
                        >
                          {row.about_payment}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.payment_types[row.payment_type_label]}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {moment(row.date_add).format("DD.MM.YYYY")}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.payment_amount + " RON"}
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer>
                <Table className={styles.table}>
                  <TableBody>
                    <TableRow
                      key={"section-all"}
                      className={styles.totalRow}
                    >
                      <TFBTableCell className={styles.tableCellTotal}>{languagePack.total}</TFBTableCell>
                      <TFBTableCell className={`${styles.tableCellTotal} ${styles.tableCellTotalDuration}`} align="right">{formatAmountCurrency(_.sum(_.map(this.state.table_data, e => _.toNumber(convertAmountCurrency(e.payment_amount, "RON", currentUser.currency, currencyRates)))), currentUser.currency, currentUser.currency, currencyRates)}</TFBTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <TFBPlaceholder text={languagePack.no_player_transactions} />
          )}
        </TFBCardBody>
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
  }),
  mapDispatchToProps
)(PaymentsToClub);
