import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

import "./Teams.scss";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../../components/reusable/useStyles";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBTeamLabel from "../../../../components/design-system/TFBTeamLabel/TFBTeamLabel";

import EditTeam from "./EditTeam";
import { baseUrl, isTFB } from "../../../../utils/utilFunctions";

function ActiveTeams(props) {
  const [fetchFinished, setFetchFinished] = useState(props.teamsListFetched);
  const [teams, setTeams] = useState(props.teamsList);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [editingTeam, setEditingTeam] = useState({});
  const [openEditingTeam, setOpenEditingTeam] = useState(false);

  const { languagePack } = props;
  const history = useHistory();

  useEffect(() => {
    setTeams(props.teamsList);
  }, [props.teamsList]);

  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = () => {
    axios
      .get(`club/get_teams_2?id_club=${props.id_club}&token=${props.token}`)
      .then((res) => {
        setTeams(Array.isArray(res.data) ? res.data : []);
        setFetchFinished(true);
      })
      .catch((err) => console.log(err));
  };

  const handleChangeStartDate = (date) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setEndDate(date);
  };

  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const exportInactivePlayers = () => {
    let payload = {
      id_club: props.id_club,
      token: props.token,
    };

    if (startDate) {
      payload.start_date = moment(startDate).format("YYYY-MM-DD");
    }

    if (endDate) {
      payload.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    const url = baseUrl(`club/export_inactive_players?id_club=${payload.id_club
      }${payload.start_date ? `&start_date=` + payload.start_date : ""}${payload.end_date ? `&end_date=` + payload.end_date : ""
      }&token=${payload.token}`);
    window.location.assign(url);
  };

  const exportActivePlayers = () => {
    let payload = {
      id_club: props.id_club,
      token: props.token,
    };

    if (startDate) {
      payload.start_date = moment(startDate).format("YYYY-MM-DD");
    }

    if (endDate) {
      payload.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    const url = baseUrl(`club/export_active_players_added?id_club=${payload.id_club
      }${payload.start_date ? `&start_date=` + payload.start_date : ""}${payload.end_date ? `&end_date=` + payload.end_date : ""
      }&token=${payload.token}`);
    window.location.assign(url);
  };

  const handleOpenEditingTeam = (team) => {
    setOpenEditingTeam(true);
    setEditingTeam(team);
  };

  const handleCloseEditingTeam = () => {
    setOpenEditingTeam(false);
    setEditingTeam({});
  };

  return (
    <>
      {fetchFinished ? (
        teams.length > 0 ? (
          <>
            <TableContainer>
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn} align="left">
                      {languagePack.team_name}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.current_season}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.team_type}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.number_players}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.team_coach}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teams.map((row, index) => (
                    <TableRow
                      key={"active-team-" + index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                        align="left"
                      >
                        <TFBTeamLabel
                          name={row.team_name}
                          color={row?.team_color ?? null}
                          onClick={() => history.push(`/club/team/${row.id_team}`)}
                        />
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.current_season_name}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.team_type}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.number_players > 0 ? row.number_players : "-"}
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.coach}</TFBTableCell>
                      <TFBTableCell align="center">
                        <div
                          className="actions-btns-container"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            altTitle={languagePack.altTitle.view_team}
                            onClick={() =>
                              history.push(`/club/team/${row.id_team}`)
                            }
                          />
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            altTitle={languagePack.altTitle.edit_team}
                            onClick={() => handleOpenEditingTeam(row)}
                          />
                          <TFBIconButton
                            name="arrow-up"
                            color="darkGray"
                            altTitle={languagePack.altTitle.import_players}
                            onClick={() =>
                              history.push(
                                `/club/team/import-player/${row.id_team}`,
                                { team_name: row.team_name }
                              )
                            }
                          />
                          {isTFB() &&
                            <TFBIconButton
                              name="dollar-in"
                              color="darkGray"
                              altTitle={languagePack.altTitle.costs}
                              onClick={() =>
                                history.push(`/club/payments/${row.id_team}`)
                              }
                            />
                          }
                          {isTFB() &&
                            <TFBIconButton
                              name="heart-check"
                              color="darkGray"
                              altTitle={languagePack.altTitle.medical_certificate}
                              onClick={() =>
                                history.push(
                                  `/club/medical-certificates/${row.id_team}`,
                                  { team_name: row.team_name }
                                )
                              }
                              {...(row.players_without_certificates > 0 && {
                                badge: row.players_without_certificates,
                              })}
                            />
                          }
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="active-teams-export-players-container">
              <div className="ds-headline-2 title">
                {languagePack.export_added_inactive_players}
              </div>

              <div className="filters-wrapper">
                <div className="dates-filters">
                  <div className="players-date-pickers">
                    <TFBDatePicker
                      className="date-picker"
                      placeholder={languagePack.from}
                      label={languagePack.from}
                      value={startDate}
                      onChange={(date) => handleChangeStartDate(date)}
                    />
                  </div>

                  <span
                    style={{
                      marginTop: "18px",
                    }}
                  >
                    {"-"}
                  </span>

                  <div className="players-date-pickers">
                    <TFBDatePicker
                      className="date-picker"
                      placeholder={languagePack.until}
                      label={languagePack.until}
                      value={endDate}
                      onChange={(date) => handleChangeEndDate(date)}
                    />
                  </div>
                </div>

                <div className="filter-buttons">
                  <TFBButton
                    className="reset-button"
                    onClick={handleResetFilters}
                    endIcon="true"
                    color={"darkGray"}
                    renderIcon={() => <TFBIcon name={"cancel"} />}
                  >
                    {languagePack.Reset_filters}
                  </TFBButton>
                </div>
              </div>

              <div className="export-buttons-wrapper">
                <TFBButton
                  className="export-inactive-players"
                  onClick={exportInactivePlayers}
                >
                  {languagePack.export_inactive_players}
                </TFBButton>
                <TFBButton
                  className="export-added-players"
                  onClick={exportActivePlayers}
                >
                  {languagePack.export_added_players}
                </TFBButton>
              </div>
            </div>
          </>
        ) : (
          <TFBPlaceholder text={languagePack.no_teams_yet} />
        )
      ) : (
        <div className="loader-container" style={{ marginTop: 50 }}>
          <Loader
            type="TailSpin"
            color={window.clubCustomization.primary_color}
            height={40}
            width={40}
          />
        </div>
      )}
      {openEditingTeam && (
        <EditTeam
          isOpenPopup={openEditingTeam}
          closePopup={handleCloseEditingTeam}
          idTeam={editingTeam?.id_team}
          refreshTeams={getTeams}
          players={editingTeam?.current_season_players}
        />
      )}
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
}))(ActiveTeams);
