export const rootPages = {
  DASHBOARD: "dashboard",
  CLUB: "club",
  TRAINING: "training",
  REPORTS: "reports",
  FINANCIAL: "financial",
  MEDICAL: "medical",
  DOCUMENTS: "documents",
  FANS: "fans",
  VIDEOGALLERY: "video-gallery",
  SITE: "site",
  APPLICATION: "application",
  SHOP: "shop",
  USERS_MANAGEMENT: "users-management",
  SCOUTING: "scouting",
};

export const asideDisplayRootPage = {
  [rootPages.DASHBOARD]: false,
  [rootPages.CLUB]: true,
  [rootPages.REPORTS]: true,
  [rootPages.FINANCIAL]: true,
  [rootPages.DOCUMENTS]: true,
  [rootPages.SCOUTING]: true,
  [rootPages.MEDICAL]: true,
  [rootPages.FANS]: true,
  [rootPages.SITE]: true,
  [rootPages.TRAINING]: true,
  [rootPages.VIDEOGALLERY]: true,
};

export const currencies = {
  EUR: {
    translation: "EUR",
    uniqueSlug: "EUR",
    bonusUniqueSlug: "Euro",
    flag: "eu",
    iconName: "eur",
    key: "EUR",
    value: "EUR",
    text: "EURO",
  },
  RON: {
    translation: "RON",
    uniqueSlug: "RON",
    bonusUniqueSlug: "Ron",
    flag: "ro",
    key: "RON",
    value: "RON",
    text: "RON",
  },
  // ARS: {
  //   translation: "ARS",
  //   uniqueSlug: "ARS",
  //   bonusUniqueSlug: "Ars",
  //   flag: "ar",
  //   key: "ARS",
  //   value: "ARS",
  //   text: "ARS",
  // },
};

export const videoSources = [
  {
    value: "veo",
    label: "Veo",
  }
];

export const importPlayerHeaders = [
  {
    value: "player_name",
    label: "Nume jucator",
    validation_type: "non_empty_string",
    required: true,
  },
  {
    value: "player_position",
    label: "Pozitie",
  },
  {
    value: "player_position_alternative",
    label: "Pozitie secundara",
  },
  {
    value: "player_main_foot",
    label: "Picior de baza",
  },
  {
    value: "birth_country",
    label: "Nationalitate",
  },
  {
    value: "second_country",
    label: "Nationalitate 2",
  },
  {
    value: "player_dob",
    label: "Data nasterii",
    validation_type: "date",
  },
  {
    value: "shirt_number",
    label: "Numar tricou",
    validation_type: "int_number",
  },
  {
    value: "cnp",
    label: "CNP",
    validation_type: "int_number",
  },
  {
    value: "fifa_id",
    label: "Fifa ID",
  },
  {
    value: "legitimation_date",
    label: "Data legitimare",
    validation_type: "date",
  },
  {
    value: "enrolment_date",
    label: "Data inscriere",
    validation_type: "date",
  },
  {
    value: "phone_number",
    label: "Numar de telefon",
    validation_type: "phone_number",
  },
  {
    value: "height",
    label: "Înalțime",
    validation_type: "only_digits",
  },
  {
    value: "optimal_weight",
    label: "Greutate",
  },
];

export const clubTypes = [
  {
    value: "club_from_platform",
    label: "Club din platforma TFB",
  },
  {
    value: "club_from_external",
    label: "Club din afara platformei TFB",
  }
]

export const frfListDropdownU21 = [
  { value: "A", lang_pack: "list_a" },
  { value: "B", lang_pack: "list_b" },
  { value: "0", lang_pack: "no_list" },
];

export const frfListDropdown = [
  { value: "A", lang_pack: "list_a" },
  { value: "0", lang_pack: "no_list" },
];

export const frfListNoDob = [
  { value: "0", lang_pack: "no_list" },
];

export const positionsDropdown = [
  {
    lang_pack: "GK",
    value: "Goalkeeper",
  },
  {
    lang_pack: "DEF",
    value: "Defender",
  },
  {
    lang_pack: "MID",
    value: "Midfielder",
  },
  {
    lang_pack: "ATT",
    value: "Forward",
  },
];

export const mainFootDropdown = [
  {
    lang_pack: "RT",
    value: "Right",
  },
  {
    lang_pack: "LT",
    value: "Left",
  },
  {
    lang_pack: "BOTH",
    value: "Both",
  },
];

export const daysOfWeek = [
  {
    lang_pack: "monday",
    value: "1",
  },
  {
    lang_pack: "tuesday",
    value: "2",
  },
  {
    lang_pack: "wednesday",
    value: "3",
  },
  {
    lang_pack: "thursday",
    value: "4",
  },
  {
    lang_pack: "friday",
    value: "5",
  },
  {
    lang_pack: "saturday",
    value: "6",
  },
  {
    lang_pack: "sunday",
    value: "7",
  },
];

export const docTypeBySign = [
  { value: -1, lang_pack: "all_documents" },
  { value: 1, lang_pack: "signed_documents" },
  { value: 0, lang_pack: "unsigned_documents" },
];