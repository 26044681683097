import React, { useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";

const DeleteStaffContract = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, contractData } = props;

    const { data: responseDeleteContract, postData: postDeleteContract } = useAxiosPost(
        "finances/delete_staff_member_contract"
    );

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteContract(),
        "red",
        "delete",
        languagePack.admin_delete,
    );

    useEffect(() => {
        if (!responseDeleteContract) return;

        if (responseDeleteContract.success == 1) {
            showNotification("success", languagePack.staff_contract, languagePack.success_delete_contract_staff_member);
            refreshList();
            closePopup();
        } else {
            showNotification("danger", languagePack.staff_contract, languagePack.fail_delete_contract_staff_member);
        }
    }, [responseDeleteContract]);

    const deleteContract = async () => {
        const payload = {
            id_contract: contractData.id_contract
        }

        if (contractData?.contract_path) {
            payload.path = contractData.contract_path;
        }

        postDeleteContract(payload);
    };

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_contract"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_contract_staff_text}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(DeleteStaffContract);
