import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

const DeletePaymentRequest = (props) => {
  const { languagePack, closePopup, isOpenPopup, refreshList, id } = props;

  const deletePaymentRequest = () => {
    const payload = {
      id_payment_request: id,
    };

    axios
      .post("finances/delete_payment_request", payload)
      .then((res) => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.payment_requests,
            message: languagePack.success_delete_payment_request,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.payment_requests,
            message: languagePack.fail_delete_payment_request,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch((e) => console.log(e));
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.delete_payment_request}
      open={isOpenPopup}
      closePopup={closePopup}
      btnLeft={() => (
        <TFBButton
          color="darkGray"
          renderIcon={() => <TFBIcon name="cancel" />}
          onClick={closePopup}
        >
          {languagePack.admin_cancel}
        </TFBButton>
      )}
      btnRight={() => (
        <TFBButton
          color="red"
          renderIcon={() => <TFBIcon name="delete" />}
          onClick={deletePaymentRequest}
        >
          {languagePack.admin_delete}
        </TFBButton>
      )}
    >
      <div className="pop-up-dialog-delete-text">
        {languagePack.delete_payment_request_message}
      </div>
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeletePaymentRequest);
