import React from "react";
import { Icon, Dropdown, Button, Checkbox } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import axios from "axios";
import { connect } from "react-redux";
import { countries } from "../../../utils/countries";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBPhoneInput from "../../../components/design-system/PhoneInput/TFBPhoneInput";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import _ from "lodash";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBTabControl from "../../../components/design-system/TabControl/TFBTabControl";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  sxStickyColumn,
  TFBTableCell,
} from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../../components/reusable/Loader";
import AddMobileUser from "../../MobileAppAdmin/UserList/AddMobileUser";
import EditMobileUser from "../../MobileAppAdmin/UserList/EditMobileUser";
import DeleteMobileUser from "../../MobileAppAdmin/UserList/DeleteMobileUser";
import ChangeStatusMobileUser from "../../MobileAppAdmin/UserList/ChangeStatusMobileUser";

const frfListDropdownU21 = [
  { key: 1, value: "A", text: "Lista A", label: "Lista A" },
  { key: 2, value: "B", text: "Lista B", label: "Lista B" },
  { key: 3, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

const frfListDropdown = [
  { key: 1, value: "A", text: "Lista A", label: "Lista A" },
  { key: 2, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

const frfListNoDob = [
  { key: 1, value: "0", text: "Nicio Lista", label: "Nicio Lista" },
];

class EditPlayerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerId: this.props.id_player,
      countriesOption: countries,
      activeTab: 0,
      mobileUsersList: [],
      newName: this.props.name,
      newPosition: this.props?.position
        ? _.size(
            _.filter(
              this.positions_dropdown,
              (e) => e.value === this.props.position
            )
          ) > 0
          ? _.filter(
              this.positions_dropdown,
              (e) => e.value === this.props.position
            )[0]
          : { value: this.props.position, label: this.props.position }
        : null,
      newSecondPosition: this.props?.player_position_alternative
        ? _.size(
            _.filter(
              this.positions_dropdown,
              (e) => e.value === this.props.player_position_alternative
            )
          ) > 0
          ? _.filter(
              this.positions_dropdown,
              (e) => e.value === this.props.player_position_alternative
            )[0]
          : { value: "custom", label: this.props.player_position_alternative }
        : null,
      newMainFoot: this.props?.player_main_foot
        ? _.size(
            _.filter(
              this.main_foot_dropdown,
              (e) => e.value === this.props.player_main_foot
            )
          ) > 0
          ? _.filter(
              this.main_foot_dropdown,
              (e) => e.value === this.props.player_main_foot
            )[0]
          : {
              value: this.props.player_main_foot,
              label: this.props.player_main_foot,
            }
        : null,
      newDob: this.props?.birthday ?? null,
      newNumber: this.props?.number ?? "",
      newPlayerIdentityCard: this.props?.cnp ?? "",
      newPlayerFifaId: this.props?.fifa_id ?? "",
      newPlayerFrfList: this.props?.frf_list
        ? _.size(
            _.filter(frfListDropdown, (e) => e.value == this.props.frf_list)
          ) > 0
          ? _.filter(frfListDropdown, (e) => e.value == this.props.frf_list)[0]
          : { value: this.props.frf_list, label: this.props.frf_list }
        : null,

      newPlayerHeight: this.props.player_height ?? "",
      newPlayerWeight: this.props.player_weight ?? "",

      new_legitimation_date: this.props?.legitimation_date ?? null,
      new_enrolment_date: this.props?.enrolment_date ?? null,

      hasProfessionalLicense: this.props?.has_professional_license ?? 0,

      loaned_out: this.props.loaned_out,
      loaned_from: this.props.loaned_from ?? "0",
      loaned_until: this.props.loaned_until,
      loaned_team: this.props.loaned_team,
      loaned_from_until: this.props.loaned_from_until || null,
      loaned_from_team: this.props.loaned_from_team || null,

      isU21: true,
      isOnFrfList: false,

      newBirthCountry: this.props?.birth_country
        ? _.size(
            _.filter(countries, (e) => e.text == this.props.birth_country)
          ) > 0
          ? _.filter(countries, (e) => e.text == this.props.birth_country)[0][
              "value"
            ]
          : null
        : null,

      newSecondCountry: this.props?.second_country
        ? _.size(
            _.filter(countries, (e) => e.text == this.props.second_country)
          ) > 0
          ? _.filter(countries, (e) => e.text == this.props.second_country)[0][
              "value"
            ]
          : null
        : null,

      isWrongNumber: false,
      playerPhoneNumber: this.props?.phone_number ?? "",
      newSerialNumber: this.props?.serial_number ?? "",
    };
  }

  positions_dropdown = [
    {
      key: "GK",
      value: "Goalkeeper",
      text: "Portar",
      label: this.props.languagePack.Goalkeeper,
    },
    {
      key: "DEF",
      value: "Defender",
      text: "Fundas",
      label: this.props.languagePack.Defender,
    },
    {
      key: "MID",
      value: "Midfielder",
      text: "Mijlocas",
      label: this.props.languagePack.Midfielder,
    },
    {
      key: "ATT",
      value: "Forward",
      text: "Atacant",
      label: this.props.languagePack.Forward,
    },
  ];

  main_foot_dropdown = [
    {
      key: "RT",
      value: "Right",
      text: "Picior drept",
      label: this.props.languagePack.right_foot,
    },
    {
      key: "LT",
      value: "Left",
      text: "Picior stang",
      label: this.props.languagePack.left_foot,
    },
    {
      key: "BOTH",
      value: "Both",
      text: "Ambele",
      label: this.props.languagePack.both,
    },
  ];

  componentDidMount() {
    let countries2 = JSON.parse(JSON.stringify(countries));
    countries2.unshift({
      key: "noCountry",
      value: "noCountry",
      flag: "noCountry",
      text: "No country",
    });
    this.setState({
      countriesOption: countries2,
    });
    this.isOnFrfList();
    this.isU21();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.activeTab !== this.state.activeTab &&
      this.state.activeTab === 1
    ) {
      this.fetchMobileUsersList();
    }
    if (prevState.newDob !== this.state.newDob) {
      this.setState({ newPlayerFrfList: "" });
      this.isOnFrfList();
      this.isU21();
    }
  }

  isOnFrfList = () => {
    if (
      _.size(
        _.intersection(
          ["A", "B"],
          _.map([this.state.newPlayerFrfList], (e) => e?.value)
        )
      ) > 0
    ) {
      this.setState({ isOnFrfList: true });
    } else {
      this.setState({ isOnFrfList: false });
    }
  };

  isU21 = () => {
    if (this.state.newDob != null) {
      const year = "2001";
      let birthdayDate = new Date(this.state.newDob);
      let u21 = new Date("01-01-" + year);

      birthdayDate <= u21
        ? this.setState({ isU21: false })
        : this.setState({ isU21: true });
    }
  };

  listFilter = () => {
    if (this.state.isU21) {
      return frfListDropdownU21;
    } else {
      return frfListDropdown;
    }
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, () => {
      this.isOnFrfList();
    });
  };

  handleChangeSelect = (selected, value) => {
    this.setState({ [value.name]: selected }, () => {
      this.isOnFrfList();
    });
  };

  hanldeBlurSelect = (name, e) => {
    if (e.target.value) {
      this.setState({
        [name]: { value: e.target.value, label: e.target.label },
      });
    }
  };

  handleChangeDate = (value) => {
    this.setState({ newDob: value }, () => {
      this.isU21();
      this.setState({ newPlayerFrfList: "" });
    });
  };

  handleChangeDateLoaned = (value) => {
    this.setState({ loaned_until: value });
  };

  handleChangeDateLoanedFrom = (value) => {
    this.setState({ loaned_from_until: value });
  };

  getCountryName = (value) => {
    if (value && value != "noCountry") {
      const selected_country = countries.find((item) => item.value === value);
      return selected_country.text;
    } else return "";
  };
  openAddUser = () => {
    this.setState({ open_add_user: true });
  };

  closeAddUser = () => {
    this.setState({ open_add_user: false });
  };

  openEditUser = (user) => {
    this.setState({ open_edit_user: true, editing_user: user });
  };

  closeEditUser = () => {
    this.setState({ open_edit_user: false, editing_user: {} });
  };

  openDeleteUser = (user) => {
    this.setState({ open_delete_user: true, deleting_user: user });
  };

  closeDeleteUser = () => {
    this.setState({ open_delete_user: false, deleting_user: {} });
  };

  openChangeStatusUser = (user) => {
    this.setState({
      open_change_status_user: true,
      changing_status_user: user,
    });
  };

  closeChangeStatusUser = () => {
    this.setState({ open_change_status_user: false, changing_status_user: {} });
  };

  refreshList = () => {
    this.fetchMobileUsersList();
  };

  validatePhone = (fieldName, value) => {
    let isWrongNumberStatic = this.state.isWrongNumber;

    if (value) {
      switch (fieldName) {
        case "phoneNumber":
          if (value.indexOf(7) == 3) {
            isWrongNumberStatic = !isValidPhoneNumber(value);
          } else {
            isWrongNumberStatic = true;
          }

          break;

        default:
          break;
      }

      this.setState({ isWrongNumber: isWrongNumberStatic });
    } else {
      this.setState({ isWrongNumber: false });
    }
  };

  fetchMobileUsersList = () => {
    const { id_club, token } = this.props.currentUser;
    this.setState({ isUserListLoading: true });
    axios
      .get(
        `parentsApp/get_mobile_user_list?id_club=${id_club}&id_player=${this.props.id_player}&token=${token}&page=1&limit=100`
      )
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            mobileUsersList: response.data.list || [],
            isUserListLoading: false,
          });
        } else if (response.data.success === 0) {
          store.addNotification({
            title: "Lista utilizatori mobili",
            message:
              "Nu s-a putut încărca lista. Vă rugăm să încercați din nou.",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching mobile users list:", error);
        store.addNotification({
          title: "Eroare",
          message:
            "A apărut o eroare la încărcarea listei. Vă rugăm să încercați din nou.",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      });
  };

  saveChanges = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      id_player: this.props.id_player,
      player_name: this.state.newName.trim(),
      player_dob: this.state.newDob,
      player_position: this.state.newPosition?.value ?? null,
      player_position_alternative: this.state.newSecondPosition?.value ?? null,
      player_main_foot: this.state.newMainFoot?.value ?? null,
      birth_country: this.getCountryName(this.state.newBirthCountry),
      second_country: this.getCountryName(this.state.newSecondCountry),
      shirt_number: this.state.newNumber,
      frf_list:
        this.state.newPlayerFrfList?.value == ""
          ? 0
          : this.state.newPlayerFrfList?.value,

      cnp: this.state.newPlayerIdentityCard,
      fifa_id: this.state.newPlayerFifaId,
      has_professional_license: this.state.hasProfessionalLicense,

      loaned_out: this.state.loaned_out,
      loaned_until: this.state.loaned_until,
      loaned_team: this.state.loaned_team,

      loaned_from: this.state.loaned_from,
      loaned_from_until: this.state.loaned_from_until,
      loaned_from_team: this.state.loaned_from_team,

      height: this.state.newPlayerHeight,
      weight: this.state.newPlayerWeight,

      legitimation_date: this.state.new_legitimation_date,
      enrolment_date: this.state.new_enrolment_date,
      serial_number: this.state.newSerialNumber,
      phone_number:
        this.state.playerPhoneNumber != ""
          ? this.state.playerPhoneNumber
          : null,
    };

    axios
      .post("club/edit_player_profile_3", payload)
      .then((res) => {
        if (res.data.success_phone_number == 1) {
          store.addNotification({
            title: "Profil jucator",
            message: "Modificarile au fost salvate cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.props.refreshData();
          this.props.closePopup();
        } else if (
          res.data.success_phone_number == 0 &&
          res.data.error_code == "used_phone_number_by_another_user"
        ) {
          store.addNotification({
            title: "Profil jucator",
            message:
              "Numărul de telefon este există deja pentru alt utilizator !",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else if (
          res.data.success_phone_number == 0 &&
          res.data.error_code == "used_phone_number_by_another_player"
        ) {
          store.addNotification({
            title: "Profil jucator",
            message: "Numărul de telefon este există deja pentru alt jucător !",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Profil jucator",
            message: "Modificarile nu au fost salvate !",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;
    const tab1 = (
      <>
        <div className="tab-control-line">
          <TFBInput
            label={this.props.languagePack.player_name}
            value={this.state?.newName ?? ""}
            onChange={(e) => this.setState({ newName: e.target.value })}
            type="text"
            style={{ width: "100%" }}
          />
        </div>
        <div className="df-g20 tab-control-line tab-control-line-mw-500">
          <TFBSelect
            placeholder={this.props.languagePack["POSITION"]}
            label={this.props.languagePack["POSITION"]}
            value={this.state.newPosition}
            name="newPosition"
            options={this.positions_dropdown}
            onChange={(selected, value) => {
              this.handleChangeSelect(selected, value);
            }}
            onBlur={(e) => this.hanldeBlurSelect("newPosition", e)}
            isClearable={true}
          />

          <TFBSelect
            placeholder={this.props.languagePack["SECONDARY_POSITION"]}
            label={this.props.languagePack["SECONDARY_POSITION"]}
            value={this.state.newSecondPosition}
            name="newSecondPosition"
            options={this.positions_dropdown}
            onChange={(selected, value) => {
              this.handleChangeSelect(selected, value);
            }}
            onBlur={(e) => this.hanldeBlurSelect("newSecondPosition", e)}
            isClearable={true}
          />
        </div>
        <div className="df-g20 tab-control-line tab-control-line-mw-500">
          <div className="tab-control-btn">
            <TFBSelect
              placeholder={this.props.languagePack["MAIN_FOOT"]}
              label={this.props.languagePack["MAIN_FOOT"]}
              value={this.state.newMainFoot}
              name="newMainFoot"
              options={this.main_foot_dropdown}
              onChange={(selected, value) => {
                this.handleChangeSelect(selected, value);
              }}
              onBlur={(e) => this.hanldeBlurSelect("newMainFoot", e)}
              isClearable={true}
            />
          </div>
          <div className="tab-control-btn">
            <TFBDropdown
              color="lightGray"
              placeholder={this.props.languagePack["NATIONALITY"]}
              label={this.props.languagePack["NATIONALITY"]}
              value={this.state.newBirthCountry}
              options={countries}
              selection
              name="newBirthCountry"
              search
              onChange={this.handleChangeDropdown}
            />
          </div>
        </div>
        <div className="df-g20 tab-control-line tab-control-line-mw-500">
          <div className="tab-control-btn">
            <TFBDropdown
              color="lightGray"
              placeholder={this.props.languagePack["NATIONALITY"]}
              label={this.props.languagePack["NATIONALITY"]}
              value={this.state.newSecondCountry}
              options={this.state.countriesOption}
              selection
              name="newSecondCountry"
              search
              onChange={this.handleChangeDropdown}
            />
          </div>
          <div className="tab-control-btn">
            <TFBDatePicker
              value={this.state.newDob}
              onChange={this.handleChangeDate}
              placeholder={this.props.languagePack["birth_date"]}
              label={this.props.languagePack["birth_date"]}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          className={
            "df-g20 tab-control-line-mw-500" +
            (this.state.isOnFrfList == true && this.state.newNumber == ""
              ? ""
              : " tab-control-line")
          }
        >
          <TFBInput
            placeholder={this.props.languagePack.shirt_number}
            label={this.props.languagePack.shirt_number}
            value={this.state.newNumber ?? ""}
            onChange={(e) => {
              this.setState({
                newNumber: e.target.value,
              });
            }}
            fullWidth
            type="number"
            style={{ backgroundColor: "#fafafa" }}
          />
          <TFBSelect
            placeholder={"Lista FRF"}
            label={"Lista FRF"}
            value={this.state.newPlayerFrfList}
            name="newPlayerFrfList"
            options={
              this.state.newDob == null ? frfListNoDob : this.listFilter()
            }
            onChange={(selected, value) => {
              this.handleChangeSelect(selected, value);
            }}
            onBlur={(e) => this.hanldeBlurSelect("newPlayerFrfList", e)}
            isClearable={true}
          />
        </div>
        {this.state.isOnFrfList == true && this.state.newNumber == "" && (
          <div className="tab-control-line error-message">
            {this.props.languagePack.insert_shirt_number}
          </div>
        )}
        <div className="df-g20 tab-control-line tab-control-line-mw-500">
          <TFBInput
            placeholder={this.props.languagePack.identity_card_id}
            label={this.props.languagePack.identity_card_id}
            value={this.state.newPlayerIdentityCard ?? ""}
            fullWidth
            onChange={(e) => {
              this.setState({
                newPlayerIdentityCard: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fafafa" }}
          />
          <TFBInput
            placeholder={"Fifa ID"}
            label={"Fifa ID"}
            fullWidth
            value={this.state.newPlayerFifaId ?? ""}
            onChange={(e) => {
              this.setState({
                newPlayerFifaId: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fafafa" }}
          />
        </div>
        <div className="df-g20 tab-control-line tab-control-line-mw-500">
          <TFBInput
            placeholder={this.props.languagePack.height}
            label={this.props.languagePack.height}
            value={this.state.newPlayerHeight ?? ""}
            fullWidth
            onChange={(e) => {
              this.setState({
                newPlayerHeight: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fafafa" }}
          />
          <TFBInput
            placeholder={this.props.languagePack.weight}
            label={this.props.languagePack.weight}
            fullWidth
            value={this.state.newPlayerWeight ?? ""}
            onChange={(e) => {
              this.setState({
                newPlayerWeight: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fafafa" }}
          />
        </div>
        <div className="df-g20 tab-control-line tab-control-line-mw-500">
          <TFBDatePicker
            value={this.state.new_legitimation_date}
            onChange={(value) =>
              this.setState({ new_legitimation_date: value })
            }
            placeholder={this.props.languagePack.legitimation_date}
            label={this.props.languagePack.legitimation_date}
            style={{ width: "100%" }}
          />
          <TFBDatePicker
            value={this.state.new_enrolment_date}
            onChange={(value) => this.setState({ new_enrolment_date: value })}
            placeholder={this.props.languagePack.enrolment_date}
            label={this.props.languagePack.enrolment_date}
            style={{ width: "100%" }}
          />
        </div>
        <div className="tab-control-line">
          <TFBInput
            label="Matricol"
            fullWidth
            style={{ backgroundColor: "#fafafa" }}
            value={this.state.newSerialNumber}
            onChange={(e) => this.setState({ newSerialNumber: e.target.value })}
          />
        </div>
        <div className="tab-control-line">
          <TFBPhoneInput
            initialValueFormat="national"
            defaultCountry="RO"
            countries={["RO"]}
            label={this.props.languagePack.tax_redirector_phone_number}
            placeholder={"Ex: 0712 345 678"}
            value={this.state.playerPhoneNumber}
            onChange={(e) => {
              this.setState({ playerPhoneNumber: e ?? "" });
              this.validatePhone("phoneNumber", e);
            }}
          />
        </div>
        {this.state.isWrongNumber &&
          this.state.playerPhoneNumber &&
          this.state.playerPhoneNumber.length > 0 && (
            <div className="text-danger">
              {this.props.languagePack.incorect_phone_number}
            </div>
          )}
        <div className="tab-control-line">
          <TFBCheckbox
            label={this.props.languagePack.has_professional_license_issued}
            checked={this.state.hasProfessionalLicense == 0 ? false : true}
            onChange={(e) => {
              this.setState({
                hasProfessionalLicense: e.target.checked ? 1 : 0,
              });
            }}
          />
        </div>
        <div className="tab-control-line">
          <TFBCheckbox
            label={this.props.languagePack.loaned_at}
            checked={this.state.loaned_out == 0 ? false : true}
            onChange={(e) => {
              this.setState({
                loaned_out: e.target.checked ? 1 : 0,
              });
              if (!e.target.checked) {
                this.setState({
                  loaned_team: "",
                  loaned_until: null,
                });
              }
            }}
          />
        </div>
        {this.state.loaned_out == "1" && (
          <div className="df-g20 tab-control-line tab-control-line-mw-500">
            <TFBInput
              placeholder={this.props.languagePack.on_loan}
              label={this.props.languagePack.on_loan}
              value={this.state.loaned_team ?? ""}
              onChange={(e) => {
                this.setState({
                  loaned_team: e.target.value,
                });
              }}
              fullWidth
              style={{ backgroundColor: "#fafafa" }}
            />
            <TFBDatePicker
              value={this.state.loaned_until}
              onChange={this.handleChangeDateLoaned}
              placeholder={this.props.languagePack.until}
              label={this.props.languagePack.until}
              style={{ width: "100%" }}
            />
          </div>
        )}
        <div className="tab-control-line">
          <TFBCheckbox
            label={this.props.languagePack.loaned_from}
            checked={this.state.loaned_from == "0" ? false : true}
            onChange={(e) => {
              this.setState({
                loaned_from: e.target.checked ? 1 : 0,
              });
              if (!e.target.checked) {
                this.setState({
                  loaned_from_team: "",
                  loaned_from_until: null,
                });
              }
            }}
          />
        </div>
        {this.state.loaned_from == "1" && (
          <div className="df-g20 tab-control-line tab-control-line-mw-500">
            <TFBInput
              placeholder={this.props.languagePack.on_loan_from}
              label={this.props.languagePack.on_loan_from}
              value={this.state.loaned_from_team ?? ""}
              onChange={(e) => {
                this.setState({
                  loaned_from_team: e.target.value,
                });
              }}
              fullWidth
              style={{ backgroundColor: "#fafafa" }}
            />
            <TFBDatePicker
              value={this.state.loaned_from_until}
              onChange={this.handleChangeDateLoanedFrom}
              placeholder={this.props.languagePack.until}
              label={this.props.languagePack.until}
              style={{ width: "100%" }}
            />
          </div>
        )}
      </>
    );

    const tab2 = (
      <>
        <div
          style={{
            height: "600px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <h2
              style={{
                color: "rgb(63, 66, 84)",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              {languagePack.mobile_app_users}
            </h2>
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="add" />}
              onClick={this.openAddUser}
            >
              {languagePack.add_user}
            </TFBButton>
          </div>
          {_.size(this.state.mobileUsersList) > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.campaign_name}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.player_players_name}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.admin_contact_phone}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.Email}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.current_status_payment}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(this.state.mobileUsersList, (row, index) => (
                    <TableRow
                      key={"notification-number-" + index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div>{row.name + " " + row.surname}</div>
                          <div style={{ color: "#b2b2b2" }}>
                            {_.join(
                              _.map(
                                _.map(row.players, (e) => e.relationship),
                                (e) => e.relationship_name
                              ),
                              ", "
                            )}
                          </div>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {_.join(
                          _.map(
                            _.map(row.players, (e) => e.player),
                            (e) => e.player_name
                          ),
                          ", "
                        )}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.phone_number}
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.email}</TFBTableCell>
                      <TFBTableCell align="center">
                        {row.is_player == 0 &&
                          (row?.recurrence?.active == 1 ? (
                            <TFBIcon name="check" color="green" />
                          ) : (
                            <TFBIcon name="x-large" color="black" />
                          ))}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div
                          className="actions-btns-container"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <TFBIconButton
                            name={
                              row.is_accepted == "1"
                                ? "active-player"
                                : "inactive-player"
                            }
                            className="tfb-icon-button-no-filter"
                            color="darkGray"
                            onClick={() => this.openChangeStatusUser(row)}
                          />
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => this.openEditUser(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => this.openDeleteUser(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {_.size(this.state.mobileUsersList) == 0 &&
            this.state.isUserListLoading == false && (
              <TFBPlaceholder text={languagePack.no_users} />
            )}
          {this.state.isUserListLoading == true && <CircularLoader />}
        </div>
      </>
    );

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.edit_details}
        open={isOpenPopup}
        fullWidth={this.state.activeTab === 1}
        height={this.state.activeTab === 1 ? "100%" : "auto"}
        maxWidth="lg"
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            disabled={this.state.isWrongNumber}
            onClick={this.saveChanges}
          >
            {languagePack.admin_save}
          </TFBButton>
        )}
      >
        <TFBTabControl
          tabs={[languagePack.general_details, languagePack.mobile_app_details]}
          tabsContent={[tab1, tab2]}
          activeTab={this.state.activeTab}
          onChangeActiveTab={(i) => this.setState({ activeTab: i })}
        />
        {this.state.open_add_user && (
          <AddMobileUser
            presetPlayer={{
              value: this.state.playerId,
              label: this.props.name,
            }}
            showAddPlayer={false}
            openPopup={this.state.open_add_user}
            closePopup={this.closeAddUser}
            refreshList={this.refreshList}
          />
        )}

        {this.state.open_edit_user && (
          <EditMobileUser
            openPopup={this.state.open_edit_user}
            closePopup={this.closeEditUser}
            refreshList={this.refreshList}
            user={this.state.editing_user}
          />
        )}

        {this.state.open_delete_user && (
          <DeleteMobileUser
            openPopup={this.state.open_delete_user}
            closePopup={this.closeDeleteUser}
            refreshList={this.refreshList}
            user={this.state.deleting_user}
          />
        )}

        {this.state.open_change_status_user && (
          <ChangeStatusMobileUser
            openPopup={this.state.open_change_status_user}
            closePopup={this.closeChangeStatusUser}
            refreshList={this.refreshList}
            user={this.state.changing_status_user}
          />
        )}
      </TFBDialog>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditPlayerDetails);
