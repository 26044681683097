import React, { useEffect, useState } from "react";
import styles from "./WarningBar.module.scss";
import { connect } from "react-redux";

import moment from "moment";
import { bindActionCreators } from "redux";
import { logout } from "../../../../../app/utils/api";
import SuspendedAccountPopup from "./SuspendedAccountPopup";

function WarningBar({ currentUser, logout, token, languagePack }) {
  const [openSuspendedAccountPopup, setOpenSuspendedAccountPopup] = useState(
    false
  );

  useEffect(() => {
    if (moment(currentUser.club.suspention_date).isBefore(moment()))
      setOpenSuspendedAccountPopup(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.message}>
        {currentUser.club.suspention_reason}{" "}
      </div>
      {openSuspendedAccountPopup && (
        <SuspendedAccountPopup
          logout={logout}
          token={token}
          languagePack={languagePack}
        />
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(WarningBar);
