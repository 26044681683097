import React, { useContext, useEffect, useReducer, useState } from "react";
import "./Form.scss";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import TFBDropdown from "../../components/design-system/Dropdown/TFBDropdown";
import { countries } from "../../utils/countries";
import { CreateAccountContext } from "../CreateAccount";
import { useHistory } from "react-router-dom";
import {
  TFBInputWithWrapper,
  TFBPasswordInputWithWrapper,
  TFBPhoneInputWithWrapper,
  TFBSelectWithWrapper,
} from "./FormAux.js";
import { isValidPhoneNumber } from "react-phone-number-input";
import { lowerCase, map, upperCase } from "lodash";
import {
  showNotification,
  useAxiosPost,
} from "../../components/customHooks/useAxiosPost";
import CircularLoader from "../../components/reusable/Loader";
import { useDispatch } from "react-redux";
import { getCountryName, isTFB } from "../../utils/utilFunctions";
import useAxiosGet from "../../components/customHooks/useAxiosGet";

const CREATE_ACCOUNT_ROUTE = "/user/create_free_account";
const redBorder = "1px solid #D91200";

const Form = ({ languagePack, lang = "ro" }) => {
  const dispatch = useDispatch();
  // ============= HOOKS ==============
  // ==================================
  const [errors, setErrors] = useState({
    fields: "",
    phoneNumber: "",
    password: "",
    email: "",
  });
  const [event, updateEvent] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      club: "",
      lang: lang,
      country: null,
      fullName: "",
      role: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
    }
  );
  const {
    goNextStep,
    setAccountEmail,
    setIdUser,
    setAccountPassword,
    subscriptionType,
  } = useContext(CreateAccountContext);

  const {
    data: ipData,
  } = useAxiosGet("https://ipapi.co/json/");

  useEffect(() => {
    if (ipData) {
      if (ipData?.country_code) {
        const checkCountryCode = getCountryName(lowerCase(ipData.country_code), countries)
        updateEvent({ country: checkCountryCode != "" ? lowerCase(ipData.country_code) : null })
      }
    }
  }, [ipData])

  const {
    loading: loadingCreate,
    postData: postCreateAccount,
  } = useAxiosPost(CREATE_ACCOUNT_ROUTE);

  const history = useHistory();

  // ============= HANDLERS ==============
  // =====================================

  const createEventHandler = (key) => (e) => {
    updateEvent({ [key]: e.target.value });
  };

  const eventHandlers = {
    handleClub: createEventHandler("club"),
    handleRole: (selected) => updateEvent({ role: selected.label }),
    handleFullName: createEventHandler("fullName"),
    handleEmail: createEventHandler("email"),
    handlePassword: createEventHandler("password"),
    handleConfirmPassword: createEventHandler("confirmPassword"),
    handleCountry: (e, data) => updateEvent({ country: data.value }),
    handlePhoneNumber: (phone) => updateEvent({ phoneNumber: phone }),
  };

  const isFormValid = () => {
    const {
      password,
      confirmPassword,
      club,
      fullName,
      role,
      email,
      phoneNumber,
    } = event;

    // Resetăm toate erorile la început
    setErrors({
      password: "",
      confirmPassword: "",
      club: "",
      fullName: "",
      role: "",
      email: "",
      phoneNumber: "",
      fields: "",
    });

    // Verificăm dacă club, fullName, role și email nu sunt goale
    if (!club || !fullName || !role || !email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        fields: languagePack.complete_all_data,
      }));
      return false;
    }

    if (
      !phoneNumber ||
      !isValidPhoneNumber(phoneNumber)
      // !phoneNumber.indexOf("7") == 3 ||
      // !phoneNumber.length == 12
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: languagePack.wrong_phone_number,
      }));
      return false;
    }

    // Verificăm dacă password are minim 8 caractere
    if (password.length < 8 || confirmPassword.length < 8) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: languagePack.wrong_password_min_8,
      }));

      return false;
    }

    // Verificăm dacă password și confirmPassword coincid
    if (password !== confirmPassword || !password || !confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: languagePack.wrong_password_not_same,
      }));

      return false;
    }

    // Verificăm dacă email-ul este valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: languagePack.wrong_email,
      }));

      return false;
    }

    return true; // Formularul este valid
  };

  const handleCreateAccountResponse = (response) => {
    if (response.success == 1) {
      const idUser = response.id_user;
      setIdUser(idUser);
      goNextStep();
    } else {
      let ERROR_TEXT = "";
      if (response.error_code == "email_used")
        ERROR_TEXT = languagePack.already_used_email;
      if (response.error_code == "email_and_phone_number_used")
        ERROR_TEXT = languagePack.already_used_email_phone_number;
      if (response.error_code == "phone_number_used")
        ERROR_TEXT = languagePack.already_used_phone_number;

      showNotification("danger", languagePack.create_account, ERROR_TEXT);
    }
  };

  // ============= SUBMIT ACCOUNT FORM ==============
  // ================================================
  const submitAccount = async () => {
    const payload = {
      club: {
        club_name: event.club,
        club_country: getCountryName(event.country, countries),
        lang: event.lang,
      },
      user: {
        email: event.email,
        phone_number: event.phoneNumber,
        full_name: event.fullName,
        password: event.password,
        club_role: event.role,
        lang: event.lang,
      },
      subscriptionType: subscriptionType,
      only_tfb: isTFB() ? 1 : 0,
    };

    if (isFormValid()) {
      postCreateAccount(payload, handleCreateAccountResponse);
      setAccountPassword(event.password);
      setAccountEmail(event.email);
    }
  };

  return (
    <div className="create-account-form-container">
      {/* =========== FORM ========== */}
      {/* =========================== */}
      <div className="form-row">
        <TFBDropdown
          color="lightGray"
          placeholder={languagePack.select_country}
          label={languagePack.country}
          value={event.country}
          options={countries}
          selection
          search
          onChange={eventHandlers.handleCountry}
        />
        <TFBInputWithWrapper
          value={event.club}
          label={languagePack.club}
          style={errors.fields && { border: redBorder }}
          onChange={eventHandlers.handleClub}
        />
      </div>
      <div className="form-row">
        <TFBInputWithWrapper
          value={event.fullName}
          style={errors.fields && { border: redBorder }}
          onChange={eventHandlers.handleFullName}
          label={languagePack.name_surname}
        />

        <TFBSelectWithWrapper
          label={languagePack.function}
          style={errors.fields && { control: { border: redBorder } }}
          onChange={eventHandlers.handleRole}
          languagePack={languagePack}
        />
      </div>
      <div className="form-row">
        <TFBInputWithWrapper
          label={languagePack.email_for_code}
          style={(errors.email || errors.fields) && { border: redBorder }}
          value={event.email}
          onChange={eventHandlers.handleEmail}
        />
        <TFBPhoneInputWithWrapper
          label={languagePack.phone}
          inputStyle={errors.phoneNumber && { border: redBorder }}
          defaultCountry={event.country ? upperCase(event.country) : "RO"}
          onChange={(phone) => eventHandlers.handlePhoneNumber(phone)}
        />
      </div>
      <div className="form-row">
        <TFBPasswordInputWithWrapper
          label={languagePack.password_min_8}
          value={event.password}
          style={errors.password && { border: redBorder }}
          onChange={eventHandlers.handlePassword}
        />
        <TFBPasswordInputWithWrapper
          label={languagePack.confirm_password}
          style={errors.password && { border: redBorder }}
          value={event.confirmPassword}
          onChange={eventHandlers.handleConfirmPassword}
        />
      </div>

      {/* =========== LOADER AND BUTTON ========== */}
      {/* ========================================*/}

      {loadingCreate && <CircularLoader />}

      {map(errors, (error) => error && <span className="text-danger"> {error} </span>)}

      <TFBButton
        color="green"
        renderIcon={() => <TFBIcon name="check" />}
        onClick={submitAccount}
      >
        {languagePack.create_account}
      </TFBButton>

      <span className="redirect-to-login-wrapper">
        {languagePack.have_already_account}{" "}
        <span
          className="redirect-to-login"
          onClick={() => history.push("/auth/login")}
        >
          {" "}
          {languagePack.login_here}
        </span>
      </span>
    </div>
  );
};

export default Form;
