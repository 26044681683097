import React, { useEffect, useReducer, useState } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import _ from "lodash";

function ReceiptsStats({ languagePack, token, currentUser }) {
  const initialOptions = {
    chart: {
      type: "bar",
      height: 450,

      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
        borderRadius: "3px",
      },
    },
    grid: {
      row: {
        colors: ["#f5f5f5", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    xaxis: {
      categories: [
        languagePack.month_1,
        languagePack.month_2,
        languagePack.month_3,
        languagePack.month_4,
        languagePack.month_5,
        languagePack.month_6,
        languagePack.month_7,
        languagePack.month_8,
        languagePack.month_9,
        languagePack.month_10,
        languagePack.month_11,
        languagePack.month_12,
      ],
      labels: {
        style: {
          colors: " #B2B2B2",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "9px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: " #B2B2B2",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "9px",
        },
        formatter: function (value) {
          return value.toLocaleString() + " RON";
        },
      },
    },
    colors: ["#00d948", "#666666"],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toLocaleString();
        },
      },
    },
  };

  const initialSeries = [
    {
      data: [3000, 5000, 2500, 6000, 4800, 0, 0, 0, 0, 0, 0, 0],
      name: "amount",
    },
  ];

  const initialState = {
    tableOptions: initialOptions,
    tableSeries: initialSeries,
    year: 2024,
    team: { value: 0, label: "All" },
  };

  function reducer(state, action) {
    switch (action.type) {
      case "changeYear":
        return { ...state, year: action.payload };
      case "changeTeam":
        return { ...state, team: action.payload };
      case "updateIncomes":
        return {
          ...state,
          tableSeries: [{ data: action.payload, name: "Încasări" }],
        };
      case "updateLanguage":
        return {
          ...state,
          tableOptions: {
            ...state.tableOptions,
            xaxis: {
              ...state.tableOptions.xaxis,
              categories: [
                languagePack.month_1,
                languagePack.month_2,
                languagePack.month_3,
                languagePack.month_4,
                languagePack.month_5,
                languagePack.month_6,
                languagePack.month_7,
                languagePack.month_8,
                languagePack.month_9,
                languagePack.month_10,
                languagePack.month_11,
                languagePack.month_12,
              ],
            },
          },
        };

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `training/get_club_teams?token=${token}&all=1&type=select`
  );

  const {
    data: monthlyPayments,
    refetch: refetchMonthlyPayments,
  } = useAxiosGet(
    `https://api.thefootballbrain.app/finances/get_data_for_chart_monthly?id_club=${currentUser.id_club}&year=${state.year}&id_team=${state.team.value}&token=${token}`
  );

  useEffect(() => {
    let payments = Array(12);

    _.forEach(monthlyPayments?.payments, (p, i) => {
      if (p.payment_month) {
        payments[p.payment_month - 1] = Number(p.total);
      }
    });

    _.forEach(payments, (el, i) => (el ? "" : (payments[i] = 0)));

    while (payments.length < 12) {
      payments.push(0);
    }

    dispatch({ type: "updateIncomes", payload: payments });
    dispatch({ type: "updateLanguage" });
  }, [monthlyPayments, languagePack]);

  useEffect(() => {
    refetchMonthlyPayments();
  }, [state.year]);

  return (
    <TFBCard style={{ marginBottom: "50px" }}>
      <TFBCardHeader title={languagePack.annual_receipts_stats}>
        <TFBSelect
          style={{
            component: { width: "250px" },
            control: { backgroundColor: "#666666" },
            placeholder: { color: "white" },

            valueContainer: { "& div": { color: "white !important" } },
          }}
          label={languagePack.team}
          name="team"
          value={state.team}
          options={teams?.list}
          onChange={(e, v) => dispatch({ type: "changeTeam", payload: e })}
        />
        <TFBDropdown
          selection={true}
          color="gray"
          style={{ width: "250px" }}
          label={languagePack.year}
          placeholder={languagePack.select_year + "..."}
          name="year"
          options={[
            { key: 0, value: 2024, text: "2024" },
            { key: 1, value: 2023, text: "2023" },
            { key: 2, value: 2022, text: "2022" },
            { key: 3, value: 2021, text: "2021" },
          ]}
          value={state.year}
          onChange={(e, v) =>
            dispatch({ type: "changeYear", payload: v.value })
          }
        />
      </TFBCardHeader>
      <TFBCardBody>
        <div>
          <Chart
            options={state.tableOptions}
            series={state.tableSeries}
            type="bar"
            height={350}
            width={"100%"}
          />
        </div>
        <div
          style={{
            color: "#000",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "11px",
          }}
        >
          {languagePack.total} {state.year}:{" "}
          {_.reduce(
            state.tableSeries[0].data,
            (acc, cur) => acc + cur
          ).toLocaleString()}{" "}
          RON
        </div>
      </TFBCardBody>
    </TFBCard>
  );
}
export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ReceiptsStats);
