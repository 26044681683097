import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Accordion, Icon, Button } from "semantic-ui-react";
import { logout } from "../../../../app/utils/api";
import { bindActionCreators } from "redux";
import axios from "axios";
import "../Medical.styles.scss";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import HealingIcon from "@material-ui/icons/Healing";
import * as myConstants from "../../../../app/utils/constants";
import EditInjuryForm from "./EditInjuryForm";
import { store } from "react-notifications-component";
import "./InjuriesHistory.scss";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import { useHistory } from "react-router-dom";

function InjuriesHistory(props) {
  const { languagePack } = props;
  const [injuries, setInjuries] = useState([]);
  const [treatmentPopup, setTreatmentPopup] = useState(false);
  const [activeInjury, setActiveInjury] = useState([]);
  const [activeTreatment, setActiveTreatment] = useState([]);
  const [treatmentTotal, setTreatmentTotal] = useState(0);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [rates, setRates] = useState([]);
  const [editInjuryForm, setEditInjuryForm] = useState(false);

  const history = useHistory();

  const fetchData = async () => {
    const result = await axios(
      `medical/get_injuries_history?token=${props.token}&id_club=${props.currentUser.id_club}`
    );
    if (result?.data?.go_to_login === 1) {
      props.logout(props.token);
    } else {
      setInjuries(Array.isArray(result.data) ? result.data : []);
    }
  };

  useEffect(() => {
    fetchData();
    // getCurrency();
  }, [props.injuriesHistoryKey]);

  function get_treatment(id_injury) {
    const fetchTreat = async () => {
      const result = await axios(
        `medical/get_injury_treatment?token=${props.token}&id_injury=${id_injury}`
      );
      if (result?.data?.go_to_login === 1) {
        props.logout(props.token);
      } else {
        setActiveTreatment(
          Array.isArray(result.data.treatments) ? result.data.treatments : []
        );
        setActiveInjury(result.data.injury_data);
        setTreatmentTotal(result.data.total);
      }
    };

    fetchTreat();
  }

  function formatNumber(value) {
    return (
      <NumberFormat
        // value={convertCurrency(value)}
        value={value}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  }

  function deleteInjury(value) {
    axios
      .post("medical/delete_injury", {
        id_injury: value,
      })
      .then(() => {
        setTreatmentPopup(false);
        fetchData();
        store.addNotification({
          title: this.props.languagePack["injury_details"],
          message: this.props.languagePack["injury_delete_success"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  }

  function expandRow(value) {
    if (activeIndexes.includes(value)) {
      setActiveIndexes((oldArray) => oldArray.filter((x) => x !== value));
    } else {
      setActiveIndexes((oldArray) => [...oldArray, value]);
    }
  }

  /* function getCurrency() {
    fetch("https://api.exchangeratesapi.io/latest")
      .then((res) => res.json())
      .then((data) => setRates(data.rates));
  }

  function convertCurrency(value) {
    if (props.currencyItemSelected.uniqueSlug === "EUR") {
      return value;
    } else {
      return value * rates[props.currencyItemSelected.uniqueSlug];
    }
  }

  const iconOsCurrency =
    props.currencyItemSelected.uniqueSlug === "EUR" ? (
      <Icon name="eur" className="green-eur-icon mg--0" />
    ) : (
      <span className="font-weight-bold"> RON </span>
    ); */

  const iconOsCurrency = <Icon name="eur" className="green-eur-icon mg--0" />;

  return (
    <div className="col-lg-6 col-xxl-6 widget">
      <div className="card card-custom">
        <div className="injuries-container-h">
          <div className="injuries-header">
            <div className="injuries-title">
              {languagePack["injuries_history"]}
            </div>
          </div>
          <div className="injuries-list">
            <div className="injury-row columns-names-injuries">
              <div className="injury-player-name">
                {languagePack["active_injuries_player_name"]}
              </div>
              <div className="injury-type">
                {languagePack["active_injuries_type"]}
              </div>
              <div className="injury-date">
                {languagePack["active_injuries_injury_date"]}
              </div>
              <div className="injury-estimated-recovery">
                {languagePack["absence_period"]}
              </div>
              <div className="injury-options"></div>
            </div>
            {injuries.map((row, index) => {
              return (
                <div className="injuries-history-container" key={"injury-history-item-" + index}>
                  <div className="injuries-history-content">
                    <div
                      className="injury-row columns-names-injuries"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div className="injury-player-name">
                        {languagePack["active_injuries_player_name"]}
                      </div>
                      <div className="injury-type">
                        {languagePack["active_injuries_type"]}
                      </div>
                      <div className="injury-date">
                        {languagePack["active_injuries_injury_date"]}
                      </div>
                      <div className="injury-estimated-recovery">
                        {languagePack["absence_period"]}
                      </div>
                      <div className="injury-options"></div>
                    </div>
                    <div
                      className="injury-row"
                      key={row.id_injury}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                      }}
                    >
                      <div
                        className="injury-player-name"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          history.push(`/club/player/${row.id_player}`)
                        }
                      >
                        {row.player_name}
                      </div>
                      <div className="injury-type">
                        {" "}
                        {row.another_zone == 1 ? row.injury_type : props.languagePack[row.injury_type]}
                      </div>
                      <div className="injury-date">
                        {" "}
                        <Moment format="DD.MM.YYYY">{row.injury_date}</Moment>
                      </div>
                      <div className="injury-estimated-recovery">
                        {row.absence_period == 1
                          ? row.absence_period +
                          " " +
                          languagePack["injury_day"]
                          : row.absence_period +
                          " " +
                          languagePack["injury_days"]}
                      </div>
                      <div className="injury-options">
                        <div
                          className="injury-see-more-button"
                          onClick={() => {
                            setTreatmentPopup(true);
                            get_treatment(row.id_injury);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <EyeIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Dialog
          open={treatmentPopup}
          // onClose={() => setTreatmentPopup(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <div className="injury-popup-item-subtitle">
            <CloseIcon
              onClick={() => setTreatmentPopup(false)}
              fontSize="large"
              style={{ cursor: "pointer" }}
            />
          </div>
          <DialogContent>
            <>
              <div className="injury-big-popup-title">
                {languagePack["injury_details"]}
              </div>
              {!editInjuryForm ? (
                <>
                  <div className="injury-big-popup-column-titles">
                    <div className="injury-column-title">
                      {languagePack["active_injuries_injury_date"]}
                    </div>
                    <div className="injury-column-title">
                      {languagePack["injury_zone"]}
                    </div>
                    <div className="injury-column-title">
                      {languagePack["return_date"]}
                    </div>
                  </div>
                  <div className="injury-big-popup-values cream-bg">
                    <div className="injury-column-title">
                      <Moment format="DD.MM.YYYY">
                        {activeInjury.date_start}
                      </Moment>
                    </div>
                    <div className="injury-column-title">
                    {activeInjury.another_zone == 1 ? activeInjury.name : props.languagePack[activeInjury.name]}
                    </div>
                    <div className="injury-column-title">
                      {activeInjury.date_end ? (
                        <Moment format="DD.MM.YYYY">
                          {activeInjury.date_end}
                        </Moment>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                  <div className="expanded-row-description">
                    {activeInjury.description}
                  </div>
                  <div className="injury-edit-delete-buttons">
                    <Button
                      className="custom-button-dual delete-button"
                      onClick={() => deleteInjury(activeInjury.id_injury)}
                    >
                      <div className="button-content">
                        {languagePack["admin_delete"]}
                        <Icon
                          name="trash alternate outline"
                          className="delete-icon"
                        />
                      </div>
                    </Button>
                    <Button
                      className="custom-button-dual edit-button"
                      onClick={() => setEditInjuryForm(true)}
                    >
                      <div className="button-content">
                        {languagePack["edit_details"]}
                        <Icon name="pencil" className="edit-icon" />
                      </div>
                    </Button>
                  </div>
                </>
              ) : (
                <div>
                  <EditInjuryForm
                    ended="1"
                    injury_name={activeInjury.another_zone == 1 ? activeInjury.name : props.languagePack[activeInjury.name]}
                    injury={activeInjury}
                    close_section={() => {
                      setEditInjuryForm(false);
                      get_treatment(activeInjury.id_injury);
                      fetchData();
                    }}
                    languagePack={props.languagePack}
                  />
                </div>
              )}
              <div className="injury-big-popup-subtitle">
                {languagePack["treatment"]}
              </div>

              {activeTreatment.length > 0 ? (
                activeTreatment.map((item, index) => {
                  return (
                    <div key={index}>
                      <Accordion exclusive={false}>
                        <Accordion.Title
                          style={{ padding: 0 }}
                          onClick={() => expandRow(index)}
                          active={activeIndexes.includes(index)}
                        >
                          <div
                            className="unexpanded-row"
                            style={{
                              backgroundColor:
                                index % 2 == 0 ? "#fafafa" : "white",
                            }}
                          >
                            <div className="unexpanded-row-title">
                              {item.treatment_name}
                            </div>
                            <div className="unexpanded-row-right">
                              <div className="unexpanded-row-cost">
                                {formatNumber(item.treatment_cost)}
                                {iconOsCurrency}
                              </div>
                              {activeIndexes.includes(index) ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </div>
                          </div>
                        </Accordion.Title>
                        <Accordion.Content
                          className="expanded-row"
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#fafafa" : "white",
                          }}
                          active={activeIndexes.includes(index)}
                        >
                          <div className="expanded-row-date">
                            <Moment format="DD.MM.YYYY">
                              {item.treatment_date_start}
                            </Moment>
                            -
                            <Moment format="DD.MM.YYYY">
                              {item.treatment_date_end}
                            </Moment>
                          </div>
                          <div className="expanded-row-description">
                            {item.treatment_description}
                          </div>
                        </Accordion.Content>
                      </Accordion>
                    </div>
                  );
                })
              ) : (
                <div style={{ textAlign: "center" }}>
                  <HealingIcon fontSize="large" />
                  <div style={{ fontSize: 15, paddingTop: 10 }}>
                    {languagePack["no_treatment_found"]}
                  </div>
                </div>
              )}
              {treatmentTotal > 0 ? (
                <div className="injury-big-popup-total-container">
                  <div className="injury-big-popup-total-text">
                    {languagePack["total_treatment_cost"]}
                  </div>
                  <div className="injury-big-popup-total-amount">
                    {formatNumber(treatmentTotal)}
                    {iconOsCurrency}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(InjuriesHistory);
