import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import _, { map } from "lodash";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { addMinutes } from "date-fns";

import { updateQuickSetup } from "./../../../utils/api";

import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTimePicker from "../../../components/design-system/TimePicker/TFBTimePicker";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBSearch from "../../../components/design-system/TFBSearch/TFBSearch";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import CircularLoader from "../../../components/reusable/Loader";

import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";

import styles from "./../Training.module.scss";
import {
  checkForFilterChange,
  convertMinToHrs,
  isTFB,
  prepareStringForCompare,
} from "../../../utils/utilFunctions";

import TrainingSectionBox from "../../../components/widgets/TrainingSection/TrainingSectionBox";
import EditTrainingSectionPopup from "../Sections/EditTrainingSectionPopup";
import ViewTrainingSectionPopup from "../Sections/ViewTrainingSectionPopup";
import TacticBoardPopup from "../TacticBoard/TacticBoardPopup";
import { TFBRangeSlider } from "../../../components/design-system/RangeSlider/TFBRangeSlider";

const AddTraining = (props) => {
  const { languagePack, currentUser, token, updateQuickSetup } = props;
  const { id_club } = currentUser;
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      teams: null,
      selected_sections: [],
      training_date: null,
      start_hour: null,
      end_hour: null,
      venue: null,
      new_venue_name: "",
      add_venue_to_mng: true,
      details: "",

      open_tactic_board: false,
      open_edit_section_popup: false,
      open_view_section_popup: false,

      section_name: "",
      added_by: "",
      date_added: "",
      img_src: "",
      video_src: "",
      section_description: "",

      move_section_mobile: false,
      mobileSections: [],
      selected_section_mobile: null,
      selected_section_mobile_index: null,

      age_group: [],
      section_type: [],
      accessibility: null,
      players_no: [0, 40],
      goalkeepers_no: [0, 5],
      range_filter_was_changed: false,

      page: 1,
      limit: 20,
      has_more: true,
      sections: [],

      search_value: "",
    }
  );

  const sectionListRef = useRef(null);
  const ageGroupString =
    _.size(state.age_group) > 0
      ? "1-" +
      _.join(
        _.map(state.age_group, (f) => (f ? f.value : "")),
        "_"
      ) +
      "~"
      : "";

  const sectionTypeString =
    _.size(state.section_type) > 0
      ? "2-" +
      _.join(
        _.map(state.section_type, (f) => (f ? f.value : "")),
        "_"
      ) +
      "~"
      : "";

  const accessibilityString = state.accessibility
    ? "3-" + state.accessibility?.value
    : "";

  const selectedFiltersString =
    ageGroupString + sectionTypeString + accessibilityString;

  const {
    data: sectionList,
    loading: isSectionListLoading,
    refetch: refetchSectionList,
  } = useAxiosGet(
    `training/get_training_sections_2?token=${token}&page=${state.page}&limit=${state.limit
    }&search_section_name=${prepareStringForCompare(state.search_value)}${selectedFiltersString ? `&tags=${selectedFiltersString}` : ""
    }${!_.includes(state.players_no, 0) || !_.includes(state.players_no, 40)
      ? `&no_of_players=${state.players_no.join("-")}`
      : ""
    }${!_.includes(state.goalkeepers_no, 0) ||
      !_.includes(state.goalkeepers_no, 5)
      ? `&no_of_gks=${state.goalkeepers_no.join("-")}`
      : ""
    }`,
    true
  );

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `training/get_club_teams?token=${token}&all=0&type=select`
  );

  const { data: venues, loading: isVenuesLoading } = useAxiosGet(
    `training/get_club_venues?id_club=${id_club}&add_venue=1&type=select`
  );

  const {
    data: sectionMobileList,
    loading: isSectionMobileListLoading,
  } = useAxiosGet(
    `training/get_training_sections_2?token=${token}&type=select`
  );

  const {
    data: filtersData,
    loading: loadingFilters,
    refetch: refetchFilters,
  } = useAxiosGet(
    `training/get_training_section_tag_nomenclature?token=${token}`
  );

  useEffect(() => {
    updateState({ mobileSections: sectionMobileList?.list });
  }, [sectionMobileList]);

  useEffect(() => {
    if (state.search_value == "") {
      refetchSectionList();
    }
  }, [state.search_value]);

  useEffect(() => {
    if (sectionList?.list) {
      if (_.size(sectionList.list) > 0) {
        if (state.page == 1) {
          updateState({ page: state.page + 1, sections: sectionList.list });
        } else {
          updateState({
            page: state.page + 1,
            sections: [...state.sections, ...sectionList.list],
          });
        }
      } else {
        if (state.page == 1) {
          updateState({ sections: [], has_more: false });
        } else {
          updateState({ has_more: false });
        }
      }
    }
  }, [sectionList]);

  const { loaderRef } = useInfiniteScroll({
    refetch:
      !isSectionListLoading && state.has_more ? refetchSectionList : () => { },
  });

  useEffect(() => {
    generateEndHour();
  }, [state.start_hour, state.selected_sections]);

  const {
    postData: addTrainingRequest,
    loading: loadingAddTrainingRequest,
  } = useAxiosPost("training/add_training");

  const handleAddTrainingResponse = (response) => {
    if (response?.success == 1) {
      history.push("/training/plan");
      showNotification(
        "success",
        languagePack.training_add_title,
        languagePack.training_success
      );
      let clubQuickSetup = response?.club_quick_setup;
      updateQuickSetup({
        ...clubQuickSetup,
        isSetupOpen: false,
      });
    } else {
      showNotification(
        "danger",
        languagePack.training_add_title,
        languagePack.training_fail
      );
    }
  };

  const addTraining = () => {
    const payload = {
      id_club: id_club,
      teams: state.teams,
      description: state.details,
      venue: state.venue?.value ?? null,
      training_date: moment(state.training_date).format("YYYY-MM-DD"),
      start_hour: moment(state.start_hour).format("HH:mm"),
      end_hour: moment(state.end_hour).format("HH:mm"),
      sections: _.map(state.selected_sections, (e) => ({
        id: e.id_training_section,
        content: e.section_name,
        section_description: e.custom_section_description,
        duration: e.duration,
        effective_duration: e.effective_duration,
      })),
      is_tfb: isTFB(),
    };

    if (state.venue?.value == 0) {
      payload["new_venue"] = state.new_venue_name;
      payload["add_venue_to_mng"] = { false: "0", true: "1" }[
        state.add_venue_to_mng
      ];
    }

    addTrainingRequest(payload, handleAddTrainingResponse);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        state[source.droppableId],
        source.index,
        destination.index
      );

      if (source.droppableId == "selected_sections") {
        updateState({ [source.droppableId]: items });
      }
    } else {
      const result = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination
      );
      updateState({
        [source.droppableId]: result[source.droppableId],
        [destination.droppableId]: result[destination.droppableId],
      });
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const section = {
      duration: "",
      effective_duration: "",
      custom_section_description: "",
      ...sourceClone[droppableSource.index],
    };

    destClone.splice(droppableDestination.index, 0, section);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const hanldeChangeSelect = (selected, value) => {
    updateState({ [value.name]: selected });
    if (selected?.index > -1) {
      updateState({ selected_section_mobile_index: selected?.index });
    }
  };
  const handleChangeFilter = (selected, value) => {
    updateState({ [value.name]: selected, page: 1 });
  };

  const handleChangeDuration = (index, field, value) => {
    const arrayClone = _.clone(state.selected_sections);
    arrayClone[index][field] = value;
    updateState({ selected_sections: arrayClone });
  };

  const handleRemoveSelectedSection = (index) => {
    let arrayClone = _.clone(state.selected_sections);
    _.remove(arrayClone, (e, i) => i == index);

    updateState({ selected_sections: arrayClone });
  };

  const getDuration = (field) => {
    return convertMinToHrs(calculateSumOfMinutes(field));
  };

  const calculateSumOfMinutes = (field) => {
    return _.sum(
      _.map(state.selected_sections, (e) => _.toNumber(e?.[field] ?? 0))
    );
  };

  function handleOpenViewSectionPopup(id) {
    updateState({
      open_view_section_popup: true,
      section_to_view: id,
    });
  }
  function handleCloseEditSectionPopup() {
    updateState({ open_edit_section_popup: false });
  }
  function handleCloseViewSectionPopup() {
    updateState({ open_view_section_popup: false });
  }

  const openTacticBoard = () => {
    updateState({ open_tactic_board: true });
  };

  const closeTacticBoard = () => {
    updateState({ open_tactic_board: false });
  };

  const openMoveSectionMobile = () => {
    updateState({ move_section_mobile: true });
  };

  const closeMoveSectionMobile = () => {
    updateState({
      move_section_mobile: false,
      selected_section_mobile: null,
      selected_section_mobile_index: null,
    });
  };

  const moveSectionMobile = () => {
    if (state.selected_section_mobile_index != null) {
      const result = move(
        state.mobileSections,
        state.selected_sections,
        {
          droppableId: "sections",
          index: state.selected_section_mobile_index,
        },
        {
          droppableId: "selected_sections",
          index: _.size(state.selected_sections),
        }
      );

      updateState({
        selected_sections: result.selected_sections,
        sections: result.sections,
      });
    }

    closeMoveSectionMobile();
  };

  const generateEndHour = () => {
    if (state.start_hour) {
      updateState({
        end_hour:
          calculateSumOfMinutes("duration") > 0
            ? addMinutes(state.start_hour, calculateSumOfMinutes("duration"))
            : state.end_hour,
      });
    }
  };

  const hasEndHourError = () => {
    return (
      state.start_hour &&
      state.end_hour &&
      moment(state.end_hour).format("HH:mm") <
      moment(state.start_hour).format("HH:mm")
    );
  };

  function handleChangeNote(index, curId, note) {
    updateState({
      selected_sections: _.map(state.selected_sections, (s, i) =>
        s.id_training_section === curId && index === i
          ? { ...s, custom_section_description: note }
          : s
      ),
    });
  }
  const refreshList = () => {
    updateState({ sections: [], page: 1, has_more: true });
    // (() => refetchSectionList())();
  };
  const applyFilters = () => {
    refreshList();
    if (sectionListRef?.current) {
      sectionListRef.current.scrollTop = 0;
    }
  };

  const resetFilters = () => {
    updateState({
      search_value: "",
      page: 1,
      has_more: true,
      accessibility: null,
      section_type: [],
      age_group: [],
      players_no: [0, 40],
      goalkeepers_no: [0, 5],
      range_filter_was_changed: false,
    });
    if (sectionListRef?.current) {
      sectionListRef.current.scrollTop = 0;
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={`row ${styles.rowCustomMacBook}`}>
        <div className="col-lg-6 col-lg-padding">
          <TFBCard classNameInner={styles.cardCustomMacBook}>
            <TFBCardHeader title={languagePack["create_training"]} />
            <TFBSelect
              label={languagePack.team}
              placeholder={languagePack.team}
              isMulti={true}
              options={teams?.list ?? []}
              value={state.teams}
              name="teams"
              onChange={(selected, value) =>
                hanldeChangeSelect(selected, value)
              }
              isLoading={isTeamsLoading}
              style={{ component: { marginBottom: 20 } }}
            />
            <TFBCardHeader title={languagePack["add_training_sections"]} />
            <div className={`${styles.onlyMobile}`}>
              {!state.move_section_mobile ? (
                <TFBButton
                  color="green"
                  renderIcon={() => <TFBIcon name="add" />}
                  onClick={openMoveSectionMobile}
                  style={{ width: "100%", marginBottom: 20 }}
                >
                  {languagePack["add_section"]}
                </TFBButton>
              ) : (
                <div className={`${styles.addSectionContainer}`}>
                  <TFBSelect
                    label={languagePack.section_name}
                    placeholder={languagePack.section_name}
                    value={state.selected_section_mobile}
                    options={map(state.mobileSections, (e, index) => ({
                      ...e,
                      index: index,
                      id: e.value,
                      content: e.label,
                      section_description: e.section_description,
                    }))}
                    name="selected_section_mobile"
                    onChange={(selected, value) =>
                      hanldeChangeSelect(selected, value)
                    }
                    isLoading={isSectionMobileListLoading}
                  />
                  <TFBIconButton
                    name="add"
                    color="green"
                    className={styles.addSectionBtn}
                    disabled={state.selected_section_mobile == null}
                    onClick={moveSectionMobile}
                  />
                  <TFBIconButton
                    name="cancel"
                    color="darkGray"
                    className={styles.addSectionBtn}
                    onClick={closeMoveSectionMobile}
                  />
                </div>
              )}
            </div>
            <Droppable droppableId="selected_sections">
              {(provided, snapshot) => (
                <div
                  className={styles.sectionsDragContainer}
                  ref={provided.innerRef}
                >
                  {_.size(state.selected_sections) > 0 &&
                    _.map(state.selected_sections, (section, index) => {
                      return (
                        <Draggable
                          key={"selected-section-" + index}
                          draggableId={"selected-section-" + index}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <TrainingSectionBox
                                key={
                                  "selected-section-" +
                                  index +
                                  section.id_training_section
                                }
                                tags={_.map(
                                  section.tags.list,
                                  (tag) => tag.label
                                )}
                                onClickView={() =>
                                  handleOpenViewSectionPopup(
                                    section.id_training_section
                                  )
                                }
                                playersNo={section.no_of_players}
                                gksNo={section.no_of_gks}
                                showAddNote={true}
                                sectionId={section.id_training_section}
                                note={section.custom_section_description}
                                onChangeNote={handleChangeNote}
                                onClickRemove={() =>
                                  handleRemoveSelectedSection(index)
                                }
                                showRemoveFromTraining={true}
                                name={section.section_name}
                                imgSrc={section.section_img}
                                imgSrcFromCanvas={section.has_section_img_from_canvas ? section.section_img_from_canvas : null}
                                hasDurations={true}
                                index={index}
                              >
                                <div className={styles.durationInput}>
                                  <label className={styles.durationInputLabel}>
                                    {languagePack.total_duration}
                                  </label>
                                  <TFBInput
                                    placeholder="min"
                                    value={section.duration ?? ""}
                                    makeStylesContent={{
                                      "&  input": {
                                        paddingTop: "0px !important",
                                        paddingBottom: "0px !important",
                                        paddingLeft: "5px !important",
                                        borderRadius: "3px",
                                        height: "35px",
                                      },
                                      "&  input::placeholder": {
                                        fontSize: "11px",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        minHeight: "30px",
                                      },
                                    }}
                                    style={{
                                      backgroundColor: "#FAFAFA",
                                      height: "35px",
                                      ...(parseFloat(
                                        section.effective_duration
                                      ) > parseFloat(section.duration)
                                        ? { borderColor: "red" }
                                        : {}),
                                    }}
                                    name="duration"
                                    type="number"
                                    onChange={(e) =>
                                      handleChangeDuration(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                                <div className={styles.durationInput}>
                                  <label className={styles.durationInputLabel}>
                                    {languagePack.effective_duration}
                                  </label>
                                  <TFBInput
                                    placeholder="min"
                                    value={section.effective_duration ?? ""}
                                    makeStylesContent={{
                                      "&  input": {
                                        paddingTop: "0px !important",
                                        paddingBottom: "0px !important",
                                        paddingLeft: "5px !important",
                                        borderRadius: "3px",
                                        height: "35px",
                                        pointerEvents: "auto !important",
                                      },
                                      "& .MuiFormControl-root.MuiTextField-root": {
                                        border: "none !important",
                                      },
                                      "&  input::placeholder": {
                                        fontSize: "11px",
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        minHeight: "30px",
                                      },
                                    }}
                                    style={{
                                      flexGrow: 4,
                                      backgroundColor: "#FAFAFA",
                                      height: "35px",
                                      ...(parseFloat(
                                        section.effective_duration
                                      ) > parseFloat(section.duration)
                                        ? { borderColor: "red" }
                                        : {}),
                                    }}
                                    name="effective_duration"
                                    type="number"
                                    onChange={(e) =>
                                      handleChangeDuration(
                                        index,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </TrainingSectionBox>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}

                  <TableContainer>
                    <Table className={styles.table}>
                      <TableBody>
                        <TableRow
                          key={"section-all"}
                          className={styles.totalRow}
                        >
                          <TFBTableCell
                            className={styles.tableCellTotal}
                          >{`${languagePack.total_training_duration} (${languagePack.effective_duration})`}</TFBTableCell>
                          <TFBTableCell
                            className={`${styles.tableCellTotal} ${styles.tableCellTotalDuration}`}
                            align="right"
                          >{`${getDuration("duration")} (${getDuration(
                            "effective_duration"
                          )})`}</TFBTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <TFBCardHeader title={languagePack["training_details"]} />
            <div className={`df df-gap-10 ${styles.trainingDateTime}`}>
              <TFBDatePicker
                label={languagePack.training_date}
                placeholder={languagePack.training_date}
                value={state.training_date}
                onChange={(value) => updateState({ training_date: value })}
              />
              <TFBTimePicker
                label={languagePack.start_hour}
                placeholder={languagePack.start_hour}
                value={state.start_hour}
                onChange={(value) => updateState({ start_hour: value })}
              />
              <TFBTimePicker
                label={languagePack.end_hour}
                placeholder={languagePack.end_hour}
                value={state.end_hour}
                onChange={(value) => updateState({ end_hour: value })}
                {...(hasEndHourError() && { style: { borderColor: "red" } })}
              />
            </div>
            {hasEndHourError() && (
              <div className="error-message">
                {languagePack["hours_error_training"]}
              </div>
            )}
            <TFBSelect
              label={languagePack.select_match_venue}
              placeholder={languagePack.select_match_venue}
              options={_.map(venues?.list ?? [], (e) =>
                e.value == 0 ? { ...e, label: languagePack.add_venue } : e
              )}
              value={
                state.venue?.value == 0
                  ? { ...state.venue, label: languagePack.add_venue }
                  : state.venue
              }
              name="venue"
              onChange={(selected, value) =>
                hanldeChangeSelect(selected, value)
              }
              style={{ component: { marginTop: 30 } }}
              isClearable={true}
              isLoading={isVenuesLoading}
            />
            {state.venue?.value == 0 && (
              <div style={{ marginTop: 20 }}>
                <TFBInput
                  label={languagePack.enter_new_location}
                  placeholder={languagePack.enter_new_location}
                  value={state.new_venue_name ?? ""}
                  style={{ backgroundColor: "#fafafa", width: "100%" }}
                  componentStyle={{ width: "100%", marginBottom: 20 }}
                  onChange={(e) =>
                    updateState({ new_venue_name: e.target.value })
                  }
                />
                <TFBCheckbox
                  label={languagePack.add_location_to_field_management}
                  checked={state.add_venue_to_mng}
                  onChange={(e) =>
                    updateState({ add_venue_to_mng: e.target.checked })
                  }
                />
              </div>
            )}
            <TFBTextArea
              label={languagePack.training_description_details}
              value={state.details ?? ""}
              onChange={(e) => updateState({ details: e.target.value })}
              componentStyle={{ marginTop: 30, marginBottom: 20 }}
            />
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="check" />}
              style={{ width: "100%" }}
              onClick={addTraining}
              disabled={
                _.size(state.teams) == 0 ||
                (state.venue?.value == 0 &&
                  _.size(_.trim(state.new_venue_name)) == 0) ||
                state.start_hour == null ||
                state.end_hour == null ||
                state.training_date == null ||
                hasEndHourError()
              }
              loading={loadingAddTrainingRequest}
            >
              {languagePack.save_training}
            </TFBButton>
          </TFBCard>
          {state.open_edit_section_popup && (
            <EditTrainingSectionPopup
              addedBy={state.added_by}
              title={state.section_name}
              closePopup={handleCloseEditSectionPopup}
            />
          )}
          {state.open_view_section_popup && (
            <ViewTrainingSectionPopup
              sectionId={state.section_to_view}
              closePopup={handleCloseViewSectionPopup}
            />
          )}
          {state.open_tactic_board && (
            <TacticBoardPopup
              openPopup={state.open_tactic_board}
              closePopup={closeTacticBoard}
            />
          )}
        </div>
        <div className={`${styles.onlyDesktop} col-lg-6 col-lg-padding`}>
          <TFBCard classNameInner={styles.cardCustomMacBook}>
            <TFBCardHeader title={languagePack["training_sections"]} />
            <div
              className={styles.searchAndFiltersDiv}
              style={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <TFBSearch
                classNameComponent={styles.searchBarDiv}
                placeholder={languagePack.find_training_section}
                fluid
                showNoResults={false}
                value={state.search_value}
                onSearchChange={(e) =>
                  updateState({
                    search_value: e.target.value,
                    page: 1,
                    has_more: true,
                  })
                }
              />
              <div
                className={styles.filtersDiv}
                style={{ flexDirection: "column" }}
              >
                <TFBSelect
                  style={{ container: { width: "100%" } }}
                  label={languagePack.age_group}
                  isMulti={true}
                  options={filtersData?.list[0].children ?? []}
                  value={state.age_group}
                  name="age_group"
                  onChange={(selected, value) =>
                    handleChangeFilter(selected, value)
                  }
                />
                <TFBSelect
                  style={{ container: { width: "100%" } }}
                  label={languagePack.section_type}
                  isMulti={true}
                  options={filtersData?.list[1].children ?? []}
                  value={state.section_type}
                  name="section_type"
                  onChange={(selected, value) =>
                    handleChangeFilter(selected, value)
                  }
                />
                <TFBSelect
                  style={{ container: { width: "100%" } }}
                  label={languagePack.accessibility}
                  options={filtersData?.list[2].children ?? []}
                  value={state.accessibility}
                  name="accessibility"
                  onChange={(selected, value) =>
                    handleChangeFilter(selected, value)
                  }
                />
                <div className={styles.filterRangesDivAddTraining}>
                  <div className={styles.playersNoDiv}>
                    <div className={styles.rangeSliderLabel}>
                      {languagePack.number_of_players}
                    </div>
                    <TFBRangeSlider
                      min={0}
                      max={40}
                      value={state.players_no}
                      onChange={(e, value) => {
                        updateState({
                          players_no: value,
                          range_filter_was_changed: true,
                        });
                      }}
                      valueLabelDisplay="on"
                    />
                  </div>{" "}
                  <div className={styles.goalkeepersNoDiv}>
                    <div className={styles.rangeSliderLabel}>
                      {languagePack.number_of_goalkeepers}
                    </div>
                    <TFBRangeSlider
                      min={0}
                      max={5}
                      value={state.goalkeepers_no}
                      onChange={(e, value) =>
                        updateState({
                          goalkeepers_no: value,
                          range_filter_was_changed: true,
                        })
                      }
                      valueLabelDisplay="on"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.searchBarAndFilter}>
                {checkForFilterChange(state) && (
                  <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="check" />}
                    onClick={applyFilters}
                    className={styles.fullWidth}
                  >
                    {languagePack["apply_filter"]}
                  </TFBButton>
                )}
                {checkForFilterChange(state) && (
                  <TFBButton
                    color="lightGray"
                    renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                    onClick={resetFilters}
                    className={styles.fullWidth}
                  >
                    {languagePack["cancel_filter"]}
                  </TFBButton>
                )}
              </div>
            </div>
            <Droppable
              droppableId="sections"
              isDropDisabled
              direction="horizontal"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={styles.sectionsAllDragContainer}
                >
                  <div ref={sectionListRef} className={styles.sectionList}>
                    {_.size(state.sections) > 0 && (
                      <div className={styles.sections}>
                        {_.map(state.sections, (row, index) => (
                          <Draggable
                            key={"section-" + index}
                            draggableId={"section-" + index}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TrainingSectionBox
                                  onClickView={() =>
                                    handleOpenViewSectionPopup(
                                      row.id_training_section
                                    )
                                  }
                                  playersNo={row.no_of_players}
                                  gksNo={row.no_of_gks}
                                  tags={_.map(
                                    row.tags.list,
                                    (tag) => tag.label
                                  )}
                                  sectionId={row.id_training_section}
                                  showAddNote={false}
                                  name={row.section_name}
                                  imgSrc={row.section_img}
                                  imgSrcFromCanvas={row.has_section_img_from_canvas ? row.section_img_from_canvas : null}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                    )}
                    {<div ref={loaderRef} style={{ marginBottom: 10 }}></div>}
                    {isSectionListLoading && <CircularLoader />}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            {_.size(state.sections) == 0 && isSectionListLoading == false && (
              <TFBPlaceholder text={languagePack.no_training_sections} />
            )}
          </TFBCard>
        </div>
      </div>
    </DragDropContext>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddTraining);
