import React, { createContext, useContext, useReducer } from "react";
import _ from "lodash";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { baseUrl, prepareStringForCompare } from "../../../utils/utilFunctions";

const PressContext = createContext({});

function PressProvider({ children }) {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      openArticleDialog: false,
      articleToOpen: "",
      query: "",
    }
  );

  const { data: articleList, loading: loadingArticles } = useAxiosGet(
    baseUrl("/scrape/rapid.php")
  );
  const filteredArticleList = _.filter(
    articleList,
    (art) =>
      prepareStringForCompare(art.title).includes(
        prepareStringForCompare(state.query)
      ) ||
      art.labels.some((label) =>
        prepareStringForCompare(label).includes(
          prepareStringForCompare(state.query)
        )
      ) ||
      prepareStringForCompare(art.content).includes(
        prepareStringForCompare(state.query)
      ) ||
      prepareStringForCompare(art.link).includes(
        prepareStringForCompare(state.query)
      ) ||
      prepareStringForCompare(art.author?.name).includes(
        prepareStringForCompare(state.query)
      ) ||
      prepareStringForCompare(art.description).includes(
        prepareStringForCompare(state.query)
      ) ||
      prepareStringForCompare(art.source).includes(
        prepareStringForCompare(state.query)
      )
  );
  const handleSearch = (e) => {
    updateState({ query: e.target.value });
  };

  const handleCloseArticle = () => {
    updateState({ openArticleDialog: false, articleToOpen: "" });
  };

  const handleClickTag = (e, tag) => {
    e.stopPropagation();
    updateState({ query: tag });
  };
  const handleClickAuthor = (e, authorName) => {
    e.stopPropagation();

    updateState({ openArticleDialog: false, query: authorName });
  };
  const handleClickSource = (e, source) => {
    e.stopPropagation();
    updateState({ openArticleDialog: false, query: source });
  };
  const handleClickArticleBox = (article) => {
    updateState({
      articleToOpen: article,
      openArticleDialog: true,
    });
  };
  const handleClickNextArticle = () => {
    const newIndex =
      _.indexOf(
        _.size(state.query) > 2 ? filteredArticleList : articleList,
        state.articleToOpen
      ) + 1;

    updateState({
      articleToOpen:
        _.size(state.query) > 2
          ? filteredArticleList[newIndex]
          : articleList[newIndex] ?? state.articleToOpen,
    });
  };
  const handleClickPreviousArticle = () => {
    const newIndex =
      _.indexOf(
        _.size(state.query) > 2 ? filteredArticleList : articleList,
        state.articleToOpen
      ) - 1;

    updateState({
      articleToOpen:
        _.size(state.query) > 2
          ? filteredArticleList[newIndex]
          : articleList[newIndex] ?? state.articleToOpen,
    });
  };

  const value = {
    articleList,
    filteredArticleList,
    loadingArticles,
    openArticleDialog: state.openArticleDialog,
    articleToOpen: state.articleToOpen,
    query: state.query,
    onSearch: handleSearch,
    onCloseArticle: handleCloseArticle,
    onClickTag: handleClickTag,
    onClickAuthor: handleClickAuthor,
    onClickSource: handleClickSource,
    onClickArticleBox: handleClickArticleBox,
    onClickNextArticle: handleClickNextArticle,
    onClickPreviousArticle: handleClickPreviousArticle,
  };
  return (
    <PressContext.Provider value={value}>{children}</PressContext.Provider>
  );
}

function usePress() {
  const context = useContext(PressContext);
  if (context === undefined) {
    console.error("PressContext was used outside of the PressProvider");
    throw new Error("PressContext was used outside of the PressProvider");
  }
  return context;
}
export { PressProvider, usePress };
