import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import TFBIconButton from "../../design-system/IconButton/TFBIconButton";
import TFBInput from "../../design-system/Input/TFBInput";
import styles from "./TrainingSectionBox.module.scss";
import { TTrainingSectionBox } from "../../design-system/types";

function TrainingSectionBox({
  onClickView,
  onClickDelete,
  onClickEdit,
  onClickRemove,
  onClickOpenTrainingBoard,
  showRemoveFromTraining = false,
  showAddNote = false,
  showEdit = false,
  draggableRef,
  note,
  onChangeNote,
  sectionId,

  tags,
  playersNo,
  gksNo,

  name,
  style,
  children,
  imgSrc,
  imgSrcFromCanvas,
  showDelete = false,

  languagePack,
  draggableProps,
  dragHandleProps,

  index,
}: TTrainingSectionBox) {
  function handleAddNote(e: any) {
    onChangeNote && onChangeNote(index ?? null, sectionId, e.target?.value);
  }

  function moveElementFirst(arr: any[], elem: any | any[]) {
    if (arr.includes(elem)) {
      arr.unshift(
        arr.splice(
          arr.findIndex((el: any) => el === elem),
          1
        )[0]
      );
      return arr;
    } else return arr;
  }

  return (
    <div
      {...dragHandleProps}
      {...draggableProps}
      className={styles.container}
      style={{ height: showAddNote ? "fit-content" : "", ...style }}
      ref={draggableRef}
    >
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src =
            "/media/dummy-content/placeholder-sectiune-antrenament.png";
        }}
        src={imgSrcFromCanvas || (imgSrc ? imgSrc : "/media/dummy-content/placeholder-sectiune-antrenament.png")}
        alt="as"
        className={styles.sectionImg}
      />
      <div
        className={styles.bigDiv}
        style={{
          gap: showAddNote ? "9px" : "",
        }}
      >
        <div
          className={styles.contents}
          style={{
            alignItems: showAddNote ? "flex-end" : "",
            paddingBottom: showAddNote ? "0px" : "",
          }}
        >
          <div
            style={{ minWidth: children ? "none" : "179px" }}
            className={styles.info}
          >
            <span className={styles.title}>
              {_.size(name) > 40 ? name.slice(0, 39) + "..." : name}
            </span>
            {/* ///// LABLELS //////// */}
            <div className={styles.characteristics}>
              {playersNo ? (
                <div className={styles.characteristic}>
                  {playersNo} {languagePack.players} {gksNo}{" "}
                  {languagePack.goalkeepers}
                </div>
              ) : (
                ""
              )}
              {_.map(
                tags?.includes("Public" as never)
                  ? moveElementFirst(tags, "Public")
                  : tags?.includes("Privat" as never)
                    ? moveElementFirst(tags, "Privat")
                    : tags,
                (tag, i) => (
                  <div
                    key={i}
                    className={
                      tag === "Public"
                        ? styles.publicChar
                        : tag === "Privat"
                          ? styles.privateChar
                          : styles.characteristic
                    }
                  >
                    {tag}
                  </div>
                )
              )}
            </div>
          </div>
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src =
                "/media/dummy-content/placeholder-sectiune-antrenament.png";
            }}
            src={imgSrcFromCanvas || (imgSrc ? imgSrc : "/media/dummy-content/placeholder-sectiune-antrenament.png")}
            alt="as"
            className={styles.sectionImgMobile}
          />

          {children}
          <div className={styles.buttonsDiv}>
            {showEdit && (
              <TFBIconButton
                classNameIcon={styles.editButtonIcon}
                name={"training-board"}
                color="darkGray"
                onClick={onClickOpenTrainingBoard}
              />
            )}
            {showEdit && (
              <TFBIconButton
                classNameIcon={styles.editButtonIcon}
                name={"edit"}
                color="darkGray"
                onClick={onClickEdit}
              />
            )}
            <TFBIconButton
              classNameIcon={styles.viewButtonIcon}
              name={"view"}
              color="darkGray"
              onClick={onClickView}
            />
            {(showDelete || showRemoveFromTraining) && (
              <TFBIconButton
                classNameIcon={
                  showDelete ? styles.deleteButtonIcon : styles.removeButtonIcon
                }
                name={showDelete ? "delete" : "x-large"}
                color="darkGray"
                onClick={showDelete ? onClickDelete : onClickRemove}
              />
            )}
          </div>
        </div>
        {showAddNote && (
          <div className={styles.note}>
            <TFBInput
              multiline={true}
              placeholder={languagePack.Add_note + "..."}
              value={note}
              makeStylesContent={{
                "&  input": {
                  paddingTop: "0px !important",
                  paddingBottom: "0px !important",
                  paddingLeft: "5px !important",
                  borderRadius: "3px",

                  width: "100%",
                },
                "&  input::placeholder": {
                  fontSize: "11px",
                },
                "& .MuiOutlinedInput-root": {
                  minHeight: "30px",
                  padding: "6px",
                  paddingLeft: "12px",
                },
                "& .MuiFormControl-root": {
                  innerWidth: "100%",
                },
              }}
              style={{
                backgroundColor: "#FAFAFA",
                minHeight: "35px",
                width: "100%",
                flexGrow: "3",
              }}
              type="text"
              //@ts-ignore onChange type error
              onChange={handleAddNote}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default connect(({ layoutService, auth }: any) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(TrainingSectionBox);
