import React, { createContext, useEffect, useState } from 'react';

import useAxiosGet from '../../../components/customHooks/useAxiosGet';
import { arrowLineList } from './utils/toolsNomenclature';

export const BoardContext = createContext();

export const BoardProvider = ({ children, token, id_training_section, section_name, closePopup, refreshList, setIsCanvasDirty }) => {
    const [step, setStep] = useState(null);
    const [pitchSelection, setPitchSelection] = useState(null);

    const [showGrid, setShowGrid] = useState(false);
    const [headerMode, setHeaderMode] = useState(1);
    const [isDragging, setIsDragging] = useState(false);
    const [isInCanvas, setIsInCanvas] = useState(false);

    const [selectedShape, setSelectedShape] = useState(null);
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedColorPallet, setSelectedColorPallet] = useState({ 1: "green", 2: "green", 3: "green", 4: "black" });

    const [selectedShapeLabel, setSelectedShapeLabel] = useState("");
    const [activeInput, setActiveInput] = useState(false);

    const [fontSize, setFontSize] = useState(14); //10, 12, 14, 18, 24, 30

    const { data: initialBoardData, loading: loadingInitialBoardData } = useAxiosGet(`training/get_training_section_board?token=${token}&id_training_section=${id_training_section}`);

    useEffect(() => {
        if (!loadingInitialBoardData) {
            if (initialBoardData?.json) {
                const backgroundImage = initialBoardData.json?.backgroundImage ?? null;
                if (backgroundImage) {
                    let pitch = null;
                    if (backgroundImage?.customProps?.id_pitch) {
                        setShowGrid(backgroundImage.customProps.show_grid);
                        pitch = {
                            img_url: backgroundImage.customProps.img_url,
                            img_url_grid: backgroundImage.customProps.img_url_grid,
                            id_pitch: backgroundImage.customProps.id_pitch,
                        }
                    }
                    setPitchSelection(pitch)
                }
            }
        }
    }, [loadingInitialBoardData])

    useEffect(() => {
        if (pitchSelection) {
            setStep(2);
        }
    }, [pitchSelection])

    useEffect(() => {
        if (["square", "rectangle", "circle", "oval"].includes(selectedShape)) {
            setSelectedColor(selectedColorPallet["1"]);
        } else if (arrowLineList.includes(selectedShape)) {
            setSelectedColor(selectedColorPallet["4"]);
        }
    }, [selectedShape, selectedColorPallet]);

    const handleSelectedColor = (group, color) => {
        setSelectedColorPallet({ ...selectedColorPallet, [group]: color })
    }

    const handleShowGrid = () => {
        setShowGrid(!showGrid);
    }

    const handleIsInCanvas = (e) => {
        const canvas = document.getElementById('canvasId');
        if (canvas) {
            const rect = canvas.getBoundingClientRect();
            const isInCanvas = e.clientX >= rect.left && e.clientX <= rect.right && e.clientY >= rect.top && e.clientY <= rect.bottom;
            setIsInCanvas(isInCanvas)
        }
    }

    const saveCanvas = () => {
        const canvas = document.getElementById('canvasId');
    }

    useEffect(() => {
        document.addEventListener('mousemove', handleIsInCanvas);

        return () => {
            document.removeEventListener('mousemove', handleIsInCanvas);
        };
    }, []);

    return (
        <BoardContext.Provider value={{
            isDragging, setIsDragging,
            isInCanvas, setIsInCanvas,
            selectedShape, setSelectedShape,
            selectedColor, setSelectedColor: handleSelectedColor,
            selectedColorPallet, setSelectedColorPallet,
            showGrid, handleShowGrid,
            headerMode, setHeaderMode,
            selectedShapeLabel, setSelectedShapeLabel,
            activeInput, setActiveInput,
            pitchSelection, setPitchSelection,
            step, setStep,
            saveCanvas,
            loadingInitialBoardData, initialBoardData,
            id_training_section, section_name,
            closeTacticBoard: closePopup,
            refreshList,
            setIsCanvasDirty,
            fontSize, setFontSize,
        }}>
            {children}
        </BoardContext.Provider>
    );
};
