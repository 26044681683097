import React, { useState, useEffect } from "react";
import axios from "axios";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Avatar from "@material-ui/core/Avatar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import "./TopMvPlayers.scss";
import { connect } from "react-redux";

function TopMvPlayers(props) {
  const [players, setPlayers] = useState([]);
  const { languagePack } = props;

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        `club/top_market_increases?id_club=${props.currentUser.id_club}`
      );
      if (result?.data?.go_to_login === 1) {
        props.logout(props.token);
      } else {
        setPlayers(Array(result.data) ? result.data : []);
      }
    };

    fetchData();
  }, []);

  function intlFormat(num) {
    return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
  }
  function makeFriendly(num) {
    if (num >= 1000000) return intlFormat(num / 1000000) + "M";
    if (num >= 1000) return intlFormat(num / 1000) + "Th.";
    return intlFormat(num);
  }

  return (
    <div className="card card-custom mv-container">
      <div className="mv-increase-title">
        <div className="mv-title-text">
          {languagePack["market_value_updates"]}
        </div>
      </div>
      <div className="row mv-row ">
        <div
          className="player-name mv-title-column"
          style={{ alignSelf: "center" }}
        >
          {languagePack["PLAYER_NAME"]}
        </div>
        <div className="difference mv-title-column">
          {languagePack["increase"]}
        </div>
        <div className="current-mv mv-title-column">
          {languagePack["current_value"]}
        </div>
      </div>
      {players.map((item, index) => {
        return (
          <div
            className="row mv-row "
            key={item.id_player}
            style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
          >
            <div className="player-name">
              <div>
                <Avatar
                  src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                />
              </div>
              <div className="name">{item.player_name}</div>
            </div>
            <div className="difference">
              <div className="number">
                {"\u20AC" + makeFriendly(item.difference)}
              </div>
              <SVG src={toAbsoluteUrl("/media/svg/icons/increase-arrow.svg")} />
            </div>
            <div className="current-mv">
              {"\u20AC" + makeFriendly(item.player_market_value)}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TopMvPlayers);
