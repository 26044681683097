import React from "react";
import axios from "axios";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Icon } from "semantic-ui-react";
import "../Club.styles.scss";
import "./PlayerProfile.scss";
import { connect } from "react-redux";
import moment from "moment";

class ContractWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractData: [],
      bonuses: [],
    };
  }

  componentDidMount = () => {
    this.getContractData();
    // this.getBonuses();
  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.id_season !== this.props.id_season) {
  //     this.getContractData();
  //     // this.getBonuses();
  //   }
  // }

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  };

  getContractData = () => {
    axios
      .get(
        `player/get_contract_data?id_player=${this.props.id_player}&id_season=${this.props.id_season}`
      )
      .then((res) =>
        this.setState(
          {
            contractData: res.data,
          },
          () => {
            this.getBonuses(this.state.contractData?.id_player_contract);
          }
        )
      )
      .catch((err) => console.log(err));
  };

  getBonuses = (id) => {
    axios
      .get(
        `upload/get_bonuses?id_player=${this.props.id_player}&id_season=${this.props.id_season}&id_contract=${id}`
      )
      .then((res) =>
        this.setState({
          bonuses: res.data.bonuses,
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="contract-info-profile-container">
        <div className="contract-info-title-container">
          <Icon name="file alternate outline" size="large" color="black" />
          <div className="contract-info-title">
            {languagePack.contract_details}
          </div>
        </div>
        <div className="contract-financial-notifications">
          {Number(this.props.basicInfo.loaned_out) === 1 &&
            !moment(this.props.basicInfo.loaned_until).isBefore(moment()) && (
              <div className="notification-container yellow-card">
                <div className="image-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlSpace="preserve"
                    width={23}
                    height={25}
                    style={{
                      shapeRendering: "geometricPrecision",
                      textRendering: "geometricPrecision",
                      imageRendering: "optimizeQuality",
                      fillRule: "evenodd",
                      clipRule: "evenodd",
                    }}
                    viewBox="0 0 561694 606658"
                  >
                    <defs>
                      <style>
                        {".fil0-player-loan{fill:#2b2a29;fill-rule:nonzero}"}
                      </style>
                    </defs>
                    <g id="Layer_x0020_1">
                      <g id="_1629328656704">
                        <path
                          className="fil0-player-loan"
                          d="M205669 255549c20038 0 36302 16265 36302 36303s-16264 36303-36302 36303v-72606zm-169338 0h169338v72606H36331v-72606zm0 72606c-20038 0-36302-16265-36302-36303s16264-36303 36302-36303v72606z"
                        />
                        <path
                          className="fil0-player-loan"
                          d="M70519 206326c14150-14150 37189-14150 51339 0 14149 14150 14149 37189 0 51338l-51339-51338zm-59857 59857 59857-59857 51339 51338-59857 59857H10662v-51338zm51339 51338c-14150 14150-37189 14150-51339 0-14149-14149-14149-37189 0-51338l51339 51338zm8518 59829-59857-59829 51339-51338 59857 59828-51339 51339zm51339-51339c14149 14150 14149 37189 0 51339-14150 14149-37189 14149-51339 0l51339-51339zM36331 59800v72605C16293 132405 29 116141 29 96103c0-20039 16264-36303 36302-36303zm169338 0v72605H36331V59800h169338zm0 72605V59800c20038 0 36302 16264 36302 36303 0 20038-16264 36302-36302 36302z"
                        />
                        <path
                          className="fil0-player-loan"
                          d="m171481 10605-51339 51339c-14149-14150-14149-37189 0-51339 14150-14150 37189-14150 51339 0zm59857 59828v51339h-51339l-59857-59828 51339-51339 59857 59828zm-51339 51339 51339-51339c14149 14150 14149 37189 0 51339-14150 14149-37189 14149-51339 0zm-8518 59857-51339-51339 59857-59857 51339 51339-59857 59857zm-51339-51339 51339 51339c-14150 14149-37189 14149-51339 0-14149-14150-14149-37189 0-51339z"
                        />
                        <path
                          d="m321666 393986-56541 16265-200952 57856c-7375 2001-13978 6660-18266 13606-9118 14836-4116 34502 11291 43992 19896 11520 43078 18323 67861 18752l356568-29c13064 0 24355-7489 29843-18437 8118-17237 12635-36474 12635-56798 0-19495-4174-38047-11692-54769l-2487-4773c-2772-5460-5917-10691-9375-15665M312690 289708c62916 26556 133921 26556 196836 0M321666 393986c57170 24126 121686 24126 178885 0m0 0 24840-288879H296797l24869 288879m-37618 13235 7175 24869m-60400-9548 7175 24869m-60371-9576 7146 24869m269470 81753v25898m-65860-25898v25898m-130547-25898v25898m-65860-25898v25898m-65888-25898v25898m-68890-44650h0z"
                          style={{
                            fill: "none",
                            stroke: "#2b2a29",
                            strokeWidth: 72605.7,
                            strokeLinecap: "round",
                            strokeLinejoin: "round",
                          }}
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="notification-text">
                  {languagePack.on_loan}
                  <span className="bold-word">
                    {this.props.basicInfo.loaned_team}
                  </span>
                  {languagePack.until_loan}
                  <span className="bold-word">
                    {moment(this.props.basicInfo.loaned_until).format(
                      "DD.MM.yyyy"
                    )}
                  </span>
                </div>
              </div>
            )}
          {this.props.basicInfo["16_years_old_alert"] > -1 && (
            <div className="notification-container red-card">
              <div className="image-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  width={23}
                  height={27}
                  style={{
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    imageRendering: "optimizeQuality",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                  }}
                  viewBox="0 0 6982476 8121718"
                >
                  <defs>
                    <style>
                      {
                        ".str0-16-years{stroke:#2b2a29;stroke-width:910820;stroke-linecap:round;stroke-linejoin:round}.fil1-16-years{fill:none}.fil0-16-years{fill:#2b2a29;fill-rule:nonzero}"
                      }
                    </style>
                  </defs>
                  <g id="Layer_x0020_1">
                    <g id="_1629158374096">
                      <path
                        className="fil0-16-years"
                        d="M5616604 3567976H1365872v-910820h4250732v910820zm1365872 455052h-910820l-359-23667-1793-22950-2869-21515-4303-22950-4661-21516-6096-20439-7889-23309-7889-19722-8248-18288-10758-20440-11116-19722-12192-19006-12192-17212-13268-16854-15061-17212-16495-17213-15419-14702-15061-13268-18647-14343-18288-13268-16854-10758-21157-11833-20798-11117-16854-7889-20798-7889-22950-7889-20798-6096-21515-4661-22591-4303-21516-2869-22950-1793-23667-359v-910820l69567 1793 69566 5379 68850 8606 67056 12192 65981 15420 65981 19005 60960 20798 62395 24384 63470 28688 57375 29763 55581 31914 57016 36576 52713 37652 50203 39445 51637 44107 47692 46258 43748 45900 42314 48768 41238 51996 38728 54506 35142 54864 32631 56299 30122 58450 28329 62036 24384 62753 20798 61319 19005 65623 15420 66697 12192 66698 8606 68850 5379 69566 1793 69567zm-910820 3643280V4023028h910820v3643280l-455410 455410-455410-455410zm910820 0c0 251372-204038 455410-455410 455410s-455410-204038-455410-455410h910820zM455410 7210898h6071656v910820H455410L0 7666308l455410-455410zm0 910820C204038 8121718 0 7917680 0 7666308s204038-455410 455410-455410v910820zm455410-4098690v3643280H0V4023028h910820zm455052-1365872v910820l-23667 359-23309 2151-21515 2511-22233 4303-21874 4661-20440 6096-23308 7889-20440 7889-17929 8248-20081 10758-21157 11833-16854 10758-18288 13268-17571 13626-15778 13985-16854 15778-15419 16137-13985 16136-12909 16854-13268 18288-12909 20081-10758 18647-9682 18647-8965 20081-7889 19722-7889 23309-6096 20439-4662 21516-4303 22950-2868 21515-1793 22950-359 23667H0l1793-69567 5379-69566 8606-68850 12192-66698 15419-66697 19006-65623 20798-61319 24384-62753 27612-60243 30480-60243 33707-57375 34425-53788 37652-53430 40879-51996 43390-49844 44824-46976 46975-45182 50203-43389 51278-40163 52713-37652 57016-36576 55581-31914 58092-30122 63112-28329 62036-24384 61319-20798 65622-19005 66340-15420 66697-12192 69567-8965 69208-5020 69567-1793z"
                      />
                      <path
                        className="fil1-16-years str0-16-years"
                        d="M455410 4630480v-607452c0-500951 409152-910462 910462-910462h4250732c500593 0 910462 409511 910462 910462v607452h-782445M455410 4630480v1138525c0 418117 341378 759137 758778 759137 417758 0 759136-341020 759136-759137v-379389M3491238 5389258c0 417758 341378 759136 758778 759136 417758 0 759136-341378 759136-759136"
                      />
                      <path
                        className="fil1-16-years str0-16-years"
                        d="M5009152 5389258c0-417758 341378-759136 782086-758778M1973324 5389616c0-417758 341378-759136 758778-759136 417758 0 759136 341378 759136 759136"
                      />
                      <path
                        className="fil0-16-years"
                        d="M3951310 3112208c0 251372-206190 455410-460072 455410s-460072-204038-460072-455410h920144zm0-909745v909745h-920144v-909745h920144zm-920144 0c0-251372 206190-455410 460072-455410s460072 204038 460072 455410h-920144zM3764843 698534c200810 150967 241331 436764 90364 637933-150966 201169-436763 241690-637574 90724l547210-728657zm-880699 60961h910820l-358-3586v-3586l358 3945-358-4304-718-3944-358-3227-1076-4662 717 1793-1793-5379v-1434l359 1793-1793-5738 1434 3945-2510-5379-2151-5379 358 717 359 717-1435-2510-2868-4661-1793-3228-1076-1076 2869 3945-6096-7530-2869-4304 3944 4662-3227-3586-3227-2869-3228-3227 359 359-3227-2510-717-718-547210 728657-18646-14344-20440-16854-16137-13985-19722-18288-19005-18647-15061-15778-17571-19364-10399-12550-17213-20799-19005-25459-10040-13985-13268-19006-13268-21157-14344-24025-11475-21157-9682-18647-8964-18646-11475-25460-10399-25460-6455-16854-8965-25819-6096-19722-6455-22233-6813-26177-4303-19722-5379-26177-3944-25460-3586-25460-2510-26536-1076-18288-1434-25819-359-25818zm333489-668054 547210 728656 717-718 3227-2510-359 359 3228-3227 3227-2869 3227-3586v359l-3944 4303 2869-3945 3586-4303-359 359 3227-4662-358 717 2868-5020 2869-5020-1793 3586-358 717 2151-5379 1434-2869-358 1076 1793-5379-359 1434 717-2868 1076-3586-717 1793 1076-4662 358-3227 718-3945 358-4303-358 3945v-3586l358-3586h-910820l359-25819 1434-25818 1076-18288 2510-26536 3586-25460 3944-25460 5379-26177 4303-19722 6096-24385 7172-24384 6096-19364 8965-26177 7530-19005 9324-22950 11475-25460 8964-18647 8248-15778 12909-24384 14344-23667 11116-17929 15420-22591 12550-17571 16495-21516 17213-21157 10399-12192 14343-16136 18289-19364 19005-18647 19722-18288 16137-13985 20440-16854 18646-14343zm0 0c200811-150967 486608-110446 637574 90723 150967 201169 110446 486966-90364 637933L3217633 91441z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="notification-text">
                {"Implineste "}
                <span className="bold-word">{" 16 ani "}</span>
                {this.props.basicInfo["16_years_old_alert"] == 0 ? (
                  "astazi"
                ) : (
                  <>
                    {"in "}
                    <span className="bold-word">
                      {this.props.basicInfo["16_years_old_alert"] +
                        (this.props.basicInfo["16_years_old_alert"] == 1
                          ? " zi"
                          : " zile")}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
          {this.props.basicInfo["days_after_16"] > -1 && (
            <div className="notification-container red-card">
              <div className="image-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  width={23}
                  height={27}
                  style={{
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    imageRendering: "optimizeQuality",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                  }}
                  viewBox="0 0 6982476 8121718"
                >
                  <defs>
                    <style>
                      {
                        ".str0-16-years{stroke:#2b2a29;stroke-width:910820;stroke-linecap:round;stroke-linejoin:round}.fil1-16-years{fill:none}.fil0-16-years{fill:#2b2a29;fill-rule:nonzero}"
                      }
                    </style>
                  </defs>
                  <g id="Layer_x0020_1">
                    <g id="_1629158374096">
                      <path
                        className="fil0-16-years"
                        d="M5616604 3567976H1365872v-910820h4250732v910820zm1365872 455052h-910820l-359-23667-1793-22950-2869-21515-4303-22950-4661-21516-6096-20439-7889-23309-7889-19722-8248-18288-10758-20440-11116-19722-12192-19006-12192-17212-13268-16854-15061-17212-16495-17213-15419-14702-15061-13268-18647-14343-18288-13268-16854-10758-21157-11833-20798-11117-16854-7889-20798-7889-22950-7889-20798-6096-21515-4661-22591-4303-21516-2869-22950-1793-23667-359v-910820l69567 1793 69566 5379 68850 8606 67056 12192 65981 15420 65981 19005 60960 20798 62395 24384 63470 28688 57375 29763 55581 31914 57016 36576 52713 37652 50203 39445 51637 44107 47692 46258 43748 45900 42314 48768 41238 51996 38728 54506 35142 54864 32631 56299 30122 58450 28329 62036 24384 62753 20798 61319 19005 65623 15420 66697 12192 66698 8606 68850 5379 69566 1793 69567zm-910820 3643280V4023028h910820v3643280l-455410 455410-455410-455410zm910820 0c0 251372-204038 455410-455410 455410s-455410-204038-455410-455410h910820zM455410 7210898h6071656v910820H455410L0 7666308l455410-455410zm0 910820C204038 8121718 0 7917680 0 7666308s204038-455410 455410-455410v910820zm455410-4098690v3643280H0V4023028h910820zm455052-1365872v910820l-23667 359-23309 2151-21515 2511-22233 4303-21874 4661-20440 6096-23308 7889-20440 7889-17929 8248-20081 10758-21157 11833-16854 10758-18288 13268-17571 13626-15778 13985-16854 15778-15419 16137-13985 16136-12909 16854-13268 18288-12909 20081-10758 18647-9682 18647-8965 20081-7889 19722-7889 23309-6096 20439-4662 21516-4303 22950-2868 21515-1793 22950-359 23667H0l1793-69567 5379-69566 8606-68850 12192-66698 15419-66697 19006-65623 20798-61319 24384-62753 27612-60243 30480-60243 33707-57375 34425-53788 37652-53430 40879-51996 43390-49844 44824-46976 46975-45182 50203-43389 51278-40163 52713-37652 57016-36576 55581-31914 58092-30122 63112-28329 62036-24384 61319-20798 65622-19005 66340-15420 66697-12192 69567-8965 69208-5020 69567-1793z"
                      />
                      <path
                        className="fil1-16-years str0-16-years"
                        d="M455410 4630480v-607452c0-500951 409152-910462 910462-910462h4250732c500593 0 910462 409511 910462 910462v607452h-782445M455410 4630480v1138525c0 418117 341378 759137 758778 759137 417758 0 759136-341020 759136-759137v-379389M3491238 5389258c0 417758 341378 759136 758778 759136 417758 0 759136-341378 759136-759136"
                      />
                      <path
                        className="fil1-16-years str0-16-years"
                        d="M5009152 5389258c0-417758 341378-759136 782086-758778M1973324 5389616c0-417758 341378-759136 758778-759136 417758 0 759136 341378 759136 759136"
                      />
                      <path
                        className="fil0-16-years"
                        d="M3951310 3112208c0 251372-206190 455410-460072 455410s-460072-204038-460072-455410h920144zm0-909745v909745h-920144v-909745h920144zm-920144 0c0-251372 206190-455410 460072-455410s460072 204038 460072 455410h-920144zM3764843 698534c200810 150967 241331 436764 90364 637933-150966 201169-436763 241690-637574 90724l547210-728657zm-880699 60961h910820l-358-3586v-3586l358 3945-358-4304-718-3944-358-3227-1076-4662 717 1793-1793-5379v-1434l359 1793-1793-5738 1434 3945-2510-5379-2151-5379 358 717 359 717-1435-2510-2868-4661-1793-3228-1076-1076 2869 3945-6096-7530-2869-4304 3944 4662-3227-3586-3227-2869-3228-3227 359 359-3227-2510-717-718-547210 728657-18646-14344-20440-16854-16137-13985-19722-18288-19005-18647-15061-15778-17571-19364-10399-12550-17213-20799-19005-25459-10040-13985-13268-19006-13268-21157-14344-24025-11475-21157-9682-18647-8964-18646-11475-25460-10399-25460-6455-16854-8965-25819-6096-19722-6455-22233-6813-26177-4303-19722-5379-26177-3944-25460-3586-25460-2510-26536-1076-18288-1434-25819-359-25818zm333489-668054 547210 728656 717-718 3227-2510-359 359 3228-3227 3227-2869 3227-3586v359l-3944 4303 2869-3945 3586-4303-359 359 3227-4662-358 717 2868-5020 2869-5020-1793 3586-358 717 2151-5379 1434-2869-358 1076 1793-5379-359 1434 717-2868 1076-3586-717 1793 1076-4662 358-3227 718-3945 358-4303-358 3945v-3586l358-3586h-910820l359-25819 1434-25818 1076-18288 2510-26536 3586-25460 3944-25460 5379-26177 4303-19722 6096-24385 7172-24384 6096-19364 8965-26177 7530-19005 9324-22950 11475-25460 8964-18647 8248-15778 12909-24384 14344-23667 11116-17929 15420-22591 12550-17571 16495-21516 17213-21157 10399-12192 14343-16136 18289-19364 19005-18647 19722-18288 16137-13985 20440-16854 18646-14343zm0 0c200811-150967 486608-110446 637574 90723 150967 201169 110446 486966-90364 637933L3217633 91441z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="notification-text">
                {"A implinit "}
                <span className="bold-word">{" 16 ani "}</span>
                {"in urma cu "}
                <span className="bold-word">
                  {this.props.basicInfo["days_after_16"] +
                    (this.props.basicInfo["days_after_16"] == 1
                      ? " zi"
                      : " zile")}
                </span>
              </div>
            </div>
          )}
          {this.props.basicInfo["19_years_old_alert"] > -1 && (
            <div className="notification-container red-card">
              <div className="image-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  width={23}
                  height={22}
                  style={{
                    shapeRendering: "geometricPrecision",
                    textRendering: "geometricPrecision",
                    imageRendering: "optimizeQuality",
                    fillRule: "evenodd",
                    clipRule: "evenodd",
                  }}
                  viewBox="0 0 345676 334014"
                >
                  <defs>
                    <style>
                      {".fil1-19-years{fill:#2b2a29;fill-rule:nonzero}"}
                    </style>
                  </defs>
                  <g id="Layer_x0020_1">
                    <path
                      d="m196616 201942-35112 10101-124794 35929c-4580 1242-8680 4136-11343 8449-5663 9214-2556 21427 7012 27320 12355 7154 26751 11379 42142 11645l221433-18c8113 0 15125-4651 18533-11449 5041-10705 7846-22651 7846-35273 0-12106-2592-23627-7260-34012l-1545-2964c-1722-3391-3674-6639-5822-9728m-116664-64758c39072 16491 83167 16491 122238 0m-116664 64758c35504 14982 75569 14982 111090 0m0 0 15426-179398H181173l15443 179398m-23361 8219 4456 15444m-37509-5929 4456 15444m-37492-5947 4438 15444m167344 50769v16083m-40899-16083v16083m-81072-16083v16083m-40899-16083v16083m-40918-16083v16083m-42781-27728h0z"
                      style={{
                        fill: "none",
                        stroke: "#2b2a29",
                        strokeWidth: 45089,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                      }}
                    />
                    <g id="_1629332209216">
                      <path
                        className="fil1-19-years"
                        d="M49474 138551h45089c0 12444-10101 22545-22545 22545s-22544-10101-22544-22545zm0-105160h45089v105160H49474V33391zm45089 0H49474c0-12444 10100-22545 22544-22545s22545 10101 22545 22545z"
                      />
                      <path
                        className="fil1-19-years"
                        d="m18905 54622 31882 31881c-8787 8787-23095 8787-31882 0s-8787-23094 0-31881zm37172-37172h31882v31882L50787 86503 18905 54622l37172-37172zm31882 31882L56077 17450c8787-8787 23095-8787 31882 0s8787 23095 0 31882zm37154 5290L93231 86503 56077 49332l31882-31882 37154 37172zM93231 86503l31882-31881c8787 8787 8787 23094 0 31881s-23095 8787-31882 0z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="notification-text">
                {"Termina "}
                <span className="bold-word">{" junioratul "}</span>
                {this.props.basicInfo["19_years_old_alert"] == 0 ? (
                  "astazi"
                ) : (
                  <>
                    {"in "}
                    <span className="bold-word">
                      {this.props.basicInfo["19_years_old_alert"] +
                        (this.props.basicInfo["19_years_old_alert"] == 1
                          ? " zi"
                          : " zile")}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {this.props.hasFinancialAccess ? (
          this.state.contractData.has_active_contract == 1 ? (
            <>
              <div className="contract-info-subtitle">
                {languagePack.active_contract}:
              </div>
              <div className="active-contract-row">
                <div className="active-contract-date">
                  {languagePack.starting_date}
                </div>
                <div className="active-contract-date">
                  {languagePack.expiry_date}
                </div>
                <div className="active-contract-wage">
                  {languagePack.monthly_wage}
                </div>
              </div>
              <div className="active-contract-row cream-bg">
                <div className="active-contract-date">
                  {this.state.contractData.contract_start ? (
                    <Moment format="DD.MM.YYYY">
                      {this.state.contractData.contract_start}
                    </Moment>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="active-contract-date">
                  {this.state.contractData.contract_end ? (
                    <Moment format="DD.MM.YYYY">
                      {this.state.contractData.contract_end}
                    </Moment>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="active-contract-wage">
                  {this.state.contractData.monthly_wage > 0 ? (
                    <>
                      {this.formatNumber(this.state.contractData.monthly_wage)}
                      {this.state.contractData.currency == "EUR" ? (
                        <Icon name="euro" color="black" />
                      ) : (
                        " RON"
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div
                className="contract-info-subtitle"
                style={{ paddingTop: 20 }}
              >
                {languagePack.active_bonuses}:
              </div>
              <div className="bonuses-container">
                {this.state.bonuses.length > 0 ? (
                  <div>
                    {this.state.bonuses.map((item, index) => {
                      return (
                        <div
                          className="contract-widget-bonus"
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                          key={"contract-widget-bonus-" + index}
                        >
                          <div className="bonus-title">{item.condition}</div>
                          <div className="bonus-element">
                            {this.formatNumber(item.amount)}{" "}
                            {item.currency == "EUR" ? (
                              <Icon name="euro" color="black" />
                            ) : (
                              " RON"
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div style={{ alignSelf: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      <Icon name="search minus" size="huge" />
                    </div>
                    <div className="placeholder-bonuses">
                      {languagePack.placeholder_bonuses}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="no-access-placeholder player-no-contract">
              <Icon name="search minus" color="black" size="big" />
              <div className="no-access-text">
                {"Nu are nici un contract activ"}
                {/* {languagePack["section_access_forbidden"]} */}
              </div>
            </div>
          )
        ) : (
          <div className="no-access-placeholder player-no-contract">
            <Icon name="lock" color="black" size="big" />
            <div className="no-access-text">
              {languagePack["section_access_forbidden"]}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ContractWidget);
