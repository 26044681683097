import React from 'react';
import ImpressionsVsClicks from "./ImpressionsVsClicks";
import LikesDislikesChart from "./LikesDislikesChart";
import PageFans from "./PageFans";
import PostsDistribution from "./PostsDistribution";
import AgeGenderChart from "./AgeGenderChart";
import ClickDistributionChart from "./ClickDistributionChart";
import ClicksOnPage from "./ClicksOnPage";
import FacebookGeneralNumbers from "./FacebookGeneralNumbers";
import FollowersDistribution from './FollowersDistribution';
import FacebookFeedback from './FacebookFeedback';

export default function FacebookDashboard(props) {
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <FacebookGeneralNumbers year={props.year} id_network={props.id_network} />
                </div>
                <div className="col-lg-6">
                    <LikesDislikesChart year={props.year} id_network={props.id_network} />
                </div>
            </div>
            <div className="row" style={{ marginTop: 25 }}>
                <div className="col-lg-6">

                    <FollowersDistribution id_network={props.id_network} />
                </div>
                <div className="col-lg-6">
                    {/* <ClicksOnPage year={this.state.year} /> */}
                    <PageFans id_network={props.id_network} />
                </div>
            </div>
            <div className="row" style={{ marginTop: 25 }}>
                <div className="col-lg-6">
                    <ImpressionsVsClicks year={props.year} id_network={props.id_network} />
                </div>
                <div className="col-lg-6">

                    <AgeGenderChart year={props.year} id_network={props.id_network} />
                </div>
            </div>
            <div className="row" style={{ marginTop: 25 }}>
                <div className="col-lg-6">
                    <FacebookFeedback year={props.year} id_network={props.id_network} />
                </div>
                <div className="col-lg-6">
                    <ClickDistributionChart year={props.year} id_network={props.id_network} />
                </div>
            </div>
            <div className="row" style={{ marginTop: 25 }}>
                <div className="col-lg-6">
                    <PostsDistribution year={props.year} id_network={props.id_network} />
                </div>
            </div>
        </>
    )
}