import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { map, size, union, slice, last } from "lodash";

import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { TableFooter } from "@mui/material";
import { Popup } from "semantic-ui-react";

import styles from "./Documents.module.scss";

import { baseUrl, getSVGName } from "../../utils/utilFunctions";
import useAxiosGet from "../../components/customHooks/useAxiosGet";
import { TFBTableCell, TFBTablePagination, TFBTableRow, sxStickyColumn } from "../../components/reusable/useStyles";
import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import CircularLoader from "../../components/reusable/Loader";

import AddFolder from "./Files/AddFolder";
import EditFolder from "./Files/EditFolder";
import DeleteFolder from "./Files/DeleteFolder";
import AddFile from "./Files/AddFile";
import EditDocument from "./Files/EditDocument";
import DeleteDocument from "./Files/DeleteDocument";

const Documents = (props) => {

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            files: [],
            route_path: [],

            open_edit_folder: false,
            editing_folder: {},

            open_delete_folder: false,
            deleting_folder: {},

            open_edit_document: false,
            editing_document: {},

            open_delete_document: false,
            deleting_document: {},

            open_upload_file: false,
            open_create_folder: false,

            page: 0,
            limit: 15,
        }
    );

    const { languagePack, token } = props;

    const {
        data: homeFiles,
        loading: isHomeFilesLoading,
        refetch: refetchHomeFiles
    } = useAxiosGet(`upload/get_documents?token=${token}`);

    useEffect(() => {
        if (homeFiles?.list) {
            updateState({ files: homeFiles.list });
        }
    }, [homeFiles])

    const {
        data: folderFiles,
        loading: isFolderFilesLoading,
        refetch: refetchFolderFiles
    } = useAxiosGet(`upload/get_folder_documents?token=${token}&id_folder=${size(state.route_path) > 0 ? last(state.route_path).id_folder : ""}`, true);

    useEffect(() => {
        if (folderFiles?.list) {
            updateState({ files: folderFiles.list });
        }
    }, [folderFiles])

    useEffect(() => {
        if (size(state.route_path) > 0) {
            refetchFolderFiles();
        } else {
            refetchHomeFiles();
        }
    }, [state.route_path])

    const openFolder = (folder) => {
        const { id_folder, folder_name } = folder;
        updateState({ route_path: union(state.route_path, [{ id_folder, folder_name }]) });
    }

    const selectFolderFromRoutePath = (index = null) => {
        if (index != null) {
            updateState({ route_path: slice(state.route_path, 0, index + 1) })
        } else {
            updateState({ route_path: [] })
        }
    }

    const openEditFolder = (folder) => {
        updateState({ open_edit_folder: true, editing_folder: folder })
    }

    const closeEditFolder = () => {
        updateState({ open_edit_folder: false, editing_folder: {} })
    }

    const openDeleteFolder = (folder) => {
        updateState({ open_delete_folder: true, deleting_folder: folder })
    }

    const closeDeleteFolder = () => {
        updateState({ open_delete_folder: false, deleting_folder: {} })
    }

    const openEditDocument = (document) => {
        updateState({ open_edit_document: true, editing_document: document })
    }

    const closeEditDocument = () => {
        updateState({ open_edit_document: false, editing_document: {} })
    }

    const openDeleteDocument = (document) => {
        updateState({ open_delete_document: true, deleting_document: document })
    }

    const closeDeleteDocument = () => {
        updateState({ open_delete_document: false, deleting_document: {} })
    }

    const openUploadFile = () => {
        updateState({ open_upload_file: true })
        closeAddFileFolderPopup();
    }

    const closeUploadFile = () => {
        updateState({ open_upload_file: false })
    }

    const openCreateFolder = () => {
        updateState({ open_create_folder: true })
        closeAddFileFolderPopup();
    }

    const closeCreateFolder = () => {
        updateState({ open_create_folder: false })
    }

    const closeAddFileFolderPopup = () => {
        const modal = document.getElementById("add-menu-document");
        if (modal) {
            modal.classList.remove("visible");
        }
    }

    const handleChangePage = (event, newPage) => {
        updateState({ page: newPage });
    };

    const emptyRows = state.page > 0 ? Math.max(0, (1 + state.page) * state.limit - size(state.files)) : 0;

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack.documents}>
                <Popup
                    trigger={<TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="add" />}
                        startIcon
                    >
                        {languagePack.Add}
                    </TFBButton>}
                    content={
                        <div className={styles.addContainer}>
                            <TFBButton
                                color="green"
                                renderIcon={() => <TFBIcon name="arrow-up" />}
                                startIcon
                                onClick={openUploadFile}
                            >
                                {languagePack.add_new_file}
                            </TFBButton>
                            <TFBButton
                                color="green"
                                renderIcon={() => <TFBIcon name="add" />}
                                startIcon
                                onClick={openCreateFolder}
                            >
                                {languagePack.create_new_folder}
                            </TFBButton>
                        </div>
                    }
                    on="click"
                    position="bottom right"
                    id={"add-menu-document"}
                />
            </TFBCardHeader>
            <TFBCardBody>
                {!isHomeFilesLoading ?
                    <>
                        {size(state.route_path) > 0 &&
                            <div className={styles.routePath}>
                                <div className={styles.routeElem} onClick={selectFolderFromRoutePath}>
                                    <div className={styles.routeName}>Home</div>
                                </div>
                                {map(state.route_path, (e, index) => (
                                    <div key={"route-" + index} className={styles.routeElem} onClick={() => selectFolderFromRoutePath(index)}>
                                        <TFBIcon name="angle-right" color="gray" className={styles.navIcon} />
                                        <div className={`${styles.routeName} ${size(state.route_path) - 1 == index ? styles.selectedRoute : ""}`}>{e.folder_name}</div>
                                    </div>
                                ))}
                            </div>
                        }
                        {!isFolderFilesLoading ?
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TFBTableRow>
                                                <TFBTableCell sx={sxStickyColumn}>
                                                    {languagePack.file_name}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {languagePack.added_by}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {languagePack.date_add}
                                                </TFBTableCell>
                                                <TFBTableCell align="right">
                                                    {languagePack.product_actions}
                                                </TFBTableCell>
                                            </TFBTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {map(slice(state.files, state.page * state.limit, state.page * state.limit + state.limit), (row, index) => (row.obj_type == "folder" ?
                                                <TFBTableRow key={"folder-" + index}>
                                                    <TFBTableCell sx={sxStickyColumn}>
                                                        <div
                                                            className={styles.fileName}
                                                            onClick={() => openFolder(row)}
                                                        >
                                                            <img
                                                                className={styles.icon}
                                                                src={`assets/SVG/folder.svg`}
                                                            />
                                                            {row.folder_name}
                                                        </div>
                                                    </TFBTableCell>
                                                    <TFBTableCell align="center">{row?.added_by?.user?.full_name ?? ""}</TFBTableCell>
                                                    <TFBTableCell align="center">
                                                        {moment(row.date_add).format("DD.MM.YYYY, HH:mm")}
                                                    </TFBTableCell>
                                                    <TFBTableCell align="center">
                                                        <div
                                                            className="actions-btns-container"
                                                            style={{ justifyContent: "flex-end" }}
                                                        >
                                                            <TFBIconButton
                                                                name="view"
                                                                color="darkGray"
                                                                onClick={() => openFolder(row)}
                                                            />
                                                            <TFBIconButton
                                                                name="edit"
                                                                color="darkGray"
                                                                onClick={() => openEditFolder(row)}
                                                            />
                                                            <TFBIconButton
                                                                name="delete"
                                                                color="darkGray"
                                                                onClick={() => openDeleteFolder(row)}
                                                            />
                                                        </div>
                                                    </TFBTableCell>
                                                </TFBTableRow> :
                                                <TFBTableRow key={"doc-" + index}>
                                                    <TFBTableCell sx={sxStickyColumn}>
                                                        <div className={styles.fileName}>
                                                            <img
                                                                className={styles.icon}
                                                                src={`assets/SVG/${getSVGName(row.type)}`}
                                                            />
                                                            <a href={baseUrl(`upload/download_document_from_entity_profile?id_document=${row.id_document}&token=${token}`)}>
                                                                {row.document_name}
                                                            </a>
                                                        </div>
                                                    </TFBTableCell>
                                                    <TFBTableCell align="center">{row.uploaded_by}</TFBTableCell>
                                                    <TFBTableCell align="center">
                                                        {moment(row.date_add).format("DD.MM.YYYY, HH:mm")}
                                                    </TFBTableCell>
                                                    <TFBTableCell align="center">
                                                        <div
                                                            className="actions-btns-container"
                                                            style={{ justifyContent: "flex-end" }}
                                                        >
                                                            <a href={baseUrl(`upload/download_document_from_entity_profile?id_document=${row.id_document}&token=${token}`)}
                                                            >
                                                                <TFBIconButton
                                                                    name="arrow-down"
                                                                    color="lightGray"
                                                                />
                                                            </a>
                                                            <TFBIconButton
                                                                name="edit"
                                                                color="darkGray"
                                                                onClick={() => openEditDocument(row)}
                                                            />
                                                            <TFBIconButton
                                                                name="delete"
                                                                color="darkGray"
                                                                onClick={() => openDeleteDocument(row)}
                                                            />
                                                        </div>
                                                    </TFBTableCell>
                                                </TFBTableRow>
                                            ))}
                                            {emptyRows > 0 && (
                                                <TFBTableRow style={{ height: 67 * emptyRows, backgroundColor: "white" }}>
                                                    <TFBTableCell colSpan={4} aria-hidden></TFBTableCell>
                                                </TFBTableRow>
                                            )}
                                        </TableBody>
                                        {size(state.files) > 0 &&
                                            <TableFooter>
                                                <TFBTableRow style={{ backgroundColor: size(state.files) % 2 == 1 ? "white" : "#fafafa" }}>
                                                    <TFBTablePagination
                                                        colSpan={4}
                                                        count={size(state.files)}
                                                        rowsPerPageOptions={[state.limit]}
                                                        rowsPerPage={state.limit}
                                                        page={state.page}
                                                        showFirstButton
                                                        showLastButton
                                                        onPageChange={handleChangePage}
                                                    />
                                                </TFBTableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </TableContainer>
                                {size(state.files) == 0 &&
                                    <TFBPlaceholder text={size(state.route_path) > 0 ? languagePack.empty_folder : languagePack.no_documents} />}
                            </> :
                            <CircularLoader />
                        }
                    </> :
                    <CircularLoader />
                }

                {state.open_edit_folder &&
                    <EditFolder
                        openPopup={state.open_edit_folder}
                        closePopup={closeEditFolder}
                        refreshList={size(state.route_path) > 0 ? refetchFolderFiles : refetchHomeFiles}
                        folder={state.editing_folder}
                    />
                }

                {state.open_delete_folder &&
                    <DeleteFolder
                        openPopup={state.open_delete_folder}
                        closePopup={closeDeleteFolder}
                        refreshList={size(state.route_path) > 0 ? refetchFolderFiles : refetchHomeFiles}
                        folder={state.deleting_folder}
                    />
                }

                {state.open_edit_document &&
                    <EditDocument
                        openPopup={state.open_edit_document}
                        closePopup={closeEditDocument}
                        refreshList={size(state.route_path) > 0 ? refetchFolderFiles : refetchHomeFiles}
                        document={state.editing_document}
                    />
                }

                {state.open_delete_document &&
                    <DeleteDocument
                        openPopup={state.open_delete_document}
                        closePopup={closeDeleteDocument}
                        refreshList={size(state.route_path) > 0 ? refetchFolderFiles : refetchHomeFiles}
                        document={state.deleting_document}
                    />
                }

                {state.open_upload_file &&
                    <AddFile
                        openPopup={state.open_upload_file}
                        closePopup={closeUploadFile}
                        idFolder={size(state.route_path) > 0 ? last(state.route_path).id_folder : null}
                        refreshList={size(state.route_path) > 0 ? refetchFolderFiles : refetchHomeFiles}
                    />
                }

                {state.open_create_folder &&
                    <AddFolder
                        openPopup={state.open_create_folder}
                        closePopup={closeCreateFolder}
                        idParentFolder={size(state.route_path) > 0 ? last(state.route_path).id_folder : null}
                        refreshList={size(state.route_path) > 0 ? refetchFolderFiles : refetchHomeFiles}
                    />
                }
            </TFBCardBody>
        </TFBCard>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(Documents);