import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import { TFBTableCell, TFBTableRow, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import EditCategory from "./EditCategory";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import DeleteCategory from "./DeleteCategory";

const CategoryList = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, list } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            show_category_list: openPopup,
            open_edit_category: false,
            editing_category: {},

            open_delete_category: false,
            deleting_category: {},
        }
    );

    const openEditCategory = (category) => {
        updateState({ open_edit_category: true, editing_category: category, show_category_list: false })
    }

    const closeEditCategory = () => {
        updateState({ open_edit_category: false, editing_category: {}, show_category_list: true })
    }

    const openDeleteCategory = (category) => {
        updateState({ open_delete_category: true, deleting_category: category, show_category_list: false })
    }

    const closeDeleteCategory = () => {
        updateState({ open_delete_category: false, deleting_category: {}, show_category_list: true })
    }

    // const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("parentsApp/delete_parent_2");

    // const handleResponse = (response) => {
    //     if (response?.success == 1) {
    //         closePopup();
    //         refreshList();
    //         showNotification(
    //             "success",
    //             languagePack.notif_delete_mobile_user_title,
    //             languagePack.notif_delete_mobile_user_success_message
    //         );
    //     } else {
    //         showNotification(
    //             "danger",
    //             languagePack.notif_delete_mobile_user_title,
    //             languagePack.notif_delete_mobile_user_failed_message
    //         );
    //     }
    // };

    // const deleteMobileUser = () => {
    //     const payload = {
    //         id_user_external: user.id_user_external,
    //     };
    //     deleteRequest(payload, handleResponse);
    // }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => { },
        // () => deleteMobileUser(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        // loadingDeleteRequest,
    );

    return (
        <>
            <TFBDialog
                className="pop-up-dialog-box prevent-select-text"
                title={languagePack.edit_category_list}
                open={state.show_category_list}
                closePopup={closePopup}
            >
                {_.size(list) > 0 ?
                    <TableContainer>
                        <Table faria-label="simple table" className="costs-table">
                            <TableHead>
                                <TFBTableRow>
                                    <TFBTableCell>
                                        {languagePack.category_name}
                                    </TFBTableCell>
                                    <TFBTableCell align="right">
                                        {languagePack.product_actions}
                                    </TFBTableCell>
                                </TFBTableRow>
                            </TableHead>
                            <TableBody className="costs-table-content">
                                {_.map(list, (row, index) => (
                                    <TFBTableRow key={"row-" + index}>
                                        <TFBTableCell sx={sxStickyColumn}>{row.label}</TFBTableCell>
                                        <TFBTableCell>
                                            <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                                                <TFBIconButton
                                                    name="edit"
                                                    color="darkGray"
                                                    onClick={() => openEditCategory(row)}
                                                />
                                                <TFBIconButton
                                                    name="delete"
                                                    color="darkGray"
                                                    onClick={() => openDeleteCategory(row)}
                                                />
                                            </div>
                                        </TFBTableCell>
                                    </TFBTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> :
                    <TFBPlaceholder text={languagePack.no_categories} />}

            </TFBDialog>
            {/* ------------------- EDIT CATEGORY ---------------- */}
            {state.open_edit_category &&
                <EditCategory
                    openPopup={state.open_edit_category}
                    closePopup={closeEditCategory}
                    refreshList={refreshList}
                    data={state.editing_category}
                />
            }

            {/* ------------------- DELETE CATEGORY ---------------- */}
            {state.open_delete_category &&
                <DeleteCategory
                    openPopup={state.open_delete_category}
                    closePopup={closeDeleteCategory}
                    refreshList={refreshList}
                    data={state.deleting_category}
                />
            }
        </>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(CategoryList);
