import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./MobileUser.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import CircularLoader from "../../../components/reusable/Loader";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import AddMobileUser from "./AddMobileUser";
import EditMobileUser from "./EditMobileUser";
import DeleteMobileUser from "./DeleteMobileUser";
import ChangeStatusMobileUser from "./ChangeStatusMobileUser";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";
import TFBSearch from "../../../components/design-system/TFBSearch/TFBSearch";
import { prepareStringForCompare } from "../../../utils/utilFunctions";

const MobileUser = (props) => {

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_add_user: false,

            open_edit_user: false,
            editing_user: {},

            open_delete_user: false,
            deleting_user: {},

            open_change_status_user: false,
            changing_status_user: {},

            page: 1,
            limit: 20,
            has_more: true,
            users: [],

            search_value: "",
        }
    );

    const { languagePack } = props;
    const { id_club, token } = props.currentUser;

    const {
        data: userList,
        loading: isUserListLoading,
        refetch: refetchUserList
    } = useAxiosGet(`parentsApp/get_mobile_user_list?id_club=${id_club}&token=${token}&page=${state.page}&limit=${state.limit}&search_player_name=${prepareStringForCompare(state.search_value)}`, true);

    useEffect(() => {
        if (state.search_value == "") {
            refetchUserList();
        }
    }, [state.search_value])

    useEffect(() => {
        if (userList?.list) {
            if (_.size(userList.list) > 0) {
                if (state.page == 1) {
                    updateState({ page: state.page + 1, users: userList.list });
                } else {
                    updateState({ page: state.page + 1, users: [...state.users, ...userList.list] });
                }
            } else {
                if (state.page == 1) {
                    updateState({ users: [], has_more: false });
                } else {
                    updateState({ has_more: false })
                }
            }
        }
    }, [userList])

    const { loaderRef } = useInfiniteScroll({ refetch: !isUserListLoading && state.has_more ? refetchUserList : () => { } });

    const openAddUser = () => {
        updateState({ open_add_user: true })
    }

    const closeAddUser = () => {
        updateState({ open_add_user: false })
    }

    const openEditUser = (user) => {
        updateState({ open_edit_user: true, editing_user: user })
    }

    const closeEditUser = () => {
        updateState({ open_edit_user: false, editing_user: {} })
    }

    const openDeleteUser = (user) => {
        updateState({ open_delete_user: true, deleting_user: user })
    }

    const closeDeleteUser = () => {
        updateState({ open_delete_user: false, deleting_user: {} })
    }

    const openChangeStatusUser = (user) => {
        updateState({ open_change_status_user: true, changing_status_user: user })
    }

    const closeChangeStatusUser = () => {
        updateState({ open_change_status_user: false, changing_status_user: {} })
    }

    const refreshList = () => {
        updateState({ users: [], page: 1, has_more: true })
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack.users_list}>
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                    onClick={openAddUser}
                >
                    {languagePack.add_user}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                <div className={styles.searchDiv}>
                    <TFBSearch
                        label={"Cautare"}
                        placeholder={"Introdu nume jucator..."}
                        classNameSearch={styles.searchVideo}
                        fluid
                        showNoResults={false}
                        value={state.search_value}
                        onSearchChange={(e) => updateState({ search_value: e.target.value, page: 1, has_more: true })}
                    />
                    {_.size(_.trim(state.search_value)) > 0 &&
                        <TFBButton
                            color="green"
                            renderIcon={() => <TFBIcon name="check" />}
                            onClick={refetchUserList}
                        >
                            {languagePack["apply_filter"]}
                        </TFBButton>
                    }
                    {_.size(state.search_value) > 0 &&
                        <div className={styles.selectDiv}>
                            <TFBButton
                                color="lightGray"
                                renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                                onClick={() => { updateState({ search_value: "", page: 1, has_more: true }) }}
                            >
                                {languagePack["cancel_filter"]}
                            </TFBButton>
                        </div>
                    }
                </div>
                {_.size(state.users) > 0 &&
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TFBTableCell sx={sxStickyColumn}>{languagePack.campaign_name}</TFBTableCell>
                                    <TFBTableCell align="center">
                                        {languagePack.player_players_name}
                                    </TFBTableCell>
                                    <TFBTableCell align="center">
                                        {languagePack.admin_contact_phone}
                                    </TFBTableCell>
                                    <TFBTableCell align="center">
                                        {languagePack.Email}
                                    </TFBTableCell>
                                    <TFBTableCell align="center">
                                        {languagePack.current_status_payment}
                                    </TFBTableCell>
                                    <TFBTableCell align="right">
                                        {languagePack.product_actions}
                                    </TFBTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.users, (row, index) => (
                                    <TableRow
                                        key={"notification-number-" + index}
                                        sx={{
                                            backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                                        }}
                                    >
                                        <TFBTableCell
                                            sx={sxStickyColumn}
                                            style={{
                                                backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                                            }}
                                        >
                                            <div className={styles.userNameRelationship}>
                                                <div>{row.name + " " + row.surname}</div>
                                                <div className={styles.relationship}>{_.join(_.map(_.map(row.players, e => e.relationship), e => e.relationship_name), ", ")}</div>
                                            </div>
                                        </TFBTableCell>
                                        <TFBTableCell align="center">{_.join(_.map(_.map(row.players, e => e.player), e => e.player_name), ", ")}</TFBTableCell>
                                        <TFBTableCell align="center">{row.phone_number}</TFBTableCell>
                                        <TFBTableCell align="center">{row.email}</TFBTableCell>
                                        <TFBTableCell align="center">
                                            {row.is_player == 0 && (row?.recurrence?.active == 1 ? <TFBIcon name="check" color="green" /> : <TFBIcon name="x-large" color="black" />)}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            <div
                                                className="actions-btns-container"
                                                style={{ justifyContent: "flex-end" }}
                                            >
                                                <TFBIconButton
                                                    name={
                                                        row.is_accepted == "1"
                                                            ? "active-player"
                                                            : "inactive-player"
                                                    }
                                                    className="tfb-icon-button-no-filter"
                                                    color="darkGray"
                                                    onClick={() => openChangeStatusUser(row)}
                                                />
                                                <TFBIconButton
                                                    name="edit"
                                                    color="darkGray"
                                                    onClick={() => openEditUser(row)}
                                                />
                                                <TFBIconButton
                                                    name="delete"
                                                    color="darkGray"
                                                    onClick={() => openDeleteUser(row)}
                                                />
                                            </div>
                                        </TFBTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {_.size(state.users) == 0 && isUserListLoading == false &&
                    <TFBPlaceholder text={languagePack.no_users} />
                }
                {<div ref={loaderRef}></div>}
                {isUserListLoading && <CircularLoader />}

                {state.open_add_user &&
                    <AddMobileUser
                        openPopup={state.open_add_user}
                        closePopup={closeAddUser}
                        refreshList={refreshList}
                    />
                }

                {state.open_edit_user &&
                    <EditMobileUser
                        openPopup={state.open_edit_user}
                        closePopup={closeEditUser}
                        refreshList={refreshList}
                        user={state.editing_user}
                    />
                }

                {state.open_delete_user &&
                    <DeleteMobileUser
                        openPopup={state.open_delete_user}
                        closePopup={closeDeleteUser}
                        refreshList={refreshList}
                        user={state.deleting_user}
                    />
                }

                {state.open_change_status_user &&
                    <ChangeStatusMobileUser
                        openPopup={state.open_change_status_user}
                        closePopup={closeChangeStatusUser}
                        refreshList={refreshList}
                        user={state.changing_status_user}
                    />
                }
            </TFBCardBody>
        </TFBCard>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(MobileUser);