import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "./../../../utils/api";
import axios from "axios";
import { store } from "react-notifications-component";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import CurrencyPicker from "../PaymentRequest/CurrencyPicker";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

class AddCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      departments: [...props.list],
      department: null,
      subdepartments: [],
      subdepartment: null,
      amount: "",
      currency: "EUR",
      payment_date: null,
      label_types: [
        {
          value: 0,
          label: "Staff",
          db_label: "staff",
        },
        {
          value: 1,
          label: "Meci",
          db_label: "match",
        },
        {
          value: 2,
          label: "Jucator",
          db_label: "player",
        },
        {
          value: 3,
          label: "Echipa",
          db_label: "team",
        },
      ],
      label_type: null,
      label_entity: null,
      label_entities: [],
      label_entities_loaded: true,
      additional_labels: [],
    };
  }

  getSubdepartments = () => {
    axios
      .get(
        `finances/get_subdepartments?id_department=${this.state.department.value}`
      )
      .then((res) =>
        this.setState({
          subdepartments: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getLabelEntities = () => {
    axios
      .get(
        `finances/get_label_entities_2?label_type=${this.state.label_type.db_label}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          label_entities: res.data,
          label_entities_loaded: true,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChangeAdditionalLabel = (index, label) => {
    let currentAdditionalLabels = this.state.additional_labels;
    currentAdditionalLabels[index] = {
      label_type: label,
      label_entity: null,
      label_entities_loaded: false,
    };
    this.setState(
      {
        additional_labels: currentAdditionalLabels,
      },
      () => {
        let currentAdditionalLabels = this.state.additional_labels;
        axios
          .get(
            `finances/get_label_entities_2?label_type=${label.db_label}&id_club=${this.props.currentUser.id_club}`
          )
          .then((res) => {
            let label_entities = res.data;
            currentAdditionalLabels[index] = {
              label_entities: label_entities,
              label_type: label,
              label_entity: null,
              label_entities_loaded: true,
            };
            this.setState({
              additional_labels: currentAdditionalLabels,
            });
          })
          .catch((err) => console.log(err));
      }
    );
  };

  handleDateChange = (value) => {
    this.setState({ payment_date: value });
  };

  resetLabel = () => {
    this.setState({
      label_entities: [],
      label_type: null,
      label_entity: null,
    });
  };

  addCost = () => {
    const { languagePack, closePopup, refreshList } = this.props;

    let label_array = [];
    //get short name for match
    if (this.state.label_type != null) {
      let initial_entity_name = this.state.label_entity.label;

      if (this.state.label_type.db_label == "match") {
        let name = initial_entity_name.split("- ");
        name = name[1];
        name = name.split(",");
        name = name[0];
        name = name.split(" (");
        name = name[0];
        initial_entity_name = name;
      }
      label_array.push({
        entity_type: this.state.label_type.db_label,
        entity_id: this.state.label_entity.value,
        entity_name: initial_entity_name,
      });
    }
    this.state.additional_labels.forEach((elem) => {
      let initial_entity_name = elem.label_entity.label;
      if (elem.label_type.db_label == "match") {
        let name = initial_entity_name.split("- ");
        name = name[1];
        name = name.split(",");
        name = name[0];
        name = name.split(" (");
        name = name[0];
        initial_entity_name = name;
      }
      label_array.push({
        entity_type: elem.label_type.db_label,
        entity_id: elem.label_entity.value,
        entity_name: initial_entity_name,
      });
    });
    const payload = {
      id_club: this.props.currentUser.id_club,
      description: this.state.description,
      id_department: this.state.department?.value,
      id_subdepartment: this.state.subdepartment?.value
        ? this.state.subdepartment?.value
        : null,
      amount: this.state.amount,
      currency: this.state.currency,
      payment_date: this.state.payment_date,
      labels: label_array,
    };

    axios
      .post("finances/add_cost_item", payload)
      .then((res) => {
        if (res.data > 0) {
          refreshList();
          store.addNotification({
            title: languagePack.finances,
            message: languagePack.success_add_cost,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.finances,
            message: languagePack.fail_add_cost,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack, closePopup, isOpenPopup, blockedPairs } = this.props;

    const hasDuplicateMatchError = () => {
      let itemsToSearch = this.state.additional_labels.filter(elem => elem.label_type?.db_label == "match" && this.state.label_entity?.value).map(elem => ({ id_match: elem.label_entity?.value, id_department: this.state.department?.value ?? null, id_subdepartment: this.state.subdepartment?.value ?? null, label: elem.label_entity?.label ?? null }));
      if (this.state.label_type?.db_label == "match") {
        itemsToSearch.push({
          id_match: this.state.label_type?.db_label == "match" ? this.state.label_entity?.value ?? null : null,
          id_department: this.state.department?.value ?? null,
          id_subdepartment: this.state.subdepartment?.value ?? null,
          label: this.state.label_entity?.label ?? null,
        })
      }

      let hasErrors = false;
      let errorObject = null;

      [...blockedPairs].forEach(elem => {
        itemsToSearch.forEach(item => {
          if (!hasErrors) {
            if (item.id_match == elem.id_match && item.id_department == elem.id_department && item.id_subdepartment == elem.id_subdepartment) {
              hasErrors = true;
              errorObject = item;
            } else {
              hasErrors = false;
            }
          }
        })
      })

      return {
        hasErrors: hasErrors,
        errorObject: errorObject,
      };
    }

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.add_spending}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            disabled={
              this.state.description == "" ||
              this.state.department == null ||
              this.state.amount == "" ||
              this.state.payment_date == null ||
              (this.state.label_type != null && this.state.label_entity == null) ||
              (this.state.additional_labels.length > 0
                ? !this.state.additional_labels.every((elem) => {
                  if (elem.label_entity != null && elem.label_type != null) {
                    return true;
                  }
                  return false;
                })
                : false) ||
              hasDuplicateMatchError().hasErrors
            }
            onClick={this.addCost}
          >
            {languagePack.admin_add}
          </TFBButton>
        )}
      >
        <div className="pop-up-section">
          <TFBInput
            label={languagePack.staff_description}
            placeholder={languagePack.staff_description}
            fullWidth={true}
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
        </div>
        <div className="pop-up-section">
          <TFBSelect
            label={languagePack.select_department}
            placeholder={languagePack.select_department}
            value={this.state.department}
            options={this.state.departments}
            onChange={(selected) => {
              this.setState(
                {
                  department: selected,
                  subdepartment: null,
                },
                this.getSubdepartments
              );
            }}
          />
        </div>
        <div className="pop-up-section">
          <TFBSelect
            label={languagePack.select_subdepartment}
            placeholder={languagePack.select_subdepartment}
            value={this.state.subdepartment}
            options={this.state.subdepartments}
            onChange={(selected) => {
              this.setState({
                subdepartment: selected,
              });
            }}
          />
        </div>
        <div className="pop-up-section">
          <div className="pop-up-section-body">
            <div className="pop-up-row">
              <div className="row-input-with-currency">
                <TFBInput
                  label={languagePack.add_payment_amount}
                  placeholder={languagePack.add_payment_amount}
                  fullWidth={true}
                  value={this.state.amount}
                  onChange={(e) => this.setState({ amount: e.target.value })}
                />
                <div className="currency-options" style={{ height: 50, alignSelf: "end" }}>
                  <CurrencyPicker
                    name="RON"
                    checked={this.state.currency == "RON"}
                    onClick={() => this.setState({ currency: "RON" })}
                  />
                  <CurrencyPicker
                    name="EUR"
                    checked={this.state.currency == "EUR"}
                    onClick={() => this.setState({ currency: "EUR" })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pop-up-section">
          <TFBDatePicker
            label={languagePack.add_payment_date}
            placeholder={languagePack.add_payment_date}
            emptyLabel={languagePack.add_payment_date}
            value={this.state.payment_date}
            style={{ width: "100%" }}
            onChange={this.handleDateChange}
          />
        </div>
        <div className="pop-up-section">
          <TFBSelect
            label={languagePack.select_tag_type}
            placeholder={languagePack.select_tag_type}
            value={this.state.label_type}
            options={this.state.label_types}
            onChange={(selected) => {
              this.setState(
                {
                  label_type: selected,
                  label_entity: null,
                  label_entities: [],
                  label_entities_loaded: false,
                },
                this.getLabelEntities
              );
            }}
          />
        </div>
        <div className="pop-up-section">
          <TFBSelect
            label={languagePack.select_subdepartment}
            placeholder={`${languagePack.select} ${this.state.label_type?.label
              ? languagePack[this.state.label_type.db_label]
              : languagePack.entity_tag
              }`}
            value={this.state.label_entity}
            options={this.state.label_entities}
            onChange={(selected) => {
              this.setState({
                label_entity: selected,
              });
            }}
            isLoading={!this.state.label_entities_loaded}
            isClearable={true}
          />
        </div>
        <div className="pop-up-section">
          <div className="pop-up-section-body">
            {this.state.additional_labels.map((elem, index) => (
              <div
                className="pop-up-row df-g20 df-center"
                key={"venue-child-" + index}
              >
                <div style={{ width: "100%" }}>
                  <TFBSelect
                    label={languagePack.select_tag_type}
                    placeholder={languagePack.select_tag_type}
                    value={elem.label_type}
                    options={this.state.label_types}
                    onChange={(selected) => {
                      this.handleChangeAdditionalLabel(index, selected);
                    }}
                    style={{ component: { marginBottom: 20 } }}
                  />
                  <TFBSelect
                    label={languagePack.select_subdepartment}
                    placeholder={`Selecteaza ${elem.label_type?.label
                      ? elem.label_type.label
                      : "entitate eticheta"
                      }`}
                    value={elem.label_entity}
                    options={elem.label_entities}
                    onChange={(selected) => {
                      let currentAdditionalLabels = this.state
                        .additional_labels;
                      currentAdditionalLabels[index].label_entity = selected;
                      this.setState({
                        additional_labels: currentAdditionalLabels,
                      });
                    }}
                    isLoading={!elem.label_entities_loaded}
                  />
                </div>
                <TFBIconButton
                  name="x-large"
                  color="red"
                  onClick={() => {
                    let currentAdditionalLabels = this.state.additional_labels;
                    currentAdditionalLabels.splice(index, 1);
                    this.setState({
                      additional_labels: currentAdditionalLabels,
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <TFBButton
          color="lightGray"
          renderIcon={() => <TFBIcon name="add" color="gray" />}
          disabled={this.state.additional_labels.filter(elem => elem.label_type == null).length > 0}
          onClick={() => {
            let currentAdditionalLabels = this.state.additional_labels;
            currentAdditionalLabels.push({
              label_type: null,
              label_entities: [],
              label_entity: null,
              label_entities_loaded: true,
            });
            this.setState({
              additional_labels: currentAdditionalLabels,
            });
          }}
        >
          {languagePack.add_tag}
        </TFBButton>
        {hasDuplicateMatchError().hasErrors &&
          <div className="error-message">{`Pentru departamentul ${this.state.department?.label}${this.state.subdepartment?.value ? ", subdepartamentul " + this.state.subdepartment?.label : ""} exista deja o cheltuiala cu tag pentru meciul ${hasDuplicateMatchError().errorObject.label}. Daca doresti sa-l modifici, mergi la el.`}</div>
        }
      </TFBDialog >
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddCost);
