import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import "./CheckboxLabel.scss";

const CheckboxLabel = ({ label, checked, onClick, labelStyle }) => {

    const styles = {
        "checkbox-label-container": {
            cursor: "pointer",
            width: "max-content",
        },
        "checkbox": {
            paddingLeft: 0,
            color: checked ? "var(--primaryColor)" : "#fafafa",
        },
        "label": {
            color: "#B2B2B2",
            fontSize: 13,
            fontWeight: 700,
        },

    }

    return (
        <div
            style={styles['checkbox-label-container']}
            onClick={onClick}
        >
            <Checkbox
                checked={checked}
                style={styles['checkbox']}
                className="tfb-checkbox"
            />
            <span style={{ ...styles['label'], ...(labelStyle ? labelStyle : {}) }} className="prevent-select-text">{label}</span>
        </div>
    )
}

export default CheckboxLabel;