import React from "react";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dialog, DialogContent } from "@material-ui/core";
import axios from "axios";
import _ from "lodash";
import { store } from "react-notifications-component";
import { Switch } from "@material-ui/core";

import StaffMembers from "./StaffMembers/StaffMembers";
import ActiveTeams from "./Teams/ActiveTeams";
import ActivePlayers from "./Players/ActivePlayers";
import "./ClubProfile.scss";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import ExportPlayers from "./ExportPlayers/ExportPlayers";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import AddTeamForm from "./Teams/AddTeamForm";
import TeamTypesList from "./Teams/TeamTypesList";

class MyTeams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedView: false, //true: players, false: teams
      show_on_loan: false,
      show_on_loan_players: false,
      exportListPopup: false,

      activePlayersData: [],

      typesDialog: false,
      addNewTeamDialog: false,
      teamsList: [],

      staffMembers: [],
      staffDataFetched: false,
    };
  }

  componentDidMount() {
    this.getStaffMembers();

    if (_.get(this.props.history, "location.state.redirectFromSetup")) {
      this.setState({ addNewTeamDialog: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (_.get(this.props.history, "location.state.redirectFromSetup")) {
        this.props.history.replace({
          ...this.props.history.location,
          state: undefined,
        });
        this.setState({ addNewTeamDialog: true });
      }
    }
  }

  // ================ GET TEAMS LIST =============================
  getTeams = () => {
    axios
      .get(
        `club/get_teams_2?id_club=${this.props.id_club}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          ...prevState,
          teamsList: Array.isArray(res.data) ? res.data : [],
        }));
      })
      .catch((err) => console.log(err));
  };

  getStaffMembers = () => {
    axios
      .get(
        `club/get_staff_members?id_club=${this.props.currentUser.id_club}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({ staffMembers: res.data, staffDataFetched: true });
      })
      .catch((err) => console.log(err));
  };

  // ================ HANDLERS =============================
  handleChangeCheckbox = () => {
    this.setState({
      selectedView: !this.state.selectedView,
      show_on_loan: !this.state.selectedView,
    });
  };

  handleChangeSwitch = (open) => {
    this.setState({
      selectedView: open,
      show_on_loan: open,
    });
  };

  handleTypesDialog = (open) => {
    this.setState((prevState) => ({
      ...prevState,
      typesDialog: open,
    }));
  };

  handleAddNewTeam = (open) => {
    this.setState((prevState) => ({
      ...prevState,
      addNewTeamDialog: open,
    }));
  };

  handleExportPlayers = () => {
    if (_.size(this.state.activePlayersData) > 0) {
      this.setState({ exportListPopup: true });
    } else
      store.addNotification({
        title: this.props.languagePack.club_management,
        message: this.props.languagePack.select_at_least_one_player,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
  };

  // ============= GET DATA FROM ACTIVE PLAYERS CHILDREN =============================
  transferData = (data) => {
    this.setState({ activePlayersData: data });
  };

  render() {
    const { languagePack } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-lg-8 col-lg-padding">
            <TFBCard>
              {/* ============== CARD HEADER ==================== */}
              <TFBCardHeader
                title={
                  this.state.selectedView
                    ? languagePack["players"]
                    : languagePack["active_teams"]
                }
              >
                {this.state.selectedView ? (
                  <TFBButton
                    onClick={this.handleExportPlayers}
                    endIcon="true"
                    color={"green"}
                    renderIcon={() => <TFBIcon name={"add"} />}
                  >
                    {languagePack.export_player_list}
                  </TFBButton>
                ) : (
                  <>
                    <TFBButton
                      onClick={() => this.handleTypesDialog(true)}
                      color={"darkGray"}
                    >
                      {languagePack["team_types_list"]}
                    </TFBButton>
                    <TFBButton
                      onClick={() => this.handleAddNewTeam(true)}
                      endIcon="true"
                      color={"green"}
                      renderIcon={() => <TFBIcon name={"add"} />}
                    >
                      {languagePack["add_team"]}
                    </TFBButton>
                  </>
                )}
              </TFBCardHeader>

              <TFBCardBody>
                {/* ==================== SUB HEADER ==================== */}
                <div
                  className="sub-header-wrapper"
                  style={{
                    display: "flex",
                    margin: "50px 0px",
                    alignItems: "center",
                    gap: "25px",
                  }}
                >
                  <div
                    className="switch-team-player"
                    style={{
                      marginTop: "4px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <span onClick={() => this.handleChangeSwitch(false)}>
                      {languagePack.teams}
                    </span>
                    <Switch
                      checked={this.state.selectedView}
                      onChange={this.handleChangeCheckbox}
                      color="primary"
                      name="anotherDatePayment"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <span onClick={() => this.handleChangeSwitch(true)}>
                      {languagePack.players}
                    </span>
                  </div>
                  {this.state.show_on_loan && (
                    <div
                      className="on-loan-players"
                      onClick={() => {
                        this.setState({
                          show_on_loan_players: !this.state
                            .show_on_loan_players,
                        });
                      }}
                    >
                      <TFBCheckbox
                        label={this.props.languagePack.show_on_loan}
                        checked={this.state.show_on_loan_players}
                        onChange={() => {
                          this.setState({
                            show_on_loan_players: !this.state
                              .show_on_loan_players,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
                {/* ============== ACTIVE TEAMS/PLAYERS COMPONENT ==================== */}
                {!this.state.selectedView ? (
                  <ActiveTeams
                    id_club={this.props.currentUser.id_club}
                    token={this.props.token}
                    languagePack={this.props.languagePack}
                    openAddTeamPopup={
                      this.props.history.location.state !== null &&
                      this.props.history.location.state !== undefined
                        ? "openAddTeam" in this.props.history.location.state
                          ? this.props.history.location.state.openAddTeam
                          : false
                        : false
                    }
                    teamsList={this.state.teamsList}
                  />
                ) : (
                  <ActivePlayers
                    id_club={this.props.currentUser.id_club}
                    token={this.props.token}
                    languagePack={this.props.languagePack}
                    show_on_loan={this.state.show_on_loan_players}
                    transferData={this.transferData}
                  />
                )}
              </TFBCardBody>
            </TFBCard>
          </div>

          {/* ==================== STAFF MEMBERS COMPONENT ====================== */}
          <div className="col-lg-4 col-lg-padding">
            <StaffMembers
              history={this.props.history}
              id_club={this.props.currentUser.id_club}
              token={this.props.token}
              staffMembers={this.state.staffMembers}
              staffDataFetched={this.state.staffDataFetched}
              refreshStaffMembers={this.getStaffMembers}
              languagePack={this.props.languagePack}
              openAddStaffPopup={
                this.props.history.location.state !== null &&
                this.props.history.location.state !== undefined
                  ? "openAddStaff" in this.props.history.location.state
                    ? this.props.history.location.state.openAddStaff
                    : false
                  : false
              }
            />
          </div>
        </div>

        {/* ============== ADD NEW TEAM POPUP ======================== */}
        {/* <Dialog open={this.state.addNewTeamDialog}>
          <DialogContent className="add-team-wrapper"> */}
        {this.state.addNewTeamDialog && (
          <AddTeamForm
            closePopup={() => this.handleAddNewTeam(false)}
            id_club={this.props.currentUser.id_club}
            refreshTeams={() => this.getTeams()}
            refreshStaffMembers={() => this.getStaffMembers()}
            languagePack={languagePack}
            open={this.state.addNewTeamDialog}
          />
        )}
        {/* </DialogContent>
        </Dialog> */}

        {/* ============== TEAM TYPES POPUP ======================== */}
        <Dialog open={this.state.typesDialog}>
          <DialogContent className="roles-list-wrapper">
            <TeamTypesList
              closePopup={() => this.handleTypesDialog(false)}
              id_club={this.props.currentUser.id_club}
              languagePack={languagePack}
            />
          </DialogContent>
        </Dialog>

        {/* ============== EXPORT LIST POPUP ======================== */}
        <ExportPlayers
          exportListPopup={this.state.exportListPopup}
          closePopup={() => this.setState({ exportListPopup: false })}
          languagePack={languagePack}
          token={this.props.currentUser.token}
          playerData={this.state.activePlayersData}
          id_club={this.props.currentUser.id_club}
        />
        {/* ==================================================== */}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(MyTeams);
