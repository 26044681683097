import React, { useState } from "react";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";

const UPDATE_VIDEO_TITLE_ENDPOINT = "upload/update_video_title";

export default function EditTitle({
  closePopup,
  openPopup,
  languagePack,
  videoTitle,
  id_video,
  setVideoData,
}) {
  const [title, setTitle] = useState(videoTitle);

  const { renderBtn: BtnRight } = useRenderButton(
    () => editTitle(),
    "green",
    "check",
    languagePack.admin_confirm
  );
  const { renderBtn: BtnLeft } = useRenderButton(
    closePopup,
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { postData: postEditTitle } = useAxiosPost(UPDATE_VIDEO_TITLE_ENDPOINT);

  const editTitle = () => {
    const payload = {
      id_video: id_video,
      title: title,
    };

    postEditTitle(payload, handleResponse);
  };

  const handleResponse = (res) => {
    if (res.success == 1) {
      showNotification(
        "success",
        languagePack["edit_video_title"],
        languagePack["successfully_edited_video_title"]
      );
      setVideoData((prev) => ({ ...prev, video_name: title }));
      closePopup();
    } else {
      showNotification(
        "danger",
        languagePack["edit_video_title"],
        languagePack["fail_edited_video_title"]
      );
    }
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["edit_video_title"]}
      open={openPopup}
      closePopup={closePopup}
      btnRight={BtnRight}
      btnLeft={BtnLeft}
    >
      <TFBInput
        label={languagePack["video_title"]}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
      />
    </TFBDialog>
  );
}
