import React from "react";
import ReactApexChart from "react-apexcharts";
import "../../../../../pages/Club/PlayerProfile/PlayerProfile.scss";
import "../PlayerRadarChart.scss";

export default function TwoPlayersComparison(props) {
  return (
    <div
      className="player-radar-chart"
      style={{
        display: "flex",
        marginTop: "50px",
      }}
    >
      {/*================ CHART ITSELF =================*/}
      {/*===============================================*/}
      <div className="radar-chart-itself-two-players">
        <ReactApexChart
          options={props.optionsCompariosn}
          series={props.seriesComparison}
          type="radar"
        />
      </div>

      {/*================ EVALUATION DETAILS PER CATEGORY =================*/}
      {/*==================================================================*/}

      <div className="player-evaluation-detail">
        {props.evaluationdataComparison.map((evaluationData, index1) => {
          return (
            <div
              className="player-evaluation-category-wrapper animate-drop"
              key={"player-evaluation-category-wrapper-" + index1}
            >
              <div className="evaluation-category-header">
                <div className="evaluation-category-col">
                  {evaluationData.category.category_name}
                </div>
              </div>
              {evaluationData.sub_categories.map((subcategory, index) => {
                return (
                  <div
                    className="evaluation-subcategories-row animate-drop"
                    key={"evaluation-subcategories-row-" + index}
                    style={{
                      backgroundColor: index % 2 !== 0 && "#fafafa",
                    }}
                  >
                    <div className="evaluation-subcategory-name">
                      {subcategory.sub_cat_name}
                    </div>
                    <div
                      className="evaluation-subcategory-grade"
                      style={{
                        color:
                          subcategory.advantage_owner == 0
                            ? "black"
                            : subcategory.advantage_owner == 1
                            ? "#00AAFF"
                            : "#FF9500",
                      }}
                    >
                      {subcategory.advantage_owner != 0
                        ? `+${Math.abs(subcategory.difference)}`
                        : "0"}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
