import { rootPages } from "./constants";
import NumberFormat from "react-number-format";
import _ from "lodash";
import React from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import { isValidPhoneNumber } from "react-phone-number-input";

export function getCurrentRootPage(pathname) {
  const rootPagesArr = Object.entries(rootPages).map(([name, value]) => value);
  for (let i = 0; i < rootPagesArr.length; i++) {
    const regex = new RegExp(`\/(${rootPagesArr[i]})([\/](.*)?)?$`);
    if (regex.test(pathname)) {
      return rootPagesArr[i];
    }
  }
  return null;
}

export function checkPageRoot(pathname, root) {
  return new RegExp(`(${root})([\/](.*)?)?$`).test(pathname);
}

export function getCurrentExchange() {
  return fetch(
    "http://api.exchangeratesapi.io/latest?access_key=4395d9b0c22ce70763c13f0208771969"
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return new Promise((resolve, reject) => reject(res));
    })
    .then((data) => {
      const rates =
        data?.rates && typeof data.rates === "object" ? data.rates : {};
      return new Promise((resolve, reject) => resolve(rates));
    })
    .catch((error) => {
      return new Promise((resolve, reject) => reject(error));
    });
}

export function setURLParam(paramName, paramValue) {
  let url = document.location.search;
  let search_params = new URLSearchParams(url);
  search_params.set(paramName, paramValue);
  window.history.replaceState(null, null, "?" + search_params.toString());
}

const readUploadedFileAsText = (inputFile) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export const handleFileInput = async (file) => {
  try {
    const fileContents = await readUploadedFileAsText(file);
    return fileContents;
  } catch (e) {
    return null;
  }
};

export const formatAmountCurrency = (
  amount,
  amountCurrency,
  toCurrency,
  currencyRates = null
) => {
  if (currencyRates == null || Object.keys(currencyRates).length == 0)
    return "";
  let value = amount;
  let currency = amountCurrency;
  if (!(amountCurrency == toCurrency)) {
    value = currencyRates[`${amountCurrency}_${toCurrency}`] * amount;
    currency = toCurrency;
  }

  return (
    <>
      <NumberFormat
        value={value}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={2}
      />
      {" " + currency}
    </>
  );
};

export const convertAmountCurrency = (
  amount,
  amountCurrency,
  toCurrency,
  currencyRates = null
) => {
  if (currencyRates == null || Object.keys(currencyRates).length == 0) return 0;
  let value = amount;
  if (!(amountCurrency == toCurrency)) {
    value = currencyRates[`${amountCurrency}_${toCurrency}`] * amount;
  }

  return value;
};

export const downloadFile = (url, fileName) => {
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((res) => {
      fileDownload(res.data, fileName);
    });
};

export const isValidPhoneNumberCustom = (phone) => {
  let valid = true;

  if (phone) {
    if (_.size(phone) > 0) {
      if (_.findIndex(_.toArray(phone), (e) => e == "7", 0) == 3) {
        valid = isValidPhoneNumber(phone);
      } else {
        valid = false;
      }
    }
  }
  return valid;
};

export const isValidEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isValidSecondCountry = (first, second) => {
  return (
    _.lowerCase(_.toString(first)) != _.lowerCase(_.toString(second)) &&
    second != "" &&
    second != null &&
    second != "No country"
  );
};

export const getCountryName = (value, countries) => {
  return value && value != "noCountry"
    ? _.find(countries, (item) => item.value === value)?.text ?? ""
    : "";
};

export const getCountryCodeByName = (value, countries) => {
  return value && value != "noCountry"
    ? _.find(countries, (item) => item.text === value)?.value ?? null
    : null;
};

export const baseUrl = (relativePath = "") => {
  return window.baseURL + relativePath;
};

export const prepareStringForCompare = (text) => {
  return _.trim(
    _.toString(text)
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
  );
};

export const removeDiacritics = (text) => {
  return _.trim(
    _.toString(text)
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  );
};

export const convertMinToHrs = (value) => {
  const hours = Math.floor(value / 60);
  const min = value % 60;
  if (hours > 0) return hours + " h, " + min + " min";
  else return min + " min";
};

export const encryptBase64String = (str) => {
  return Buffer.from(str).toString("base64");
};

export const hexToRgb = (hex) => {
  const shortHexRegExp = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shortHexRegExp, (_, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) throw Error("A valid HEX must be provided");

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const getContrastTextColor = (color) => {
  const { r, g, b } = hexToRgb(color);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  let contrastColor;

  if (yiq >= 128) {
    contrastColor = "#000";
  } else {
    contrastColor = "#fff";
  }

  return contrastColor;
};

export const isTFB = () => {
  return window.platform == "tfb";
};

export const random = () => {
  return (Math.random() + 1).toString(36).substring(7);
};

export const createSelectedFiltersString = (
  ageGroup,
  sectionType,
  accessibility
) => {
  const ageGroupString =
    _.size(ageGroup) > 0
      ? "1-" +
      _.join(
        _.map(ageGroup, (f) => (f ? f.value : "")),
        "_"
      ) +
      "~"
      : "";

  const sectionTypeString =
    _.size(sectionType) > 0
      ? "2-" +
      _.join(
        _.map(sectionType, (f) => (f ? f.value : "")),
        "_"
      ) +
      "~"
      : "";

  const accessibilityString = accessibility ? "3-" + accessibility?.value : "";

  const selectedFiltersString =
    ageGroupString + sectionTypeString + accessibilityString;

  return selectedFiltersString;
};

export const checkForFilterChange = (state) => {
  return (
    _.size(_.trim(state?.search_value)) > 0 ||
    _.size(state?.accessibility) > 0 ||
    _.size(state?.section_type) > 0 ||
    _.size(state?.age_group) > 0 ||
    !_.includes(state?.players_no, 0) ||
    !_.includes(state?.players_no, 40) ||
    !_.includes(state?.goalkeepers_no, 0) ||
    !_.includes(state?.goalkeepers_no, 5) ||
    state?.range_filter_was_changed
  );
};

export const removeAgeGroupFromTeamName = (query) => {
  const newQuery = query.replace(
    /\b(?:sc|SC|sa|SA|SRL|srl|u4|U4|u5|U5|u6|U6|u7|U7|u8|U8|u9|U9|u10|U10|u11|U11|u12|U12|u13|U13|u14|U14|u15|U15|u16|U16|u17|U17|u18|U18|u19|U19|u20|U20|u21|U21|u22|U22|u23|U23|u24|U24)\b/gi,
    ""
  );
  return newQuery.trim();
};

export const getSVGName = (value) => {
  if (value === "xlsx" || value === "xls") return "xls.svg";
  if (value === "doc" || value === "docx") return "doc.svg";
  if (value === "pdf") return "pdf.svg";
  if (value === "jpg" || value === "jpeg") return "jpg.svg";
  if (value === "png") return "png.svg";
  if (value === "txt") return "txt.svg";
  if (value === "csv") return "csv.svg";
  if (value === "mp3") return "mp3.svg";
  if (value === "m4v" || value === "avi" || value === "mpg" || value === "mp4")
    return "video.svg";
  else return "search.svg";
};

export const chartColors = () => {
  return [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#A1FF33",
    "#FF8C33",
    "#33FFA5",
    "#8C33FF",
    "#FFD633",
    "#33D4FF",
    "#FF3333",
    "#33FF8C",
    "#5733FF",
    "#FF33FF",
    "#D4FF33",
    "#FF6633",
    "#33FFD4",
    "#6633FF",
    "#FFFF33",
    "#33FF66",
  ];
};
