import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextArea, Button, Icon } from "semantic-ui-react";
import { store } from "react-notifications-component";
import delete_icon from "../../../../assets/icons/delete.png";
import Loader from "react-loader-spinner";

export default function TeamTypesList(props) {
  const [typesList, setTypesList] = useState([]);
  const [newType, setNewType] = useState("");
  const [addNewType, setAddNewType] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const { languagePack } = props;

  useEffect(() => {
    getTypes();
  }, []);

  function getTypes() {
    axios
      .get(`club/get_types?id_club=${props.id_club}`)
      .then((res) => {
        setTypesList(Array.isArray(res.data) ? res.data : []);
        setDataFetched(true);
      })
      .catch((err) => console.log(err));
  }

  function handleChangeInput(e) {
    setNewType(e.target.value);
  }

  function submitNewType() {
    const type = {
      team_type_name: newType,
      id_club: props.id_club,
    };

    axios.post("club/add_new_type", type).then(() => {
      getTypes();
      setAddNewType(false);
      setNewType("");
      store.addNotification({
        title: languagePack["team_type_notification_title"],
        message: languagePack["team_type_notification_succes"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  }

  function deleteType(id) {
    const role = {
      id_type: id,
    };

    axios.post("club/delete_team_type", role).then(() => {
      getTypes();
      store.addNotification({
        title: languagePack["team_type_notification_title"],
        message: languagePack["team_type_notification_fail"],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  }

  return (
    <div className="roles-list-container">
      <div className="role-list-header">
        <div className="role-list-title">{languagePack["team_types_list"]}</div>
        <Icon
          name="close"
          className="close-icon-popup"
          onClick={() => props.closePopup()}
        />
      </div>
      {!dataFetched ? (
        <div className="loader-container">
          <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={25} width={25} />
        </div>
      ) : (
        <div className="roles-list">
          {typesList.map((item, index) => {
            return (
              <div
                className="role-row"
                style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                key={item.key}
              >
                <div className="role-name">{item.text}</div>
                <img
                  src={delete_icon}
                  alt="delete"
                  onClick={() => deleteType(item.key)}
                />
              </div>
            );
          })}
        </div>
      )}
      {addNewType ? (
        <div className="add-role-container-opened">
          <TextArea
            placeholder={languagePack["team_type_add"]}
            name="bonus-condition"
            value={newType}
            onChange={handleChangeInput}
            className="add-role-input"
          />
          <div className="add-role-buttons-container">
            <Button
              className="role-button role-button-dark"
              onClick={() => setAddNewType(false)}
            >
              {languagePack["admin_cancel"]}
              <Icon name="cancel" className="add-white-icon" />
            </Button>
            <Button className="role-button" onClick={submitNewType}>
              {languagePack["team_type_add_short"]}
              <Icon name="add" className="add-white-icon" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="add-role-container-closed">
          <Button className="role-button" onClick={() => setAddNewType(true)}>
            {languagePack["team_type_add"]}
            <Icon name="add" className="add-white-icon" />
          </Button>
        </div>
      )}
    </div>
  );
}
