import React from "react";
import styles from "./TFBSearch.module.scss";
import { Search } from "semantic-ui-react";
import { TSearch } from "../types";

const TFBSearch = (props: TSearch) => {
  const {
    label,
    styleSearch,
    classNameSearch,
    classNameComponent,
    ...otherProps
  } = props;

  const ReusableSearch = (
    <Search
      style={styleSearch}
      className={`${styles.tfbSearchInput} ${classNameSearch}`}
      fluid
      input={{ icon: "search" }}
      {...otherProps}
    />
  );

  return (
    <div className={`${styles.tfbSearchInputGroup} ${classNameComponent}`}>
      {label && <h6 className={styles.tfbSearchInputLabel}>{label}</h6>}
      {ReusableSearch}
    </div>
  );
};

export default TFBSearch;
