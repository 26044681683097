import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { Popup } from "semantic-ui-react";
import Loader from "react-loader-spinner";

class PostsDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: [window.clubCustomization.primary_color],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            endingShape: "flat",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#fafafa", "transparent"],
        },
        grid: {
          row: {
            colors: ["#fafafa", "transparent"],
            opacity: 1,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function(y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.getData();
    }
  }

  getData = () => {
    this.setState({ isFetching: true });
    axios
      .get(
        `fans/device_type_chart?year=${this.props.year}&id_network=${this.props.id_network}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.values,
          isFetching: false,
        }));
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="card card-custom">
        <div className="dashboard-box-container">
          <div className="dashboard-box-title">
            Device distribution
            <Popup
              trigger={<div className="info-button">!</div>}
              content={
                <div>
                  The number of people logged in to Facebook who have viewed
                  your page profile, grouped by the type of device
                </div>
              }
              position="right center"
              size="small"
              basic
            />
          </div>
          {!this.state.isFetching ? (
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height={350}
            />
          ) : (
            <div className="loader-container" style={{ height: 350 }}>
              <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={60} width={60} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PostsDistribution;
