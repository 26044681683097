import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import styles from "./ViewTrainingSectionPopup.module.scss";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import CircularLoader from "../../../components/reusable/Loader";
import { TViewTrainingSessionPopup } from "../../../components/design-system/types";
import _ from "lodash";
import nl2br from "react-nl2br";

function ViewTrainingSectionPopup({
  languagePack,
  openPopup,
  sectionId,
  token,
  closePopup,
}: TViewTrainingSessionPopup): JSX.Element {
  const [openVideo, setOpenVideo] = useState(false);
  const [openImage, setOpenImage] = useState(false);

  const { data: sectionData, loading: loadingSectionData } = useAxiosGet(
    `training/get_training_section?token=${token}&id_training_section=${sectionId}`
  );

  return (
    <>
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={sectionData?.["data"]?.["section_name"]}
        open={true}
        closePopup={closePopup}
        fullWidth
        maxWidth="sm"
      >
        <p className={styles.addedBy}>
          {sectionData?.["data"]?.["added_by"]
            ? languagePack.added_by + ":"
            : languagePack.added}{" "}
          {sectionData?.["data"]?.["added_by"]
            ? sectionData?.["data"]?.["added_by"] + ", "
            : ""}{" "}
          {languagePack.added_on_date}:{" "}
          {moment(sectionData?.["data"]?.["date_add"]).format("DD.MM.YYYY")}
        </p>
        <p className={styles.addedBy}>
          {sectionData?.["data"]?.["upd_by"]
            ? languagePack.last_update + ":"
            : ""}{" "}
          {sectionData?.["data"]?.["upd_by"]
            ? sectionData?.["data"]?.["upd_by"] + ", "
            : ""}{" "}
          {sectionData?.["data"]?.["date_upd"] &&
          sectionData?.["data"]?.["upd_by"]
            ? languagePack.added_on_date +
              moment(sectionData?.["data"]?.["date_upd"]).format("DD.MM.YYYY")
            : ""}
        </p>
        <div className={styles.wrapper}>
          {/* /////// LABELS ///////// */}
          {
            <div className={styles.labelsDiv}>
              <span className={styles.subtitle}>{languagePack.Labels}</span>
              <div className={styles.tags}>
                {!loadingSectionData &&
                  (sectionData?.["data"]?.["no_of_players"] !== null ||
                    sectionData?.["data"]?.["no_of_gks"] !== null) && (
                    <div className={styles.tag}>
                      {(sectionData?.["data"]?.["no_of_players"] ?? "0") +
                        " " +
                        languagePack.players +
                        " " +
                        (sectionData?.["data"]?.["no_of_gks"] ?? "0") +
                        " " +
                        languagePack.goalkeepers}
                    </div>
                  )}
                {loadingSectionData ? (
                  <CircularLoader />
                ) : _.size(sectionData?.["data"]?.["tags"]?.["list"]) > 0 ? (
                  <>
                    {_.map(
                      sectionData?.["data"]?.["tags"]?.["list"],
                      (tag, index) => (
                        <div
                          key={"tag" + index}
                          className={
                            tag?.["label" as keyof typeof tag] === "Public"
                              ? styles.publicTag
                              : tag === "Privat"
                              ? styles.privateTag
                              : styles.tag
                          }
                        >
                          {tag?.["label" as keyof typeof tag]}
                        </div>
                      )
                    )}{" "}
                  </>
                ) : (
                  languagePack.no_labels_added
                )}
              </div>
            </div>
          }

          {/* /////// DESCRIPTION ///////// */}

          <div className={styles.descriptionDiv}>
            <span className={styles.subtitle}>
              {languagePack.Section_description}
            </span>
            {loadingSectionData ? (
              <CircularLoader />
            ) : sectionData?.["data"]?.["section_description"] ? (
              <p className={styles.descriptionP}>
                {nl2br(sectionData?.["data"]?.["section_description"])}
              </p>
            ) : (
              languagePack.no_description_added
            )}
          </div>

          {/* /////// MEDIA ///////// */}

          <div className={styles.mediaDiv}>
            <span className={styles.subtitle}>{languagePack.Media}</span>
            <div className={styles.mediaContainer}>
              {sectionData?.["data"]?.["section_img"] ? (
                <img
                  className={styles.sectionImg}
                  src={
                    sectionData?.["data"]?.["section_img"]
                      ? sectionData?.["data"]?.["section_img"]
                      : "/media/dummy-content/training-tactic-dummy.jpeg"
                  }
                  alt="section tactic preview"
                  onClick={() => setOpenImage(true)}
                />
              ) : loadingSectionData ? (
                <CircularLoader />
              ) : (
                "Nu a fost adaugata imagine"
              )}
              {/* <div
                onClick={() => setOpenVideo(true)}
                className={styles.videoThumbnailDiv}
              >
                {videoSrc ? (
                  <>
                    <img
                      src="/media/dummy-content/video-thumbnail-dummy.jpeg"
                      alt="section tactic preview"
                    />

                    <div className={styles.playButtonDiv}>
                      <TFBIconButton
                        color="green"
                        name="play"
                        className={styles.playVideoButton}
                      />
                    </div>
                  </>
                ) : (
                  "Nu a fost adaugat video"
                )}
              </div> */}
            </div>
          </div>
        </div>
      </TFBDialog>
      {openVideo && (
        <TFBDialog
          open
          fullWidth={true}
          closePopup={() => {
            setOpenVideo(false);
          }}
          maxWidth="lg"
          title={sectionData?.["data"]?.["section_name"]}
        >
          <video
            style={{ width: "100%" }}
            controls
            src={sectionData?.["data"]?.["section_video"]}
          ></video>
        </TFBDialog>
      )}
      {openImage && (
        <TFBDialog
          open
          fullWidth={true}
          closePopup={() => {
            setOpenImage(false);
          }}
          maxWidth="md"
          title={sectionData?.["data"]?.["section_name"]}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "600px" }}
            src={sectionData?.["data"]?.["section_img"]}
            alt="training section"
          ></img>
        </TFBDialog>
      )}
    </>
  );
}
export default connect(({ layoutService, auth }: any) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth.user.token,
}))(ViewTrainingSectionPopup);
