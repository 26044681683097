import React, { useContext, useEffect, useRef, useState } from 'react';
import { isString } from 'lodash';
import { connect } from "react-redux";

import styles from "./TrainingBoard.module.scss";

import { BoardContext } from './BoardContext';

import useRenderButton from '../../../components/customHooks/useRenderButton';
import { showNotification, useAxiosPost } from '../../../components/customHooks/useAxiosPost';

import TFBIconButton from '../../../components/design-system/IconButton/TFBIconButton';
import TFBButton from '../../../components/design-system/Button/TFBButton';
import TFBIcon from '../../../components/design-system/Icon/TFBIcon';
import TFBDialog from '../../../components/design-system/Dialog/TFBDialog';
import TFBInput from '../../../components/design-system/Input/TFBInput';
import TFBDropdown from '../../../components/design-system/Dropdown/TFBDropdown';

const Header = ({ deleteSelected, rotateSelected, clearAll, getCanvasObjects, getCanvasImage, languagePack, token }) => {
    const { headerMode, showGrid, handleShowGrid, selectedShapeLabel, setSelectedShapeLabel, setActiveInput, isDragging, setStep, id_training_section, section_name, initialBoardData, closeTacticBoard, refreshList, fontSize, setFontSize } = useContext(BoardContext);

    const [openDeleteAll, setOpenDeleteAll] = useState(false);
    const [openDeleteBoard, setOpenDeleteBoard] = useState(false);

    const inputRef = useRef(null);

    const { postData: saveCanvasPost, loading: loadingSaveCanvas } = useAxiosPost("training/save_training_section_board");
    const { postData: deleteCanvasPost, loading: loadingDeleteCanvas } = useAxiosPost("training/delete_training_section_board");

    const handleInputChange = e => {
        const value = e.target.value;
        if (isString(value)) {
            if (value.length < 3) {
                setSelectedShapeLabel(value);
            }
        }
    }

    const handleInputFocus = () => {
        setActiveInput(true);
    }

    const handleInputBlur = () => {
        setActiveInput(false);
    }

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closeTacticBoard();
            refreshList();
            showNotification(
                "success",
                languagePack.tactic_board,
                languagePack.success_save_tactic_board
            );
        } else {
            showNotification(
                "danger",
                languagePack.tactic_board,
                languagePack.fail_save_tactic_board
            );
        }
    };

    const saveCanvas = async () => {
        const json = getCanvasObjects();
        const image_base64 = await getCanvasImage();

        const payload = {
            token,
            id_training_section,
            json: json,
            image_base64: image_base64,
        };

        saveCanvasPost(payload, handleResponse);
    }

    const handleDeleteCanvasResponse = (response) => {
        if (response?.success == 1) {
            closeTacticBoard();
            refreshList();
            showNotification(
                "success",
                languagePack.tactic_board,
                languagePack.success_delete_tactic_board
            );
        } else {
            showNotification(
                "danger",
                languagePack.tactic_board,
                languagePack.fail_delete_tactic_board
            );
        }
    };

    const deleteCanvas = async () => {
        const payload = { token, id_training_section, };

        deleteCanvasPost(payload, handleDeleteCanvasResponse);
    }

    const downloadImage = async () => {
        const image_base64 = await getCanvasImage();
        if (image_base64) {
            const image = new Image();
            image.src = image_base64;

            image.onload = () => {
                // Create a new canvas with the same dimensions as the image
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = image.width;
                canvas.height = image.height;

                // Fill canvas with a white background
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                // Draw the original image onto the white background
                ctx.drawImage(image, 0, 0);

                // Convert the canvas to a base64 image
                const whiteBackgroundImage = canvas.toDataURL("image/png");

                // Create a download link for the new image
                var a = document.createElement("a");
                a.href = whiteBackgroundImage;
                a.download = `${section_name}.png`;
                a.click();
            };
        }
    }

    const openPopup = () => setOpenDeleteAll(true);
    const closePopup = () => setOpenDeleteAll(false);

    const openDeletePopup = () => setOpenDeleteBoard(true);
    const closeDeletePopup = () => setOpenDeleteBoard(false);

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => {
            clearAll();
            closePopup();
        },
        "red",
        "delete",
        languagePack.admin_clear_all,
    );

    const { renderBtn: BtnLeftDeleteBoard } = useRenderButton(
        () => closeDeletePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRightDeleteBoard } = useRenderButton(
        () => {
            deleteCanvas();
            closeDeletePopup();
        },
        "red",
        "delete",
        languagePack.admin_delete,
    );

    return (
        <>
            <div className={styles['content-creation-header']}>
                {!initialBoardData?.json ?
                    <TFBIconButton
                        name='arrow-left'
                        color="darkGray"
                        onClick={() => setStep(1)}
                    /> :
                    <div style={{ width: 35 }}></div>
                }
                <div className={styles['diagram-creator-contextual-btns']}>
                    {headerMode === 1 &&
                        <>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='delete' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={openPopup}
                            >
                                {languagePack.admin_clear_all}
                            </TFBButton>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name={showGrid ? "grid" : 'no-grid'} color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={handleShowGrid}
                            >
                                {languagePack.admin_toggle_grid}
                            </TFBButton>
                        </>
                    }
                    {headerMode === 2 &&
                        <>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='delete' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={deleteSelected}
                            >
                                {languagePack.admin_delete}
                            </TFBButton>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='rotate' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={rotateSelected}
                            >
                                {languagePack.admin_rotate}
                            </TFBButton>
                        </>
                    }
                    {headerMode === 3 &&
                        <TFBButton
                            color="white"
                            renderIcon={() => <TFBIcon name='delete' color="gray" />}
                            startIcon
                            style={{ border: "2px solid #ddd" }}
                            onClick={deleteSelected}
                        >
                            {languagePack.admin_delete}
                        </TFBButton>
                    }
                    {headerMode === 4 &&
                        <>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='delete' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={deleteSelected}
                            >
                                {languagePack.admin_delete}
                            </TFBButton>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='rotate' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={rotateSelected}
                            >
                                {languagePack.admin_rotate}
                            </TFBButton>
                            <TFBInput
                                placeholder={languagePack.label}
                                value={selectedShapeLabel}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                style={{ width: 110 }}
                            />
                        </>
                    }
                    {headerMode === 5 &&
                        <>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='delete' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={deleteSelected}
                            >
                                {languagePack.admin_delete}
                            </TFBButton>
                            <TFBButton
                                color="white"
                                renderIcon={() => <TFBIcon name='rotate' color="gray" />}
                                startIcon
                                style={{ border: "2px solid #ddd" }}
                                onClick={rotateSelected}
                            >
                                {languagePack.admin_rotate}
                            </TFBButton>
                            <TFBDropdown
                                selection
                                color="white"
                                options={[10, 12, 14, 18, 24, 30].map(e => ({ value: e, text: e }))}
                                value={fontSize}
                                onChange={(e, v) => setFontSize(v.value)}
                                className={styles['font-size-dropdown']}
                            />
                        </>
                    }
                </div>
                <div className={styles['diagram-creator-contextual-btns']}>
                    {/* {initialBoardData?.json &&
                        <TFBButton
                            color="red"
                            renderIcon={() => <TFBIcon name='delete' />}
                            startIcon
                            onClick={openDeletePopup}
                            loading={loadingDeleteCanvas}
                        >
                            {languagePack.delete_board}
                        </TFBButton>
                    } */}
                    <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name='arrow-down' />}
                        startIcon
                        onClick={downloadImage}
                    >
                        {languagePack.download_board}
                    </TFBButton>
                    <TFBButton
                        color="green"
                        onClick={saveCanvas}
                        loading={loadingSaveCanvas}
                    >
                        {languagePack.admin_save}
                    </TFBButton>
                </div>
            </div>
            <TFBDialog
                className="pop-up-dialog-box prevent-select-text"
                title={languagePack["admin_are_you_sure"]}
                open={openDeleteAll}
                closePopup={closePopup}
                btnLeft={BtnLeft}
                btnRight={BtnRight}
            >
                <div className="pop-up-dialog-delete-text">
                    {languagePack.delete_diagram_training_board_message}
                </div>
            </TFBDialog>
            <TFBDialog
                className="pop-up-dialog-box prevent-select-text"
                title={languagePack["tactic_board"]}
                open={openDeleteBoard}
                closePopup={closeDeletePopup}
                btnLeft={BtnLeftDeleteBoard}
                btnRight={BtnRightDeleteBoard}
            >
                <div className="pop-up-dialog-delete-text">
                    {languagePack.delete_file_diagram_training_board_message}
                </div>
            </TFBDialog>
        </>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(Header);