import React from "react";
import ActiveInjuries from "./ActiveInjuries";
import InjuriesHistory from "./InjuriesHistory";

class Injuries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      injuriesHistoryKey: undefined
    };
  }

  refreshInjuryHistory = () => {
    this.setState({
      injuriesHistoryKey: `${Date.now()}-history-injury`
    })
  }

  render() {
    return (
      <div className="row">
        <ActiveInjuries refreshInjuryHistory={this.refreshInjuryHistory}/>
        <InjuriesHistory injuriesHistoryKey={this.state.injuriesHistoryKey}/>
      </div>
    );
  }
}

export default Injuries;
