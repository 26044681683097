import React from "react";
import DataTable from "react-data-table-component";
import { Icon, Button as ButtonS, Dropdown } from "semantic-ui-react";
import Cashflow from "./Cashflow";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import axios from "axios";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import { logout, updateUserLanguage } from "./../../../utils/api";
import { bindActionCreators } from "redux";
import * as constants from "./../../../../app/utils/constants";
import "../Financial.scss";
import { baseUrl } from "../../../utils/utilFunctions";

const currencies = Object.entries(constants.currencies).map(
  ([uniqueSlug, value]) => value
);

class IncomeOutcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: new Date().getFullYear(),
      addedCategory: "",
      addCategoryOpen: false,
      addCategoryType: "",
      totals: [],
      rates: {},
      CurrencyDialog: {},
      selectedCurrencyDropdown: this.props.user.currency,
    };
  }

  componentDidMount = () => {
    this.calculateTotals();
  };

  calculateTotals = () => {
    const year = this.state.selectedYear;
    axios
      .get("finances/get_totals", {
        params: {
          year,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          //prevent state update from an old unneded call
          if (year === this.state.selectedYear) {
            this.setState({ totals: res.data });
          }
        }
      })
      .catch((err) => console.log(err));
    this.getCurrency();
  };
  onChangeYear = (inc) => {
    this.setState(
      {
        selectedYear: this.state.selectedYear + inc,
      },
      () => {
        this.calculateTotals();
      }
    );
  };
  handleChange = (event) => {
    this.setState({ addedCategory: event.target.value });
  };

  handleClose = () => {
    this.setState({ addCategoryOpen: false, addedCategory: "" });
  };

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={Math.round(this.convertCurrency(value))}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
  };

  getCurrency = () => {
    fetch(baseUrl("club/get_currency_rates"))
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };

  convertCurrency = (value) => {
    if (this.props.currency === constants.currencies.EUR.uniqueSlug) {
      return value;
    } else {
      return value * this.state.rates[this.props.currency];
    }
  };

  handleAddCategory = () => {
    const payload = {
      id_club: this.props.user.id_club,
      cashflow_name: this.state.addedCategory,
      cashflow_type: this.state.addCategoryType,
      year: this.state.selectedYear,
    };
    axios
      .post("finances/add_category/", payload, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            addCategoryOpen: false,
            addedCategory: "",
            openPopupCategoryId: undefined,
            totals: this.state.totals.map((item) =>
              item.id === this.state.openPopupCategoryId
                ? { ...item, refreshCashflowKey: Date.now() }
                : item
            ),
          });
          store.addNotification({
            title: "Operatiuni cashflow",
            message: "Categoria a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  onCloseCurrencyDialog = () => {
    this.setState({
      CurrencyDialog: {
        ...this.state.CurrencyDialog,
        open: false,
      },
    });
  };

  updateUserLanguage = () => {
    this.props.updateUserLanguage(
      this.props.user,
      this.state.CurrencyDialog.currency
    );
    this.setState({
      selectedCurrencyDropdown: this.state.CurrencyDialog.currency,
    });
    this.onCloseCurrencyDialog(this.state.CurrencyDialog.currency);
  };

  render() {
    const conditionalRowStyles = [
      {
        when: (row) => row.id === 1,
        style: {
          backgroundColor: "rgba(63, 195, 128, 0.9)",
        },
      },
      {
        when: (row) => row.id === 2,
        style: {
          backgroundColor: "#ed5a4a",
        },
      },
      {
        when: (row) => row.name === "Total",
        style: {
          backgroundColor: "#949199",
          color: "white",
        },
      },
    ];
    const columns = [
      {
        name: "Nume",
        selector: "name",
        sortable: true,
        grow: 2,
      },
      {
        center: true,
        name: "Ianuarie",
        selector: "1",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[1] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[1]),
      },
      {
        center: true,
        name: "Februarie",
        selector: "2",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[2] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[2]),
      },
      {
        center: true,
        name: "Martie",
        selector: "3",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[3] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[3]),
      },
      {
        center: true,
        name: "Aprilie",
        selector: "4",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[4] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[4]),
      },
      {
        center: true,
        name: "Mai",
        selector: "5",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[5] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[5]),
      },
      {
        center: true,
        name: "Iunie",
        selector: "6",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[6] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[6]),
      },
      {
        center: true,
        name: "Iulie",
        selector: "7",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[7] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[7]),
      },
      {
        center: true,
        name: "August",
        selector: "8",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[8] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[8]),
      },
      {
        center: true,
        name: "Septembrie",
        selector: "9",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[9] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[9]),
      },
      {
        center: true,
        name: "Octombrie",
        selector: "10",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[10] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[10]),
      },
      {
        center: true,
        name: "Noiembrie",
        selector: "11",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[11] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[11]),
      },
      {
        center: true,
        name: "Decembrie",
        selector: "12",
        sortable: true,
        conditionalCellStyles: [
          {
            when: (row) => row[12] < 0,
            style: {
              color: "#d93300",
              fontWeight: 600,
            },
          },
        ],
        cell: (row) => this.formatNumber(row[12]),
      },
      {
        name: "",
        selector: "edit",
        cell: (row) => {
          return row.id === 1 || row.id === 2 ? (
            <Icon
              className="cursor-pointer"
              name="add"
              color="black"
              onClick={() =>
                this.setState({
                  addCategoryOpen: true,
                  addCategoryType: row.id === 1 ? "income" : "expenditure",
                  openPopupCategoryId: row.id,
                })
              }
            />
          ) : (
            ""
          );
        },
      },
    ];
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        <div className="cashflow-container">
          <Dialog
            open={this.state.CurrencyDialog.open}
            onClose={this.onCloseCurrencyDialog}
          >
            <DialogContent>
              <div className="cashflow-change-currency-popup">
                {languagePack.this_will_overwrite_your_preferred_currency}
              </div>

              <div className="buttons-container">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={this.onCloseCurrencyDialog}
                >
                  {languagePack.Cancel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: 10 }}
                  onClick={this.updateUserLanguage}
                >
                  {languagePack.Confirm}
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <div className="cashflow-header">
            <div className="cashflow-header-title">
              {languagePack.Cashflow}
              <Icon
                className="cursor-pointer"
                name="angle left"
                color="black"
                onClick={() => this.onChangeYear(-1)}
                // disabled={
                //   !(this.state.selectedYear > new Date().getFullYear() - 2)
                // }
              />
              {this.state.selectedYear}
              <Icon
                className="cursor-pointer"
                name="angle right"
                color="black"
                onClick={() => this.onChangeYear(1)}
                // disabled={
                //   !(this.state.selectedYear < new Date().getFullYear() + 2)
                // }
              />
            </div>
            <div className="cashflow-header-buttons">
              <Dropdown
                selection
                placeholder="Selecteaza moneda"
                className="currency-dropdown-cashflow"
                value={this.state.selectedCurrencyDropdown}
                text={this.state.selectedCurrencyDropdown}
              >
                <Dropdown.Menu>
                  {currencies.map((item) => {
                    return (
                      <Dropdown.Item
                        key={item.value}
                        active={
                          item.value === this.state.selectedCurrencyDropdown
                        }
                        {...item}
                        onClick={
                          this.props.currency !== item.uniqueSlug
                            ? () =>
                              this.setState({
                                CurrencyDialog: {
                                  open: true,
                                  currency: item.uniqueSlug,
                                },
                              })
                            : () => { }
                        }
                      />
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <DataTable
            center
            columns={columns}
            data={this.state.totals}
            highlightOnHover
            fixedHeader
            fixedHeaderScrollHeight="70vh"
            expandableRows
            expandableRowsComponent={
              <Cashflow
                selectedYear={this.state.selectedYear}
                rates={this.state.rates}
                selectedCurrency={this.props.currency}
              />
            }
            className="data-table-text-wrap"
            expandableRowExpanded={(row) => row.id < 3}
            conditionalRowStyles={conditionalRowStyles}
          />

          <Dialog
            open={this.state.addCategoryOpen}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <div style={{ display: "flex" }}>
                {languagePack.Add_category_to}{" "}
                {this.state.addCategoryType === "income" ? (
                  <div
                    style={{ color: "rgba(63, 195, 128, 0.9)", marginLeft: 10 }}
                  >
                    {languagePack.PROFIT}
                  </div>
                ) : (
                  <div style={{ color: "#ed5a4a", marginLeft: 10 }}>
                    {languagePack.SPENDING}
                  </div>
                )}
              </div>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label={languagePack.Name}
                type="text"
                fullWidth
                value={this.state.addedCategory}
                onChange={this.handleChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                {languagePack.Cancel}
              </Button>
              <Button
                onClick={this.handleAddCategory}
                color="primary"
                disabled={!this.state.addedCategory}
              >
                {languagePack.Add}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      updateUserLanguage,
    },
    dispatch
  );
};
const mapStateToProps = ({ layoutService: { languagePack }, auth }) => ({
  languagePack,
  token: auth?.user?.token,
  currency: auth?.user?.currency,
  user: auth?.user || {},
  currencyItemSelected:
    constants.currencies[auth?.user?.currency] || constants.currencies.EUR,
});
export default connect(mapStateToProps, mapDispatchToProps)(IncomeOutcome);
