import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import Moment from "react-moment";
import "./NextMatches.scss";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function NextMatches(props) {
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        // `rapidapi/get_upcoming_matches?token=${props.token}&next=3&id_club=${props.currentUser.id_club}`
        // `/instat/get_next_matches?next=3&id_club=${props.currentUser.id_club}`
        `/matches/get_next_matches_football_api_dashboard?id_club=${props.currentUser.id_club}`
      );
      if (result?.data?.go_to_login === 1) {
        props.logout(props.token);
      } else {
        let eventsList = result?.data;
        eventsList = eventsList.map((event) => {
          return {
            ...event,
            competition:
              props.languagePack[event.competition] || event.competition,
          };
        });

        // setMatches(Array.isArray(result.data) ? result.data : []);
        setMatches(Array.isArray(eventsList) ? eventsList : []);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let eventsList = [...matches];
    eventsList = eventsList.map((event) => {
      return {
        ...event,
        competition: props.languagePack[event.competition] || event.competition,
      };
    });
    setMatches(Array.isArray(eventsList) ? eventsList : []);
  }, [props.languagePack]);

  return (
    <div className="card" style={{ border: 0, marginBottom: 20 }}>
      {/* {matches.length > 0 || props.currentUser.id_club == 1 ? ( */}
      {matches.length > 0 ? (
        <div className="row cancelmargins next-matches-widget">
          {matches.map((match, index) => {
            return (
              <div className="match-widget row-widget" key={match.id_match}>
                <div className="match-round">
                  {match.competition + ", "}
                  {match.round != null && match.round != ""
                    ? match.round.toUpperCase().includes("ETAPA")
                      ? match.round
                      : "Etapa " +
                      match.round.charAt(0).toUpperCase() +
                      match.round.slice(1)
                    : ""}
                </div>
                <div className="match-content">
                  <div className="match-home">
                    <div className="team-logo">
                      <img src={match.home_team_url} />
                    </div>
                    <div className="team-name">{match.team_home_name}</div>
                  </div>
                  <div className="match-vs">vs</div>
                  <div className="match-away">
                    <div className="team-logo">
                      <img src={match.away_team_url} />
                    </div>
                    <div className="team-name"> {match.team_away_name}</div>
                  </div>
                </div>
                <div className="match-date">
                  {match.match_time !== "02:00" ? (
                    <Moment format="DD MMM YYYY, HH:mm">
                      {match.match_date}
                    </Moment>
                  ) : (
                    <Moment format="DD MMM YYYY">{match.match_date}</Moment>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        /*Placeholder loading */
        <div className="row cancelmargins dashboard-next-matches-placeholders">
          <div className="col-lg-4">
            <div className="placeholder-round">
              <Skeleton variant="text" width={130} />
            </div>
            <div className="placeholder-teams">
              <Skeleton variant="circle" width={90} height={90} />
              <Skeleton variant="text" width={40} height={15} />
              <Skeleton variant="circle" width={90} height={90} />
            </div>
            <div className="placeholder-date">
              <Skeleton variant="text" width={150} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="placeholder-round">
              <Skeleton variant="text" width={130} />
            </div>
            <div className="placeholder-teams">
              <Skeleton variant="circle" width={90} height={90} />
              <Skeleton variant="text" width={40} height={15} />
              <Skeleton variant="circle" width={90} height={90} />
            </div>
            <div className="placeholder-date">
              <Skeleton variant="text" width={150} />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="placeholder-round">
              <Skeleton variant="text" width={130} />
            </div>
            <div className="placeholder-teams">
              <Skeleton variant="circle" width={90} height={90} />
              <Skeleton variant="text" width={40} height={15} />
              <Skeleton variant="circle" width={90} height={90} />
            </div>
            <div className="placeholder-date">
              <Skeleton variant="text" width={150} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(NextMatches);
