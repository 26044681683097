import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "./../../../utils/api";
import "./Costs.scss";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import EditDepartment from "./EditDepartment";
import DeleteDepartment from "./DeleteDepartment";

class DepartmentsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_departments_list: props.isOpenPopup,
            open_edit_department: false,
            editing_department: {},

            open_delete_department: false,
            deleting_department: {},
        };
    }

    openEditDepartment = (department) => {
        this.setState({ open_edit_department: true, editing_department: department, show_departments_list: false })
    }

    closeEditDepartment = () => {
        console.log(this.props.list)
        this.setState({ open_edit_department: false, editing_department: {}, show_departments_list: true })
    }

    openDeleteDepartment = (department) => {
        this.setState({ open_delete_department: true, deleting_department: department, show_departments_list: false })
    }

    closeDeleteDepartment = () => {
        this.setState({ open_delete_department: false, deleting_department: {}, show_departments_list: true })
    }

    render() {
        const { languagePack, closePopup, refreshList, list } = this.props;
        const departments = [...list];

        return (
            <>
                <TFBDialog
                    className="pop-up-dialog-box prevent-select-text"
                    title={languagePack.departments_list}
                    open={this.state.show_departments_list}
                    closePopup={closePopup}
                >
                    {departments.length > 0 ?
                        <TableContainer>
                            <Table faria-label="simple table" className="costs-table">
                                <TableHead>
                                    <TableRow>
                                        <TFBTableCell>
                                            {languagePack.department_name}
                                        </TFBTableCell>
                                        <TFBTableCell align="right">
                                            {languagePack.product_actions}
                                        </TFBTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="costs-table-content">
                                    {departments.map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                                            className="cost-row-content"
                                        >
                                            <TFBTableCell
                                                sx={sxStickyColumn}
                                                style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                                            >
                                                {row.label}
                                            </TFBTableCell>
                                            <TFBTableCell>
                                                <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                                                    <TFBIconButton
                                                        name="edit"
                                                        color="darkGray"
                                                        onClick={() => this.openEditDepartment(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="delete"
                                                        color="darkGray"
                                                        onClick={() => this.openDeleteDepartment(row)}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <TFBPlaceholder text={languagePack.no_departments} />}
                </TFBDialog>

                {/* ------------------- EDIT DEPARTMENT ---------------- */}
                {this.state.open_edit_department &&
                    <EditDepartment
                        isOpenPopup={this.state.open_edit_department}
                        closePopup={this.closeEditDepartment}
                        refreshList={refreshList}
                        departmentData={this.state.editing_department}
                    />
                }

                {/* ------------------- DELETE ORDER ---------------- */}
                {this.state.open_delete_department &&
                    <DeleteDepartment
                        isOpenPopup={this.state.open_delete_department}
                        closePopup={this.closeDeleteDepartment}
                        refreshList={refreshList}
                        departmentData={this.state.deleting_department}
                    />
                }
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
}

export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(DepartmentsList);
