import React from "react";
import axios from "axios";
import { Input, Dropdown, Button } from "semantic-ui-react";
import { connect } from "react-redux";

class AddNewParamForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      category: "",
      um: "",
    };
  }

  submitNewParam = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      physical_parameter_name: this.state.name,
      um: this.state.um,
      category: this.state.category,
    };

    axios.post("report/submit_new_param", payload);
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  render() {
    return (
      <div className="add-param-container">
        <Dropdown
          placeholder="Categorie"
          className="add-param-form-element"
          name="category"
          onChange={this.handleChangeDropdown}
          value={this.state.category}
          fluid
          selection
        />
        <Input
          placeholder="Nume"
          className="add-param-form-element"
          name="name"
          onChange={this.handleChangeInput}
          value={this.state.name}
        />
        <Input
          placeholder="Unitate de masura"
          className="add-param-form-element"
          name="um"
          onChange={this.handleChangeInput}
          value={this.state.um}
        />
        <div className="add-param-buttons-container">
          <Button primary style={{ width: 220 }}>
            Anuleaza
          </Button>
          <Button primary style={{ width: 220 }}>
            Salveaza
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddNewParamForm);
