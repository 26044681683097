import React, { useState, useEffect } from 'react';
import Loader from "react-loader-spinner";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import { useParams } from 'react-router';
import { baseUrl } from '../../utils/utilFunctions';

export default function ShortlistPlayers(props) {
    const [shortlistName, setShortlistName] = useState("WL - Wide playmaker");
    const [isFetching, setIsFetching] = useState(false);
    const [players, setPlayers] = useState([]);

    const params = useParams();

    useEffect(() => {
        getPlayers()
    }, [])

    function getPlayers() {
        axios.get(`transferlab/shortlist_players?id=${params.id}`)
            .then((res) => {
                setPlayers(res.data.players)
                setShortlistName(res.data.sh_name)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="player-search-page-container">
            <div className="player-search-page-header">
                <div className="ps-title">{shortlistName}</div>

            </div>
            {isFetching ? (
                <div className="loader-container" style={{ marginTop: 50 }}>
                    <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={70} width={70} />
                </div>
            ) : (<div className="player-search-list">
                {players.map((item, index) => (
                    <div className="player-box" key={index}>
                        <div className="row-1">
                            <Avatar
                                src={baseUrl("uploads/placeholder_player.png")}
                                className="player-avatar"
                            />
                        </div>
                        <div
                            className="row-1"
                            style={{
                                paddingBottom: 20,
                                borderBottom: "solid  1px #f5f5f5",
                                alignItems: "center",
                                marginBottom: 20,
                            }}
                        >
                            <div className="player-name">{item.player_name}</div>
                            <div className="player-value">{item.quality}</div>
                        </div>
                        <div className="row-3">
                            <div className="team-badge">
                                <img
                                    src={baseUrl("uploads/placeholder_team.png")}
                                    alt="team-badge"
                                />
                            </div>
                            <div className="country-badge">
                                <img
                                    src={baseUrl("uploads/placeholder_country.png")}
                                    alt="country-badge"
                                />
                            </div>
                            <div className="position-age">
                                <div className="position-row">{item.position}</div>
                                <div className="stat-row">
                                    <div className="stat-name">Age:</div>
                                    <div className="stat-value">
                                        {item.player_birthday
                                            ? moment().diff(item.player_birthday, "years")
                                            : "-"}
                                    </div>
                                </div>
                            </div>
                            <div className="mins-height">
                                <div className="stat-row">
                                    <div className="stat-name">Mins:</div>
                                    <div className="stat-value">2578</div>
                                </div>
                                <div className="stat-row">
                                    <div className="stat-name">Height:</div>
                                    <div className="stat-value">
                                        {item.height > 0 ? item.height : "-"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>)}
        </div>
    )
}