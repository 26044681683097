import React from "react";
import "./StaffSettings.scss";
import { Button, Icon, Input, Checkbox } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";

class StaffSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffData: [],
      newsLoaded: false,
    };
  }

  componentDidMount = () => {
    this.getStaff();
  };

  getStaff = () => {
    axios
      .get(
        `club/get_staff_members?id_club=${this.props.currentUser.id_club}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({ staffData: res.data, newsLoaded: true });
      })
      .catch((err) => console.log(err));
  };

  handleChangeOrder = (value, index) => {
    let currentStaff = this.state.staffData;
    currentStaff[index].order_in_site = value;

    this.setState({ staffData: currentStaff });
  };

  handleShowOnSite = (index, data) => {
    let currentStaff = this.state.staffData;
    currentStaff[index].show_on_site = { false: "0", true: "1" }[data.checked];

    this.setState({ staffData: currentStaff });
  };

  handleSaveSettings = () => {
    const payload = [];

    this.state.staffData.forEach((staff) => {
      if (
        staff.order_in_site == null ||
        staff.order_in_site == undefined ||
        staff.order_in_site == ""
      ) {
        payload.push({
          id_staff_member: staff.id_staff_member,
          show_on_site: staff.show_on_site,
          order_in_site: "0",
        });
      } else {
        payload.push({
          id_staff_member: staff.id_staff_member,
          show_on_site: staff.show_on_site,
          order_in_site: staff.order_in_site,
        });
      }
    });

    axios
      .post("site/save_staff_member_settings_on_site", payload)
      .then(() => {
        this.getStaff();

        store.addNotification({
          title: "Administrare stiri",
          message: "Setarile au fost salvate cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        {" "}
        <div className="news-page-container">
          <div className="news-page-header">
            <div className="news-page-title">
              {languagePack.staff_management}
            </div>
          </div>
          {!this.state.newsLoaded ? (
            <div className="loader-container">
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <div className="last-news-list">
              <div className="last-news-row-header desktop-header">
                <div className="news-title">{languagePack.campaign_name}</div>

                <div className="news-date">{languagePack.number_order}</div>
                <div className="news-option">
                  {languagePack.display_on_website}
                </div>
              </div>
              {this.state.staffData.map((item, index) => {
                return (
                  <div
                    className="last-news-row"
                    key={index}
                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                  >
                    <div className="header-row">
                      <div className="mobile-header">
                        <div className="news-title">
                          {languagePack["news_page_header_title"]}
                        </div>
                        {/* <div className="news-tag">
                          {languagePack["news_page_header_tag"]}
                        </div> */}
                        <div className="news-date">
                          {languagePack["news_page_header_date"]}
                        </div>
                        <div className="news-option">
                          {languagePack["news_page_header_options"]}
                        </div>
                      </div>
                    </div>
                    <div className="content-row">
                      <div className="news-title">{item.full_name}</div>
                      {/* <div className="news-tag">{item.news_tag}</div> */}
                      <div className="news-date">
                        {/* <Moment format="DD.MM.YYYY,  HH:mm">
                          {item.date_add}
                        </Moment> */}
                        <Input
                          // icon={{ name: "search", circular: true, link: true }}
                          defaultValue={item.order_in_site ?? "0"}
                          // value={item.order_in_site}
                          type="number"
                          placeholder="Număr"
                          className="number"
                          style={{ width: "40%", marginBottom: 20 }}
                          onChange={(event) =>
                            this.handleChangeOrder(event.target.value, index)
                          }
                        />
                      </div>
                      <div className="news-option">
                        <Checkbox
                          //   label={"Adauga locatia la managementul terenurilor"}
                          checked={item.show_on_site == 0 ? false : true}
                          onChange={(event, data) =>
                            this.handleShowOnSite(index, data)
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="final-settings-submit-button-row">
                <Button
                  className="final-submit-button"
                  onClick={this.handleSaveSettings}
                >
                  {/* {languagePack["save_report"]}{" "} */}
                  Salvează setări
                  <Icon name="add" className="final-submit-button-icon" />{" "}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(StaffSettings);
