import React, { useState, useEffect } from "react";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./Ranking.scss";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import { Dropdown, Modal, Icon, Button } from "semantic-ui-react";

export function Ranking(props) {
  const [teams, setTeams] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetition, setSelectedCompetition] = useState("");
  const { languagePack } = props;

  useEffect(() => {
    // const fetchData = async () => {
    //   const result = await axios(
    //     `rapidapi/get_standings?token=${props.token}&id_club=${props.currentUser.id_club}`
    //   );
    //   if (result?.data?.go_to_login === 1) {
    //     props.logout(props.token);
    //   } else {
    //     setTeams(Array.isArray(result.data) ? result.data : []);
    //   }
    // };

    // fetchData();
    axios
      .get(
        `rapidapi/get_competitions_for_dashboard?id_club=${props.currentUser.id_club}`
      )
      .then((res) => {
        setCompetitions(res.data);
        if (res.data.length > 0) {
          setSelectedCompetition(res.data[0].value);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedCompetition != "") {
      axios
        .get(
          `rapidapi/get_standings_by_competition?id_competition=${selectedCompetition}&id_club=${props.currentUser.id_club}`
        )
        .then((res) => {
          setTeams(res.data);
        })
        .catch((error) => console.log(error));
    }
  }, [selectedCompetition]);

  const handleChangeDropdownCompetition = (e, value) => {
    setSelectedCompetition(value.value);
  };

  return (
    <div className="card card-custom card-ranking" style={{ padding: 50 }}>
      <div className="ranking-header">
        <Dropdown
          placeholder={"Selecteaza competitia"}
          selection
          options={competitions}
          onChange={handleChangeDropdownCompetition}
          name="chosen_competition"
          value={selectedCompetition}
          className="players-season-dropdown"
        />
        <div className="ranking-title">{languagePack["ranking"]}</div>
      </div>
      {teams.length > 0 ? (
        <>
          <div className="standings-header dark-bg ranking-titles ranking-header ranking-row-header">
            <div className="row-position">
              {/* {languagePack["position"]} */}
            </div>
            <div className="row-team-name">
              {/* {languagePack["team_name"]} */}
              {languagePack.team}
            </div>
            <div className="row-matches-played">M</div>
            <div className="row-matches-played">W</div>
            <div className="row-matches-played">D</div>
            <div className="row-matches-played">L</div>
            <div className="row-matches-played">P</div>
          </div>
          <div className="ranking-table">
            {teams.map((team, index) => {
              return (
                <div
                  // className={
                  //   index % 2 == 0
                  //     ? "hoverable-row light-bg"
                  //     : "hoverable-row dark-bg"
                  // }
                  className={`hoverable-row ${
                    index % 2 == 0 ? "light-bg" : "dark-bg"
                  } ${team.own_team == 1 ? "own-team" : ""}`}
                  key={"rank-row-id-team-" + index}
                >
                  <div className="ranking-row">
                    <div className="row-position row-line">
                      {team.position}.
                    </div>
                    <div
                      className="row-team-name row-line"
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {team.team_name}
                    </div>
                    <div className="row-matches-played row-line">
                      {team.matches_played}
                    </div>
                    <div className="row-matches-played row-line">
                      {team.matches_won}
                    </div>
                    <div className="row-matches-played row-line">
                      {team.matches_draw}
                    </div>
                    <div className="row-matches-played row-line">
                      {team.matches_lost}
                    </div>
                    <div className="row-matches-played row-line">
                      {team.points}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div>
          {[...Array(16)].map((x, i) => (
            <div className="standings-header-placeholder" key={i}>
              <Skeleton variant="circle" width={30} height={30} />
              <div style={{ marginLeft: 15 }}>
                <Skeleton variant="text" width={300} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Ranking);
