import React, { useState, useRef, useContext, useEffect } from "react";
import "./CheckCode.scss";
import { CreateAccountContext } from "../CreateAccount";
import CircularLoader from "../../components/reusable/Loader";
import { clone, forEach, map, range } from "lodash";

const CODE_LENGTH = 6;

const CheckCode = ({ loadingCheck, languagePack, focusInput }) => {
  // ============ HOOKS ============
  // ==================================
  const [checkCode, setCheckCode] = useState(map(range(CODE_LENGTH), () => ""));
  const [selectedCheckCodeIndex, setSelectedCheckcCodeIndex] = useState(0);
  const [checkCodeInput, setCheckCodeInput] = useState("");
  const { setTrialCode, step } = useContext(CreateAccountContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (step == 1) {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }
  }, [step, focusInput])

  useEffect(() => {
    setTrialCode(checkCode.join(""));
  }, [checkCode])

  const handleInputChange = (e) => {
    let value = String(e.target.value);
    value = value.replace(/\D/g, "")

    let checkCodeInput = "";
    const clonedArray = clone(checkCode);
    let index = selectedCheckCodeIndex;

    if (value.length > 6 - index) {
      value = value.substring(0, 6 - index);
    }
    forEach(value, d => {
      const numberRegex = /^[0-9]$/;
      if (numberRegex.test(d) || d == "") {
        checkCodeInput = d;
        clonedArray[index] = d;
        if (index + 1 < checkCode.length) {
          index = index + 1;
          checkCodeInput = "";
        }
      }
    })
    setCheckCodeInput(checkCodeInput);
    setCheckCode(clonedArray);
    setSelectedCheckcCodeIndex(index);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && selectedCheckCodeIndex - 1 > -1) {
      if (checkCodeInput == "") {
        const clonedArray = clone(checkCode);
        clonedArray[selectedCheckCodeIndex - 1] = "";
        setCheckCode(clonedArray);
        setSelectedCheckcCodeIndex(selectedCheckCodeIndex - 1);
      }
    }
  };

  return (
    <div className="check-code-container">
      <label htmlFor="checkCodeInput" className="ds-secondary-text-auxiliary">
        {languagePack.verification_code}
      </label>

      {/* // ============ CODE ============
  // ====================================== */}
      <div className="input-container">
        <input
          ref={inputRef}
          className="ds-main-text-auxiliary input-digit-selected"
          type="text"
          autoFocus
          style={{ left: 54 * selectedCheckCodeIndex }}
          value={checkCodeInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={e => {
            if (e.relatedTarget === null) {
              e.target.focus();
            }
          }}
        />
        {map(checkCode, (digit, index) => (
          <div
            className="ds-main-text-auxiliary input-digit"
            key={"key-" + index}
          >{index < checkCode.length - 1 ? digit : ""}</div>
        ))}
      </div>
      {/* // ============ LOADER ============
  // ================================== */}
      {loadingCheck && <CircularLoader />}
    </div>
  );
};
export default CheckCode;
