import React from "react";
import axios from "axios";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Icon, Placeholder, Button } from "semantic-ui-react";
import { logout } from "./../../../utils/api";
import { bindActionCreators } from "redux";
import "../Club.styles.scss";
import "./PlayerProfile.scss";
import { connect } from "react-redux";
import moment from "moment";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
  DatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

class TransferHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      opneAddDialog: false,
      club_name: "",
      club_img: "",
      date_start: null,
      date_end: null,

      openEditDialog: false,
      id_player_history_item: "",

      openDeleteDialog: false,
    };
  }

  componentDidMount = () => {
    this.getTransferHistory();
  };

  componentDidUpdate(prevProps) {}

  getTransferHistory = () => {
    axios
      .get(
        `player/get_player_transfer_history?id_player=${this.props.id_player}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({
          history: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  closeAddDialog = () => {
    this.setState({
      opneAddDialog: false,
      club_name: "",
      club_img: "",
      date_start: null,
      date_end: null,
    });
  };

  handleDateChangeStart = (value) => {
    this.setState({ date_start: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ date_end: value });
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    if (file != undefined) {
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.setState({
          // fileSection: file,
          club_img: reader.result,
        });
      };
    }
  };

  addPlayerHistoryItem = () => {
    const payload = {
      id_player: this.props.id_player,
      id_club: this.props.currentUser.id_club,
      club_name: this.state.club_name,
      club_img: this.state.club_img,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
    };
    axios
      .post("player/add_player_transfer_history_item", payload)
      .then((res) => {
        if (res.data.success == 1) {
          this.closeAddDialog();
          this.getTransferHistory();
          store.addNotification({
            title: this.props.languagePack.career,
            // message: res.data.error,
            message: "Inregistrarea a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.career,
            message: "Ceva nu a mers bine. Mai incearca o data!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  closeEditDialog = () => {
    this.setState({
      openEditDialog: false,
      club_name: "",
      club_img: "",
      date_start: null,
      date_end: null,
      id_player_history_item: "",
    });
  };

  saveEditPlayerHistoryItem = () => {
    const payload = {
      id_player: this.props.id_player,
      id_club: this.props.currentUser.id_club,
      club_name: this.state.club_name,
      club_img: this.state.club_img,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      id_player_history_item: this.state.id_player_history_item,
    };
    axios
      .post("player/save_edit_player_transfer_history_item", payload)
      .then((res) => {
        if (res.data.success == 1) {
          this.closeEditDialog();
          this.getTransferHistory();
          store.addNotification({
            title: this.props.languagePack.career,
            // message: res.data.error,
            message: "Inregistrarea a fost actualizata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.career,
            message: "Ceva nu a mers bine. Mai incearca o data!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  closeDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      club_name: "",
      id_player_history_item: "",
    });
  };

  deletePlayerHistoryItem = () => {
    const payload = {
      id_player_history_item: this.state.id_player_history_item,
    };
    axios
      .post("player/delete_player_transfer_history_item", payload)
      .then((res) => {
        if (res.data.success == 1) {
          this.closeDeleteDialog();
          this.getTransferHistory();
          store.addNotification({
            title: this.props.languagePack.career,
            // message: res.data.error,
            message: "Inregistrarea a fost stearsa cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.career,
            message: "Ceva nu a mers bine. Mai incearca o data!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard className="transfer-history-widget">
        <TFBCardHeader title={languagePack.career}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => this.setState({ opneAddDialog: true })}
          >
            {languagePack.add_in_career}
          </TFBButton>
        </TFBCardHeader>
        {this.state.history.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TFBTableCell sx={sxStickyColumn}>
                    {languagePack.club_name}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.legitimation_period}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.order_actions}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.history.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    <TFBTableCell
                      sx={sxStickyColumn}
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <div className="transfer-history-club">
                        <div className="club-img">
                          <img src={row.club_img} />
                        </div>
                        <div className="club-name">{row.club_name}</div>
                      </div>
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {`${moment(row.date_start).format("DD.MM.YYYY")} - ${
                        row.date_end
                          ? moment(row.date_end).format("DD.MM.YYYY")
                          : ""
                      }`}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      <div className="actions-btns-container">
                        <TFBIconButton
                          name="edit"
                          color="darkGray"
                          onClick={() => {
                            this.setState({
                              openEditDialog: true,
                              club_name: row.club_name,
                              date_start: row.date_start,
                              date_end: row.date_end,
                              id_player_history_item:
                                row.id_player_history_item,
                            });
                          }}
                        />
                        <TFBIconButton
                          name="delete"
                          color="darkGray"
                          onClick={() => {
                            this.setState({
                              openDeleteDialog: true,
                              club_name: row.club_name,
                              id_player_history_item:
                                row.id_player_history_item,
                            });
                          }}
                        />
                      </div>
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TFBPlaceholder text={languagePack.no_records} />
        )}

        {/* ADAUGARE INREGISTRARE IN ISTORIC TRANSFER */}
        <Dialog open={this.state.opneAddDialog} maxWidth="xl">
          <DialogContent>
            <div className="player-transaction-popup-container">
              <div className="player-transaction-header">
                <div className="player-transaction-title">
                  {languagePack.add_in_career}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={this.closeAddDialog}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="add-event-form-container">
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.club_name}
                  onChange={(e) => this.setState({ club_name: e.target.value })}
                  placeholder={languagePack.club_name}
                  className="add-event-input"
                />
              </div>
              <div className="add-team-manual-image">
                <div className="image-input-container">
                  <span>{languagePack["team_logo"]}</span>
                  <input
                    type="file"
                    className="add-news-image-input"
                    id="customFile"
                    onChange={this.handleChangeImage}
                    accept="image/*"
                  />
                  <label
                    className="add-file-form-file-label"
                    htmlFor="customFile"
                  >
                    <div className="image-view">
                      {this.state.club_img !== "" ? (
                        <img src={this.state.club_img} />
                      ) : (
                        <span>
                          <Icon
                            name="arrow up"
                            className="cusor-pointer"
                            size="small"
                          />
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="add-event-form-row">
                  <DatePicker
                    emptyLabel={languagePack.starting_date}
                    value={this.state.date_start}
                    onChange={this.handleDateChangeStart}
                    ampm={false}
                    format="dd.MM.yyyy"
                    name="date-picker"
                    className="add-event-date-input"
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </div>
                <div className="add-event-form-row">
                  <DatePicker
                    value={this.state.date_end}
                    onChange={this.handleDateChangeEnd}
                    ampm={false}
                    format="dd.MM.yyyy"
                    name="date-picker"
                    emptyLabel={languagePack.ending_date}
                    className="add-event-date-input"
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="container-buttons-add-event">
                <Button
                  className="cancel-button"
                  onClick={() => this.closeAddDialog()}
                >
                  <Icon name="close" className="plus-icon-button" />
                  {languagePack.Cancel}
                </Button>

                <Button
                  className="add-button"
                  onClick={this.addPlayerHistoryItem}
                  disabled={
                    this.state.club_name.length == 0 ||
                    this.state.date_start == null
                    // || this.state.date_end == null
                  }
                >
                  <Icon name="add" className="plus-icon-button" />
                  {languagePack.Add}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* MODFIFICARE INREGISTRARE IN ISTORIC TRANSFER */}
        <Dialog open={this.state.openEditDialog} maxWidth="xl">
          <DialogContent>
            <div className="player-transaction-popup-container">
              <div className="player-transaction-header">
                <div className="player-transaction-title">
                  {languagePack.change_career}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={this.closeEditDialog}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="add-event-form-container">
              <div className="add-event-form-row">
                <input
                  type="text"
                  value={this.state.club_name}
                  onChange={(e) => this.setState({ club_name: e.target.value })}
                  placeholder={languagePack.club_name}
                  className="add-event-input"
                />
              </div>
              <div className="add-team-manual-image">
                <div className="image-input-container">
                  <span>{languagePack["team_logo"]}</span>
                  <input
                    type="file"
                    className="add-news-image-input"
                    id="customFile"
                    onChange={this.handleChangeImage}
                    accept="image/*"
                  />
                  <label
                    className="add-file-form-file-label"
                    htmlFor="customFile"
                  >
                    <div className="image-view">
                      {this.state.club_img !== "" ? (
                        <img src={this.state.club_img} />
                      ) : (
                        <span>
                          <Icon
                            name="arrow up"
                            className="cusor-pointer"
                            size="small"
                          />
                        </span>
                      )}
                    </div>
                  </label>
                </div>
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="add-event-form-row">
                  <DatePicker
                    emptyLabel={languagePack.starting_date}
                    value={this.state.date_start}
                    onChange={this.handleDateChangeStart}
                    ampm={false}
                    format="dd.MM.yyyy"
                    name="date-picker"
                    className="add-event-date-input"
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </div>
                <div className="add-event-form-row">
                  <DatePicker
                    value={this.state.date_end}
                    onChange={this.handleDateChangeEnd}
                    ampm={false}
                    format="dd.MM.yyyy"
                    name="date-picker"
                    emptyLabel={languagePack.ending_date}
                    className="add-event-date-input"
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="container-buttons-add-event">
                <Button
                  className="cancel-button"
                  onClick={() => this.closeAddDialog()}
                >
                  <Icon name="close" className="plus-icon-button" />
                  {languagePack.Cancel}
                </Button>

                <Button
                  className="add-button"
                  onClick={this.saveEditPlayerHistoryItem}
                  disabled={
                    this.state.club_name.length == 0 ||
                    this.state.date_start == null
                    // || this.state.date_end == null
                  }
                >
                  <Icon name="add" className="plus-icon-button" />
                  {"Salveaza"}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* STERGERE INREGISTRARE IN ISTORIC TRANSFER */}
        <Dialog open={this.state.openDeleteDialog}>
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack.delete_career_record}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={this.closeDeleteDialog}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <div className="delete-bonus-query">
              {languagePack.delete_national_call_up_message}
              <span
                style={{
                  color: "var(--primaryColor)",
                  textTransform: "uppercase",
                }}
              >
                {this.state.club_name}
              </span>
              {"?"}
            </div>
            <div className="delete-bonus-buttons">
              <Button
                className="delete-bonus-button grey-btn"
                onClick={this.closeDeleteDialog}
              >
                <div className="button-content">
                  {languagePack["admin_cancel"]}
                </div>
                <Icon name="ban" className="white-icon" />
              </Button>
              <Button
                className="delete-bonus-button"
                color="red"
                onClick={this.deletePlayerHistoryItem}
              >
                <div className="button-content">{languagePack.yes_delete}</div>
                <Icon name="trash" className="white-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
  }),
  mapDispatchToProps
)(TransferHistory);
