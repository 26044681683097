import React, { useEffect, useState } from 'react';
import impressions_icon from '../../../../assets/icons/fans-dashboard/page_impressions.png';
import clicks_icon from '../../../../assets/icons/fans-dashboard/page_clicks2.png';
import NumberFormat from "react-number-format";
import axios from "axios";

export default function ImpressionsVsClicks(props) {
    const [impressions, setImpressions] = useState(0);
    const [clicks, setClicks] = useState(0);

    useEffect(() => {
        getValues();
    }, [props.year])

    function getValues() {
        axios.get(`fans/total_content_clicks?year=${props.year}&id_network=${props.id_network}`)
            .then((res) => {
                setClicks(res.data.clicks_count)
                setImpressions(res.data.impressions_count)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom">
            <div className="dashboard-box-container">
                <div className="dashboard-box-title" >Impressions vs clicks</div>
                <div className="stats-row">
                    <div className="stat-column param-value-box">
                        <div className="comparison-content-container">
                            <div className="image-container"><img src={impressions_icon} alt="impressions" /></div>

                            <div className="parameter-name">Page impressions</div>
                            <div className="parameter-value">
                                <NumberFormat
                                    value={impressions}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="stat-column param-value-box">
                        <div className="comparison-content-container">
                            <div className="image-container"> <img src={clicks_icon} alt="clicks" /></div>
                            <div className="parameter-name">Clicks</div>
                            <div className="parameter-value">
                                <NumberFormat
                                    value={clicks}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}