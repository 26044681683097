import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { bindActionCreators } from "redux";

import { updateQuickSetup } from "./../../../utils/api";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBTimePicker from "../../../components/design-system/TimePicker/TFBTimePicker";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import { daysOfWeek } from "../../../utils/constants";
import { isTFB } from "../../../utils/utilFunctions";

const AddRecurrentTraining = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, token, updateQuickSetup } = props;
    const { id_club } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            teams: null,
            day_of_week: null,
            location: null,
            venue_name: "",
            start_hour: null,
            end_hour: null,
            start_recurrence_date: null,
            end_recurrence_date: null,
        }
    );

    const {
        data: teams,
        loading: isTeamsLoading,
    } = useAxiosGet(
        `training/get_club_teams?token=${token}&all=0&type=select`
    );

    const {
        data: venues,
        loading: isVenuesLoading,
    } = useAxiosGet(
        `training/get_club_venues?id_club=${id_club}&add_venue=1&type=select`
    );

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("training/add_recurrent_training");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.training_add_title,
                languagePack.training_success
            );
            let clubQuickSetup = response?.club_quick_setup;
            updateQuickSetup({
                ...clubQuickSetup,
                isSetupOpen: false,
            });
        } else {
            showNotification(
                "danger",
                languagePack.training_add_title,
                languagePack.training_fail
            );
        }
    };

    const addTraining = () => {
        const payload = {
            id_club: id_club,
            teams: state.teams,
            day_of_week: state.day_of_week,
            location: state.location?.value ?? null,
            start_hour: moment(state.start_hour).format("HH:mm"),
            end_hour: moment(state.end_hour).format("HH:mm"),
            start_recurrence_date: moment(state.start_recurrence_date).format("YYYY-MM-DD"),
            end_recurrence_date: moment(state.end_recurrence_date).format("YYYY-MM-DD"),
            is_tfb: isTFB(),
        };

        if (state.location?.value == 0) {
            payload['new_location'] = state.venue_name;
        }

        addRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addTraining(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        _.size(state.teams) == 0 || _.size(state.day_of_week) == 0 || (state.location?.value == 0 && _.size(_.trim(state.venue_name)) == 0) || state.start_hour == null || state.end_hour == null || state.start_recurrence_date == null || state.end_recurrence_date == null,
        loadingAddRequest,
    );

    const hanldeChangeSelect = (selected, value) => {
        updateState({ [value.name]: selected })
    };

    return (
        <TFBDialog
            fullWidth maxWidth="lg"
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_recurrent_training"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBSelect
                    label={languagePack.team}
                    placeholder={languagePack.team}
                    isMulti={true}
                    options={teams?.list ?? []}
                    value={state.teams}
                    name="teams"
                    onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                    isLoading={isTeamsLoading}
                />
            </div>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBSelect
                    label={languagePack.day_of_the_week}
                    placeholder={languagePack.day_of_the_week}
                    value={state.day_of_week}
                    options={_.map(daysOfWeek, e => ({ ...e, label: languagePack?.[e.lang_pack] }))}
                    name="day_of_week"
                    onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                    isMulti
                />
                <TFBSelect
                    label={languagePack.location}
                    placeholder={languagePack.location}
                    options={_.map(venues?.list ?? [], e => e.value == 0 ? ({ ...e, label: languagePack.add_venue }) : e)}
                    value={state.location?.value == 0 ? { ...state.location, label: languagePack.add_venue } : state.location}
                    name="location"
                    onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                    isClearable={true}
                    isLoading={isVenuesLoading}
                />
            </div>
            {state.location?.value == 0 &&
                <div className="df-g20 df-end tab-control-line tab-control-line-mw-500">
                    <TFBInput
                        label={languagePack.enter_new_location}
                        placeholder={languagePack.enter_new_location}
                        value={state.venue_name}
                        style={{ backgroundColor: "#fafafa", width: "100%" }}
                        componentStyle={{ width: "49%" }}
                        onChange={(e) => updateState({ venue_name: e.target.value })}
                    />
                </div>
            }
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBTimePicker
                    label={languagePack.start_hour}
                    placeholder={languagePack.start_hour}
                    value={state.start_hour}
                    onChange={(value) => updateState({ start_hour: value })}
                    style={{ width: "100%" }}
                />
                <TFBTimePicker
                    label={languagePack.end_hour}
                    placeholder={languagePack.end_hour}
                    value={state.end_hour}
                    onChange={(value) => updateState({ end_hour: value })}
                    style={{ width: "100%" }}
                />
            </div>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBDatePicker
                    label={languagePack.start_recurrent}
                    placeholder={languagePack.start_recurrent}
                    value={state.start_recurrence_date}
                    onChange={(value) => updateState({ start_recurrence_date: value })}
                    style={{ width: "100%" }}
                />
                <TFBDatePicker
                    label={languagePack.end_recurrent}
                    placeholder={languagePack.end_recurrent}
                    value={state.end_recurrence_date}
                    onChange={(value) => updateState({ end_recurrence_date: value })}
                    style={{ width: "100%" }}
                />
            </div>
        </TFBDialog>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateQuickSetup,
        },
        dispatch
    );
}

export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(AddRecurrentTraining);
