import React from "react";
import "./FansList.scss";
import axios from "axios";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import {
  Pagination,
  Input,
  Dropdown,
  Button,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import Moment from "react-moment";
import moment from "moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const ppp_options = [
  { key: 1, text: 10, value: 10 },
  { key: 2, text: 20, value: 20 },
  { key: 3, text: 50, value: 50 },
  { key: 4, text: 100, value: 100 },
  { key: 5, text: 500, value: 500 },
];

const months = [
  { key: 1, value: 1, text: "Ianuarie" },
  { key: 2, value: 2, text: "Februarie" },
  { key: 3, value: 3, text: "Martie" },
  { key: 4, value: 4, text: "Aprilie" },
  { key: 5, value: 5, text: "Mai" },
  { key: 6, value: 6, text: "Iunie" },
  { key: 7, value: 7, text: "Iulie" },
  { key: 8, value: 8, text: "August" },
  { key: 9, value: 9, text: "Septembrie" },
  { key: 10, value: 10, text: "Octombrie" },
  { key: 11, value: 11, text: "Noiembrie" },
  { key: 12, value: 12, text: "Decembrie" },
];

class FansList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fans: [],
      total: 0,
      page: 1,
      total_pages: 0,
      searchbar_input: "",
      el_per_page: 20,
      filterPopup: false,

      filterDayStart: "",
      filterMonthStart: "",
      filterDayEnd: "",
      filterMonthEnd: "",
      filterActive: false,

      days_dropdown: [],
      months_dropdown: [],

      merge_accounts: false,
      checkboxes: [],

      merge_result_name: "",
      merge_result_birthday: "",
    };
  }

  componentDidMount = () => {
    this.getFans();
    this.generateDays();
  };

  handlePaginationChange = (e, data) => {
    this.setState({ page: data.activePage }, this.getFans);
  };

  handleChangeDropdown = (e, value) => {
    this.setState(
      {
        [value.name]: value.value,
        page: 1,
      },
      this.getFans
    );
  };

  handleChangeDateDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeCheckbox = (id) => {
    if (this.state.checkboxes.includes(id)) {
      this.setState(
        {
          checkboxes: this.state.checkboxes.filter((item) => item != id),
        },
        this.generateMergeResult
      );
    } else {
      if (this.state.checkboxes.length < 3) {
        this.setState(
          {
            checkboxes: this.state.checkboxes.concat(id),
          },
          this.generateMergeResult
        );
      }
    }
  };

  generateDays = () => {
    const days = [];
    for (let i = 0; i < 31; i++) {
      const day_data = {
        key: i + 1,
        value: i + 1,
        text: i + 1,
      };
      days.push(day_data);
    }
    this.setState({
      days_dropdown: days,
    });
  };

  generateMergeResult = () => {
    const checkboxes = this.state.checkboxes;
    let elements = this.state.fans.filter((it) => {
      return (
        it.id_fan == checkboxes[0] ||
        it.id_fan == checkboxes[1] ||
        it.id_fan == checkboxes[2]
      );
    });
  };

  saveFilter = () => {
    this.getFans();
    this.setState({ page: 1, filterPopup: false, filterActive: true });
  };

  getFans = () => {
    axios
      .get(
        `fans/get_fans_list?
        id_club=${this.props.currentUser.id_club}&
        page=${this.state.page}&
        search=${this.state.searchbar_input}&
        nr_el=${this.state.el_per_page}&
        d_start=${this.state.filterDayStart}&
        m_start=${this.state.filterMonthStart}&
        d_end=${this.state.filterDayEnd}&
        m_end=${this.state.filterMonthEnd}
        `
      )
      .then((res) =>
        this.setState({
          fans: res.data.fans,
          total: res.data.number_fans,
          total_pages: res.data.total_pages,
        })
      )
      .catch((err) => console.log(err));
  };

  handleSearchbarChange = (e) => {
    this.setState({ searchbar_input: e.target.value }, this.getFans);
  };

  handleChangeDate = (value) => {
    this.setState({ merge_result_birthday: value });
  };

  resetPeriodFilter = () => {
    this.setState(
      {
        filterDayStart: "",
        filterMonthStart: "",
        filterDayEnd: "",
        filterMonthEnd: "",
        filterActive: false,
      },
      this.getFans
    );
  };

  saveMerge = () => {
    const payload = {
      final_name: this.state.merge_result_name,
      final_birthday: this.state.merge_result_birthday,
      ids: JSON.stringify(this.state.checkboxes),
    };
    axios
      .post("fans/merge_profiles", payload)
      .then((res) => {
        this.getFans();
        this.setState({ merge_accounts: false });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="card card-custom">
        <div className="fans-list-container">
          <div className="fans-list-header">
            <div className="fans-list-title">{languagePack["fans_list"]}</div>
            <div className="total">
              Total:{" "}
              <div
                style={{
                  color: window.clubCustomization.primary_color,
                  marginLeft: 5,
                }}
              >
                {this.state.total}
              </div>
            </div>
          </div>
          <div className="fans-list-second-header">
            <Input
              icon={{ name: "search", circular: true, link: true }}
              placeholder={languagePack["search"]}
              className="search-field"
              onChange={this.handleSearchbarChange}
            />
            <div className="right-side">
              <Button
                className="period-filter"
                onClick={() =>
                  this.setState({ merge_accounts: !this.state.merge_accounts })
                }
                style={{ backgroundColor: "#666666", width: 200 }}
              >
                {languagePack["combine_fan_profiles"]}
                {this.state.merge_accounts ? (
                  <Icon
                    name="close"
                    size="small"
                    className="period-filter-icon"
                  />
                ) : (
                  <Icon
                    name="linkify"
                    size="small"
                    className="period-filter-icon"
                  />
                )}
              </Button>
              {!this.state.filterActive ? (
                <Button
                  className="period-filter"
                  onClick={() => this.setState({ filterPopup: true })}
                  style={{ backgroundColor: "#666666" }}
                >
                  {languagePack["filter_profiles"]}
                  <Icon
                    name="filter"
                    size="small"
                    className="period-filter-icon"
                  />
                </Button>
              ) : (
                <Button
                  className="period-filter"
                  onClick={this.resetPeriodFilter}
                  style={{ backgroundColor: "#666666" }}
                >
                  {languagePack.admin_cancel}
                  <Icon
                    name="close"
                    size="small"
                    className="period-filter-icon"
                  />
                </Button>
              )}
            </div>
          </div>
          <div className="fans-list">
            <div className="fans-list-table-header">
              <div className="name">{languagePack["firstname_lastname"]}</div>
              <div className="email">E-mail</div>
              <div className="phone">{languagePack["phone_no"]}</div>
              <div className="age">{languagePack["age"]}</div>
              <div className="options"></div>
            </div>
            {this.state.fans.map((item, index) => (
              <div
                className="fans-list-table-container"
                key={"fan-list-container-" + index}
              >
                <div className="fans-list-table-content">
                  <div
                    className="fans-list-table-header"
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                    }}
                  >
                    <div className="name">
                      {languagePack["firstname_lastname"]}
                    </div>
                    <div className="email">E-mail</div>
                    <div className="phone">{languagePack["phone_no"]}</div>
                    <div className="age">{languagePack["age"]}</div>
                    <div className="options"></div>
                  </div>
                  <div
                    className="fan-row"
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    {this.state.merge_accounts && (
                      <Checkbox
                        onChange={() => this.handleChangeCheckbox(item.id_fan)}
                        checked={this.state.checkboxes.includes(item.id_fan)}
                        className="fan-row-checkbox"
                      />
                    )}
                    <div
                      className="name"
                      onClick={() =>
                        this.props.history.push(`/fans/${item.id_fan}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {item.fan_name}
                    </div>
                    <div className="email">{item.fan_email}</div>
                    <div className="phone">{item.fan_phone}</div>

                    {item.fan_birthday && item.fan_birthday !== "0000-00-00" ? (
                      <div className="age">
                        <div>
                          {moment().diff(item.fan_birthday, "years") +
                            " " +
                            languagePack["years"]}
                        </div>
                        <div style={{ color: "#b2b2b2" }}>
                          <Moment format="DD.MM.YYYY">
                            {item.fan_birthday}
                          </Moment>
                        </div>
                      </div>
                    ) : (
                      <div className="age">-</div>
                    )}
                    <div className="options">
                      <div
                        className="eye-icon-container"
                        onClick={() =>
                          this.props.history.push(`/fans/${item.id_fan}`)
                        }
                      >
                        <EyeIcon />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {this.state.merge_accounts && (
            <div className="merge-profiles-container">
              <div className="merge-row-1">
                {this.state.checkboxes.map((el, i) => {
                  const element = this.state.fans.find((it) => it.id_fan == el);
                  if (element) {
                    return (
                      <div className="profile-box">
                        <div className="title">Profil {i + 1}</div>
                        <div className="profile-box-row">
                          <div className="row-label">Nume:</div>
                          <div className="row-value">{element.fan_name}</div>
                        </div>
                        <div className="profile-box-row">
                          <div className="row-label">Data nasterii:</div>
                          <div className="row-value">
                            {element.fan_birthday &&
                            element.fan_birthday !== "0000-00-00" ? (
                              <Moment format="DD.MM.YYYY">
                                {element.fan_birthday}
                              </Moment>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
                {this.state.checkboxes.length > 1 && (
                  <div className="profile-box">
                    <div
                      className="title"
                      style={{ color: window.clubCustomization.primary_color }}
                    >
                      Rezultat
                    </div>
                    <div className="profile-box-row">
                      <div className="row-label">Nume:</div>
                      <div className="row-value">
                        {/* {this.state.merge_result_name} */}
                        <input
                          className="edit-result-input"
                          onChange={(e) =>
                            this.setState({ merge_result_name: e.target.value })
                          }
                          value={this.state.merge_result_name}
                        />
                      </div>
                    </div>
                    <div className="profile-box-row">
                      <div className="row-label">Data nasterii:</div>
                      <div className="row-value">
                        {/* {this.state.merge_result_birthday} */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className="edit-result-date-input-wrapper">
                            <KeyboardDatePicker
                              value={
                                this.state.merge_result_birthday !== ""
                                  ? this.state.merge_result_birthday
                                  : null
                              }
                              onChange={this.handleChangeDate}
                              format="dd MM yyyy"
                              className="edit-date-input"
                            />
                            <Icon
                              name="calendar alternate outline"
                              className="calendar-icon"
                              size="small"
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="merge-row-2">
                <Button
                  className="period-filter"
                  style={{
                    backgroundColor: "#00d948",
                    width: 200,
                  }}
                  onClick={this.saveMerge}
                >
                  {languagePack.admin_contact_save_changes}
                  <Icon
                    name="check"
                    size="small"
                    className="period-filter-icon"
                  />
                </Button>
              </div>
            </div>
          )}
          <div className="pagination-container">
            <div className="dropdown-ppp">
              <div className="dropdown-label">
                {languagePack.people_per_page}
              </div>
              <Dropdown
                onChange={this.handleChangeDropdown}
                options={ppp_options}
                selection
                value={this.state.el_per_page}
                name="el_per_page"
                style={{ width: 100, minWidth: 100 }}
              />
            </div>

            <Pagination
              activePage={this.state.page}
              onPageChange={this.handlePaginationChange}
              totalPages={this.state.total_pages}
            />
          </div>
        </div>
        <Dialog
          open={this.state.filterPopup}
          // onClose={() => this.setState({ filterPopup: false })}
        >
          <DialogContent>
            <div className="filter-popup-container">
              <div className="filter-popup-title">
                {languagePack.filter_by_birthday}
              </div>
              <div className="filter-popup-row">
                <div className="row-label">{languagePack.start_date}</div>
                <Dropdown
                  placeholder={languagePack.injury_day}
                  value={this.state.filterDayStart}
                  name="filterDayStart"
                  onChange={this.handleChangeDateDropdown}
                  className="date-dropdown"
                  style={{ width: "25%" }}
                  options={this.state.days_dropdown}
                  selection
                />
                <Dropdown
                  placeholder={languagePack.month}
                  value={this.state.filterMonthStart}
                  name="filterMonthStart"
                  onChange={this.handleChangeDateDropdown}
                  className="date-dropdown"
                  style={{ width: "35%" }}
                  options={months}
                  selection
                />
              </div>
              <div className="filter-popup-row">
                <div className="row-label">{languagePack.end_date}</div>
                <Dropdown
                  placeholder={languagePack.injury_day}
                  value={this.state.filterDayEnd}
                  name="filterDayEnd"
                  onChange={this.handleChangeDateDropdown}
                  className="date-dropdown"
                  style={{ width: "25%" }}
                  options={this.state.days_dropdown}
                  selection
                />
                <Dropdown
                  placeholder={languagePack.month}
                  value={this.state.filterMonthEnd}
                  name="filterMonthEnd"
                  onChange={this.handleChangeDateDropdown}
                  className="date-dropdown"
                  style={{ width: "35%" }}
                  options={months}
                  selection
                />
              </div>
              <div className="filter-popup-buttons-row">
                <Button
                  className="period-filter"
                  onClick={() => this.setState({ filterPopup: false })}
                  style={{ width: "47%", backgroundColor: "#666666" }}
                >
                  {languagePack.admin_cancel}{" "}
                  <Icon
                    name="ban"
                    size="small"
                    className="period-filter-icon"
                  />
                </Button>
                <Button
                  className="period-filter"
                  style={{ width: "47%", backgroundColor: "#00d948" }}
                  onClick={this.saveFilter}
                  disabled={
                    this.state.filterMonthStart > this.state.filterMonthEnd
                  }
                >
                  {languagePack.admin_save}{" "}
                  <Icon
                    name="check"
                    size="small"
                    className="period-filter-icon"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(FansList);
