import React, { useReducer } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import { TFBTableCell, TFBTableRow, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBTabControl from "../../../components/design-system/TabControl/TFBTabControl";
import CircularLoader from "../../../components/reusable/Loader";

import AddPlayerToDocument from "./AddPlayerToDocument";
import DeletePlayerFromDocument from "./DeletePlayerFromDocument";

import { baseUrl, encryptBase64String } from "../../../utils/utilFunctions";

const ViewJustifyingDocument = (props) => {

    const { id } = useParams();

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            active_tab: 0,

            open_add_player_to_doc: false,

            open_delete_player_doc: false,
            deleting_player_doc: {},

            open_add_auxiliary: false,

            open_delete_auxiliary: false,
            deleting_auxiliary: {},
        }
    );

    const { languagePack, id_club } = props;

    const {
        data: documentData,
        loading: isDocumentDataLoading,
        refetch: refetchDocumentData
    } = useAxiosGet(`finances/get_justifying_document_data?id_document=${id}`);

    const openAddPlayerToDoc = () => {
        updateState({ open_add_player_to_doc: true })
    }

    const closeAddPlayerToDoc = () => {
        updateState({ open_add_player_to_doc: false })
    }

    const openDeletePlayerDoc = (doc) => {
        updateState({ open_delete_player_doc: true, deleting_player_doc: doc })
    }

    const closeDeletePlayerDoc = () => {
        updateState({ open_delete_player_doc: false, deleting_player_doc: {} })
    }

    const openAddAuxiliary = () => {
        updateState({ open_add_auxiliary: true })
    }

    const closeAddAuxiliary = () => {
        updateState({ open_add_auxiliary: false })
    }

    const openDeleteAuxiliary = (p) => {
        updateState({ open_delete_auxiliary: true, deleting_auxiliary: p })
    }

    const closeDeleteAuxiliary = () => {
        updateState({ open_delete_auxiliary: false, deleting_auxiliary: {} })
    }

    const tab1 = <>
        {_.size(documentData?.players) > 0 ?
            <TableContainer>
                <Table>
                    <TableHead>
                        <TFBTableRow>
                            <TFBTableCell sx={sxStickyColumn}>
                                {languagePack.players}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                                {languagePack.team}
                            </TFBTableCell>
                            <TFBTableCell align="right">
                                {languagePack.product_actions}
                            </TFBTableCell>
                        </TFBTableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(documentData?.players, (row, index) => (
                            <TFBTableRow key={"player-" + index}>
                                <TFBTableCell>
                                    <TFBPlayerWithAvatar
                                        playerName={row?.player?.data?.player_name}
                                        avatarUrl={row?.player?.data?.img_url}
                                    />
                                </TFBTableCell>
                                <TFBTableCell align="center">
                                    {_.join(_.map(row?.player?.data?.teams?.list ?? [], e => e.team_name), ", ")}
                                </TFBTableCell>
                                <TFBTableCell align="right">
                                    <div className="df df-end df-g10">
                                        <TFBIconButton
                                            name="delete"
                                            onClick={() => openDeletePlayerDoc(row)}
                                        />
                                    </div>
                                </TFBTableCell>
                            </TFBTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_data} />
        }
    </>

    const tab2 = <>
        {_.size(documentData?.auxiliary_staff) > 0 ?
            <TableContainer>
                <Table>
                    <TableHead>
                        <TFBTableRow>
                            <TFBTableCell sx={sxStickyColumn}>
                                {languagePack.auxiliary_persons}
                            </TFBTableCell>
                            <TFBTableCell align="right">
                                {languagePack.product_actions}
                            </TFBTableCell>
                        </TFBTableRow>
                    </TableHead>
                    <TableBody>
                        {_.map(documentData?.auxiliary_staff, (row, index) => (
                            <TFBTableRow key={"auxiliary_staff-" + index}>
                                <TFBTableCell>{row?.person?.person?.person_name}</TFBTableCell>
                                <TFBTableCell align="right">
                                    <div className="df df-end df-g10">
                                        <TFBIconButton
                                            name="delete"
                                            onClick={() => openDeleteAuxiliary(row)}
                                        />
                                    </div>
                                </TFBTableCell>
                            </TFBTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_data} />
        }
    </>

    return (
        <TFBCard>
            <TFBCardHeader title={documentData?.title}>
                <a target="_blank" href={baseUrl(`finances/download_justifying_document?id_document=${encryptBase64String(id)}&type=D`)}>
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="arrow-down" />}
                    >
                        {languagePack.download_view_document}
                    </TFBButton>
                </a>
                {state.active_tab == 0 &&
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="add" />}
                        onClick={openAddPlayerToDoc}
                    >
                        {languagePack.add_player}
                    </TFBButton>
                }
                {state.active_tab == 1 &&
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="add" />}
                        onClick={openAddAuxiliary}
                    >
                        {languagePack.add_auxiliary_person}
                    </TFBButton>
                }
            </TFBCardHeader>
            <TFBCardBody>
                {!isDocumentDataLoading ?
                    <TFBTabControl
                        tabs={[languagePack.players, languagePack.auxiliary_persons]}
                        tabsContent={[tab1, tab2]}
                        activeTab={state.active_tab}
                        onChangeActiveTab={(i) => updateState({ active_tab: i })}
                    /> :
                    <CircularLoader />
                }
                {state.open_add_player_to_doc &&
                    <AddPlayerToDocument
                        openPopup={state.open_add_player_to_doc}
                        closePopup={closeAddPlayerToDoc}
                        refreshList={refetchDocumentData}
                        id_document={id}
                        currentPlayers={_.map(documentData?.players, e => e.id_player)}
                    />
                }

                {state.open_delete_player_doc &&
                    <DeletePlayerFromDocument
                        openPopup={state.open_delete_player_doc}
                        closePopup={closeDeletePlayerDoc}
                        refreshList={refetchDocumentData}
                        document={state.deleting_player_doc}
                    />
                }

                {state.open_add_auxiliary &&
                    <AddAuxiliary
                        openPopup={state.open_add_auxiliary}
                        closePopup={closeAddAuxiliary}
                        refreshList={refetchDocumentData}
                        languagePack={languagePack}
                        idClub={id_club}
                        idDocument={id}
                        usedPersonIds={_.map(documentData?.auxiliary_staff, e => e.id_person)}
                    />
                }

                {state.open_delete_auxiliary &&
                    <DeleteAuxiliary
                        openPopup={state.open_delete_auxiliary}
                        closePopup={closeDeleteAuxiliary}
                        refreshList={refetchDocumentData}
                        data={state.deleting_auxiliary}
                        languagePack={languagePack}
                    />
                }
            </TFBCardBody>
        </TFBCard>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    id_club: auth?.user?.id_club,
}))(ViewJustifyingDocument);

const AddAuxiliary = ({ openPopup, closePopup, refreshList, languagePack, idClub, idDocument, usedPersonIds }) => {

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            person_name: "",
            selected_person: null,
        }
    );

    const {
        data: personList,
        loading: isPersonListLoading,
    } = useAxiosGet(`finances/get_auxiliary_person_list?id_club=${idClub}&type=select`);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("finances/add_auxiliary_person_to_justifying_document");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.justifying_documents,
                languagePack.success_add
            );
        } else {
            showNotification(
                "danger",
                languagePack.justifying_documents,
                languagePack.fail_add
            );
        }
    };

    const addDocument = () => {
        const payload = {
            id_document: idDocument,
        };

        if (state.selected_person?.value != -1) {
            payload.id_person = state.selected_person?.value;
        }
        if (state.selected_person?.value == -1) {
            payload.payload = {
                person_name: state.person_name,
                id_club: idClub,
            };
        }

        addRequest(payload, handleResponse);
    }

    const isValidAdd = () => {
        if (state.selected_person == null) return false;
        if (state.selected_person?.value == -1 && _.size(_.trim(state.person_name)) == 0) return false;
        return true;
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addDocument(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        !isValidAdd(),
        loadingAddRequest,
    );

    const getPersonListOptions = () => {
        return _.union([{ value: -1, label: languagePack.add_new_auxiliary_person }], _.filter(personList?.list ?? [], e => (_.findIndex(usedPersonIds, v => v == e.value)) == -1));
    }

    const handleSelectChange = (selected) => {
        if (selected) {
            const updatePayload = { selected_person: selected }
            if (selected?.value != -1) {
                updatePayload.person_name = "";
            }
            updateState(updatePayload);
        }
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_auxiliary_person"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            {!isPersonListLoading ?
                <>
                    <TFBSelect
                        label={languagePack.select_auxiliary_person}
                        value={state.selected_person}
                        options={getPersonListOptions()}
                        onChange={handleSelectChange}
                        style={{ component: { marginBottom: 20 } }}
                    />
                    {state.selected_person?.value == -1 &&
                        <TFBInput
                            label={languagePack.auxiliary_person_name}
                            value={state.person_name}
                            onChange={(e) => updateState({ person_name: e.target.value })}
                            style={{ width: "100%", marginBottom: 20 }}
                        />
                    }
                </> :
                <CircularLoader />
            }
        </TFBDialog>
    )
}

const DeleteAuxiliary = ({ openPopup, closePopup, refreshList, languagePack, data }) => {

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("finances/delete_auxiliary_person_from_justifying_document");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.justifying_documents,
                languagePack.success_delete
            );
        } else {
            showNotification(
                "danger",
                languagePack.justifying_documents,
                languagePack.fail_delete
            );
        }
    };

    const deleteAction = () => {
        const payload = {
            id_document: data.id_document,
            id_person: data.id_person,
        };
        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteAction(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_auxiliary_person_document_to_sign"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_auxiliary_person_document_to_sign_message}
            </div>
        </TFBDialog>
    );
}