import React from "react";
import styles from "./TFBDialog.module.scss";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core";
import { TDialog } from "../types";

import TFBIconButton from "../IconButton/TFBIconButton";
import TFBIcon from "../Icon/TFBIcon";

const TFBDialog = ({
  open,
  onClose,
  paddingX = 2,
  closePopup,
  className,
  style,
  title,
  children,
  btnLeft,
  btnRight,
  btnCenter,
  fullWidth,
  maxWidth,
  scroll,
  stickyBtns,
  columnActionsMobile,
  isNews,
  hideX = false,
}: TDialog) => {
  const useStyles = makeStyles((theme) => ({
    newPosOfDialog: {
      position: "absolute",
      top: "0%",
      right: "130px",
      [theme.breakpoints.down("sm")]: {
        position: "unset",
        top: "unset",
        right: "unset", // Ascunde stilurile pentru ecran mai mic de 998px
      },
    },
    dialogWithPadding: {
      paddingLeft: theme.spacing(paddingX), // Modificați acest număr pentru padding-ul dorit
      paddingRight: theme.spacing(paddingX), // Modificați acest număr pentru padding-ul dorit
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      className={`${styles.tfbDialog} `}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableEnforceFocus
      scroll={scroll}
      classes={isNews ? { paper: classes.newPosOfDialog } : undefined}
    >
      {/* <div className={`${className ? className : ""}`} style={style}> */}
      <DialogTitle disableTypography className={styles.tfbDialogTitle}>
        <h2 className={styles.header}>{title}</h2>
        <div className={styles.dialogCloseIcon} onClick={closePopup}>
          {!hideX && <TFBIcon name="close-popup" color="black" size="lg" />}
        </div>
      </DialogTitle>
      <DialogContent
        // className={`${styles.tfbDialogContent}`}
        dividers={scroll === "paper"}
        className={`${styles.tfbDialogContent} ${className ? className : ""} ${
          isNews ? classes.dialogWithPadding : undefined
        }`}
        style={style}
      >
        {children}
      </DialogContent>

      {/* <div className="tfb-dialog-action-wrapper"> */}
      <DialogActions
        className={`tfb-custom-dialog-actions ${styles.tfbDialogActions} ${
          stickyBtns ? styles.tfbDialogStickyActions : ""
        } ${columnActionsMobile ? styles.tfbActionsColumn : ""}`}
        style={
          (btnLeft !== undefined &&
            btnRight !== undefined &&
            btnCenter !== undefined) ||
          (btnLeft !== undefined && btnRight !== undefined)
            ? {}
            : btnLeft !== undefined
            ? { justifyContent: "flex-start" }
            : btnRight !== undefined
            ? { justifyContent: "flex-end" }
            : btnCenter !== undefined
            ? { justifyContent: "center" }
            : {}
        }
      >
        {btnLeft && (
          <div
            className={`tfb-custom-dialog-btn-left ${styles.btnLeft} ${
              btnLeft !== undefined &&
              btnRight !== undefined &&
              btnCenter !== undefined
                ? styles.btnTFBThree
                : styles.btnTFBTwo
            }`}
          >
            {btnLeft()}
          </div>
        )}
        {btnCenter && (
          <div
            className={`tfb-custom-dialog-btn-center ${styles.btnCenter} ${
              btnLeft !== undefined &&
              btnRight !== undefined &&
              btnCenter !== undefined
                ? styles.btnTFBThree
                : styles.btnTFBTwo
            }`}
          >
            {btnCenter()}
          </div>
        )}
        {btnRight && (
          <div
            className={`tfb-custom-dialog-btn-right ${styles.btnRight} ${
              btnLeft !== undefined &&
              btnRight !== undefined &&
              btnCenter !== undefined
                ? styles.btnTFBThree
                : styles.btnTFBTwo
            }`}
          >
            {btnRight()}
          </div>
        )}
      </DialogActions>
      {/* </div> */}
      {/* </div> */}
    </Dialog>
  );
};

export default TFBDialog;
