import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useHistory } from "react-router-dom";
import moment from "moment";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { TFBTableCell, TFBTableRow } from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../../components/reusable/Loader";

import DeleteEvaluation from "./DeleteEvaluation";

const Evaluation = (props) => {

    const { currentUser, languagePack } = props;
    const history = useHistory();

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_delete_evaluation: false,
            deleting_evaluation: {},
        }
    );

    const {
        data: evaluations,
        loading: isEvaluationsLoading,
        refetch: refetchEvaluationsList,
    } = useAxiosGet(
        `report/get_evaluation_list?id_club=${currentUser.id_club}`
    );

    const openAddEvaluation = () => {
        history.push("/reports/evaluation/add");
    }

    const openEditEvaluation = (evaluation) => {
        history.push(`/reports/evaluation/edit/${evaluation?.id_evaluation_test}`);
    }

    const openDeleteEvaluation = (evaluation) => {
        updateState({ open_delete_evaluation: true, deleting_evaluation: evaluation })
    }

    const closeDeleteEvaluation = () => {
        updateState({ open_delete_evaluation: false, deleting_evaluation: {} })
    }

    const viewEvaluation = (evaluation) => {
        history.push(`/reports/evaluation/${evaluation?.id_evaluation_test}`);
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack["players_evaluations"]}>
                <TFBButton
                    onClick={openAddEvaluation}
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                >
                    {languagePack.add_evaluation}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                {!isEvaluationsLoading ?
                    (_.size(evaluations?.list) > 0 ?
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TFBTableRow>
                                        <TFBTableCell>
                                            {languagePack.test_name}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.team}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.evaluation_year}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.evaluation_date}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.visible_in_mobile_app}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                        </TFBTableCell>
                                    </TFBTableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(evaluations?.list, (row, index) => (
                                        <TFBTableRow key={"evaluation-" + index}>
                                            <TFBTableCell>
                                                <div className="cursor-pointer" onClick={() => viewEvaluation(row)}>
                                                    {row.evaluation_test_name}
                                                </div>
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row?.team?.data?.team_name}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {moment(row?.evaluation_test_start).format("YYYY")}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {moment(row?.evaluation_test_start).format("DD.MM") + " - " + moment(row?.evaluation_test_end).format("DD.MM")}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                <TFBIcon
                                                    color={row.show_in_mobile_app == 0 ? "black" : "green"}
                                                    name={row.show_in_mobile_app == 0 ? "x-small" : "check"}
                                                />
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                <div className="actions-btns-container df-end">
                                                    <TFBIconButton
                                                        name="view"
                                                        color="darkGray"
                                                        onClick={() => viewEvaluation(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="edit"
                                                        color="darkGray"
                                                        onClick={() => openEditEvaluation(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="delete"
                                                        color="darkGray"
                                                        onClick={() => openDeleteEvaluation(row)}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                        </TFBTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <TFBPlaceholder
                            text={languagePack.no_evaluation}
                        />
                    ) :
                    <CircularLoader />
                }
            </TFBCardBody>

            {state.open_delete_evaluation &&
                <DeleteEvaluation
                    openPopup={state.open_delete_evaluation}
                    closePopup={closeDeleteEvaluation}
                    refreshList={refetchEvaluationsList}
                    data={state.deleting_evaluation}
                />
            }
        </TFBCard>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(Evaluation);