import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./PlayersStatistics.scss";
import { Button, Icon } from "semantic-ui-react";
import Moment from "react-moment";
// import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ListItemSecondaryAction } from "@material-ui/core";
// import OptionsPopup from "./OptionsPopup";

class PlayersStatistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            statistics: [
                {
                    name: "Alex Delta",
                    age: "21",
                    date_birth: "22.01.2000",
                    main_position: "RB",
                    secondary_positions: "CB, LB",
                    league: "Premier League",
                    matches: "13",
                    played_minutes: "900",
                    goals: "4"
                },
                {
                    name: "Victor Alfa",
                    age: "21",
                    date_birth: "22.01.2000",
                    main_position: "RB",
                    secondary_positions: "CB, LB",
                    league: "Premier League",
                    matches: "13",
                    played_minutes: "900",
                    goals: "4"
                },
                {
                    name: "Damian Teta",
                    age: "21",
                    date_birth: "22.01.2000",
                    main_position: "RB",
                    secondary_positions: "CB, LB",
                    league: "Premier League",
                    matches: "13",
                    played_minutes: "900",
                    goals: "4"
                }
            ],
            //   optionsPopupOpen: false,
        };
    }

    getStatistics = () => {
        // axios
        //   .get(
        //     `transferlab/get_shortlists?id_club=${this.props.currentUser.id_club}`
        //   )
        //   .then((res) => this.setState({ shortlists: res.data }))
        //   .catch((err) => console.log(err));
    };

    componentDidMount() {
        this.getStatistics();
    }

    render() {
        const { languagePack } = this.props;
        return (
            <div className="card card-custom" style={{ padding: 50 }}>
                <div className="stat-page-container">
                    <div className="stat-header">
                        <div className="stat-title">{"Players statistics"}</div>
                        <Button
                            className="create-stat-button"
                        //   onClick={() => this.setState({ optionsPopupOpen: true })}
                        >
                            {"Create statistics"}
                            <Icon name="add" className="stat-button-icon" />
                        </Button>
                    </div>
                    <div className="stat-list-header">
                        <div className="stat-col name">{"Name"}</div>
                        <div className="stat-col age">{"Age"}</div>
                        <div className="stat-col date-birth">{"Date of birth"}</div>
                        <div className="stat-col main-position">{"Main position"}</div>
                        <div className="stat-col secondary-positions">{"Other positions"}</div>
                        <div className="stat-col league">{"League"}</div>
                        <div className="stat-col matches">{"Played matches"}</div>
                        <div className="stat-col played-minutes">{"Played minutes"}</div>
                        <div className="stat-col goals">{"Scored goals"}</div>
                    </div>
                    <div className="stat-list">
                        {this.state.statistics.map((item, index) => (
                            <div
                                className="stat-row"
                                key={index}
                                style={{
                                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                                }}
                            >
                                <div className="stat-col name">{item.name}</div>
                                <div className="stat-col age">{item.age}</div>
                                <div className="stat-col date-birth">{item.date_birth}</div>
                                <div className="stat-col main-position">{item.main_position}</div>
                                <div className="stat-col secondary-positions">{item.secondary_positions}</div>
                                <div className="stat-col league">{item.league}</div>
                                <div className="stat-col matches">{item.matches}</div>
                                <div className="stat-col played-minutes">{item.played_minutes}</div>
                                <div className="stat-col goals">{item.goals}</div>
                                {/* <div className="sh-see-more-button">
                  <EyeIcon
                    onClick={() =>
                      this.props.history.push(
                        `/scouting/shortlists/${item.id_shortlist}`
                      )
                    }
                  />
                </div> */}
                            </div>
                        ))}
                    </div>
                </div>
                {/* <Dialog
          open={this.state.optionsPopupOpen}
          onClose={() => this.setState({ optionsPopupOpen: false })}
          maxWidth="xl"
        >
          <DialogContent>
            <OptionsPopup
              closePopup={(
                profileType,
                nationality,
                foot,
                heightMin,
                heightMax,
                ageMin,
                ageMax,
                league,
                formation
              ) => {
                this.setState({ optionsPopupOpen: false });
                this.props.history.push(
                  `/scouting/players?profile=${profileType}&nationality=${nationality}&foot=${foot}&ageMin=${ageMin}&ageMax=${ageMax}&heightMin=${heightMin}&heightMax=${heightMax}&league=${league}&formation=${formation}`
                );
              }}
            />
          </DialogContent>
        </Dialog> */}
            </div>
        );
    }
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(PlayersStatistics);
