import React from "react";
import styles from "./TFBDateTimePicker.module.scss";
import { TDateTimePicker } from "../types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const TFBDateTimePicker = ({
  onChange,
  label,
  value,
  fullWidth,
  className,
}: TDateTimePicker) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label ? (
        <div className={styles.tfbDateGroup} style={{ width: "100%" }}>
          <h6 className={styles.dateLabel}>{label}</h6>
          <DateTimePicker
            value={value}
            onChange={onChange}
            format="DD / MM / YYYY HH:mm"
            className={`${styles.tfbDateTimePicker} ${className ? className : ""
              }`}
            sx={{
              width: fullWidth ? "100%" : "50%",
              border: "1px solid #F2F2F2",
              borderColor: "#F2F2F2",
              borderRadius: "3px",
            }}
            ampm={false}
          />
        </div>
      ) : (
        <DateTimePicker
          value={value}
          onChange={onChange}
          className={`${styles.tfbDateTimePicker} ${className ? className : ""
            }`}
          sx={{
            width: fullWidth ? "100%" : "50%",
            border: "1px solid #F2F2F2",
            borderColor: "#F2F2F2",
            borderRadius: "3px",
          }}
          format="DD / MM / YYYY   HH:mm"
          ampm={false}
        />
      )}
    </LocalizationProvider>
  );
};

export default TFBDateTimePicker;
