import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./authRedux";
import playerReducer from "../redux/list/list.reducer";
import layoutServiceReducer from "./LayoutService/layout.service.reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  player: playerReducer,
  layoutService: layoutServiceReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
