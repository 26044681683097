import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./Scouting.scss";
import { Button, Icon } from "semantic-ui-react";
import Moment from "react-moment";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import OptionsPopup from "./OptionsPopup";

class Shortlists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shortlists: [],
      optionsPopupOpen: false,
    };
  }

  getShortlists = () => {
    axios
      .get(
        `transferlab/get_shortlists?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ shortlists: res.data }))
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.getShortlists();
  }

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom" style={{ padding: 50 }}>
        <div className="shortlists-page-container">
          <div className="shortlists-header">
            <div className="shortlists-title">
              {languagePack["saved_shortlists"]}
            </div>
            <Button
              className="create-shortlist-button"
              onClick={() => this.setState({ optionsPopupOpen: true })}
            >
              {languagePack["create_shortlist"]}
              <Icon name="add" className="shortlist-button-icon" />
            </Button>
          </div>
          <div className="shortlists-list-header">
            <div className="sh-name">{languagePack["shortlist_name"]}</div>
            <div className="sh-col">{languagePack["formation"]}</div>
            <div className="sh-col">{languagePack["selected_players"]}</div>
            <div className="sh-col">{languagePack["position"]}</div>
            <div className="sh-date">{languagePack["date_created"]}</div>
          </div>
          <div className="shortlists-list">
            {this.state.shortlists.map((item, index) => (
              <div
                className="shortlist-row"
                key={index}
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}
              >
                <div className="sh-name">{item.shortlist_name}</div>
                <div className="sh-col">{item.shortlist_formation}</div>
                <div className="sh-col">{item.nr_players}</div>
                <div className="sh-col">{item.profile}</div>
                <div className="sh-date">
                  <Moment format="DD.MM.YYYY">{item.created_at}</Moment>
                </div>
                <div className="sh-see-more-button">
                  <EyeIcon
                    onClick={() =>
                      this.props.history.push(
                        `/scouting/shortlists/${item.id_shortlist}`
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Dialog
          open={this.state.optionsPopupOpen}
          // onClose={() => this.setState({ optionsPopupOpen: false })}
          maxWidth="xl"
        >
          <DialogContent>
            <OptionsPopup
              closePopup={(
                profileType,
                nationality,
                foot,
                heightMin,
                heightMax,
                ageMin,
                ageMax,
                league,
                formation
              ) => {
                this.setState({ optionsPopupOpen: false });
                this.props.history.push(
                  `/scouting/players?profile=${profileType}&nationality=${nationality}&foot=${foot}&ageMin=${ageMin}&ageMax=${ageMax}&heightMin=${heightMin}&heightMax=${heightMax}&league=${league}&formation=${formation}`
                );
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(Shortlists);
