import React from "react";
import axios from "axios";
import "./AddPlayerPopup.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../../Financial/PaymentRequest/PaymentRequest.scss";
import { logout } from "../../../../../utils/api";
import TFBDialog from "../../../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../../components/design-system/Icon/TFBIcon";
import TFBSelect from "../../../../../components/design-system/TFBSelect/TFBSelect";
import TFBIconButton from "../../../../../components/design-system/IconButton/TFBIconButton";
import TFBCheckbox from "../../../../../components/design-system/Checkbox/TFBCheckbox";
import { store } from "react-notifications-component";

class AddPlayerPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      amount: "",
      teams_are_loaded: false,
      teams: [],
      selected_teams_players: [
        {
          players_are_loaded: false,
          team: null,
          players: [],
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getTeams();
  };

  //   ================ GET DATA =============================
  // ==========================================================
  getTeams = () => {
    axios
      .get(`finances/get_teams?token=${this.props.token}`)
      .then((res) =>
        this.setState({ teams: res.data.teams, teams_are_loaded: true })
      )
      .catch((err) => console.log(err));
  };

  //   ================ HANDLERS =============================
  // ==========================================================

  generateTeamsOptions(selectedTeamsPlayers, teams, teamPlayer) {
    return teams.filter(
      (elem1) =>
        ![
          ...selectedTeamsPlayers.map((elem) => elem.team?.value ?? null),
        ].includes(elem1.value) || elem1.value == teamPlayer.team?.value
    );
  }

  selectTeam = async (index, selected) => {
    let teams_players = this.state.selected_teams_players;
    teams_players[index].team = selected;
    teams_players[index].players_are_loaded = false;

    //get players
    this.setState(
      {
        selected_teams_players: teams_players,
      },
      async () => {
        await axios
          .get(
            `finances/get_players?id_team=${selected.value}&token=${this.props.token}`
          )
          .then((res) => {
            let teams_players = this.state.selected_teams_players;
            let players = res.data.players;
            players[0].label = this.props.languagePack.all_players;
            teams_players[index].players = players;
            teams_players[index].players_are_loaded = true;
            this.setState({ selected_teams_players: teams_players });
          })
          .catch((err) => console.log(err));
      }
    );
  };

  addTeam = () => {
    this.setState((prevState) => ({
      selected_teams_players: [
        ...prevState.selected_teams_players,
        {
          players_are_loaded: false,
          team: null,
          players: [],
        },
      ],
    }));
  };

  deleteTeam = (indexTeam) => {
    let teams_players = this.state.selected_teams_players;
    teams_players.splice(indexTeam, 1);
    this.setState({ selected_teams_players: teams_players });
  };

  handleCheckbox = (indexTeam, indexPlayer) => {
    let teams_players = this.state.selected_teams_players;
    if (indexPlayer == 0) {
      teams_players[indexTeam].players.forEach((elem, index) => {
        if (index > 0) {
          teams_players[indexTeam].players[index].checked = !teams_players[
            indexTeam
          ].players[indexPlayer].checked;
        }
      });
    } else {
      if (teams_players[indexTeam].players[indexPlayer].checked) {
        //all players disabled
        teams_players[indexTeam].players[0].checked = false;
      } else {
        //check if all players are selected
        let selected_players = teams_players[indexTeam].players.filter(
          (player, index) =>
            index != 0 && index != indexPlayer && player.checked
        ).length;
        if (selected_players == teams_players[indexTeam].players.length - 2) {
          teams_players[indexTeam].players[0].checked = true;
        }
      }
    }
    teams_players[indexTeam].players[indexPlayer].checked = !teams_players[
      indexTeam
    ].players[indexPlayer].checked;
    this.setState({ selected_teams_players: teams_players });
  };

  // =================  RENDER BOTTOM DIALOG BUTTONS =================
  // ==========================================================

  renderBtn = (position, onClick) => {
    const { languagePack } = this.props;

    return (
      <TFBButton
        onClick={() => {
          onClick();
          this.setState({
            selected_teams_players: [
              {
                players_are_loaded: false,
                team: null,
                players: [],
              },
            ],
          });
        }}
        endIcon={true}
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "add"} />
        )}
        prettier-ignore
        disabled={
          position == "right" &&
          this.state.selected_teams_players.every((team) =>
            team.players.every((player) => !player.checked)
          )
        }
      >
        {position == "left" ? languagePack.Cancel : languagePack.Add}
      </TFBButton>
    );
  };

  //   ================ ADD PLAYERS TO MATCH SQUAD =============================
  // ==========================================================

  addPlayersToSquad = () => {
    const selectedPlayers = [];
    [...this.state.selected_teams_players].forEach((team) => {
      const players = team.players;
      const allPlayersChecked = players[0].checked;

      if (allPlayersChecked) {
        // Daca "Toti jucatorii" este checked, luam toti jucatorii in array-ul rezultat
        players.slice(1).forEach((player) => {
          selectedPlayers.push({ id_player: player.value });
        });
      } else {
        // Daca "Toti jucatorii" nu este checked, adaugam jucatorii cu "checked" true in array-ul rezultat
        players.forEach((player, index) => {
          if (index !== 0 && player.checked) {
            selectedPlayers.push({ id_player: player.value });
          }
        });
      }
    });

    let payload = {
      id_match: this.props.id_match,
      id_user: this.props.id_user,
      players: selectedPlayers,
    };

    axios
      .post("matches/add_player_to_match_squad", payload)
      .then((res) => {
        if (res.status == 200 && res.data.success == 1) {
          store.addNotification({
            title: this.props.languagePack.match_pl,
            message: this.props.languagePack
              .players_successfully_added_to_the_squad,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.match_pl,
            message: this.props.languagePack.players_failed_added_to_the_squad,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
        this.props.refreshSquad();
      })
      .catch((e) => console.log(e));
  };

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.add_player}
        open={isOpenPopup}
        closePopup={() => {
          closePopup();
          this.setState({
            selected_teams_players: [
              {
                players_are_loaded: false,
                team: null,
                players: [],
              },
            ],
          });
        }}
        btnRight={() => this.renderBtn("right", () => this.addPlayersToSquad())}
        btnLeft={() => this.renderBtn("left", () => closePopup())}
      >
        {/* ============ DIALOG SUBTITLE ============= */}
        {/* ===========================--============= */}

        <div className="add-player-squad-popup-section">
          <div className="pop-up-section-title">
            {languagePack.choose_players}
          </div>

          {/* ============ DIALOG BODY - CONTENT ============= */}
          {/* ===========================--============= */}

          <div className="pop-up-section-body">
            {this.state.selected_teams_players.map((elem, index) => (
              <div className="pop-up-row" key={"team-" + index}>
                <div className="row-team animate-in">
                  <TFBSelect
                    placeholder={languagePack.choose_team}
                    label={languagePack.team}
                    options={this.generateTeamsOptions(
                      this.state.selected_teams_players,
                      this.state.teams,
                      elem
                    )}
                    value={elem.team}
                    onChange={(selected) => this.selectTeam(index, selected)}
                  />
                  {this.state.selected_teams_players.length > 1 && (
                    <TFBIconButton
                      name="x-large"
                      className="delete-team-icon-button"
                      color="red"
                      onClick={() => this.deleteTeam(index)}
                    />
                  )}
                </div>
                <div
                  className="row-player"
                  style={{ display: "flex", gap: "20px" }}
                >
                  {elem.players_are_loaded &&
                    [...elem.players].map((player, index2) => (
                      <TFBCheckbox
                        key={"team-" + index + "-player-" + index2}
                        checked={player.checked}
                        label={player.label}
                        labelColor={"rgb(178, 178, 178)"}
                        onChange={() => this.handleCheckbox(index, index2)}
                        className="animate-in"
                      />
                    ))}
                </div>
              </div>
            ))}
            <div className="pop-up-row">
              <TFBButton
                color="lightGray"
                renderIcon={() => <TFBIcon name="add" color="gray" />}
                disabled={
                  this.state.selected_teams_players.filter(
                    (elem) => elem.team == null
                  ).length > 0
                }
                onClick={this.addTeam}
              >
                {languagePack.add_team}
              </TFBButton>
            </div>
          </div>
        </div>
      </TFBDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddPlayerPopup);
