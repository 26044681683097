import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
// import "../Club/Matches/Competitions/ManageCompetitions.scss";
import "./Reports.styles.scss";
import { connect } from "react-redux";

function DeletePhysicalTest(props) {
  const { languagePack } = props;

  function deletePhysicalTest() {
    const payload = {
      id_physical_test: props.physicalTestDelete.id_test,
      id_team: props.physicalTestDelete.id_team,
      id_club: props.id_club,
    };

    axios.post("report/delete_physical_test", payload).then((resp) => {
      if (resp.data === 1) {
        store.addNotification({
          title: "Evaluări",
          message: "Evaluare ștearsă cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        props.fetchDataPhysical();
        props.closePopup();
      }

      if (resp.data === 0) {
        store.addNotification({
          title: props.languagePack["manage_competitions"],
          message: props.languagePack["competition_deleted_success"],
          type: "fail",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });

        props.closePopup();
      }
    });
  }

  return (
    <div className="delete-category-popup-container">
      <div className="delete-category-header">
        <div className="delete-category-title">
          {languagePack.delete_evaluation}
        </div>
        <Icon
          name="close"
          className="close"
          size="large"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="delete-category-text">
        {languagePack.delete_physical_test_message}
      </div>
      <div className="delete-category-buttons">
        <Button className="cancel-button" onClick={() => props.closePopup()}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button
          className="delete-comp-button"
          onClick={() => deletePhysicalTest()}
        >
          {languagePack["admin_delete"]}
          <Icon name="trash alternate outline" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeletePhysicalTest);
