import React, { useContext } from "react";
import "./stepGroup.scss";
import _ from "lodash";

import { QuickSetupContext } from "./QuickSetup";

const MainStepItem = ({ mainSteps, languagePack, isQuickAction }) => {
  // GET DATA FROM CONTEXT ===============================
  // ========================================
  const { mainStep, setMainStep } = useContext(QuickSetupContext);

  // HANDLERS COMPONENTS ===============================
  // ========================================
  const chooseMainStep = (index) => {
    setMainStep(index);
  };

  // RENDER COMPONENTS ===============================
  // ========================================
  return (
    <div className="main-steps-list">
      {_.map(mainSteps, (step, index) => (
        <div
          className="main-steps cursor-pointer"
          key={"main-step-" + index}
          onClick={() => chooseMainStep(index)}
          style={{ color: mainStep == index && "var(--primaryColor)" }}
        >
          <span
            className="ds-headline-2"
            style={{ marginBottom: "13px", lineHeight: "18.5px" }}
          >
            {languagePack.quickSetup[step.slug_name]}
          </span>
          {!isQuickAction && (
            <span className="ds-secondary-text-auxiliary">{`(${step.progress}/${step.target})`}</span>
          )}

          {index !== mainSteps.length - 1 && (
            <div
              className="horizontal-bar"
              style={{ marginBottom: "25px", marginTop: "25px" }}
            ></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MainStepItem;
