import React, { useRef } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { authReduxFunctions } from "./../../../../../redux/functions/authReduxFunctions";
import { actions } from "../../../../../redux/authRedux";

import styles from "./styles.module.scss";

import { showNotification, useAxiosPost } from "../../../../../app/components/customHooks/useAxiosPost";
import TFBIcon from "../../../../../app/components/design-system/Icon/TFBIcon";
import TFBFileInput from "../../../../../app/components/design-system/FileInput/TFBFileInput";
import TFBCircularLoader from "../../../../../app/components/design-system/CircularLoader/TFBCircularLoader";

import { handleFileInput } from "../../../../../app/utils/utilFunctions";

interface IUserAvatar {
    className?: string;
    url?: string | false;
    languagePack?: any;
    token?: string;
    clubQuickSetup?: any;
}

const UserAvatar: React.FC<IUserAvatar> = ({ className = "", url = false, languagePack, token, clubQuickSetup }) => {
    const dispatch = useDispatch();

    const refFileInput = useRef<any>(null);

    const { postData: postRequest, loading: isLoadingUpload } = useAxiosPost("user/update_user_avatar");

    const handleResponse = (response: any) => {
        if (response?.success == 1) {
            authReduxFunctions(dispatch).login(response.user);
            dispatch(actions.updateQuickSetup(clubQuickSetup));
            showNotification(
                "success",
                languagePack.user_profile,
                languagePack.edit_user_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.user_profile,
                languagePack.edit_user_danger
            );
        }
        if (refFileInput?.current) {
            refFileInput.current.clearFileInput();
        }
    };

    const openFileInput = () => {
        if (refFileInput?.current) {
            refFileInput.current.openFileInput();
        }
    }

    const onFileChange = async (e: any) => {
        const files = e.target.files;

        if (files.length) {
            const file = files[0];

            const content = await handleFileInput(file);
            if (content != null) {
                const regex = new RegExp('[^.]+$');
                const payload = {
                    token: token,
                    content: content,
                    filte_type: file.type,
                }

                postRequest(payload, handleResponse);
            }
        }
    }

    return (
        <>
            <div className={`${styles.container} ${className}`} {...url && { style: { backgroundImage: `url('${url}')` } }}>
                <div className={`${isLoadingUpload ? styles.loading : styles.hovered} ${className}`}></div>
                {!isLoadingUpload && <div onClick={openFileInput}><TFBIcon name="edit" color="white" className={styles.changeImg} /></div>}
                {isLoadingUpload && <TFBCircularLoader />}
            </div>
            <TFBFileInput
                accept="image/jpg, image/png, image/jpeg"
                ref={refFileInput}
                style={{ display: "none" }}
                onChange={onFileChange}
            />
        </>
    )
}

export default connect(({ layoutService, auth }: any) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    clubQuickSetup: auth?.clubQuickSetup,
}))(UserAvatar);