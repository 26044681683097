import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./AddNotes.scss";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import _ from "lodash";
import { store } from "react-notifications-component";

import TFBPlayerWithAvatar from "../../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import useTFBInput from "../../../../components/customHooks/useTFBInput";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import DeleteNotes from "./DeleteNotes/DeleteNotes";
import { lang } from "moment";
import TFBTextArea from "../../../../components/design-system/TextArea/TFBTextArea";

export default function AddNotes({
  openPopup,
  closePopup,
  languagePack,
  playerName,
  viewModeProp,
  noteList,
  id_player,
  playerData,
  id_club,
  id_user,
  token,
  resfreshNotes,
  ...props
}) {
  // When viewMode is true, button "create notes" is displayed, otherwise form should display
  const [viewMode, setViewMode] = useState(viewModeProp);
  const [remarkDetail, setRemarkDetail] = useState("");
  const [playerNoteList, setPlayerNoteList] = useState(noteList);
  const [isCriticalPoint, setIsCriticalPoint] = useState(false);

  const [editMode, setEditMode] = useState(null);
  const [openPopupContor, setOpenPopupContor] = useState(null);

  const editInput = useTFBInput(null);
  const [editIsCriticalPoint, setEditIsCriticalPoint] = useState(null);

  const [idNoteDelete, setIdNoteDelete] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  const scrollRef = useRef(null);

  // =========== POST ADD NOTE CUSTOM HOOK =========================
  const { data: responseAddNote, postData: postAddNote } = useAxiosPost(
    "player/add_player_note"
  );

  const { data: responseEditNote, postData: postEditNote } = useAxiosPost(
    "player/edit_player_note"
  );

  const isFirstRender = useRef(true);

  // =========== USE EFFECTS =========================

  useEffect(() => {
    setPlayerNoteList(noteList);
  }, [noteList]);

  useEffect(() => {
    setOpenPopupContor(openPopup);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [openPopupContor]);

  useEffect(() => {
    scrollToBottom();
  }, [viewMode]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    handleResponse(
      responseAddNote ? responseAddNote : responseEditNote,
      languagePack,
      showNotification,
      languagePack.note_add_success,
      languagePack.note_add_fail,
      resfreshNotes
    );
  }, [responseAddNote, responseEditNote]);

  // =========== HANDLERS =========================

  // Funcție custom pentru logica comună de tratat răspunsul
  const handleResponse = (
    response,
    languagePack,
    showNotification,
    onSuccessMessage,
    onFailMessage,
    resfreshNotes
  ) => {
    if (response.success === 1) {
      showNotification(
        "success",
        languagePack.player_profile,
        onSuccessMessage
      );
      resfreshNotes();
    } else {
      showNotification("danger", languagePack.player_profile, onFailMessage);
    }
  };

  const scrollToBottom = () => {
    const scrollElement = scrollRef.current;
    if (scrollElement && !viewMode && openPopup) {
      scrollElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderBtnForm = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "add"} />
        )}
        prettier-ignore
        disabled={position == "right" && remarkDetail == ""}
      >
        {position == "left"
          ? languagePack.admin_cancel
          : languagePack.admin_save}
      </TFBButton>
    );
  };

  const renderBtnCreate = (onClick) => {
    return (
      <TFBButton
        onClick={() => {
          setViewMode(false);
          setEditMode(null);
        }}
        endIcon={true}
        renderIcon={() => <TFBIcon name="add" />}
        prettier-ignore
      >
        {languagePack.create_note}
      </TFBButton>
    );
  };

  const cancelAddNote = () => {
    setViewMode(true);
  };

  // =========== ADD REMARK/NOTES PLAYER  =========================

  const addRemark = () => {
    const payload = {
      id_player: id_player,
      id_user: id_user,
      note_text: remarkDetail,
      //Change from boolean to 0/1
      is_critical: +isCriticalPoint,
    };

    postAddNote(payload);
  };

  // =========== Edit REMARK/NOTES PLAYER  =========================

  const editNote = () => {
    const payload = {
      id_note: editMode,
      note_text: editInput.value,
      is_critical: +editIsCriticalPoint,
    };

    postEditNote(payload);
    setEditMode(null);
  };

  return (
    <TFBDialog
      open={openPopup}
      fullWidth={true}
      maxWidth="sm"
      stickyBtns
      scroll="paper"
      closePopup={closePopup}
      title={`${playerName} - ${languagePack.remarks}`}
      btnRight={() => !viewMode && renderBtnForm("right", () => addRemark())}
      btnLeft={() =>
        !viewMode
          ? renderBtnForm("left", () => cancelAddNote())
          : renderBtnCreate()
      }
    >
      <div className="add-notes-popup-content">
        {playerNoteList.length > 0 ? (
          <div className="notes-list">
            {_.map(playerNoteList, (note, index) => (
              <div
                className="note-content"
                key={"note-list-" + index}
                style={{
                  position: "relative",
                  border: note.is_critical == 1 && "1px solid red",
                }}
              >
                <div className="creator-details">
                  <div className="user-post-name">
                    <TFBPlayerWithAvatar />
                    <div className="details">
                      <span className="ds-main-text-auxiliary">
                        {note.user_name}
                      </span>
                      <span className="ds-main-text-auxiliary">
                        {note.date_add}
                        {note.last_edited &&
                          ` - ${languagePack.last_edit}: ${note.last_edited}`}
                      </span>
                    </div>
                  </div>

                  {note.id_user == id_user && (
                    <div className="note-container-buttons">
                      <TFBIcon
                        color="black"
                        name="edit"
                        className="cursor-pointer"
                        onClick={() => {
                          editInput.setValue(note.note_text);
                          setEditIsCriticalPoint(!!Number(note.is_critical));
                          setViewMode(true);
                          setEditMode(note.id_note);
                        }}
                      />
                      <TFBIcon
                        color="black"
                        name="delete"
                        className="cursor-pointer"
                        onClick={() => {
                          setDeletePopup(true);
                          setIdNoteDelete(note.id_note);
                        }}
                      />
                    </div>
                  )}
                </div>

                {note.id_note == editMode ? (
                  <div className="edit-note-form">
                    <TFBTextArea
                      placeholder={languagePack.add_remarks}
                      label={languagePack.your_observations}
                      fullWidth
                      value={editInput.value}
                      onChange={editInput.onChange}
                    />

                    <div className="mark-critic-point">
                      <TFBCheckbox
                        label={languagePack.mark_as_critical_point}
                        checked={editIsCriticalPoint}
                        onChange={() =>
                          setEditIsCriticalPoint(!editIsCriticalPoint)
                        }
                      />
                      <TFBIcon name="flag" color="red" />
                    </div>

                    <div
                      className="edit-footer-buttons"
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="cancel" />}
                        style={{ width: "50%" }}
                        onClick={() => setEditMode(null)}
                      >
                        {languagePack.admin_cancel}
                      </TFBButton>
                      <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="check" />}
                        style={{ width: "50%" }}
                        onClick={editNote}
                      >
                        {languagePack.admin_confirm}
                      </TFBButton>
                    </div>
                  </div>
                ) : (
                  <div className="remark-description ds-main-text-auxiliary">
                    {note.is_critical == 1 && (
                      <TFBIcon
                        name="flag"
                        color="red"
                        style={{
                          position: "absolute",
                          left: "9px",
                          bottom: "30px",
                        }}
                      />
                    )}
                    {note.note_text}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <TFBPlaceholder
            text={languagePack.no_notes_placeholder}
            style={{ marginTop: "50px", marginBottom: "50px" }}
          />
        )}

        {!viewMode && (
          <div className="add-note-form">
            <TFBTextArea
              placeholder={languagePack.add_remarks}
              label={languagePack.your_observations}
              fullWidth
              value={remarkDetail}
              onChange={(e) => setRemarkDetail(e.target.value)}
            />

            <div className="mark-critic-point">
              <TFBCheckbox
                label={languagePack.mark_as_critical_point}
                checked={isCriticalPoint}
                onChange={() => setIsCriticalPoint(!isCriticalPoint)}
              />
              <TFBIcon name="flag" color="red" />
            </div>
          </div>
        )}
      </div>

      {/* ============= Delete Notes Popup =================== */}

      <DeleteNotes
        openPopup={deletePopup}
        closePopup={() => setDeletePopup(false)}
        languagePack={languagePack}
        id_note={idNoteDelete}
        refreshNotes={resfreshNotes}
      ></DeleteNotes>

      <div ref={scrollRef}></div>
    </TFBDialog>
  );
}
