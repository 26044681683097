import LayoutServiceActions from './layout.service.types';

export const setLanguage = (payload) => ({
    type: LayoutServiceActions.SET_LANGUAGE,
    payload
});
export const setClub = (payload) => ({
    type: LayoutServiceActions.SET_CLUB,
    payload
});
export const setLoader = (payload) => ({
    type: LayoutServiceActions.SET_LOADER,
    payload
});