import React, { useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./DocumentToSign.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBShape from "../../../components/design-system/Shape/TFBShape";
import EditDocumentToSign from "./EditDocumentToSign";
import DeleteDocumentToSign from "./DeleteDocumentToSign";
import CircularLoader from "../../../components/reusable/Loader";

const DocumentToSign = (props) => {
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open_edit_doc: false,
      editing_doc: {},

      open_delete_doc: false,
      deleting_doc: {},

      status_obj: {
        1: {
          style: "documentActiveLabel",
          text_code: "document_active",
        },
        0: {
          style: "documentArchivedLabel",
          text_code: "document_archived",
        },
      },
    }
  );

  const { languagePack } = props;
  const { id_club } = props.currentUser;

  const {
    data: documents,
    loading: isDocumentsLoading,
    refetch: refetchDocuments,
  } = useAxiosGet(`parentsApp/get_document_to_sign_list?id_club=${id_club}`);

  const openAddDocument = () => {
    history.push("/application/add-doc-to-sign");
  };

  const openEditDoc = (doc) => {
    updateState({ open_edit_doc: true, editing_doc: doc });
  };

  const closeEditDoc = () => {
    updateState({ open_edit_doc: false, editing_doc: {} });
  };

  const openDeleteDoc = (doc) => {
    updateState({ open_delete_doc: true, deleting_doc: doc });
  };

  const closeDeleteDoc = () => {
    updateState({ open_delete_doc: false, deleting_doc: {} });
  };

  const viewDocument = (doc) => {
    history.push(`/application/doc-to-sign/${doc.id_document}`);
  };

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.documents_to_sign}>
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="add" />}
          onClick={openAddDocument}
        >
          {languagePack.create_document}
        </TFBButton>
      </TFBCardHeader>
      <TFBCardBody>
        {!isDocumentsLoading ? (
          _.size(documents?.list) > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.document_title}
                    </TFBTableCell>
                    <TFBTableCell align="left">
                      {languagePack.signed_by}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.document_type}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.document_status}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(documents.list, (row, index) => (
                    <TableRow
                      key={"doc-" + index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <div
                          className="cursor-pointer"
                          onClick={() => viewDocument(row)}
                        >
                          {row.document_title}
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="left">
                        <div className="df df-center df-gap-5">
                          <TFBShape
                            name="square"
                            color={row.color}
                            text={row.player_doc_signed}
                          />
                          <span className="text-no-wrap">
                            {" / " + row.player_doc_total}
                          </span>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack[row.document_type]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="df df-center-center">
                          <TFBShape
                            name="square"
                            text={
                              languagePack[
                                state.status_obj[row.need_to_sign].text_code
                              ]
                            }
                            className={
                              styles[state.status_obj[row.need_to_sign].style]
                            }
                          />
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div
                          className="actions-btns-container"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            onClick={() => viewDocument(row)}
                          />
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => openEditDoc(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => openDeleteDoc(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack.no_documents_to_sign} />
          )
        ) : (
          <CircularLoader />
        )}

        {state.open_edit_doc && (
          <EditDocumentToSign
            openPopup={state.open_edit_doc}
            closePopup={closeEditDoc}
            refreshList={refetchDocuments}
            document={state.editing_doc}
          />
        )}

        {state.open_delete_doc && (
          <DeleteDocumentToSign
            openPopup={state.open_delete_doc}
            closePopup={closeDeleteDoc}
            refreshList={refetchDocuments}
            document={state.deleting_doc}
          />
        )}
      </TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(DocumentToSign);
