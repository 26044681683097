import React from 'react';
import styled from 'styled-components';
import Param from './parameter';
import { Droppable } from 'react-beautiful-dnd';
import './drag-and-drop.scss';


const ParamList = styled.div`
  padding: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDraggingOver ? 'skyblue' : 'white'}
  flex-grow: 1;
  min-height: 100px;
`

export default class Column extends React.Component {
    render() {
        return (
            <div className="param-columns-container">
                <div className="param-column-title">{this.props.column.title}</div>
                <Droppable droppableId={this.props.column.id} type="PARAM">
                    {(provided, snapshot) => (
                        <ParamList

                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {this.props.params.map((param, index) => (
                                <Param key={param.id} param={param} index={index} />
                            ))}
                            {provided.placeholder}
                        </ParamList>
                    )}
                </Droppable>
            </div>
        )
    }
}