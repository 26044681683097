import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

const PlayerTeams = (props) => {

    const { refreshList, languagePack, teams, clubTeams, id_player } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_delete_team: false,
            deleting_team: {},

            open_add_team: false,

            new_team: null,
            keep_in_previous_teams: false,
            teams_to_keep: null,
        }
    );

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("player/move_player_to_another_team_2");

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("player/delete_player_from_team_2");

    const handleAddResponse = (response) => {
        if (response?.success == 1) {
            closeAddTeam();
            refreshList();
            showNotification(
                "success",
                languagePack.player_profile,
                languagePack.teams_notification_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.player_profile,
                languagePack.teams_notification_fail
            );
        }
    };

    const addTeam = () => {
        const payload = {
            id_player: id_player,
            id_new_team: state.new_team?.value,
            keep_in_previous_teams: state.keep_in_previous_teams,
            previous_teams: state.teams_to_keep ?? [],
        };

        addRequest(payload, handleAddResponse);
    }

    const handleDeleteResponse = (response) => {
        if (response?.success == 1) {
            closeDeleteTeam();
            refreshList();
            showNotification(
                "success",
                languagePack.player_profile,
                languagePack.success_delete_team
            );
        } else {
            showNotification(
                "danger",
                languagePack.player_profile,
                languagePack.fail_delete_team
            );
        }
    };

    const deleteTeam = () => {
        const payload = {
            id_player: id_player,
            id_team: state.deleting_team?.value,
        };

        deleteRequest(payload, handleDeleteResponse);
    }

    const { renderBtn: BtnLeftAdd } = useRenderButton(
        () => closeAddTeam(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRightAdd } = useRenderButton(
        () => addTeam(),
        "green",
        "check",
        languagePack.admin_save,
        {},
        true,
        state.new_team == null,
        loadingAddRequest,
    );

    const { renderBtn: BtnLeftDelete } = useRenderButton(
        () => closeDeleteTeam(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRightdelete } = useRenderButton(
        () => deleteTeam(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    const openAddTeam = () => {
        updateState({ open_add_team: true })
    }

    const closeAddTeam = () => {
        updateState({ open_add_team: false, new_team: null, keep_in_previous_teams: false, teams_to_keep: null, })
    }

    const openDeleteTeam = (team) => {
        updateState({ open_delete_team: true, deleting_team: team })
    }

    const closeDeleteTeam = () => {
        updateState({ open_delete_team: false, deleting_team: {} })
    }

    return (
        <>
            <div className="d-flex df-gap-10">
                {_.map(teams, (row, index) => (
                    <TFBButton
                        key={"team-" + index}
                        color="white"
                        {...(_.size(teams) > 1 && { renderIcon: () => <TFBIcon name="x-large" color="gray" /> })}
                        {...(_.size(teams) > 1 && { onClick: () => openDeleteTeam(row) })}
                    >
                        {row.label}
                    </TFBButton>
                ))}
                {_.size(teams) > 0 &&
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="add" />}
                        onClick={openAddTeam}
                    >
                        {languagePack.add_team}
                    </TFBButton>
                }
            </div>
            {state.open_add_team &&
                <TFBDialog
                    className="pop-up-dialog-box prevent-select-text"
                    title={languagePack["add_player_to_another_team"]}
                    open={state.open_add_team}
                    closePopup={closeAddTeam}
                    btnLeft={BtnLeftAdd}
                    btnRight={BtnRightAdd}
                >
                    <TFBSelect
                        label={languagePack.select_new_team}
                        value={state.new_team}
                        options={clubTeams}
                        onChange={selected => updateState({ new_team: selected })}
                        style={{ component: { marginBottom: 20 } }}
                    />
                    <TFBCheckbox
                        label={languagePack.keep_it_in_previous_teams}
                        checked={state.keep_in_previous_teams}
                        onChange={(e) => updateState({ keep_in_previous_teams: e.target.checked, teams_to_keep: e.target.checked ? teams : null })}
                        style={{ marginBottom: 20 }}
                    />
                    {state.keep_in_previous_teams &&
                        <TFBSelect
                            value={state.teams_to_keep}
                            options={teams}
                            onChange={(selected) => updateState({ teams_to_keep: selected, keep_in_previous_teams: selected == null ? false : true, })}
                            isMulti
                            isClearable
                            isSearchable
                        />
                    }
                </TFBDialog>
            }
            {state.open_delete_team &&
                <TFBDialog
                    className="pop-up-dialog-box prevent-select-text"
                    title={languagePack["delete_player_from_team"]}
                    open={state.open_delete_team}
                    closePopup={closeDeleteTeam}
                    btnLeft={BtnLeftDelete}
                    btnRight={BtnRightdelete}
                >
                    <div className="pop-up-dialog-delete-text">
                        {languagePack.delete_player_from_team_message}
                    </div>
                </TFBDialog>
            }
        </>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(PlayerTeams);
