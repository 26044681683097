import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import asideMenuSVG from "../../../../app/utils/asideMenuSVG";

export function QuickUserToggler() {
  // const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  const { languagePack } = useSelector(
    ({ layoutService: { languagePack } }) => ({
      languagePack,
    })
  );
  return (
    <>
      {layoutProps.offcanvas && (
        <div
          className="btn btn-icon btn-clean btn-lg w-100px h-60px"
          id="kt_quick_user_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
        >
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="quick-user-tooltip">
                {languagePack.user_profile}
              </Tooltip>
            }
          >
            <span className="symbol symbol-30 symbol-lg-40">
              <span className="svg-icon svg-icon-lg">
                <img src={asideMenuSVG.USER_PROFILE} />
              </span>
            </span>
          </OverlayTrigger>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
