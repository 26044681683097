import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import _ from "lodash";

import styles from "./DocumentToSign.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import FadeInOut from "../../../components/design-system/FadeInOut/FadeInOut";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import CircularLoader from "../../../components/reusable/Loader";
import VariablesSection from "./VariablesSection";

const AddDocumentToSign = (props) => {
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      step: 0,
      document_text: "",
      teams: [{ team: null }],

      load_players_for_team: null,

      document_title: "",
      doc_type: "contract",
      active: 1,
    }
  );

  const { languagePack } = props;
  const { id_club } = props.currentUser;

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `club/get_teams_nomenclature?id_club=${id_club}&type=select&all=0`
  );

  const {
    data: players,
    loading: isPlayersLoading,
    refetch: refetchPlayers,
  } = useAxiosGet(
    `club/get_current_team_players?id_team=${state.load_players_for_team}&type=select`,
    true
  );

  const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost(
    "parentsApp/create_document_to_sign"
  );

  useEffect(() => {
    if (state.load_players_for_team > 0) {
      refetchPlayers();
    }
  }, [state.load_players_for_team]);

  useEffect(() => {
    if (players?.success == 1) {
      const id_team = players?.id_team;
      const cloned_teams = _.clone(state.teams);
      const team_index = _.findIndex(
        cloned_teams,
        (e) => e?.team?.value == id_team
      );
      if (team_index > -1) {
        cloned_teams[team_index].players = _.map(players?.list ?? [], (e) => ({
          ...e,
          checked: true,
        }));
        cloned_teams[team_index].is_loading_players = false;
        updateState({ teams: cloned_teams });
      }
    }
  }, [players]);

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    updateState({ document_text: data });
  };

  const nextStep = () => {
    updateState({ step: state.step + 1 });
  };

  const previousStep = () => {
    updateState({ step: state.step - 1 });
  };

  const handleSelectTeam = (index, selected) => {
    const cloned_teams = _.clone(state.teams);
    cloned_teams[index].team = selected;
    cloned_teams[index].is_loading_players = true;
    updateState({
      teams: cloned_teams,
      load_players_for_team: selected?.value ?? null,
    });
  };

  const addTeam = () => {
    let cloned_teams = _.clone(state.teams);
    cloned_teams = _.union(cloned_teams, [{ team: null }]);
    updateState({ teams: cloned_teams });
  };

  const deleteTeam = (index) => {
    let cloned_teams = _.clone(state.teams);
    cloned_teams.splice(index, 1);
    updateState({ teams: cloned_teams });
  };

  const getTeamOptions = () => {
    return _.filter(
      teams?.list ?? [],
      (e) => _.findIndex(state.teams, (v) => v?.team?.value == e.value) == -1
    );
  };

  const handleChangePlayerCheckbox = (e, indexTeam, indexPlayer = "all") => {
    let cloned_teams = _.clone(state.teams);

    if (indexPlayer == "all") {
      cloned_teams[indexTeam].players = _.map(
        cloned_teams[indexTeam]?.players,
        (p) => ({ ...p, checked: e.target.checked })
      );
    } else {
      cloned_teams[indexTeam].players[indexPlayer].checked = e.target.checked;
    }
    updateState({ teams: cloned_teams });
  };

  const isValidAddDocument = () => {
    return _.size(_.trim(state.document_title)) > 0;
  };

  const handleResponse = (response) => {
    if (response?.success == 1) {
      history.push("/application/doc-to-sign");
      showNotification(
        "success",
        languagePack.mobile_app,
        languagePack.success_generate_document
      );
    } else {
      showNotification(
        "danger",
        languagePack.mobile_app,
        languagePack.fail_generate_document
      );
    }
  };

  const addDocument = () => {
    const payload = {
      id_club: id_club,
      document_title: state.document_title,
      document_type: state.doc_type,
      document_text_html: state.document_text,
      need_to_sign: state.active,
      players: _.filter(
        _.reduce(
          _.map(state.teams, (e) => e.players),
          (result, value, key) => {
            result = _.union(result, _.isArray(value) ? value : []);
            return result;
          },
          []
        ),
        (e) => e.checked
      ),
    };

    addRequest(payload, handleResponse);
  };

  const step1 = (
    <FadeInOut show={state.step == 0}>
      <div
        className={styles.stepTitle}
      >{`${languagePack.step} 1: ${languagePack.edit_document}`}</div>
      <div className={styles.documentTypeContainer}>
        <TFBInput
          label={languagePack.document_title}
          value={state.document_title}
          onChange={(e) => updateState({ document_title: e.target.value })}
          style={{ width: "100%" }}
        />
      </div>
      <div className={styles.documentTypeContainer}>
        <div className={styles.title}>{languagePack.document_type}</div>
        <div className={styles.documentTypeList}>
          <TFBCheckbox
            checked={state.doc_type == "contract"}
            label={languagePack.contract}
            onChange={(e) => updateState({ doc_type: "contract" })}
          />
          <TFBCheckbox
            checked={state.doc_type == "contract_annex"}
            label={languagePack.contract_annex}
            onChange={(e) => updateState({ doc_type: "contract_annex" })}
          />
        </div>
      </div>
      <div className={styles.documentTypeContainer}>
        <div className={styles.title}>{languagePack.document_will_be}</div>
        <div className={styles.documentTypeList}>
          <TFBCheckbox
            checked={state.active == 1}
            label={languagePack.document_active}
            onChange={(e) => updateState({ active: 1 })}
          />
          <TFBCheckbox
            checked={state.active == 0}
            label={languagePack.document_archived}
            onChange={(e) => updateState({ active: 0 })}
          />
        </div>
      </div>
      <div className={styles.editorContainer}>
        <CKEditor
          editor={ClassicEditor}
          config={{
            toolbar: [
              "heading",
              "|",
              "bold",
              "italic",
              "link",
              "bulletedList",
              "numberedList",
              "blockQuote",
              "|",
              "undo",
              "redo",
            ],
          }}
          data={state.document_text}
          onChange={handleEditorChange}
        />
      </div>
      <VariablesSection languagePack={languagePack} />
      <div className="df df-end">
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="arrow-right" />}
          onClick={nextStep}
          disabled={
            _.size(state.document_text) == 0 ||
            _.size(_.trim(state.document_title)) == 0
          }
        >
          {languagePack.next_step}
        </TFBButton>
      </div>
    </FadeInOut>
  );

  const step2 = (
    <FadeInOut show={state.step == 1}>
      <div
        className={styles.stepTitle}
      >{`${languagePack.step} 2: ${languagePack.select_audience}`}</div>
      <div className={styles.teamsContainer}>
        {_.map(state.teams, (value, key) => (
          <div key={"team-" + key} className={styles.teamContainer}>
            <div className="df df-g20 df-align-end">
              <TFBSelect
                label={languagePack.team}
                placeholder={languagePack.select_team}
                value={value?.team ?? null}
                options={getTeamOptions()}
                onChange={(selected) => handleSelectTeam(key, selected)}
                style={{ component: { width: "300px" } }}
              />
              {_.size(state.teams) > 1 && (
                <TFBIconButton
                  name="x-large"
                  color="red"
                  onClick={() => deleteTeam(key)}
                />
              )}
            </div>
            <div className={styles.playersContainer}>
              {value?.is_loading_players ? (
                <CircularLoader />
              ) : (
                <div className="df df-gap-15 df-wrap prevent-select-text">
                  {_.size(value?.players) > 0 && (
                    <TFBCheckbox
                      key={"player-all"}
                      checked={!_.some(value?.players, ["checked", false])}
                      label={languagePack.all_players}
                      onChange={(e) => handleChangePlayerCheckbox(e, key)}
                      labelColor={"var(--primaryColor)"}
                    />
                  )}
                  {_.map(value?.players, (value2, key2) => (
                    <TFBCheckbox
                      key={"player-" + key2}
                      checked={value2.checked}
                      label={value2.label}
                      onChange={(e) => handleChangePlayerCheckbox(e, key, key2)}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {_.size(getTeamOptions()) > 0 && (
        <TFBButton
          color="lightGray"
          renderIcon={() => <TFBIcon name="add" color="gray" />}
          disabled={_.some(state?.teams, ["team", null])}
          onClick={addTeam}
        >
          {languagePack.add_team}
        </TFBButton>
      )}
      <div className={styles.btnsContainer}>
        <TFBButton
          color="darkGray"
          renderIcon={() => <TFBIcon name="arrow-left" />}
          onClick={previousStep}
          startIcon
        >
          {languagePack.previous_step}
        </TFBButton>
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="add" />}
          disabled={!isValidAddDocument()}
          onClick={addDocument}
        >
          {languagePack.create_document}
        </TFBButton>
      </div>
    </FadeInOut>
  );

  const steps = [step1, step2];

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.documents_to_sign} />
      <TFBCardBody>{steps[state.step]}</TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddDocumentToSign);
