import React from "react";
import { TTeamLabel } from "../types";
import styles from "./TFBTeamLabel.module.scss";
import TFBShape from "../Shape/TFBShape";
import { getContrastTextColor } from "../../../utils/utilFunctions";

const TFBTeamLabel = ({
    name = "",
    color = "",
    onClick,
    style,
    textStyle,
    className,
}: TTeamLabel) => {

    return (
        <div className={onClick ? "cursor-pointer" : ""} onClick={onClick} style={{ width: "fit-content" }}>
            <TFBShape
                text={name}
                style={{ ...(color ? { backgroundColor: color } : { backgroundColor: "transparent" }), ...style }}
                textStyle={{ ...(color ? { color: getContrastTextColor(color) } : { color: "#666666" }), ...textStyle }}
            />
        </div>
    )
};

export default TFBTeamLabel;
