import React, { useState } from "react";
import { connect } from "react-redux";

import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../../components/design-system/TextArea/TFBTextArea";
import { showNotification, useAxiosPost } from "../../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../../components/customHooks/useRenderButton";

const HandlePlayerInactiveStatus = (props) => {

    const { languagePack, closePopup, isOpenPopup, idPlayer, refreshList, setActive } = props;

    const [fromDate, setFromDate] = useState(null);
    const [reason, setReason] = useState("");

    const { postData: changeStatusRequest, loading: loadingChangeStatusRequest } = useAxiosPost("club/update_player_active_status");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.club_management,
                setActive ? languagePack.success_activate_player : languagePack.success_inactivate_player,
            );
        } else {
            showNotification(
                "danger",
                languagePack.club_management,
                setActive ? languagePack.fail_activate_player : languagePack.fail_inactivate_player,
            );
        }
    };

    const changeStatus = () => {
        let payload = {
            id_player: idPlayer,
        };
        if (setActive) {
            payload = {
                ...payload,
                inactive: 0,
            }
        } else {
            payload = {
                ...payload,
                inactive_from_date: fromDate,
                inactive_reason: reason,
                inactive: 1,
            }
        }

        changeStatusRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => changeStatus(),
        "green",
        "check",
        setActive ? languagePack.yes_activate : languagePack.admin_save,
        {},
        true,
        false,
        loadingChangeStatusRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={setActive ? languagePack.set_player_as_active : languagePack.set_player_as_inactive}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            {setActive ?
                <div className="pop-up-dialog-delete-text">
                    {languagePack.activate_player_question}
                </div> :
                <>
                    <div className="tab-control-line">
                        <TFBDatePicker
                            value={fromDate}
                            onChange={value => setFromDate(value)}
                            label={languagePack.inactive_from}
                            placeholder={languagePack.select_date}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <TFBTextArea
                        label={languagePack.inactive_reason}
                        placeholder={languagePack.insert_reason}
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                    />
                </>
            }
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(HandlePlayerInactiveStatus);