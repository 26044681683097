import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const { user } = useSelector(state => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div className={"btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"}>
          <span className="text-white opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span className="text-white opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user.fullname}
          </span>
          <span className="symbol symbol-35">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30">{user.fullname[0]}</span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">

        <div className="navi navi-spacer-x-0 pt-5">
          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  My Profile
                  </div>
                <div className="text-muted">
                  Account settings and more{` `}
                  <span className="label label-light-danger label-inline font-weight-bold">update</span>
                </div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  My players list
                  </div>
                <div className="text-muted">
                  Inbox and tasks
                  </div>
              </div>
            </div>
          </a>

          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">
                  Reports
                  </div>
                <div className="text-muted">
                  Check the most detailed player reports
                  </div>
              </div>
            </div>
          </a>

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link to="/logout" className="btn btn-light-primary font-weight-bold">
              Sign Out
              </Link>
            <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
              </a>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
