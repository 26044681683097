import React, { useState } from "react";
import { Card, Icon, Button } from "semantic-ui-react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";

import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout, login, updateQuickSetup } from "../../../utils/api";
import EditStaffDetails from "./EditStaffDetails";
import "./PlayerProfile.scss";

import ContractWidget from "./ContractWidget";
import StaffLicense from "./StaffLicense";
import DocumentsWidget from "./DocumentsWidget";
import { actions } from "../../../../redux/authRedux";
import { baseUrl, isTFB } from "../../../utils/utilFunctions";

function StaffProfile(props) {
  const history = useHistory();
  const [addedImage, setAddedImage] = useState("");
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [deleteStaffPopup, setDeleteStaffPopup] = useState(false);

  const idStaff = props.match.params.id;
  const [memberData, setMemberData] = useState({});
  const [idContract, setIdContract] = useState("");
  const [contractData, setContractData] = useState({});
  const [bonuses, setBonuses] = useState([]);

  const [hasAccessToStaff, setHasAccessToStaff] = useState(false);

  const isAllowedFinancial = () => {
    return props.currentUser.user_permissions.FINANCIAL;
  };
  const financialAccess = isAllowedFinancial();

  useEffect(() => {
    hasAccessToStaffFunc();
    getMemberInfo();
  }, []);

  useEffect(() => {
    getContractData();
  }, [memberData]);

  const hasAccessToStaffFunc = () => {
    axios
      .get(
        `user/has_access_to_staff_member?id_user=${props.currentUser.id_user}&id_staff_member=${props.match.params.id}`
      )
      .then((res) => {
        if (res.data == 1) {
          setHasAccessToStaff(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const getMemberInfo = () => {
    axios
      .get(
        baseUrl(`club/get_staff_member_basic_info?id_staff_member=${idStaff}`)
      )
      .then((res) => {
        setMemberData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getContractData = () => {
    axios
      .get(
        baseUrl(`club/get_staff_contract_data?id_contract=${memberData.current_contract_id}`)
      )
      .then((res) => {
        setContractData(res.data);

        getBonuses();
      })
      .catch((err) => console.log(err));
  };

  const getBonuses = () => {
    axios
      .get(
        baseUrl(`upload/get_bonuses_staff?id_contract=${memberData.current_contract_id}`)
      )
      .then((res) => setBonuses(res.data.bonuses))
      .catch((err) => console.log(err));
  };

  const addDefaultSrc = (event) => {
    event.target.src = baseUrl(`uploads/players/placeholder.png`);
  };

  const deleteStaffMember = () => {
    const payload = {
      id_staff_member: idStaff,
      id_club: props.currentUser.id_club,
      is_tfb: isTFB(),
    };

    axios
      .post("club/delete_staff_member_2", payload)
      .then((res) => {
        if (res.data.success == 1) {
          store.addNotification({
            title: "delete_member_staff",
            message: "Membru staff șters",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          history.push("/club");

          let clubQuickSetup = res.data.club_quick_setup;
          props.updateQuickSetup({
            ...clubQuickSetup,
            isSetupOpen: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSetImage = (childFromState) => {
    setAddedImage(childFromState);
  };

  const { languagePack } = props;

  return (
    <>
      {hasAccessToStaff && (
        <>
          <div className="row margin-bottom-row">
            <div className="col-lg-2 col-xxl-2 player-info-widget">
              {/* Selected season mobile ??*/}

              <Card style={{ position: "relative" }}>
                <div
                  style={{
                    height: 310,
                    position: "relative",
                    textAlign: "center",
                  }}
                >
                  {addedImage === "" ? (
                    <img
                      onError={addDefaultSrc}
                      src={`${window.baseURL}uploads/staff_members/${idStaff}.jpg`}
                      className="player-profile-img"
                      alt=""
                    />
                  ) : (
                    <img
                      src={addedImage}
                      alt=""
                      className="player-profile-img"
                    />
                  )}{" "}
                </div>
                <Card.Content style={{ paddingTop: 10, borderTop: "none" }}>
                  <Card.Header>
                    <div className="profile-name" style={{ marginTop: "12px" }}>
                      {memberData.staff_member_name}
                    </div>
                  </Card.Header>
                  <Card.Description>
                    <div
                      className="basic-info-row"
                      style={{ marginBottom: "24px" }}
                    >
                      <div className="basic-info-title">
                        {languagePack.staff_member_role + ":"}
                      </div>
                      <div className="basic-info-value">
                        {memberData.staff_member_role}
                      </div>
                    </div>

                    <div
                      className="edit-staff-details-profile"
                      onClick={() => setOpenDetailPopup(true)}
                      title={languagePack["edit_details"]}
                    >
                      <Icon
                        name="pencil"
                        size="small"
                        className="edit-details-icon"
                      />
                    </div>
                    <div
                      className="delete-staff-details-profile"
                      onClick={() => setDeleteStaffPopup(true)}
                      title={languagePack["delete_player"]}
                    >
                      <Icon
                        name="trash"
                        size="small"
                        className="edit-details-icon"
                      />
                    </div>
                  </Card.Description>
                </Card.Content>
              </Card>
            </div>

            <div className="col-lg-10 col-xxl-10 no-margin-bottom">
              <div className="row" style={{ height: "100%" }}>
                {isTFB() &&
                  <div className="col-lg-5 col-xxl-5 widget staff-profile-contract no-padding-horizontal-mobile-991">
                    <div className="card card-custom" style={{ height: "100%" }}>
                      {contractData != "" && (
                        <ContractWidget
                          bonuses={bonuses}
                          contractData={contractData}
                          languagePack={languagePack}
                          id_staff={idStaff}
                          id_contract={idContract}
                          hasFinancialAccess={
                            financialAccess ||
                            !typeof financialAccess == "undefined"
                          }
                        />
                      )}
                    </div>
                  </div>
                }

                <div className="col-lg-7 col-xxl-7 widget no-padding-horizontal-mobile-991">
                  <div style={{ height: "100%" }}>
                    <StaffLicense
                      date_expire_license={memberData.date_expire_license}
                      license_type={memberData.license}
                      license_document={memberData.license_document}
                      id_staff={idStaff}
                      languagePack={languagePack}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row margin-bottom-row">
            {isTFB() &&
              <div className="col-lg-12 col-xxl-12 widget">
                <div className="card card-custom">
                  <DocumentsWidget id_staff_member={props.match.params.id} />
                </div>
              </div>
            }
          </div>
        </>
      )}
      <Dialog open={openDetailPopup} maxWidth="xl">
        <DialogContent>
          <EditStaffDetails
            closePopup={() => setOpenDetailPopup(false)}
            refreshData={getMemberInfo}
            item={memberData}
            languagePack={languagePack}
            club={props.currentUser.id_club}
            handleImageCallback={handleSetImage}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={deleteStaffPopup} maxWidth="xl">
        <DialogContent>
          <div className="delete-staff-popup-container">
            <div className="delete-staff-header">
              <div className="delete-staff-title">
                {languagePack.delete_staff_member}
              </div>
              <Icon
                name="close"
                size="large"
                onClick={() => setDeleteStaffPopup(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="delete-staff-text">
              {languagePack.delete_staff_member_text}
            </div>
            <div className="delete-staff-buttons">
              <Button
                className="delete-staff-button"
                onClick={() => setDeleteStaffPopup(false)}
                style={{ backgroundColor: "#666666" }}
              >
                {languagePack["admin_cancel"]}
                <Icon name="ban" size="small" className="delete-player-icon" />
              </Button>
              <Button
                className="delete-staff-button"
                style={{ backgroundColor: "var(--primaryColor)" }}
                onClick={() => {
                  deleteStaffMember();
                  setDeleteStaffPopup(false);
                }}
              >
                {languagePack["admin_delete"]}
                <Icon
                  name="trash"
                  size="small"
                  className="delete-player-icon"
                />
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
      login,
      updateQuickSetup,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    lang: layoutService.lang,
    currentUser: auth.user,
  }),
  mapDispatchToProps
)(StaffProfile);
