import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UpdateQuickSetup: "[Update Quick Setup] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  clubQuickSetup: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v709-demo3-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { user } = action.payload;

        return { user };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UpdateQuickSetup: {
        const clubQuickSetup = {
          ...action.payload,
        };

        return { ...state, clubQuickSetup: clubQuickSetup };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user) => ({ type: actionTypes.Login, payload: { user } }),
  logout: () => ({ type: actionTypes.Logout }),
  updateQuickSetup: (updatedSetup) => ({
    type: actionTypes.UpdateQuickSetup,
    payload: updatedSetup,
  }),
};

export function* saga() {}
