import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "./../../../utils/api";
import axios from "axios";
import { store } from "react-notifications-component";
import { Button, Icon, TextArea } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import "./AddNecessityReport.scss";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import { baseUrl } from "../../../utils/utilFunctions";

const statusList = {
  approved: {
    text: "Aprobat",
    color: "var(--primaryColor)",
  },
  partial_approved: {
    text: "Partial aprobat",
    color: "#00aaff",
  },
  waiting: {
    text: "În așteptare",
    color: "#eedb00",
  },
  declined: {
    text: "Respins",
    color: "#d91200",
  },
};

class UpdateNecessityReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      register_number: "",
      report_date: new Date(),
      purchase_category: null,
      purchase_categories: [],
      just_of_purchase: "",

      articles: [],

      article: null,
      article_description: "",
      unit_price: "",
      quantity: "",
      tva_odd: "",
      um: "",
      workbook: false,
      include_tva_odd: true,

      additional_selected_articles: [],

      is_loaded: false,

      status: "",
      status_justification: "",

      user_effect: [],
    };
  }

  componentDidMount = () => {
    this.getCurrency();
    this.getPurchaseCategories();
    this.getArticles();
    this.getSelectedNecessityReportData();
  };

  getSelectedNecessityReportData = () => {
    axios
      .get(
        `finances/get_necessity_report_data?id_report=${this.props.idReport}`
      )
      .then((res) => {
        this.setState({
          register_number: res.data["register_number"],
          report_date: new Date(res.data["report_date"]),
          purchase_category: res.data["purchase_category"],
          just_of_purchase: res.data["just_of_purchase"],

          article: res.data.articles_array[0]["article"],
          article_description:
            res.data.articles_array[0]["article_description"],
          unit_price: res.data.articles_array[0]["unit_price"],
          quantity: res.data.articles_array[0]["quantity"],
          tva_odd: res.data.articles_array[0]["tva_odd"],
          um: res.data.articles_array[0]["um"],
          workbook: res.data.articles_array[0]["workbook"],
          include_tva_odd: res.data.articles_array[0]["include_tva_odd"],
          is_loaded: true,
          status: res.data["status"],
          status_justification: res.data["status_justification"],
          user_effect: res.data["user_effect"],
        });
        if (res.data.articles_array.length > 1) {
          let new_array = res.data.articles_array;
          new_array.shift();
          this.setState({
            additional_selected_articles: new_array,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getCurrency = () => {
    fetch(baseUrl("club/get_currency_rates"))
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };

  getPurchaseCategories = () => {
    axios
      .get(
        `finances/get_purchase_categories?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          purchase_categories: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getArticles = () => {
    axios
      .get(`finances/get_articles?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState({
          articles: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  handleChangeArticleNameAdditional = (selected, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index] = {
      article: {
        ...selected,
        details: "",
        price: "",
        um: "",
        vat: "",
      },
      article_description: "",
      unit_price: "",
      tva_odd: "",
      um: "",
      workbook: false,
      include_tva_odd: true,
      quantity: "",
    };
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleChangeArtDescAddit = (e, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index].article_description = e.target.value;
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleChangeUnitPriceAddit = (e, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index].unit_price = e.target.value;
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleChangeQuantityAddit = (e, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index].quantity = e.target.value;
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleChangeTvaOddAddit = (e, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index].tva_odd = e.target.value;
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleChangeUmAddit = (e, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index].um = e.target.value;
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleChangeIncludeTvaOddAddit = (value, index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles[index].include_tva_odd = value;
    this.setState({ additional_selected_articles: additionalArticles });
  };

  deleteArticle = (index) => {
    let additionalArticles = this.state.additional_selected_articles;
    additionalArticles.splice(index, 1);
    this.setState({ additional_selected_articles: additionalArticles });
  };

  handleDateChangeStart = (value) => {
    this.setState({ report_date: value });
  };

  updateNecessityReport = () => {
    //generate articlers array
    let articles_array = [
      {
        id_necessity_item: this.state.article.value,
        necessity_item_name: this.state.article.label,
        necessity_item_details: this.state.article_description,
        necessity_item_price: this.state.unit_price,
        necessity_item_vat: this.state.tva_odd,
        necessity_item_unit_of_measure: this.state.um,
        necessity_item_qty: this.state.quantity,
        necessity_item_book: { true: "1", false: "0" }[this.state.workbook],
        necessity_item_include_vat: { true: "1", false: "0" }[
          this.state.include_tva_odd
        ],
      },
    ];

    this.state.additional_selected_articles.forEach((elem, index) => {
      articles_array.push({
        id_necessity_item: elem.article.value,
        necessity_item_name: elem.article.label,
        necessity_item_details: elem.article_description,
        necessity_item_price: elem.unit_price,
        necessity_item_vat: elem.tva_odd,
        necessity_item_unit_of_measure: elem.um,
        necessity_item_qty: elem.quantity,
        necessity_item_book: { true: "1", false: "0" }[elem.workbook],
        necessity_item_include_vat: { true: "1", false: "0" }[
          elem.include_tva_odd
        ],
      });
    });

    const payload = {
      id_club: this.props.currentUser.id_club,
      id_user: this.props.currentUser.id_user,
      registration_number: this.state.register_number,
      registration_date: this.state.report_date,
      id_necessity_category: this.state.purchase_category.value,
      necessity_category_name: this.state.purchase_category.label,
      purchase_justification: this.state.just_of_purchase,
      articles_array: articles_array,
      id_necessity_report: this.props.idReport,
    };

    axios
      .post("finances/update_necessity_report", payload)
      .then((res) => {
        if (res.data > 0) {
          this.props.refreshReports();
          store.addNotification({
            title: "Necessity report",
            message: "Referatul de necesitate a fost actualizat cu suscces!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Necessity report",
            message: "Referatul de necesitate nu a fost actualizat cu suscces!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div
        className="add-contract-container add-cost-container"
        style={{ height: this.state.height }}
      >
        {this.state.is_loaded && (
          <>
            <div className="status-row">
              <div className="status-row-container">
                <span className="status-label">{"Status: "}</span>
                <span
                  className="status-icon"
                  style={{
                    backgroundColor: statusList[this.state.status].color,
                  }}
                >
                  {statusList[this.state.status].text}
                </span>
              </div>
              {this.state.user_effect.map((elem, index) => {
                return (
                  <div key={"user-effect-" + index}>
                    <div className="status-row-container">
                      <span className="status-label">
                        {elem.status == "declined"
                          ? "Respins de: "
                          : "Approbat de: "}
                      </span>
                      <span className="status-justification">
                        {elem.full_name}
                      </span>
                    </div>
                    {elem.status == "declined" && (
                      <div className="status-row-container">
                        <span className="status-label">{"Motiv: "}</span>
                        <span className="status-justification">
                          {elem.status_justification}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="necessity-report-double-row">
              <input
                type="text"
                value={this.state.register_number}
                onChange={(e) => {
                  const new_value = e.target.value.replace(/[^0-9]*/g, "");
                  this.setState({ register_number: new_value });
                }}
                placeholder={"Număr de înregistrare"}
                className="add-necessity-report-form-input"
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="add-contract-row" style={{ marginTop: "14px" }}>
                  <DatePicker
                    autoOk={true}
                    value={this.state.report_date}
                    onChange={this.handleDateChangeStart}
                    format="dd.MM.yyyy"
                    name="contract_signing_date"
                    inputVariant="outlined"
                    className="contract-date-input"
                    emptyLabel={"Dată referat"}
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="calendar-icon"
                    size="small"
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
            <div className="add-contract-row">
              <TFBSelect
                placeholder={"Categorie achiziție"}
                value={this.state.purchase_category}
                options={this.state.purchase_categories}
                onChange={(selected) => {
                  this.setState({ purchase_category: selected });
                }}
                onBlur={(e) => {
                  if (e.target.value.length > 0) {
                    this.setState({
                      purchase_category: {
                        value: "0",
                        label: e.target.value,
                      },
                    });
                  }
                }}
              />
            </div>
            <div
              className="add-contract-row general-info"
              style={{ height: "70px" }}
            >
              <TextArea
                placeholder={"Justificare achiziție"}
                className="add-necessity-report-form-input"
                value={this.state.just_of_purchase}
                onChange={(e) =>
                  this.setState({ just_of_purchase: e.target.value })
                }
              />
            </div>
            <div className="add-contract-row">
              <TFBSelect
                placeholder={"Denumire articol"}
                value={this.state.article}
                options={this.state.articles}
                onChange={(selected) => {
                  this.setState({
                    article: selected,
                    article_description: selected.details,
                    unit_price: selected.price,
                    tva_odd: selected.vat,
                    um: selected.um,
                    workbook: false,
                    include_tva_odd: true,
                    quantity: "",
                  });
                }}
                onBlur={(e) => {
                  if (
                    e.target.value.length > 0 &&
                    e.target.value != this.state.article?.label
                  ) {
                    this.setState({
                      article: {
                        value: "0",
                        label: e.target.value,
                      },
                      article_description: "",
                      unit_price: "",
                      quantity: "",
                      tva_odd: "",
                      um: "",
                      workbook: false,
                      include_tva_odd: true,
                    });
                  }
                }}
              />
            </div>
            <div className="add-contract-row" style={{ height: "70px" }}>
              <TextArea
                placeholder={"Detalii articol"}
                className="add-necessity-report-form-input"
                value={this.state.article_description}
                onChange={(e) =>
                  this.setState({ article_description: e.target.value })
                }
              />
            </div>
            <div className="necessity-report-double-row">
              <input
                type="text"
                value={this.state.unit_price}
                onChange={(e) => this.setState({ unit_price: e.target.value })}
                placeholder={"Preț unitar"}
                className="add-necessity-report-form-input"
              />
              <input
                type="text"
                value={this.state.tva_odd}
                onChange={(e) => this.setState({ tva_odd: e.target.value })}
                placeholder={"Cotă TVA"}
                className="add-necessity-report-form-input"
              />
            </div>
            <div className="specification-book-container">
              <div className="specification-book">
                {"Prețul unitar include TVA:"}
              </div>
              <div
                className="currency-container"
                style={{ marginRight: 30, marginLeft: 20 }}
                onClick={() => this.setState({ include_tva_odd: true })}
              >
                <div className="checkbox-background">
                  <div
                    className={
                      this.state.include_tva_odd
                        ? "currency-checkbox currency-checkbox-selected checked-center"
                        : "currency-checkbox checked-center"
                    }
                  />
                </div>
                <div className="currency-name">DA</div>
              </div>
              <div
                className="currency-container"
                style={{ marginRight: 10 }}
                onClick={() => this.setState({ include_tva_odd: false })}
              >
                <div className="checkbox-background">
                  <div
                    className={
                      !this.state.include_tva_odd
                        ? "currency-checkbox currency-checkbox-selected checked-center"
                        : "currency-checkbox checked-center"
                    }
                  />
                </div>
                <div className="currency-name">NU</div>
              </div>
            </div>
            <div className="necessity-report-double-row">
              <input
                type="text"
                value={this.state.quantity}
                onChange={(e) => this.setState({ quantity: e.target.value })}
                placeholder={"Cantitate"}
                className="add-necessity-report-form-input"
              />
              <input
                type="text"
                value={this.state.um}
                onChange={(e) => this.setState({ um: e.target.value })}
                placeholder={"Unitate de măsură"}
                className="add-necessity-report-form-input"
              />
            </div>
            <div className="specification-book-container">
              <div className="specification-book">{"Caiet de sarcini:"}</div>
              <div
                className="currency-container"
                style={{ marginRight: 30, marginLeft: 20 }}
                onClick={() => this.setState({ workbook: true })}
              >
                <div className="checkbox-background">
                  <div
                    className={
                      this.state.workbook
                        ? "currency-checkbox currency-checkbox-selected checked-center"
                        : "currency-checkbox checked-center"
                    }
                  />
                </div>
                <div className="currency-name">DA</div>
              </div>
              <div
                className="currency-container"
                style={{ marginRight: 10 }}
                onClick={() => this.setState({ workbook: false })}
              >
                <div className="checkbox-background">
                  <div
                    className={
                      !this.state.workbook
                        ? "currency-checkbox currency-checkbox-selected checked-center"
                        : "currency-checkbox checked-center"
                    }
                  />
                </div>
                <div className="currency-name">NU</div>
              </div>
            </div>
            {this.state.additional_selected_articles.map((elem, index) => {
              return (
                <div
                  key={"additional-label-" + index}
                  className="additional-label-report"
                >
                  <div className="add-contract-row">
                    <TFBSelect
                      placeholder={"Denumire articol"}
                      value={elem.article}
                      options={this.state.articles}
                      onChange={(selected) => {
                        this.handleChangeArticleNameAdditional(selected, index);
                      }}
                      onBlur={(e) => {
                        if (
                          e.target.value.length > 0 &&
                          e.target.value !=
                            this.state.additional_selected_articles[index]
                              .article?.label
                        ) {
                          let additionalArticles = this.state
                            .additional_selected_articles;
                          additionalArticles[index] = {
                            article: {
                              value: "0",
                              label: e.target.value,
                            },
                            article_description: "",
                            unit_price: "",
                            quantity: "",
                            tva_odd: "",
                            um: "",
                            workbook: false,
                            include_tva_odd: true,
                          };
                          this.setState({
                            additional_selected_articles: additionalArticles,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="add-contract-row" style={{ height: "70px" }}>
                    <TextArea
                      placeholder={"Detalii articol"}
                      className="add-necessity-report-form-input"
                      value={elem.article_description}
                      onChange={(e) => this.handleChangeArtDescAddit(e, index)}
                    />
                  </div>
                  <div className="necessity-report-double-row">
                    <input
                      type="text"
                      value={elem.unit_price}
                      onChange={(e) =>
                        this.handleChangeUnitPriceAddit(e, index)
                      }
                      placeholder={"Preț unitar"}
                      className="add-necessity-report-form-input"
                    />
                    <input
                      type="text"
                      value={elem.tva_odd}
                      onChange={(e) => this.handleChangeTvaOddAddit(e, index)}
                      placeholder={"Cotă TVA"}
                      className="add-necessity-report-form-input"
                    />
                  </div>
                  <div className="specification-book-container">
                    <div className="specification-book">
                      {"Prețul unitar include TVA:"}
                    </div>
                    <div
                      className="currency-container"
                      style={{ marginRight: 30, marginLeft: 20 }}
                      onClick={() =>
                        this.handleChangeIncludeTvaOddAddit(true, index)
                      }
                    >
                      <div className="checkbox-background">
                        <div
                          className={
                            elem.include_tva_odd
                              ? "currency-checkbox currency-checkbox-selected checked-center"
                              : "currency-checkbox checked-center"
                          }
                        />
                      </div>
                      <div className="currency-name">DA</div>
                    </div>
                    <div
                      className="currency-container"
                      style={{ marginRight: 10 }}
                      onClick={() =>
                        this.handleChangeIncludeTvaOddAddit(false, index)
                      }
                    >
                      <div className="checkbox-background">
                        <div
                          className={
                            !elem.include_tva_odd
                              ? "currency-checkbox currency-checkbox-selected checked-center"
                              : "currency-checkbox checked-center"
                          }
                        />
                      </div>
                      <div className="currency-name">NU</div>
                    </div>
                  </div>
                  <div className="necessity-report-double-row">
                    <input
                      type="text"
                      value={elem.quantity}
                      onChange={(e) => this.handleChangeQuantityAddit(e, index)}
                      placeholder={"Cantitate"}
                      className="add-necessity-report-form-input"
                    />
                    <input
                      type="text"
                      value={elem.um}
                      onChange={(e) => this.handleChangeUmAddit(e, index)}
                      placeholder={"Unitate de măsură"}
                      className="add-necessity-report-form-input"
                    />
                  </div>
                  <div className="specification-book-container">
                    <div className="specification-book">
                      {"Caiet de sarcini:"}
                    </div>
                    <div
                      className="currency-container"
                      style={{ marginRight: 30, marginLeft: 20 }}
                      onClick={() => {
                        let currentAdditionalArticles = this.state
                          .additional_selected_articles;
                        currentAdditionalArticles[index].workbook = true;
                        this.setState({
                          additional_selected_articles: currentAdditionalArticles,
                        });
                      }}
                    >
                      <div className="checkbox-background">
                        <div
                          className={
                            elem.workbook
                              ? "currency-checkbox currency-checkbox-selected checked-center"
                              : "currency-checkbox checked-center"
                          }
                        />
                      </div>
                      <div className="currency-name">DA</div>
                    </div>
                    <div
                      className="currency-container"
                      style={{ marginRight: 10 }}
                      onClick={() => {
                        let currentAdditionalArticles = this.state
                          .additional_selected_articles;
                        currentAdditionalArticles[index].workbook = false;
                        this.setState({
                          additional_selected_articles: currentAdditionalArticles,
                        });
                      }}
                    >
                      <div className="checkbox-background">
                        <div
                          className={
                            !elem.workbook
                              ? "currency-checkbox currency-checkbox-selected checked-center"
                              : "currency-checkbox checked-center"
                          }
                        />
                      </div>
                      <div className="currency-name">NU</div>
                    </div>
                  </div>
                  <div className="delete-article-necessity-container">
                    <Button
                      className="add-file-button cancel-button"
                      onClick={() => this.deleteArticle(index)}
                    >
                      {"Elimină articol"}
                      <Icon
                        name="minus"
                        size="small"
                        className="add-file-button-icon"
                      />
                    </Button>
                  </div>
                </div>
              );
            })}
            <div className="add-contract-row necessity-label-button">
              <Button
                className="add-label-button"
                onClick={() => {
                  let currentAdditionalArticles = this.state
                    .additional_selected_articles;
                  currentAdditionalArticles.push({
                    article: null,
                    article_description: "",
                    unit_price: "",
                    quantity: "",
                    tva_odd: "",
                    um: "",
                    workbook: false,
                    include_tva_odd: true,
                  });
                  this.setState({
                    additional_selected_articles: currentAdditionalArticles,
                  });
                }}
              >
                {"Adauga articol"}
                <Icon
                  name="add"
                  size="small"
                  className="add-file-button-icon"
                />
              </Button>
            </div>
            <div
              className="add-file-buttons-container"
              style={{ marginBottom: 25 }}
            >
              <Button
                className="add-file-button cancel-button"
                onClick={() => this.props.closePopup()}
              >
                {languagePack["admin_cancel"]}
                <Icon
                  name="ban"
                  size="small"
                  className="add-file-button-icon"
                />
              </Button>
              <Button
                className="add-file-button"
                onClick={this.updateNecessityReport}
                disabled={
                  this.state.register_number == "" ||
                  this.state.purchase_category == null ||
                  this.state.article == null ||
                  this.state.unit_price == "" ||
                  this.state.quantity == "" ||
                  this.state.tva_odd == "" ||
                  this.state.um == "" ||
                  (this.state.additional_selected_articles.length > 0
                    ? !this.state.additional_selected_articles.every((elem) => {
                        if (
                          elem.article != null &&
                          elem.unit_price != "" &&
                          elem.quantity != "" &&
                          elem.tva_odd != "" &&
                          elem.um != ""
                        ) {
                          return true;
                        }
                        return false;
                      })
                    : false)
                }
              >
                {"Salveaza"}
                <Icon
                  name="add"
                  size="small"
                  className="add-file-button-icon"
                />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(UpdateNecessityReport);
