import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import "./MatchDetails.scss";
import styles from "./Match.module.scss";
import AddMatchEvent from "./AddMatchEvent.js";
import DeleteMatchEvent from "./DeleteMatchEvent";

import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell } from "../../../../components/reusable/useStyles";
import CircularLoader from "../../../../components/reusable/Loader";

const MatchEvents = (props) => {
  const {
    languagePack,
    matchDetails,
    matchTeams,
    teamsPlayers,
    staff,
    hasAccessToDeleteHomeEvents,
    hasAccessToDeleteAwayEvents,
    updatePlayersRating,
    updateLineups,
  } = props;

  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open_add_event: false,

      open_delete_event: false,
      deleting_event: {},
    }
  );

  const openAddEvent = () => {
    updateState({ open_add_event: true });
  };

  const closeAddEvent = () => {
    updateState({ open_add_event: false });
  };

  const openDeleteEvent = (event) => {
    updateState({ open_delete_event: true, deleting_event: event });
  };

  const closeDeleteEvent = () => {
    updateState({ open_delete_event: false, deleting_event: {} });
  };

  const {
    data: eventList,
    loading: isEventListLoading,
    refetch: refetchEventList,
  } = useAxiosGet(
    `matches/get_manual_match_events?id_match=${matchDetails.id_match}`
  );

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack["match_events"]}>
        <TFBButton
          color="green"
          onClick={openAddEvent}
          renderIcon={() => <TFBIcon name="add" />}
        >
          {languagePack["add_event"]}
        </TFBButton>
      </TFBCardHeader>
      <TFBCardBody>
        {!isEventListLoading ? (
          _.size(eventList?.events) > 0 ? (
            <TableContainer>
              <Table faria-label="simple table">
                <TableBody>
                  {_.map(eventList.events, (row, index) => (
                    <TableRow
                      key={"notification-number-" + index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                      className={styles.eventRow}
                    >
                      <TFBTableCell>
                        <div
                          className={`${styles.eventDiv} ${
                            row.autogoal == 1
                              ? matchDetails.id_team_home ==
                                row.id_manual_competition_team
                                ? styles.awayEvent
                                : ""
                              : matchDetails.id_team_away ==
                                  row.id_manual_competition_team &&
                                row.autogoal != 1
                              ? styles.awayEvent
                              : ""
                          }`}
                        >
                          <div className={styles.eventData}>
                            <div className={styles.minute}>
                              {row.event_minute + "'"}
                            </div>
                            <div className={styles.verticalBar}></div>
                            <div className={styles.eventFirstPart}>
                              <div className={styles.eventSvg}>
                                <TFBIcon
                                  name={
                                    {
                                      Goal: "soccer-goal",
                                      "Yellow card": "soccer-card",
                                      "Red card": "soccer-card",
                                      Substitution: "arrow-down",
                                    }[row.event_type]
                                  }
                                  color={
                                    {
                                      Goal: row.autogoal == 1 ? "red" : "green",
                                      "Yellow card": "yellow",
                                      "Red card": "red",
                                      Substitution: "red",
                                    }[row.event_type]
                                  }
                                />
                              </div>
                              <div
                                className={styles.player}
                                onClick={() => {
                                  if (row.id_player !== null) {
                                    history.push(
                                      "/club/player/" + row.id_player
                                    );
                                  } else {
                                    history.push("/club/staff/" + row.id_staff);
                                  }
                                }}
                              >
                                {row.player_name !== ""
                                  ? row.player_name
                                  : row.staff_name !== ""
                                  ? `${row.staff_name} (staff)`
                                  : ""}
                              </div>
                              {row.event_type === "Goal" &&
                                row.player_assist_name && (
                                  <div
                                    className={styles.playerAssist}
                                    onClick={() =>
                                      history.push(
                                        "/club/player/" + row.id_opponent
                                      )
                                    }
                                  >
                                    {" (" + row.player_assist_name + ")"}
                                  </div>
                                )}
                            </div>
                            {row.event_type === "Substitution" && (
                              <div className={styles.eventFirstPart}>
                                <div className={styles.eventAssistSvg}>
                                  <TFBIcon name="arrow-up" color="green" />
                                </div>
                                <div
                                  className={styles.playerAssist}
                                  onClick={() =>
                                    history.push(
                                      "/club/player/" + row.id_opponent
                                    )
                                  }
                                >
                                  {row.player_assist_name}
                                </div>
                              </div>
                            )}
                          </div>
                          {(matchDetails.id_team_home ==
                          row.id_manual_competition_team
                            ? hasAccessToDeleteHomeEvents
                            : hasAccessToDeleteAwayEvents) && (
                            <div
                              className={styles.deleteEvent}
                              onClick={() => openDeleteEvent(row)}
                            >
                              <TFBIcon name="delete" color="gray" />
                            </div>
                          )}
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack.no_data_events} />
          )
        ) : (
          <CircularLoader />
        )}
      </TFBCardBody>

      {state.open_add_event && (
        <AddMatchEvent
          openPopup={state.open_add_event}
          closePopup={closeAddEvent}
          refreshList={() => {
            refetchEventList();
            updatePlayersRating();
            updateLineups();
          }}
          staff={staff}
          teams={matchTeams}
          players={teamsPlayers}
          matchDetails={matchDetails}
        />
      )}

      {state.open_delete_event && (
        <DeleteMatchEvent
          openPopup={state.open_delete_event}
          closePopup={closeDeleteEvent}
          refreshList={() => {
            refetchEventList();
            updatePlayersRating();
            updateLineups();
          }}
          event={state.deleting_event}
        />
      )}
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchEvents);
