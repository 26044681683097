import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";

const AddPayment = (props) => {
  const {
    openPopup,
    closePopup,
    refreshList,
    languagePack,
    currentUser,
    data,
    year,
    lastNrReceipt,
  } = props;

  const { id_club, lang } = currentUser;

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      month: null,
      amount: data?.monthly_tax ?? "",
      payment_type: null,
      generate_receipt: false,
      receipt_name: "",
      receipt_payer: "",
      receipt_serial: "",
      nr_receipt: lastNrReceipt + 1,
      date_of_payment: "",
      payment_date: null,
      description: "",

      open_confirm_add_payment: false,
    }
  );

  const defaultReceiptName = `Taxă luna ${state.month?.label} - ${year}`;

  const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost(
    "payment/add_manual_payment"
  );

  const handleResponse = (response) => {
    closeConfirmPayment();
    if (Number(response?.success) === 1) {
      closePopup();
      refreshList();
      showNotification(
        "success",
        languagePack.finances,
        languagePack.success_add_payment
      );
    } else {
      showNotification(
        "danger",
        languagePack.finances,
        languagePack.fail_add_payment
      );
    }
  };

  const openConfirmPayment = () => {
    updateState({ open_confirm_add_payment: true });
  };

  const closeConfirmPayment = () => {
    updateState({ open_confirm_add_payment: false });
  };

  const checkExistsPaymentForSelectedMonth = (month) => {
    let exists = false;
    let payment = {};

    const paymentSearch = _.find(
      data?.payments?.list,
      (e) => e.payment_month == month && e.payment_year == year
    );

    if (paymentSearch) {
      exists = true;
      payment = paymentSearch;
    }

    return { exists, payment };
  };

  const checkAddPayment = () => {
    const { exists } = checkExistsPaymentForSelectedMonth(
      state.month?.value ?? null
    );

    if (exists) {
      openConfirmPayment();
    }

    return !exists;
  };

  const addPayment = () => {
    let payload = {
      payment_data: {
        id_player: data.id_player,
        payment_type: state.payment_type?.label ?? null,
        payment_type_label: state.payment_type?.value ?? null,
        payment_month: state.month?.value ?? null,
        payment_year: year,
        payment_description: state.description,
        payment_amount: state.amount,
        payment_payed_date: state.payment_date,
        id_club: id_club,
      },
      generate_receipt: { true: "1", false: "0" }[state.generate_receipt],
    };

    if (state.generate_receipt) {
      payload.receipt_data = {
        nr_receipt: state.nr_receipt,
        amount: state.amount,
        paid_by: state.receipt_payer,
        receipt_description: state.receipt_name,
        receipt_date: state.payment_date,
        receipt_series: state.receipt_serial,
      };
    }

    const { exists, payment } = checkExistsPaymentForSelectedMonth(
      state.month?.value ?? null
    );

    if (exists) {
      payload.id_payment = payment.id_payment;
    } else {
      payload.id_payment = null;
    }

    addRequest(payload, handleResponse);
  };

  const { renderBtn: BtnLeft } = useRenderButton(
    () =>
      state.open_confirm_add_payment ? closeConfirmPayment() : closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () =>
      state.open_confirm_add_payment
        ? addPayment()
        : checkAddPayment()
        ? addPayment()
        : {},
    "green",
    "add",
    languagePack.admin_save,
    {},
    true,
    state.month == null ||
      state.amount === "" ||
      state.payment_type == null ||
      state.payment_date == null ||
      (state.payment_type?.value === "cash" && state.generate_receipt
        ? _.size(_.trim(state.receipt_name)) === 0 ||
          _.size(_.trim(state.receipt_payer)) === 0 ||
          _.size(_.trim(state.receipt_serial)) === 0 ||
          !_.toNumber(state.nr_receipt) > 0
        : false),
    loadingAddRequest
  );

  useEffect(() => {
    if (state.payment_type) {
      if (state.payment_type?.value !== "cash") {
        resetReceiptData();
      }
    }
  }, [state.payment_type]);

  const resetReceiptData = () => {
    updateState({
      generate_receipt: false,
      receipt_name: "",
      receipt_payer: "",
      receipt_serial: "",
      nr_receipt: "",
    });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["add_payment_title"] + " " + data.player_name}
      open={
        state.open_confirm_add_payment
          ? state.open_confirm_add_payment
          : openPopup
      }
      closePopup={
        state.open_confirm_add_payment ? closeConfirmPayment : closePopup
      }
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      {!state.open_confirm_add_payment ? (
        <>
          <TFBSelect
            label={languagePack["add_payment_select_month"]}
            value={state.month}
            options={_.map(_.range(12), (v) => ({
              value: v + 1,
              label: _.capitalize(
                moment()
                  .set("month", v)
                  .locale(lang ?? "ro")
                  .format("MMMM")
              ),
            }))}
            onChange={(selected) => updateState({ month: selected })}
            style={{ component: { marginBottom: 20 } }}
          />
          <TFBInput
            label={languagePack.add_payment_amount}
            value={state.amount}
            onChange={(e) => updateState({ amount: e.target.value })}
            style={{ marginBottom: 20, width: "100%" }}
          />
          <TFBSelect
            label={languagePack.payment_type}
            value={state.payment_type}
            options={_.map(["cash", "bank_transfer"], (e) => ({
              value: e,
              label: languagePack.add_payment_type?.[e],
            }))}
            onChange={(selected) => updateState({ payment_type: selected })}
            style={{ component: { marginBottom: 20 } }}
          />
          {state.payment_type?.value === "cash" && (
            <TFBCheckbox
              label={"Generează chitanță"}
              checked={state.generate_receipt}
              onChange={(e) =>
                updateState({
                  generate_receipt: e.target.checked,
                  receipt_name:
                    e.target.checked && _.size(_.trim(state.receipt_name)) === 0
                      ? defaultReceiptName
                      : state.receipt_name,
                })
              }
              style={{ marginBottom: 20 }}
            />
          )}
          {state.generate_receipt && (
            <>
              <TFBInput
                label={"Nume taxă"}
                value={state.receipt_name}
                onChange={(e) => updateState({ receipt_name: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBInput
                label={"Nume plătitor"}
                value={state.receipt_payer}
                onChange={(e) => updateState({ receipt_payer: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBInput
                label={"Seria"}
                value={state.receipt_serial}
                onChange={(e) =>
                  updateState({ receipt_serial: e.target.value })
                }
                onKeyPress={(e) =>
                  !/\b[a-zA-Z]\b/.test(e.key) ? e.preventDefault() : {}
                }
                inputProps={{ maxLength: 3 }}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBInput
                label={"Numar chitanta"}
                type="number"
                value={state.nr_receipt}
                onChange={(e) => updateState({ nr_receipt: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
              />
            </>
          )}
          {state.payment_type && (
            <>
              <TFBDatePicker
                value={state.payment_date}
                onChange={(value) => updateState({ payment_date: value })}
                emptyLabel={languagePack["add_payment_date"]}
                label={languagePack["payment_date"]}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBTextArea
                label={languagePack["add_payment_description"]}
                value={state.description}
                onChange={(e) => updateState({ description: e.target.value })}
              />
            </>
          )}
        </>
      ) : (
        <div className="pop-up-dialog-delete-text">
          {`${languagePack.add_payment_overwrite_first} ${state.month?.label} ${languagePack.add_payment_overwrite_second} ${data.player_name}?`}
        </div>
      )}
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(AddPayment);
