import React from "react";
import axios from "axios";
import { logout } from "./../../../utils/api";
import "./Costs.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button } from "semantic-ui-react";
import moment from "moment";
import NumberFormat from "react-number-format";
import AddCost from "./AddCost";
import AddDepartment from "./AddDepartment";
import Loader from "react-loader-spinner";
import EditCost from "./EditCost";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import DepartmentsList from "./DepartmentsList";
import DeleteCost from "./DeleteCost";
import { baseUrl } from "../../../utils/utilFunctions";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";

class Costs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rates: [],

      costs: [],

      filter_btns: [
        {
          id: 0,
          name: "Staff",
          active_color: "#ff0055",
          type: "staff",
        },
        {
          id: 1,
          name: this.props.languagePack.match,
          active_color: "#aa00ff",
          type: "match",
        },
        {
          id: 2,
          name: this.props.languagePack.player,
          active_color: "#ff9500",
          type: "player",
        },
        {
          id: 3,
          name: this.props.languagePack.team,
          active_color: "#00aaff",
          type: "team",
        },
      ],

      departments: [],
      department: "",
      subdepartments: [],
      subdepartment: "",
      min_amount: "",
      max_amount: "",
      start_date: null,
      end_date: null,
      active_filter_btns: [],

      open_add_department: false,
      open_edit_departments: false,
      editing_departments: {},

      open_add_cost: false,
      open_edit_cost: false,
      editing_cost: {},
      open_delete_cost: false,
      deleting_cost: {},

      blocked_matches: [],
    };
  }

  componentDidMount = () => {
    this.getCurrency();
  };

  getCurrency = () => {
    axios
      .get(baseUrl("club/get_currency_rates"))
      .then((res) => {
        this.setState(
          {
            rates: res.data,
          },
          () => {
            this.getDepartments();
            this.getCosts();
          }
        );
      });
  };

  getCosts = () => {
    let params = {};

    if (this.state.department?.value) {
      params.id_department = this.state.department.value;
    }

    if (this.state.subdepartment?.value) {
      params.id_subdepartment = this.state.subdepartment.value;
    }

    if (this.state.min_amount != "") {
      params.min_amount = this.state.min_amount;
    }

    if (this.state.max_amount != "") {
      params.max_amount = this.state.max_amount;
    }

    if (this.state.start_date != null) {
      params.start_date = this.state.start_date;
    }

    if (this.state.end_date != null) {
      params.end_date = this.state.end_date;
    }

    if (this.state.active_filter_btns.length > 0) {
      let filters = [];
      this.state.active_filter_btns.forEach((filter) => {
        let filter_type = this.state.filter_btns.find(
          (elem) => elem.id == filter
        ).type;
        filters.push(filter_type);
      });
      params.filters = filters;
    }

    this.setState(
      {
        fetching_costs: true,
      },
      () => {
        axios
          .get(`finances/get_cost_items_2?id_club=${this.props.currentUser.id_club}`, { params: { params: JSON.stringify(params) } })
          .then((res) =>
            this.setState({
              costs: res.data.list,
              blocked_matches: res.data.blocked_matches,
              fetching_costs: false,
            })
          )
          .catch((err) => console.log(err));
      }
    );
  };

  getDepartments = () => {
    axios
      .get(`finances/get_departments?id_club=${this.props.currentUser.id_club}`)
      .then((res) =>
        this.setState({
          departments: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getSubdepartments = () => {
    axios
      .get(
        `finances/get_subdepartments?id_department=${this.state.department.value}`
      )
      .then((res) =>
        this.setState({
          subdepartments: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  convertCurrency = (value, currency) => {
    const user_currency = this.props.currentUser.currency;
    if (user_currency == currency) {
      return value;
    } else {
      if (user_currency == "EUR") {
        return value / this.state.rates[currency];
      } else {
        return value * this.state.rates[user_currency];
      }
    }
  };

  formatNumber = (value, currency) => {
    return (
      <NumberFormat
        value={this.convertCurrency(value, currency)}
        displayType={"text"}
        thousandSeparator={" "}
        decimalScale={2}
        fixedDecimalScale={2}
      />
    );
  };

  getTotalTransaction = (costs) => {
    let sum = 0;
    costs.map((elem) => {
      sum += parseFloat(this.convertCurrency(elem.amount, elem.currency));
    });

    return this.formatNumber(sum, this.props.currentUser.currency);
  };

  changeDepartment = (selected) => {
    this.setState(
      {
        department: selected,
        subdepartment: "",
      },
      this.getSubdepartments
    );
  };

  handleAmountInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  resetFilters = () => {
    this.setState(
      {
        department: "",
        subdepartments: [],
        subdepartment: "",
        min_amount: "",
        max_amount: "",
        start_date: null,
        end_date: null,
        active_filter_btns: [],
      },
      this.getCosts
    );
  };

  openAddDepartment = () => {
    this.setState({ open_add_department: true })
  }

  closeAddDepartment = () => {
    this.setState({ open_add_department: false })
  }

  openEditDepartments = (departments) => {
    this.setState({ open_edit_departments: true, editing_departments: departments })
  }

  closeEditDepartments = () => {
    this.setState({ open_edit_departments: false, editing_departments: {} })
  }

  openAddCost = () => {
    this.setState({ open_add_cost: true })
  }

  closeAddCost = () => {
    this.setState({ open_add_cost: false })
  }

  openEditCost = (cost) => {
    this.setState({ open_edit_cost: true, editing_cost: cost })
  }

  closeEditCost = () => {
    this.setState({ open_edit_cost: false, editing_cost: {} })
  }

  openDeleteCost = (cost) => {
    this.setState({ open_delete_cost: true, deleting_cost: cost })
  }

  closeDeleteCost = () => {
    this.setState({ open_delete_cost: false, deleting_cost: {} })
  }

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.SPENDING}>
          <TFBButton
            color="darkGray"
            onClick={this.openEditDepartments}
          >
            {languagePack.edit_departaments_list}
          </TFBButton>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={this.openAddDepartment}
          >
            {languagePack.add_departament}
          </TFBButton>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={this.openAddCost}
          >
            {languagePack.add_spending}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          <div className="costs-container">
            <div className="costs-filters-row">
              <div className="filters-row-content">
                <div className="filter-department">
                  <TFBSelect
                    label={languagePack.department}
                    placeholder={languagePack.department}
                    value={this.state.department}
                    options={this.state.departments}
                    onChange={(selected) => {
                      this.changeDepartment(selected);
                    }}
                    style={{
                      control: {
                        backgroundColor: "#d4d4d4",
                        border: "2px solid #d4d4d4",
                      },
                      placeholder: {
                        color: "#666666",
                      },
                      dropdownIndicator: {
                        color: "#666666",
                      },
                      valueContainer: {
                        paddingLeft: "15px",
                      }
                    }}
                  />
                </div>
                <div className="filter-subdepartment">
                  <TFBSelect
                    label={languagePack.subdepartment}
                    placeholder={languagePack.subdepartment}
                    value={this.state.subdepartment}
                    options={this.state.subdepartments}
                    onChange={(selected) => {
                      this.setState({
                        subdepartment: selected,
                      });
                    }}
                    style={{
                      control: {
                        backgroundColor: "#fff",
                        border: "2px solid #d4d4d4",
                      },
                      placeholder: {
                        color: "#666666",
                      },
                      dropdownIndicator: {
                        color: "#666666",
                      },
                      valueContainer: {
                        paddingLeft: "15px",
                      }
                    }}
                  />
                </div>
                <div className="filter-amount">
                  <h6 className="filter-label">{languagePack.add_payment_amount}</h6>
                  <div className="amount-content">
                    <input
                      name="min_amount"
                      type="number"
                      placeholder="Min"
                      value={this.state.min_amount}
                      onChange={(e) => this.handleAmountInputChange(e)}
                    />
                    <span className="amount-separator">{"-"}</span>
                    <input
                      name="max_amount"
                      type="number"
                      placeholder="Max"
                      value={this.state.max_amount}
                      onChange={(e) => this.handleAmountInputChange(e)}
                    />
                  </div>
                </div>
                <div className="filter-period-of-time">
                  <h6 className="filter-label">{languagePack.period}</h6>
                  <div className="time-filters">
                    <TFBDatePicker
                      placeholder={languagePack.date_format}
                      emptyLabel={languagePack.date_format}
                      value={this.state.start_date}
                      onChange={(value) =>
                        this.setState({ start_date: value })
                      }
                      className="start-time"
                    />
                    <span className="amount-separator">{"-"}</span>
                    <TFBDatePicker
                      placeholder={languagePack.date_format}
                      emptyLabel={languagePack.date_format}
                      value={this.state.end_date}
                      onChange={(value) =>
                        this.setState({ end_date: value })
                      }
                      className="start-time"
                    />
                  </div>
                </div>
                <div className="filter-labels">
                  <h6 className="filter-label">{languagePack.tags}</h6>
                  <div className="filter-list">
                    {this.state.filter_btns.map((button, index) => {
                      return (
                        <div
                          key={"filter-button-" + index}
                          className="filter-button-container"
                        >
                          <Button
                            onClick={() => {
                              let current_active_btns = this.state
                                .active_filter_btns;
                              const index = current_active_btns.indexOf(
                                button.id
                              );
                              if (index < 0) {
                                current_active_btns.push(button.id);
                              } else {
                                current_active_btns.splice(index, 1);
                              }
                              this.setState({
                                active_filter_btns: current_active_btns,
                              });
                            }}
                            className="filter-button"
                            style={{
                              backgroundColor:
                                this.state.active_filter_btns.indexOf(
                                  button.id
                                ) > -1
                                  ? button.active_color
                                  : "initial",
                              color:
                                this.state.active_filter_btns.indexOf(
                                  button.id
                                ) > -1
                                  ? "white"
                                  : "#666666",
                              border: `2px solid ${this.state.active_filter_btns.indexOf(
                                button.id
                              ) > -1
                                ? button.active_color
                                : "#d4d4d4"
                                }`,
                            }}
                          >
                            {button.name}
                          </Button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="costs-filters-row second-filters-row">
              <div className="filters-row-content">
                <TFBButton
                  color="green"
                  renderIcon={() => <TFBIcon name="check" />}
                  startIcon
                  onClick={this.getCosts}
                >
                  {languagePack.apply_filters}
                </TFBButton>
                {(this.state.department != "" ||
                  this.state.subdepartment != "" ||
                  this.state.min_amount != "" ||
                  this.state.max_amount != "" ||
                  this.state.start_date != null ||
                  this.state.end_date != null ||
                  this.state.active_filter_btns.length > 0) &&
                  <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    startIcon
                    onClick={this.resetFilters}
                  >
                    <span>{languagePack.cancel_filters}</span>
                  </TFBButton>
                }
              </div>
            </div>
            {this.state.fetching_costs ?
              <div className="loader-container" style={{ marginTop: 50 }}>
                <Loader
                  type="TailSpin"
                  color={window.clubCustomization.primary_color}
                  height={40}
                  width={40}
                />
              </div> :
              this.state.costs.length > 0 ?
                <TableContainer>
                  <Table faria-label="simple table" className="costs-table">
                    <TableHead>
                      <TableRow>
                        <TFBTableCell sx={sxStickyColumn}>
                          {languagePack.staff_description}
                        </TFBTableCell>
                        <TFBTableCell align="right">
                          {languagePack.add_payment_amount}
                        </TFBTableCell>
                        <TFBTableCell>
                          {languagePack.Moneda}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.department}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {languagePack.subdepartment}
                        </TFBTableCell>
                        <TFBTableCell>
                          {languagePack.add_payment_date}
                        </TFBTableCell>
                        <TFBTableCell align="right">
                          {languagePack.product_actions}
                        </TFBTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="costs-table-content">
                      {this.state.costs.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                          className="cost-row-content"
                        >
                          <TFBTableCell
                            sx={sxStickyColumn}
                            className="description"
                            style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                          >
                            <div className="description">
                              <TFBPlayerWithAvatar
                                avatarUrl={row.labels.length > 0
                                  ? row.labels[0]["type"] == "staff"
                                    ? baseUrl(`uploads/staff_members/${row.labels[0]["entity_id"]}.jpg`)
                                    : row.labels[0]["type"] == "player"
                                      ? baseUrl(`uploads/players/${row.labels[0]["entity_id"]}.jpg`)
                                      : ""
                                  : ""}
                                size={50}
                              />
                              <div className="cost-info">
                                <div className="cost-name">
                                  {row["description"]}
                                </div>
                                <div className="cost-tags">
                                  {row.labels.map((tag, index2) => {
                                    return (
                                      <div
                                        className="tag-container"
                                        key={"tag-" + index + "-" + index2}
                                        style={{
                                          backgroundColor: this.state.filter_btns.find(
                                            (elem) => elem.type == tag.type
                                          ).active_color,
                                        }}
                                      >
                                        <div className="tag-text">
                                          {tag.entity_name}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="right" style={{ whiteSpace: "nowrap" }}>
                            {this.formatNumber(row["amount"], row["currency"])}
                          </TFBTableCell>
                          <TFBTableCell>
                            {this.props.currentUser.currency}
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            {row["department_name"]}
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            {row["subdepartment_name"] ?? ""}
                          </TFBTableCell>
                          <TFBTableCell>
                            {moment(row["payment_date"]).format("DD.MM.YYYY")}
                          </TFBTableCell>
                          <TFBTableCell>
                            <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                              <TFBIconButton
                                name="edit"
                                color="darkGray"
                                onClick={() => this.openEditCost(row)}
                              />
                              <TFBIconButton
                                name="delete"
                                color="darkGray"
                                onClick={() => this.openDeleteCost(row)}
                              />
                            </div>
                          </TFBTableCell>
                        </TableRow>
                      ))}
                      {this.state.costs.length > 0 &&
                        <TableRow
                          key={this.state.costs.length - 1}
                          sx={{ backgroundColor: "#666" }}
                        >
                          <TFBTableCell
                            sx={sxStickyColumn}
                            style={{ backgroundColor: "#666", color: "white" }}
                          >
                            {`${languagePack.total_expenses}:`}
                          </TFBTableCell>
                          <TFBTableCell align="right" style={{ color: "var(--primaryColor)", whiteSpace: "nowrap" }}>
                            {this.getTotalTransaction(this.state.costs)}
                          </TFBTableCell>
                          <TFBTableCell style={{ color: "var(--primaryColor)" }}>
                            {this.props.currentUser.currency}
                          </TFBTableCell>
                          <TFBTableCell></TFBTableCell>
                          <TFBTableCell></TFBTableCell>
                          <TFBTableCell></TFBTableCell>
                          <TFBTableCell></TFBTableCell>
                        </TableRow>
                      }
                    </TableBody>
                  </Table>
                </TableContainer> :
                <TFBPlaceholder text={languagePack.no_costs} />
            }
          </div>
        </TFBCardBody>

        {/* ------------------- ADD DEPARTMENT ---------------- */}
        {this.state.open_add_department &&
          <AddDepartment
            isOpenPopup={this.state.open_add_department}
            closePopup={this.closeAddDepartment}
            refreshList={this.getDepartments}
          />
        }

        {/* ------------------- EDIT DEPARTMENTS ---------------- */}
        {this.state.open_edit_departments &&
          <DepartmentsList
            isOpenPopup={this.state.open_edit_departments}
            closePopup={this.closeEditDepartments}
            refreshList={this.getDepartments}
            list={this.state.departments}
          />
        }

        {/* ------------------- ADD COST ---------------- */}
        {this.state.open_add_cost &&
          <AddCost
            isOpenPopup={this.state.open_add_cost}
            closePopup={this.closeAddCost}
            refreshList={this.getCosts}
            list={this.state.departments}
            blockedPairs={this.state.blocked_matches}
          />
        }

        {/* ------------------- EDIT COST ---------------- */}
        {this.state.open_edit_cost &&
          <EditCost
            isOpenPopup={this.state.open_edit_cost}
            closePopup={this.closeEditCost}
            refreshList={this.getCosts}
            list={this.state.departments}
            costData={this.state.editing_cost}
            blockedPairs={this.state.blocked_matches}
          />
        }

        {/* ------------------- DELETE COST ---------------- */}
        {this.state.open_delete_cost &&
          <DeleteCost
            isOpenPopup={this.state.open_delete_cost}
            closePopup={this.closeDeleteCost}
            refreshList={this.getCosts}
            costData={this.state.deleting_cost}
          />
        }
      </TFBCard>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Costs);
