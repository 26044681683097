import React, { useContext, useEffect, useState } from "react";
import "./stepGroup.scss";
import _ from "lodash";
import TFBIcon from "../../../../../app/components/design-system/Icon/TFBIcon";
import TFBButton from "../../../../../app/components/design-system/Button/TFBButton";
import { connect, useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../../redux/authRedux";
import { useHistory } from "react-router-dom";
import SelectTeamPopup from "./SelectTeamPopup";

const StepGroup = ({
  currentMainStep,
  mainSteps,
  languagePack,
  isQuickAction,
  refetchTeams,
}) => {
  const [stepsToShow, setStepsToShow] = useState(null);
  const [selectTeamPopup, setSelectTeamPopup] = useState(false);
  const [addPlayerPage, setAddPlayerPage] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  // GET clubQuickSetup FROM REDUX STORE STATE ===============================
  // ===============================================================
  const { clubQuickSetup } = useSelector(({ auth }) => ({
    clubQuickSetup: auth?.clubQuickSetup,
  }));

  // USE EFFECTS ===============================
  // ========================================
  useEffect(() => {
    if (mainSteps) setStepsToShow(mainSteps[currentMainStep].action);
  }, [mainSteps, currentMainStep]);

  useEffect(() => {
    refetchTeams();
  }, [selectTeamPopup]);

  // HANDLERS ===============================
  // ========================================
  const handleClick = (page, stepSlug) => {
    if (stepSlug != "add_player_min") {
      let historyState =
        stepSlug == "add_staff_min"
          ? { openAddStaff: true }
          : { redirectFromSetup: true };

      history.push(page, historyState);

      dispatch(
        actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: false })
      );
    } else {
      setAddPlayerPage(page);
      setSelectTeamPopup(true);
    }
  };

  // RETURN COMPONENTS ===============================
  // ========================================

  return (
    <div className="step-group-container">
      {_.map(stepsToShow, (step, index) => (
        <TFBButton
          key={"scondary-step-" + index}
          onClick={() => handleClick(step.url_path, step.slug_name)}
          color={
            step.progress >= step.target && !isQuickAction
              ? "darkGray"
              : "green"
          }
          renderIcon={() => <TFBIcon name={"arrow-right"} />}
        >
          <div style={{ gap: 10, display: "flex" }}>
            {step.progress >= step.target && !isQuickAction && (
              <TFBIcon name="check" color="white" />
            )}
            {!isQuickAction
              ? String(languagePack.quickSetup[step.slug_name]).replaceAll(
                  "x_number",
                  step.target
                )
              : String(languagePack.quickSetup[step.slug_name])
                  .replaceAll("x_number", "")
                  .replace(/(minim|minimum)\s+/i, "")
                  .replace(/\([^)]*\)/, "")}
          </div>
        </TFBButton>
      ))}

      <SelectTeamPopup
        selectTeamPopup={selectTeamPopup}
        languagePack={languagePack}
        closePopup={() => setSelectTeamPopup(false)}
        clubQuickSetup={clubQuickSetup}
        redirectPage={addPlayerPage}
      />
    </div>
  );
};

export default connect(
  ({ auth }) => ({
    clubQuickSetup: auth.clubQuickSetup,
  }),
  { updateQuickSetup: actions.updateQuickSetup }
)(StepGroup);
