import React, { Component } from "react";
import { connect } from "react-redux";
import "./AddProduct.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { store } from "react-notifications-component";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import { handleFileInput } from "../../../utils/utilFunctions";

class EditProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_name: props?.productData?.product_name,
      product_price: props?.productData?.product_price,
      description: props?.productData?.product_description ?? "",
      is_active: props?.productData?.product_status === "1" ? true : false,
      categories: [...props?.productData?.categories],
      sizes: [...props?.productData?.sizes],
      product_image: [],
    };
  }

  updateProduct = async () => {
    const { languagePack, closePopup, refreshList, productData } = this.props;

    const payload = {
      edit_product: 1,
      id_club: this.props.currentUser.id_club,
      id_product: productData.id_product,
      columns: {
        product_name: this.state.product_name,
        product_description: this.state.description,
        id_tax: 2,
        product_status: this.state.is_active ? 1 : 0,
        product_price: Number(this.state.product_price),
      },
      product_categories: this.state.categories.filter(elem => elem.checked).map(elem => elem.id),
      product_sizes: this.state.sizes.filter(elem => elem.checked).map(elem => elem.id),
    };

    if (this.state.product_image.length > 0) {
      const img_content = await handleFileInput(this.state.product_image[0]);
      if (img_content != null) {
        payload.image_name = this.state.product_image[0].name;
        payload.image_type = this.state.product_image[0].type;
        payload.image_file = img_content;
      }
    }

    axios
      .post("/product", payload)
      .then((res) => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.products,
            message: languagePack.success_update_product,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.products,
            message: languagePack.fail_update_product,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.product_edit}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="check" />}
            disabled={this.state.product_name == "" || this.state.product_price == ""}
            onClick={this.updateProduct}
          >
            {languagePack.apply}
          </TFBButton>
        )}
      >
        <div className="pop-up-section">
          <TFBInput
            label={languagePack.product_name}
            placeholder={languagePack.add_product_name}
            value={this.state.product_name}
            fullWidth={true}
            onChange={(e) => {
              this.setState({ product_name: e.target.value });
            }}
          />
        </div>
        <div className="pop-up-section">
          <CKEditor
            editor={ClassicEditor}
            style={{ minHeight: 400 }}
            config={{
              toolbar: ["bold", "italic", "link", "|", "undo", "redo"],
              placeholder: languagePack.add_product_description,
            }}
            data={this.state.description}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.setState({
                description: data,
              });
            }}
          />
        </div>
        <div className="pop-up-section">
          <TFBInput
            label={languagePack.product_price}
            placeholder={languagePack.add_product_price}
            value={this.state.product_price}
            type="number"
            fullWidth={true}
            onChange={(e) => {
              this.setState({ product_price: e.target.value });
            }}
          />
        </div>
        <div className="pop-up-section">
          <TFBCheckbox
            label={languagePack.product_is_active}
            checked={this.state.is_active}
            onChange={() => this.setState({ is_active: !this.state.is_active })}
          />
        </div>
        <div className="pop-up-section">
          <TFBFileInput
            label={languagePack.product_image}
            accept="image/jpg, image/png, image/jpeg"
            placeholder={languagePack.upload_file}
            onChange={(event) => this.setState({ product_image: event.target.files })}
            files={Array.from(this.state.product_image).map(elem => elem.name)}
          />
        </div>
        <div className="pop-up-section">
          <div className="pop-up-section-title">
            {languagePack.choose_categories}
          </div>
          <div className="df-g20">
            {this.state.categories.map((item, index) => (
              <TFBCheckbox
                key={"category-" + index}
                label={item.name}
                checked={item.checked}
                onChange={() => {
                  let prevStateCat = this.state.categories;
                  prevStateCat[index].checked = !prevStateCat[index].checked;
                  this.setState({ categories: prevStateCat });
                }}
              />
            ))}
            {this.state.categories.length == 0 &&
              <div className="no-items-placeholder">{languagePack.no_categories}</div>
            }
          </div>
        </div>
        <div className="pop-up-section">
          <div className="pop-up-section-title">
            {languagePack.choose_sizes}
          </div>
          <div className="df-g20">
            {this.state.sizes.map((item, index) => (
              <TFBCheckbox
                key={"size-" + index}
                label={item.name}
                checked={item.checked}
                onChange={() => {
                  let prevStateCat = this.state.sizes;
                  prevStateCat[index].checked = !prevStateCat[index].checked;
                  this.setState({ sizes: prevStateCat });
                }}
              />
            ))}
            {this.state.sizes.length == 0 &&
              <div className="no-items-placeholder">{languagePack.no_sizes}</div>
            }
          </div>
        </div>
      </TFBDialog>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditProduct);
