import React, { useContext, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import styles from "./AddManualCompetition.module.scss";
import _ from "lodash";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import { ageCategoryOptions } from "./AddManualCompetition";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import { showNotification } from "../../../../components/customHooks/useAxiosPost";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../../components/reusable/useStyles";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import { CompetitionsContext } from "./Competitions";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";

function EditCompetitionPopup({ languagePack }) {
  const history = useHistory();
  const competitionsContext = useContext(CompetitionsContext);
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      ...competitionsContext.wantToEditComp,
    }
  );
  const comp_types = [
    {
      key: 1,
      value: "championship",
      text: languagePack.competition_type.elements["championship"],
    },
    {
      key: 2,
      value: "knockout",
      text: languagePack.competition_type.elements["knockout"],
    },
    {
      key: 3,
      value: "group-stage",
      text: languagePack.competition_type.elements["group_stage"],
    },
  ];

  useEffect(() => {
    setState({ ...competitionsContext.wantToEditComp });
  }, [competitionsContext.wantToEditComp]);

  const handleChangeSelect = (selected, value) => {
    setState({ [value.name]: selected });
  };

  const handleEditCompetition = () => {
    const payload = {
      category: state.category?.label,
      competition_name: state.competition_name,
      competition_type: state.competition_type?.value,
      date_end: state.date_end,
      date_start: state.date_start,
      id_competition: state.id_competition,
      minutes_per_time: state.minutes_per_time,
      teams_number: state.teams_number,
    };
    const responseHandler = (response) => {
      if (Number(response.success) === 1) {
        competitionsContext.onCloseEditCompetition();
        competitionsContext.refetchCompetitions();
        showNotification(
          "success",
          languagePack.competitions,
          languagePack.competition_edit_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.competitions,
          languagePack.competition_edit_fail
        );
      }
    };
    competitionsContext.postEditCompetition(payload, responseHandler);
  };

  const { renderBtn: renderBtnRight } = useRenderButton(
    handleEditCompetition,
    "green",
    "edit",
    languagePack.admin_edit,
    {},
    true,
    false,
    competitionsContext.loadingEditCompetition
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    competitionsContext.onCloseEditCompetition,
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <TFBDialog
      closePopup={competitionsContext.onCloseEditCompetition}
      btnLeft={renderBtnLeft}
      btnRight={renderBtnRight}
      fullWidth
      maxWidth="sm"
      open
      title={languagePack.edit_competition}
    >
      <div className={styles.wrapper}>
        <TFBInput
          fullWidth
          type="text"
          label={languagePack.name}
          placeholder={languagePack.type_competition_name}
          value={state.competition_name}
          name="competition_name"
          onChange={(e) => setState({ competition_name: e.target.value })}
        />

        {Number(state.multiple_age_groups) === 1 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn} align="left">
                      {languagePack.subgroup_name}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.age_group}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.news_page_header_options}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(
                    competitionsContext.wantToEditComp?.sub_comps,
                    (comp, i) => (
                      <TableRow
                        sx={{
                          backgroundColor: i % 2 == 0 ? "#fafafa" : "white",
                        }}
                        key={i}
                      >
                        <TFBTableCell
                          style={{
                            backgroundColor: i % 2 == 0 ? "#fafafa" : "white",
                          }}
                          sx={sxStickyColumn}
                          align="left"
                        >
                          {comp.competition_name}
                        </TFBTableCell>
                        <TFBTableCell
                          style={{
                            backgroundColor: i % 2 == 0 ? "#fafafa" : "white",
                          }}
                          align="center"
                        >
                          {comp.category?.label}
                        </TFBTableCell>
                        <TFBTableCell
                          align="center"
                          style={{
                            backgroundColor: i % 2 == 0 ? "#fafafa" : "white",
                            display: "flex",
                            gap: "6px",
                            justifyContent: "flex-end",
                          }}
                        >
                          <TFBIconButton
                            name="cup"
                            color="darkGray"
                            altTitle={
                              languagePack.altTitle.view_competition_prizes
                            }
                            onClick={() =>
                              history.push(
                                `/club/competitions/prizes/${comp?.id_competition}`
                              )
                            }
                          />
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            altTitle={languagePack.altTitle.view_competition}
                            onClick={() =>
                              history.push(
                                `/club/competitions/${comp?.id_competition}`
                              )
                            }
                          />
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            altTitle={languagePack.altTitle.edit_competition}
                            onClick={() => {
                              competitionsContext.onEditComp(comp);
                            }}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            altTitle={languagePack.altTitle.delete}
                            onClick={() =>
                              competitionsContext.onDeleteComp(comp)
                            }
                          />
                        </TFBTableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="add" />}
              onClick={() => competitionsContext.onAddGroupAge(state)}
            >
              {languagePack.add_age_group_competition}
            </TFBButton>
          </>
        ) : (
          <>
            <TFBInput
              type="number"
              fullWidth
              label={languagePack["teams_number"]}
              placeholder={languagePack.type_teams_number}
              value={state.teams_number}
              onChange={(e) => setState({ teams_number: e.target.value })}
            />
            {state.competition_type?.value === "group-stage" && (
              <TFBInput
                type="number"
                fullWidth
                label={languagePack["number_of_groups"]}
                placeholder={languagePack.type_number_of_groups}
                value={state.groups_number}
                onChange={(e) =>
                  setState({
                    groups_number: e.target.value,
                  })
                }
              />
            )}
            {
              <TFBInput
                type="number"
                fullWidth
                label={languagePack.minutes_per_time}
                placeholder={languagePack.type_minutes_per_time}
                value={state.minutes_per_time}
                onChange={(e) =>
                  setState({
                    minutes_per_time: e.target.value,
                  })
                }
              />
            }
            <TFBDatePicker
              fullWidth
              label={languagePack.starting_date}
              value={state.date_start}
              onChange={(value) => setState({ date_start: value })}
              ampm={false}
              format="dd MM yyyy"
              name="newEventStart"
              className="add-event-date-input"
            />
            <TFBDatePicker
              fullWidth
              label={languagePack.ending_date}
              value={state.date_end}
              onChange={(value) => setState({ date_end: value })}
              ampm={false}
              format="dd MM yyyy"
              name="newEventStart"
            />
            <TFBSelect
              label={languagePack.age_category}
              placeholder={languagePack.select_age_category}
              name="category"
              onChange={handleChangeSelect}
              value={state.category}
              options={ageCategoryOptions}
              isClearable
            />{" "}
          </>
        )}
      </div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditCompetitionPopup);
