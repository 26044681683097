import ListActionTypes from './list.types';

const initialState = {
    myList: []
};

const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case ListActionTypes.ADD_PLAYER:
            return {
                myList: [...state.myList, ...action.payload]
            }
        case ListActionTypes.REMOVE_PLAYER:
            return {
                myList: state.myList.filter(player => player.id_player_tm !== action.payload.id_player_tm)
            }
        default:
            return state;
    }
}

export default listReducer;