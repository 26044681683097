import React, { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../../utils/api";
import "./UsersManagement.scss";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../components/design-system/Input/TFBInput";
import TFBPhoneInput from "../../components/design-system/PhoneInput/TFBPhoneInput";
import TFBCheckbox from "../../components/design-system/Checkbox/TFBCheckbox";
import {
  useAxiosPost,
  showNotification,
} from "../../components/customHooks/useAxiosPost";
import useTFBInput from "../../components/customHooks/useTFBInput";
import _, { size, upperCase } from "lodash";
import useAxiosGet from "../../components/customHooks/useAxiosGet";
import CircularLoader from "../../components/reusable/Loader";

import TFBPasswordInput from "../../components/design-system/PasswordInput/TFBPasswordInput";
import useError from "../../components/customHooks/useError";
import { getCountryCodeByName, isTFB } from "../../utils/utilFunctions";
import { countries } from "../../utils/countries";

const CenterCircularLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularLoader />
    </div>
  );
};

function AddUser({ ...props }) {
  const fullName = useTFBInput("");
  const email = useTFBInput("");
  const password = useTFBInput("");
  const confirmPassword = useTFBInput("");

  const { ErrorComponent: NotSamePassword } = useError(
    props.languagePack.password_confirm_coincide_err
  );

  const { ErrorComponent: PasswordLengthErr } = useError(
    props.languagePack.password_min_characters_err
  );

  const [accessToAllModules, setAccessToAllModules] = useState(false);
  const [allTeamsChecked, setAllTeamsChecked] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [wrongPhoneNumber, setWrongPhoneNumber] = useState(false);
  const [wrongEmail, setWrongEmail] = useState(false);
  const [extendTeams, setExtendTeams] = useState(false);
  const [teamsAccess, setTeamsAccess] = useState(null);
  const [platformModules, setPlatformModules] = useState(null);
  const [defaultCountry, setDefaultCountry] = useState("RO");

  const { currentUser } = props;

  useEffect(() => {
    if (currentUser?.club?.club_country) {
      const country_code = getCountryCodeByName(currentUser.club.club_country, countries);
      if (country_code) {
        setDefaultCountry(upperCase(country_code));
      }
    }
  }, [])

  const isFirstRender = useRef(true);

  // GET PLATFORM MODULES CUSTOM HOOK ================================================
  // ===========================================================================
  const { data: getAddUserData, loading: modulesLoading } = useAxiosGet(
    `user/get_access_module?id_club=${props.id_club}&only_tfb=${isTFB() ? 1 : 0}`
  );

  // POST EDIT USER CUSTOM HOOK ================================================
  // ===========================================================================
  const { data: responseAddUser, postData: postAddUser, loading: postLoading } = useAxiosPost(
    `user/add_user`
  );

  // USE EFFECTS =============================================================
  // =======================================================================

  useEffect(() => {
    setPlatformModules(getAddUserData?.module);
    setTeamsAccess(getAddUserData?.team);
  }, [getAddUserData]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (responseAddUser.success == 1) {
      showNotification(
        "success",
        props.languagePack.users_management,
        props.languagePack.edit_user_success
      );
      props.refreshUsers();
      props.closePopup();
    }

    if (responseAddUser.error_code) {
      showNotification(
        "danger",
        props.languagePack.users_management,
        props.languagePack[responseAddUser.error_code]
      );
    }
  }, [responseAddUser]);

  useEffect(() => {
    if (teamsAccess) {
      let allTeams = [...teamsAccess];
      let isAllTeamsChecked = allTeams.every((team) => team.has_access);
      setAllTeamsChecked(isAllTeamsChecked ? true : false);
    }
  }, [teamsAccess]);

  // HANDLERS =============================================================
  // =======================================================================

  const handleCheckTeam = (teamIndex) => {
    let allTeams = [...teamsAccess];
    allTeams[teamIndex].has_access = !allTeams[teamIndex].has_access;
    setTeamsAccess(allTeams);
  };

  const handleCheckAllTeams = (checkAll) => {
    let allTeams = [...teamsAccess];

    allTeams.forEach((team) =>
      checkAll ? (team.has_access = true) : (team.has_access = false)
    );

    setTeamsAccess(allTeams);
  };

  const handleCheckSubmodule = (index, submoduleIndex) => {
    let allModules = [...platformModules];
    allModules[index].sub_module[submoduleIndex].has_access = !allModules[index]
      .sub_module[submoduleIndex].has_access;
    setPlatformModules(allModules);

    allModules[index].has_access = _.every(
      allModules[index].sub_module,
      (submodule) => submodule.has_access == true
    );

    isAllSubmodulesChecked(allModules);
  };

  const handleCheckAction = (index, submoduleIndex, actionIndex) => {
    let allModules = [...platformModules];
    let action =
      allModules[index].sub_module[submoduleIndex].action[actionIndex];
    action.has_access = !action.has_access;

    allModules[index].sub_module[submoduleIndex].action[actionIndex] = action;

    setPlatformModules(allModules);
    isAllSubmodulesChecked(allModules);
  };

  const handleExtendArrow = (index) => {
    let allModules = [...platformModules];
    allModules[index].extend = !allModules[index].extend;
    setPlatformModules(allModules);
  };

  const handleCheckModule = (check, index) => {
    let allModules = [...platformModules];

    if (_.size(allModules[index].sub_module) > 0) {
      allModules[index].sub_module = _.map(
        allModules[index].sub_module,
        (submodule) => {
          const updatedSubmodule = {
            ...submodule,
            has_access: check,
          };

          if (_.size(updatedSubmodule.action) > 0) {
            _.each(updatedSubmodule.action, (action) => {
              action.has_access = check;
            });
          }

          return updatedSubmodule;
        }
      );

      allModules[index].has_access = _.every(
        allModules[index].sub_module,
        (submodule) => submodule.has_access == true
      );
    } else {
      allModules[index].has_access = check;
    }

    isAllSubmodulesChecked(allModules);
    setPlatformModules(allModules);
  };

  const handleProvideAllModules = () => {
    let allModules = [...platformModules];
    allModules.forEach((module) => {
      module.sub_module.forEach((submodule) => {
        if (_.size(submodule.action) > 0)
          _.forEach(submodule.action, (action) => {
            action.has_access = !accessToAllModules ? true : false;
          });
        return (submodule.has_access = !accessToAllModules ? true : false);
      });
    });

    allModules[0].has_access = !accessToAllModules ? true : false;

    setPlatformModules(allModules);

    setAccessToAllModules(!accessToAllModules);
  };

  const isModuleCheck = (index) => {
    let allModules = [...platformModules];
    let currentModule = allModules[index];
    const submodulesArray = currentModule.sub_module;

    if (_.size(submodulesArray) > 0) {
      return submodulesArray.every(
        (submodule) =>
          submodule.has_access &&
          (_.size(submodule.action) > 0
            ? _.every(submodule.action, (action) => action.has_access)
            : true)
      );
    } else {
      return currentModule.has_access;
    }
  };

  const isAllSubmodulesChecked = (allModules) => {
    const allSubmodulesChecked = _.every(
      _.flatMap(allModules, "sub_module"),
      (submodule) => {
        return (
          submodule.has_access === true &&
          (_.size(submodule.action) > 0
            ? _.every(submodule.action, (action) => action.has_access)
            : true)
        );
      }
    );

    let dashboardChecked = allModules[0].has_access;

    setAccessToAllModules(allSubmodulesChecked && dashboardChecked);
  };

  const checkPhoneNumber = () => {
    const myPhoneNumber = phoneNumber;

    if (myPhoneNumber.indexOf("7") == 3 && myPhoneNumber.length == 12)
      return true;
    else return false;
  };

  // RENDER DIALOG BUTTONS ================================================
  // =======================================================================

  const renderBtn = (position, onClick, loading = false) => {
    // let noTeamSelected = !_.some(
    //   teamsAccess,
    //   (team) => team.has_access == true
    // );

    const noModulesSelected = !_.some(platformModules, (permission) => {
      return _.some(
        permission.sub_module,
        (subModule) => subModule.has_access === true
      );
    });

    const isFirstPermissionDenied = !_.get(
      platformModules,
      "[0].has_access",
      true
    );

    let isDisabled =
      fullName.value == "" ||
      // noTeamSelected ||
      (noModulesSelected && isFirstPermissionDenied) ||
      password.value != confirmPassword.value ||
      password.value == "" ||
      confirmPassword.value == "" ||
      password.value.length < 8;
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "check"} />
        )}
        disabled={position == "right" && isDisabled}
      >
        {position == "left" ? languagePack.admin_cancel : languagePack.apply}
      </TFBButton>
    );
  };

  // POST EDIT USER ================================================
  // ===============================================================

  const editUser = () => {
    if (
      phoneNumber &&
      (!isValidPhoneNumber(phoneNumber)) &&
      !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email.value)
    ) {
      setWrongPhoneNumber(true);
      setWrongEmail(true);
    } else if (
      phoneNumber &&
      (!isValidPhoneNumber(phoneNumber))
    ) {
      setWrongPhoneNumber(true);
      setWrongEmail(false);
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email.value)) {
      setWrongEmail(true);
      setWrongPhoneNumber(false);
    } else if (phoneNumber == null || phoneNumber == undefined) {
      setWrongPhoneNumber(true);
    } else {
      const payload = {
        id_user: props.id_user,
        token: props.token,
        id_club: props.id_club,
        full_name: fullName.value,
        email: email.value,
        phone_number: phoneNumber,
        password: password.value,
        is_tfb: isTFB(),
      };

      if (teamsAccess.every((team) => team.has_access == true)) {
        payload.team_access = "full";
      } else {
        let checkedTeams = teamsAccess.filter(
          (team) => team.has_access == true
        );

        if (size(checkedTeams) == 0) {
          payload.team_access = "no_access";
        } else {
          payload.team_access = "partial";
        }
        
        payload.teams = checkedTeams;
      }

      let moduleAccess = _.chain(platformModules)
        .cloneDeep()
        .filter((item) => {
          item.sub_module = _.chain(item.sub_module)
            .filter((submodule) => {
              const filteredActions = _.filter(submodule.action, {
                has_access: true,
              });
              submodule.action = filteredActions;
              return submodule.has_access || filteredActions.length > 0;
            })
            .value();

          return item.sub_module.length > 0 || item.has_access === true;
        })
        .flatMap((item) => (item.sub_module.length > 0 ? item : []))
        .value();

      if (platformModules[0].has_access)
        moduleAccess = [platformModules[0], ...moduleAccess];

      payload.module = moduleAccess;

      postAddUser(payload);
    }
  };

  const { languagePack, addUserPopup, closePopup, refreshUsers } = props;
  return (
    <TFBDialog
      open={addUserPopup}
      fullWidth={true}
      stickyBtns
      maxWidth="sm"
      closePopup={() => {
        closePopup();
      }}
      title={languagePack.add_user}
      btnRight={() => renderBtn("right", () => editUser(), postLoading)}
      btnLeft={() =>
        renderBtn("left", () => {
          closePopup();
        })
      }
    >
      <div className="add-user-container">
        <TFBInput
          label={languagePack.campaign_name}
          value={fullName.value}
          onChange={fullName.onChange}
          fullWidth
          style={{ marginBottom: "25px", backgroundColor: "#fafafa" }}
        />

        <TFBInput
          label={languagePack.Email}
          value={email.value}
          onChange={email.onChange}
          fullWidth
          style={{ marginBottom: "25px", backgroundColor: "#fafafa" }}
        />

        <TFBPhoneInput
          defaultCountry={defaultCountry}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e)}
          label={languagePack.admin_contact_phone}
        />

        {wrongPhoneNumber && (
          <div class="ui red message">{languagePack.wrong_phone_number}</div>
        )}

        {wrongEmail && (
          <div class="ui red message">{languagePack.wrong_email}</div>
        )}

        <TFBPasswordInput
          label={`${languagePack.Password} (${languagePack.min_characters})`}
          wrapperStyle={{ marginTop: "25px" }}
          fullWidth
          value={password.value}
          onChange={password.onChange}
        />

        <TFBPasswordInput
          label={`${languagePack.Confirm_Password}`}
          wrapperStyle={{ marginTop: "25px" }}
          fullWidth
          value={confirmPassword.value}
          onChange={confirmPassword.onChange}
        />

        {password.value != confirmPassword.value &&
          password.value != "" &&
          confirmPassword.value != "" && <NotSamePassword />}

        {password.value != "" && password.value.length < 8 && (
          <PasswordLengthErr />
        )}

        {/* // TEAM ACCESS =============================================================
  // ======================================================================= */}
        <div className="team-access-wrapper">
          <div className="team-access-title">{languagePack.team_access}</div>
          <div className="teams-access">
            <div className="text-checkbox-container">
              <TFBCheckbox
                label={languagePack.all_teams}
                labelColor={"rgba(0, 0, 0, 0.87)"}
                checked={_.every(
                  teamsAccess,
                  (team) => team.has_access == true
                )}
                onChange={() => {
                  handleCheckAllTeams(!allTeamsChecked);
                }}
                mode="light"
              />
            </div>

            <div
              className="extend-icon-wrapper cursor-pointer"
              onClick={() => setExtendTeams(!extendTeams)}
            >
              {" "}
              <TFBIcon
                name={extendTeams ? "angle-up" : "angle-down"}
                color="grey"
                className="extend-icon"
              />
            </div>
          </div>

          {extendTeams && (
            <div className="teams-enum animate-drop">
              {teamsAccess.map((team, index) => (
                <div className="checkbox-text" key={"user-team-" + index}>
                  <TFBCheckbox
                    label={team.team_name}
                    checked={team.has_access}
                    onChange={() => handleCheckTeam(index)}
                    mode="light"
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* // MODULES & SUBMODULES ACCESS =============================================================
  // ======================================================================= */}
        <div className="module-access-container">
          <div className="module-access-title">
            {languagePack.modules_access}
          </div>

          <TFBCheckbox
            label={languagePack.provide_access_all_modules}
            labelColor={"rgba(0, 0, 0, 0.87)"}
            checked={accessToAllModules}
            onChange={handleProvideAllModules}
            style={{ marginBottom: "25px" }}
          />

          <div className="platform-modules-wrapper">
            {modulesLoading && <CenterCircularLoader />}
            {!modulesLoading &&
              _.map(platformModules, (module, index) => (
                <div className="module-access-wrapper" key={"module-" + index}>
                  <div className="modules-access">
                    <div className="text-checkbox-container">
                      <div className="checkbox">
                        <TFBCheckbox
                          checked={isModuleCheck(index)}
                          onChange={(e) =>
                            handleCheckModule(e.target.checked, index)
                          }
                          mode="light"
                        />
                      </div>
                      <div className="module-icon">
                        <TFBIcon name={module.tfb_icon} color="black" />
                      </div>
                      <div className="module-text">
                        {
                          languagePack.modules[module.permission_slug][
                          module.permission_slug
                          ]
                        }
                      </div>
                    </div>

                    <div
                      className="extend-icon-wrapper"
                      onClick={() => handleExtendArrow(index)}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {_.size(platformModules[index].sub_module) > 0 && (
                        <TFBIcon
                          name={
                            platformModules[index].extend
                              ? "angle-up"
                              : "angle-down"
                          }
                          color="grey"
                          className="extend-icon"
                        />
                      )}
                    </div>
                  </div>

                  {_.size(platformModules[index].sub_module) > 0 &&
                    platformModules[index].extend && (
                      <div className="submodule-enum animate-drop">
                        {module.sub_module.map((submodule, submoduleIndex) => (
                          <div
                            key={"submodule-key-2" + submoduleIndex}
                            style={{ display: "flex", gap: "15px" }}
                          >
                            <div
                              className="checkbox-text"
                              key={"submodule-key-" + submoduleIndex}
                            >
                              <TFBCheckbox
                                checked={submodule.has_access}
                                onChange={() =>
                                  handleCheckSubmodule(index, submoduleIndex)
                                }
                                label={
                                  languagePack.modules[module.permission_slug][
                                  submodule.permission_slug
                                  ]
                                }
                                mode="light"
                              />
                            </div>

                            {submodule.has_access &&
                              _.map(submodule.action, (action, actionIndex) => (
                                <div
                                  className="checkbox-text animate-slide"
                                  key={"submodule-acction-key-" + actionIndex}
                                >
                                  <TFBCheckbox
                                    checked={action.has_access}
                                    onChange={() =>
                                      handleCheckAction(
                                        index,
                                        submoduleIndex,
                                        actionIndex
                                      )
                                    }
                                    label={
                                      languagePack.modules[
                                      module.permission_slug
                                      ][action.permission_slug]
                                    }
                                    mode="light"
                                  />
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </TFBDialog>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddUser);
