import React from "react";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import styles from "./VariablesSection.module.scss";
import { showNotification } from "../../../components/customHooks/useAxiosPost";

const variables = [
  { variable: "${surname}", meaning: "Nume de familie" },
  { variable: "${name}", meaning: "Prenume" },
  { variable: "${city}", meaning: "Oraș" },
  { variable: "${street}", meaning: "Stradă" },
  { variable: "${nr_street}", meaning: "Număr stradă" },
  { variable: "${nr_apartment}", meaning: "Număr apartament" },
  { variable: "${county}", meaning: "Județ" },
  { variable: "${cnp_parent}", meaning: "CNP părinte" },
  { variable: "${player_name}", meaning: "Numele complet al jucătorului" },
];

const copyToClipboard = (text, languagePack) => {
  navigator.clipboard.writeText(text).then(
    () => {
      showNotification(
        "success",
        languagePack.mobile_app,
        languagePack.copied_to_clipboard
      );
    },
    (err) => {
      showNotification(
        "danger",
        languagePack.mobile_app,
        languagePack.fail_copy_to_clipboard
      );
      console.error("Could not copy text: ", err);
    }
  );
};

const VariablesSection = ({ languagePack }) => {
  return (
    <div className={styles.variablesContainer}>
      <div className={styles.title}>{languagePack.available_variables}</div>
      <div className={styles.variablesHeader}>
        <div className={styles.actionColumn}></div>
        <div className={styles.meaningColumn}>{languagePack.meaning}</div>
        <div className={styles.variableColumn}>{languagePack.variable}</div>
      </div>
      {variables.map((item, index) => (
        <div key={index} className={styles.variableRow}>
          <div className={styles.actionColumn}>
            <TFBIcon
              name="copy"
              color="black"
              onClick={() => copyToClipboard(item.variable, languagePack)}
              className={styles.copyIcon}
            />
          </div>
          <div className={styles.meaningColumn}>{item.meaning}</div>
          <div className={styles.variableColumn}>{item.variable}</div>
        </div>
      ))}
    </div>
  );
};

export default VariablesSection;
