import React from 'react';
import FollowersCount from './FollowersCount';
import FollowersDistribution from './FollowersDistribution';
import NewFollowersChart from './NewFollowersChart';

export default function InstagramDashboard(props) {
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <FollowersDistribution id_network={props.id_network} />
                </div>
                <div className="col-lg-6">
                    <FollowersCount id_network={props.id_network} />
                </div>
            </div>
            <div className="row" style={{ marginTop: 25 }}>
                <div className="col-lg-6">
                    <NewFollowersChart id_network={props.id_network} />
                </div>
                <div className="col-lg-6">

                </div>
            </div>
        </>
    )
}