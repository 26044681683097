import React, { Component } from "react";
import "./../AddTeam/AddTeam.scss";
import { Icon, Button } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import Select from "react-select"

class AddSponsor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sponsorName: "",
            sponsorImage: "",
            sponsorLink: "",
            id_sponsor: "",
            sponsorOrder: "",
            club_sponsors: [],
            logo: ""
        };
    }

    addSponsor = () => {
        // const payload = {
        //     id_competition: this.props.id_competition,
        //     sponsor_name: this.state.sponsorName,
        //     sponsor_website: this.state.sponsorLink,
        //     logo: this.state.logo,
        //     display_order: this.state.sponsorOrder,
        // };
        const payload = new FormData();
        payload.append("id_competition", this.props.id_competition);
        payload.append("sponsor_name", this.state.sponsorName);
        payload.append("sponsor_website", this.state.sponsorLink);
        payload.append("logo", this.state.logo);
        payload.append("display_order", this.state.sponsorOrder);

        axios
            .post("site/add_competition_sponsor", payload)
            .then((res) => {
                if (res.data.success == 1) {
                    this.props.getSponsors();
                    this.props.closePopup();
                    store.addNotification({
                        title: this.props.languagePack["competitions_actions"],
                        // message: res.data.error,
                        message: "Partenerul a fost adaugat cu succes!",
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: this.props.languagePack["competitions_actions"],
                        message: res.data.error,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    componentDidMount = () => {
    };

    handleChangeImage = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            this.setState({
                sponsorImage: reader.result,
                logo: file
            });
        };
    };

    render() {
        const { languagePack } = this.props;
        return (
            <div className="add-team-manual-container" style={{ paddingBottom: "12px" }}>
                <div className="add-team-manual-title">Add sponsor</div>
                {/* <div className="add-team-manual-options">
                    <div
                        className="add-team-option"
                        onClick={() => this.setState({ sponsorOption: "new" })}
                        style={{
                            backgroundColor: this.state.sponsorOption === "new" ? "#fafafa" : "",
                        }}
                    >
                        <div>New sponsor</div>
                    </div>
                    <div
                        className="add-team-option"
                        onClick={() => this.setState({ sponsorOption: "sponsor" })}
                        style={{
                            backgroundColor:
                                this.state.sponsorOption === "sponsor" ? "#fafafa" : "",
                        }}
                    >
                        <div>{"Club's sponsor"}</div>
                    </div>
                </div> */}
                <div className="add-team-container-input">
                    <input
                        value={this.state.sponsorName}
                        onChange={(e) => this.setState({ sponsorName: e.target.value })}
                        placeholder={"Sponsor name"}
                        className="add-team-input"
                    />
                    <input
                        value={this.state.sponsorLink}
                        onChange={(e) => this.setState({ sponsorLink: e.target.value })}
                        placeholder={"Sponsor web-site link"}
                        className="add-team-input"
                    />
                    <input
                        value={this.state.sponsorOrder}
                        onChange={(e) => this.setState({ sponsorOrder: e.target.value })}
                        placeholder={"Sponsor order"}
                        className="add-team-input"
                    />
                    <div className="add-team-manual-image">
                        <div className="image-input-container">
                            <span>{"Sponsor logo"}</span>
                            <input
                                type="file"
                                className="add-news-image-input"
                                id="customFile"
                                onChange={this.handleChangeImage}
                            // accept="image/*"
                            />
                            <label
                                className="add-file-form-file-label"
                                htmlFor="customFile"
                            >
                                <div className="image-view">
                                    {this.state.sponsorImage !== "" ? <img src={this.state.sponsorImage} /> : <span><Icon name="arrow up" className="cusor-pointer" size="small" /></span>}
                                </div>
                            </label>
                        </div>
                    </div>
                    {/* {this.state.sponsorOption === "new" ? (
                        <>
                            <input
                                value={this.state.newSponsorName}
                                onChange={(e) => this.setState({ newSponsorName: e.target.value })}
                                placeholder={"Sponsor name"}
                                className="add-team-input"
                            />
                            <input
                                value={this.state.sponsorLink}
                                onChange={(e) => this.setState({ sponsorLink: e.target.value })}
                                placeholder={"Sponsor web-site link"}
                                className="add-team-input"
                            />
                            <input
                                value={this.state.sponsorOrder}
                                onChange={(e) => this.setState({ sponsorOrder: e.target.value })}
                                placeholder={"Sponsor order"}
                                className="add-team-input"
                            />
                            <div className="add-team-manual-image">
                                <div className="image-input-container">
                                    <span>{"Sponsor logo"}</span>
                                    <input
                                        type="file"
                                        className="add-news-image-input"
                                        id="customFile"
                                        onChange={this.handleChangeImage}
                                        accept="image/*"
                                    />
                                    <label
                                        className="add-file-form-file-label"
                                        htmlFor="customFile"
                                    >
                                        <div className="image-view">
                                            {this.state.sponsorImage !== "" ? <img src={this.state.sponsorImage} /> : <span><Icon name="arrow up" className="cusor-pointer" size="small" /></span>}
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </>
                    )
                        : (
                            <div className="club-team-dropdown">
                                <select
                                    id="team-select"
                                    value={this.state.sponsorName}
                                    onChange={(e) => {
                                        this.setState({
                                            id_sponsor: e.target.selectedOptions[0].getAttribute("id-sponsor"),
                                            sponsorName: e.target.value
                                        });
                                    }}
                                >
                                    <option value="" disabled selected>
                                        {"Choose sponsor"}
                                    </option>
                                    {this.state.club_sponsors.map((item) => {
                                        return (
                                            <option value={item.sponsor_name} key={item.id_sponsor} id-sponsor={item.id_sponsor}>
                                                {item.sponsor_name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        )} */}

                    <div className="add-team-manual-buttons">
                        <Button
                            className="add-team-button cancel-button"
                            onClick={() => this.props.closePopup()}
                        >
                            {languagePack["admin_cancel"]}
                            <Icon name="cancel" className="white-icon" />
                        </Button>
                        <Button
                            className="add-team-button"
                            disabled={this.state.sponsorName == "" || this.state.sponsorImage == "" || this.state.sponsorOrder == ""}
                            onClick={this.addSponsor}
                        >
                            {languagePack["admin_add"]}
                            <Icon name="add" className="white-icon" />
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(AddSponsor);
