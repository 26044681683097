import React, {
  useMemo,
  useLayoutEffect,
  useEffect,
  createContext,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import objectPath from "object-path";
import { useLocation, useHistory } from "react-router-dom";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { actions } from "../../../../redux/authRedux";

import TFBIcon from "../../../../app/components/design-system/Icon/TFBIcon";
import TFBCircularLoader from "../../../../app/components/design-system/CircularLoader/TFBCircularLoader";
import TFBPlayerWithAvatar from "../../../../app/components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";

import News from "./components/News/News";
import EditClub from "./EditClub/EditClub";
import QuickSetup from "../QuickSetup/Popup/QuickSetup";

export const NewFunctionalityContext = createContext();

const SubHeader = () => {
  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();
  const { user, languagePack, clubQuickSetup } = useSelector(
    ({ auth: { user, clubQuickSetup }, layoutService: { languagePack } }) => ({
      user,
      clubQuickSetup,
      languagePack,
    })
  );

  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const [isOpenNews, setIsOpenNews] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState("0");
  const [refreshNotifications, setRefreshNotifications] = useState(0);

  const [openEditClub, setOpenEditClub] = useState(false);

  const [tempClubLogo, setTempClubLogo] = useState(null);
  const [tempClubName, setTempClubName] = useState(null);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => { }, [subheader]);

  useEffect(() => {
    getNotifications();
  }, [refreshNotifications]);

  const getNotifications = () => {
    axios
      .get(`notification/get_notifications_header?id_user=${user.id_user}`)
      .then((res) => {
        setNotifications(res.data.notifications);
        setTotalNotifications(res.data.total);
      })
      .catch((err) => console.log(err));
  };

  const seenNotification = (id) => {
    const payload = {
      id_notification: id,
      id_user: user.id_user,
    };
    axios
      .post(`notification/seen_notification`, payload)
      .then((res) => {
        // setRefreshNotifications(refreshNotifications + 1);
      })
      .catch((err) => console.log(err));
  };

  const handleClickOutsideBox = (event) => {
    const box = document.getElementById("notification-content-container");
    const icon = document.getElementById("notification-container");
    if (!box.contains(event.target) && !icon.contains(event.target)) {
      if (isOpenNotification) {
        setIsOpenNotification(false);
      }
    }
  };

  const changeClubLogo = (imageFile) => {
    setTempClubLogo(imageFile);
  };

  const changeClubName = (name) => {
    setTempClubName(name);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutsideBox);
    return () => {
      window.removeEventListener("click", handleClickOutsideBox);
    };
  }, [isOpenNotification]);

  const closeNewsPopup = () => {
    setIsOpenNews(false);
  };

  return (
    <div
      id="kt_subheader"
      className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
    // className={`subheader ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none open-menu-mobile"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="d-flex align-items-baseline mr-5">
            {/* begin::Title */}
            <h2
              className="subheader-title text-dark font-weight-bold my-2 mr-3"
              style={{ display: "flex" }}
            >
              <img
                className="subheader-team-logo"
                src={tempClubLogo ? tempClubLogo : user?.club?.image_url}
                alt="team-logo"
              />
              <div className="subheader-team-name">
                {tempClubName ? tempClubName : user?.club?.club_name}
              </div>

              <TFBIcon
                style={{ marginLeft: "5px" }}
                className="cursor-pointer"
                name="edit"
                color="black"
                onClick={() => setOpenEditClub(true)}
              />
            </h2>
            {/* <button 
              className="open-menu-mobile" 
              onClick={openMenu}
            >
              <img src={closeMenu} alt="Open Menu" className="open-menu-icon"/>
              { isMenuOpen ? <Aside /> : null }
            </button> */}
            {/* end::Title */}
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          {/* <button
            type="button"
            className={`btn btn-fixed-height ${layoutProps.subheaderFixed ? "btn-default" : "btn-white"
              } btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2`}
          >
            <span className="svg-icon svg-icon-success svg-icon-lg">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            {` `}{languagePack.add_new_member}
          </button> */}

          {/* <QuickActions /> */}

          {/* Button */}
          {/* <button
          className="btn btn-success btn-icon font-weight-bold"
          data-toggle="modal"
          data-target="#kt_chat_modal"
        >
          <span className="svg-icon svg-icon-lg">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Group-chat.svg"
              )}
            />
          </span>
        </button> */}
        </div>
      </div>
      <div className="sub-header-notification">
        <div className="notification-container" id="notification-container">
          <div
            className="notification-amount-container"
            style={{ display: totalNotifications > 0 ? "flex" : "none" }}
          >
            <div className="amount-text">
              {totalNotifications > 0
                ? totalNotifications > 99
                  ? "99+"
                  : totalNotifications
                : ""}
            </div>
          </div>

          <div className="header-buttons-wrapper">

            {/* ======== QUICK SETUP =========== */}
            {clubQuickSetup ? (
              <>
                <div
                  className="notification-icon-container"
                  style={{ backgroundColor: "var(--primaryColor)" }}
                  onClick={() => {
                    dispatch(
                      actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: true })
                    );
                  }}
                >
                  <TFBIcon name="setting" spinningIcon={true} />
                </div>
              </>
            ) : (
              <TFBCircularLoader size={50} />
            )}

            <QuickSetup />
            {/* =============================== */}

            <div
              className="new-functionalities"
              onClick={() => setIsOpenNews(true)}
            >
              <TFBIcon name="news" color="white" />
            </div>

            <div
              className="notification-icon-container"
              onClick={() => {
                setIsOpenNotification(!isOpenNotification);
                // setIsOpenNotification(true);
                getNotifications();
              }}
            >
              <TFBIcon
                name="bell"
                color="white"
                className={totalNotifications > 0 ? "animate-ringing-bell" : ""}
              />
            </div>
          </div>

          <div
            className="notification-content-container"
            id="notification-content-container"
            style={{ display: isOpenNotification ? "block" : "none" }}
          >
            {notifications.map((elem, index) => {
              return (
                <div key={"notification-" + index}>
                  <div
                    className="notification-elem-container"
                    onClick={() => {
                      history.push(elem.redirect_url);
                      seenNotification(elem.id_notification);
                    }}
                  >
                    <div className="notification-first-part">
                      <div className="notification-icon">
                        {/* <Avatar
                          className="player-avatar"
                          src={elem.notification_avatar}
                        /> */}
                        <TFBPlayerWithAvatar avatarUrl={elem.notification_avatar} />
                      </div>
                      <div className="notification-text">
                        <div className="notification-title">
                          {elem.notification_title}
                        </div>
                        <div className="notification-description">
                          {elem.notification_subtitle}
                        </div>
                      </div>
                    </div>
                    <div className="notification-date">{elem.date_text}</div>
                  </div>
                  {index < notifications.length - 1 && (
                    <div className="separator-line"></div>
                  )}
                </div>
              );
            })}
            {notifications.length == 0 && (
              <div className="no-notifications-message">
                {"Nu sunt notificari noi"}
              </div>
            )}
            <div className="view-all-notifications">
              <div
                className="text"
                onClick={() => {
                  history.push("/notifications");
                }}
              >
                {totalNotifications
                  ? totalNotifications > 3
                    ? "Vezi toate notificarile (+" +
                    (totalNotifications - 3) +
                    " notificari noi)"
                    : "Vezi toate notificarile"
                  : "Vezi toate notificarile"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {openEditClub && (
        <EditClub
          openPopup={openEditClub}
          closePopup={() => setOpenEditClub(false)}
          changeClubLogo={changeClubLogo}
          changeClubName={changeClubName}
          tempClubName={tempClubName}
          user={user}
        />
      )}

      <NewFunctionalityContext.Provider
        value={{ closeNewsPopup, userId: user.id_user }}
      >
        {isOpenNews && (
          <News
            isOpenNews={isOpenNews}
            closePopup={() => setIsOpenNews(false)}
            languagePack={languagePack}
            currentLang={user.lang}
          />
        )}
      </NewFunctionalityContext.Provider>
    </div>
  );
};
export default SubHeader;
