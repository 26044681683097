import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import { currencies } from "../../../utils/constants";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBCurrencyInput from "../../../components/design-system/CurrencyInput/TFBCurrencyInput";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";

const AddStaffContract = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            staff_member: null,
            wage_amount: "",
            currency: { value: "EUR", label: "EUR" },
            currency_list: Object.keys(currencies).map(e => ({ value: e, label: e })),
            start_date: null,
            end_date: null,
            file: [],
        }
    );

    const {
        data: staffMember,
        loading: staffMemberLoading,
        refetch: refetchStaffMember,
    } = useAxiosGet(
        `finances/get_staff_member_list?id_club=${currentUser.id_club}`
    );

    const { data: responseAddContract, postData: postAddContract } = useAxiosPost(
        "finances/add_staff_member_contract"
    );

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const hasDateError = () => {
        if (state.start_date != null && state.end_date != null) {
            if (moment(state.start_date) > moment(state.end_date)) {
                return true;
            }
        }
        return false;
    }

    const { renderBtn: BtnRight } = useRenderButton(
        () => addContract(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        state.staff_member == null || String(state.wage_amount).trim().length == 0 || state.currency == null || state.start_date == null || state.end_date == null || hasDateError(),
    );

    useEffect(() => {
        if (!responseAddContract) return;

        if (responseAddContract.success == 1) {
            showNotification("success", languagePack.staff_contract, languagePack.success_add_contract_staff_member);
            refreshList();
            closePopup();
        } else {
            showNotification("danger", languagePack.staff_contract, languagePack.fail_add_contract_staff_member);
        }
    }, [responseAddContract]);

    const addContract = async () => {
        const payload = new FormData();
        payload.append("category", "Contracte staff");
        payload.append("id_club", currentUser.id_club);
        payload.append("id_member_staff", state.staff_member?.value);
        payload.append("monthly_wage", state.wage_amount);
        payload.append("currency", state.currency?.value);
        payload.append("contract_date_start", moment(state.start_date).format("YYYY-MM-DD"));
        payload.append("contract_date_end", moment(state.end_date).format("YYYY-MM-DD"));
        payload.append("uploaded_by", currentUser.nickname);

        if (state.file.length > 0) {
            payload.append("file", state.file[0]);
        }

        postAddContract(payload);
    };

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_staff_contract"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBSelect
                label={languagePack.staff_member}
                placeholder={languagePack.choose_staff_member}
                value={state.staff_member}
                options={staffMember?.list ?? []}
                onChange={(selected) => updateState({ staff_member: selected })}
                style={{ control: { marginBottom: 20 } }}
            />
            <TFBCurrencyInput
                label={languagePack["monthly_wage"]}
                placeholder={languagePack["add_value"]}
                value={state.wage_amount}
                currency={state.currency}
                currencyOptions={state.currency_list}
                onValueChange={(e) => updateState({ wage_amount: e.target.value })}
                onCurrencyChange={(selected) => updateState({ currency: selected })}
                style={{ marginBottom: 20 }}
            />
            <TFBDatePicker
                label={languagePack["signing_date"]}
                value={state.start_date}
                onChange={(value) => updateState({ start_date: value })}
                style={{ marginBottom: 20 }}
            />
            <TFBDatePicker
                label={languagePack["ending_date"]}
                value={state.end_date}
                onChange={(value) => updateState({ end_date: value })}
                style={{ marginBottom: 20 }}
                error={hasDateError()}
                helperText={hasDateError() ? languagePack['start_end_contract_error'] : ""}
            />
            <TFBFileInput
                label={languagePack["upload_file"]}
                files={Array.from(state.file).map(elem => elem.name)}
                onChange={(e) => updateState({ file: e.target.files })}
                style={{ marginBottom: 20 }}
                accept=".png, .jpg, .jpeg, .doc, .docx, .pdf"
            />
            <div className="ds-secondary-text-auxiliary" style={{ color: "#b2b2b2" }}>
                {languagePack["add_contract_extra_bonuses_text"]}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(AddStaffContract);
