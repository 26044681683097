import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import DataTable from "react-data-table-component";

import yellow_red from "./../../../../assets/yellow_red.png";
import styles from "./CardsManagement.module.scss";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBTabControl from "../../../../components/design-system/TabControl/TFBTabControl";
import CircularLoader from "../../../../components/reusable/Loader";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import TFBDropdown from "../../../../components/design-system/Dropdown/TFBDropdown";
import ImportUpdateCardsManagement from "./ImportUpdateCardsManagement";
import { TFBTableCell } from "../../../../components/reusable/useStyles";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

const CardsManagement = (props) => {
  const { currentUser, languagePack } = props;

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      competition: null,
      team: null,
      active_tab: 0,
      open_import: false,
      import_data: {},
    }
  );

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `finances/get_club_teams?id_club=${currentUser.id_club}`
  );

  useEffect(() => {
    if (teams) {
      if (teams?.success == 1) {
        if (_.isArray(teams?.list) && _.size(teams?.list) > 0) {
          updateState({ team: teams.list[0].value });
        }
      }
    }
  }, [teams]);

  const {
    data: competitions,
    loading: isCompetitionsLoading,
    refetch: refetchCompetitions,
  } = useAxiosGet(
    `matches/get_competition_by_team?id_team=${state.team}&active_status=1`,
    true
  );

  useEffect(() => {
    if (competitions) {
      if (competitions?.success == 1) {
        if (_.isArray(competitions?.list) && _.size(competitions?.list) > 0) {
          updateState({ competition: competitions.list[0].value });
        }
      }
    }
  }, [competitions]);

  const {
    data: cardsMonitoringData,
    loading: isCardsMonitoringDataLoading,
    refetch: refetchCardsMonitoringData,
  } = useAxiosGet(
    `matches/get_cards_management_data_2?id_club=${
      currentUser.id_club
    }&id_competition=${state.competition}&id_team=${
      state.team
    }&competition_type=${
      _.find(competitions?.list, (e) => e.value == state.competition)?.type
    }`,
    true
  );

  const {
    data: matches,
    loading: isMatchesLoading,
    refetch: refetchMatches,
  } = useAxiosGet(
    `matches/get_football_api_matches_by_competition?id_team=${state.team}&id_competition=${state.competition}`,
    true
  );

  useEffect(() => {
    if (state.team) {
      refetchCompetitions();
      if (state.competition) {
        refetchMatches();
      }
    }
  }, [state.team]);

  useEffect(() => {
    if (state.competition) {
      refetchCardsMonitoringData();
      refetchMatches();
    }
  }, [state.competition]);

  const handleChangeDropdown = (e, value) => {
    updateState({ [value.name]: value.value });
  };

  const { renderBtn: BtnDownload } = useRenderButton(
    () => {},
    "green",
    null,
    languagePack.download_in_format + " .pdf"
  );

  const updateAfterImport = (match) => {
    refetchCardsMonitoringData();
    refetchMatches();
  };

  const openImport = (match) => {
    updateState({
      open_import: true,
      import_data: {
        id_football_api_match: match.id_rapid_api,
        id_team: state.team,
        id_club: currentUser.id_club,
      },
    });
  };

  const closeImport = () => {
    updateState({
      open_import: false,
      import_data: {},
    });
  };

  const renderTableColumns = () => {
    return _.union(
      [
        {
          selector: "name",
          grow: 1,
          name: languagePack.player_name,
          cell: (row, index) => row.player_name,
          style: { justifyContent: "flex-start", textTransform: "uppercase" },
          left: true,
        },
        {
          selector: "yellow",
          grow: 0.5,
          name: languagePack.yellow_cards_pl,
          cell: (row, index) =>
            _.size(_.filter(_.values(row), (e) => e?.yellow_card == 1)),
          style: { backgroundColor: "#EEEEEE" },
        },
      ],
      _.map(cardsMonitoringData?.matches, (m, i) => ({
        selector: m.id_match,
        grow: 0.5,
        name: (
          <div style={{ textAlign: "center" }}>
            <label className="margin-0">{i + 1}</label>
            <br />
            <label className="margin-0">{`${m.opponent_name} (${
              m.is_home_match == 1
                ? languagePack.home_match_abbreviation
                : languagePack.away_match_abbreviation
            })`}</label>
          </div>
        ),
        cell: (row, index) => (
          <div>
            {row?.[m.id_match]?.suspended == 1
              ? "SUSP"
              : row?.[m.id_match]?.unused_substitute
              ? row?.[m.id_match]?.unused_substitute == 0
                ? row?.[m.id_match]?.played_minutes
                : "RN"
              : "-"}
          </div>
        ),
        center: true,
        conditionalCellStyles: [
          {
            when: (row) =>
              row?.[m.id_match]?.yellow_card == 1 &&
              row?.[m.id_match]?.red_card == 0,
            style: {
              backgroundColor: "yellow",
              color: "black",
            },
          },
          {
            when: (row) =>
              row?.[m.id_match]?.yellow_card == 0 &&
              row?.[m.id_match]?.red_card == 1,
            style: {
              backgroundColor: "red",
              color: "white",
            },
          },
          {
            when: (row) =>
              row?.[m.id_match]?.yellow_card == 1 &&
              row?.[m.id_match]?.red_card == 1,
            style: {
              background: `url(${yellow_red})`,
              color: "black",
              backgroundPosition: "center",
            },
          },
          {
            when: (row) => row?.[m.id_match]?.suspended == 1,
            style: {
              backgroundColor: "black",
              color: "white",
            },
          },
        ],
      }))
    );
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#EEEEEE",
        paddingLeft: "8px",
        paddingRight: "8px",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        paddingLeft: "8px",
        paddingRight: "8px",
        borderRight: "1px solid #e0e0e0",
      },
    },
  };

  const tab1 = (
    <>
      {_.size(cardsMonitoringData?.players) > 0 &&
      _.size(cardsMonitoringData?.matches) > 0 ? (
        <div className={styles.suspensionTableContainer}>
          <DataTable
            highlightOnHover
            columns={renderTableColumns()}
            data={_.orderBy(
              cardsMonitoringData?.players,
              ["player_name"],
              ["asc"]
            )}
            customStyles={customStyles}
            noHeader={true}
          />
        </div>
      ) : (
        <TFBPlaceholder text={languagePack.no_data} />
      )}
    </>
  );

  const tab2 = (
    <>
      {_.size(matches?.list) > 0 ? (
        <TFBCard styleCardInner={{ padding: 10 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TFBTableCell>{languagePack.match}</TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.score}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.competition}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.round}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.date}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(matches?.list, (row, i) => (
                  <TableRow
                    key={"rapid-api-match" + i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: i % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    <TFBTableCell>
                      {row?.home_team?.name + " - " + row?.away_team?.name}
                      {_.size(row?.import_obj?.import_warning) > 0 && (
                        <div className={styles.unmappedPlayer}>
                          {languagePack?.unmapped_player +
                            ": " +
                            row?.import_obj?.import_warning}
                        </div>
                      )}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row?.score?.home + " - " + row?.score?.away}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row?.competition}
                    </TFBTableCell>
                    <TFBTableCell align="center">{row?.round}</TFBTableCell>
                    <TFBTableCell align="center">
                      {moment(row?.match_date).format("DD.MM.YYYY")}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row?.imported ? (
                        <TFBIconButton
                          name="check"
                          color="green"
                          disabled={true}
                          altTitle={languagePack.imported}
                        />
                      ) : (
                        <TFBIconButton
                          name="arrow-right"
                          altTitle={languagePack.import}
                          onClick={() => openImport(row)}
                        />
                      )}
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ImportUpdateCardsManagement
            openPopup={state.open_import}
            setLoad
            closePopup={closeImport}
            refreshList={updateAfterImport}
            importData={state.import_data}
          />
        </TFBCard>
      ) : (
        <TFBPlaceholder text={languagePack.no_matches} />
      )}
    </>
  );

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack["suspension_status"]}>
        {!isTeamsLoading &&
          !isCompetitionsLoading &&
          !isCardsMonitoringDataLoading &&
          !isMatchesLoading && (
            <>
              {/* <BtnDownload /> */}
              <TFBDropdown
                placeholder={languagePack["select_team"]}
                selection
                options={teams?.list ?? []}
                value={state.team}
                onChange={handleChangeDropdown}
                name="team"
              />
              <TFBDropdown
                placeholder={languagePack["select_competition"]}
                selection
                options={competitions?.list ?? []}
                value={state.competition}
                onChange={handleChangeDropdown}
                name="competition"
              />
            </>
          )}
      </TFBCardHeader>
      <TFBCardBody>
        {!isTeamsLoading &&
        !isCompetitionsLoading &&
        !isCardsMonitoringDataLoading ? (
          <TFBTabControl
            tabs={[
              languagePack.suspension_table,
              languagePack.import_suspension_update,
            ]}
            tabsContent={[tab1, tab2]}
            activeTab={state.active_tab}
            onChangeActiveTab={(i) => updateState({ active_tab: i })}
          />
        ) : (
          <CircularLoader />
        )}
      </TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(CardsManagement);
