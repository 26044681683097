import React, { useEffect } from "react";
import _ from "lodash";

import useAxiosGet from "../components/customHooks/useAxiosGet";
import DemoVideoItem from "./DemoVideoItem";

interface SingleTutorialVideoI {
    id_video: string | number;
    thumbnail_url: string;
    video_name: string;
    video_url: string;
}

const VideoList = ({
    menuModule,
    onClick,
}: {
    menuModule: string;
    onClick: (video_name: string, video_url: string) => {};
}) => {
    const {
        data: videoList,
        loading: isVideoListLoading,
        refetch: refetchVideoList,
    } = useAxiosGet(
        `https://api.thefootballbrain.app/tutorialVideo/get_tutorial_video_by_module?module=${menuModule === "dashboard" ? "" : menuModule
        }`,
        true
    );

    useEffect(() => {
        refetchVideoList();
    }, [menuModule, refetchVideoList]);

    return (
        <>
            {_.map(
                videoList ? videoList["list"] : [],
                (e: SingleTutorialVideoI, i) => (
                    <DemoVideoItem
                        key={"video-" + i}
                        title={e.video_name}
                        src={e.thumbnail_url}
                        onButtonClick={() => onClick(e.video_name, e.video_url)}
                    />
                )
            )}
        </>
    );
};

export default VideoList;