import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useHistory } from "react-router-dom";

import styles from "./News.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";

const AddNews = (props) => {
  const { languagePack, currentUser } = props;
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      title: "",
      file: [],
      file_preview: null,
      selected_tags: [],

      add_new_section: false,
      add_section_type: null,
      add_section_data: {},

      sections: [],

      selected_audience: {
        value: "only_my_club",
        label: "Clubul meu",
      },

      send_mobile_app_notification: false,
    }
  );

  const { data: tags } = useAxiosGet(`parentsApp/get_news_tags`);

  const { postData: addRequest, loading: loadingAddNews } = useAxiosPost(
    "parentsApp/add_news"
  );

  const handleResponse = (response) => {
    if (response?.success == 1) {
      history.push("/application/news");
      showNotification(
        "success",
        languagePack.news,
        languagePack.success_add_news
      );
    } else {
      showNotification("danger", languagePack.news, languagePack.fail_add_news);
    }
  };

  const addNews = () => {
    const payload = {
      news_title: state.title,
      id_club:
        state.selected_audience?.value == "only_my_club"
          ? currentUser.id_club
          : null,
      id_author: currentUser.id_user,
      preview_img_url: _.size(state.file) > 0 ? 1 : 0,
      main_img_base64: state?.file_preview ?? null,
      tags: state.selected_tags ?? [],
      sections: _.map(state.sections, (e, index) => ({
        section_type: e.section_type,
        section_type_style: e.section_type_style,
        section_text: e?.section_text ?? null,
        section_img_base64: e?.section_img_preview ?? null,
        nr_order: index + 1,
      })),
      send_mobile_app_notification: state.send_mobile_app_notification,
    };

    addRequest(payload, handleResponse);
  };

  const handleChooseSectionAdd = (type) => {
    if (type != state.add_section_type) {
      updateState({
        add_new_section: true,
        add_section_type: type,
        add_section_data: {},
      });
    }
  };

  const cancelAddSection = () => {
    updateState({
      add_new_section: false,
      add_section_type: null,
      add_section_data: {},
    });
  };

  const addSection = () => {
    const payload = {
      section_type: {
        article_sub_title: "text",
        article_paragraph: "text",
        article_image: "image",
      }[state.add_section_type],
      section_type_style: {
        article_sub_title: "sub_title_text",
        article_paragraph: "content_text",
        article_image: "section_image",
      }[state.add_section_type],
      section_text: state.add_section_data?.value ?? null,
      section_img:
        _.size(state.add_section_data?.file ?? null) > 0
          ? state.add_section_data?.file
          : null,
      section_img_preview:
        _.size(state.add_section_data?.file ?? null) > 0
          ? state.add_section_data?.file_preview
          : null,
    };

    updateState({
      sections: [...state.sections, payload],
    });
    cancelAddSection();
  };

  const removeSection = (index) => {
    let arrayCopy = _.clone(state.sections);
    arrayCopy.splice(index, 1);
    updateState({ sections: arrayCopy });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(state.sections, source.index, destination.index);

      updateState({
        sections: items,
      });
    }
  };

  const isDisabledAddSection = () => {
    if (
      _.includes(
        ["article_sub_title", "article_paragraph"],
        state.add_section_type
      )
    ) {
      return (state.add_section_data?.value ?? "") == "";
    }
    if (_.includes(["article_image"], state.add_section_type)) {
      return _.size(Array.from(state.add_section_data?.file ?? [])) == 0;
    }
    return true;
  };

  const getImagefromFile = (files, file, saveTo, type) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      if (type == "main_img") {
        updateState({ [saveTo]: reader.result, file: files });
      }
      if (type == "section_img") {
        updateState({
          add_section_data: {
            ...state.add_section_data,
            [saveTo]: reader.result,
            file: files,
          },
        });
      }
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const onChangeFile = (e) => {
    if (_.size(e.target.files) > 0) {
      getImagefromFile(
        e.target.files,
        e.target.files[0],
        "file_preview",
        "main_img"
      );
    } else {
      updateState({ file_preview: null });
    }
  };

  const onChangeSectionFile = (e) => {
    if (_.size(e.target.files) > 0) {
      getImagefromFile(
        e.target.files,
        e.target.files[0],
        "file_preview",
        "section_img"
      );
    } else {
      updateState({
        add_section_data: {
          ...state.add_section_data,
          file: [],
          file_preview: null,
        },
      });
    }
  };

  const handleChangeSendMobileAppNotification = (e) => {
    updateState({ send_mobile_app_notification: e.target.checked });
  };

  return (
    <div className="row">
      <div className="col-lg-6 col-lg-padding">
        <TFBCard className={styles.addNews}>
          <TFBCardHeader title={languagePack.add_news_title} />
          <TFBCardBody>
            <TFBInput
              label={languagePack["add_news_article_title"]}
              placeholder={languagePack["add_value"]}
              value={state.title}
              onChange={(e) => updateState({ title: e.target.value })}
              style={{
                marginBottom: 20,
                width: "100%",
                backgroundColor: "#fafafa",
              }}
            />
            <div className="df-g20">
              <TFBFileInput
                label={languagePack["upload_main_image"]}
                files={Array.from(state.file).map((elem) => elem.name)}
                onChange={onChangeFile}
                className={styles.fileInput}
                accept=".png, .jpg, .jpeg"
              />
              <TFBSelect
                label={languagePack.select_tags}
                placeholder={languagePack.select_tags}
                value={state.selected_tags}
                options={tags?.list ?? []}
                onChange={(selected) =>
                  updateState({ selected_tags: selected })
                }
                className={styles.selectComponent}
                isMulti={true}
                isSearchable={true}
                isClearable={true}
              />
            </div>
            {currentUser?.id_club == "6" && (
              <TFBSelect
                label={languagePack.select_audience}
                placeholder={languagePack.select_audience}
                value={{
                  ...state.selected_audience,
                  label:
                    languagePack?.[state.selected_audience?.value] ??
                    state.selected_audience?.label,
                }}
                options={[
                  { value: "only_my_club", label: languagePack.only_my_club },
                  { value: "all_clubs", label: languagePack.all_clubs },
                ]}
                onChange={(selected) =>
                  updateState({ selected_audience: selected })
                }
                className={styles.selectComponent}
                style={{ component: { marginTop: 20 } }}
              />
            )}
            <TFBCardHeader
              title={languagePack.sections}
              style={{ marginTop: 30 }}
            />
            {state.add_new_section && (
              <>
                {_.includes(
                  ["article_sub_title", "article_paragraph"],
                  state.add_section_type
                ) && (
                  <TFBTextArea
                    label={languagePack[state.add_section_type]}
                    value={state.add_section_data?.value ?? ""}
                    onChange={(e) =>
                      updateState({
                        add_section_data: {
                          ...state.add_section_data,
                          value: e.target.value,
                        },
                      })
                    }
                  />
                )}
                {_.includes(["article_image"], state.add_section_type) && (
                  <TFBFileInput
                    label={languagePack[state.add_section_type]}
                    files={Array.from(state.add_section_data?.file ?? []).map(
                      (elem) => elem.name
                    )}
                    onChange={onChangeSectionFile}
                    style={{ width: "100%", marginBottom: 20 }}
                    accept=".png, .jpg, .jpeg"
                  />
                )}
                <div className={styles.addSectionBtnGrid}>
                  <TFBButton
                    color="lightGray"
                    renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                    onClick={cancelAddSection}
                  >
                    {languagePack.admin_cancel}
                  </TFBButton>
                  <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                    onClick={addSection}
                    disabled={isDisabledAddSection()}
                  >
                    {languagePack.admin_add}
                  </TFBButton>
                </div>
              </>
            )}
            {!state.add_new_section && (
              <>
                {_.size(state.sections) > 0 && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppableSections">
                      {(provided, snapshot) => (
                        <div
                          className={styles.sectionsDragContainer}
                          ref={provided.innerRef}
                        >
                          <TableContainer>
                            <Table
                              sx={{ minWidth: 0 }}
                              aria-label="simple table"
                            >
                              <TableBody>
                                {_.map(state.sections, (row, index) => (
                                  <Draggable
                                    key={"section-" + index}
                                    draggableId={"section-" + index}
                                    index={index}
                                  >
                                    {(provided, snapshot) => (
                                      <TableRow
                                        key={"news-" + index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                          backgroundColor:
                                            index % 2 == 0
                                              ? "#fafafa"
                                              : "white",
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <TFBTableCell>{index + 1}</TFBTableCell>
                                        <TFBTableCell>
                                          {
                                            languagePack[
                                              {
                                                sub_title_text:
                                                  "article_sub_title",
                                                content_text:
                                                  "article_paragraph",
                                                section_image: "article_image",
                                              }[row.section_type_style]
                                            ]
                                          }
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                          <div
                                            style={{
                                              height: 23,
                                              overflow: "hidden",
                                            }}
                                          >
                                            {row.section_text}
                                          </div>
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                          <div className="actions-btns-container df-end">
                                            <TFBIconButton
                                              name="delete"
                                              color="darkGray"
                                              onClick={() =>
                                                removeSection(index)
                                              }
                                            />
                                          </div>
                                        </TFBTableCell>
                                      </TableRow>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )}
                <div className={styles.addSectionBtnGrid}>
                  <TFBButton
                    color="lightGray"
                    renderIcon={() => <TFBIcon name="add" color="gray" />}
                    onClick={() => handleChooseSectionAdd("article_sub_title")}
                  >
                    {languagePack.article_sub_title}
                  </TFBButton>
                  <TFBButton
                    color="lightGray"
                    renderIcon={() => <TFBIcon name="add" color="gray" />}
                    onClick={() => handleChooseSectionAdd("article_paragraph")}
                  >
                    {languagePack.article_paragraph}
                  </TFBButton>
                  <TFBButton
                    color="lightGray"
                    renderIcon={() => <TFBIcon name="add" color="gray" />}
                    onClick={() => handleChooseSectionAdd("article_image")}
                  >
                    {languagePack.article_image}
                  </TFBButton>
                </div>
                <TFBCheckbox
                  label={languagePack.send_mobile_app_notification}
                  checked={state.send_mobile_app_notification}
                  onChange={handleChangeSendMobileAppNotification}
                  style={{ marginTop: 20 }}
                />
                <div className={styles.submitGroupBtn}>
                  <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    className={styles.cancelArticleBtn}
                    onClick={() => history.push("/application/news")}
                  >
                    {languagePack.add_news_cancel_button}
                  </TFBButton>
                  <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                    className={styles.addArticleBtn}
                    onClick={addNews}
                    loading={loadingAddNews}
                    disabled={_.size(_.trim(state.title)) == 0}
                  >
                    {languagePack.add_news_publish_button}
                  </TFBButton>
                </div>
              </>
            )}
          </TFBCardBody>
        </TFBCard>
      </div>
      <div className="col-lg-6 col-lg-padding">
        <TFBCard className={styles.addNews}>
          <TFBCardHeader title={languagePack.article_preview} />
          <TFBCardBody>
            <div className={styles.newsContainer}>
              <div className={styles.newsTitle}>{state.title}</div>
              <div className={styles.newsTagsContainer}>
                {_.map(state.selected_tags, (row, index) => (
                  <div className={styles.newsTag} key={"tag-" + index}>
                    {row.label}
                  </div>
                ))}
              </div>
              {_.size(state.file) > 0 && state.file_preview != null && (
                <div className={styles.news_main_img}>
                  <img src={state.file_preview} />
                </div>
              )}
              {_.map(state.sections, (row, index) => {
                if (row.section_type == "text") {
                  return (
                    <div
                      className={styles[row.section_type_style]}
                      key={"section-" + index}
                    >
                      {row.section_text}
                    </div>
                  );
                }
                if (row.section_type == "image") {
                  return (
                    <div
                      className={styles[row.section_type_style]}
                      key={"section-" + index}
                    >
                      <img src={row.section_img_preview} />
                    </div>
                  );
                }
              })}
            </div>
          </TFBCardBody>
        </TFBCard>
      </div>
    </div>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(AddNews);
