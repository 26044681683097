import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";
import BasePage from "./BasePage";
import AuthPage from "./Auth/AuthPage";
import { IntlProvider } from "react-intl";

import { checkToken } from "../redux/authCrud";
import { actions } from "../redux/authRedux";
import { logout, login } from "./utils/api";
import { clearVisitorDataForTidioChatApi, identifyVisitorForTidioChatApi, loadTidioChat, unloadTidioChat } from "./utils/tidio";

import { useHistory } from "react-router-dom";
import axios from "axios";
import CreateAccount from "./CreateAccount/CreateAccount";

export function Routes() {
  const { isAuth, lang, languagePack, token, user, club } = useSelector(
    ({ auth, layoutService: { lang, languagePack } }) => ({
      isAuth: auth?.user?.token,
      lang,
      token: auth?.user?.token,
      languagePack,
      user: auth?.user,
      club: auth?.user?.club,
    })
  );
  const dispatch = useDispatch();

  const history = useHistory();

  const location = useLocation();
  let redirect_url = new URLSearchParams(location.search).get("redirect_url");

  if (!redirect_url) {
    redirect_url = location.state?.redirectUrl;
  }

  useEffect(() => {
    if (isAuth) {
      return history.listen((location) => {
        let array = location.pathname.split("/");
        const payload = {
          token: token,
          id_user: user.id_user,
          id_club: user.id_club,
          full_pathname: location.pathname,
          module: array.length > 1 ? array[1] : null,
          sub_module: array.length > 2 ? array[2] : null,
          id_entity: array.length > 3 ? array[3] : null,
        };
        axios
          .post("user/add_access_log", payload)
          .catch((err) => console.log(err));
      });
    }
    return;
  }, [history]);

  useEffect(() => {
    if (isAuth) {
      const userPayload = {
        distinct_id: user.id_user, // Unique visitor ID in your system
        email: user.email, // visitor email
        name: `${user.full_name} (${club.club_name})`, // Visitor name
        phone: user.phone_number, //Visitor phone
      };
      identifyVisitorForTidioChatApi(userPayload);
      loadTidioChat();
    } else {
      clearVisitorDataForTidioChatApi();
      unloadTidioChat();
    }
  }, [isAuth])

  const [messages, setMessages] = useState(languagePack.FORM_CONTROL);

  function checkLoggedUser() {
    return checkToken({ token }).then(({ data }) => {
      if (data.success === 1) {
        let clubQuickSetup = data.club_quick_setup;

        login(data.user)(dispatch);
        dispatch(
          actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: false })
        );
      } else {
        logout({ token })(dispatch);
      }
    });
  }

  useLayoutEffect(() => {
    setMessages(languagePack.FORM_CONTROL);
  }, [lang]);

  useEffect(() => {
    setTimeout(() => {
      if (token) {
        checkLoggedUser();
      }
    }, 5000);
  }, []);

  const user_permissions =
    user?.user_permissions && typeof user.user_permissions === "object"
      ? user.user_permissions
      : null;

  return (
    <IntlProvider locale="en" messages={messages}>
      <Switch>
        {!isAuth && <Route path="/register" component={CreateAccount} />}
        {!isAuth && <Route path="/auth" component={() => <AuthPage {...redirect_url && { redirectUrl: encodeURI(redirect_url) }} />} />}
        {!isAuth &&
          <Route path="/" render={() =>
            <Redirect
              to={{
                pathname: "/auth",
                state: { ...(redirect_url && { redirectUrl: encodeURI(redirect_url) }) }
              }}
            />}
          />
        }

        {user_permissions && isAuth ? (
          <>
            <Layout>
              <BasePage user_permissions={user_permissions} />
            </Layout>
          </>
        ) : (
          <LayoutSplashScreen />
        )}
      </Switch>
    </IntlProvider>
  );
}
