import React, { useRef, useEffect, useState, useCallback, forwardRef, useImperativeHandle, useContext } from 'react';
import { fabric } from 'fabric';
import { forEach, includes, size, filter } from 'lodash';
import { v4 as uuid } from "uuid";

import styles from "./TrainingBoard.module.scss";

import { BoardContext } from './BoardContext';

import { colorCoding } from './utils/colorCoding';
import { imgCursor, svgRotateIcon } from './utils/svgIcons';
import { arrowTriangle, dashedProps, initialFigureProps, initialGroupProps, initialLineProps, initialShapeProps, initialTextInputProps, initialWavyProps, lineGroupProps, polylineTriangle, resizeShapeProps, squareControlProps, wavyDashedProps } from './utils/initialProps';
import { arrowLineList } from './utils/toolsNomenclature';
import { calculateWavePoints, wavyConst } from './utils/functions';

import ball from './../../../assets/training-board/ball.svg';
import x_cone from './../../../assets/training-board/x-cone.svg';
import cone from './../../../assets/training-board/cone.svg';
import goal from './../../../assets/training-board/goal.svg';
import x_goal from './../../../assets/training-board/x-goal.svg';
import mannequin from './../../../assets/training-board/mannequin.svg';
import ladder from './../../../assets/training-board/ladder.svg';
import hurdle from './../../../assets/training-board/hurdle.svg';
import slalom_pole from './../../../assets/training-board/slalom-pole.svg';

import player from './../../../assets/training-board/player.svg';
import goalkeeper from './../../../assets/training-board/goalkeeper.svg';
import triangle_player from './../../../assets/training-board/triangle-player.svg';
import circle_player from './../../../assets/training-board/circle-player.svg';

fabric.Object.prototype.toObject = (function (toObject) {
    return function () {
        // Call the original toObject method and add custom properties
        return fabric.util.object.extend(toObject.call(this), {
            customProps: this.customProps, // Add custom props here
            hasBorders: this.hasBorders ?? true,
            ...(this.radius && { radius: this.radius }), // for circle
            ...(this.rx && this.ry && { rx: this.rx, ry: this.ry, }), // for oval
            ...(this.customProps?.type == "text" && { ...initialTextInputProps }),
            ...(this.fontSize && { fontSize: this.fontSize }),
            ...(this.fontWeight && { fontWeight: this.fontWeight }),
            ...(this.fontFamily && { fontFamily: this.fontFamily }),
            ...(this.shadow && { shadow: this.shadow }),
            ...(this.text && { text: this.text }),
            ...(this.borderColor && { borderColor: this.borderColor }),
            ...(this.type == "text" && { text: this.text ?? "" }),
        });
    };
})(fabric.Object.prototype.toObject);

fabric.Object.prototype.setCustomControls = function () {
    // Custom render icon function for rotation control
    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        const size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(imgIcon, -size / 2, -size / 2, size, size);
        ctx.restore();
    }

    const rotateIcon = `data:image/svg+xml;utf8,${encodeURIComponent(svgRotateIcon)}`;
    const imgIcon = document.createElement('img');
    imgIcon.src = rotateIcon;

    // Create the custom rotation control
    const mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetX: 0,
        offsetY: -40,
        cursorStyle: `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(imgCursor)}") 12 12, crosshair`,
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: 'rotate',
        render: renderIcon,
        cornerSize: 32,
        withConnection: true,
        sizeX: 20,
        sizeY: 20,
    });

    // Apply the custom rotation control to the object's controls
    this.controls.mtr = mtr;
};

fabric.Object.prototype.initialize = (function (originalInitialize) {
    return function (options) {
        originalInitialize.call(this, options);

        // Ensure the custom properties are always set
        this.set({
            borderColor: '#2cc681',
            cornerColor: '#2cc681',
            cornerSize: 8,
            transparentCorners: true,
        });

        this.setCustomControls();
    };
})(fabric.Object.prototype.initialize);

const FootballField = forwardRef((props, ref) => {
    const { selectedShape, setSelectedShape, selectedColor, showGrid, setHeaderMode, isDragging, isInCanvas, activeInput, selectedShapeLabel, setSelectedShapeLabel, pitchSelection, initialBoardData, setIsCanvasDirty, fontSize, setFontSize } = useContext(BoardContext);

    const canvasRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });
    const [endPos, setEndPos] = useState({ x: 0, y: 0 });
    const [moveStartSquareControl, setMoveStartSquareControl] = useState(false);
    const [currentShape, setCurrentShape] = useState(null);

    const deleteSelectedObject = () => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            const id = activeObject?.customProps?.id;
            if (id) {
                const customControls = canvas.getObjects().filter(obj => obj.customProps && (obj.customProps.type === 'start' || obj.customProps.type === 'end') && obj.customProps.id === id);

                forEach(customControls, obj => canvas.remove(obj));
            }

            if (activeObject) {
                if (size(activeObject._objects) > 1) {
                    forEach(activeObject._objects, obj => canvas.remove(obj));
                    activeObject.set({
                        hasBorders: false,
                    });
                }
                canvas.remove(activeObject);
                setHeaderMode(1);
            }
            canvas.renderAll();
        }
    }

    useImperativeHandle(ref, () => ({
        clearAll() {
            if (canvas) {
                canvas.getObjects().forEach((obj) => {
                    canvas.remove(obj);
                });
                setCurrentShape(null);
            }
        },
        deleteSelected() {
            deleteSelectedObject();
        },
        rotateSelected() {
            if (canvas) {
                const obj = canvas.getActiveObject();
                if (obj) {
                    let angle = obj.angle + 15;
                    angle = angle % 360;
                    obj.rotate(angle);
                    canvas.setActiveObject(obj);
                    canvas.renderAll();
                }
            }
        },
        getCanvasObjects() {
            if (canvas) {
                const customControls = canvas.getObjects().filter(obj => obj.customProps && (obj.customProps.type === 'start' || obj.customProps.type === 'end'));
                forEach(customControls, obj => canvas.remove(obj));
                canvas.renderAll();

                return canvas.toJSON();
            } else {
                return null;
            }
        },
        async getCanvasImage() {
            if (canvas) {
                const customControls = canvas.getObjects().filter(obj => obj.customProps && (obj.customProps.type === 'start' || obj.customProps.type === 'end'));
                forEach(customControls, obj => canvas.remove(obj));
                canvas.renderAll();

                return canvas.toDataURL({
                    format: 'png', // Format of the image (e.g., 'png', 'jpeg')
                    quality: 1.0,  // Optional: Quality (0-1) for 'jpeg' format
                });
            } else {
                return null;
            }
        }
    }));

    useEffect(() => {
        const fabricCanvas = new fabric.Canvas(canvasRef.current, {
            backgroundColor: 'transparent',
            hoverCursor: 'move',
            defaultCursor: 'default',
            selection: false,
            selectionBorderColor: '#2cc681',
            selectionColor: 'rgb(44 198 129 / 10%)',
            preserveObjectStacking: true,
        });

        setCanvas(fabricCanvas);

        return () => {
            fabricCanvas.dispose();
        };
    }, []);

    useEffect(() => {
        if (pitchSelection && canvas) {
            fabric.Image.fromURL(`data:image/png;base64,${!showGrid ? pitchSelection.img_url : pitchSelection.img_url_grid}`, function (img) {
                // add background image
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height,
                    customProps: {
                        ...pitchSelection,
                        show_grid: showGrid,
                    },
                });
                setCanvas(canvas);
            });
        }
    }, [pitchSelection, canvas]);

    useEffect(() => {
        if (canvas && initialBoardData?.json) {
            canvas.loadFromJSON(initialBoardData.json, () => {
                canvas.getObjects().forEach((obj) => {
                    const shape = obj?.customProps?.shape || obj?.customProps?.type;
                    obj.setControlsVisibility(resizeShapeProps[shape] ?? {});
                });
                canvas.renderAll();
                setCanvas(canvas);
            });
        }
    }, [initialBoardData, canvas])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!activeInput) {
                const activeObject = canvas.getActiveObject();
                if ((e.key === 'Backspace' || e.key === 'Delete') && activeObject?.customProps?.type != "text") {
                    deleteSelectedObject();
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Clean up event listener on component unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [canvas, activeInput]);

    useEffect(() => {
        if (canvas) {
            if (selectedShape) {
                canvas.selection = false;
                canvas.defaultCursor = 'crosshair';
            } else {
                canvas.selection = true;
                canvas.defaultCursor = 'default';
            }
        }
    }, [selectedShape, canvas])

    useEffect(() => {
        if (canvas) {
            fabric.Image.fromURL(`data:image/png;base64,${!showGrid ? pitchSelection.img_url : pitchSelection.img_url_grid}`, function (img) {
                // add background image
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height,
                    customProps: {
                        ...pitchSelection,
                        show_grid: showGrid,
                    },
                });
                setCanvas(canvas);
            });
        }
    }, [showGrid])

    useEffect(() => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                if (activeObject?.customProps?.hasLabel) {
                    if (activeObject.type === 'group') {
                        let foundText = false;

                        activeObject.forEachObject((obj) => {
                            if (obj.customProps?.type === 'text') {
                                obj.set({ text: selectedShapeLabel });
                                foundText = true;
                            }
                        });

                        if (foundText) {
                            activeObject.set({ customProps: { ...activeObject.customProps, label: selectedShapeLabel } });
                            canvas.renderAll();
                            return;
                        }
                    }

                    const textObj = new fabric.Text(selectedShapeLabel, {
                        fontSize: activeObject.customProps.fontSize,
                        fontWeight: 700,
                        fontFamily: "Poppins",
                        fill: activeObject.customProps.labelColor,
                        left: activeObject.left,
                        top: activeObject.top,
                        originX: 'center',
                        originY: 'center',
                        selectable: false,
                        customProps: { type: "text" },
                        ...(activeObject.customProps.hasShadow && { shadow: new fabric.Shadow({ color: 'black', blur: 1, offsetY: 0.5, }) }),
                    });

                    const newGroup = new fabric.Group([activeObject, textObj], {
                        ...initialFigureProps,
                        left: activeObject.left,
                        top: activeObject.top,
                        originX: 'center',
                        originY: 'center',
                        selectable: true,
                        customProps: { ...activeObject.customProps, label: selectedShapeLabel }
                    });
                    newGroup.setControlsVisibility(resizeShapeProps.figures)
                    canvas.remove(activeObject);
                    canvas.add(newGroup);
                    canvas.setActiveObject(newGroup);
                    canvas.renderAll();
                }
            }
        }
    }, [selectedShapeLabel, canvas])

    useEffect(() => {
        if (canvas) {
            const activeObject = canvas.getActiveObject();
            if (activeObject) {
                if (activeObject?.customProps?.type == "text") {
                    activeObject.set({ fontSize })
                    if (activeObject.text == "") {
                        const minWidth = 100;
                        activeObject.set({ width: minWidth })
                        activeObject.scaleX = minWidth / activeObject.width;
                    }
                    canvas.renderAll();
                }
            }
        }
    }, [fontSize, canvas])

    useEffect(() => {
        if (isDragging) {
            if (canvas) {
                canvas.discardActiveObject();
                canvas.renderAll();
            }
        }
    }, [canvas, isDragging])

    const handleMouseDown = useCallback((options) => {
        if (options.target) { // Do not draw if clicking on an existing shape
            const movedObject = options.target;
            const movedObjectType = movedObject?.customProps?.type;

            if (movedObjectType === 'start' || movedObjectType === 'end') {
                const id = movedObject?.customProps?.id;
                const arrow = canvas.getObjects().find(obj => obj?.customProps && arrowLineList.includes(obj?.customProps?.type) && obj?.customProps?.id === id);

                if (arrow) {
                    const startPoint = arrow.getPointByOrigin('left', 'center');
                    const endPoint = arrow.getPointByOrigin('right', 'center');
                    const type = arrow.customProps.type;
                    const color = arrow.customProps.color;

                    setStartPos(startPoint)
                    if (movedObjectType === 'start') {
                        setEndPos(endPoint)
                        setMoveStartSquareControl(true);
                    }
                    setIsDrawing(true);
                    setSelectedShape(type);

                    const { x: startX, y: startY } = startPoint;
                    const { x: endX, y: endY } = endPoint;

                    if (includes(filter(arrowLineList, e => includes(e, "wavy")), type)) {
                        const { linePoints, trianglePoints } = calculateWavePoints(startX + 1, startY + 1, endX, endY, includes(type, "arrow"));

                        const line = new fabric.Polyline(linePoints, {
                            ...initialWavyProps,
                            ...(includes(type, "dashed") && { ...wavyDashedProps }),
                            stroke: colorCoding[color + "Figure"],
                            customProps: { type, color },
                        });

                        canvas.remove(arrow);

                        let figure = { line, type };

                        if (includes(type, "arrow")) {
                            const triangle = new fabric.Polygon(trianglePoints, {
                                ...polylineTriangle,
                                stroke: colorCoding[color + "Figure"],
                                fill: colorCoding[color + "Figure"],
                            });
                            canvas.add(triangle);
                            figure = { ...figure, triangle }
                        }
                        canvas.add(line);
                        setCurrentShape(figure);
                        canvas.renderAll();
                    } else {
                        const initialLine = new fabric.Line([startPoint.x, startPoint.y, startPoint.x, startPoint.y], {
                            ...initialLineProps,
                            ...(includes(type, "dashed") && { ...dashedProps }),
                            stroke: colorCoding[color + "Figure"],
                            customProps: { type, color },
                        });
                        initialLine.set({ x2: endX, y2: endY });

                        canvas.remove(arrow);

                        let shape = { type, line: initialLine };
                        if (includes(type, "arrow")) {
                            const initialTriangle = new fabric.Triangle({
                                ...arrowTriangle,
                                left: startPoint.x,
                                top: startPoint.y,
                                fill: colorCoding[color + "Figure"],
                            });

                            const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI);
                            initialTriangle.set({
                                left: endX,
                                top: endY,
                                angle: angle + 90, // Rotate the triangle to align with the line
                            });
                            canvas.add(initialTriangle);
                            shape = { ...shape, triangle: initialTriangle }
                        }

                        // Add the line and triangle separately to the canvas
                        canvas.add(initialLine);
                        setCurrentShape(shape);
                        canvas.renderAll();
                    }
                }
            }
        } else {
            const pointer = canvas.getPointer(options.e);
            setStartPos(pointer);
            setIsDrawing(true);

            let shape;
            switch (selectedShape) {
                case 'rectangle':
                    shape = new fabric.Rect({
                        left: pointer.x,
                        top: pointer.y,
                        fill: colorCoding[selectedColor + "Shape"],
                        ...initialShapeProps,
                    });
                    break;
                case 'square':
                    shape = new fabric.Rect({
                        left: pointer.x,
                        top: pointer.y,
                        fill: colorCoding[selectedColor + "Shape"],
                        ...initialShapeProps,
                    });
                    break;
                case 'circle':
                    shape = new fabric.Circle({
                        left: pointer.x,
                        top: pointer.y,
                        radius: 1,
                        fill: colorCoding[selectedColor + "Shape"],
                        ...initialShapeProps,
                    });
                    break;
                case 'oval':
                    shape = new fabric.Ellipse({
                        left: pointer.x,
                        top: pointer.y,
                        rx: 1,
                        ry: 1,
                        fill: colorCoding[selectedColor + "Shape"],
                        ...initialShapeProps,
                    });
                    break;
                case 'line': case 'dashed-line': case 'arrow': case 'dashed-arrow': {
                    const initialLine = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                        ...initialLineProps,
                        stroke: colorCoding[selectedColor + "Figure"],
                        customProps: { type: selectedShape, color: selectedColor },
                        ...(includes(selectedShape, "dashed") && { ...dashedProps })
                    });

                    let figure = { line: initialLine, type: selectedShape };
                    if (includes(selectedShape, "arrow")) {
                        const initialTriangle = new fabric.Triangle({
                            ...arrowTriangle,
                            left: pointer.x,
                            top: pointer.y,
                            fill: colorCoding[selectedColor + "Figure"],
                        });

                        canvas.add(initialTriangle);
                        figure = { ...figure, triangle: initialTriangle }
                    }

                    canvas.add(initialLine);
                    setCurrentShape(figure);
                    canvas.renderAll();
                    break;
                }
                case 'wavy-line': case 'wavy-arrow': case 'dashed-wavy-line': case 'dashed-wavy-arrow':
                    {
                        const points = [{ x: pointer.x, y: pointer.y }];

                        const line = new fabric.Polyline(points, {
                            ...initialWavyProps,
                            ...(includes(selectedShape, "dashed") && { ...wavyDashedProps }),
                            stroke: colorCoding[selectedColor + "Figure"],
                            customProps: { type: selectedShape, color: selectedColor },
                        });

                        let figure = { line, type: selectedShape };

                        if (includes(selectedShape, "arrow")) {
                            const triangle = new fabric.Polygon(points, {
                                ...polylineTriangle,
                                stroke: colorCoding[selectedColor + "Figure"],
                                fill: colorCoding[selectedColor + "Figure"],
                            });
                            canvas.add(triangle);
                            figure = { ...figure, triangle }
                        }

                        canvas.add(line);
                        setCurrentShape(figure);
                        canvas.renderAll();
                        break;
                    }
                default:
                    break;
            }

            if (shape) {
                canvas.add(shape);
                setCurrentShape(shape);
            }
        }
    }, [canvas, selectedShape, selectedColor, startPos, endPos, moveStartSquareControl]);

    const handleMouseMove = useCallback((options) => {
        if (canvas) {
            if (canvas) {
                if (selectedShape) {
                    canvas.selection = false;
                    canvas.defaultCursor = 'crosshair';
                } else {
                    canvas.selection = true;
                    canvas.defaultCursor = 'default';
                }
            }
        }

        if (!isDrawing || !currentShape) return;

        const pointer = canvas.getPointer(options.e);
        const { x: startX, y: startY } = startPos;
        const { x: endX, y: endY } = pointer;

        if (startPos, pointer) {
            switch (selectedShape) {
                case 'rectangle':
                    currentShape.set({
                        width: Math.abs(endX - startX),
                        height: Math.abs(endY - startY),
                        left: endX < startX ? endX : startX,
                        top: endY < startY ? endY : startY,
                        visible: true, // Make it visible when dragging
                    });

                    break;
                case 'square':
                    const side = Math.max(Math.abs(endX - startX), Math.abs(endY - startY));
                    currentShape.set({
                        width: side,
                        height: side,
                        left: endX < startX ? startX - side : startX,
                        top: endY < startY ? startY - side : startY,
                        visible: true, // Make it visible when dragging
                    });
                    break;
                case 'circle':
                    const radius = Math.hypot(endX - startX, endY - startY) / 2;
                    currentShape.set({
                        radius,
                        left: startX,
                        top: startY,
                        visible: true, // Make it visible when dragging
                    });
                    break;
                case 'oval':
                    currentShape.set({
                        rx: Math.abs(endX - startX) / 2,
                        ry: Math.abs(endY - startY) / 2,
                        left: startX < endX ? startX : endX,
                        top: startY < endY ? startY : endY,
                        visible: true, // Make it visible when dragging
                    });
                    break;
                case 'line': case 'dashed-line': case 'arrow': case 'dashed-arrow': {
                    if (moveStartSquareControl) {
                        const { x: endArrowX, y: endArrowY } = endPos;
                        const { line, triangle, type } = currentShape;
                        line.set({ x1: endX, y1: endY });

                        if (includes(type, "arrow")) {
                            const angle = Math.atan2(endArrowY - endY, endArrowX - endX) * (180 / Math.PI);
                            triangle.set({
                                left: endArrowX,
                                top: endArrowY,
                                angle: angle + 90,
                            });
                        }
                    } else {
                        const { line, triangle, type } = currentShape;
                        line.set({ x2: endX, y2: endY });

                        if (includes(type, "arrow")) {
                            const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI);
                            triangle.set({
                                left: endX,
                                top: endY,
                                angle: angle + 90,
                            });
                        }
                    }
                    break;
                }
                case 'wavy-line': case 'wavy-arrow': case 'dashed-wavy-line': case 'dashed-wavy-arrow':
                    {
                        if (moveStartSquareControl) {
                            const { x: endArrowX, y: endArrowY } = endPos;

                            const { line, triangle, type } = currentShape;
                            const { linePoints, trianglePoints } = calculateWavePoints(endX, endY, endArrowX, endArrowY, includes(type, "arrow"));

                            line.set({ points: linePoints });
                            if (trianglePoints) triangle.set({ points: trianglePoints });
                        } else {
                            const { line, triangle, type } = currentShape;
                            const { linePoints, trianglePoints } = calculateWavePoints(startX, startY, endX, endY, includes(type, "arrow"));

                            line.set({ points: linePoints });
                            if (trianglePoints) triangle.set({ points: trianglePoints });
                        }
                        break;
                    }
                default:
                    break;
            }
        }

        canvas.renderAll();
    }, [isDrawing, currentShape, canvas, selectedShape, startPos, endPos, moveStartSquareControl]);

    const handleMouseUp = useCallback((options) => {
        if (currentShape) {
            const MIN_SIZE = 5;
            const { width, height, rx, ry } = currentShape;
            const pointer = canvas.getPointer(options.e);

            const { x: startX, y: startY } = moveStartSquareControl ? pointer : startPos;
            const { x: endX, y: endY } = moveStartSquareControl ? endPos : pointer;

            const validShape =
                (selectedShape === 'circle' && currentShape.radius >= MIN_SIZE) ||
                (selectedShape === 'oval' && (rx >= MIN_SIZE && ry >= MIN_SIZE)) ||
                (["line", "dashed-line", "arrow", "dashed-arrow"].includes(selectedShape) && (Math.abs(currentShape?.line?.x2 - currentShape?.line?.x1) >= MIN_SIZE || Math.abs(currentShape?.line?.y2 - currentShape?.line?.y1) >= MIN_SIZE)) || (arrowLineList.includes(selectedShape) && size(calculateWavePoints(startX, startY, endX, endY, includes(selectedShape, "arrow"), true).linePoints) > 0) || (width >= MIN_SIZE && height >= MIN_SIZE);

            if (!validShape) {
                if (arrowLineList.includes(selectedShape)) {
                    const { line, triangle } = currentShape;
                    canvas.remove(line);
                    if (triangle) canvas.remove(triangle);
                } else {
                    canvas.remove(currentShape);
                }
            } else {
                if (["line", "dashed-line", "arrow", "dashed-arrow"].includes(selectedShape)) {
                    const { line, triangle, type } = currentShape;

                    // Calculate line length and angle
                    const x1 = line.x1, y1 = line.y1;
                    const x2 = line.x2, y2 = line.y2;

                    const dx = x2 - x1;
                    const dy = y2 - y1;
                    const length = Math.sqrt(dx * dx + dy * dy);
                    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

                    // Adjust the line and triangle's relative position before grouping
                    line.set({
                        x1: 0,
                        y1: 0,
                        x2: length, // Line length to match the calculated length
                        y2: 0, // Align the y-coordinate to be at the same level (y1)
                    });

                    let group = [line];

                    if (includes(type, "arrow")) {
                        triangle.set({
                            left: length, // Place the triangle at the end of the line
                            top: 0, // Align with the line's y-coordinate
                            angle: 90, // Align triangle correctly relative to the line
                        });
                        group = [line, triangle];
                    }
                    const groupObj = new fabric.Group(group, {
                        ...lineGroupProps,
                        left: x1,  // Start position of the line
                        top: y1,   // Start position of the line
                        angle: angle, // Set the rotation angle to match the line
                        customProps: { type, id: uuid(), color: line?.customProps?.color }
                    });
                    groupObj.setControlsVisibility(resizeShapeProps[selectedShape] ?? {});

                    // Replace the individual line and triangle with the group on the canvas
                    canvas.remove(line);
                    if (includes(type, "arrow")) canvas.remove(triangle);
                    canvas.add(groupObj);
                    canvas.setActiveObject(groupObj);
                } else if (includes(selectedShape, "wavy")) {
                    const { line, triangle, type } = currentShape;
                    const color = line?.customProps?.color;

                    const dx = endX - startX;
                    const dy = endY - startY;
                    const length = Math.sqrt(dx * dx + dy * dy);
                    const angle = Math.atan2(dy, dx) * (180 / Math.PI);

                    const { linePoints, trianglePoints } = calculateWavePoints(0, 0, length, 0, includes(type, "arrow"), true);

                    const line2 = new fabric.Polyline(linePoints, {
                        ...initialWavyProps,
                        ...(includes(type, "dashed") && { ...wavyDashedProps }),
                        stroke: colorCoding[color + "Figure"],
                    });

                    let group = [line2];

                    if (includes(selectedShape, "arrow") && linePoints.length > 0) {
                        const { taperLength, amplitude } = wavyConst;

                        const triangle2 = new fabric.Polygon(trianglePoints, {
                            ...polylineTriangle,
                            stroke: colorCoding[color + "Figure"],
                            fill: colorCoding[color + "Figure"],
                            left: length + taperLength,
                            top: -amplitude,
                            angle: 90,
                        });
                        group = [line2, triangle2];
                    }

                    const groupObj = new fabric.Group(group, {
                        ...lineGroupProps,
                        left: startX,
                        top: startY,
                        angle: angle,
                        customProps: { type, id: uuid(), color }
                    });
                    groupObj.setControlsVisibility(resizeShapeProps[selectedShape] ?? {});

                    // Replace the individual line and triangle with the group on the canvas
                    canvas.remove(line);
                    if (includes(type, "arrow")) canvas.remove(triangle);
                    canvas.add(groupObj);
                    canvas.setActiveObject(groupObj);
                } else {
                    currentShape.setCoords(); // Update the coordinates
                    currentShape.set({ customProps: { shape: selectedShape } })
                    currentShape.setControlsVisibility(resizeShapeProps[selectedShape] ?? {});
                    canvas.setActiveObject(currentShape);
                    canvas.renderAll();
                }
            }
            canvas.renderAll();
        }

        const pointer = canvas.getPointer(options.e);
        if (startPos && pointer) {
            const isSamePosition = startPos.x === pointer.x && startPos.y === pointer.y;
            if (isSamePosition) {
                setSelectedShape(null);
            }
        }

        setIsDrawing(false);
        setMoveStartSquareControl(false);
        setCurrentShape(null);

        if (!isDrawing) {
            const activeObject = options.target;
            const id = activeObject?.customProps?.id;
            if (id) {
                const customControls = canvas.getObjects().filter(obj => obj.customProps && (obj.customProps.type === 'start' || obj.customProps.type === 'end') && obj.customProps.id !== id);
                const shape = canvas.getObjects().find(obj => obj.customProps && arrowLineList.includes(obj.customProps.type) && obj.customProps.id === id);

                forEach(customControls, obj => canvas.remove(obj));

                if (arrowLineList.includes(activeObject.customProps?.type)) {
                    const startPoint = activeObject.getPointByOrigin('left', 'center');
                    const endPoint = activeObject.getPointByOrigin('right', 'center');

                    const startSquare = createSquareControl(startPoint.x, startPoint.y, 'start', id);
                    const endSquare = createSquareControl(endPoint.x, endPoint.y, 'end', id);

                    canvas.add(startSquare, endSquare);
                    canvas.bringForward(startSquare);
                    canvas.bringForward(endSquare);
                    canvas.sendBackwards(shape);
                }
            }
        }
    }, [canvas, currentShape, isDrawing, startPos]);

    const createSquareControl = useCallback((left, top, type, id) => {
        return new fabric.Rect({
            ...squareControlProps,
            left: left,
            top: top,
            customProps: { type, id }
        });
    }, []);

    const handleSelectOn = () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
            const id = activeObject.customProps?.id;
            const customControls = canvas.getObjects().filter(obj => obj.customProps && (obj.customProps.type === 'start' || obj.customProps.type === 'end') && !(id && obj.customProps.id !== id));
            forEach(customControls, obj => canvas.remove(obj));

            if (id) {
                setHeaderMode(3);

                const shape = canvas.getObjects().find(obj => obj.customProps && arrowLineList.includes(obj.customProps.type) && obj.customProps.id === id);

                if (arrowLineList.includes(activeObject.customProps?.type)) {
                    const startPoint = activeObject.getPointByOrigin('left', 'center');
                    const endPoint = activeObject.getPointByOrigin('right', 'center');

                    const startSquare = createSquareControl(startPoint.x, startPoint.y, 'start', id);
                    const endSquare = createSquareControl(endPoint.x, endPoint.y, 'end', id);

                    canvas.add(startSquare, endSquare);
                    canvas.bringForward(startSquare);
                    canvas.bringForward(endSquare);
                    canvas.sendBackwards(shape);
                    canvas.renderAll();
                }
            } else {
                if (activeObject?.customProps?.type == "text") {
                    const fontSize = activeObject.fontSize ?? 14;
                    setFontSize(fontSize)
                    setHeaderMode(5);
                } else if (activeObject?.customProps?.hasLabel) {
                    setHeaderMode(4);
                    setSelectedShapeLabel(activeObject.customProps.label);
                } else if (activeObject.customProps?.rotate) {
                    setHeaderMode(2);
                } else {
                    if (size(activeObject._objects) > 1) {
                        activeObject.set(initialGroupProps);
                        activeObject.setControlsVisibility(resizeShapeProps["group"] ?? {})
                        if (activeObject.customProps?.rotate) {
                            activeObject.setControlsVisibility({ mtr: true })
                        }
                        canvas.renderAll(); // Re-render the canvas to apply changes
                        setHeaderMode(3);
                    } else {
                        setHeaderMode(2);
                    }
                    if ([2, 3].includes(activeObject.customProps?.group)) {
                        setHeaderMode(2);
                    }
                }
            }

            if (canvas) {
                canvas.defaultCursor = 'crosshair';
            }
        }
    }

    const handleSelectOff = (options) => {
        setHeaderMode(1);
        setSelectedShapeLabel("");

        if (options?.deselected?.[0]?.customProps?.type == "text") {
            setFontSize(14);
            const activeObject = options.deselected[0];
            if (activeObject.text === '') {
                canvas.remove(activeObject);
                canvas.renderAll();
            }
        }


        const deselected = filter(options.deselected ?? [], o => arrowLineList.includes(o.customProps?.type));

        if (size(deselected) === 1) {
            const id = deselected[0].customProps.id;
            if (id) {
                const startSquare = filter(canvas.getObjects(), obj => obj.customProps && obj.customProps.type === 'start' && obj.customProps.id === id);
                const endSquare = filter(canvas.getObjects(), obj => obj.customProps && obj.customProps.type === 'end' && obj.customProps.id === id);

                forEach(startSquare, e => canvas.remove(e));
                forEach(endSquare, e => canvas.remove(e));
                canvas.renderAll();
            }
        }

        if (canvas) {
            canvas.defaultCursor = 'default';
        }
    }

    const handleObjectMoving = useCallback((e) => {
        const id = e.target?.customProps?.id;
        if (id) {
            const startSquare = canvas.getObjects().find(obj => obj.customProps && obj.customProps.type === 'start' && obj.customProps.id === id);
            const endSquare = canvas.getObjects().find(obj => obj.customProps && obj.customProps.type === 'end' && obj.customProps.id === id);

            if (startSquare && endSquare) {
                canvas.remove(startSquare);
                canvas.remove(endSquare);
                canvas.renderAll();
            }
        }
    }, [canvas]);

    const handleFigureDrop = (event) => {
        const figureSvg = {
            "ball": ball,
            "x-cone": x_cone,
            "cone": cone,
            "goal": goal,
            "x-goal": x_goal,
            "mannequin": mannequin,
            "ladder": ladder,
            "hurdle": hurdle,
            "slalom-pole": slalom_pole,
            "player": player,
            "goalkeeper": goalkeeper,
            "triangle-player": triangle_player,
            "circle-player": circle_player,
        }

        const rect = canvas.lowerCanvasEl.getBoundingClientRect();
        const x = event.detail.x - rect.left;
        const y = event.detail.y - rect.top;
        const figure = event.detail.figure;
        const color = event.detail.color;
        const coloringType = event.detail.coloringType;
        const hasLabel = event.detail.hasLabel;
        const labelColor = event.detail.labelColor;
        const defaultLabel = event.detail.defaultLabel;
        const fontSize = event.detail.fontSize;
        const hasShadow = event.detail.hasShadow;
        const group = event.detail.group;

        fabric.loadSVGFromURL(figureSvg[figure], (objects, options) => {
            const svg = fabric.util.groupSVGElements(objects, options);
            svg.set({
                ...initialFigureProps,
                left: x,
                top: y,
                scaleX: 1.25, scaleY: 1.25,
                originX: 'center',
                originY: 'center',
                ...(color && { [coloringType]: colorCoding[color + "Figure"] }),
                customProps: {
                    hasLabel, labelColor, defaultLabel, fontSize, hasShadow, group,
                    label: "",
                    rotate: true,
                    shape: "figures"
                }
            });
            if (svg?._objects) {
                forEach(svg._objects, obj => {
                    if (obj.fill === "inherite") {
                        obj.set({ fill: colorCoding[color + "Figure"] })
                    }
                })
            }
            svg.setControlsVisibility(resizeShapeProps.figures)
            canvas.setActiveObject(svg);
            canvas.add(svg);

            if (hasLabel && defaultLabel) {
                const textObj = new fabric.Text(defaultLabel, {
                    fontSize: fontSize,
                    fontWeight: 700,
                    fontFamily: "Poppins",
                    fill: labelColor,
                    left: svg.left,
                    top: svg.top,
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    customProps: { type: "text" },
                    ...(hasShadow && { shadow: new fabric.Shadow({ color: 'black', blur: 1, offsetY: 0.5, }) }),
                });
                const newGroup = new fabric.Group([svg, textObj], {
                    ...initialFigureProps,
                    left: svg.left,
                    top: svg.top,
                    originX: 'center',
                    originY: 'center',
                    selectable: true,
                    customProps: { ...svg.customProps, label: defaultLabel }
                });
                newGroup.setControlsVisibility(resizeShapeProps.figures)
                canvas.remove(svg);
                canvas.add(newGroup);
                canvas.setActiveObject(newGroup);
            }

            canvas.renderAll();
        });
    };

    const markCanvasDirty = () => setIsCanvasDirty(true);

    const handleTextInputAdd = (event) => {
        setHeaderMode(5);
        const rect = canvas.lowerCanvasEl.getBoundingClientRect();
        const x = event.detail.x - rect.left;
        const y = event.detail.y - rect.top;

        const minWidth = 100;

        const input = new fabric.IText('', {
            ...initialTextInputProps,
            left: x,
            top: y,
            fontSize,
            customProps: { type: "text", shape: "text_input" }
        });
        input.set({ width: minWidth });
        input.scaleX = minWidth / input.width;
        input.setControlsVisibility(resizeShapeProps.text_input)
        input.enterEditing();

        canvas.setActiveObject(input);
        canvas.add(input);
        canvas.renderAll();
    };

    useEffect(() => {
        if (canvas) {
            canvas.on('mouse:down', handleMouseDown);
            canvas.on('mouse:move', handleMouseMove);
            canvas.on('mouse:up', handleMouseUp);
            canvas.on('selection:cleared', handleSelectOff);
            canvas.on('selection:created', handleSelectOn);
            canvas.on('selection:updated', handleSelectOn);
            canvas.on('object:moving', handleObjectMoving);
            canvas.on('object:rotating', handleObjectMoving);
            window.addEventListener('figure:drop', handleFigureDrop);
            window.addEventListener('inputtext:add', handleTextInputAdd);

            // Listen to object added and modified events
            canvas.on('object:added', markCanvasDirty);
            canvas.on('object:modified', markCanvasDirty);
        }

        return () => {
            if (canvas) {
                canvas.off('mouse:down', handleMouseDown);
                canvas.off('mouse:move', handleMouseMove);
                canvas.off('mouse:up', handleMouseUp);
                canvas.off('selection:cleared', handleSelectOff);
                canvas.off('selection:created', handleSelectOn);
                canvas.off('selection:updated', handleSelectOn);
                canvas.off('object:moving', handleObjectMoving);
                canvas.off('object:rotating', handleObjectMoving);
                window.removeEventListener('figure:drop', handleFigureDrop);
                window.removeEventListener('inputtext:add', handleTextInputAdd);

                canvas.off('object:added', markCanvasDirty);
                canvas.off('object:modified', markCanvasDirty);
            }
        };
    }, [canvas, handleMouseDown, handleMouseMove, handleMouseUp, handleSelectOff, handleSelectOn, handleObjectMoving, handleFigureDrop, handleTextInputAdd]);

    return (
        <div className={styles['diagram-creator-canvas-outer']}>
            <div className={styles['diagram-creator-canvas-container']}>
                <div className={styles["diagram-creator-canvas"]} style={{ border: `2px dashed ${isInCanvas ? "#45d695" : (isDragging ? "#45d6954d" : "transparent")}` }}>
                    <div className={styles['canvas-area']}>
                        <canvas width={1000} height={563} id={"canvasId"} className={styles["canvas-board"]} ref={canvasRef} />
                    </div>
                </div>
            </div>
            <img style={{ display: "none" }} />
        </div>
    );
});

export default FootballField;
