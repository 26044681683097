import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";

import moment from "moment";

import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";

import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import { TFBTableCell } from "../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TFBPlaceholder from "../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import StatusUser from "./StatusUser";
import Adduser from "./Adduser";
import _ from "lodash";
import useAxiosGet from "../../components/customHooks/useAxiosGet";
import CircularLoader from "../../components/reusable/Loader";
import TFBPlayerWithAvatar from "../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import { isTFB } from "../../utils/utilFunctions";

const CenterCircularLoader = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularLoader />
    </div>
  );
};

const UserManagement = (props) => {
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [activeInactiveUserPopup, setActiveInactiveUserPopup] = useState(false);
  const [editUserPopup, setEditUserPopup] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [userDataToUpdate, setUserDataToUpdate] = useState("");
  const [idUserStatus, setIdUserStatus] = useState("");
  const [userIsActive, setUserIsActive] = useState("");
  const [idUserToUpdate, setIdUserToUpdate] = useState("");
  const [idUserToDelete, setIdUserToDelete] = useState("");

  const {
    data: usersData,
    loading: isUsersLoading,
    refetch: refetchUsers,
  } = useAxiosGet(
    `user/get_users_list_2?id_club=${props.currentUser.id_club}&id_user=${
      props.currentUser.id_user
    }&only_tfb=${isTFB() ? 1 : 0}&token=${props.currentUser.token}`
  );

  const { languagePack } = props;

  if (isUsersLoading)
    return (
      <TFBCard>
        <CenterCircularLoader />
      </TFBCard>
    );

  return (
    <TFBCard>
      <div className="shortlists-page-container">
        <TFBCardHeader title={languagePack.users_management}>
          <TFBButton
            color="green"
            endIcon={true}
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => setAddUserPopup(true)}
          >
            {languagePack.add_user}
          </TFBButton>
        </TFBCardHeader>

        {_.size(usersData) > 0 ? (
          <TFBCardBody>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell>{languagePack.User_name}</TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.account_creation_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.team_access}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.access_module}
                    </TFBTableCell>

                    <TFBTableCell align="center">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(_.cloneDeep(usersData), (row, index) => (
                    <TableRow
                      key={"user-management" + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell align="center">
                        <TFBPlayerWithAvatar playerName={row.full_name} />
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {moment(row.date_add).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack[row.team_access]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.data_for_edit.module_access == "full"
                          ? languagePack.full_access
                          : languagePack.some_modules}
                      </TFBTableCell>

                      <TFBTableCell align="center">
                        <div className="actions-btns-container">
                          <TFBIconButton
                            name={
                              row.active == "1"
                                ? "active-player"
                                : "inactive-player"
                            }
                            onClick={() => {
                              setActiveInactiveUserPopup(true);
                              setIdUserStatus(row.id_user);
                              setUserIsActive(row.active == "1" ? true : false);
                            }}
                            className="tfb-icon-button-no-filter"
                            color="darkGray"
                          />
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => {
                              setIdUserToUpdate(row.id_user);

                              setUserDataToUpdate(row.data_for_edit);
                              setEditUserPopup(true);
                            }}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => {
                              setDeleteUserPopup(true);
                              setIdUserToDelete(row.id_user);
                            }}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </TFBCardBody>
        ) : (
          <TFBPlaceholder text={languagePack.no_users} />
        )}
      </div>

      {addUserPopup && (
        <Adduser
          refreshUsers={refetchUsers}
          addUserPopup={addUserPopup}
          id_club={props.currentUser.id_club}
          id_user={props.currentUser.id_user}
          closePopup={() => setAddUserPopup(false)}
        />
      )}

      {editUserPopup && (
        <EditUser
          editUserPopup={editUserPopup}
          id_club={props.currentUser.id_club}
          dataToEdit={userDataToUpdate}
          refreshUsers={() => refetchUsers()}
          id_user_to_update={idUserToUpdate}
          token={props.currentUser.token}
          closePopup={() => {
            setEditUserPopup(false);
            setIdUserToUpdate(null);
            setUserDataToUpdate(null);
          }}
        />
      )}

      <DeleteUser
        deleteUserPopup={deleteUserPopup}
        refreshUsers={refetchUsers}
        id_user_to_delete={idUserToDelete}
        token={props.currentUser.token}
        closePopup={() => {
          setDeleteUserPopup(false);
          setIdUserToDelete("");
        }}
      />

      <Dialog open={activeInactiveUserPopup} maxWidth="sm" fullWidth>
        <DialogContent>
          <StatusUser
            refreshUsers={refetchUsers}
            id_user_status={idUserStatus}
            isActive={userIsActive}
            token={props.currentUser.token}
            languagePack={languagePack}
            closePopup={() => {
              setActiveInactiveUserPopup(false);
              setIdUserStatus("");
              setUserIsActive("");
            }}
          />
        </DialogContent>
      </Dialog>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(UserManagement);
