import React from "react";
import "./Campaigns.scss";
import { connect } from "react-redux";
import Datatable from "../../../components/reusable/Datatable/Datatable";
import { ReactComponent as EyeIcon } from "../../../assets/icons/eye-white.svg";
import { Icon, Button } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { Dropdown } from "semantic-ui-react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MailContent from "./MailContent/MailContent";
import recipientIcon from "./../../../assets/icons/recipients-icon.png";

class Campaigns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns_list: [
        [
          "Campaign 1",
          "e-mail",
          "sent",
          "Audience #1",
          "Last Edited",
          "8 (67%)",
          "1 (8.3%)",
          <div className="action-group">
            <div
              className="action-button"
              onClick={() => this.viewPopup("emailCampaign")}
            >
              <EyeIcon />
            </div>
            <div
              className="action-button"
              onClick={() => this.viewPopup("reportCampaign")}
            >
              <Icon name="list" className="list-campaign-icon" size="small" />
            </div>
            <div className="action-button">
              <Icon
                name="add"
                className="duplicate-campaign-icon"
                size="small"
              />
            </div>
          </div>,
        ],
        [
          "Campaign 1",
          "e-mail",
          "sent",
          "Audience #1",
          "Last Edited",
          "8 (67%)",
          "1 (8.3%)",
          <div className="action-group">
            <div
              className="action-button"
              onClick={() => this.viewPopup("emailCampaign")}
            >
              <EyeIcon />
            </div>
            <div
              className="action-button"
              onClick={() => this.viewPopup("reportCampaign")}
            >
              <Icon name="list" className="list-campaign-icon" size="small" />
            </div>
            <div className="action-button">
              <Icon
                name="add"
                className="duplicate-campaign-icon"
                size="small"
              />
            </div>
          </div>,
        ],
        [
          "Campaign 1",
          "e-mail",
          "sent",
          "Audience #1",
          "Last Edited",
          "8 (67%)",
          "1 (8.3%)",
          <div className="action-group">
            <div
              className="action-button"
              onClick={() => this.viewPopup("emailCampaign")}
            >
              <EyeIcon />
            </div>
            <div
              className="action-button"
              onClick={() => this.viewPopup("reportCampaign")}
            >
              <Icon name="list" className="list-campaign-icon" size="small" />
            </div>
            <div className="action-button">
              <Icon
                name="add"
                className="duplicate-campaign-icon"
                size="small"
              />
            </div>
          </div>,
        ],
      ],
      contactsMatch: [
        { key: 1, text: "Condition 1", value: "condition_1" },
        { key: 2, text: "Condition 2", value: "condition_2" },
      ],
      popUps: {
        createCampaign: false,
        viewCampaign: false,
        emailCampaign: false,
        whatsappCampaign: false,
        reportCampaign: false,
        scheduleCampaign: false,
        saveSegment: false,
      },
      activeCampaign: 0,
      userInput: {
        segmentName: "",
        emailCampaign: {
          audience: "audience_1",
          segment: "segment_1",
          contactsMatch: "any_condition_1",
          filterType: "date_added",
          standardCondition: "after",
          conditionSpecificity: "specific_date",
          inputDate: null,
          senderName: "",
          senderEmail: "",
          subjectEmail: "",
        },
      },
    };
  }

  closePopups = () => {
    let popUps = {};
    for (const [key, value] of Object.entries(this.state.popUps)) {
      popUps[key] = false;
    }
    this.setState({ popUps: popUps });
  };

  clearInputs = (value = null) => {
    let userInput = {};
    let clearObj = value || this.state.userInput;
    for (const [key, value] of Object.entries(clearObj)) {
      if (
        typeof clearObj[key] === "object" &&
        !Array.isArray(clearObj[key]) &&
        clearObj[key] !== null &&
        clearObj[key] !== undefined
      ) {
        userInput[key] = this.clearInputs(clearObj[key]);
      } else {
        if (value != null) {
          userInput[key] = "";
        }
      }
    }
    if (value != null) {
      return userInput;
    }
    this.setState({ userInput: userInput });
  };

  viewPopup = (popupName) => {
    this.closePopups(); // close all other popups
    this.clearInputs(); // clear inputs
    let popupsState = { ...this.state.popUps };
    popupsState[popupName] = true;
    this.setState({ popUps: popupsState });
  };

  saveSegment = () => {
    this.closePopups();
  };

  handleInputChange = (value, userInput) => {
    let newInputState = { ...this.state.userInput };
    if (newInputState.hasOwnProperty(userInput)) {
      newInputState[userInput] = value;
    } else {
      newInputState.emailCampaign[userInput] = value;
    }
    this.setState({ userInput: newInputState });
  };

  handleDropdownChange = (e, value, input) => {
    let userInput = this.state.userInput;
    userInput.emailCampaign[input] = value;
    this.setState({ userInput: userInput });
  };

  emailCampaignDate = (value) => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        emailCampaign: {
          ...this.state.userInput.emailCampaign,
          inputDate: value,
        },
      },
    });
  };

  render() {
    const { languagePack } = this.props;

    const audiences = [
      { key: 1, text: "Audience 1", value: "audience_1" },
      { key: 2, text: "Audience 2", value: "audience_2" },
    ];

    const segments = [
      { key: 1, text: "Segment 1", value: "segment_1" },
      { key: 2, text: "Segment 2", value: "segment_2" },
    ];

    const contactsMatchCondition = [
      { key: 1, text: "Any condition 1", value: "any_condition_1" },
      { key: 2, text: "Any condition 2", value: "any_condition_2" },
    ];

    const filterTypeList = [
      { key: 1, text: languagePack.campaign_date_added, value: "date_added" },
      { key: 2, text: languagePack.campaign_birthday, value: "birthday" },
      { key: 3, text: languagePack.campaign_gender, value: "gender" },
    ];

    const standardCondition = [
      { key: 1, text: languagePack.campaign_is_before, value: "before" },
      { key: 2, text: languagePack.campaign_is_after, value: "after" },
    ];

    const conditionSpecificity = [
      {
        key: 1,
        text: languagePack.campaign_specific_date,
        value: "specific_date",
      },
      { key: 2, text: languagePack.campaign_range, value: "range" },
    ];

    const topLinksList = [
      "www.loremipsumdolor1.com",
      "www.loremipsumdolor2.com",
      "www.loremipsumdolor3.com",
      "www.loremipsumdolor4.com",
      "www.loremipsumdolor5.com",
    ];

    return (
      <div className="campaigns-wrapper">
        <div className="campaigns-list-header">
          <div className="campaigns-list-title">
            {languagePack.campaign_title}
          </div>
        </div>
        <div className="campaigns-second-header">
          <Button
            className="period-filter"
            onClick={() => void 0}
            style={{ backgroundColor: "#666666", width: 160 }}
          >
            {languagePack.campaign_status_filter}
            <Icon name="filter" size="small" className="period-filter-icon" />
          </Button>
          <Button
            className="period-filter"
            onClick={() => void 0}
            style={{ backgroundColor: "#666666", width: 140 }}
          >
            {languagePack.campaign_type_filter}
            <Icon name="filter" size="small" className="period-filter-icon" />
          </Button>
        </div>
        <Datatable
          languagePack={languagePack}
          tableHeader={[
            {
              label: languagePack.campaign_name,
              slug: "campaign-name",
              width: "20%",
            },
            { label: languagePack.campaign_type, slug: "type", width: "10%" },
            {
              label: languagePack.campaign_status,
              slug: "status",
              width: "10%",
            },
            {
              label: languagePack.campaign_audience,
              slug: "audience",
              width: "10%",
            },
            {
              label: languagePack.campaign_last_edited,
              slug: "last-edited",
              width: "10%",
            },
            { label: languagePack.campaign_opens, slug: "opens", width: "10%" },
            {
              label: languagePack.campaign_clicks,
              slug: "clicks",
              width: "10%",
            },
            { label: "", slug: "controls", width: "20%" },
          ]}
          contentData={this.state.campaigns_list}
        ></Datatable>
        {/* View Campaign content */}
        <Dialog
          open={this.state.popUps.viewCampaign}
          // onClose={() => this.closePopups()}
        >
          <DialogContent></DialogContent>
        </Dialog>
        {/* Create campaign */}
        {/* Edit Email Campaign */}
        <Dialog
          open={this.state.popUps.emailCampaign}
          // onClose={() => this.closePopups()}
          className="editEmailCampaign-wrapper"
        >
          <DialogContent className="open-dialog editEmailCampaign">
            <CloseIcon
              name="close"
              className="close-dialog"
              onClick={() => this.closePopups()}
              fontSize="large"
            />
            <div className="campaign-name">
              {languagePack.email_campaign}: Campaign name #1
            </div>
            <div className="popup-body">
              <div className="subtitle">
                {languagePack.campaign_your_audience}
              </div>
              <div className="section">
                {languagePack.campaign_choose_audience}
              </div>
              <div className="group">
                <Dropdown
                  value={
                    this.state.userInput.emailCampaign.audience != ""
                      ? this.state.userInput.emailCampaign.audience
                      : audiences[0].value
                  }
                  onChange={(e, value) =>
                    this.handleDropdownChange(e, value.value, "audience")
                  }
                  options={audiences}
                />
                <Dropdown
                  value={
                    this.state.userInput.emailCampaign.segment != ""
                      ? this.state.userInput.emailCampaign.segment
                      : segments[0].value
                  }
                  onChange={(e, value) =>
                    this.handleDropdownChange(e, value.value, "segment")
                  }
                  options={segments}
                />
              </div>
              <div className="section">{languagePack.campaign_new_segment}</div>
              <div className="description">
                {languagePack.campaign_contact_must_match}:
              </div>
              <Dropdown
                value={
                  this.state.userInput.emailCampaign.contactsMatch != ""
                    ? this.state.userInput.emailCampaign.contactsMatch
                    : contactsMatchCondition[0].value
                }
                onChange={(e, value) =>
                  this.handleDropdownChange(e, value.value, "contactsMatch")
                }
                options={contactsMatchCondition}
              />
              <div className="group">
                <Dropdown
                  value={
                    this.state.userInput.emailCampaign.filterType != ""
                      ? this.state.userInput.emailCampaign.filterType
                      : filterTypeList[0].value
                  }
                  onChange={(e, value) =>
                    this.handleDropdownChange(e, value.value, "filterType")
                  }
                  options={filterTypeList}
                />
                <Dropdown
                  value={
                    this.state.userInput.emailCampaign.standardCondition != ""
                      ? this.state.userInput.emailCampaign.standardCondition
                      : standardCondition[0].value
                  }
                  onChange={(e, value) =>
                    this.handleDropdownChange(
                      e,
                      value.value,
                      "standardCondition"
                    )
                  }
                  options={standardCondition}
                />
                <Dropdown
                  value={
                    this.state.userInput.emailCampaign.conditionSpecificity !=
                    ""
                      ? this.state.userInput.emailCampaign.conditionSpecificity
                      : conditionSpecificity[0].value
                  }
                  onChange={(e, value) =>
                    this.handleDropdownChange(
                      e,
                      value.value,
                      "conditionSpecificity"
                    )
                  }
                  options={conditionSpecificity}
                />

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={this.state.userInput.emailCampaign.inputDate}
                    onChange={this.emailCampaignDate}
                    emptyLabel={"Choose date"}
                    format="dd MMMM yyyy"
                    className="add-player-date-input"
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="group segment-controls">
                <Button
                  style={{ backgroundColor: "#666666", width: "auto" }}
                  onClick={this.closePopups}
                >
                  {languagePack.campaign_add_new_condition}
                  <Icon name="add" className="add-action-icon" />
                </Button>
                <Button
                  style={{ width: 200, backgroundColor: "#00d948" }}
                  onClick={this.closePopups}
                >
                  {languagePack.campaign_save_segment}
                  <Icon name="checkmark" className="save-segment-icon" />
                </Button>
              </div>
              <div className="subtitle">{languagePack.campaign_sender}</div>
              <div className="group">
                <input
                  type="text"
                  className={"dialog-input"}
                  placeholder={languagePack.campaign_name}
                  value={this.state.userInput.emailCampaign.senderName}
                  onChange={(e) => {
                    this.handleInputChange(e.target.value, "senderName");
                  }}
                />
                <input
                  type="text"
                  className={"dialog-input"}
                  placeholder={"E-mail"}
                  value={this.state.userInput.emailCampaign.senderEmail}
                  onChange={(e) => {
                    this.handleInputChange(e.target.value, "senderEmail");
                  }}
                />
              </div>
              <div className="section">{languagePack.campaign_subject}</div>
              <input
                type="text"
                className={"dialog-input"}
                placeholder={languagePack.campaign_enter_subject}
                value={this.state.userInput.emailCampaign.subjectEmail}
                onChange={(e) => {
                  this.handleInputChange(e.target.value, "subjectEmail");
                }}
              />
              <div className="subtitle">{languagePack.campaign_content}</div>
              <MailContent />
              <div className="subtitle">
                {languagePack.campaign_schedule_campaign}
              </div>
              <div className="group email-campaign-final-controls extend">
                <Button
                  style={{ width: "32%", backgroundColor: "#00d948" }}
                  onClick={this.closePopups}
                >
                  {languagePack.campaign_schedule_campaign}
                  <Icon name="arrow right" className="save-segment-icon" />
                </Button>
                <Button
                  style={{ width: "32%", backgroundColor: "#666666" }}
                  onClick={this.closePopups}
                >
                  {languagePack.campaign_send_later}
                  <Icon name="arrow right" className="save-segment-icon" />
                </Button>
                <Button
                  style={{ width: "32%", backgroundColor: "#d4d4d4" }}
                  onClick={this.closePopups}
                >
                  {languagePack.campaign_send_test_message}
                  <Icon name="arrow right" className="save-segment-icon" />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* Edit Whatsapp Campaign */}
        {/* Campaign report */}
        <Dialog
          open={this.state.popUps.reportCampaign}
          // onClose={() => this.closePopups()}
          className="reportCampaign-wrapper"
        >
          <DialogContent className="open-dialog">
            <CloseIcon
              name="close"
              className="close-dialog"
              onClick={() => this.closePopups()}
              fontSize="large"
            />
            <div className="report-title-wrapper">
              <div className="dialog-main-tite">
                <div className="report-title">
                  {languagePack.campaign_report}: Campaign name #1
                </div>
              </div>
              <div className="dialog-main-controls">
                <Button onClick={this.closePopups}>
                  {languagePack.campaign_view_email}
                  <EyeIcon className="eye-icon" />
                </Button>
                <Button onClick={this.closePopups}>
                  {languagePack.campaign_download}
                  <Icon name="arrow down" />
                </Button>
                <Button onClick={this.closePopups}>
                  {languagePack.campaign_print}
                  <Icon name="print" />
                </Button>
              </div>
            </div>
            <div className="report-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="card card-faded">
                    <div className="section">
                      {languagePack.campaign_recipients}
                    </div>
                    <div className="recipients-header">
                      <img src={recipientIcon} alt="recipient-icon.png" />
                      <div className="recipients-summary">
                        <div className="description">
                          {languagePack.campaign_total_recipients}
                        </div>
                        152
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div className="description">
                      {languagePack.campaign_audience}
                    </div>
                    <div className="subtitle">Audience #1</div>
                    <div className="description">
                      {languagePack.campaign_subject}
                    </div>
                    <div className="subtitle">My subject lorem ipsum</div>
                    <div className="description">
                      {languagePack.campaign_delivered}
                    </div>
                    <div className="subtitle last">Thu, May 27th, 8:25 AM</div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card card-faded">
                    <div className="section">
                      {languagePack.campaign_top_links}
                    </div>
                    <div className="top-links-wrapper">
                      {topLinksList.map((value, index) => {
                        return (
                          <div key={index} className="top-links-item">
                            <span>{index + 1}.</span>
                            {value}
                          </div>
                        );
                      })}
                    </div>
                    <Button className="view-links" onClick={this.closePopups}>
                      {languagePack.campaign_view_all_links}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="row quick-stats">
                <div className="col-lg-3">
                  <div className="card card-faded">
                    <div className="campaign-number">8</div>
                    <div className="description">
                      {languagePack.campaign_total_opens}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card card-faded">
                    <div className="campaign-number">1</div>
                    <div className="description">
                      {languagePack.campaign_total_clicked}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card card-faded">
                    <div className="campaign-number">0</div>
                    <div className="description">
                      {languagePack.campaign_total_bounced}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="card card-faded">
                    <div className="campaign-number">0</div>
                    <div className="description">
                      {languagePack.campaign_total_unsubscribed}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row detailed-stats">
                <div className="col-lg-6">
                  <div className="card card-faded">
                    <div className="section">
                      {languagePack.campaign_numbers_stats}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="description">
                          {languagePack.campaign_successful_deliveries}
                        </div>
                        <div className="subtitle">12 (100%)</div>
                        <div className="description">
                          {languagePack.campaign_total_opens}
                        </div>
                        <div className="subtitle">10</div>
                        <div className="description">
                          {languagePack.campaign_last_opened}
                        </div>
                        <div className="subtitle">5/27/21 4:41AM</div>
                        <div className="description">
                          {languagePack.campaign_forwarded}
                        </div>
                        <div className="subtitle last">0</div>
                      </div>
                      <div className="col-lg-6">
                        <div className="description">
                          {languagePack.campaign_clicks_per_unique_opens}
                        </div>
                        <div className="subtitle">12.5%</div>
                        <div className="description">
                          {languagePack.campaign_total_clicks}
                        </div>
                        <div className="subtitle">1</div>
                        <div className="description">
                          {languagePack.campaign_last_clicked}
                        </div>
                        <div className="subtitle">5/20/21 2:18AM</div>
                        <div className="description">
                          {languagePack.campaign_abuse_reports}
                        </div>
                        <div className="subtitle last">0</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card card-faded">
                    <div className="section">
                      {languagePack.campaign_subscribers_most_opens}
                    </div>
                    <div className="top-links-wrapper">
                      {topLinksList.map((value, index) => {
                        return (
                          <div key={index} className="top-links-item">
                            <span>{index + 1}.</span>
                            {value}
                          </div>
                        );
                      })}
                    </div>
                    <Button className="view-links" onClick={this.closePopups}>
                      {languagePack.campaign_view_all}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* Schedule Campaign */}
        {/* Save segment */}
        <Dialog
          open={this.state.popUps.saveSegment}
          // onClose={() => this.closePopups()}
        >
          <DialogContent className="open-dialog">
            <CloseIcon
              name="close"
              className="close-dialog"
              onClick={() => this.closePopups()}
              fontSize="large"
            />
            <div className="dialog-title">Save segment</div>
            <input
              type="text"
              className={"dialog-input"}
              placeholder={"Segment name"}
              value={this.state.userInput.segmentName}
              onChange={(e) => {
                this.handleInputChange(e.target.value, "segmentName");
              }}
            />
            <div className="dialog-controls">
              <Button
                style={{ backgroundColor: "#666666", width: 230 }}
                onClick={() => this.closePopups()}
              >
                Cancel
                <Icon name="cancel" className="cancel-action-icon" />
              </Button>
              <Button
                style={{ width: 230, backgroundColor: "#00d948" }}
                onClick={() => this.saveSegment()}
              >
                Save
                <Icon name="checkmark" className="save-segment-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Campaigns);
