import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

const DeletePlayerFromDocument = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, document } = props;

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("parentsApp/delete_player_from_document_to_sign");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.mobile_app,
                languagePack.success_delete_document
            );
        } else {
            showNotification(
                "danger",
                languagePack.mobile_app,
                languagePack.fail_delete_document
            );
        }
    };

    const deleteDocument = () => {
        const payload = {
            id_document: document.id_document,
            id_player: document.id_player,
        };
        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteDocument(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_player_document_to_sign"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_player_document_to_sign_message}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(DeletePlayerFromDocument);
