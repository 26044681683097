import React from "react";
import { IconProps } from "../types";
import styles from "./TFBIcon.module.scss";

const TFBIcon = ({
  name,
  color = "white",
  size,
  className,
  style,
  onClick,
  spinningIcon = false,
}: IconProps) => {
  const cssColor = color;
  const iconPath = require(`../../../assets/icons/design-system/${name}.svg`);

  return (
    <img
      onClick={onClick}
      src={iconPath}
      className={`${className ? className : ""} ${styles[cssColor]} ${spinningIcon ? styles.spinningIcon : ""}`}
      alt={`${name} icon`}
      style={style}
    />
  );
};

export default TFBIcon;
