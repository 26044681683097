import React, { Component } from "react";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import { Avatar } from "@material-ui/core/";
import pitch from "../../assets/pitch.png";
import { baseUrl } from "../../utils/utilFunctions";

const general_fields = [
  { label: "Position", key: "main_position" },
  { label: "Secondary position", key: "secondary_positions" },
  { label: "League", key: "league_name" },
  { label: "Nationality", key: "nationality" },
  { label: "Foot", key: "player_foot" },
  { label: "Age", key: "age" }, //de adaugat  bck
  { label: "Height", key: "player_height" },
  { label: "Minutes played", key: "minutes_played" }, //de adaugat bck
];

class PlayerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generalData: {},
    };
  }

  componentDidMount = () => {
    this.getGeneralData();
  };

  getGeneralData = () => {
    axios
      .get(
        `transferlab/player_profile_general?id_player=${this.props.id_player}`
      )
      .then((res) => this.setState({ generalData: res.data }))
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="player-profile-scouting-container">
        <div className="pp-header">
          <div className="pl-name">{this.state.generalData.player_name}</div>
          <Button className="save-shortlist-button">
            Save shortlist
            <Icon name="add" size="small" className="shortlist-button-icon" />
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="general-data-column">
            <div className="avatar-row">
              <Avatar
                src={baseUrl("uploads/placeholder_player.png")}
                className="player-avatar"
              />
            </div>
            <div className="player-name-profile">
              Player profile: {this.state.generalData["player_name"]}
            </div>
            <div className="badges-row">
              <div
                className="badge-container"
                style={{ borderRight: "solid 1px #ffffff" }}
              >
                <img
                  src={baseUrl("uploads/placeholder_team.png")}
                  alt="team-badge"
                />
                <div className="badge-label">Club:</div>
                <div className="badge-value">
                  {this.state.generalData["team_name"]}
                </div>
              </div>
              <div className="badge-container" style={{ paddingLeft: 25 }}>
                <img
                  src={baseUrl("uploads/placeholder_country.png")}
                  alt="country-badge"
                />
                <div className="badge-label">Nationality:</div>
                <div className="badge-value">
                  {this.state.generalData["nationality"]}
                </div>
              </div>
            </div>
            <div className="details-list">
              {general_fields.map((item, idx) => (
                <div className="detail-row" key={idx}>
                  <div className="det-label">{item.label}:</div>
                  <div className="det-value">
                    {this.state.generalData[item.key]}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="central-column">
            <div className="cc-title">Metrics</div>
          </div>
          <div className="general-data-column">
            <div className="player-name-profile">Position chart</div>
            <img src={pitch} alt="pitch" className="positions-pitch" />
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerProfile;
