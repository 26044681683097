import React from "react";
import { connect } from "react-redux";
import axios from "axios";

class MatchLineups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players_home: {
        starting11: [],
        substitutes: [],
      },
      players_away: {
        starting11: [],
        substitutes: [],
      },
      team_home: "",
      team_away: "",
    };
  }

  componentDidMount = () => {
    this.getPlayers();
  };

  getPlayers = () => {
    axios
      .get(`instat/match_lineups?id_match=${this.props.id_match}`)
      .then((res) => {
        // this.setState({
        //   players_home: res.data.home,
        //   players_away: res.data.away,
        //   team_home: res.data.team_home,
        //   team_away: res.data.team_away,
        //   team_home_id: res.data.team_home_id,
        //   team_away_id: res.data.team_away_id,
        // });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom" style={{ marginTop: 25 }}>
        <div className="match-lineup-container">
          <div className="match-lineup-title">
            {languagePack["starting_lineup"]}
          </div>
          <div className="match-lineup-details-container">
            <div
              className="details-team-container"
              style={{ justifyContent: "flex-start" }}
            >
              <div className="logo-container" style={{ marginRight: 20 }}>
                <img
                  src={`https://instatscout.com/images/teams/180/${this.state.team_home_id}.png`}
                />
              </div>
              <div className="team-name">{this.state.team_home}</div>
            </div>
            <div className="vs">vs</div>
            <div
              className="details-team-container"
              style={{ justifyContent: "flex-end" }}
            >
              <div className="team-name">{this.state.team_away}</div>
              <div className="logo-container" style={{ marginLeft: 20 }}>
                <img
                  src={`https://instatscout.com/images/teams/180/${this.state.team_away_id}.png`}
                />
              </div>
            </div>
          </div>
          <div className="match-lineup-list">
            <div style={{ width: "50%" }}>
              {this.state.players_home.starting11 && this.state.players_home.starting11.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"
                    // style={{}}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      justifyContent: "flex-start",
                      paddingLeft: 25,
                    }}
                  >
                    <div className="number" style={{ marginRight: 20 }}>
                      {item.player_number}
                    </div>
                    <div className="name">{item.player_name}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "50%" }}>
              {this.state.players_home.starting11 && this.state.players_away.starting11.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      justifyContent: "flex-end",
                      paddingRight: 25,
                    }}
                  >
                    <div className="name">{item.player_name}</div>
                    <div className="number" style={{ marginLeft: 20 }}>
                      {item.player_number}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="substitutes-title">Rezerve </div>
          <div className="match-lineup-list">
            <div style={{ width: "50%" }}>
              {" "}
              {this.state.players_home.substitutes.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"
                    // style={{}}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      justifyContent: "flex-start",
                      paddingLeft: 25,
                    }}
                  >
                    <div className="number" style={{ marginRight: 20 }}>
                      {item.player_number}
                    </div>
                    <div className="name">{item.player_name}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "50%" }}>
              {" "}
              {this.state.players_away.substitutes.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      justifyContent: "flex-end",
                      paddingRight: 25,
                    }}
                  >
                    <div className="name">{item.player_name}</div>
                    <div className="number" style={{ marginLeft: 20 }}>
                      {item.player_number}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchLineups);
