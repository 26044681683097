import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import styles from "./Evaluation.module.scss";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";

import FadeInOut from "../../../components/design-system/FadeInOut/FadeInOut";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import CircularLoader from "../../../components/reusable/Loader";

import EvaluationTable from "./EvaluationTable";
import CategoryList from "./CategoryList";
import AddCategory from "./AddCategory";

const AddEvaluation = (props) => {

    const history = useHistory();

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            step: 0,

            //step 1
            evaluation_name: "",
            evaluation_description: "",
            evaluation_start_date: null,
            evaluation_end_date: null,
            evaluator: null,
            show_in_mobile_app: 1,

            //step 2
            teams: [{ team: null }],
            load_players_for_team: null,

            //step 3
            categories: [{ category: null }],
            load_params_for_category: null,

            //step 4
            table_params: [],
            table_players: [],

            data: [],

            open_category_list: false,
            open_add_category: false,
        }
    );

    const { languagePack } = props;
    const { id_club } = props.currentUser;

    const {
        data: staffMembers,
    } = useAxiosGet(`finances/get_staff_member_list?id_club=${id_club}&type=select`);

    const {
        data: teams,
    } = useAxiosGet(`club/get_teams_nomenclature?id_club=${id_club}&type=select&all=0`);

    const {
        data: players,
        refetch: refetchPlayers,
    } = useAxiosGet(`club/get_current_team_players?id_team=${state.load_players_for_team}&type=select&inactive=0`, true);

    const {
        data: categories,
        refetch: refetchCategories,
    } = useAxiosGet(`report/get_evaluation_categories_list?id_club=${id_club}&type=select`);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("report/add_evaluation");

    useEffect(() => {
        if (state.load_players_for_team > 0) {
            refetchPlayers();
        }
    }, [state.load_players_for_team])

    useEffect(() => {
        if (players?.success == 1) {
            const id_team = players?.id_team;
            const cloned_teams = _.clone(state.teams);
            const team_index = _.findIndex(cloned_teams, e => e?.team?.value == id_team);
            if (team_index > -1) {
                cloned_teams[team_index].players = _.map(players?.list ?? [], e => ({ ...e, checked: true }));
                cloned_teams[team_index].is_loading_players = false;
                updateState({ teams: cloned_teams })
            }
        }
    }, [players])

    useEffect(() => {
        //handle categories refetech
        if (categories?.list) {
            //check if selected categories are in the new ones list
            let cloned_categories = _.filter(_.clone(state.categories), c => {
                return _.indexOf(_.map(categories.list, c => c.value), c?.category?.value) > -1;
            });

            //foreach category update params list
            cloned_categories = _.map(cloned_categories, c => {
                const new_category = _.find(categories.list, c_new => c_new.value == c?.category?.value);
                if (new_category) {
                    const old_params = c.params;
                    const new_params = _.map(new_category.children, p => {
                        //check if new param is in old params
                        const old_param = _.find(old_params, old_p => old_p.value == p.value);
                        if (old_param) {
                            return ({ ...p, checked: old_param.checked })
                        }
                        return ({ ...p, checked: false })
                    })
                    return ({ ...c, category: { ...c.category, label: new_category.label }, params: new_params });
                }
                return c;
            })
            updateState({ categories: _.size(cloned_categories) > 0 ? cloned_categories : [{ category: null }] });
        }
    }, [categories])

    const nextStep = () => {
        const next_step = state.step + 1;
        if (next_step < 5) {
            updateState({ step: next_step });
        }
        if (next_step == 3) {
            generateEvaluationTable();
        }
        if (next_step == 4) {
            generateReport();
        }
    }

    const isValidNextStep = () => {
        if (state.step == 0) {
            return _.size(_.trim(state.evaluation_name)) > 0 && state.evaluation_start_date != null && state.evaluation_end_date != null && state.evaluator != null;
        }
        if (state.step == 1) {
            return _.size(_.filter(_.reduce(_.map(state.categories, e => e.params), (result, value, key) => {
                result = _.union(result, _.isArray(value) ? value : []);
                return result;
            }, []), e => e.checked)) > 0;
        }
        if (state.step == 2) {
            return _.size(_.filter(_.reduce(_.map(state.teams, e => e.players), (result, value, key) => {
                result = _.union(result, _.isArray(value) ? value : []);
                return result;
            }, []), e => e.checked)) > 0;
        }
        return true;
    }

    const previousStep = () => {
        updateState({ step: state.step - 1 });
    }

    const openCategoryList = () => {
        updateState({ open_category_list: true })
    }

    const closeCategoryList = () => {
        updateState({ open_category_list: false })
    }

    const openAddCategory = () => {
        updateState({ open_add_category: true })
    }

    const closeAddCategory = () => {
        updateState({ open_add_category: false })
    }

    const handleSelectTeam = (index, selected) => {
        const cloned_teams = _.clone(state.teams);
        cloned_teams[index].team = selected;
        cloned_teams[index].is_loading_players = true;
        updateState({ teams: cloned_teams, load_players_for_team: selected?.value ?? null })
    }

    const handleSelectCategory = (index, selected) => {
        const cloned_categories = _.clone(state.categories);
        if (selected) {
            const { children, ...parent } = selected;
            cloned_categories[index].category = parent;
            cloned_categories[index].params = _.map(children ?? [], e => ({ ...e, checked: true }));

            updateState({ categories: cloned_categories })
        }
    }

    const addTeam = () => {
        let cloned_teams = _.clone(state.teams);
        cloned_teams = _.union(cloned_teams, [{ team: null }]);
        updateState({ teams: cloned_teams })
    }

    const addCategory = () => {
        let cloned_categories = _.clone(state.categories);
        cloned_categories = _.union(cloned_categories, [{ category: null }]);
        updateState({ categories: cloned_categories })
    }

    const deleteTeam = (index) => {
        let cloned_teams = _.clone(state.teams);
        cloned_teams.splice(index, 1);
        updateState({ teams: cloned_teams })
    }

    const deleteCategory = (index) => {
        let cloned_categories = _.clone(state.categories);
        cloned_categories.splice(index, 1);
        updateState({ categories: cloned_categories })
    }

    const getTeamOptions = () => {
        return _.filter(teams?.list ?? [], e => (_.findIndex(state.teams, v => v?.team?.value == e.value)) == -1);
    }

    const getCategoryOptions = () => {
        return _.filter(categories?.list ?? [], e => (_.findIndex(state.categories, v => v?.category?.value == e.value)) == -1);
    }

    const handleChangePlayerCheckbox = (e, indexTeam, indexPlayer = "all") => {
        let cloned_teams = _.clone(state.teams);

        if (indexPlayer == "all") {
            cloned_teams[indexTeam].players = _.map(cloned_teams[indexTeam]?.players, p => ({ ...p, checked: e.target.checked }));
        } else {
            cloned_teams[indexTeam].players[indexPlayer].checked = e.target.checked;
        }
        updateState({ teams: cloned_teams })
    }

    const handleChangeParamCheckbox = (e, indexCategory, indexParam = "all") => {
        let cloned_categories = _.clone(state.categories);

        if (indexParam == "all") {
            cloned_categories[indexCategory].params = _.map(cloned_categories[indexCategory]?.params, p => ({ ...p, checked: e.target.checked }));
        } else {
            cloned_categories[indexCategory].params[indexParam].checked = e.target.checked;
        }
        updateState({ categories: cloned_categories })
    }

    const generateEvaluationTable = () => {
        let params = [];
        _.forEach(state.categories, c => {
            const selected_params = _.filter(c.params, i => i.checked);

            if (_.size(selected_params) > 0) {
                params = _.union(params, _.map(selected_params, p => ({ value: p.value, label: c.category.label + " : " + p.label })))
            }
        })

        let players = [];

        _.forEach(state.teams, t => {
            const selected_players = _.filter(t.players, i => i.checked);

            const test_result = _.reduce(params, (result, value, key) => {
                return ({ ...result, [value.value]: { ...value, grade: "", description: "" } })
            }, {});

            if (_.size(selected_players) > 0) {
                players = _.union(players, _.map(selected_players, p => {
                    const result = _.cloneDeep(test_result);
                    const player_previous_result = _.find(state.data, e => e.value == p.value);
                    if (player_previous_result) {
                        const test_result_keys = _.keys(result);
                        _.forEach(test_result_keys, key => {
                            if (player_previous_result.test_result?.[key]) {
                                result[key] = player_previous_result.test_result[key];
                            }
                        })
                    }

                    return ({ ...p, team_name: t?.team?.label, id_team: t?.team?.value, test_result: result })
                }))
            }
        })

        updateState({ table_players: players, table_params: params, data: players })
    }

    const handleResponse = (response) => {
        if (response?.success == 1) {
            history.push("/reports/evaluation");
            showNotification(
                "success",
                languagePack.players_evaluations,
                languagePack.success_add_evaluation
            );
        } else {
            showNotification(
                "danger",
                languagePack.players_evaluations,
                languagePack.fail_add_evaluation
            );
        }
    };

    const generateReport = () => {
        const payload = {
            evaluation_data: {
                id_club: id_club,
                evaluation_test_name: state.evaluation_name,
                evaluation_description: state.evaluation_description,
                id_evaluator: state.evaluator?.value ?? null,
                evaluation_test_start: state.evaluation_start_date,
                evaluation_test_end: state.evaluation_end_date,
                show_in_mobile_app: state.show_in_mobile_app,
            },
            result_data: _.reduce(_.groupBy(state.data, "id_team"), (result, value, key) => {
                return _.union(result, [{ id_team: key, players: _.map(value, p => ({ ...p, test_result: _.map(_.values(p.test_result), r => ({ id_evaluation_parameter: r.value, id_player: p.value, test_grade: r.grade, description: r.description })) })) }])
            }, []),
        };

        addRequest(payload, handleResponse);
    }

    const step1 = <FadeInOut show={state.step == 0}>
        <div className={styles.stepTitle}>{`${languagePack.step} 1: ${languagePack.evaluation_info}`}</div>
        <div className={styles.documentTypeContainer}>
            <TFBInput
                label={languagePack.evaluation_name}
                value={state.evaluation_name}
                onChange={(e) => updateState({ evaluation_name: e.target.value })}
                style={{ width: "100%" }}
            />
        </div>
        <div className={styles.documentTypeContainer}>
            <TFBTextArea
                label={languagePack.staff_description}
                value={state.evaluation_description}
                onChange={(e) => updateState({ evaluation_description: e.target.value })}
                style={{ width: "100%" }}
            />
        </div>
        <div className={styles.documentTypeContainer}>
            <div className={styles.documentTypeList}>
                <TFBDatePicker
                    value={state.evaluation_start_date}
                    onChange={(value) => updateState({ evaluation_start_date: value })}
                    placeholder={languagePack["starting_date"]}
                    label={languagePack["starting_date"]}
                    style={{ width: "100%" }}
                />
                <TFBDatePicker
                    value={state.evaluation_end_date}
                    onChange={(value) => updateState({ evaluation_end_date: value })}
                    placeholder={languagePack["ending_date"]}
                    label={languagePack["ending_date"]}
                    style={{ width: "100%" }}
                />
                <TFBSelect
                    label={languagePack.select_evaluator}
                    placeholder={languagePack.select_evaluator}
                    value={state.evaluator}
                    options={staffMembers?.list ?? []}
                    onChange={(selected) => updateState({ evaluator: selected })}
                />
            </div>
        </div>
        <div className={styles.documentTypeContainer}>
            <div className={styles.title}>{languagePack.visible_in_mobile_app + ":"}</div>
            <div className={styles.documentTypeList}>
                <TFBCheckbox
                    checked={state.show_in_mobile_app == 1}
                    label={languagePack.yes}
                    onChange={(e) => updateState({ show_in_mobile_app: 1 })}
                />
                <TFBCheckbox
                    checked={state.show_in_mobile_app == 0}
                    label={languagePack.no}
                    onChange={(e) => updateState({ show_in_mobile_app: 0 })}
                />
            </div>
        </div>
    </FadeInOut>

    const step2 = <FadeInOut show={state.step == 1}>
        <div className={styles.stepTitle}>{`${languagePack.step} 2: ${languagePack.select_evaluation_params}`}</div>
        <div className={`${styles.teamsContainer} df df-gap-10 flex-row`}>
            <TFBButton
                color="darkGray"
                onClick={openCategoryList}
            >
                {languagePack.category_list}
            </TFBButton>
            <TFBButton
                color="green"
                onClick={openAddCategory}
                renderIcon={() => <TFBIcon name="add" />}
            >
                {languagePack.add_new_category}
            </TFBButton>
        </div>
        <div className={styles.teamsContainer}>
            {_.map(state.categories, (value, key) => (
                <div key={"category-" + key} className={styles.teamContainer}>
                    <div className="df df-g20 df-align-end">
                        <TFBSelect
                            label={languagePack.category}
                            placeholder={languagePack.select_category}
                            value={value?.category ?? null}
                            options={getCategoryOptions()}
                            onChange={(selected) => handleSelectCategory(key, selected)}
                            style={{ component: { width: "300px" } }}
                        />
                        {_.size(state.categories) > 1 &&
                            <TFBIconButton
                                name="x-large"
                                color="red"
                                onClick={() => deleteCategory(key)}
                            />
                        }
                    </div>
                    <div className={styles.playersContainer}>
                        {value?.is_loading_params ?
                            <CircularLoader /> :
                            <div className="df df-gap-15 df-wrap prevent-select-text">
                                {_.size(value?.params) > 0 &&
                                    <TFBCheckbox
                                        key={"params-all"}
                                        checked={!_.some(value?.params, ["checked", false])}
                                        label={languagePack.all_params}
                                        onChange={(e) => handleChangeParamCheckbox(e, key)}
                                        labelColor={"var(--primaryColor)"}
                                    />
                                }
                                {_.map(value?.params, (value2, key2) => (
                                    <TFBCheckbox
                                        key={"param-" + key2}
                                        checked={value2.checked}
                                        label={value2.label}
                                        onChange={(e) => handleChangeParamCheckbox(e, key, key2)}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
        {_.size(getCategoryOptions()) > 0 &&
            <TFBButton
                color="lightGray"
                renderIcon={() => <TFBIcon name="add" color="gray" />}
                disabled={_.some(state?.categories, ["category", null])}
                onClick={addCategory}
            >
                {languagePack.add_category}
            </TFBButton>
        }
        {state.open_category_list &&
            <CategoryList
                openPopup={state.open_category_list}
                closePopup={closeCategoryList}
                refreshList={refetchCategories}
                list={categories?.list ?? []}
            />
        }
        {state.open_add_category &&
            <AddCategory
                openPopup={state.open_add_category}
                closePopup={closeAddCategory}
                refreshList={refetchCategories}
            />
        }
    </FadeInOut>

    const step3 = <FadeInOut show={state.step == 2}>
        <div className={styles.stepTitle}>{`${languagePack.step} 3: ${languagePack.select_audience}`}</div>
        <div className={styles.teamsContainer}>
            {_.map(state.teams, (value, key) => (
                <div key={"team-" + key} className={styles.teamContainer}>
                    <div className="df df-g20 df-align-end">
                        <TFBSelect
                            label={languagePack.team}
                            placeholder={languagePack.select_team}
                            value={value?.team ?? null}
                            options={getTeamOptions()}
                            onChange={(selected) => handleSelectTeam(key, selected)}
                            style={{ component: { width: "300px" } }}
                        />
                        {_.size(state.teams) > 1 &&
                            <TFBIconButton
                                name="x-large"
                                color="red"
                                onClick={() => deleteTeam(key)}
                            />
                        }
                    </div>
                    <div className={styles.playersContainer}>
                        {value?.is_loading_players ?
                            <CircularLoader /> :
                            <div className="df df-gap-15 df-wrap prevent-select-text">
                                {_.size(value?.players) > 0 &&
                                    <TFBCheckbox
                                        key={"player-all"}
                                        checked={!_.some(value?.players, ["checked", false])}
                                        label={languagePack.all_players}
                                        onChange={(e) => handleChangePlayerCheckbox(e, key)}
                                        labelColor={"var(--primaryColor)"}
                                    />
                                }
                                {_.map(value?.players, (value2, key2) => (
                                    <TFBCheckbox
                                        key={"player-" + key2}
                                        checked={value2.checked}
                                        label={value2.label}
                                        onChange={(e) => handleChangePlayerCheckbox(e, key, key2)}
                                    />
                                ))}
                            </div>
                        }
                    </div>
                </div>
            ))}
        </div>
        {_.size(getTeamOptions()) > 0 &&
            <TFBButton
                color="lightGray"
                renderIcon={() => <TFBIcon name="add" color="gray" />}
                disabled={_.some(state?.teams, ["team", null])}
                onClick={addTeam}
            >
                {languagePack.add_team}
            </TFBButton>
        }
    </FadeInOut>

    const step4 = <FadeInOut show={state.step == 3}>
        <div className={styles.stepTitle}>{`${languagePack.step} 4: ${languagePack.evaluation_table}`}</div>
        <div className={styles.teamsContainer}>
            <EvaluationTable
                params={state.table_params}
                players={state.table_players}
                getChildData={data => updateState({ data: data })}
            />
        </div>
    </FadeInOut>

    const steps = [step1, step2, step3, step4];

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack.add_evaluation}>
                {state.step > 0 && state.step < 4 && <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="arrow-left" />}
                    onClick={previousStep}
                    startIcon
                >
                    {languagePack.previous_step}
                </TFBButton>
                }
                {state.step > -1 && state.step < 4 && <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="arrow-right" />}
                    onClick={nextStep}
                    disabled={!isValidNextStep()}
                    loading={loadingAddRequest}
                >
                    {state.step == 3 ? languagePack.save_evaluation : languagePack.next_step}
                </TFBButton>}
            </TFBCardHeader>
            <TFBCardBody>
                {steps[state.step]}
            </TFBCardBody>
        </TFBCard >
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(AddEvaluation);