import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { useHistory } from "react-router-dom";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { TFBTableCell, TFBTableRow } from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../../components/reusable/Loader";

import AddJustifyingDocument from "./AddJustifyingDocument";
import EditJustifyingDocument from "./EditJustifyingDocument";
import DeleteJustifyingDocument from "./DeleteJustifyingDocument";

const FinancialJustifyingDocument = (props) => {

    const { currentUser, languagePack } = props;
    const history = useHistory();

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_add_document: false,

            open_edit_document: false,
            editing_document: {},

            open_delete_document: false,
            deleting_document: {},
        }
    );

    const {
        data: documents,
        loading: isDocumentsLoading,
        refetch: refetchDocumentsList,
    } = useAxiosGet(
        `finances/get_financial_justifying_document_list?id_club=${currentUser.id_club}`
    );

    const openAddDocument = () => {
        updateState({ open_add_document: true })
    }

    const closeAddDocument = () => {
        updateState({ open_add_document: false })
    }

    const openEditDocument = (doc) => {
        updateState({ open_edit_document: true, editing_document: doc })
    }

    const closeEditDocument = () => {
        updateState({ open_edit_document: false, editing_document: {} })
    }

    const openDeleteDocument = (doc) => {
        updateState({ open_delete_document: true, deleting_document: doc })
    }

    const closeDeleteDocument = () => {
        updateState({ open_delete_document: false, deleting_document: {} })
    }

    const viewDocument = (doc) => {
        history.push(`/financial/justifying-document/${doc.id_document}`);
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack["justifying_documents"]}>
                <TFBButton
                    onClick={openAddDocument}
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                >
                    {languagePack.add_justifying_document}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                {!isDocumentsLoading ?
                    (_.size(documents?.list) > 0 ?
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TFBTableRow>
                                        <TFBTableCell>
                                            {languagePack.title}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.document_type}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.made_by}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                        </TFBTableCell>
                                    </TFBTableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(documents?.list, (row, index) => (
                                        <TFBTableRow key={"document-" + index}>
                                            <TFBTableCell>
                                                <div className="cursor-pointer" onClick={() => viewDocument(row)}>
                                                    {row.title}
                                                </div>
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack?.[row.type]}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row?.user?.user?.full_name}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                <div className="actions-btns-container df-end">
                                                    <TFBIconButton
                                                        name="view"
                                                        color="darkGray"
                                                        onClick={() => viewDocument(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="edit"
                                                        color="darkGray"
                                                        onClick={() => openEditDocument(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="delete"
                                                        color="darkGray"
                                                        onClick={() => openDeleteDocument(row)}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                        </TFBTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <TFBPlaceholder
                            text={languagePack.no_justifying_documents}
                        />
                    ) :
                    <CircularLoader />
                }
            </TFBCardBody>

            {state.open_add_document &&
                <AddJustifyingDocument
                    openPopup={state.open_add_document}
                    closePopup={closeAddDocument}
                    refreshList={refetchDocumentsList}
                />
            }
            {state.open_edit_document &&
                <EditJustifyingDocument
                    openPopup={state.open_edit_document}
                    closePopup={closeEditDocument}
                    refreshList={refetchDocumentsList}
                    document={state.editing_document}
                />
            }
            {state.open_delete_document &&
                <DeleteJustifyingDocument
                    openPopup={state.open_delete_document}
                    closePopup={closeDeleteDocument}
                    refreshList={refetchDocumentsList}
                    document={state.deleting_document}
                />
            }
        </TFBCard>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(FinancialJustifyingDocument);