import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { connect } from "react-redux";
import { store } from "react-notifications-component";

import "../../ClubProfile/Teams/Teams.scss";
import "./MatchDetails.scss";
import styles from "./Match.module.scss";

import AddPlayingFormation from "./AddPlayingFormation";
import ImportLineup from "./ImportLineup";
import DeleteMatchEvent from "./DeleteMatchEvent";
import EditMatchLineup from "./EditMatchLineup";

import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

function MatchLineup(props) {
  const {
    languagePack,
    matchDetails,
    teamsPlayers,
    hasAccessToDeleteHomeEvents,
    hasAccessToDeleteAwayEvents,
    hasAccessToEditMatch,
    updatePlayersRating,
    refreshMatchDetails,
    staff,
    doUpdateLineups,
  } = props;
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open_edit_event: false,
      editing_event: {},
    }
  );

  const [isLoad, setisLoad] = useState(false);
  const [playerHome, setPlayerHome] = useState(null);
  const [playerHomeCaptain, setPlayerHomeCaptain] = useState(null);
  const [playerAway, setPlayerAway] = useState(null);
  const [playerAwayCaptain, setPlayerAwayCaptain] = useState(null);
  const [playerHomeSub, setPlayerHomeSub] = useState(null);
  const [playerAwaySub, setPlayerAwaySub] = useState(null);
  const [staffHome, setStaffHome] = useState(null);
  const [staffAway, setStaffAway] = useState(null);
  const [delegateHome, setDelegateHome] = useState(null);
  const [delegateAway, setDelegateAway] = useState(null);

  const [deleteLineupEvent, setDeleteLineupEvent] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  const [lineups, setLineups] = useState({});
  const [referee1, setReferee1] = useState(matchDetails.referee ?? "");
  const [referee2, setReferee2] = useState(matchDetails.assistant_first ?? "");
  const [referee3, setReferee3] = useState(matchDetails.assistant_second ?? "");
  const [referee4, setReferee4] = useState(matchDetails.fourth_official ?? "");

  const [obsRefree, setObsRefree] = useState(
    matchDetails.referee_observer ?? ""
  );
  const [obsGame, setObsGame] = useState(matchDetails.game_observer ?? "");

  const [openPlayingFormationPopup, setOpenPlayingFormationPopup] = useState(
    false
  );
  const [playingFormationProps, setPlayingFormationProps] = useState({});

  const [openImportLineup, setOpenImportLineup] = useState(false);
  const [importLineupProps, setImportLineupProps] = useState({});

  useEffect(() => {
    setLineupsAndSubs();
  }, [doUpdateLineups]);

  const setLineupsAndSubs = () => {
    axios
      .get(
        `matches/get_manual_match_lineups_3?id_match=${matchDetails.id_match}&home_team_id=${matchDetails.id_team_home}&away_team_id=${matchDetails.id_team_away}&home_team_id_own=${matchDetails.own_team_home_id}&away_team_id_own=${matchDetails.own_team_away_id}`
      )
      .then((res) => {
        setLineups(res.data);
        setisLoad(true);
      })
      .catch((err) => console.log(err));
  };

  const addPlayer = (type, team) => {
    const playerDataId = {
      start: { home: playerHome?.value, away: playerAway?.value },
      captain: {
        home: playerHomeCaptain?.value,
        away: playerAwayCaptain?.value,
      },
      sub: { home: playerHomeSub?.value, away: playerAwaySub?.value },
    };

    const playerDataName = {
      start: { home: playerHome?.label, away: playerAway?.label },
      captain: {
        home: playerHomeCaptain?.label,
        away: playerAwayCaptain?.label,
      },
      sub: { home: playerHomeSub?.label, away: playerAwaySub?.label },
    };

    const idTeam = {
      home: matchDetails.own_team_home_id,
      away: matchDetails.own_team_away_id,
    };

    const idManualCompetitionTeam = {
      home: matchDetails.id_team_home,
      away: matchDetails.id_team_away,
    };

    const typeHandler = {
      start: "Starting player",
      sub: "Substitute player",
      captain: "Captain",
    };

    const payload = {
      id_match: matchDetails.id_match,
      action_name: typeHandler[type],
      minute: 0,
      half: 1,
      id_player: playerDataId[type][team],
      player_name: playerDataName[type][team],
      id_team: idTeam[team],
      id_manual_competition_team: idManualCompetitionTeam[team],
    };

    axios.post("matches/add_manual_match_event_2", payload).then((res) => {
      if (res.data.success == 1) {
        setLineupsAndSubs();
        updatePlayersRating();

        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message:
            type == "start"
              ? languagePack["player_lineup_successfully_added"]
              : languagePack["player_sub_successfully_added"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Evenimentul nu a fost adaugat cu succes.",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
    });
  };

  const addStaff = (type, team) => {
    const staffDataId = {
      staff: { home: staffHome?.value, away: staffAway?.value },
      delegate: { home: delegateHome?.value, away: delegateAway?.value },
    };

    const staffDataName = {
      staff: { home: staffHome?.label, away: staffAway?.label },
      delegate: { home: delegateHome?.label, away: delegateAway?.label },
    };

    const staffRole = {
      staff: {
        home:
          staffHome?.role?.value == "custom" ? staffHome?.role?.label : null,
        away:
          staffAway?.role?.value == "custom" ? staffAway?.role?.label : null,
      },
      delegate: {
        home: null,
        away: null,
      },
    };

    const idTeam = {
      home: matchDetails.own_team_home_id,
      away: matchDetails.own_team_away_id,
    };

    const idManualCompetitionTeam = {
      home: matchDetails.id_team_home,
      away: matchDetails.id_team_away,
    };

    const typeHandler = {
      staff: "Staff",
      delegate: "Delegate",
    };

    const payload = {
      id_match: matchDetails.id_match,
      action_name: typeHandler[type],
      id_staff_member: staffDataId[type][team],
      staff_member_name: staffDataName[type][team],
      staff_member_role: staffRole[type][team],
      id_team: idTeam[team],
      id_manual_competition_team: idManualCompetitionTeam[team],
    };

    axios.post("matches/add_manual_match_staff_event", payload).then((res) => {
      if (res.data.success == 1) {
        setLineupsAndSubs();

        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message:
            type == "start"
              ? languagePack["player_lineup_successfully_added"]
              : languagePack["player_sub_successfully_added"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Evenimentul nu a fost adaugat cu succes.",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
    });
  };

  const openDeleteLineupRow = (id) => {
    setIdToDelete(id);
    setDeleteLineupEvent(true);
  };

  const closeDeleteLineupRow = () => {
    setIdToDelete("");
    setDeleteLineupEvent(false);
  };

  const saveReferees = () => {
    const payload = {
      referee: referee1,
      assistant_first: referee2,
      assistant_second: referee3,
      fourth_official: referee4,
      referee_observer: obsRefree,
      game_observer: obsGame,

      id_match: matchDetails.id_match,
    };
    axios
      .post(`matches/save_referees`, payload)
      .then((res) => {
        setLineupsAndSubs();
        store.addNotification({
          title: languagePack["fixtures_schedule"],
          message: "Arbitrii au fost salvati cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  const openPlayingFormation = (type, half) => {
    setOpenPlayingFormationPopup(true);
    //players, existingPlayerPositionAssoc, matchFormation = null
    const props = {
      players: [
        ...(lineups?.[`${type}_team`]?.starting ?? []),
        ...(lineups?.[`${type}_team`]?.substitutes ?? []),
      ],
      existingPlayerPositionAssoc:
        lineups?.[`${type}_team`]?.[`formation_${half}_half`],
      matchFormation: matchDetails?.[`${type}_${half}_half_formation`],
      idTeam: matchDetails?.[`own_team_${type}_id`],
      idManualCompetitionTeam: matchDetails?.[`id_team_${type}`],
      half: half,
      type: type,
    };
    setPlayingFormationProps(props);
  };

  const closePlayingFormation = () => {
    setOpenPlayingFormationPopup(false);
    setPlayingFormationProps({});
  };

  const openImportPopup = (type) => {
    setOpenImportLineup(true);
    const props = {
      idTeam: matchDetails?.[`own_team_${type}_id`],
      idManualCompetitionTeam: matchDetails?.[`id_team_${type}`],
    };
    setImportLineupProps(props);
  };

  const closeImportPopup = () => {
    setOpenImportLineup(false);
    setImportLineupProps({});
  };

  const openEditEvent = (event) => {
    updateState({ open_edit_event: true, editing_event: event })
  }

  const closeEditEvent = () => {
    updateState({ open_edit_event: false, editing_event: {} })
  }

  const addPlayerHomeDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("start", "home");
          setPlayerHome(null);
        }}
        disabled={playerHome == null}
      />
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_home]
            ? [...teamsPlayers?.[matchDetails.id_team_home]].filter(
              (player) =>
                ![
                  ...(lineups?.home_team?.starting ?? []),
                  ...(lineups?.home_team?.substitutes ?? []),
                ]
                  .map((elem) => elem.id_player)
                  .includes(player.value)
            )
            : []
        }
        value={playerHome}
        onChange={(selected) => {
          setPlayerHome(selected);
        }}
        isClearable={true}
      />
    </div>
  );

  const addPlayerHomeCaptainDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("captain", "home");
          setPlayerHomeCaptain(null);
        }}
        disabled={playerHomeCaptain == null}
      />
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_home]
            ? _.map(lineups?.home_team?.starting ?? [], (e) => ({
              value: e?.id_player,
              label: e?.name,
            }))
            : []
        }
        value={playerHomeCaptain}
        onChange={(selected) => {
          setPlayerHomeCaptain(selected);
        }}
        isClearable={true}
      />
    </div>
  );

  const addPlayerHomeSubDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("sub", "home");
          setPlayerHomeSub(null);
        }}
        disabled={playerHomeSub == null}
      />
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_home]
            ? [...teamsPlayers?.[matchDetails.id_team_home]].filter(
              (player) =>
                ![
                  ...(lineups?.home_team?.starting ?? []),
                  ...(lineups?.home_team?.substitutes ?? []),
                ]
                  .map((elem) => elem.id_player)
                  .includes(player.value)
            )
            : []
        }
        value={playerHomeSub}
        onChange={(selected) => {
          setPlayerHomeSub(selected);
        }}
        isClearable={true}
      />
    </div>
  );

  const addPlayerAwayCaptainDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_away]
            ? _.map(lineups?.away_team?.starting ?? [], (e) => ({
              value: e?.id_player,
              label: e?.name,
            }))
            : []
        }
        value={playerAwayCaptain}
        onChange={(selected) => {
          setPlayerAwayCaptain(selected);
        }}
        isClearable={true}
      />
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("captain", "away");
          setPlayerAwayCaptain(null);
        }}
        disabled={playerAwayCaptain == null}
      />
    </div>
  );

  const addPlayerAwayDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_away]
            ? [...teamsPlayers?.[matchDetails.id_team_away]].filter(
              (player) =>
                ![
                  ...(lineups?.away_team?.starting ?? []),
                  ...(lineups?.away_team?.substitutes ?? []),
                ]
                  .map((elem) => elem.id_player)
                  .includes(player.value)
            )
            : []
        }
        value={playerAway}
        onChange={(selected) => {
          setPlayerAway(selected);
        }}
        isClearable={true}
      />
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("start", "away");
          setPlayerAway(null);
        }}
        disabled={playerAway == null}
      />
    </div>
  );

  const addPlayerAwaySubDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBSelect
        placeholder={languagePack.select_player}
        options={
          teamsPlayers?.[matchDetails.id_team_away]
            ? [...teamsPlayers?.[matchDetails.id_team_away]].filter(
              (player) =>
                ![
                  ...(lineups?.away_team?.starting ?? []),
                  ...(lineups?.away_team?.substitutes ?? []),
                ]
                  .map((elem) => elem.id_player)
                  .includes(player.value)
            )
            : []
        }
        value={playerAwaySub}
        onChange={(selected) => {
          setPlayerAwaySub(selected);
        }}
        isClearable={true}
      />
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addPlayer("sub", "away");
          setPlayerAwaySub(null);
        }}
        disabled={playerAwaySub == null}
      />
    </div>
  );

  const addStaffHomeDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addStaff("staff", "home");
          setStaffHome(null);
        }}
        disabled={staffHome == null || staffHome?.role == null}
      />
      <div className={styles.staffDiv}>
        <TFBSelect
          placeholder={languagePack.select_staff}
          options={_.filter(
            staff,
            (e) =>
              !_.includes(
                _.map(
                  lineups?.home_team?.staff ?? [],
                  (v) => v.id_staff_member
                ),
                e.value
              )
          )}
          value={staffHome}
          onChange={(selected) => {
            setStaffHome(selected);
          }}
          isClearable={true}
        />
        {staffHome && (
          <TFBSelect
            label={languagePack.staff_member_role}
            value={staffHome?.role ?? null}
            options={staffHome?.role_list ?? []}
            onChange={(selected) =>
              setStaffHome({ ...staffHome, role: selected })
            }
            onBlur={(e) => {
              if (e.target.value) {
                setStaffHome({
                  ...staffHome,
                  role: { value: "custom", label: e.target.value },
                });
              }
            }}
            isClearable={true}
          />
        )}
      </div>
    </div>
  );

  const addStaffAwayDiv = (
    <div className="df df-center-center df-gap-10">
      <div className={styles.staffDiv}>
        <TFBSelect
          placeholder={languagePack.select_staff}
          options={_.filter(
            staff,
            (e) =>
              !_.includes(
                _.map(
                  lineups?.away_team?.staff ?? [],
                  (v) => v.id_staff_member
                ),
                e.value
              )
          )}
          value={staffAway}
          onChange={(selected) => {
            setStaffAway(selected);
          }}
          isClearable={true}
        />
        {staffAway && (
          <TFBSelect
            label={languagePack.staff_member_role}
            value={staffAway?.role ?? null}
            options={staffAway?.role_list ?? []}
            onChange={(selected) =>
              setStaffAway({ ...staffAway, role: selected })
            }
            onBlur={(e) => {
              if (e.target.value) {
                setStaffAway({
                  ...staffAway,
                  role: { value: "custom", label: e.target.value },
                });
              }
            }}
            isClearable={true}
          />
        )}
      </div>
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addStaff("staff", "away");
          setStaffAway(null);
        }}
        disabled={staffAway == null || staffAway?.role == null}
      />
    </div>
  );

  const addDelegateHomeDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addStaff("delegate", "home");
          setDelegateHome(null);
        }}
        disabled={delegateHome == null}
      />
      <TFBSelect
        placeholder={languagePack.select_staff}
        options={_.filter(
          staff,
          (e) =>
            !_.includes(
              _.map(
                lineups?.home_team?.delegate ?? [],
                (v) => v.id_staff_member
              ),
              e.value
            )
        )}
        value={delegateHome}
        onChange={(selected) => {
          setDelegateHome(selected);
        }}
        isClearable={true}
      />
    </div>
  );

  const addDelegateAwayDiv = (
    <div className="df df-center-center df-gap-10">
      <TFBSelect
        placeholder={languagePack.select_staff}
        options={_.filter(
          staff,
          (e) =>
            !_.includes(
              _.map(
                lineups?.away_team?.delegate ?? [],
                (v) => v.id_staff_member
              ),
              e.value
            )
        )}
        value={delegateAway}
        onChange={(selected) => {
          setDelegateAway(selected);
        }}
        isClearable={true}
      />
      <TFBIconButton
        name="add"
        color="green"
        onClick={() => {
          addStaff("delegate", "away");
          setDelegateAway(null);
        }}
        disabled={delegateAway == null}
      />
    </div>
  );

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack["starting_lineup"]} />
      <TFBCardBody>
        {isLoad && (
          <div className="match-lineup-container">
            <div className="match-lineup-details-container">
              <div className="details-team-container left">
                <div className="logo-container team-home">
                  <img src={matchDetails.home_team_logo_url} alt="team_home" />
                </div>
                <div className="team-name">{matchDetails.home_team_name}</div>
              </div>
              <div className="vs">{"vs"}</div>
              <div className="details-team-container right">
                <div className="team-name">{matchDetails.away_team_name}</div>
                <div className="logo-container team-away">
                  <img src={matchDetails.away_team_logo_url} alt="team_away" />
                </div>
              </div>
            </div>
            <div className={styles.lineupList}>
              <div className={styles.listColumn}>
                {hasAccessToDeleteHomeEvents && (
                  <>
                    <TFBButton
                      color="green"
                      renderIcon={() => <TFBIcon name={matchDetails.home_first_half_formation == null ? "add" : "view"} />}
                      onClick={() => openPlayingFormation("home", "first")}
                    >
                      {matchDetails.home_first_half_formation == null ? (
                        languagePack.add_match_formation_first_half
                      ) : (
                        <div>
                          <div>
                            {languagePack.view_match_formation_first_half}
                          </div>
                          <div className={styles.matchformationName}>
                            {"(" +
                              matchDetails.home_first_half_formation?.label +
                              ")"}
                          </div>
                        </div>
                      )}
                    </TFBButton>
                    <TFBButton
                      color="green"
                      renderIcon={() => <TFBIcon name={matchDetails.home_second_half_formation == null ? "add" : "view"} />}
                      onClick={() => openPlayingFormation("home", "second")}
                    >
                      {matchDetails.home_second_half_formation == null ? (
                        languagePack.add_match_formation_second_half
                      ) : (
                        <div>
                          <div>
                            {languagePack.view_match_formation_second_half}
                          </div>
                          <div className={styles.matchformationName}>
                            {"(" +
                              matchDetails.home_second_half_formation?.label +
                              ")"}
                          </div>
                        </div>
                      )}
                    </TFBButton>
                  </>
                )}
                {_.map(lineups?.home_team.starting, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-starting-player" + index}
                    >
                      <div className={styles.lineupData}>
                        <div className={styles.number}>
                          {item.number}
                        </div>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/player/" + item.id_player)}
                        >
                          {`${item.name}`}
                          <span className={styles.minutes}>{`${item?.played_minutes > 0 ? ` (${item.played_minutes}')` : ""}`}</span>
                        </div>
                      </div>

                      {hasAccessToDeleteHomeEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openEditEvent(item)}
                          >
                            <TFBIcon name="edit" color="gray" />
                          </div>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
                {hasAccessToDeleteHomeEvents && addPlayerHomeDiv}
                {hasAccessToDeleteHomeEvents &&
                  <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="arrow-up" />}
                    onClick={() => openImportPopup("home")}
                  >
                    {languagePack.import_lineups}
                  </TFBButton>
                }
              </div>
              <div className={styles.listColumn}>
                {hasAccessToDeleteAwayEvents && (
                  <>
                    <TFBButton
                      color="green"
                      renderIcon={() => (
                        <TFBIcon
                          name={
                            matchDetails.away_first_half_formation == null
                              ? "add"
                              : "view"
                          }
                        />
                      )}
                      onClick={() => openPlayingFormation("away", "first")}
                    >
                      {matchDetails.away_first_half_formation == null ? (
                        languagePack.add_match_formation_first_half
                      ) : (
                        <div>
                          <div>
                            {languagePack.view_match_formation_first_half}
                          </div>
                          <div className={styles.matchformationName}>
                            {"(" +
                              matchDetails.away_first_half_formation?.label +
                              ")"}
                          </div>
                        </div>
                      )}
                    </TFBButton>
                    <TFBButton
                      color="green"
                      renderIcon={() => (
                        <TFBIcon
                          name={
                            matchDetails.away_second_half_formation == null
                              ? "add"
                              : "view"
                          }
                        />
                      )}
                      onClick={() => openPlayingFormation("away", "second")}
                    >
                      {matchDetails.away_second_half_formation == null ? (
                        languagePack.add_match_formation_second_half
                      ) : (
                        <div>
                          <div>
                            {languagePack.view_match_formation_second_half}
                          </div>
                          <div className={styles.matchformationName}>
                            {"(" +
                              matchDetails.away_second_half_formation?.label +
                              ")"}
                          </div>
                        </div>
                      )}
                    </TFBButton>
                  </>
                )}
                {lineups.away_team.starting.map((item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"away-starting-player" + index}
                    >
                      <div className={styles.lineupData}>
                        <div className={styles.number}>
                          {item.number}
                        </div>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/player/" + item.id_player)}
                        >
                          {`${item.name}`}
                          <span className={styles.minutes}>{`${item?.played_minutes > 0 ? ` (${item.played_minutes}')` : ""}`}</span>
                        </div>
                      </div>

                      {hasAccessToDeleteAwayEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openEditEvent(item)}
                          >
                            <TFBIcon name="edit" color="gray" />
                          </div>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
                {hasAccessToDeleteAwayEvents && addPlayerAwayDiv}
                {hasAccessToDeleteAwayEvents &&
                  <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="arrow-up" />}
                    onClick={() => openImportPopup("away")}
                  >
                    {languagePack.import_lineups}
                  </TFBButton>
                }
              </div>
            </div>
            <div className="substitutes-title">{languagePack["captain"]}</div>
            <div className={styles.lineupList}>
              <div className={styles.listColumn}>
                {_.map(lineups?.home_team?.captain) == 0 &&
                  hasAccessToDeleteHomeEvents &&
                  addPlayerHomeCaptainDiv}
                {_.map(lineups?.home_team?.captain, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-captain-player" + index}
                    >
                      <div className={styles.lineupData}>
                        <div className={styles.number}>
                          {item.number}
                        </div>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/player/" + item.id_player)}
                        >
                          {item.name}
                        </div>
                      </div>

                      {hasAccessToDeleteHomeEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
              <div className={styles.listColumn}>
                {_.map(lineups?.away_team?.captain) == 0 &&
                  hasAccessToDeleteAwayEvents &&
                  addPlayerAwayCaptainDiv}
                {_.map(lineups?.away_team?.captain, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-captain-player" + index}
                    >
                      <div className={styles.lineupData}>
                        <div className={styles.number}>
                          {item.number}
                        </div>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/player/" + item.id_player)}
                        >
                          {item.name}
                        </div>
                      </div>

                      {hasAccessToDeleteAwayEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="substitutes-title">
              {languagePack["substitutes"]}
            </div>
            <div className={styles.lineupList}>
              <div className={styles.listColumn}>
                {hasAccessToDeleteHomeEvents && addPlayerHomeSubDiv}
                {_.map(lineups.home_team.substitutes, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-substitute-player" + index}
                    >
                      <div className={styles.lineupData}>
                        <div className={styles.number}>
                          {item.number}
                        </div>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/player/" + item.id_player)}
                        >
                          {`${item.name}`}
                          <span className={styles.minutes}>{`${item?.played_minutes > 0 ? ` (${item.played_minutes}')` : ""}`}</span>
                        </div>
                      </div>

                      {hasAccessToDeleteHomeEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openEditEvent(item)}
                          >
                            <TFBIcon name="edit" color="gray" />
                          </div>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
              <div className={styles.listColumn}>
                {hasAccessToDeleteAwayEvents && addPlayerAwaySubDiv}
                {_.map(lineups.away_team.substitutes, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-substitute-player" + index}
                    >
                      <div className={styles.lineupData}>
                        <div className={styles.number}>
                          {item.number}
                        </div>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/player/" + item.id_player)}
                        >
                          {`${item.name}`}
                          <span className={styles.minutes}>{`${item?.played_minutes > 0 ? ` (${item.played_minutes}')` : ""}`}</span>
                        </div>
                      </div>

                      {hasAccessToDeleteAwayEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openEditEvent(item)}
                          >
                            <TFBIcon name="edit" color="gray" />
                          </div>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="substitutes-title">
              {languagePack["staff"]}
            </div>
            <div className={styles.lineupList}>
              <div className={styles.listColumn}>
                {_.size(lineups?.home_team?.staff) < 6 &&
                  hasAccessToDeleteHomeEvents && addStaffHomeDiv}
                {_.map(lineups?.home_team?.staff, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-staff" + index}
                    >
                      <div className={styles.lineupData}>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/staff/" + item.id_staff_member)}
                        >
                          {item.name}
                          <div className={styles.role}>{item.role}</div>
                        </div>
                      </div>

                      {hasAccessToDeleteHomeEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
              <div className={styles.listColumn}>
                {_.size(lineups?.away_team?.staff) < 6 &&
                  hasAccessToDeleteAwayEvents &&
                  addStaffAwayDiv}
                {_.map(lineups?.away_team?.staff, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"away-staff" + index}
                    >
                      <div className={styles.lineupData}>
                        <div
                          className={styles.player}
                          onClick={() => history.push("/club/staff/" + item.id_staff_member)}
                        >
                          {item.name}
                          <div className={styles.role}>{item.role}</div>
                        </div>
                      </div>

                      {hasAccessToDeleteAwayEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="substitutes-title">{languagePack["delegate"]}</div>
            <div className={styles.lineupList}>
              <div className={styles.listColumn}>
                {_.size(lineups?.home_team?.delegate) == 0 &&
                  hasAccessToDeleteHomeEvents &&
                  addDelegateHomeDiv}
                {_.map(lineups?.home_team?.delegate, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"home-delegate" + index}
                    >
                      <div className={styles.lineupData}>
                        <div
                          className={styles.player}
                        >
                          {item.name}
                        </div>
                      </div>

                      {hasAccessToDeleteHomeEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
              <div className={styles.listColumn}>
                {_.size(lineups?.away_team?.delegate) == 0 &&
                  hasAccessToDeleteAwayEvents &&
                  addDelegateAwayDiv}
                {_.map(lineups?.away_team?.delegate, (item, index) => {
                  return (
                    <div
                      className={styles.lineupRowContainer}
                      style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      key={"away-delegate" + index}
                    >
                      <div className={styles.lineupData}>
                        <div
                          className={styles.player}
                        >
                          {item.name}
                        </div>
                      </div>

                      {hasAccessToDeleteAwayEvents &&
                        <div className={styles.lineupAction}>
                          <div
                            className={styles.eventIcon}
                            onClick={() => openDeleteLineupRow(item.id_event)}
                          >
                            <TFBIcon name="delete" color="gray" />
                          </div>
                        </div>
                      }
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="substitutes-title">{languagePack.refrees}</div>
            {hasAccessToEditMatch ? (
              <div className="referees-list">
                <div className="referee-line">
                  <div className="referee-type">A:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee1}
                      onChange={(e) => setReferee1(e.target.value)}
                      placeholder={languagePack.central_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A1:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee2}
                      onChange={(e) => setReferee2(e.target.value)}
                      placeholder={languagePack.assistant_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A2:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee3}
                      onChange={(e) => setReferee3(e.target.value)}
                      placeholder={languagePack.assistant_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">R:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={referee4}
                      onChange={(e) => setReferee4(e.target.value)}
                      placeholder={languagePack.reserve_referee}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">OBS Arbitri:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={obsRefree}
                      onChange={(e) => setObsRefree(e.target.value)}
                      placeholder={languagePack.obs_refree}
                      className="add-team-input"
                    />
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">OBS Joc:</div>
                  <div className="referee-input">
                    <input
                      type="text"
                      value={obsGame}
                      onChange={(e) => setObsGame(e.target.value)}
                      placeholder={languagePack.obs_game}
                      className="add-team-input"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="referees-list">
                <div className="referee-line">
                  <div className="referee-type">A:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee1}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A1:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee2}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">A2:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee3}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">R:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{referee4}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">OBS Arbitri:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{obsRefree}</div>
                  </div>
                </div>
                <div className="referee-line">
                  <div className="referee-type">OBS Joc:</div>
                  <div className="referee-input">
                    <div className="add-team-input">{obsGame}</div>
                  </div>
                </div>
              </div>
            )}
            {hasAccessToEditMatch && (
              <div className="save-referees">
                <TFBButton
                  color="green"
                  onClick={saveReferees}
                  renderIcon={() => <TFBIcon name="check" />}
                >
                  {languagePack.save_refrees}
                </TFBButton>
              </div>
            )}
          </div>
        )}
      </TFBCardBody>

      {state.open_edit_event &&
        <EditMatchLineup
          openPopup={state.open_edit_event}
          closePopup={closeEditEvent}
          refreshList={() => {
            setLineupsAndSubs();
          }}
          event={state.editing_event}
        />
      }

      {deleteLineupEvent &&
        <DeleteMatchEvent
          openPopup={deleteLineupEvent}
          closePopup={closeDeleteLineupRow}
          refreshList={() => {
            setLineupsAndSubs();
            updatePlayersRating();
          }}
          event={{ id_event: idToDelete }}
          eventType={"lineup"}
        />
      }

      {openPlayingFormationPopup && (
        <AddPlayingFormation
          isOpenPopup={openPlayingFormationPopup}
          closePopup={closePlayingFormation}
          idMatch={matchDetails.id_match}
          matchDetails={matchDetails}
          refreshMatchDetails={refreshMatchDetails}
          refreshLineupsAndSubs={setLineupsAndSubs}
          {...playingFormationProps}
        />
      )}

      {openImportLineup && (
        <ImportLineup
          isOpenPopup={openImportLineup}
          closePopup={closeImportPopup}
          idMatch={matchDetails.id_match}
          matchDetails={matchDetails}
          refreshLineupsAndSubs={setLineupsAndSubs}
          {...importLineupProps}
        />
      )}
    </TFBCard>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchLineup);
