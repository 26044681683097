import React, { useContext } from "react";
import { connect } from "react-redux";

import styles from "./ImportCompetitionForm.module.scss";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import { AddCompetitionContext } from "./AddCompetition";
import TFBInput from "../../../../components/design-system/Input/TFBInput";

function ImportCompetitionForm({ languagePack, currentUser, token }) {
  const context = useContext(AddCompetitionContext);

  return (
    <div className={styles.wrapper}>
      <TFBSelect
        isClearable
        value={context.selectedSource}
        label={languagePack.source}
        options={[
          { value: "1", label: "Competiții FRF/LPF" },
          { value: "2", label: "Competiții AJF" },
        ]}
        name="selectedSource"
        onChange={context.onChangeSource}
        placeholder={languagePack.select_source}
      />
      {Number(context.selectedSource?.value) === 2 && (
        <TFBSelect
          isClearable
          isLoading={context.loadingCounties}
          value={context.selectedCounty}
          onChange={context.onChangeCounty}
          name="selectedCounty"
          label={languagePack.county}
          isDisabled={context.selectedSource === ""}
          options={context.countiesData?.list}
          placeholder={languagePack.select_county}
        />
      )}
      <TFBSelect
        isClearable
        value={context.selectedCompetition}
        isLoading={
          context.loadingCompetitions || context.loadingCountyCompetitions
        }
        onChange={context.onChangeCompetition}
        name="selectedCompetition"
        label={languagePack.competition}
        isDisabled={
          context.selectedSource === "" ||
          (Number(context.selectedSource?.value) === 2 &&
            context.selectedCounty === "")
        }
        options={
          context.selectedCounty !== ""
            ? context.countyCompetitionsData?.list
            : context.competitionsData?.list
        }
        placeholder={languagePack.select_competition}
      />
      {context.seriesData?.success === 1 && (
        <TFBSelect
          name="selectedSeries"
          isLoading={context.loadingSeries}
          options={context.seriesData.list}
          onChange={context.onChangeSeries}
          isClearable
          value={context.selectedSeries}
          isDisabled={context.selectedCompetition === ""}
          label={languagePack.series}
          placeholder={languagePack.select_series}
        />
      )}
      <TFBInput
        type="number"
        disabled={
          context.selectedCompetition === "" ||
          (context.seriesData?.success === 1 && context.selectedSeries === "")
        }
        fullWidth
        label={languagePack.minutes_per_time}
        placeholder={languagePack.type_minutes_per_time}
        value={context.importedCompetitionMinutesPerHalf}
        onChange={(e) =>
          context.onSetState({
            importedCompetitionMinutesPerHalf: e.target.value,
          })
        }
      />

      <TFBSelect
        label={languagePack.your_club}
        isLoading={context.loadingCompetitionClubs}
        name="selectedClub"
        value={context.selectedClub}
        onChange={context.onChangeSelect}
        options={
          context.selectedCounty !== ""
            ? context.countyCompetitionTeamsData?.list
            : context.competitionClubsData?.list
        }
        isClearable
        isDisabled={
          (context.seriesData?.success === 1 &&
            context.selectedSeries === "") ||
          context.selectedCompetition === ""
        }
        placeholder={languagePack.select_your_club}
      />
      <TFBSelect
        label={languagePack.club_team}
        isLoading={context.loadingClubTeams}
        onChange={context.onChangeSelect}
        isClearable
        name="selectedClubTeam"
        options={context.clubTeamsData?.list}
        isDisabled={context.selectedClub === ""}
        placeholder={languagePack.select_club_team}
      />
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ImportCompetitionForm);
