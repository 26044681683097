import React, { useContext, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as XLSX from "xlsx";
import styles from "./ImportKSport.module.scss";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBFileInput from "../../../../components/design-system/FileInput/TFBFileInput";
import DataTable from "react-data-table-component";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import CircularLoader from "../../../../components/reusable/Loader";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import { baseUrl } from "../../../../utils/utilFunctions";
import { TrainingDataContext } from "../TrainingData";

const ImportKSport = ({
  currentUser,
  token,
  languagePack,
  updateQuickSetup,
  onSetDialogSize,
}) => {
  const { idTraining, closeDataFilePopup, refetchKSportData } = useContext(
    TrainingDataContext
  );
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      is_loading_file: false,
      file: [],
      data: [],
      players_list: [],
    }
  );

  const { data: clubPlayers, loading: loadingClubPlayers } = useAxiosGet(
    `club/get_club_players?id_club=${currentUser.id_club}&token=${token}&loaned_out=0&type=select`
  );

  const { loading: loadingPostKSports, postData: postKSports } = useAxiosPost(
    baseUrl(`training/import_ksport_stats`)
  );

  const handleChangeSelect = (selected, value, index) => {
    updateState({
      [value.name]: _.map(state[value.name], (player, i) =>
        i === index ? selected : player
      ),
    });
  };

  useEffect(() => {
    _.forEach(state.players_list, (player, index) => {
      handleChangeRowValuedMapping(index - 2, "checkbox", player);
    });
  }, [state.players_list]);

  useEffect(() => {
    updateState({
      players_list: _.map(state.data, (el, i) => {
        const id = el?.A?.split(" ")?.[el?.A?.split(" ").length - 1];

        if (i > 1)
          return clubPlayers?.list?.find((el) => el.value == id) ?? null;
      }),
    });
  }, [state.data, clubPlayers]);

  const importPlayers = () => {
    let players = [];
    _.forEach(state.players_list, (player) => {
      if (player?.value) {
        players.push({ id_player: player?.value, params: [] });
      } else players.push({ id_player: null, params: [] });
    });

    _.forEach(state.players_list, (player, i) => {
      if (i > 1) {
        _.forEach(state.data, (row, j) => {
          if (j > 1) {
            const id = row?.A?.split(" ")?.[row?.A?.split(" ").length - 1];

            if (
              Number(player?.value) === Number(id) ||
              (isNaN(Number(id)) && i === j)
            ) {
              _.forEach(Object.entries(state.data?.[0]), ([key, value], k) => {
                _.forEach(players, (player, index) => {
                  if (
                    (isNaN(Number(id)) && i === index) ||
                    Number(player?.id_player) === Number(id)
                  ) {
                    player.params.push({
                      name: value,
                      value: state.data?.[j]?.[key],
                      unit:
                        key === "B" ||
                        key === "C" ||
                        key === "E" ||
                        key === "F" ||
                        key === "G" ||
                        key === "H" ||
                        key === "K" ||
                        key === "L"
                          ? "m"
                          : key === "J"
                          ? "kmh"
                          : null,
                    });
                  }
                });
              });
            }
          }
        });
      }
    });
    const payload = {
      token: token,
      id_training: idTraining,
      players: _.filter(players, (player) => player.id_player),
      // is_tfb: isTFB(),
    };

    const responseHandler = (res) => {
      if (Number(res.success) === 1) {
        closeDataFilePopup();
        refetchKSportData();
        showNotification(
          "success",
          languagePack.trainings,
          languagePack.import_data_success
        );
      } else
        showNotification(
          "danger",
          languagePack.trainings,
          languagePack.import_data_fail
        );
    };

    postKSports(payload, responseHandler);
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    updateState({
      file: files,
      is_loading_file: true,
      used_headers: [],
      column_field_mapping: {},
      row_value_mapping: {},
    });
    if (_.size(files) > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array", cellDates: false });
        const firstSheetName = _.filter(workbook.SheetNames, (e, i) => i == 0);
        const firstSheetData = XLSX.utils.sheet_to_json(
          workbook.Sheets[firstSheetName],
          { header: "A", blankrows: false, raw: false }
        );
        updateState({ data: firstSheetData });
        onSetDialogSize("xl");
      };
      reader.readAsArrayBuffer(file);
    } else {
      updateState({ data: [] });
    }
  };

  useEffect(() => {
    updateState({ is_loading_file: false });
  }, [state.data]);

  const handleChangeRowValuedMapping = (rowIndex, valueSelector, value) => {
    const mappingObj = state.row_value_mapping;
    if (mappingObj[rowIndex]) {
      mappingObj[rowIndex][valueSelector] = value;
    } else {
      mappingObj[rowIndex] = { [valueSelector]: value };
    }
    updateState({ row_value_mapping: mappingObj });
  };

  const handleCheckAllRows = (e) => {
    _.forEach(renderTableData(), (item, index) => {
      handleChangeRowValuedMapping(index, "checkbox", e.target.checked);
    });
  };

  const renderTableColumns = () => {
    return _.union(
      [
        {
          selector: "checkbox",
          grow: 0,
          name: (
            <TFBCheckbox
              checked={
                _.size(renderTableData()) ==
                _.size(
                  _.filter(
                    _.values(state.row_value_mapping),
                    (e) => e?.checkbox == true
                  )
                )
              }
              onChange={handleCheckAllRows}
              mode="light"
            />
          ),
          cell: (row, index) => (
            <TFBCheckbox
              checked={state.row_value_mapping?.[index]?.["checkbox"] ?? false}
              onChange={(e) =>
                handleChangeRowValuedMapping(
                  index,
                  "checkbox",
                  e.target.checked
                )
              }
              mode="light"
            />
          ),
          conditionalCellStyles: [
            {
              when: (e) => state.row_value_mapping?.[e.index]?.["checkbox"],
              style: {
                backgroundColor: "#D4D4D4 !important",
              },
            },
          ],
        },
      ],
      _.map(
        _.reduce(
          state.data,
          (result, value) => {
            return _.union(_.keys(value), result);
          },
          []
        ),
        (e, index) => {
          return {
            selector: e,
            grow: index === 0 ? 2 : 1,
            width: index === 0 ? "400px" : "",
            name: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 6.5,
                  padding: "6.5px 0",
                  width: index === 0 ? "200px" : "",
                }}
              >
                {index === 0 ? "Player Name" : state.data?.[0]?.[e]}
              </div>
            ),
            cell: (row, index) => (
              <div className={styles.importTableCell}>{row[e]}</div>
            ),
            conditionalCellStyles: [
              {
                when: (e) => state.row_value_mapping?.[e.index]?.["checkbox"],
                style: {
                  backgroundColor: "#D4D4D4",
                },
              },
            ],
          };
        }
      )
    );
  };

  const renderTableData = () => {
    const dataToRender = JSON.parse(JSON.stringify(state.data));
    return _.map(dataToRender.slice(2), (e, i) => {
      e["A"] = (
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            padding: "8px 0px 8px 0px",
            width: "300px",
            height: "100%",
          }}
          key={i}
        >
          <div
            style={{
              width: "130px",
              fontWeight: "700",
              fontSize: "14px",
              color: "#666",
              cursor: "pointer",
              marginBottom: "0 !important",
              whiteSpace: "break-spaces",
            }}
          >
            {e["A"]}
          </div>

          <TFBSelect
            isLoading={loadingClubPlayers}
            isClearable
            name="players_list"
            value={state.players_list[i + 2]}
            onChange={(selected, value) =>
              handleChangeSelect(selected, value, i + 2)
            }
            placeholder={languagePack.player_name}
            options={clubPlayers?.list}
          />
        </div>
      );
      //   else e["A"] = state.data?.[i + 2]?.["A"];
      e["index"] = i;
      return e;
    });
  };

  const { renderBtn: BtnImport } = useRenderButton(
    importPlayers,
    "green",
    "arrow-up",
    languagePack.import,
    {},
    true,
    false,
    loadingPostKSports
  );

  return (
    <>
      <TFBFileInput
        style={{
          alignItems: "flex-start",
          display: "flex",
          minWidth: "300px",
          flexDirection: "column",
          marginBottom: 20,
        }}
        label={languagePack["select_file"]}
        files={Array.from(state.file).map((elem) => elem.name)}
        onChange={handleFileUpload}
        accept=".csv,.xlsx,.xls"
      />
      {!state.is_loading_file ? (
        _.size(state.data) > 0 && (
          <div className={styles.importTableContainer}>
            <DataTable
              highlightOnHover
              columns={renderTableColumns()}
              data={renderTableData()}
              customStyles={customStyles}
              noHeader={true}
              style={{ marginBottom: "15px" }}
            />
            <div className="df df-end">
              <BtnImport />
            </div>
          </div>
        )
      ) : (
        <CircularLoader />
      )}
    </>
  );
};

const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
      backgroundColor: "rgb(250 250 250)",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
      borderRight: "1px solid #e0e0e0",
    },
  },
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(ImportKSport);
