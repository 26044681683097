import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

const ImportUpdateBonus = (props) => {
  const {
    openPopup,
    closePopup,
    refreshList,
    languagePack,
    importData,
  } = props;

  const {
    postData: importRequest,
    loading: loadingImportRequest,
  } = useAxiosPost("finances/import_bonus_monitoring_from_match");

  const handleResponse = (response) => {
    if (response?.success == 1) {
      closePopup();
      refreshList();
      showNotification(
        "success",
        languagePack.financial,
        languagePack.import_update_bonus_success
      );
    } else {
      showNotification(
        "danger",
        languagePack.financial,
        languagePack.import_update_bonus_fail
      );
    }
  };

  const importMatch = () => {
    importRequest(importData, handleResponse);
  };

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => importMatch(),
    "green",
    "arrow-right",
    languagePack.import,
    {},
    true,
    false,
    loadingImportRequest
  );

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["import_bonus_update"]}
      open={openPopup}
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <div className="pop-up-dialog-delete-text">
        {languagePack.import_update_bonus_text}
      </div>
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(ImportUpdateBonus);
