import React from "react";
import styles from "./TFBDatePicker.module.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TDatePicker } from "../types";
import TFBIcon from "../Icon/TFBIcon";

const TFBDatePicker = ({
  onChange,
  label,
  value,
  style,
  placeholder,
  className,
  name,
  error,
  helperText,
  onFocus,
  onBlur,
  ...otherProps
}: TDatePicker) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {label ? (
        <div className={styles.tfbDateGroup}>
          <h6 className={styles.dateLabel}>{label}</h6>
          <div className={styles.tfbDatePickerContainer}>
            <DatePicker
              error={error}
              helperText={helperText}
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              emptyLabel={placeholder}
              format="dd.MM.yyyy"
              inputVariant="outlined"
              className={`${styles.tfbDatePicker} ${className ? className : ""
                }`}
              style={{
                border: "1px solid #F2F2F2",
                borderColor: "#F2F2F2",
                borderRadius: "3px",
                ...style,
              }}
              name={name}
              {...otherProps}
            />
            <TFBIcon
              name="calendar"
              color="gray"
              className={styles.tfbDatePikerIcon}
            />
          </div>
        </div>
      ) : (
        <div className={styles.tfbDatePickerContainer}>
          <DatePicker
            error={error}
            helperText={helperText}
            value={value}
            onChange={onChange}
            emptyLabel={placeholder}
            format="dd.MM.yyyy"
            inputVariant="outlined"
            className={`${styles.tfbDatePicker} ${className ? className : ""}`}
            style={{
              ...style,
              border: "1px solid #F2F2F2",
              borderRadius: "3px",
            }}
            name={name}
            {...otherProps}
          />
          <TFBIcon
            name="calendar"
            color="gray"
            className={styles.tfbDatePikerIcon}
          />
        </div>
      )}
    </MuiPickersUtilsProvider>
  );
};

export default TFBDatePicker;
