import React from "react";
import axios from "axios";
import { Dropdown, Button, Icon, Checkbox } from "semantic-ui-react";
import "../Club/Club.styles.scss";
import "./AddReport.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";

class EditPlayerPhysical extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category_name: "",

      playerToEdit: this.props.playerToEdit,
      parameters: this.props.playerToEdit.values,
      currentEditPlayerValues: this.props.playerToEdit.values,

      biggerError: false,
      smallerError: false,
      emptyError: false,
    };
  }

  editPlayerFunction = () => {
    let paramsGrades = [];
    this.state.currentEditPlayerValues.forEach((param) => {
      paramsGrades.push(param.test_grade);
    });

    let paramBiggerThan = paramsGrades.some((grade) => grade > 5);
    let smallerBiggerThan = paramsGrades.some((value) => value < 1);
    let emptyValueInGrade = paramsGrades.some((grade) => grade == "");

    if (
      emptyValueInGrade == true ||
      paramsGrades.length != this.state.parameters.length
    ) {
      this.setState({ emptyError: true });
      return false;
    }

    if (paramBiggerThan == true) {
      this.setState({ biggerError: true });
      return false;
    }

    if (smallerBiggerThan == true) {
      this.setState({ smallerError: true });
      return false;
    }

    this.props.setPlayerParamValues(
      this.state.currentEditPlayerValues,
      this.props.playerToEdit.id_player
    );
    this.props.closePopup();
  };

  isDisabledButton = () => {
    let editPlayerValues = [];
    this.state.currentEditPlayerValues.forEach((element) => {
      editPlayerValues.push(element.value);
    });

    return !editPlayerValues.every((value) => value != "");
  };

  handleEditChangeParamValue = (
    e,
    param_id,
    param_name,
    param_um,
    param_index
  ) => {
    let param_value = e.target.value;
    let copyPlayers = JSON.parse(
      JSON.stringify(this.state.currentEditPlayerValues)
    );

    if (copyPlayers[param_index] != undefined) {
      copyPlayers[param_index].value = param_value;
      copyPlayers[param_index].param_name = param_name;
      copyPlayers[param_index].param_um = param_um;
      copyPlayers[param_index].param_id = param_id;
      copyPlayers[param_index].index = param_index;
    } else {
      copyPlayers[param_index] = {
        value: param_value,
        param_name: param_name,
        param_um: param_um,
        param_id: param_id,
        index: param_index,
        test_grade: "",
      };
    }

    this.setState({
      currentEditPlayerValues: copyPlayers,
    });

    this.isDisabledButton();
  };

  handleTestGrade = (gradeValue, index) => {
    let currentPlayerValues = JSON.parse(
      JSON.stringify(this.state.currentEditPlayerValues)
    );

    if (currentPlayerValues.findIndex((player) => player.index == index) > -1) {
      currentPlayerValues[index].test_grade = gradeValue;
    } else {
      currentPlayerValues[index] = {
        test_grade: gradeValue,
      };
    }

    this.setState({ currentEditPlayerValues: currentPlayerValues });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-player-form">
        <div className="add-player-header">
          <div className="add-player-title">
            {this.state.playerToEdit.player_name}
          </div>
          <div className="add-player-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => this.props.closePopup()}
            />
          </div>
        </div>

        <div className="params-physical-test-list">
          {this.state.parameters.map((item, index) => {
            return (
              <div
                className="add-player-param-row"
                key={"params-" + index}
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                  display: "flex",
                  gap: "5px",
                }}
              >
                <div className="parameter-name">{item.param_name}</div>
                <input
                  className="add-player-value-input"
                  placeholder={languagePack.value}
                  defaultValue={
                    this.state.playerToEdit.values[index] == undefined
                      ? ""
                      : this.state.playerToEdit.values[index].value
                  }
                  onChange={(e) =>
                    this.handleEditChangeParamValue(
                      e,
                      item.param_id,
                      item.param_name,
                      item.param_um,
                      index
                    )
                  }
                />
                <input
                  className="add-player-value-input"
                  onFocus={() =>
                    this.setState({
                      smallerError: false,
                      biggerError: false,
                      emptyError: false,
                    })
                  }
                  placeholder={languagePack.mark}
                  defaultValue={
                    this.state.playerToEdit.values[index] == undefined
                      ? ""
                      : this.state.playerToEdit.values[index].test_grade
                  }
                  type="number"
                  min="1"
                  max="5"
                  onChange={(e) => this.handleTestGrade(e.target.value, index)}
                />
              </div>
            );
          })}
          {this.state.smallerError && (
            <div className="ui negative message">
              {" "}
              Nota introdusa este prea mica. Notele parametrilor trebuie sa fie
              cuprinse intre 1 si 5{" "}
            </div>
          )}
          {this.state.biggerError && (
            <div className="ui negative message">
              {" "}
              Nota introdusa este prea mare. Notele parametrilor trebuie sa fie
              cuprinse intre 1 si 5{" "}
            </div>
          )}
          {this.state.emptyError && (
            <div className="ui negative message">
              {" "}
              Toate notele prametrilor trebuie introduse{" "}
            </div>
          )}
        </div>

        <div className="add-player-buttons">
          <Button
            className="custom-button-dual add-button"
            onClick={this.editPlayerFunction}
            disabled={
              this.state.currentEditPlayerValues.length !=
                this.state.parameters.length || this.isDisabledButton()
            }
          >
            {" "}
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default EditPlayerPhysical;
