import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../../components/customHooks/useAxiosPost";

import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBDropdown from "../../../../components/design-system/Dropdown/TFBDropdown";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";

import { countries } from "../../../../utils/countries";
import { getCountryCodeByName, getCountryName, removeDiacritics } from "../../../../utils/utilFunctions";

const EditNationalTeamCallUp = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, data } = props;

    const { id_club } = currentUser;

    const ageGroups = _.union([{ value: 1, label: languagePack.national_team }], _.map(_.range(21, 14, -1), e => ({ value: e, label: "U" + e })))

    const actionTypes = [{ value: "match", label: languagePack.match }, { value: "training", label: languagePack.training }]

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            national_team: getCountryCodeByName(data?.national_team, countries),
            action_date: data?.start_date ?? null,
            competition: null,
            new_competition_name: "",
            age_group: _.find(ageGroups, e => removeDiacritics(e.label) == removeDiacritics(data?.age_group)) ?? null,
            action_type: _.find(actionTypes, e => e.value == data?.action_type) ?? null,
            action_name: data?.opponent_name ?? "",
            own_goals: data?.own_goals ?? "",
            opponent_goals: data?.opponent_goals ?? "",
            played_minutes: data?.played_minutes ?? "",
            scored_goals: data?.scored_goals ?? "",
            assists: data?.assists ?? "",
            yellow_cards: data?.yellow_cards ?? "",
            red_cards: data?.red_cards ?? "",
        }
    );

    const {
        data: competitionList,
    } = useAxiosGet(`player/get_competitions_for_national_team_call_up?id_club=${id_club}`);

    useEffect(() => {
        if (competitionList) {
            updateState({ competition: _.find(competitionList, e => e.value == data?.id_competition) });
        }
    }, [competitionList])

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("player/update_national_team_call_up");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.player,
                languagePack.success_edit_national_team_call_up
            );
        } else {
            showNotification(
                "danger",
                languagePack.player,
                languagePack.fail_edit_national_team_call_up
            );
        }
    };

    const addCallUp = () => {
        const payload = {
            national_team_call_up: {
                id_national_team_call_up: data?.id_national_team_call_up,
                national_team: getCountryName(state.national_team, countries),
                age_group: state.age_group?.label ?? null,
                start_date: state.action_date,
                end_date: state.action_date,
            },
            national_team_call_up_action: {
                id_national_team_call_up_action: data?.id_national_team_call_up_action,
                action_date: state.action_date,
                action_type: state.action_type?.value ?? null,
            },
            national_team_call_up_competition: {
                id_club: id_club,
            }
        };

        if (state.competition?.value == 0) {
            payload.national_team_call_up_competition.new_competition_name = state.new_competition_name;
            payload.national_team_call_up.id_competition = null;
        } else {
            payload.national_team_call_up.id_competition = state.competition.value;
        }

        if (state.action_type?.value == "match") {
            payload.national_team_call_up_action = {
                ...payload.national_team_call_up_action, ...{
                    own_goals: state.own_goals,
                    opponent_goals: state.opponent_goals,
                    played_minutes: state.played_minutes,
                    scored_goals: state.scored_goals,
                    assists: state.assists,
                    yellow_cards: state.yellow_cards,
                    red_cards: state.red_cards,
                    opponent_name: state.action_name,
                }
            }
        } else {
            payload.national_team_call_up_action = {
                ...payload.national_team_call_up_action, ...{
                    own_goals: null,
                    opponent_goals: null,
                    played_minutes: null,
                    scored_goals: null,
                    assists: null,
                    yellow_cards: null,
                    red_cards: null,
                    opponent_name: null,
                }
            }
        }

        addRequest(payload, handleResponse);
    }

    const isValidForAdd = () => {
        if (state.action_type?.value == "match") {
            if (state.action_name == "") return false;
        }

        return state.national_team != "" && state.action_date != null && state.competition != null && state.age_group != null && state.action_type != null;
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addCallUp(),
        "green",
        "check",
        languagePack.admin_save,
        {},
        true,
        !isValidForAdd(),
        loadingAddRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["edit_national_callup"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBDropdown
                color="lightGray"
                placeholder={languagePack["select_national_team"]}
                label={languagePack["select_national_team"]}
                value={state.national_team}
                options={countries}
                selection
                name="national_team"
                search
                onChange={(e, value) => updateState({ [value.name]: value.value })}
                style={{ marginBottom: 20 }}
            />
            <TFBDatePicker
                value={state.action_date}
                onChange={(value) => updateState({ action_date: value })}
                label={languagePack["call_up_date"]}
                style={{ marginBottom: 20 }}
            />
            <TFBSelect
                label={languagePack.select_competition}
                value={state.competition}
                options={_.union([{ value: 0, label: languagePack.add_new_competition }], competitionList)}
                onChange={(selected) => updateState({ competition: selected })}
                style={{ component: { marginBottom: 20, } }}
            />
            {state.competition?.value == 0 &&
                <TFBInput
                    label={languagePack.new_competition_name}
                    value={state.new_competition_name}
                    onChange={(e) => updateState({ new_competition_name: e.target.value })}
                    style={{ marginBottom: 20, width: "100%" }}
                />
            }
            <TFBSelect
                label={languagePack.select_age_group}
                value={state.age_group}
                options={ageGroups}
                onChange={(selected) => updateState({ age_group: selected })}
                style={{ component: { marginBottom: 20, } }}
            />
            <TFBSelect
                label={languagePack.select_type_of_activity}
                value={state.action_type}
                options={actionTypes}
                onChange={(selected) => updateState({ action_type: selected })}
                style={{ component: { marginBottom: 20, } }}
            />
            {state.action_type?.value == "match" &&
                <>
                    <TFBInput
                        label={languagePack.opponent_team_name}
                        value={state.action_name}
                        onChange={(e) => updateState({ action_name: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.player_team_goals}
                        value={state.own_goals}
                        type="number"
                        onChange={(e) => updateState({ own_goals: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.opponent_team_goals}
                        value={state.opponent_goals}
                        type="number"
                        onChange={(e) => updateState({ opponent_goals: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.minutes_played}
                        value={state.played_minutes}
                        type="number"
                        onChange={(e) => updateState({ played_minutes: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.goals_scored}
                        value={state.scored_goals}
                        type="number"
                        onChange={(e) => updateState({ scored_goals: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.key_passes}
                        value={state.assists}
                        type="number"
                        onChange={(e) => updateState({ assists: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.yellow_cards_pl}
                        value={state.yellow_cards}
                        type="number"
                        onChange={(e) => updateState({ yellow_cards: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                    <TFBInput
                        label={languagePack.red_cards_pl}
                        value={state.red_cards}
                        type="number"
                        onChange={(e) => updateState({ red_cards: e.target.value })}
                        style={{ marginBottom: 20, width: "100%" }}
                    />
                </>
            }
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditNationalTeamCallUp);
