// Function to load Tidio chat script
export const loadTidioChat = () => {
    if (!document.getElementById('tidio-script')) {
        const script = document.createElement('script');
        script.id = 'tidio-script';
        script.src = '//code.tidio.co/hhozca3eutkids8uzqaizmjlvt5zbkd4.js';
        script.async = true;
        document.body.appendChild(script);
    }
}

// Function to unload Tidio chat script
export const unloadTidioChat = () => {
    const script = document.getElementById('tidio-script');
    const iframe = document.getElementById('tidio-chat-code');
    const chat = document.getElementById('tidio-chat');
    if (script) {
        script.remove();
    }
    if (iframe) {
        iframe.remove();
    }
    if (chat) {
        chat.remove();
    }

    // Optional: clear any Tidio-related data from window object
    if (window.tidioChatApi) {
        delete window.tidioChatApi;
    }
}

export const identifyVisitorForTidioChatApi = (user) => {
    function setTidioVisitorData() {
        window.tidioChatApi.setVisitorData(user);
    }

    if (window.tidioChatApi) {
        setTidioVisitorData();
    } else {
        document.addEventListener('tidioChat-ready', setTidioVisitorData);
    }
};

export const clearVisitorDataForTidioChatApi = () => {
    function resetTidioVisitorData() {
        if (window.tidioChatApi) {
            window.tidioChatApi.setVisitorData({
                distinct_id: "", // Unique visitor ID in your system
                email: "", // visitor email
                name: "", // Visitor name
                phone: "", //Visitor phone
            });
        }
    }

    if (window.tidioChatApi) {
        resetTidioVisitorData();
    } else {
        document.addEventListener('tidioChat-ready', resetTidioVisitorData);
    }
};