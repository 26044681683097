import React from "react";
import "./stepGroup.scss";
import _ from "lodash";
import MainStepItem from "./MainStepItem";
import CircularLoader from "../../../../../app/components/reusable/Loader";

const MainStepGroup = ({ mainSteps, languagePack, isQuickAction }) => {
  // RENDER COMPONENTS ===============================
  // ========================================
  return (
    <div className="main-step-group-container">
      {mainSteps ? (
        <>
          <MainStepItem
            mainSteps={mainSteps}
            languagePack={languagePack}
            isQuickAction={isQuickAction}
          />

          <div className="vertical-bar"></div>
        </>
      ) : (
        <CircularLoader />
      )}
    </div>
  );
};

export default MainStepGroup;
