import React, { useEffect, useReducer } from "react";

import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import * as XLSX from "xlsx";
import { useParams, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";

import styles from "./ImportPlayer.module.scss";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBFileInput from "../../../../components/design-system/FileInput/TFBFileInput";
import DataTable from "react-data-table-component";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import { importPlayerHeaders } from "../../../../utils/constants";
import CircularLoader from "../../../../components/reusable/Loader";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import { isTFB } from "../../../../utils/utilFunctions";
import { updateQuickSetup } from "../../../../utils/api";

const ImportPlayer = (props) => {
  const { languagePack, currentUser, updateQuickSetup } = props;
  const { id } = useParams();
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      is_loading_file: false,
      file: [],
      data: [],

      used_headers: [],
      column_field_mapping: {}, // {"E" : {value: "", label: "", ...}}
      row_value_mapping: {},
      column_date_format_mapping: {},
    }
  );

  const { data: team, loading: isTeamLoading } = useAxiosGet(
    `club/get_team?id_team=${id}`
  );

  const { renderBtn: BtnImport } = useRenderButton(
    () => importPlayers(),
    "green",
    "arrow-up",
    languagePack.import,
    {},
    true,
    _.size(
      _.filter(_.values(state.row_value_mapping), (e) => e?.checkbox == true)
    ) == 0 ||
      (_.size(_.filter(importPlayerHeaders, (e) => e?.required)) > 0 &&
        _.size(
          _.intersection(
            _.map(
              _.filter(importPlayerHeaders, (e) => e?.required),
              (e) => e.value
            ),
            _.map(state.used_headers, (e) => e.value)
          )
        ) == 0) ||
      _.size(
        _.filter(
          state.used_headers,
          (header) => header.validation_type === "date"
        )
      ) !== _.size(_.values(state.column_date_format_mapping))
  );

  const importPlayers = () => {
    let players = [];
    _.forEach(
      _.filter(
        state.data,
        (e0, i0) => state.row_value_mapping?.[i0]?.["checkbox"]
      ),
      (e1, i1) => {
        let obj = {};
        _.forIn(state.column_field_mapping, (value, key) => {
          let isValueValid = true;
          let valueToVerify = e1[key];

          if (value?.required) {
            if (_.size(_.toString(valueToVerify)) == 0) {
              obj = {};
              return false;
            }
          }

          if (valueToVerify) {
            if (value?.validation_type == "non_empty_string") {
              if (_.size(_.toString(valueToVerify)) == 0) {
                isValueValid = false;
              }
            }
            if (value?.validation_type == "date") {
              isValueValid = moment(
                valueToVerify,
                state.column_date_format_mapping?.[key]?.["formatExp"]
              ).isValid();
              if (isValueValid) {
                valueToVerify = moment(
                  valueToVerify,
                  state.column_date_format_mapping?.[key]?.["formatExp"]
                ).format("YYYY-MM-DD");
              }
            }
            if (value?.validation_type == "int_number") {
              valueToVerify = _.replace(valueToVerify, /\D/g, "");
              if (!/\d+/g.test(valueToVerify)) {
                isValueValid = false;
              }
            }
            if (value?.validation_type == "phone_number") {
              const number = _.toString(_.replace(valueToVerify, /\D/g, ""));
              if (/^07[0-9]{8}$/g.test(number)) {
                valueToVerify = "+4" + number;
              } else {
                isValueValid = false;
              }
            }

            if (value?.validation_type == "only_digits") {
              valueToVerify = _.toNumber(
                _.toString(_.replace(valueToVerify, /\D/g, ""))
              );
            }

            if (isValueValid) {
              obj[value.value] = valueToVerify;
            }
          }
        });

        if (_.size(obj) > 0) {
          players.push(obj);
        }
      }
    );

    const payload = {
      id_club: currentUser.id_club,
      id_team: id,
      players: players,
      is_tfb: isTFB(),
    };

    importRequest(payload, handleResponse);
  };

  const { postData: importRequest } = useAxiosPost("club/import_players");

  const handleResponse = (response) => {
    history.push(`/club/team/${id}`);
    _.forEach(response, (e) => {
      if (e?.success == 1) {
        let clubQuickSetup = e.club_quick_setup;
        updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });
        showNotification(
          "success",
          languagePack.club,
          languagePack.success_added_player
        );
      } else {
        showNotification(
          "danger",
          languagePack.club,
          languagePack.fail_added_player
        );
      }
    });
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    updateState({
      file: files,
      is_loading_file: true,
      used_headers: [],
      column_field_mapping: {},
      row_value_mapping: {},
    });
    if (_.size(files) > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array", cellDates: false });
        const firstSheetName = _.filter(workbook.SheetNames, (e, i) => i == 0);
        const firstSheetData = XLSX.utils.sheet_to_json(
          workbook.Sheets[firstSheetName],
          { header: "A", blankrows: false, raw: false }
        );
        updateState({ data: firstSheetData });
      };
      reader.readAsArrayBuffer(file);
    } else {
      updateState({ data: [] });
    }
  };

  useEffect(() => {
    updateState({ is_loading_file: false });
  }, [state.data]);

  const handleChangeColumnFieldMapping = (column, field) => {
    const mappingObj = state.column_field_mapping;
    const dateFormatMappingObj = state.column_date_format_mapping;
    if (field) {
      mappingObj[column] = field;
    } else {
      delete mappingObj[column];
      delete dateFormatMappingObj[column];
    }
    updateState({
      column_field_mapping: mappingObj,
      column_date_format_mapping: dateFormatMappingObj,
      used_headers: _.values(mappingObj),
    });
  };

  const handleChangeColumnDateFormatMapping = (column, field) => {
    const dateFormatMappingObj = state.column_date_format_mapping;
    if (field) {
      dateFormatMappingObj[column] = field;
    } else {
      delete dateFormatMappingObj[column];
    }
    updateState({ column_date_format_mapping: dateFormatMappingObj });
  };

  const handleChangeRowValuedMapping = (rowIndex, valueSelector, value) => {
    const mappingObj = state.row_value_mapping;
    if (mappingObj[rowIndex]) {
      mappingObj[rowIndex][valueSelector] = value;
    } else {
      mappingObj[rowIndex] = { [valueSelector]: value };
    }
    updateState({ row_value_mapping: mappingObj });
  };

  const handleCheckAllRows = (e) => {
    _.forEach(renderTableData(), (item, index) => {
      handleChangeRowValuedMapping(index, "checkbox", e.target.checked);
    });
  };

  const renderTableColumns = () => {
    return _.union(
      [
        {
          selector: "checkbox",
          grow: 0,
          name: (
            <TFBCheckbox
              checked={
                _.size(renderTableData()) ==
                _.size(
                  _.filter(
                    _.values(state.row_value_mapping),
                    (e) => e?.checkbox == true
                  )
                )
              }
              onChange={handleCheckAllRows}
              mode="light"
            />
          ),
          cell: (row, index) => (
            <TFBCheckbox
              checked={state.row_value_mapping?.[index]?.["checkbox"] ?? false}
              onChange={(e) =>
                handleChangeRowValuedMapping(
                  index,
                  "checkbox",
                  e.target.checked
                )
              }
              mode="light"
            />
          ),
          conditionalCellStyles: [
            {
              when: (e) => state.row_value_mapping?.[e.index]?.["checkbox"],
              style: {
                backgroundColor: "#D4D4D4 !important",
              },
            },
          ],
        },
      ],
      _.map(
        _.reduce(
          state.data,
          (result, value) => {
            return _.union(result, _.keys(value));
          },
          []
        ),
        (e, index) => ({
          selector: e,
          grow: 1,
          name: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 6.5,
                padding: "6.5px 0",
              }}
            >
              <TFBSelect
                style={{
                  container: { width: "230px" },
                  control: { backgroundColor: "white" },
                }}
                options={_.difference(importPlayerHeaders, state.used_headers)}
                value={state.column_field_mapping?.[e] ?? null}
                onChange={(selected) =>
                  handleChangeColumnFieldMapping(e, selected)
                }
                isClearable={true}
              />
              {state.column_field_mapping?.[e]?.validation_type == "date" && (
                <TFBSelect
                  label={languagePack.select_date_format}
                  style={{
                    container: { width: "230px" },
                    control: { backgroundColor: "white" },
                  }}
                  options={languagePack.date_format_list}
                  value={state.column_date_format_mapping?.[e] ?? null}
                  onChange={(selected) =>
                    handleChangeColumnDateFormatMapping(e, selected)
                  }
                  isClearable={true}
                />
              )}
            </div>
          ),
          cell: (row, index) => (
            <div className={styles.importTableCell}>{row[e]}</div>
          ),
          conditionalCellStyles: [
            {
              when: (e) => state.row_value_mapping?.[e.index]?.["checkbox"],
              style: {
                backgroundColor: "#D4D4D4",
              },
            },
          ],
        })
      )
    );
  };

  const renderTableData = () => {
    return _.map(state.data, (e, i) => {
      e["index"] = i;
      return e;
    });
  };

  return (
    <TFBCard>
      {!isTeamLoading ? (
        <>
          <TFBCardHeader
            title={
              languagePack["import_players"] + " - " + team?.data?.team_name
            }
          />
          <TFBCardBody>
            <TFBFileInput
              style={{
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
              label={languagePack["select_file"]}
              files={Array.from(state.file).map((elem) => elem.name)}
              onChange={handleFileUpload}
              accept=".csv,.xlsx,.xls"
            />
            {!state.is_loading_file ? (
              _.size(state.data) > 0 && (
                <div className={styles.importTableContainer}>
                  <DataTable
                    highlightOnHover
                    columns={renderTableColumns()}
                    data={renderTableData()}
                    customStyles={customStyles}
                    noHeader={true}
                    style={{ marginBottom: "15px" }}
                  />
                  <div className="df df-end">
                    <BtnImport />
                  </div>
                </div>
              )
            ) : (
              <CircularLoader />
            )}
          </TFBCardBody>
        </>
      ) : (
        <CircularLoader />
      )}
    </TFBCard>
  );
};

const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
      backgroundColor: "rgb(250 250 250)",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      paddingLeft: "8px",
      paddingRight: "8px",
      borderRight: "1px solid #e0e0e0",
    },
  },
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates,
  }),
  mapDispatchToProps
)(ImportPlayer);
