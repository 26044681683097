import React from "react";
import styles from "./TFBPasswordInput.module.scss";
import { TPassInput } from "./../types";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "50px",
        },
      },
    },
  },
});

const TFBPasswordInput = ({
  className,
  fullWidth,
  label,
  onChange,
  placeholder,
  style,
  value,
  wrapperStyle,
}: TPassInput) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className={styles.inputPassGroup} style={wrapperStyle}>
      {label && <h6 className={styles.inputPassLabel}>{label}</h6>}
      <ThemeProvider theme={theme}>
        <OutlinedInput
          type={showPassword ? "text" : "password"}
          className={`${styles.tfbPassInput} ${className ? className : ""}`}
          style={{ ...style, backgroundColor: "#fafafa" }}
          placeholder={placeholder}
          fullWidth={fullWidth}
          onChange={onChange}
          value={value}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </ThemeProvider>
    </div>
  );
};

export default TFBPasswordInput;
