import React from "react";
import axios from "axios";
import { Button, Icon, TextArea } from "semantic-ui-react";
import { store } from "react-notifications-component";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import delete_icon from "../../../../assets/icons/delete.png";
import edit_icon from "../../../../assets/icons/edit.png";
import "./EditNews.scss";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import EditSection from "./EditSection";

const options = [
  { key: "competitions", text: "Competitii", value: "Competitii" },
  { key: "results", text: "Rezultate", value: "Rezultate" },
  { key: "birthdays", text: "Aniversari", value: "Aniversari" },
];

class EditNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      tag: "",
      image: "",
      content: [],
      elementToAdd: "",
      elementToAddValue: "",
      sectionsLoaded: false,
      editedElementValue: "",
      editedElementIndex: "",
    };
  }

  componentDidMount = () => {
    this.getNewsContent();
  };

  getNewsContent = () => {
    axios
      .get(`site/get_news_content?id_news=${this.props.match.params.id}`)
      .then((res) => {
        this.setState({
          title: res.data.title,
          tag: res.data.tag,
          content: res.data.content,
          image: res.data.image,
          sectionsLoaded: true,
        });
      });
  };

  handleChangeInput = (e, { name, value }) => this.setState({ [name]: value });

  handleChangeDropdown = (e, value) => {
    this.setState({
      [value.name]: value.value,
    });
  };

  refreshInputs = () => {
    this.setState({
      elementToAdd: "",
      elementToAddValue: "",
    });
  };

  addSection = () => {
    const newSection = {
      type: this.state.elementToAdd,
      value: this.state.elementToAddValue,
    };

    this.setState(
      { content: [...this.state.content, newSection] },
      this.refreshInputs
    );
  };

  deleteSection = (id) => {
    let deletedElement = this.state.content.splice(id, 1);
    let deletedElementValue = deletedElement.value;
    let filteredArray = this.state.content.filter(
      (item) => item.value !== deletedElementValue
    );
    this.setState({ content: filteredArray });
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file: file,
        elementToAddValue: reader.result,
      });
    };
  };

  handleChangeMainImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        image: reader.result,
      });
    };
  };

  submitNewArticle = () => {
    const data = new FormData();
    data.append("id_news", this.props.match.params.id);
    data.append("title", this.state.title);
    data.append("tag", this.state.tag);
    data.append("image", this.state.image);

    var total_images = 0;
    var total_text_elements = 0;
    var order = 0;
    for (var i = 0; i < this.state.content.length; i++) {
      if (this.state.content[i]["type"] === "Imagine") {
        data.append(`image_${order}`, this.state.content[i]["value"]);
        total_images++;
      } else {
        data.append(
          `text_${total_text_elements}`,
          `${order}_${this.state.content[i]["type"]}_${this.state.content[i]["value"]}`
        );
        total_text_elements++;
      }
      order++;
    }
    data.append("total_images", total_images);
    data.append("total_text_elements", total_text_elements);

    axios
      .post("site/edit_news", data)
      .then((res) => {
        if (res.data.success === 1) {
          store.addNotification({
            title: "Administrare stiri",
            message: "Stirea a fost modificata cu succes",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.props.history.push("/site/news");
        } else {
          store.addNotification({
            title: "Administrare stiri",
            message: "Eroare la salvarea modificarilor.",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  deleteArticle = () => {
    const payload = {
      id_news: this.props.match.params.id,
    };

    axios
      .post("site/delete_news", payload)
      .then(() => {
        store.addNotification({
          title: "Administrare stiri",
          message: "Stirea a fost stearsa cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.props.history.push("/site/news");
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    // const textarea = document.getElementsByClassName("edit-section-text-area");

    // textarea.forEach((elem) => {
    //   elem.addEventListener("input", function(e) {
    //     this.style.height = "auto";
    //     this.style.height = this.scrollHeight + "px";
    //   });
    // });

    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="add-news-container">
              <div className="add-news-header">
                <div className="add-news-title">
                  {languagePack["edit_news_title"]}
                </div>
              </div>
              <div className="add-news-form-container">
                <div className="add-news-form">
                  <div className="add-news-row">
                    <input
                      className="title-input"
                      placeholder={languagePack["add_news_article_title"]}
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div className="add-news-row" style={{ marginBottom: 50 }}>
                    <div className="image-input-container">
                      <input
                        type="file"
                        className="add-news-image-input"
                        id="customFile"
                        onChange={this.handleChangeMainImage}
                      />
                      <label
                        className="add-file-form-file-label"
                        htmlFor="customFile"
                      >
                        {this.state.image !== ""
                          ? languagePack["upload_another_main_image"]
                          : languagePack["upload_main_image"]}
                      </label>
                      <Icon
                        name="arrow up"
                        className="cusor-pointer grey-button-icon"
                        size="small"
                      />
                    </div>
                    <select
                      value={this.state.tag}
                      onChange={(e) => this.setState({ tag: e.target.value })}
                    >
                      <option value="" disabled selected>
                        Tag
                      </option>
                      {options.map((item) => {
                        return (
                          <option value={item.value} key={item.key}>
                            {item.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="sections-title">
                    {languagePack["sections"]}
                  </div>
                  {!this.state.sectionsLoaded ? (
                    <div className="loader-container">
                      <Loader
                        type="TailSpin"
                        color={window.clubCustomization.primary_color}
                        height={40}
                        width={40}
                      />
                    </div>
                  ) : (
                    <div className="add-news-sections-list">
                      {this.state.content.map((item, index) => {
                        return (
                          <div
                            className="add-news-section"
                            key={index}
                            style={{
                              backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                            }}
                          >
                            <div className="add-news-section-type">
                              {index + 1}. {item.type}
                            </div>

                            <div className="add-news-section-preview">
                              {(item.type === "Paragraf" ||
                                item.type === "Subtitlu" ||
                                item.type === "Text evidentiat") &&
                                item.value}
                            </div>

                            <div className="add-news-section-options">
                              {item.type !== "Imagine" && (
                                <img
                                  src={edit_icon}
                                  alt="edit"
                                  onClick={() =>
                                    this.setState({
                                      editedElementIndex: index,
                                      editedElementValue: item.value,
                                    })
                                  }
                                />
                              )}
                              <img
                                src={delete_icon}
                                alt="edit"
                                onClick={() => this.deleteSection(index)}
                                style={{ marginLeft: 20 }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {(this.state.elementToAdd === "Paragraf" ||
                    this.state.elementToAdd === "Subtitlu" ||
                    this.state.elementToAdd === "Text evidentiat") && (
                    <div className="element-to-add-container">
                      <TextArea
                        placeholder={this.state.elementToAdd}
                        className="text-area-add-news"
                        value={this.state.elementToAddValue}
                        onChange={this.handleChangeInput}
                        name="elementToAddValue"
                      />

                      <div className="element-to-add-buttons">
                        <Button
                          className="add-news-button dark-background"
                          onClick={this.addSection}
                          style={{ width: "48%" }}
                        >
                          {languagePack["admin_add"]}
                          <Icon name="add" className="add-news-button-icon" />
                        </Button>
                        <Button
                          className="add-news-button light-background"
                          style={{
                            width: "48%",
                            marginLeft: 15,
                          }}
                          onClick={() =>
                            this.setState({
                              elementToAdd: "",
                              elementToAddValue: "",
                            })
                          }
                        >
                          {languagePack["admin_cancel"]}
                          <Icon
                            name="close"
                            className="add-news-button-icon-dark"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                  {this.state.elementToAdd === "Imagine" && (
                    <div className="add-news-image-container">
                      <div className="add-news-image-preview">
                        {this.state.elementToAddValue !== "" && (
                          <img src={this.state.elementToAddValue} />
                        )}
                      </div>
                      <div className="add-news-image-right-side">
                        <div className="image-input-container  element-to-add-image-input">
                          <input
                            type="file"
                            className="add-news-image-input"
                            id="section-image"
                            onChange={this.handleChangeImage}
                          />
                          <label
                            className="add-file-form-file-label"
                            htmlFor="section-image"
                          >
                            {this.state.elementToAddValue !== ""
                              ? languagePack["upload_other_image"]
                              : languagePack["upload_image"]}
                          </label>
                          <Icon
                            name="arrow up"
                            className="cusor-pointer grey-button-icon"
                            size="small"
                          />
                        </div>

                        <div className="element-to-add-buttons">
                          <Button
                            className="add-news-button dark-background"
                            onClick={this.addSection}
                            style={{ width: "48%" }}
                          >
                            {languagePack["admin_add"]}
                            <Icon name="add" className="add-news-button-icon" />
                          </Button>
                          <Button
                            className="add-news-button light-background"
                            style={{
                              width: "48%",
                            }}
                            onClick={() =>
                              this.setState({
                                elementToAdd: "",
                                elementToAddValue: "",
                              })
                            }
                          >
                            {languagePack["admin_cancel"]}
                            <Icon
                              name="close"
                              className="add-news-button-icon-dark"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="add-options-buttons">
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({ elementToAdd: "Subtitlu" })
                      }
                      style={{ marginBottom: 15 }}
                    >
                      Subtitlu
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({ elementToAdd: "Paragraf" })
                      }
                      style={{ marginBottom: 15 }}
                    >
                      Paragraf
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({ elementToAdd: "Text evidentiat" })
                      }
                    >
                      Citat/ Paragraf evidentiat
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                    <Button
                      className="add-news-button dark-background"
                      onClick={() => this.setState({ elementToAdd: "Imagine" })}
                    >
                      Imagine
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="news-article-final-buttons">
                <Button
                  className="add-news-button"
                  onClick={this.submitNewArticle}
                  style={{ width: "66%" }}
                >
                  {languagePack["add_news_publish_button"]}
                  <Icon name="add" className="add-news-button-icon" />
                </Button>
                <Button
                  className="add-news-button dark-background"
                  style={{ width: "32%" }}
                  onClick={this.deleteArticle}
                >
                  {languagePack["edit_news_delete_button"]}
                  <Icon name="close" className="add-news-button-icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="news-article-preview-container">
              <div className="news-article-preview-header">
                <div className="preview-title">
                  {languagePack["article_preview"]}
                </div>
              </div>
              {!this.state.sectionsLoaded ? (
                <div className="loader-container">
                  <Loader
                    type="TailSpin"
                    color={window.clubCustomization.primary_color}
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <div className="news-article-preview-content">
                  {this.state.image !== "" && (
                    <div className="preview-main-image-container">
                      <img src={this.state.image} />
                    </div>
                  )}
                  <div className="preview-article-title">
                    {this.state.title}
                  </div>

                  <div className="preview-text-container">
                    {this.state.content.map((item, index) => {
                      return (
                        <>
                          {item.type === "Paragraf" && (
                            <div className="preview-paragraph-container">
                              {item.value}
                            </div>
                          )}
                          {item.type === "Subtitlu" && (
                            <div className="preview-subtitle-container">
                              {item.value}
                            </div>
                          )}
                          {item.type === "Imagine" && (
                            <div className="preview-image-container">
                              <img src={item.value} />
                            </div>
                          )}
                          {item.type === "Text evidentiat" && (
                            <div className="preview-highlighted-container">
                              {item.value}
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.editedElementIndex !== ""}
          // onClose={() => this.setState({ editedElementIndex: "" })}
          maxWidth="xl"
        >
          <DialogContent>
            <EditSection
              closePopup={() =>
                this.setState({ editedElementIndex: "" }, this.getNewsContent)
              }
              value={this.state.editedElementValue}
              index={this.state.editedElementIndex}
              id_news={this.props.match.params.id}
              id_club={this.props.currentUser.id_club}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditNews);
