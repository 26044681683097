import React from "react";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon, Button } from "semantic-ui-react";
import { store } from "react-notifications-component";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Dialog, DialogContent } from "@material-ui/core";

import "./NecessityReport.scss";
import AddNecessityReport from "./AddNecessityReport.jsx";
import UpdateNecessityReport from "./UpdateNecessityReport";
import { logout } from "./../../../utils/api";
import { baseUrl } from "../../../utils/utilFunctions";
import permissions from "./../../../../app/utils/permissions";
import * as myConstants from "./../../../utils/constants";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../../components/reusable/Loader";

const statusList = {
  approved: {
    text: "Aprobat",
    color: "var(--primaryColor)",
  },
  partial_approved: {
    text: "Partial aprobat",
    color: "#00aaff",
  },
  waiting: {
    text: "În așteptare",
    color: "#eedb00",
  },
  declined: {
    text: "Respins",
    color: "#d91200",
  },
};

class NecessityReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      reports: [],
      last_registration_number: 1,
      open_add_report_popup: false,

      report_to_delete: "",
      open_delete_popup: false,

      report_to_edit: "",
      open_edit_report_popup: false,

      report_to_approve: "",
      open_approve_report_popup: false,
      approve_reason: "",
    };
  }

  componentDidMount = () => {
    this.getReports();
  };

  getReports = () => {
    axios
      .get(
        `finances/get_necessary_reports_new?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`
      )
      .then((res) => {
        if (res.data.success == 1) {
          if (res.data.list.length > 0) {
            //find last register number
            let registrationNumbers = [];
            res.data.list.forEach((elem) => {
              registrationNumbers.push(Number(elem.registration_number));
            });
            const lastRegistrationNumber = Math.max(...registrationNumbers);
            this.setState({
              reports: res.data.list,
              is_loading: false,
              last_registration_number: lastRegistrationNumber,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  deleteReport = () => {
    const payload = {
      id_necessity_report: this.state.report_to_delete,
    };

    axios.post("finances/delete_necessary_report", payload).then((res) => {
      if (res.data == 1) {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate a fost sters cu suscces!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        this.getReports();
      } else {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate nu a fost sters cu suscces!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      this.closeDeletePopup();
    });
  };

  approveReport = () => {
    const payload = {
      id_necessity_report: this.state.report_to_approve.id_necessity_report,
      status_justification: this.state.approve_reason,
      id_user: this.props.currentUser.id_user,
    };

    axios.post("finances/approve_necessary_report", payload).then((res) => {
      if (res.status == 200) {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate a fost aprobat cu suscces!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        this.getReports();
      } else {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate nu a fost aprobat cu suscces!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      this.closeApprovePopup();
    });
  };

  disapproveReport = () => {
    const payload = {
      id_necessity_report: this.state.report_to_approve.id_necessity_report,
      status_justification: this.state.approve_reason,
      id_user: this.props.currentUser.id_user,
    };

    axios.post("finances/disapprove_necessary_report", payload).then((res) => {
      if (res.status == 200) {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate a fost respins cu suscces!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        this.getReports();
      } else {
        store.addNotification({
          title: "Necessity report",
          message: "Referatul de necesitate nu a fost respins cu suscces!",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      this.closeApprovePopup();
    });
  };

  closeDeletePopup = () => {
    this.setState({
      open_delete_popup: false,
      report_to_delete: "",
    });
  };

  closeEditPopup = () => {
    this.setState({
      open_edit_report_popup: false,
      report_to_edit: "",
    });
  };

  closeApprovePopup = () => {
    this.setState({
      open_approve_report_popup: false,
      report_to_approve: "",
      approve_reason: "",
    });
  };

  render() {
    const { languagePack, user_permissions } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.necessity_reports}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => this.setState({ open_add_report_popup: true })}
          >
            {languagePack.add_necessity_report}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {!this.state.is_loading ?
            (_.size(this.state.reports) > 0 ?
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TFBTableCell align="center" width={100}>{languagePack.criteria_number}</TFBTableCell>
                      <TFBTableCell>{languagePack.made_by}</TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.registration_number}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.injury_date}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.purchase_category}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.trasnaction_amount}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.product_status}
                      </TFBTableCell>
                      <TFBTableCell align="right">
                        {languagePack.news_page_header_options}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(this.state.reports, (row, index) => (
                      <TableRow
                        key={"necessity-reports-row-" + index}
                        sx={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell align="center">{index + 1}</TFBTableCell>
                        <TFBTableCell>{row.added_by}</TFBTableCell>
                        <TFBTableCell align="center">{row.registration_number}</TFBTableCell>
                        <TFBTableCell align="center">{moment(row.registration_date).format("DD.MM.yyyy")}</TFBTableCell>
                        <TFBTableCell align="center">{row.category}</TFBTableCell>
                        <TFBTableCell align="center">{row.total_value + " RON"}</TFBTableCell>
                        <TFBTableCell
                          align="center"
                          style={{ color: statusList?.[row.status]?.color }}
                        >
                          {statusList?.[row.status]?.text + ({
                            "partial_approved": ` ${row.nr_of_approvals}/2`,
                            "approved": ` ${row.nr_of_approvals}/2`,
                          }?.[row.status] ?? "")}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          <div
                            className="actions-btns-container"
                            style={{ justifyContent: "flex-end" }}
                          >
                            {user_permissions?.[permissions.FINANCIAL]?.[permissions.NECCESSITY_REPORT]?.[permissions.APPROVE_NECESSITY_REPORT] &&
                              <TFBIconButton
                                name="check"
                                color="darkGray"
                                onClick={() =>
                                  this.setState({
                                    report_to_approve: row,
                                    open_approve_report_popup: true,
                                  })
                                }
                              />
                            }
                            <a
                              target="_blank"
                              href={baseUrl(`finances/view_necessary_report?id_report=${row.id_necessity_report}&token=${this.props.token}`)}
                            >
                              <TFBIconButton
                                name="view"
                                color="darkGray"
                              />
                            </a>
                            <a
                              href={baseUrl(`finances/download_necessary_report?id_report=${row.id_necessity_report}&token=${this.props.token}`)}
                            >
                              <TFBIconButton
                                name="arrow-down"
                                color="darkGray"
                              />
                            </a>
                            {row.status != "approved" &&
                              <TFBIconButton
                                name="edit"
                                color="darkGray"
                                onClick={() =>
                                  this.setState({
                                    report_to_edit: row["id_necessity_report"],
                                    open_edit_report_popup: true,
                                  })
                                }
                              />
                            }
                            <TFBIconButton
                              name="delete"
                              color="darkGray"
                              onClick={() =>
                                this.setState({
                                  report_to_delete: row["id_necessity_report"],
                                  open_delete_popup: true,
                                })
                              }
                            />
                          </div>
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> :
              <TFBPlaceholder text={languagePack.no_necessity_report} />
            ) :
            <CircularLoader />
          }
        </TFBCardBody>
        {/* ADAUGARE RAPORT DE NECESITATE */}
        <Dialog open={this.state.open_add_report_popup}>
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.add_the_necessity_report}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() =>
                  this.setState({
                    open_add_report_popup: false,
                  })
                }
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="add-file-container-all">
            <AddNecessityReport
              lastRegistrationNumber={this.state.last_registration_number + 1}
              refreshReports={this.getReports}
              closePopup={() =>
                this.setState({
                  open_add_report_popup: false,
                })
              }
            />
          </DialogContent>
        </Dialog>

        {/* DELETE REPORT */}
        <Dialog open={this.state.open_delete_popup} maxWidth="xl">
          <DialogContent>
            <div className="delete-competition-popup-container">
              <div className="delete-competition-header">
                <div className="delete-competition-title">
                  {languagePack.delete_necessity_report}
                </div>
                <Icon
                  name="close"
                  className="close"
                  size="large"
                  onClick={this.closeDeletePopup}
                />
              </div>
              <div className="delete-competition-text">
                {languagePack.delete_necessity_report_text}
              </div>
              <div className="delete-competition-buttons">
                <Button
                  className="cancel-user-mobile-button"
                  onClick={this.closeDeletePopup}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="close" className="white-icon" />
                </Button>
                <Button
                  className="delete-user-mobile-button"
                  onClick={this.deleteReport}
                >
                  {languagePack["admin_delete"]}
                  <Icon name="trash alternate outline" className="white-icon" />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>

        {/* EDITARE RAPORT DE NECESITATE */}
        <Dialog open={this.state.open_edit_report_popup}>
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.edit_necessity_report}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={this.closeEditPopup}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent className="add-file-container-all">
            <UpdateNecessityReport
              refreshReports={this.getReports}
              closePopup={this.closeEditPopup}
              idReport={this.state.report_to_edit}
            />
          </DialogContent>
        </Dialog>

        {/* APROBARE RAPORT DE NECESITATE */}
        <Dialog open={this.state.open_approve_report_popup} maxWidth="xl">
          <DialogContent>
            <div className="delete-competition-popup-container">
              <div className="delete-competition-header gap-40">
                <div className="delete-competition-title">
                  {languagePack.approval_disapproval_of_necessity_report}
                </div>
                <Icon
                  name="close"
                  className="close"
                  size="big"
                  onClick={this.closeApprovePopup}
                />
              </div>
              <div className="approve-necessity-report-container">
                <div className="approve-necessity-report-row">
                  <label className="label-approve-reason">
                    {languagePack.necessity_report_reason}
                    <span style={{ color: "red", fontSize: "11px" }}>
                      {languagePack.in_case_of_disapproval_is_mandatory_field}
                    </span>
                  </label>
                  <textarea
                    id="approve_reason"
                    name="approve_reason"
                    className="add-necessity-report-form-input"
                    rows="3"
                    placeholder="Introdu motivul..."
                    value={this.state.approve_reason}
                    onChange={(e) =>
                      this.setState({
                        approve_reason: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="delete-competition-buttons">
                <Button
                  className="delete-user-mobile-button"
                  onClick={this.disapproveReport}
                  disabled={this.state.approve_reason.trim() == ""}
                >
                  {"Respinge"}
                  <Icon
                    name="window close outline"
                    className="white-icon"
                    size="large"
                  />
                </Button>
                <Button
                  className="add-file-button"
                  onClick={this.approveReport}
                >
                  {"Aproba"}
                  <Icon
                    name="check circle outline"
                    className="white-icon"
                    size="large"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </TFBCard>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
    user_permissions: auth?.user?.user_permissions
  }),
  mapDispatchToProps
)(NecessityReport);
