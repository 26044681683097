import React from "react";
import styles from "./DemoVideoItem.module.scss";
import TFBIconButton from "../components/design-system/IconButton/TFBIconButton";

function DemoVideoItem({ title, onButtonClick, src }) {
    return (
        <div className={styles.videoItemDiv}>
            <p>{title}</p>
            <div onClick={() => onButtonClick(title, src)} className={styles.imgDiv}>
                <div className={styles.playVideoTutorialButtonDiv}>
                    <TFBIconButton
                        classNameIcon={styles.playVideoTutorialIcon}
                        name="play"
                        color="green"
                    />
                </div>
                <img className={styles.img} src={src} alt="video tutorial" />
            </div>
        </div>
    );
}

export default DemoVideoItem;