import React from "react";

import { logout } from "./../../../utils/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import _ from "lodash";

import styles from "./Evaluation.module.scss";

import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBInput from "../../../components/design-system/Input/TFBInput";

class EvaluationTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: props.params,
            players: props.players,

            columns: [],
            data: props.players,
        };
    }

    handleInputDescription = (value, idPlayer, idSubcategory, idCategory) => {
        let currentState = this.state.testData;

        const index = currentState
            .map((object) => object.id_player)
            .indexOf(idPlayer);

        const mark = currentState[index].marks[idSubcategory].mark;
        const infoMark = {
            mark: mark,
            description: value,
            id_category: idCategory,
            id_subcategory: idSubcategory,
        };
        currentState[index].marks[idSubcategory] = infoMark;

        this.setState({ testData: currentState });
    };

    handleChangePlayerEvaluationData = (playerIndex, paramId, field, value) => {
        let cloned_players = _.clone(this.state.data);
        cloned_players[playerIndex].test_result[paramId][field] = value;

        this.setState({ data: cloned_players });
        this.props.getChildData(cloned_players);
    }

    generateDataTable = () => {
        const { languagePack } = this.props;

        const columns = _.union(
            [
                {
                    selector: "player_name",
                    name: languagePack.player_name,
                    maxWidth: "fit-content",
                    minWidth: "200px",
                    cell: (row, index) => (
                        <TFBPlayerWithAvatar
                            playerName={row?.player_name}
                            avatarUrl={row?.photo}
                        />
                    ),
                    style: { justifyContent: "flex-start" },
                },
                {
                    selector: "team_name",
                    name: languagePack.team,
                    center: true,
                    cell: (row, index) => row.team_name,
                },
            ],
            _.map(this.state.params, (m, i) => (
                {
                    selector: m.value,
                    name: m.label,
                    center: true,
                    maxWidth: "250px",
                    minWidth: "250px",
                    cell: (row, index) => (
                        <div>
                            <TFBInput
                                label={languagePack.grade}
                                type="number"
                                makeStylesContent={{
                                    "& .MuiOutlinedInput-root": {
                                        minHeight: "initial !important",
                                    },
                                    "& .MuiOutlinedInput-root > input": {
                                        padding: "5px !important",
                                    }
                                }}
                                defaultValue={row?.test_result?.[m.value]?.grade ?? ""}
                                inputProps={{ max: 5, min: 1 }}
                                onChange={e => this.handleChangePlayerEvaluationData(index, m.value, "grade", e.target.value)}
                            />
                            <TFBInput
                                label={languagePack.details}
                                makeStylesContent={{
                                    "& .MuiOutlinedInput-root": {
                                        minHeight: "initial !important",
                                    },
                                    "& .MuiOutlinedInput-root > input": {
                                        padding: "5px !important",
                                    }
                                }}
                                defaultValue={row?.test_result?.[m.value]?.description ?? ""}
                                onChange={e => this.handleChangePlayerEvaluationData(index, m.value, "description", e.target.value)}
                            />
                        </div>
                    ),
                }
            ))
        );

        this.setState({ columns: columns })

    };

    componentDidMount = () => {
        this.generateDataTable();
    };

    render() {
        return (
            <div className={styles.suspensionTableContainer}>
                <DataTable
                    noHeader
                    center
                    columns={this.state.columns}
                    data={this.state.data}
                    highlightOnHover
                    customStyles={customStyles}
                    fixedHeader
                    fixedHeaderScrollHeight="max-content"
                />
            </div>
        );
    }
}
const customStyles = {
    headCells: {
        style: {
            backgroundColor: "#EEEEEE",
            paddingLeft: "8px",
            paddingRight: "8px",
            textAlign: "center",
            width: "fit-content",
        },
    },
    cells: {
        style: {
            justifyContent: "center",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            borderRight: "1px solid #e0e0e0",
        },
    },
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
}
export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(EvaluationTable);
