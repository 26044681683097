import React from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import ExpandedCashflow from "./ExpandedCashflow";
import CircularLoader from "../../../components/reusable/Loader";
import { Icon } from "semantic-ui-react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { store } from "react-notifications-component";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NumberFormat from "react-number-format";
import * as constants from "./../../../../app/utils/constants";

const categoryTypes = {
  1: "income",
  2: "expenditure",
};
const defaultFlowColumns = [
  {
    name: "Nume",
    selector: "name",
    sortable: true,
    grow: 2,
  },
  {
    center: true,
    name: "Ianuarie",
    selector: "january",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Februarie",
    selector: "february",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Martie",
    selector: "march",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Aprilie",
    selector: "april",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Mai",
    selector: "may",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Iunie",
    selector: "june",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Iulie",
    selector: "july",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "August",
    selector: "august",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Septembrie",
    selector: "september",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Octombrie",
    selector: "october",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Noiembrie",
    selector: "november",
    sortable: true,
    type: "number",
  },
  {
    center: true,
    name: "Decembrie",
    selector: "december",
    sortable: true,
    type: "number",
  },
];
class Cashflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isFetching: true,
      selectedYear: 2020,
      addElementOpen: false,
      addedElement: "",
      addNewElementCategory: "",
      addNewElementCategoryName: "",
      // delete element from table
      deleteElementOpen: false,
      deleteElementCategory: "",
      deleteElementCategoryName: "",
      // state to update current expanded cashflow
      updateExpandedCashflow: false,
    };
  }

  componentDidMount() {
    this.getCateogoriesOfType();
  }

  getCateogoriesOfType = () => {
    const cashflow_type = categoryTypes[this.props.data.id];
    if (!this.state.isFetching) {
      this.setState(
        {
          isFetching: true,
        },
        () => {
          this.getCateogoriesOfType();
        }
      );
      return;
    }

    axios
      .get(`finances`, {
        params: {
          year: this.props.selectedYear,
          cashflow_type,
          id_club: this.props.currentUser.id_club,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ data: res.data, isFetching: false });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        this.setState({
          isFetching: false,
        });
      });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.data.refreshCashflowKey !==
      this.props.data.refreshCashflowKey ||
      prevProps.selectedYear !== this.props.selectedYear
    ) {
      this.getCateogoriesOfType();
    }
  };

  addNewElementCategory = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      id_category: this.state.addNewElementCategory,
      element_name: this.state.addedElement,
    };
    axios
      .post("finances/add_element/", payload, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.getCateogoriesOfType();
          this.setState({
            addElementOpen: false,
            addedElement: "",
            updateExpandedCashflow: !this.state.updateExpandedCashflow,
          });
          store.addNotification({
            title: "Operatiuni cashflow",
            message: "Elementul a fost adaugat cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  deleteElementCategory = () => {
    const payload = {
      id_club: this.props.currentUser.id_club,
      id_cashflow_category: this.state.deleteElementCategory,
      id_cashflow_name: this.state.deleteElementCategoryName,
    };
    axios
      .post("finances/delete_cashflow_category/", payload, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.getCateogoriesOfType();
          this.setState({ deleteElementOpen: false });
          store.addNotification({
            title: "Operatiuni cashflow",
            message: "Elementul a fost eliminat cu succes",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleChange = (event) => {
    this.setState({ addedElement: event.target.value });
  };

  handleCloseDialog = () => {
    this.setState({
      addElementOpen: false,
      addedElement: "",
      deleteElementOpen: false,
    });
  };
  convertCurrency = (value) => {
    if (this.props.selectedCurrency === constants.currencies.EUR.uniqueSlug) {
      return value;
    } else {
      return value * this.props.rates[this.props.selectedCurrency];
    }
  };
  formatNumber = (value) => {
    return (
      <NumberFormat
        value={Math.round(this.convertCurrency(value))}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
  };

  render() {
    const cashFlowColumns = [
      ...defaultFlowColumns,
      {
        name: "",
        selector: "edit",
        cell: (row) => {
          return (
            <div>
              <Icon
                className="cursor-pointer"
                name="add"
                color="green"
                onClick={() =>
                  this.setState({
                    addElementOpen: true,
                    addNewElementCategory: row.id,
                    addNewElementCategoryName: row.name,
                  })
                }
              />
              <Icon
                className="cursor-pointer"
                name="trash"
                style={(StyleSheet.style = { marginLeft: 15 })}
                color="black"
                onClick={() => {
                  this.setState({
                    deleteElementOpen: true,
                    deleteElementCategory: row.id,
                    deleteElementCategoryName: row.name,
                  });
                }}
              />
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <DataTable
          columns={cashFlowColumns}
          data={this.state.data}
          noHeader
          noTableHead
          highlightOnHover
          striped
          expandableRows
          expandableRowsComponent={
            <ExpandedCashflow
              selectedYear={this.props.selectedYear}
              rates={this.props.rates}
              selectedCurrency={this.props.selectedCurrency}
              forceReload={this.state.updateExpandedCashflow}
            />
          }
          expandOnRowClicked
          progressPending={this.state.isFetching}
          progressComponent={<CircularLoader />}
        />

        <Dialog
          open={this.state.addElementOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <div style={{ display: "flex" }}>
              Adauga element in {this.state.addNewElementCategoryName}
            </div>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nume"
              type="text"
              fullWidth
              value={this.state.addedElement}
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Anuleaza
            </Button>
            <Button
              onClick={this.addNewElementCategory}
              color="primary"
              disabled={!this.state.addedElement}
            >
              Adauga
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.deleteElementOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <div style={{ display: "flex" }}>
              Ești sigur/ă de ștergerea {this.state.deleteElementCategoryName}?
              Acțiunea este ireversibilă!
            </div>
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Anuleaza
            </Button>
            <Button onClick={this.deleteElementCategory}
              color="var(--secondaryColor)"
            >
              Șterge
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Cashflow);
