import React, { useReducer } from "react";
import "../Matches.scss";
import _ from "lodash";
import moment from "moment";
import "moment/locale/ro";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import { Icon } from "semantic-ui-react";

import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-white.svg";
import DeleteMatch from "../AddMatch/DeleteMatch";
import EditMatch from "../AddMatch/EditMatch";
import * as constants from "./../../../../../app/utils/constants";
import permissions from "./../../../../../app/utils/permissions";
import TFBTeamLabel from "../../../../components/design-system/TFBTeamLabel/TFBTeamLabel";
import { useMatches } from "../MatchesContext";

function ManualLastMatches(props) {
  const { languagePack, user_permissions } = props;
  const history = useHistory();
  const { matches, onRefreshList } = useMatches();

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      loading: true,
      openEditPopup: false,
      idEditMatch: "",
      openDeletePopup: false,
      idDeleteMatch: "",
    }
  );

  return (
    <div>
      <div className="matches-list">
        <div className="match-container match-list-col-title match-schedule-head-desktop">
          <div className="match-date">{languagePack["round"]}</div>
          <div className="match-column-title">{languagePack["match"]}</div>
        </div>
        {matches.map((item, index) => {
          return item.id_instat_match == null && _.size(matches) > 0 ? (
            <div
              className="match-container flex-column-mobile"
              style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "" }}
              key={"match-" + index}
            >
              <div className="match-date">
                {item.id_competition > 0
                  ? item.competition_name +
                    ", " +
                    languagePack["round"] +
                    " " +
                    item.round
                  : languagePack.frendly_match}
                <br></br>
                {item.venue_name != "" && (
                  <>
                    {item.venue_name}
                    <br></br>
                  </>
                )}
                {item.match_date != "0000-00-00 00:00:00"
                  ? moment(item.match_date)
                      .locale("ro")
                      .format("dddd, DD.MM.YYYY, HH:mm")
                  : "-"}
              </div>
              <div className="match-details">
                <div className="match-team">
                  <div
                    className="team-name df df-end"
                    style={{ textAlign: "end", paddingRight: 30 }}
                  >
                    <TFBTeamLabel
                      name={item.team_home_name}
                      color={item?.home_team_color ?? null}
                    />
                  </div>
                  <div className="match-logo">
                    <img src={item.home_team_url} alt="team_home_logo" />
                  </div>
                </div>
                <div className="match-score">
                  {item.team_home_goals}:{item.team_away_goals}
                </div>
                <div className="match-team">
                  <div className="match-logo">
                    <img src={item.away_team_url} alt="team_away_logo" />
                  </div>
                  <div className="team-name df" style={{ paddingLeft: 30 }}>
                    <TFBTeamLabel
                      name={item.team_away_name}
                      color={item?.away_team_color ?? null}
                    />
                  </div>
                </div>
              </div>
              <div className="icons-container">
                {user_permissions?.[permissions.CLUB]?.[permissions.MATCH]?.[
                  permissions.MATCH_COSTS
                ] && (
                  <div className="icon-notification">
                    <div className="add-score-icon">
                      <Icon
                        style={{ cursor: "pointer" }}
                        name="credit card"
                        size="large"
                        color="black"
                        onClick={() => {
                          history.push(
                            `/club/match-costs/edit/${item.id_match}`
                          );
                        }}
                      />
                    </div>
                    <div className="notification-container">
                      <div className="notification-circle">
                        <div className="notification-number">
                          {Number(item.match_costs_update).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {(item.id_common_competition == null ||
                  user_permissions?.[permissions.CLUB]?.[
                    permissions.COMPETITION
                  ]?.[permissions.EDIT_COMMON_COMPETITION]) && (
                  <>
                    <div className="add-score-icon">
                      <Icon
                        style={{ cursor: "pointer" }}
                        name="trash"
                        size="large"
                        color="black"
                        onClick={() => {
                          setState({
                            idDeleteMatch: item.id_match,
                            openDeletePopup: true,
                          });
                        }}
                      />
                    </div>
                    <div className="add-score-icon">
                      <Icon
                        style={{ cursor: "pointer" }}
                        name="edit"
                        size="large"
                        color="black"
                        onClick={() => {
                          setState({
                            openEditPopup: true,
                            idEditMatch: item.id_match,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
                <div className="match-see-more">
                  <div
                    className="match-see-more-button"
                    onClick={() => history.push(`/club/match/${item.id_match}`)}
                  >
                    <EyeIcon />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            _.size(state.sections) > 0 && (
              <div
                className="match-container flex-column-mobile"
                style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "" }}
                key={"match-" + index}
              >
                <div className="match-date">
                  {item.id_competition > 0
                    ? item.competition_name +
                      ", " +
                      languagePack["round"] +
                      " " +
                      item.round
                    : "Meci amical"}
                  <br></br>
                  {item.venue_name != "" && (
                    <>
                      {item.venue_name}
                      <br></br>
                    </>
                  )}
                  {moment(item.match_date)
                    .locale("ro")
                    .format("dddd, DD.MM.YYYY, HH:mm")}
                </div>
                <div className="match-details">
                  <div className="match-team">
                    <div
                      className="team-name df df-end"
                      style={{ textAlign: "end", paddingRight: 30 }}
                    >
                      <TFBTeamLabel
                        name={item.team_home_name}
                        color={item?.home_team_color ?? null}
                      />
                    </div>
                    <div className="match-logo">
                      <img src={item.home_team_url} alt="team_home_logo" />
                    </div>
                  </div>
                  <div className="match-score">
                    {item.team_home_goals}:{item.team_away_goals}
                  </div>
                  <div className="match-team">
                    <div className="match-logo">
                      <img src={item.away_team_url} alt="team_away_logo" />
                    </div>
                    <div className="team-name df" style={{ paddingLeft: 30 }}>
                      <TFBTeamLabel
                        name={item.team_away_name}
                        color={item?.away_team_color ?? null}
                      />
                    </div>
                  </div>
                </div>
                <div className="icons-container">
                  {user_permissions?.[permissions.CLUB]?.[permissions.MATCH]?.[
                    permissions.MATCH_COSTS
                  ] && (
                    <div className="icon-notification">
                      <div className="add-score-icon">
                        <Icon
                          style={{ cursor: "pointer" }}
                          name="credit card"
                          size="large"
                          color="black"
                          onClick={() => {
                            history.push(
                              `/club/match-costs/edit/${item.id_match}`
                            );
                          }}
                        />
                      </div>
                      <div className="notification-container">
                        <div className="notification-circle">
                          <div className="notification-number">
                            {Number(item.match_costs_update).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="match-see-more">
                    <div
                      className="match-see-more-button"
                      onClick={() =>
                        history.push(
                          `/club/match/instat/${item.id_instat_match}`
                        )
                      }
                    >
                      <EyeIcon />
                    </div>
                  </div>
                </div>
              </div>
            )
          );
        })}
      </div>

      <Dialog open={state.openEditPopup} fullWidth maxWidth="sm">
        <DialogContent className="add-team-manual-wrapper">
          <EditMatch
            closePopup={() => {
              setState({ openEditPopup: false, idEditMatch: "" });
            }}
            getMatches={() => {
              onRefreshList();
            }}
            idMatch={state.idEditMatch}
            type={"past"}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={state.openDeletePopup}>
        <DialogContent className="add-team-manual-wrapper">
          <DeleteMatch
            closePopup={() => {
              setState({ openDeletePopup: false, setIdDeleteMatch: "" });
            }}
            getMatches={() => {
              onRefreshList();
            }}
            id_match={state.idDeleteMatch}
            type={"past"}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  token: auth?.user?.token,
  currentUser: auth.user,
  currencyItemSelected:
    constants.currencies[auth?.user?.currency] || constants.currencies.EUR,
  user_permissions: auth?.user?.user_permissions,
}))(ManualLastMatches);
