import React, { useReducer } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import { TFBTableCell, TFBTableRow, sxStickyColumn } from "../../../../components/reusable/useStyles";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../../../components/reusable/Loader";
import moment from "moment";
import AddNationalTeamCallUp from "./AddNationalTeamCallUp";
import EditNationalTeamCallUp from "./EditNationalTeamCallUp";
import DeleteNationalTeamCallUp from "./DeleteNationalTeamCallUp";

// import styles from "./DocumentToSign.module.scss";


const NationalTeamCallUpWidget = (props) => {

    const history = useHistory();

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_add_call_up: false,

            open_edit_call_up: false,
            editing_call_up: {},

            open_delete_call_up: false,
            deleting_call_up: {},
        }
    );

    const { languagePack, id_player } = props;
    const { id_club } = props.currentUser;

    const {
        data: nationalTeamCallUp,
        loading: isNationalTeamCallUpLoading,
        refetch: refetchNationalTeamCallUp
    } = useAxiosGet(`player/get_player_national_team_call_up?id_player=${id_player}`);

    const openAddCallUp = () => {
        updateState({ open_add_call_up: true })
    }

    const closeAddCallUp = () => {
        updateState({ open_add_call_up: false })
    }

    const openEditCallUp = (call_up) => {
        updateState({ open_edit_call_up: true, editing_call_up: call_up })
    }

    const closeEditCallUp = () => {
        updateState({ open_edit_call_up: false, editing_call_up: {} })
    }

    const openDeleteCallUp = (call_up) => {
        updateState({ open_delete_call_up: true, deleting_call_up: call_up })
    }

    const closeDeleteCallUp = () => {
        updateState({ open_delete_call_up: false, deleting_call_up: {} })
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack.national_team_calls}>
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                    onClick={openAddCallUp}
                >
                    {languagePack.add_national_team_call_up}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                {!isNationalTeamCallUpLoading ?
                    (_.size(nationalTeamCallUp) > 0 ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TFBTableRow>
                                        <TFBTableCell sx={sxStickyColumn}>
                                            {languagePack.injury_date}
                                        </TFBTableCell>
                                        <TFBTableCell>{languagePack.national_team}</TFBTableCell>
                                        <TFBTableCell>{languagePack.Competition}</TFBTableCell>
                                        <TFBTableCell>{languagePack.age_group}</TFBTableCell>
                                        <TFBTableCell>{languagePack.activity_type}</TFBTableCell>
                                        <TFBTableCell>{languagePack.score}</TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.minutes_played}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.goals_scored_pl}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.key_pass}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.yellow_cards_pl}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.red_cards_pl}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.order_actions}
                                        </TFBTableCell>
                                    </TFBTableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(nationalTeamCallUp, (row, index) => (
                                        <TFBTableRow key={"national-team-call-up-row-" + index}>
                                            <TFBTableCell sx={sxStickyColumn}>
                                                {moment(row["action_date"]).format("DD.MM.YYYY")}
                                            </TFBTableCell>
                                            <TFBTableCell>{row["national_team"]}</TFBTableCell>
                                            <TFBTableCell>{row["competition_name"]}</TFBTableCell>
                                            <TFBTableCell>
                                                {row["age_group"]}
                                            </TFBTableCell>
                                            <TFBTableCell>
                                                {row["action_type"] == "training" ? _.lowerCase(languagePack.training) : "vs " + row["opponent_name"]}
                                            </TFBTableCell>
                                            <TFBTableCell>{row["score"]}</TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row["played_minutes"] || "-"}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row["scored_goals"] || "-"}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row["assists"] || "-"}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row["yellow_cards"] || "-"}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row["red_cards"] || "-"}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                <div
                                                    className="actions-btns-container"
                                                    style={{ justifyContent: "flex-end" }}
                                                >
                                                    <TFBIconButton
                                                        name="edit"
                                                        color="darkGray"
                                                        onClick={() => openEditCallUp(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="delete"
                                                        color="darkGray"
                                                        onClick={() => openDeleteCallUp(row)}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                        </TFBTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <TFBPlaceholder text={languagePack.no_national_team_call_up} />) :
                    <CircularLoader />
                }

                {state.open_add_call_up &&
                    <AddNationalTeamCallUp
                        openPopup={state.open_add_call_up}
                        closePopup={closeAddCallUp}
                        refreshList={refetchNationalTeamCallUp}
                        id_player={id_player}
                    />
                }

                {state.open_edit_call_up &&
                    <EditNationalTeamCallUp
                        openPopup={state.open_edit_call_up}
                        closePopup={closeEditCallUp}
                        refreshList={refetchNationalTeamCallUp}
                        data={state.editing_call_up}
                    />
                }

                {state.open_delete_call_up &&
                    <DeleteNationalTeamCallUp
                        openPopup={state.open_delete_call_up}
                        closePopup={closeDeleteCallUp}
                        refreshList={refetchNationalTeamCallUp}
                        data={state.deleting_call_up}
                    />
                }
            </TFBCardBody>
        </TFBCard>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(NationalTeamCallUpWidget);