import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./Sizes.scss";
import AddSize from "./AddSize";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditSize from "./EditSize";
import DeleteSize from "./DeleteSize";

class Sizes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sizes: [],
      isFetching: true,

      open_add_dialog: false,

      open_edit_dialog: false,
      editing_size: {},

      open_delete_dialog: false,
      deleting_size: {},
    };
  }

  componentDidMount() {
    this.getSizes();
  }

  getSizes = () => {
    axios
      .get(`/size/${this.props.currentUser.id_club}/id_club`)
      .then((res) => this.setState({ sizes: res.data, isFetching: false }))
      .catch((err) => console.log(err));
  };

  openAddSize = () => {
    this.setState({ open_add_dialog: true })
  }

  closeAddSize = () => {
    this.setState({ open_add_dialog: false })
  }

  openEditSize = (size) => {
    this.setState({ open_edit_dialog: true, editing_size: size })
  }

  closeEditSize = () => {
    this.setState({ open_edit_dialog: false, editing_size: {} })
  }

  openDeleteSize = (size) => {
    this.setState({ open_delete_dialog: true, deleting_size: size })
  }

  closeDeleteSize = () => {
    this.setState({ open_delete_dialog: false, deleting_size: {} })
  }

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.sizes}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={this.openAddSize}
          >
            {languagePack.add_size}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.sizes.length > 0 ?
            <TableContainer>
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.size}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.sizes.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        {row.size_name}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => this.openEditSize(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => this.openDeleteSize(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_sizes} />
          }
        </TFBCardBody>

        {/* ------------------- ADD SIZE ---------------- */}
        {this.state.open_add_dialog &&
          <AddSize
            isOpenPopup={this.state.open_add_dialog}
            closePopup={this.closeAddSize}
            refreshList={this.getSizes}
          />
        }

        {/* ------------------- EDIT SIZE ---------------- */}
        {this.state.open_edit_dialog &&
          <EditSize
            isOpenPopup={this.state.open_edit_dialog}
            closePopup={this.closeEditSize}
            refreshList={this.getSizes}
            sizeData={this.state.editing_size}
          />
        }

        {/* ------------------- DELETE SIZE ---------------- */}
        {this.state.open_delete_dialog &&
          <DeleteSize
            isOpenPopup={this.state.open_delete_dialog}
            closePopup={this.closeDeleteSize}
            refreshList={this.getSizes}
            sizeData={this.state.deleting_size}
          />
        }
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Sizes);
