import React, { useEffect, useState } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import _ from "lodash";

import "./CreateNotification.scss";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell } from "../../../../components/reusable/useStyles";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

const CreateNotification = (props) => {
  const [groupsOptions, setGroupsOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [users, setUsers] = useState([]);
  const [fullUsers, setFullUsers] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [step2, setStep2] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const maxMessageLength = 120;

  useEffect(() => {
    // getFullUsers();
    getTeams();
    // getUsers();
  }, []);

  useEffect(() => {
    if (selectedGroup != null) {
      getUsers();
    }
  }, [selectedGroup]);

  useEffect(() => {
    let allUsers = [...users];
    const allChecked = allUsers.every((user) => user.checked);
    setIsAllChecked(allChecked);
  }, [users]);

  const { languagePack } = props;

  const getTeams = () => {
    axios
      .get(`finances/get_teams?token=${props.currentUser.token}`)
      .then((res) => {
        setGroupsOptions(res.data?.teams);
        if (_.size(res.data?.teams) > 0) {
          setSelectedGroup(res.data?.teams[0]);
        }
      })
      .catch((err) => console.log(err));
  };

  const getFullUsers = () => {
    axios
      .get(
        `notification/get_external_users_by_team?id_club=${props.currentUser.id_club}&id_user=${props.currentUser.id_user}&token=${props.currentUser.token}`
      )
      .then((res) => {
        const dataResponse = res.data.list.map((data) => {
          return { ...data, checked: false };
        });

        // setFullUsers(dataResponse);
      })
      .catch((err) => console.log(err));
  };

  const getUsers = () => {
    let addIdGroup =
      selectedGroup != null ? `id_team=${selectedGroup.value}` : "";
    axios
      .get(
        `notification/get_external_users_by_team?${addIdGroup}&id_club=${props.currentUser.id_club}&id_user=${props.currentUser.id_user}&token=${props.currentUser.token}`
      )
      .then((res) => {
        setUsers(_.map(res.data?.list, e => ({ ...e, checked: false })));
      })
      .catch((err) => console.log(err));
  };

  // =========== HANDLERS =========================
  // ==============================================

  const handleHeaderCheck = (e) => {
    let allUsers = [...users];
    let allChecked = e.target.checked ? true : false;

    allUsers = allUsers.map((user) => {
      return {
        ...user,
        checked: allChecked,
      };
    });

    setUsers(allUsers);
    setIsAllChecked(allChecked);
  };

  const handleRowCheck = (index) => {
    let allUsers = [...users];
    allUsers[index].checked = !allUsers[index].checked;

    const allChecked = allUsers.every((user) => user.checked);

    setIsAllChecked(allChecked ? true : false);
    setUsers(allUsers);
  };

  const renderBtn = (position, onClick) => {
    return (
      <div className="action-buttons-add-notification">
        <TFBButton
          onClick={onClick}
          style={{ position: "sticky" }}
          endIcon={true}
          color={position == "left" ? "darkGray" : "green"}
          renderIcon={() => (
            <TFBIcon name={position == "left" ? "cancel" : "check"} />
          )}
          prettier-ignore
          disabled={
            position == "right" && !users.some((user) => user.checked)
          }
        >
          {position == "left" ? languagePack.Cancel : languagePack.next_step}
        </TFBButton>
      </div>
    );
  };

  const renderBtnSecondStep = (position, onClick) => {
    return (
      <div className="action-buttons-add-notification">
        <TFBButton
          onClick={onClick}
          endIcon={true}
          color={position == "left" ? "darkGray" : "green"}
          renderIcon={() => (
            <TFBIcon name={position == "left" ? "arrow-left" : "check"} />
          )}
          prettier-ignore
          disabled={
            position == "right" &&
            !users.some((user) => user.checked && notificationMessage != "")
          }
        >
          {position == "left"
            ? languagePack.previous_step
            : languagePack.send_notification}
        </TFBButton>
      </div>
    );
  };

  // =========== SEND NOTIFICATION (BACKEND CONNECTION) =========================
  // ============================================================================

  const sendNotification = () => {
    let usersToNotificate = [...users].filter((user) => {
      return user.checked;
    });

    usersToNotificate = usersToNotificate.map((user) => user.id_external_user);

    let payload = {
      notification_text: notificationMessage,
      token: props.currentUser.token,
      id_club: props.currentUser.id_club,
      id_user: props.currentUser.id_user,
      users_external: usersToNotificate,
    };

    axios
      .post("notification/generate_notification_from_admin", payload)
      .then((res) => {
        if (res.status == 200) {
          props.refreshMobileUsers();
          store.addNotification({
            title: languagePack.notification_title_message,
            message: languagePack.notification_sucess_message,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
          props.closePopup();
        } else {
          store.addNotification({
            title: languagePack.notification_title_message,
            message: languagePack.notification_failed_message,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="wrapper-parent-create-buttons">
      <TFBDialog
        open={props.openDialog}
        fullWidth={true}
        maxWidth="md"
        closePopup={props.closePopup}
        title={languagePack.create_notification}
        stickyBtns
        columnActionsMobile
        btnLeft={() =>
          !step2
            ? renderBtn("left", () => props.closePopup())
            : renderBtnSecondStep("left", () => setStep2(false))
        }
        btnRight={() =>
          !step2
            ? renderBtn("right", () => setStep2(true))
            : renderBtnSecondStep("right", () => sendNotification())
        }
      >
        <div className="add-users-notifications-container">
          {!step2 && (
            <>
              <div className="sub-header ds-headline-2">
                {languagePack.step_one_selecte_audience}
              </div>

              <div className="group-select-filter">
                <TFBSelect
                  options={groupsOptions}
                  label={languagePack.select_group}
                  placeholder={languagePack.all_groups}
                  className="select-group"
                  value={selectedGroup}
                  onChange={(selected) => {
                    setSelectedGroup(selected);
                  }}
                />

                {/* <TFBButton
                  className="apply-filter"
                  renderIcon={() => <TFBIcon name="check" />}
                  onClick={() => {
                    setIsAllChecked(false);
                    getUsers();
                  }}
                >
                  {languagePack.apply_filters}
                </TFBButton>
                <TFBButton
                  className="cancel-filter"
                  renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                  onClick={() => {
                    setSelectedGroup(null);
                  }}
                  color="lightGray"
                >
                  {languagePack.cancel_filters}
                </TFBButton> */}
              </div>

              {users.length > 0 && (
                <div className="users-table">
                  <TableContainer>
                    <Table
                      faria-label="simple table"
                      sx={{ minWidth: "370px" }}
                    >
                      <TableHead>
                        <TableRow>
                          <TFBTableCell>
                            <div className="header-checkbox">
                              <TFBCheckbox
                                checked={isAllChecked}
                                onChange={(e) => handleHeaderCheck(e)}
                                mode="light"
                              />
                              <div>{languagePack.User_name}</div>
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            {languagePack.plyers_names}
                          </TFBTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 == 0 ? "#fafafa" : "white",
                            }}
                          >
                            <TFBTableCell
                              style={{
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <div className="row-checkbox-player">
                                <TFBCheckbox
                                  onChange={() => handleRowCheck(index)}
                                  checked={row.checked}
                                  mode="light"
                                />

                                <div className="user-details">
                                  <div>{row.user_name}</div>
                                  <div className="relationship ds-secondary-text-auxiliary">
                                    {row.relationships}
                                  </div>
                                </div>
                              </div>
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {row.players_name}
                            </TFBTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </>
          )}

          {step2 && (
            <>
              <div className="sub-header ds-headline-2">
                {languagePack.step_two_select_audience}
              </div>

              <div className="message">
                <TFBInput
                  label={`${String(notificationMessage).length
                    }/${maxMessageLength}`}
                  placeholder={languagePack.your_message + "..."}
                  className="message-input"
                  value={notificationMessage}
                  onChange={(e) => setNotificationMessage(e.target.value)}
                  fullWidth
                  inputProps={{ maxLength: maxMessageLength }}
                />
              </div>
            </>
          )}
        </div>
      </TFBDialog>
    </div>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(CreateNotification);
