import React from "react";
import TFBInput from "../../components/design-system/Input/TFBInput";
import TFBPasswordInput from "../../components/design-system/PasswordInput/TFBPasswordInput";
import TFBPhoneInput from "../../components/design-system/PhoneInput/TFBPhoneInput";
import TFBSelect from "../../components/design-system/TFBSelect/TFBSelect";

export const TFBInputWithWrapper = ({ label, value, onChange, style }) => {
  return (
    <div className="form-input">
      <TFBInput
        fullWidth
        label={label}
        style={{ ...style, backgroundColor: "#FAFAFA" }}
        value={value || ""}
        onChange={onChange}
      />
    </div>
  );
};

export const TFBPasswordInputWithWrapper = ({
  label,
  value,
  onChange,
  style,
}) => {
  return (
    <div className="form-input">
      <TFBPasswordInput
        fullWidth
        className="form-input"
        label={label}
        value={value || ""}
        onChange={onChange}
        style={style}
      />
    </div>
  );
};

export const TFBPhoneInputWithWrapper = ({ label, onChange, inputStyle, defaultCountry }) => {
  return (
    <div className="form-input">
      <TFBPhoneInput
        defaultCountry={defaultCountry}
        className="form-input"
        label={label}
        inputStyle={inputStyle}
        onChange={onChange}
      />
    </div>
  );
};

export const TFBSelectWithWrapper = ({ label, onChange, style, value, languagePack }) => {
  const roles = [
    {
      value: 0,
      label: "Antrenor",
      lang_pack: "coach",
    },
    {
      value: 1,
      label: "Presedinte club",
      lang_pack: "club_president",
    },
    {
      value: 2,
      label: "Analist video",
      lang_pack: "video_analyst",
    },
    {
      value: 3,
      label: "Administrator",
      lang_pack: "administrator",
    },
    {
      value: 4,
      label: "Coordonator",
      lang_pack: "coordinator",
    },
    {
      value: 5,
      label: "Director",
      lang_pack: "director",
    },
    {
      value: 6,
      label: "Dietetician",
      lang_pack: "dietician",
    },
    {
      value: 7,
      label: "Kinetoterapeut",
      lang_pack: "kinesiologist",
    },
    {
      value: 8,
      label: "Manager",
      lang_pack: "manager",
    },
    {
      value: 9,
      label: "Medic",
      lang_pack: "doctor",
    },
    {
      value: 10,
      label: "Preparator fizic",
      lang_pack: "physical_trainer",
    },
    {
      value: 11,
      label: "Vicepresedinte",
      lang_pack: "vicepresident",
    },
  ];

  return (
    <div className="form-input">
      <TFBSelect
        label={label}
        options={roles.map(e => ({ ...e, label: languagePack?.[e.lang_pack] ?? e.value }))}
        className="form-input"
        style={style}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
