import React, { useState } from "react";
import { Link, Switch, Redirect, Route, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "./../../_metronic/_helpers";
import { ContentRoute } from "./../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "./../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import axios from "axios";

export default function AuthPage(props) {
  const { lang, languagePack } = useSelector(
    ({ layoutService: { lang, languagePack } }) => ({
      lang,
      languagePack,
    })
  );

  let { redirectUrl } = props;

  const location = useLocation();
  if (!redirectUrl) {
    redirectUrl = location.state?.redirectUrl;
  }

  return (
    <IntlProvider
      locale={lang.toLowerCase()}
      // locale={clubCustomization.language}
      messages={languagePack.FORM_CONTROL}
    >
      {/* {clubCustomization.loader === false && */}
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${window.clubCustomization.login_image})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5"></Link>
              {/* end:: Aside header */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div
              className="cotnainer-logo"
              style={{
                width: "20vw",
                marginTop: 100,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <img
                // alt={clubCustomization.clubName}
                src={window.clubCustomization.logo_login}
                style={{ width: "100%" }}
              />
            </div>

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={() => <Login {...props} />} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Route
                  path="/auth"
                  render={() => <Redirect
                    to={{
                      pathname: "/auth/login",
                      state: { ...(redirectUrl && { redirectUrl: encodeURI(redirectUrl) }) }
                    }}
                  />}
                />
                <Route
                  path="/"
                  exact
                  render={() => <Redirect to="/auth/login" />}
                />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
      {/* } */}
    </IntlProvider>
  );
}
