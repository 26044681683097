import React from "react";
import "./TrainingDetailsPage.scss";
import DeletePlayer from "./DeletePlayer";
import axios from "axios";
import Moment from "react-moment";
import { Button, Icon, Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import { Redirect } from "react-router-dom";
import { DialogContent, Dialog } from "@material-ui/core";

import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import _ from "lodash";

const months = {
  "1": "Ianuarie",
  "2": "Februarie",
  "3": "Martie",
  "4": "Aprilie",
  "5": "Mai",
  "6": "Iunie",
  "7": "Iulie",
  "8": "August",
  "9": "Septembrie",
  "10": "Octombrie",
  "11": "Noiembrie",
  "12": "Decembrie",
};

class TrainingDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: [],
      schedule: [],
      training_date: null,
      marks: [
        {
          value: "1",
          label: "1/5",
        },
        {
          value: "2",
          label: "2/5",
        },
        {
          value: "3",
          label: "3/5",
        },
        {
          value: "4",
          label: "4/5",
        },
        {
          value: "5",
          label: "5/5",
        },
      ],
      mark_for_all: "",
      test_players: [],
      initial_data: [],
      all_present: false,
      disable_all_marks: false,
      team_name: "",
      is_load: false,
      addRpe: false,

      hasAccessToTraining: false,
      redirectToTrainingHistory: false,

      add_player_to_training: false,

      players_list: [],
      selected_player: null,
      add_player_to_all_future_trainings: false,
      add_player_popup_height: "initial",

      deletePlayerPopup: false,
      idPlayerToDelete: "",

      history: 0,
      unsavedChanges: false,
    };
  }

  componentDidMount = () => {
    this.hasAccessToTraining();
  };

  hasAccessToTraining = () => {
    axios
      .post(`user/has_access_to_training`, {
        id_club: this.props.currentUser.id_club,
        id_training: this.props.match.params.id,
      })
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToTraining: true,
          });
          this.getInitialData();
          this.getPlayersList();
        } else {
          this.setState({
            redirectToTrainingHistory: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getInitialData = () => {
    axios
      .get(
        `training/get_training_session_details?id_training=${this.props.match.params.id}`
      )
      .then((res) => {
        let data = [];
        res.data.players.map((elem) => {
          let item = {
            name: elem.player_name,
            img_url: elem.img_url,
            present: elem.was_present == "1" ? true : false,
            remark: elem.training_comment == null ? "" : elem.training_comment,
            mark: ["", null, "0"].includes(elem.training_rating)
              ? ""
              : {
                  value: elem.training_rating,
                  label: `${elem.training_rating}/5`,
                },
            id: elem.id_training_session_player,
            id_player: elem.id_player,
            debt_for_previous_month: elem.debt_for_previous_month,
            debt_for_previous_month_year: elem.debt_for_previous_month_year,
            id_team: elem.id_team,
            rpe_before: elem.rpe_before,
            rpe_after: elem.rpe_after,
          };
          data.push(item);
        });
        this.setState({
          training_date: res.data.date,
          history: res.data.history,
          test_players: data,
          initial_data: data,
          team_name: res.data.team.team_name,
          id_team: res.data.team.id_team,
          is_load: true,
        });
      })
      .catch((err) => console.log(err));
  };

  getPlayersList = () => {
    axios
      .get(
        `training/get_players_list?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({
          players_list: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  setInitialData = () => {
    let data = this.state.initial_data;
    this.setState({
      test_players: data,
    });
  };

  saveData = () => {
    let payload = [];
    this.state.test_players.map((elem) => {
      let item = {
        id_training_session_player: elem.id,
        was_present: elem.present ? "1" : "0",
        id_user: this.props.currentUser.id_user,
        training_rating: elem.mark == "" ? "" : elem.mark.value,
        training_comment: elem.remark,
        add_rpe: this.state.addRpe ? 1 : 0,
        rpe_after: this.state.addRpe ? elem.rpe_after ?? null : null,
        rpe_before: this.state.addRpe ? elem.rpe_before ?? null : null,
      };
      payload.push(item);
    });

    axios
      .post(`training/update_details_training/`, payload)
      .then((res) => {
        this.getInitialData();
        store.addNotification({
          title: "Antrenamente",
          message: "Detaliile despre antrenament au fost salvate cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState({
          all_present: false,
          mark_for_all: "",
          unsavedChanges: false,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeArea = (e, { name, value }) => this.setState({ [name]: value });

  handleChangeRpe = (e, id, type) => {
    this.setState({
      test_players: _.map(this.state.test_players, (player) =>
        player.id_player === id
          ? {
              ...player,
              [`rpe_${type}`]:
                Number(e.target.value) > 10
                  ? "10"
                  : Number(e.target.value) < 1
                  ? "1"
                  : e.target.value,
            }
          : { ...player }
      ),
      unsavedChanges: true,
    });
  };

  closeAddPlayerPopup = () => {
    this.setState({
      add_player_to_training: false,
      selected_player: null,
      add_player_to_all_future_trainings: false,
    });
  };

  addPlayerToTraining = () => {
    let payload = {
      id_player: this.state.selected_player?.value,
      id_training_session: this.props.match.params.id,
      add_player_to_all_future_trainings: this.state
        .add_player_to_all_future_trainings,
      id_team: this.state.id_team,
    };

    axios
      .post(`training/add_player_to_training/`, payload)
      .then((res) => {
        if (res.status == 200) {
          this.getInitialData();
          store.addNotification({
            title: "Antrenamente",
            message: "Jucatorul a fost adaugat cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Antrenamente",
            message: "Jucatorul nu a fost adaugat cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.closeAddPlayerPopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (this.state.redirectToTrainingHistory) {
      return <Redirect to="/training/history" />;
    }

    const { languagePack } = this.props;

    const customStyleSelect = {
      menu: (provided, state) => ({
        ...provided,
        color: "hsl(0,0%,20%)",
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: "#b3b3b3",
        fontWeight: 600,
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
      }),
      control: (provided, state) => ({
        ...provided,
        border: "1px solid #b3b3b3",
        borderRadiu: "5px",
      }),
    };

    return (
      <>
        {this.state.is_load && (
          <>
            <div className="training-name">
              {this.state.team_name + " - "} {languagePack["training"]}
              <Moment format="DD.MM.YYYY">{this.state.training_date}</Moment>
            </div>

            <TFBCard>
              <div className="training-details-container">
                <TFBCardHeader title={languagePack.trainings_history}>
                  {this.state.history == 1 && (
                    <TFBButton
                      onClick={() =>
                        this.props.history.push(
                          `/training/rpe/${this.props.match.params.id}`
                        )
                      }
                    >
                      {languagePack.training_intensity}
                    </TFBButton>
                  )}

                  <TFBButton
                    onClick={() =>
                      this.setState({ add_player_to_training: true })
                    }
                    endIcon={true}
                    renderIcon={() => <TFBIcon name="add" />}
                    color="green"
                  >
                    {languagePack.add_player}
                  </TFBButton>
                </TFBCardHeader>

                <TFBCardBody>
                  <div className="presence-detail-training-table-desktop">
                    <TFBCheckbox
                      checked={this.state.addRpe}
                      mode="light"
                      onChange={() =>
                        this.setState({
                          addRpe: !this.state.addRpe,
                          unsavedChanges: true,
                        })
                      }
                      label={languagePack.add_rpe}
                    />
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TFBTableCell
                              sx={sxStickyColumn}
                              style={{ maxWidth: "800px" }}
                            >
                              {languagePack.player}
                            </TFBTableCell>
                            <TFBTableCell
                              align="left"
                              style={{ minWidth: "124px" }}
                            >
                              <div>{languagePack.presence}</div>
                              <Checkbox
                                label={languagePack.all_present}
                                className="checkbox-header"
                                onChange={() => {
                                  let data = this.state.test_players;
                                  data.forEach((elem, index) => {
                                    elem.present = !this.state.all_present;
                                    if (!elem.present) {
                                      data[index].mark = "";
                                    }
                                  });
                                  if (this.state.all_present) {
                                    this.setState({
                                      mark_for_all: "",
                                      unsavedChanges: true,
                                    });
                                  }
                                  this.setState({
                                    all_present: !this.state.all_present,
                                    test_players: data,
                                    unsavedChanges: true,
                                  });
                                }}
                                checked={this.state.all_present}
                              />
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {languagePack.remarks}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              <div className="qualifying col1">
                                <TFBSelect
                                  className="select-mark"
                                  placeholder={languagePack.mark}
                                  isMulti={false}
                                  options={this.state.marks}
                                  value={this.state.mark_for_all}
                                  onChange={(selected) => {
                                    let data = this.state.test_players;
                                    data.forEach((elem) => {
                                      if (elem.present) {
                                        elem.mark = selected;
                                      }
                                    });
                                    this.setState({
                                      mark_for_all: selected,
                                      unsavedChanges: true,
                                    });
                                  }}
                                  style={{
                                    container: {
                                      backgroundColor: "white",
                                      width: "initial",
                                    },
                                    control: {
                                      backgroundColor: "white",
                                      minHeight: "initial",
                                      border: "1px solid #cccccc",
                                      borderRadius: "4px",
                                    },
                                    valueContainer: {
                                      paddingLeft: "8px",
                                    },
                                  }}
                                />
                              </div>
                            </TFBTableCell>
                            {this.state.addRpe && (
                              <TFBTableCell
                                sx={{ minWidth: "100px" }}
                                align="center"
                              >
                                {languagePack.rpe_before}
                              </TFBTableCell>
                            )}
                            {this.state.addRpe && (
                              <TFBTableCell
                                sx={{ minWidth: "100px" }}
                                align="center"
                              >
                                {languagePack.rpe_after}
                              </TFBTableCell>
                            )}
                            <TFBTableCell align="center">
                              {languagePack.product_actions}
                            </TFBTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.test_players.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <TFBTableCell
                                component="th"
                                scope="row"
                                sx={sxStickyColumn}
                                style={{
                                  minWidth: "200px",
                                  width: "32%",
                                  backgroundColor:
                                    index % 2 == 0 ? "#fafafa" : "white",
                                }}
                                onClick={() => {
                                  if (row.debt_for_previous_month != null) {
                                    this.props.history.push(
                                      "/club/payments/" + row.id_team
                                    );
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div className="player-photo">
                                    <TFBPlayerWithAvatar
                                      avatarUrl={row.img_url}
                                    />
                                  </div>
                                  <div className="player-error-container">
                                    <div
                                      className="player-name"
                                      style={{
                                        color:
                                          row.debt_for_previous_month == null
                                            ? "#666666"
                                            : "red",
                                      }}
                                    >
                                      {row.name}
                                    </div>
                                    {row.debt_for_previous_month != null && (
                                      <div
                                        className="error-not-pay"
                                        style={{
                                          color:
                                            row.debt_for_previous_month == null
                                              ? "#666666"
                                              : "red",
                                        }}
                                      >
                                        {`Nu a achitat taxa pentru ${months[
                                          row.debt_for_previous_month
                                        ].toLowerCase()} ${
                                          row.debt_for_previous_month_year
                                        }`}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </TFBTableCell>
                              <TFBTableCell
                                style={{ minWidth: "124px" }}
                                align="left"
                              >
                                <Checkbox
                                  onChange={() => {
                                    let data = this.state.test_players;
                                    data[index].present = !row.present;
                                    if (!data[index].present) {
                                      data[index].mark = "";
                                    }
                                    let all_present = true;
                                    data.forEach((elem) => {
                                      if (!elem.present) {
                                        all_present = false;
                                        return;
                                      }
                                    });
                                    this.setState({
                                      test_players: data,
                                      all_present: all_present,
                                      unsavedChanges: true,
                                    });
                                  }}
                                  checked={row.present}
                                />
                              </TFBTableCell>
                              <TFBTableCell
                                align="center"
                                style={{ width: "29%" }}
                              >
                                <div className="presence-training-detail-mark">
                                  <input
                                    className="text-input"
                                    placeholder={languagePack.add_remarks}
                                    name="remark"
                                    value={row.remark}
                                    onChange={(e) => {
                                      let data = this.state.test_players;
                                      data[index].remark = e.target.value;
                                      this.setState({
                                        test_players: data,
                                        unsavedChanges: true,
                                      });
                                    }}
                                  />
                                </div>
                              </TFBTableCell>
                              <TFBTableCell
                                align="center"
                                style={{ minWidth: "127px", width: "10%" }}
                              >
                                <TFBSelect
                                  className="select-mark"
                                  placeholder={languagePack.mark}
                                  isMulti={false}
                                  options={this.state.marks}
                                  value={row.mark}
                                  isDisabled={!row.present}
                                  onChange={(selected) => {
                                    let data = this.state.test_players;
                                    data[index].mark = selected;
                                    let same_marks = true;
                                    data.forEach((elem) => {
                                      if (
                                        elem.mark.value !==
                                        this.state.mark_for_all
                                      ) {
                                        same_marks = false;
                                        return;
                                      }
                                    });
                                    if (!same_marks) {
                                      this.setState({
                                        mark_for_all: "",
                                        unsavedChanges: true,
                                      });
                                    }
                                    this.setState({
                                      test_players: data,
                                      unsavedChanges: true,
                                    });
                                  }}
                                  style={{
                                    container: {
                                      width: "initial",
                                    },
                                    control: {
                                      minHeight: "initial",
                                      border: "1px solid #cccccc",
                                      borderRadius: "4px",
                                    },
                                    valueContainer: {
                                      paddingLeft: "8px",
                                    },
                                  }}
                                />
                              </TFBTableCell>
                              {this.state.addRpe && (
                                <TFBTableCell width="110px" align="center">
                                  <TFBInput
                                    onChange={(e) =>
                                      this.handleChangeRpe(
                                        e,
                                        row.id_player,
                                        "before"
                                      )
                                    }
                                    makeStylesContent={{
                                      "&  input": {
                                        color: !row.rpe_before
                                          ? "black"
                                          : Number(row.rpe_before) < 4
                                          ? "#d91200"
                                          : Number(row.rpe_before) >= 4 &&
                                            Number(row.rpe_before) < 8
                                          ? "#ff9500"
                                          : "#00d948",
                                      },
                                    }}
                                    value={row.rpe_before ?? ""}
                                    type="number"
                                    min="1"
                                    max="10"
                                  />
                                </TFBTableCell>
                              )}
                              {this.state.addRpe && (
                                <TFBTableCell width="110px" align="center">
                                  <TFBInput
                                    onChange={(e) =>
                                      this.handleChangeRpe(
                                        e,
                                        row.id_player,
                                        "after"
                                      )
                                    }
                                    makeStylesContent={{
                                      "&  input": {
                                        color: !row.rpe_after
                                          ? "black"
                                          : Number(row.rpe_after) < 4
                                          ? "#d91200"
                                          : Number(row.rpe_after) >= 4 &&
                                            Number(row.rpe_after) < 8
                                          ? "#ff9500"
                                          : "#00d948",
                                      },
                                    }}
                                    value={row.rpe_after ?? ""}
                                    type="number"
                                    inputProps={{ min: "1", max: "10" }}
                                  />
                                </TFBTableCell>
                              )}
                              <TFBTableCell align="right">
                                <div className="actions-btns-container">
                                  <TFBIconButton
                                    name="delete"
                                    onClick={() => {
                                      this.setState({
                                        deletePlayerPopup: true,
                                        idPlayerToDelete: row.id_player,
                                      });
                                    }}
                                    color="darkGray"
                                  />
                                </div>
                              </TFBTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                  <div className="training-table-mobile">
                    <div className="table-header">
                      <div className="qualifying col1">
                        <TFBSelect
                          className="select-mark"
                          placeholder="Notă toți"
                          isMulti={false}
                          options={this.state.marks}
                          value={this.state.mark_for_all}
                          onChange={(selected) => {
                            let data = this.state.test_players;
                            data.forEach((elem) => {
                              if (elem.present) {
                                elem.mark = selected;
                              }
                            });
                            this.setState({ mark_for_all: selected });
                          }}
                          style={{
                            container: {
                              width: "initial",
                            },
                            control: {
                              backgroundColor: "white",
                              minHeight: "initial",
                              border: "1px solid #cccccc",
                              borderRadius: "4px",
                            },
                            valueContainer: {
                              paddingLeft: "8px",
                            },
                          }}
                        />
                      </div>
                      <div className="present-check col1">
                        <Button
                          onClick={() => {
                            let data = this.state.test_players;
                            data.forEach((elem, index) => {
                              elem.present = !this.state.all_present;
                              if (!elem.present) {
                                data[index].mark = "";
                              }
                            });
                            if (this.state.all_present) {
                              this.setState({
                                mark_for_all: "",
                              });
                            }
                            this.setState({
                              all_present: !this.state.all_present,
                              test_players: data,
                            });
                          }}
                        >
                          {this.state.all_present && (
                            <Icon name="checkmark" className="check-custom" />
                          )}
                          {this.state.all_present
                            ? languagePack.all_present
                            : "Bifează pentru toți"}
                        </Button>
                      </div>
                    </div>
                    <div className="table-content">
                      {this.state.test_players.map((item, index) => {
                        return (
                          <div key={index} className="table-card">
                            <div className="card-row">
                              <div
                                className="player"
                                onClick={() => {
                                  if (item.debt_for_previous_month != null) {
                                    this.props.history.push(
                                      "/club/payments/" + item.id_team
                                    );
                                  }
                                }}
                              >
                                <div className="player-photo">
                                  <TFBPlayerWithAvatar
                                    avatarUrl={item.img_url}
                                  />
                                </div>
                                <div className="player-error-container">
                                  <div
                                    className="player-name"
                                    style={{
                                      color:
                                        item.debt_for_previous_month == null
                                          ? "#666666"
                                          : "red",
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                  {item.debt_for_previous_month != null && (
                                    <div className="error-not-pay">
                                      {`Nu a achitat taxa pentru ${months[
                                        item.debt_for_previous_month
                                      ].toLowerCase()} ${
                                        item.debt_for_previous_month_year
                                      }`}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="present">
                                <TFBIconButton
                                  name="delete"
                                  onClick={() => {
                                    this.setState({
                                      deletePlayerPopup: true,
                                      idPlayerToDelete: item.id_player,
                                    });
                                  }}
                                  color="darkGray"
                                />
                              </div>
                            </div>
                            <div className="card-row">
                              <div className="present-plus-mark">
                                <div className="present">
                                  <Checkbox
                                    onChange={() => {
                                      let data = this.state.test_players;
                                      data[index].present = !item.present;
                                      if (!data[index].present) {
                                        data[index].mark = "";
                                      }
                                      let all_present = true;
                                      data.forEach((elem) => {
                                        if (!elem.present) {
                                          all_present = false;
                                          return;
                                        }
                                      });
                                      this.setState({
                                        test_players: data,
                                        all_present: all_present,
                                      });
                                    }}
                                    className="check-custom"
                                    checked={item.present}
                                  />
                                  <span>{"Prezent"}</span>
                                </div>
                                <div className="mark-row">
                                  <TFBSelect
                                    className="select-mark"
                                    placeholder={languagePack.mark}
                                    isMulti={false}
                                    options={this.state.marks}
                                    value={item.mark}
                                    isDisabled={!item.present}
                                    onChange={(selected) => {
                                      let data = this.state.test_players;
                                      data[index].mark = selected;
                                      let same_marks = true;
                                      data.forEach((elem) => {
                                        if (
                                          elem.mark.value !==
                                          this.state.mark_for_all
                                        ) {
                                          same_marks = false;
                                          return;
                                        }
                                      });
                                      if (!same_marks) {
                                        this.setState({ mark_for_all: "" });
                                      }
                                      this.setState({
                                        test_players: data,
                                      });
                                    }}
                                    style={{
                                      container: {
                                        width: "initial",
                                      },
                                      control: {
                                        minHeight: "initial",
                                        border: "1px solid #cccccc",
                                        borderRadius: "4px",
                                      },
                                      valueContainer: {
                                        paddingLeft: "8px",
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="remark">
                              <input
                                className="text-input"
                                placeholder={languagePack.add_remarks}
                                name="remark"
                                value={item.remark}
                                onChange={(e) => {
                                  let data = this.state.test_players;
                                  data[index].remark = e.target.value;
                                  this.setState({
                                    test_players: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="buttons-container">
                    <TFBButton
                      color="green"
                      onClick={this.saveData}
                      endIcon={true}
                      renderIcon={() => <TFBIcon name="check" />}
                    >
                      {languagePack["admin_save"]}
                    </TFBButton>
                  </div>
                </TFBCardBody>
              </div>
            </TFBCard>

            {/* ADD PLAYER TO TRAINING */}
            <Dialog
              open={this.state.add_player_to_training}
              fullWidth
              maxWidth="sm"
            >
              <DialogContent>
                <div
                  className="add-recurrent-form"
                  style={{ minHeight: this.state.add_player_popup_height }}
                >
                  <div className="add-recurrent-header">
                    <div className="add-recurrent-title">
                      {languagePack.add_player_to_training}
                    </div>
                    <div className="add-player-popup-close">
                      {" "}
                      <Icon
                        name="close"
                        className="close-icon-popup"
                        onClick={this.closeAddPlayerPopup}
                      />
                    </div>
                  </div>
                  <div className="add-recurrent-row add-recurrent-row2">
                    <TFBSelect
                      placeholder={languagePack.choose_player}
                      className="recurrent-select-teams"
                      options={this.state.players_list}
                      value={this.state.selected_player}
                      onChange={(selected) =>
                        this.setState({ selected_player: selected })
                      }
                    />
                  </div>
                  <div className="add-recurrent-row">
                    <div className="add-checkbox-input">
                      <Checkbox
                        label={
                          languagePack.add_player_to_future_training_sessions +
                          this.state.team_name
                        }
                        checked={this.state.add_player_to_all_future_trainings}
                        onChange={(e, data) => {
                          this.setState({
                            add_player_to_all_future_trainings: data.checked,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="add-recurrent-buttons">
                    <Button
                      className="custom-button-dual add-button"
                      onClick={this.addPlayerToTraining}
                      disabled={this.state.selected_player == null}
                    >
                      {" "}
                      <div className="button-content">
                        {languagePack["admin_add"]}
                        <Icon name="add" size="small" className="add-icon" />
                      </div>
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>

            {/* Remove player */}
            <Dialog open={this.state.deletePlayerPopup} fullWidth maxWidth="sm">
              <DialogContent>
                <DeletePlayer
                  closePopup={() =>
                    this.setState({
                      deletePlayerPopup: false,
                      idPlayerToDelete: "",
                    })
                  }
                  refreshPlayerList={this.getInitialData}
                  languagePack={this.props.languagePack}
                  idPlayerToDelete={this.state.idPlayerToDelete}
                  idTrainingSession={this.props.match.params.id}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TrainingDetailsPage);
