import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import styles from "./FinancialJustifyingDocument.module.scss";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import CircularLoader from "../../../components/reusable/Loader";

const AddJustifyingDocument = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser } = props;

    const { id_club, id_user } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            document_title: "",
            document_type: null,
            date_type: "single_date", // single_date/period_of_time
            date_start: null,
            date_end: null,
            provider: "",
            details: "",

            teams: [{ team: null }],
            load_players_for_team: null,
        }
    );

    const {
        data: teams,
        loading: isTeamsLoading,
    } = useAxiosGet(`club/get_teams_nomenclature?id_club=${id_club}&type=select&all=0`);

    const {
        data: players,
        loading: isPlayersLoading,
        refetch: refetchPlayers,
    } = useAxiosGet(`club/get_current_team_players?id_team=${state.load_players_for_team}&type=select&inactive=0`, true);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("finances/create_justifying_document");

    useEffect(() => {
        if (state.load_players_for_team > 0) {
            refetchPlayers();
        }
    }, [state.load_players_for_team])

    useEffect(() => {
        if (players?.success == 1) {
            const id_team = players?.id_team;
            const cloned_teams = _.clone(state.teams);
            const team_index = _.findIndex(cloned_teams, e => e?.team?.value == id_team);
            if (team_index > -1) {
                cloned_teams[team_index].players = _.map(players?.list ?? [], e => ({ ...e, checked: true }));
                cloned_teams[team_index].is_loading_players = false;
                updateState({ teams: cloned_teams })
            }
        }
    }, [players])

    const handleSelectTeam = (index, selected) => {
        const cloned_teams = _.clone(state.teams);
        cloned_teams[index].team = selected;
        cloned_teams[index].is_loading_players = true;
        updateState({ teams: cloned_teams, load_players_for_team: selected?.value ?? null })
    }

    const addTeam = () => {
        let cloned_teams = _.clone(state.teams);
        cloned_teams = _.union(cloned_teams, [{ team: null }]);
        updateState({ teams: cloned_teams })
    }

    const deleteTeam = (index) => {
        let cloned_teams = _.clone(state.teams);
        cloned_teams.splice(index, 1);
        updateState({ teams: cloned_teams })
    }

    const getTeamOptions = () => {
        return _.filter(teams?.list ?? [], e => (_.findIndex(state.teams, v => v?.team?.value == e.value)) == -1);
    }

    const handleChangePlayerCheckbox = (e, indexTeam, indexPlayer = "all") => {
        let cloned_teams = _.clone(state.teams);

        if (indexPlayer == "all") {
            cloned_teams[indexTeam].players = _.map(cloned_teams[indexTeam]?.players, p => ({ ...p, checked: e.target.checked }));
        } else {
            cloned_teams[indexTeam].players[indexPlayer].checked = e.target.checked;
        }
        updateState({ teams: cloned_teams })
    }

    const isDateValid = () => {
        if (state.date_type == "single_date") {
            return state.date_start != null;
        } else {
            if (state.date_start != null && state.date_end != null) {
                if (moment(state.date_end).format("DD.MM.YYYY") >= moment(state.date_start).format("DD.MM.YYYY")) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    const isValidAddDocument = () => {
        return _.size(_.filter(_.reduce(_.map(state.teams, e => e.players), (result, value, key) => {
            result = _.union(result, _.isArray(value) ? value : []);
            return result;
        }, []), e => e.checked)) > 0 && _.size(_.trim(state.document_title)) > 0 && !_.some(state?.teams, ["team", null]) && state.document_type != null && isDateValid() && (state.document_type?.value != "transportation_table" ? _.size(_.trim(state.provider)) > 0 : true)
    }

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.justifying_documents,
                languagePack.success_add_justifying_document
            );
        } else {
            showNotification(
                "danger",
                languagePack.justifying_documents,
                languagePack.fail_add_justifying_document
            );
        }
    };

    const addDocument = () => {
        const payload = {
            title: state.document_title,
            id_club: id_club,
            type: state.document_type?.value,
            added_by: id_user,
            provider: state.provider == "" ? null : state.provider,
            date_start: state.date_start,
            date_end: state.date_end,
            details: state.details,
            players: _.filter(_.reduce(_.map(state.teams, e => e.players), (result, value, key) => {
                result = _.union(result, _.isArray(value) ? value : []);
                return result;
            }, []), e => e.checked),
        };

        addRequest(payload, handleResponse);
    }


    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addDocument(),
        "green",
        "add",
        languagePack.admin_save,
        {},
        true,
        !isValidAddDocument(),
        loadingAddRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_justifying_document"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className={styles.stepTitle}>{languagePack.document_details}</div>
            <TFBInput
                label={languagePack.document_title}
                value={state.document_title}
                onChange={(e) => updateState({ document_title: e.target.value })}
                style={{ width: "100%", marginBottom: 20 }}
            />
            <TFBSelect
                label={languagePack.document_type}
                value={state.document_type}
                options={_.map(["accommodation_chart", "transportation_table", "meal_time"], e => ({ value: e, label: languagePack?.[e] }))}
                onChange={selected => updateState({ document_type: selected, provider: selected?.value == "transportation_table" ? "" : state.provider })}
                style={{ component: { marginBottom: 20 } }}
            />
            <div className={styles.documentTypeContainer}>
                <div className={styles.documentTypeList}>
                    <TFBCheckbox
                        checked={state.date_type == "single_date"}
                        label={languagePack.single_date}
                        onChange={(e) => updateState({ date_type: "single_date", date_end: null })}
                    />
                    <TFBCheckbox
                        checked={state.date_type == "period_of_time"}
                        label={languagePack.period_of_time}
                        onChange={(e) => updateState({ date_type: "period_of_time" })}
                    />
                </div>
            </div>
            <div className="df df-g20 df-mobile">
                <TFBDatePicker
                    value={state.date_start}
                    onChange={(value) => updateState({ date_start: value })}
                    label={languagePack["start_date"]}
                    style={{ marginBottom: 20 }}
                />
                {state.date_type == "period_of_time" &&
                    <>
                        <TFBDatePicker
                            value={state.date_end}
                            onChange={(value) => updateState({ date_end: value })}
                            label={languagePack["end_date"]}
                            style={{ marginBottom: 20 }}
                        />
                        {!isDateValid() && <div className="error-message">{languagePack["invalid_selected_period_of_time"]}</div>}
                    </>
                }
            </div>
            {state.document_type?.value != "transportation_table" &&
                <TFBInput
                    label={languagePack.provider}
                    value={state.provider}
                    onChange={(e) => updateState({ provider: e.target.value })}
                    style={{ width: "100%", marginBottom: 20 }}
                />
            }
            <TFBTextArea
                label={languagePack["document_details"]}
                value={state.details}
                style={{ marginBottom: 20 }}
                onChange={(e) => updateState({ details: e.target.value })}
            />
            <div className={styles.stepTitle}>{languagePack.choose_players}</div>
            <div className={styles.teamsContainer}>
                {_.map(state.teams, (value, key) => (
                    <div key={"team-" + key} className={styles.teamContainer}>
                        <div className="df df-g20 df-align-end">
                            <TFBSelect
                                label={languagePack.team}
                                placeholder={languagePack.select_team}
                                value={value?.team ?? null}
                                options={getTeamOptions()}
                                onChange={(selected) => handleSelectTeam(key, selected)}
                                style={{ component: { width: "300px" } }}
                            />
                            {_.size(state.teams) > 1 &&
                                <TFBIconButton
                                    name="x-large"
                                    color="red"
                                    onClick={() => deleteTeam(key)}
                                />
                            }
                        </div>
                        <div className={styles.playersContainer}>
                            {value?.is_loading_players ?
                                <CircularLoader /> :
                                <div className="df df-gap-15 df-wrap prevent-select-text">
                                    {_.size(value?.players) > 0 &&
                                        <TFBCheckbox
                                            key={"player-all"}
                                            checked={!_.some(value?.players, ["checked", false])}
                                            label={languagePack.all_players}
                                            onChange={(e) => handleChangePlayerCheckbox(e, key)}
                                            labelColor={"var(--primaryColor)"}
                                        />
                                    }
                                    {_.map(value?.players, (value2, key2) => (
                                        <TFBCheckbox
                                            key={"player-" + key2}
                                            checked={value2.checked}
                                            label={value2.label}
                                            onChange={(e) => handleChangePlayerCheckbox(e, key, key2)}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                ))}
            </div>
            {_.size(getTeamOptions()) > 0 &&
                <TFBButton
                    color="lightGray"
                    renderIcon={() => <TFBIcon name="add" color="gray" />}
                    disabled={_.some(state?.teams, ["team", null])}
                    onClick={addTeam}
                >
                    {languagePack.add_team}
                </TFBButton>
            }
        </TFBDialog>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(AddJustifyingDocument);