import React, { createContext, useEffect, useState } from "react";
import "./CreateAccount.scss";
import "./../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { IntlProvider } from "react-intl";
import { useSelector, connect, useDispatch } from "react-redux";
import Tabs from "./Tabs/Tabs";
import Form from "./Form/Form";
import StartTrial from "./StartTrial/StartTrial";
import { useLocation } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../../redux/authRedux";
import { baseUrl, isTFB, random } from "../utils/utilFunctions";
import languages from "./../utils/languages";
import TFBDropdown from "../components/design-system/Dropdown/TFBDropdown";
import { authReduxFunctions } from "../../redux/functions/authReduxFunctions";

const Title = ({ languagePack }) => {
  return (
    <>
      <span
        className="ds-headline-1"
        style={{ marginBottom: 25, lineHeight: "unset", fontSize: "27px" }}
      >
        {languagePack?.register_title?.[window.hostname]}
      </span>

      <span
        className="ds-main-text-auxiliary"
        style={{
          marginBottom: 25,
          lineHeight: "unset",
          fontWeight: 700,
          color: "#666666",
        }}
      >
        <span style={{ color: "var(--primaryColor)" }}>{languagePack?.register_subtitle_green?.[window.hostname]}</span> {languagePack?.register_subtitle?.[window.hostname]}
      </span>
    </>
  );
};

export const CreateAccountContext = createContext();

const backgroundImages = [1, 2, 3, 4].map(e => baseUrl(`uploads/custom/${window.hostname}/create_account_page/create-account-bg-${e}.jpg?v=${random()}`));

function CreateAccount() {
  const [step, setStep] = useState(0);
  const [accountEmail, setAccountEmail] = useState("");
  const [accountPassword, setAccountPassword] = useState(null);
  const [idUser, setIdUser] = useState(null);
  const [trialCode, setTrialCode] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.search) {
      setSubscriptionType(location.search);
    }
  }, [location.search]);

  const [randomIndex, setRandomIndex] = useState(null);

  const { lang, languagePack } = useSelector(
    ({ layoutService: { lang, languagePack } }) => ({
      lang,
      languagePack,
    })
  );

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * backgroundImages.length);
    setRandomIndex(randomIndex);
  }, []);

  const contextValue = {
    step,
    goNextStep: () => setStep(1),
    goPreviousStep: () => setStep(0),
    accountEmail,
    setAccountEmail,
    idUser,
    setIdUser,
    trialCode,
    setTrialCode,
    accountPassword,
    setAccountPassword,
    subscriptionType,
  };

  const changeLang = (e, data) => {
    authReduxFunctions(dispatch).changeLanguage(data.value);
  }

  const CreateAccount = (
    <div className="create-account-container">
      <Title languagePack={languagePack} />
      <Tabs languagePack={languagePack} />
      <div style={{ display: step == 0 ? "block" : "none" }}>
        <Form languagePack={languagePack} lang={lang} />
      </div>
      <div style={{ display: step == 1 ? "block" : "none" }}>
        <StartTrial languagePack={languagePack} />
      </div>
    </div>
  );

  return (
    <IntlProvider
      locale={lang.toLowerCase()}
      messages={languagePack.FORM_CONTROL}
    >
      <CreateAccountContext.Provider value={contextValue}>
        {!isTFB() ? <>
          <video autoPlay loop muted id="video-create-account" src={baseUrl(`uploads/custom/${window.hostname}/sign_up_background_video.mov`)} type="video/mov" />
          <div className="d-flex flex-root create-account-container-wrapper">
            {CreateAccount}
            <div className="language-container">
              <TFBDropdown
                color="lightGray"
                value={lang}
                options={languages}
                selection
                style={{ minWidth: 50 }}
                onChange={changeLang}
              />
            </div>
          </div>
        </> :
          <div
            className="d-flex flex-root create-account-container-wrapper"
            style={{
              backgroundImage: `url(${backgroundImages[randomIndex]})`,
            }}
          >
            {CreateAccount}
            <div className="language-container">
              <TFBDropdown
                color="lightGray"
                value={lang}
                options={languages}
                selection
                style={{ minWidth: 50 }}
                onChange={changeLang}
              />
            </div>
          </div>
        }
      </CreateAccountContext.Provider>
    </IntlProvider>
  );
}

export default injectIntl(connect(null, auth.actions)(CreateAccount));
