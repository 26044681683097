import React from "react";
import axios from "axios";
import { Dropdown, Button, Icon, Checkbox } from "semantic-ui-react";
import "../Club.styles.scss";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import { FALSE } from "sass";

class AddVenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      venue_name: "",
      is_parent: false,
      child_venues: [],
    };
  }

  addNewVenue = () => {
    const payload = {
      venue_name: this.state.venue_name,
      venue_childs: this.state.is_parent ? this.state.child_venues : [],
      id_club: this.props.id_club,
      is_parent: { false: "0", true: "1" }[this.state.is_parent],
    };
    axios.post("club/add_venue", payload).then(() => {
      store.addNotification({
        title: "Management club",
        message: "Terenul a fost adaugat cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.props.closePopup();
      this.props.getVenues();
      this.props.getVenuesSchedule();
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-player-form">
        <div className="add-player-header">
          <div className="add-player-title">{languagePack.add_venue}</div>
          <div className="add-player-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => this.props.closePopup()}
            />
          </div>
        </div>
        <div className="add-player-row">
          <input
            className="add-video-title"
            placeholder={languagePack.venue_name}
            onChange={(event) => {
              this.setState({ venue_name: event.target.value });
            }}
          />
        </div>
        <div className="add-player-row">
          <div className="add-checkbox-input">
            <Checkbox
              label={languagePack.has_subdivison}
              checked={this.state.is_parent}
              onChange={(e, data) => {
                this.setState({
                  is_parent: data.checked,
                });
                if (data.checked) {
                  if (this.state.child_venues.length == 0) {
                    this.state.child_venues.push({
                      venue_name: "",
                    });
                  }
                } else {
                  this.setState({ child_venues: [] });
                }
              }}
            />
          </div>
        </div>
        {this.state.is_parent &&
          this.state.child_venues.map((elem, index) => {
            return (
              <div
                className="add-player-row"
                style={{
                  marginBottom:
                    index == this.state.child_venues.length - 1 ? "0" : "",
                }}
                key={"venue-child-" + index}
              >
                <input
                  className="add-video-title"
                  placeholder={languagePack.subdivison_name}
                  onChange={(event) => {
                    let child_venues = this.state.child_venues;
                    child_venues[index].venue_name = event.target.value;
                    this.setState({ child_venues: child_venues });
                  }}
                />
                <Icon
                  name="minus"
                  size="large"
                  className="add-icon"
                  color="red"
                  onClick={() => {
                    let child_venues = this.state.child_venues;
                    child_venues.splice(index, 1);
                    this.setState({ child_venues: child_venues });
                    if (child_venues.length == 0) {
                      this.setState({ is_parent: false });
                    }
                  }}
                />
              </div>
            );
          })}
        {this.state.is_parent && (
          <div
            className="add-new-sub-venue"
            onClick={() => {
              let child_venues = this.state.child_venues;
              child_venues.push({ venue_name: "" });
              this.setState({ child_venues: child_venues });
            }}
          >
            {languagePack.add_new_subdivision}
          </div>
        )}
        <div className="add-player-buttons">
          {/* <Button
                        className="custom-button-dual cancel-button"
                        onClick={() => this.props.closePopup()}
                    >
                        {" "}
                        <div className="button-content">
                            {languagePack["admin_cancel"]}
                            <Icon name="ban" size="small" className="delete-icon" />
                        </div>
                    </Button> */}
          <Button
            className="custom-button-dual add-button"
            onClick={this.addNewVenue}
            disabled={
              this.state.venue_name.length > 0
                ? this.state.is_parent && this.state.child_venues.length > 0
                  ? !this.state.child_venues.every((elem) => {
                      if (elem.venue_name.length > 0) {
                        return true;
                      }
                      return false;
                    })
                  : false
                : true
            }
          >
            {" "}
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default AddVenue;
