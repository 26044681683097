import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./PaymentRequest.scss";
import { logout } from "./../../../utils/api";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import * as myConstants from "../../../../app/utils/constants";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import CheckboxLabel from "./CheckboxLabel";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

class AddPlayersToPaymentRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams_are_loaded: false,
      teams: [],
      selected_teams_players: [
        {
          players_are_loaded: false,
          team: null,
          players: [],
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getTeams();
  };

  getTeams = () => {
    axios
      .get(`finances/get_teams?token=${this.props.token}`)
      .then((res) =>
        this.setState({ teams: res.data.teams, teams_are_loaded: true })
      )
      .catch((err) => console.log(err));
  };

  addPlayersToPaymentRequest = () => {
    let payload = {
      id_entity: this.props.id,
      id_club: this.props.paymentRequestData?.id_club,
      amount: this.props.paymentRequestData?.amount,
      currency: this.props.paymentRequestData?.currency,
    };
    let teams_players = this.state.selected_teams_players;
    teams_players.forEach((team, index) => {
      teams_players[index].players = team.players.filter(
        (player) => player.checked && player.value != 0
      );
    });
    payload.teams_players = teams_players;

    axios
      .post("finances/add_players_to_payment_request_2", payload)
      .then((res) => {
        if (res.status == 200 && res.data.success == 1) {
          this.props.refreshList();
          store.addNotification({
            title: this.props.languagePack.payment_requests,
            message: this.props.languagePack
              .success_add_players_to_payment_request,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack.payment_requests,
            message: this.props.languagePack
              .fail_add_players_to_payment_request,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        this.props.closePopup();
      })
      .catch((e) => console.log(e));
  };

  selectTeam = async (index, selected) => {
    let teams_players = this.state.selected_teams_players;
    teams_players[index].team = selected;
    teams_players[index].players_are_loaded = false;

    //get players
    this.setState(
      {
        selected_teams_players: teams_players,
      },
      async () => {
        await axios
          .get(
            `finances/get_players?id_team=${selected.value}&token=${this.props.token}`
          )
          .then((res) => {
            let teams_players = this.state.selected_teams_players;
            let players = [...res.data.players].filter(
              (player) =>
                ![...this.props.exceptedPlayers].includes(player.value)
            );
            players[0].label = this.props.languagePack.all_players;
            teams_players[index].players = players;
            teams_players[index].players_are_loaded = true;
            this.setState({ selected_teams_players: teams_players });
          })
          .catch((err) => console.log(err));
      }
    );
  };

  addTeam = () => {
    let teams_players = this.state.selected_teams_players;
    teams_players.push({
      players_are_loaded: false,
      team: null,
      players: [],
    });
    this.setState({ selected_teams_players: teams_players });
  };

  deleteTeam = (indexTeam) => {
    let teams_players = this.state.selected_teams_players;
    teams_players.splice(indexTeam, 1);
    this.setState({ selected_teams_players: teams_players });
  };

  handleCheckbox = (indexTeam, indexPlayer) => {
    let teams_players = this.state.selected_teams_players;
    if (indexPlayer == 0) {
      teams_players[indexTeam].players.forEach((elem, index) => {
        if (index > 0) {
          teams_players[indexTeam].players[index].checked = !teams_players[
            indexTeam
          ].players[indexPlayer].checked;
        }
      });
    } else {
      if (teams_players[indexTeam].players[indexPlayer].checked) {
        //all players disabled
        teams_players[indexTeam].players[0].checked = false;
      } else {
        //check if all plaeyrs are selected
        let selected_players = teams_players[indexTeam].players.filter(
          (player, index) =>
            index != 0 && index != indexPlayer && player.checked
        ).length;
        if (selected_players == teams_players[indexTeam].players.length - 2) {
          teams_players[indexTeam].players[0].checked = true;
        }
      }
    }
    teams_players[indexTeam].players[indexPlayer].checked = !teams_players[
      indexTeam
    ].players[indexPlayer].checked;
    this.setState({ selected_teams_players: teams_players });
  };

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;

    {
      /* ----------------- ADD PAYMENT REQUEST ----------------*/
    }
    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.add_players}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            disabled={
              this.state.selected_teams_players.filter(
                (elem) =>
                  elem.team == null ||
                  elem.players.filter(
                    (player) => player.checked && player.value != 0
                  ).length == 0
              ).length > 0
            }
            onClick={this.addPlayersToPaymentRequest}
          >
            {languagePack.admin_add}
          </TFBButton>
        )}
      >
        <div className="pop-up-section">
          <div className="pop-up-section-body">
            {this.state.selected_teams_players.map((elem, index) => (
              <div className="pop-up-row" key={"team-" + index}>
                <label className="row-label">{languagePack.team}</label>
                <br />
                <div className="row-team">
                  <TFBSelect
                    placeholder={languagePack.choose_team}
                    options={[
                      ...this.state.teams.filter(
                        (elem1) =>
                          ![
                            ...this.state.selected_teams_players.map(
                              (elem) => elem.team?.value ?? null
                            ),
                          ].includes(elem1.value) ||
                          elem1.value == elem.team?.value
                      ),
                    ]}
                    value={elem.team}
                    onChange={(selected) => this.selectTeam(index, selected)}
                  />
                  {this.state.selected_teams_players.length > 1 && (
                    <TFBIconButton
                      name="x-large"
                      color="red"
                      onClick={() => this.deleteTeam(index)}
                    />
                  )}
                </div>
                <div className="row-player">
                  {elem.players_are_loaded &&
                    [...elem.players].map((player, index2) => (
                      <CheckboxLabel
                        key={"team-" + index + "-player-" + index2}
                        checked={player.checked}
                        label={player.label}
                        labelStyle={
                          player.checked && index2 == 0
                            ? { color: "var(--primaryColor)" }
                            : {}
                        }
                        onClick={() => this.handleCheckbox(index, index2)}
                      />
                    ))}
                </div>
              </div>
            ))}
            <div className="pop-up-row">
              <TFBButton
                color="lightGray"
                renderIcon={() => <TFBIcon name="add" color="gray" />}
                disabled={
                  this.state.selected_teams_players.filter(
                    (elem) => elem.team == null
                  ).length > 0
                }
                onClick={this.addTeam}
              >
                {languagePack.add_team}
              </TFBButton>
            </div>
          </div>
        </div>
      </TFBDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(AddPlayersToPaymentRequest);
