import React, { useReducer } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import TFBColorPicker from "../../design-system/TFBColorPicker/TFBColorPicker";
import { baseUrl } from "../../../utils/utilFunctions";

function EditEventTypePopup({
  refetchCategories,
  languagePack,
  categories,
  closePopup,
  token,
}) {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      categories: categories,
      newCategories: [],
      toDeleteCategories: [],
    }
  );

  const {
    loading: editEventTypesLoading,
    postData: postEditEventTypes,
  } = useAxiosPost(baseUrl("calendar/edit_category"));

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => updateCategory(),
    "green",
    "save",
    languagePack.admin_save,
    {},
    true,
    false,
    editEventTypesLoading
  );

  function handleChangeTagName(e, index) {
    const children = state.categories;

    children[index]["text"] = e.target.value;

    setState({ categories: children });
  }

  function updateCategory() {
    function handleResponse(response) {
      if (response.success === 1) {
        closePopup();
        refetchCategories();
        showNotification(
          "success",
          languagePack.calendar_title,
          languagePack.add_event_type_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.calendar_title,
          languagePack.add_event_type_fail
        );
      }
    }

    const toDelete = _.map(state.toDeleteCategories, (cat) => {
      return { id_cat: cat.value };
    });

    const payload = {
      token: token,
      categories: [...state.categories],
      categories_to_delete: toDelete,
    };

    postEditEventTypes(payload, handleResponse);
  }

  const handleRemoveColor = (index) => {
    setState({
      categories: _.map(state.categories, (cat, i) =>
        i === index ? { ...cat, color: "" } : { ...cat }
      ),
    });
  };
  const handleChangeColor = (color, index) => {
    setState({
      categories: _.map(state.categories, (cat, i) =>
        i === index ? { ...cat, color: color } : { ...cat }
      ),
    });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.edit_category}
      open
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <div className="pop-up-section">
        <div className="pop-up-section-body">
          {_.map(state.categories, (elem, index) => (
            <div
              className="pop-up-row df-g20 align-items-end"
              key={"venue-child-" + index}
            >
              <div
                className="color-picker-div"
                style={{ position: "relative" }}
              >
                {state.categories?.[index]["color"] === "" || (
                  <TFBIconButton
                    name="x-small"
                    style={{
                      height: "16px",
                      padding: "0px",
                      minWidth: "16px",
                      position: "absolute",
                      right: "-7px",
                      top: "15px",
                    }}
                    onClick={() => handleRemoveColor(index)}
                  />
                )}
                <TFBColorPicker
                  label={languagePack.category_color}
                  noColor={state.categories?.[index]["color"] === ""}
                  returnColor={(color) => handleChangeColor(color, index)}
                  initialColor={elem.color}
                  text={languagePack.select_color}
                />
              </div>
              <TFBInput
                label={languagePack.category_name}
                placeholder={languagePack.category_name}
                value={elem.text}
                fullWidth={true}
                onChange={(e) => handleChangeTagName(e, index)}
              />

              {_.size(state.categories) > 1 && (
                <TFBIconButton
                  name="x-large"
                  color="red"
                  size="lg"
                  onClick={() => {
                    let children = state.categories;
                    children.splice(index, 1);
                    let to_delete = [...state.toDeleteCategories];
                    if (elem?.value) {
                      to_delete = [...to_delete, elem];
                    }
                    setState({
                      categories: children,
                      toDeleteCategories: to_delete,
                    });
                  }}
                />
              )}
            </div>
          ))}
          <TFBButton
            color="lightGray"
            renderIcon={() => <TFBIcon name="add" color="gray" />}
            disabled={_.some(state.categories, ["text", ""])}
            onClick={() => {
              const children = state.categories;
              children && children.push({ text: "", color: "" });
              setState({
                categories: children,
              });
            }}
          >
            {languagePack.add_new_label}
          </TFBButton>
        </div>
      </div>
    </TFBDialog>
  );
}
export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditEventTypePopup);
