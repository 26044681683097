import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import "./FinancialCharts.scss";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class IncomeOutcomeChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 330,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [
            "Ian",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value.toLocaleString();
            },
          },
        },
        colors: [window.clubCustomization.primary_color, "#666666"],
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toLocaleString() + " Euro";
            },
          },
        },
      },
    };
  }

  componentDidMount() {
    axios
      .get("finances/get_data_chart1", {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ series: Array.isArray(res.data) ? res.data : [] });
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <div className="col-lg-6 col-xxl-6">
        <div className="card card-custom">
          <div className="financial-chart-dashboard-container">
            <div className="financial-chart-title">
              Raport incasari-cheltuieli
            </div>
            <div id="chart">
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height={330}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(IncomeOutcomeChart);
