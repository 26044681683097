import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { store } from "react-notifications-component"; // Importă obiectul store din biblioteca de notificări

export const useAxiosPost = (url, skipResponseHandler = false) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const postData = useCallback(
    async (postData, responseHandler) => {
      setLoading(true);

      try {
        const response = await axios.post(url, postData);
        setData(response.data);
        if (skipResponseHandler) return response.data;
        setError(null);

        // Apelăm funcția de manipulare a răspunsului, dacă este definită
        if (responseHandler) {
          responseHandler(response.data);
        }
      } catch (error) {
        setError(error);
      }

      setLoading(false);
    },
    [url]
  );

  useEffect(() => {
    return () => {
      // Cleanup function: se apelează când componenta este demontată
      // Aici poți adăuga logica de anulare a cererii sau alte acțiuni de curățare
    };
  }, []);

  return { data, error, loading, postData };
};

export const showNotification = (
  type,
  title,
  message,
  dismiss = {
    duration: 2000,
  },
  insert = "bottom",
  container = "bottom-right",
  animationIn = ["animate__animated", "animate__fadeIn"],
  animationOut = ["animate__animated", "animate__fadeOut"]
) => {
  return store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: insert,
    container: container,
    animationIn: animationIn,
    animationOut: animationOut,
    dismiss: dismiss,
  });
};
