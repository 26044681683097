import React from "react";
import axios from "axios";
import "./WeightControl.scss";
import { Icon, Button } from "semantic-ui-react";
import moment from "moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Dialog, DialogContent } from "@material-ui/core";
import { store } from "react-notifications-component";
import Moment from "react-moment";
import arrow from "../../../assets/icons/arrow-down.png";
import { connect } from "react-redux";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";

import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import { baseUrl } from "../../../utils/utilFunctions";
import _ from "lodash";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import { NoEncryption } from "@material-ui/icons";
import {
  sxStickyColumn,
  TFBTableCell,
} from "../../../components/reusable/useStyles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TFBShape from "../../../components/design-system/Shape/TFBShape";
import TFBCircularLoader from "../../../components/design-system/CircularLoader/TFBCircularLoader";
import { TFBTableRow } from "../../../components/reusable/useStyles";

class WeightControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [],
      teams: [],
      dates: [],
      period_start: moment().startOf("isoWeek"),
      period_end: moment().endOf("isoWeek"),
      selected_team: "",
      addWeightId: "",
      addOptimalWeightId: "",

      addedWeightNumber: "",
      addedOptWeightNumber: "",
      addedWeightDate: new Date(),
    };
  }

  componentDidMount = () => {
    this.getTeams();
  };

  getTeams = () => {
    axios
      .get(
        `training/get_club_teams?token=${this.props.token}&all=0&type=dropdown`
      )
      .then((res) =>
        this.setState(
          {
            teams: res.data?.list ?? [],
            selected_team:
              _.size(res.data?.list) > 0 ? res.data?.list[0]["value"] : "",
          },
          this.getPlayers
        )
      )
      .catch((err) => console.log(err));
  };

  getPlayers = () => {
    axios
      .get(
        `training/get_players_weight_2?&id_team=${
          this.state.selected_team
        }&date_start=${this.state.period_start.format(
          "YYYY-MM-DD"
        )}&date_end=${this.state.period_end.format("YYYY-MM-DD")}`
      )
      .then((res) =>
        this.setState({
          players: res.data.list,
          dates: res.data.dates,
          selected_team: res.data.id_team,
        })
      )
      .catch((err) => console.log(err));
  };

  decreasePeriod = () => {
    this.setState(
      {
        period_start: this.state.period_start.subtract(1, "weeks"),
        period_end: this.state.period_end.subtract(1, "weeks"),
      },
      this.getPlayers
    );
  };

  increasePeriod = () => {
    this.setState(
      {
        period_start: this.state.period_start.add(1, "weeks"),
        period_end: this.state.period_end.add(1, "weeks"),
      },
      this.getPlayers
    );
  };

  handleChangeDropdown = (e, value) => {
    this.setState(
      {
        [value.name]: value.value,
      },
      this.getPlayers
    );
  };

  handleDateChange = (value) => {
    this.setState({ addedWeightDate: value });
  };

  handleSubmitWeight = () => {
    const payload = {
      id_player: this.state.addWeightId,
      player_weight: this.state.addedWeightNumber,
      measure_date: moment(this.state.addedWeightDate).format("YYYY-MM-DD"),
    };
    axios
      .post("training/add_weight", payload)
      .then((res) => {
        store.addNotification({
          title: "Monitorizare greutate",
          message: "Masuratoarea a fost adaugata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState(
          { addWeightId: "", addedWeightNumber: "" },
          this.getPlayers
        );
      })
      .catch((err) => console.log(err));
  };

  handleSubmitOptWeight = () => {
    const payload = {
      id_player: this.state.addOptimalWeightId,
      player_weight: this.state.addedOptWeightNumber,
    };
    axios
      .post("training/add_optimal_weight", payload)
      .then((res) => {
        store.addNotification({
          title: "Monitorizare greutate",
          message: "Masuratoarea a fost adaugata cu succes",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState(
          { addOptimalWeightId: "", addedOptWeightNumber: "" },
          this.getPlayers
        );
      })
      .catch((err) => console.log(err));
  };
  handleDeleteWeight = (id_player_weight) => {
    axios
      .post("training/delete_weight", { id_player_weight })
      .then((res) => {
        if (res.data.success === 1) {
          store.addNotification({
            title: "Monitorizare greutate",
            message: "Masuratoarea a fost stearsa cu succes",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.getPlayers(); // Refresh the player list
        } else {
          store.addNotification({
            title: "Eroare",
            message: "Nu s-a putut sterge masuratoarea",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        store.addNotification({
          title: "Eroare",
          message: "Nu s-a putut sterge masuratoarea",
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <div className="weight-control-container">
          {/* <div className="weight-control-header">
            <div className="weight-control-title">
              {languagePack["weight_control"]}
            </div>

            <div className="wrapper-weight-control-team-dropdown">
              <Dropdown
                selection
                className="weight-control-team-dropdown"
                options={this.state.teams}
                placeholder={languagePack["select_team"]}
                value={this.state.selected_team}
                name="selected_team"
                onChange={this.handleChangeDropdown}
              />
            </div>
          </div> */}

          <TFBCardHeader title={languagePack["weight_control"]}>
            <TFBDropdown
              placeholder={languagePack["select_team"]}
              selection
              options={this.state.teams}
              name="selected_team"
              color="gray"
              value={this.state.selected_team}
              onChange={this.handleChangeDropdown}
            />
          </TFBCardHeader>

          <div className="period-container">
            <img
              src={arrow}
              alt=""
              onClick={this.decreasePeriod}
              className="left-arrow"
            />

            <div className="period">
              {this.state.period_start.format("D MMM")} -{" "}
              {this.state.period_end.format("D MMM")}
            </div>

            <img
              src={arrow}
              alt=""
              onClick={this.increasePeriod}
              className="right-arrow"
            />
          </div>

          <TableContainer>
            <Table>
              <TableHead>
                <TFBTableRow>
                  <TFBTableCell>{languagePack["PLAYER_NAME"]}</TFBTableCell>
                  {this.state.dates.map((date, index) => (
                    <TFBTableCell key={`date-${index}`} align="center">
                      <Moment format="DD MMM">{date}</Moment>
                    </TFBTableCell>
                  ))}
                  <TFBTableCell align="center">
                    {languagePack["optimal_weight"]}
                  </TFBTableCell>
                  <TFBTableCell align="right">
                    {languagePack["add_new_weight"]}
                  </TFBTableCell>
                </TFBTableRow>
              </TableHead>
              <TableBody>
                {this.state.players?.map((item, index) => (
                  <TFBTableRow key={`player-${index}`}>
                    <TFBTableCell>
                      <TFBPlayerWithAvatar
                        avatarUrl={baseUrl(
                          `uploads/players/${item.player_data.id_player}.jpg`
                        )}
                        playerName={item.label}
                        onClick={() =>
                          this.props.history.push(`/club/player/${item.value}`)
                        }
                      />
                    </TFBTableCell>
                    {item.weight_history?.list?.map((weight, weightIndex) => (
                      <TFBTableCell
                        key={`weight-${weightIndex}`}
                        className="weight-cell"
                        align="center"
                        style={{
                          color:
                            weight.data &&
                            Number(weight.data?.player_weight) -
                              Number(item.player_data?.optimal_weight) >=
                              1
                              ? "#d91200"
                              : Number(weight.data?.player_weight) -
                                  Number(item.player_data?.optimal_weight) <
                                1
                              ? "var(--primaryColor"
                              : "#666666",
                          position: "relative",
                        }}
                      >
                        {weight.data && weight.data.player_weight}{" "}
                        {weight.data && weight.data.player_weight ? "kg" : ""}
                        {weight.data && (
                          <TFBIcon
                            onClick={() =>
                              this.handleDeleteWeight(
                                weight.data.id_player_weight
                              )
                            }
                            name="delete"
                            color="black"
                            className="edit-button-weight-control"
                            style={{
                              position: "absolute",
                              padding: "4px",
                              top: "25px",
                              right: "6px",
                              fontSize: "12px",
                              display: "none",
                            }}
                          />
                        )}
                      </TFBTableCell>
                    ))}
                    <TFBTableCell
                      align="center"
                      style={{ position: "relative" }}
                    >
                      {item.player_data?.optimal_weight ? (
                        <div
                          title={languagePack["add_optimal_weight"]}
                          style={{
                            cursor: "pointer",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="weight-cell"
                        >
                          {`${item.player_data?.optimal_weight} kg`}
                          <TFBIcon
                            onClick={() =>
                              this.setState({
                                addOptimalWeightId: item.player_data.id_player,
                                addedOptWeightNumber:
                                  item.player_data?.optimal_weight,
                              })
                            }
                            name="edit"
                            color="black"
                            className="edit-button-weight-control"
                            style={{
                              position: "absolute",
                              padding: "4px",
                              top: "25px",
                              right: "40px",
                              fontSize: "12px",
                              display: "none",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <TFBIconButton
                            name="add"
                            color="darkGray"
                            onClick={() =>
                              this.setState({
                                addOptimalWeightId: item.player_data.id_player,
                                addedOptWeightNumber: "",
                              })
                            }
                            title={languagePack["add_optimal_weight"]}
                          />
                        </div>
                      )}
                    </TFBTableCell>
                    <TFBTableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <TFBIconButton
                          name="add"
                          color="green"
                          onClick={() =>
                            this.setState({
                              addWeightId: item.player_data.id_player,
                            })
                          }
                        />
                      </div>
                    </TFBTableCell>
                  </TFBTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Dialog
          open={this.state.addWeightId !== ""}
          // onClose={() => this.setState({ addWeightId: "" })}
        >
          <DialogContent>
            <div className="add-player-weight-popup">
              <div className="add-player-weight-header">
                <div className="add-weight-title">
                  {languagePack["add_weight"]}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={() => this.setState({ addWeightId: "" })}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="add-weight-date-row">
                  <DatePicker
                    value={this.state.addedWeightDate}
                    onChange={this.handleDateChange}
                    format="dd MMMM yyyy"
                    name="addedWeightDate"
                    inputVariant="outlined"
                    className="weight-date-input"
                    emptyLabel={languagePack["measure_date"]}
                  />
                  <Icon
                    name="calendar alternate outline"
                    className="weight-calendar-icon"
                    size="small"
                  />
                </div>
              </MuiPickersUtilsProvider>
              <input
                value={this.state.addedWeightNumber}
                onChange={(e) =>
                  this.setState({ addedWeightNumber: e.target.value })
                }
                className="add-weight-input"
                placeholder={languagePack["trasnaction_amount"]}
              />
              <Button
                className="add-player-weight-button"
                onClick={this.handleSubmitWeight}
              >
                {languagePack["admin_save"]}
                <Icon name="add" className="player-weight-button-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.addOptimalWeightId !== ""}
          // onClose={() => this.setState({ addOptimalWeightId: "" })}
        >
          <DialogContent>
            <div className="add-player-weight-popup">
              <div className="add-player-weight-header">
                <div className="add-weight-title">
                  {languagePack["add_optimal_weight"]}
                </div>
                <Icon
                  name="close"
                  size="large"
                  onClick={() => this.setState({ addOptimalWeightId: "" })}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <input
                value={this.state.addedOptWeightNumber}
                onChange={(e) =>
                  this.setState({ addedOptWeightNumber: e.target.value })
                }
                className="add-weight-input"
                placeholder={languagePack["trasnaction_amount"]}
              />
              <Button
                className="add-player-weight-button"
                onClick={this.handleSubmitOptWeight}
              >
                {languagePack["admin_save"]}
                <Icon name="add" className="player-weight-button-icon" />
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(WeightControl);
