export const initialShapeProps = {
    width: 1,
    height: 1,
    visible: false,
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 8,
    transparentCorners: true,
    rotationCursor: "pointer",
};

export const initialFigureProps = {
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 8,
    transparentCorners: true
};

export const initialGroupProps = {
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 8,
    transparentCorners: true,
    hasRotatingPoint: false,
};

export const initialTextInputProps = {
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 12,
    transparentCorners: true,
    fontFamily: 'arial',
    fontSize: 14,
    padding: 5,
    editingBorderColor: '#2cc681',
    textAlign: 'left',
    editable: true,
    strokeWidth: 4,
    lockScalingX: true,
    lockScalingY: true,
    lockSkewingX: true,
    lockSkewingY: true,
    lockUniScaling: true,
};

export const initialLineProps = {
    hasBorders: false,
    visible: true,
    transparentCorners: true,
    strokeWidth: 2,
    selectable: false,
    evented: false,
}

export const dashedProps = {
    strokeDashArray: [6],
}

export const arrowTriangle = {
    originX: 'center',
    originY: 'center',
    width: 10,
    height: 10,
    angle: 0,
    selectable: false,
    evented: false,
}

export const initialWavyProps = {
    strokeWidth: 2,
    fill: null,  // No fill for the wave
    selectable: false,
    objectCaching: false,
    hasBorders: false,
};

export const wavyDashedProps = {
    strokeDashArray: [6, 3],
}

export const polylineTriangle = {
    strokeWidth: 2,
    selectable: false,
    objectCaching: false
}

export const lineGroupProps = {
    hasBorders: false,
    visible: true,
    borderColor: '#2cc681',
    cornerColor: '#2cc681',
    cornerSize: 14,
    transparentCorners: true,
    rotationCursor: "pointer",
    originX: 'left',
    originY: 'center', // Align the group vertically
    selectable: true,
    evented: true,
    objectCaching: false,
}

export const squareControlProps = {
    width: 18,
    height: 18,
    fill: 'transparent',
    stroke: '#45d695',
    strokeWidth: 2,
    originX: 'center',
    originY: 'center',
    selectable: true,
    hasControls: false,
    hasBorders: false,
    zIndex: 99999999,
}

export const resizeShapeProps = {
    "square": {
        tl: true, //top-left
        mt: false, // middle-top
        tr: true, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: true, // bottom-left
        mb: false, //middle-bottom
        br: true, //bottom-right 
    },
    "rectangle": {
        tl: true, //top-left
        mt: true, // middle-top
        tr: true, //top-right
        ml: true, //middle-left
        mr: true, //middle-right
        bl: true, // bottom-left
        mb: true, //middle-bottom
        br: true, //bottom-right
    },
    "circle": {
        tl: true, //top-left
        mt: false, // middle-top
        tr: true, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: true, // bottom-left
        mb: false, //middle-bottom
        br: true, //bottom-right 
    },
    "oval": {
        tl: true, //top-left
        mt: true, // middle-top
        tr: true, //top-right
        ml: true, //middle-left
        mr: true, //middle-right
        bl: true, // bottom-left
        mb: true, //middle-bottom
        br: true, //bottom-right
    },
    "figures": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: true, //rotation-point
    },
    "group": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: false, //rotation-point
    },
    "text_input": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
        mtr: true, //rotation-point
    },
    "line": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "dashed-line": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "arrow": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "dashed-arrow": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "wavy-line": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "wavy-arrow": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "dashed-wavy-line": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
    "dashed-wavy-arrow": {
        tl: false, //top-left
        mt: false, // middle-top
        tr: false, //top-right
        ml: false, //middle-left
        mr: false, //middle-right
        bl: false, // bottom-left
        mb: false, //middle-bottom
        br: false, //bottom-right
    },
};