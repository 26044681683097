import React from "react";
import styles from "./MatchResult.module.scss";
import TFBInput from "../../design-system/Input/TFBInput";
import { connect } from "react-redux";

export type matchResultTypes = {
  style?: React.CSSProperties;
  children?: any;
  className?: string;
  type?: "half" | "final";
  homeTeamName?: string;
  awayTeamName?: string;
  languagePack?: any;
  homeScore?: any;
  awayScore?: any;
  onChangeHomeScore?: () => void;
  onChangeAwayScore?: () => void;
};

const MatchResult = ({
  style,
  className,
  languagePack,
  type,
  children,
  homeScore,
  awayScore,
  homeTeamName = "Echipa 1",
  awayTeamName = "Echipa 2",
  onChangeHomeScore,
  onChangeAwayScore,
}: matchResultTypes) => {
  return (
    <div className={styles.matchResultWrapper}>
      <div>
        {type == "half"
          ? languagePack.half_time_result
          : languagePack.full_time_result}
      </div>
      <div className={styles.matchResult}>
        <div className={styles.teamName}>{homeTeamName}</div>

        <div className={styles.result}>
          <TFBInput
            className="result-value-input"
            defaultValue={homeScore}
            onChange={onChangeHomeScore}
          />
          {":"}
          <TFBInput
            className="result-value-input"
            defaultValue={awayScore}
            onChange={onChangeAwayScore}
          />
        </div>

        <div className={styles.teamName}>{awayTeamName}</div>
      </div>
    </div>
  );
};

export default connect(({ layoutService }: any) => ({
  languagePack: layoutService.languagePack,
}))(MatchResult);
