import React from "react";
import axios from "axios";
import { Placeholder } from "semantic-ui-react";
import { connect } from "react-redux";

import "../Club.styles.scss";
import "./PlayerProfile.scss";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

class PlayerVideoHighlightsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player_videos: [],
      current_video: "",
      playing_tag: {
        data_video_tag: {
          second_start: "",
          second_final: "",
        },
      },
      event_type: eventsType[0],
      selected_event_type: this.event_types[0].value,
    };
  }

  event_types = [
    {
      key: "0",
      text: this.props.languagePack.dropdown["Toate"],
      value: "0",
    },
    {
      key: "1",
      text: this.props.languagePack.dropdown["Gol"],
      value: "1",
    },
    {
      key: "2",
      text: this.props.languagePack.dropdown["Assist"],
      value: "2",
    },
    {
      key: "3",
      text: this.props.languagePack.dropdown["yellow_card"],
      value: "3",
    },
    {
      key: "4",
      text: this.props.languagePack.dropdown["red_card"],
      value: "4",
    },
  ];

  componentDidMount = () => {
    this.getPlayerVideoData();
  };

  getPlayerVideoData = () => {
    axios
      .get(
        `player/get_player_video_data?id_player=${
          this.props.id_player
        }&filter_type=${
          this.event_types.find(
            (elem) => elem.value == this.state.selected_event_type
          ).text
        }`
      )
      .then((res) => {
        this.setState({
          player_videos: res.data,
          playing_tag:
            res.data.length > 0
              ? res.data[0].video_tags[0]
              : {
                  data_video_tag: {
                    second_start: "",
                    second_final: "",
                  },
                },
          current_video:
            res.data.length > 0 ? res.data[0].data_video.video_url : "",
        });
      })
      .catch((err) => console.log(err));
  };

  toHHMMSS(secs) {
    const sec = parseInt(secs, 10);
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = sec - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }

  handleChangeEventType = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getPlayerVideoData);
  };

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.player_video_clips}>
          <TFBDropdown
            placeholder={"Tip eveniment"}
            selection
            options={this.event_types}
            name="selected_event_type"
            color="gray"
            value={this.state.selected_event_type}
            onChange={this.handleChangeEventType}
          />
        </TFBCardHeader>
        <TFBCardBody className="player-highlights-widget-container">
          {this.state.player_videos.length > 0 ? (
            <div className="content-highlights-container">
              <div className="video-container">
                <video
                  className="video"
                  src={`${this.state.current_video}#t=${this.state.playing_tag.data_video_tag.second_start},${this.state.playing_tag.data_video_tag.second_final}`}
                  controls
                  type="video/mp4"
                  style={{ width: "100%", margin: "auto" }}
                />
                {this.state.current_video == "" && (
                  <Placeholder.Image rectangular />
                )}
              </div>
              <div className="events-container">
                {this.state.player_videos.map((elem, index) => {
                  return (
                    <div
                      key={"video-with-player" + elem.data_video.id_video}
                      className="player-video-container"
                    >
                      <div className="player-video-title">
                        {index + 1 + ". " + elem.data_video.video_name}
                      </div>
                      <div className="player-video-tags">
                        {elem.video_tags.map((item, index2) => {
                          return (
                            <div
                              id={
                                "video-tag-" + item.data_video_tag.id_video_tag
                              }
                              key={"video-tag-" + index + "-" + index2}
                              className="player-video-tag"
                              onClick={() => {
                                this.setState({
                                  playing_tag: item,
                                  current_video: elem.data_video.video_url,
                                });
                              }}
                            >
                              <div className="video-tag-data">
                                <div className="video-time-event">
                                  <div className="video-tage-time">
                                    {this.toHHMMSS(
                                      item.data_video_tag.second_start
                                    )}
                                  </div>
                                  <div className="video-tag-details">
                                    {item.video_tag_details.map((e, index) => {
                                      return (
                                        <a
                                          key={"video-tag-details-" + index}
                                          style={{
                                            color:
                                              event_type_colors[
                                                e.tag_categ_name
                                              ],
                                          }}
                                        >
                                          {index + 1 ==
                                          item.video_tag_details.length
                                            ? e.tag_categ_name
                                            : e.tag_categ_name + ", "}
                                        </a>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="video-tag-name">
                                  {item.data_video_tag.video_tag_title}
                                </div>
                                {this.state.playing_tag.data_video_tag
                                  .id_video_tag ==
                                  item.data_video_tag.id_video_tag && (
                                  <div
                                    className="video-actual-border"
                                    style={{
                                      borderBottom: "3px solid #8d8d8d",
                                      width: "42px",
                                    }}
                                  ></div>
                                )}
                              </div>
                              <div className="video-state">
                                {this.state.playing_tag.data_video_tag
                                  .id_video_tag ==
                                item.data_video_tag.id_video_tag ? (
                                  <TFBIconButton
                                    name="pause"
                                    color="darkGray"
                                  />
                                ) : (
                                  <TFBIconButton name="play" color="darkGray" />
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <TFBPlaceholder text={languagePack.no_video_events} />
          )}
        </TFBCardBody>
      </TFBCard>
    );
  }
}

const eventsType = [
  "Toate",
  "Gol",
  "Asist",
  "Cartonas galben",
  "Cartonas rosu",
];

// const event_types = [
//   {
//     key: "0",
//     text: "Toate",
//     value: "0",
//   },
//   {
//     key: "1",
//     text: "Gol",
//     value: "1",
//   },
//   {
//     key: "2",
//     text: "Asist",
//     value: "2",
//   },
//   {
//     key: "3",
//     text: "Cartonas galben",
//     value: "3",
//   },
//   {
//     key: "4",
//     text: "Cartonas rosu",
//     value: "4",
//   },
// ];

const event_type_colors = {
  Gol: "#1adb57",
  Asist: "blue",
  "Cartonas galben": "#fedc46",
  "Cartonas rosu": "red",
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(PlayerVideoHighlightsWidget);
