import React, { createContext, useEffect, useReducer } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import ImportDataFilePopup from "./ImportTrainingData/ImportDataFilePopup.jsx";
import styles from "./TrainingData.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TrainingDataTable from "./TrainingDataTable";
import TrainingDataChart from "./TrainingDataChart";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import { baseUrl } from "../../../utils/utilFunctions";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

export const TrainingDataContext = createContext({});
function TrainingData({ languagePack, token, match }) {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      openUploadFile: false,
      tab: "table",
      chartParameters: [],
    }
  );

  const { loading: loadingDeleteData, postData: postDeleteData } = useAxiosPost(
    baseUrl(`training/delete_ksport_stats_from_training`)
  );

  const {
    data: kSportData,
    loading: loadingKSportData,
    refetch: refetchKSportData,
  } = useAxiosGet(
    baseUrl(`training/get_ksport_stat?token=${token}&id_training=${match}`)
  );
  const handleDeleteData = () => {
    const responseHandler = (res) => {
      if (res.success === 1) {
        refetchKSportData();
        showNotification(
          "success",
          languagePack.TRAININGS,
          languagePack.delete_data_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.TRAININGS,
          languagePack.delete_data_fail
        );
      }
    };
    const payload = { token, id_training: match };
    postDeleteData(payload, responseHandler);
  };
  useEffect(() => {
    setState({
      chartParameters: [
        {
          label: kSportData?.params?.[0]?.param_name,
          value: kSportData?.params?.[0]?.id_param,
        },
      ],
    });
  }, [kSportData]);

  const handleChangeSelect = (selected, value) => {
    setState({ [value.name]: selected });
  };

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.training_data}>
        <TFBButton
          onClick={() => setState({ openUploadFile: true })}
          color="green"
          renderIcon={() => <TFBIcon name="arrow-up" />}
        >
          {languagePack.upload_file}
        </TFBButton>
        <TFBButton
          onClick={handleDeleteData}
          color="red"
          loading={loadingDeleteData}
          renderIcon={() => <TFBIcon name="delete" />}
        >
          {languagePack.delete_data}
        </TFBButton>
      </TFBCardHeader>
      <TFBCardBody>
        <TrainingDataContext.Provider
          value={{
            ...state,
            idTraining: match,
            loadingKSportData,
            refetchKSportData,

            kSportData,
            closeDataFilePopup: () => setState({ openUploadFile: false }),
          }}
        >
          {_.size(kSportData?.players) > 0 ? (
            <>
              {" "}
              <div className={styles.topDiv}>
                <div className={styles.tabContainer}>
                  <div
                    className={styles.tab}
                    onClick={() => setState({ tab: "table" })}
                    style={{
                      backgroundColor:
                        state.tab === "table" ? "#fafafa" : "#fff",
                    }}
                  >
                    <div>{languagePack.table}</div>
                  </div>
                  <div
                    onClick={() => setState({ tab: "chart" })}
                    className={styles.tab}
                    style={{
                      backgroundColor:
                        state.tab === "chart" ? "#fafafa" : "#fff",
                    }}
                  >
                    <div>{languagePack.chart}</div>
                  </div>
                </div>
                {state.tab === "chart" && (
                  <TFBSelect
                    isMulti
                    name="chartParameters"
                    value={state.chartParameters}
                    onChange={handleChangeSelect}
                    isSearchable
                    placeholder={languagePack.select_parameters}
                    options={_.map(kSportData?.params, (param) => {
                      return {
                        label: param?.param_name,
                        value: param?.id_param,
                      };
                    })}
                    style={{
                      container: {
                        maxWidth:
                          _.size(state.chartParameters) > 2 ? "700px" : "300px",
                      },
                    }}
                  />
                )}
              </div>
              <div className={styles.tableChartContainer}>
                {state.tab === "table" && <TrainingDataTable />}
                {state.tab === "chart" && <TrainingDataChart />}
              </div>
            </>
          ) : (
            <TFBPlaceholder text={languagePack.no_training_data} />
          )}

          {state.openUploadFile && <ImportDataFilePopup />}
        </TrainingDataContext.Provider>
      </TFBCardBody>
    </TFBCard>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(TrainingData);
