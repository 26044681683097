import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button, TextArea, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import forbidden_icon from "../../assets/icons/forbidden-white.png";

function BonusOptionsList(props) {
  const [options, setOptions] = useState([]);
  const [addedCondition, setAddedCondition] = useState("");
  const [addCondition, setAddCondition] = useState(false);
  const optionsEndRef = useRef(null);
  const { languagePack } = props;
  const fetchData = async () => {
    const result = await axios(
      `upload/get_bonus_conditions?id_club=${props.currentUser.id_club}`
    );
    setOptions(Array.isArray(result.data) ? result.data : []);
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleChangeInput(e) {
    setAddedCondition(e.target.value);
  }

  function submitOption() {
    const condition = {
      bonus_condition_name: addedCondition,
      id_club: props.currentUser.id_club,
    };
    axios
      .post(
        `upload/add_bonus_condition?id_club=${props.currentUser.id_club}`,
        condition
      )
      .then(() => {
        fetchData();
        setAddedCondition("");
        scrollToBottom();
      });
  }

  const scrollToBottom = () => {
    optionsEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="options-container">
      <div className="options-list">
        {options.map((item, index) => {
          return (
            <div
              className="option-row"
              style={{ backgroundColor: index % 2 == 1 ? "#fafafa" : "white" }}
              key={"option-row-" + index}
            >
              {item.text}
            </div>
          );
        })}
        <div ref={optionsEndRef} style={{ height: 30 }} />
      </div>
      {addCondition ? (
        <div className="add-option-container-opened">
          <TextArea
            placeholder={languagePack["Bonus_name"]}
            name="bonus-condition"
            value={addedCondition}
            onChange={handleChangeInput}
            className="text-area-bonus-cond"
          />
          <div className="add-option-container-opened-buttons">
            <Button
              className="cancel-bonus-button"
              onClick={() => setAddCondition(false)}
            >
              <div className="button-flex-content">
                {languagePack["admin_cancel"]}
                <img src={forbidden_icon} alt="" />
              </div>
            </Button>
            <Button className="add-bonus-button" onClick={() => submitOption()}>
              <div className="button-flex-content">
                {languagePack["admin_add"]}
                <Icon name="add" className="add-white-icon" />
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div className="add-option-container">
          <Button
            className="add-option-button"
            onClick={() => setAddCondition(true)}
          >
            <div className="button-flex-content">
              {languagePack["Add_bonus"]}
              <Icon name="add" className="add-white-icon" />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(BonusOptionsList);
