import React, { useEffect, useState } from "react";
import TFBDialog from "../../../../../app/components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../../../app/components/design-system/Input/TFBInput";
import TFBIcon from "../../../../../app/components/design-system/Icon/TFBIcon";
import useRenderButton from "../../../../../app/components/customHooks/useRenderButton";
import {
  showNotification,
  useAxiosPost,
} from "../../../../../app/components/customHooks/useAxiosPost";
import "./EditClub.scss";

const EDIT_CLUB_ROUTE = "/club/customize_club";

const EditClub = ({
  openPopup,
  closePopup,
  user,
  changeClubLogo,
  changeClubName,
  tempClubName,
}) => {
  const [clubName, setClubName] = useState(
    tempClubName ? tempClubName : user.club.club_name
  );
  const [teamImage, seTeamImage] = useState("");
  const [imageFile, setImageFile] = useState("");

  const { data: resonseEdit, postData: postEditClub } = useAxiosPost(
    EDIT_CLUB_ROUTE
  );

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    "Anulează"
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => editClub(),
    "green",
    "check",
    "Editează",
    {},
    true,
    clubName == ""
  );

  useEffect(() => {
    if (!resonseEdit) return;

    if (resonseEdit.success == 1) {
      showNotification("success", "Editare club", "Ai editat clubul cu succes");
      closePopup();

      if (teamImage != "") changeClubLogo(teamImage);
      changeClubName(clubName);
    } else {
      showNotification(
        "danger",
        "Editare club",
        "A apărut o eroare la editare"
      );
    }
  }, [resonseEdit]);

  const handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      seTeamImage(reader.result);
    };
    setImageFile(file);
  };

  const editClub = () => {
    const formData = new FormData();
    formData.append("token", user.token);
    formData.append("id_club", user.club.id_club);
    formData.append("club_name", clubName);
    if (imageFile != "") {
      formData.append("image", imageFile);
    }

    postEditClub(formData);
  };

  return (
    <TFBDialog
      title="Editează club"
      open={openPopup}
      closePopup={closePopup}
      maxWidth={"sm"}
      fullWidth
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <TFBInput
        value={clubName}
        onChange={(e) => setClubName(e.target.value)}
        fullWidth
        label="Nume Club"
      />

      <div className="edit-club-logo-image" style={{ marginTop: 25 }}>
        <div className="image-input-container">
          <span>{"Logo club"}</span>
          <input
            type="file"
            className="add-news-image-input"
            id="customFile"
            onChange={handleChangeImage}
            accept="image/*"
          />
          <label className="add-file-form-file-label" htmlFor="customFile">
            <div className="image-view">
              {teamImage ? (
                <img src={teamImage} />
              ) : (
                <span>
                  <TFBIcon
                    name="arrow-up"
                    className="cusor-pointer"
                    color="black"
                  />
                </span>
              )}
            </div>
          </label>
        </div>
      </div>
    </TFBDialog>
  );
};

export default EditClub;
