import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";

import { showNotification, useAxiosPost } from "../../../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../../../components/customHooks/useAxiosGet";
import useRenderButton from "../../../../../components/customHooks/useRenderButton";

import { TFBTableCell, TFBTableRow } from "../../../../../components/reusable/useStyles";
import TFBCard from "../../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../../components/design-system/Icon/TFBIcon";
import TFBPlaceholder from "../../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../../../components/design-system/IconButton/TFBIconButton";
import TFBDialog from "../../../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../../../components/design-system/TFBSelect/TFBSelect";
import CircularLoader from "../../../../../components/reusable/Loader";

import VideoDetails from "../../../../VideoGallery/VideoDetails/VideoDetails";

const MatchVideo = (props) => {
    const { languagePack, id_club, token, matchDetails } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_add_video: false,

            open_edit_video: false,
            editing_video: {},

            open_delete_video: false,
            deleting_video: {},
        }
    );

    const openAddVideo = () => {
        updateState({ open_add_video: true })
    }

    const closeAddVideo = () => {
        updateState({ open_add_video: false })
    }

    const openEditVideo = (video) => {
        updateState({ open_edit_video: true, editing_video: video })
    }

    const closeEditVideo = () => {
        updateState({ open_edit_video: false, editing_video: {} })
    }

    const openDeleteVideo = (video) => {
        updateState({ open_delete_video: true, deleting_video: video })
    }

    const closeDeleteVideo = () => {
        updateState({ open_delete_video: false, deleting_video: {} })
    }

    const {
        data: matchVideoList,
        loading: isMatchVideoListLoading,
        refetch: refetchMatchVideoList
    } = useAxiosGet(`matches/get_match_videos?id_match=${matchDetails.id_match}`);

    return (
        <TFBCard className="card-mb">
            <TFBCardHeader title={languagePack["related_videos"]}>
                <TFBButton
                    color="green"
                    onClick={openAddVideo}
                    renderIcon={() => <TFBIcon name="add" />}
                >
                    {languagePack["add_video"]}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                {!isMatchVideoListLoading ?
                    (_.size(matchVideoList?.list) > 0 ?
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TFBTableRow>
                                        <TFBTableCell>{languagePack.video_title}</TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.video_source}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                        </TFBTableCell>
                                    </TFBTableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(matchVideoList.list, (row, index) => (
                                        <TFBTableRow key={"video-" + index}>
                                            <TFBTableCell>{row.video_name}</TFBTableCell>
                                            <TFBTableCell align="center">
                                                {row.video_source == "veo" && <TFBIcon className="tfb-icon-button-no-filter" name="veo" />}
                                            </TFBTableCell>
                                            <TFBTableCell align="right">
                                                <div className="actions-btns-container df-end">
                                                    <TFBIconButton
                                                        name="view"
                                                        onClick={() => openEditVideo(row)}
                                                    />
                                                    <TFBIconButton
                                                        name="delete"
                                                        onClick={() => openDeleteVideo(row)}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                        </TFBTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <TFBPlaceholder text={languagePack.There_are_no_records_to_display} />
                    ) :
                    <CircularLoader />
                }
            </TFBCardBody>
            {state.open_add_video &&
                <AddVideo
                    openPopup={state.open_add_video}
                    closePopup={closeAddVideo}
                    refreshList={refetchMatchVideoList}
                    languagePack={languagePack}
                    id_club={id_club}
                    token={token}
                    idMatch={matchDetails.id_match}
                    addedVideos={_.map(matchVideoList?.list, e => ({ id_video: e.id_video }))}
                />
            }
            {state.open_delete_video &&
                <DeleteVideo
                    openPopup={state.open_delete_video}
                    closePopup={closeDeleteVideo}
                    refreshList={refetchMatchVideoList}
                    languagePack={languagePack}
                    data={state.deleting_video}
                    idMatch={matchDetails.id_match}
                />
            }
            {state.open_edit_video &&
                <ViewVideo
                    openPopup={state.open_edit_video}
                    closePopup={closeEditVideo}
                    languagePack={languagePack}
                    data={state.editing_video}
                />
            }
        </TFBCard>
    );
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    id_club: auth?.user?.id_club,
}))(MatchVideo);

const AddVideo = ({ languagePack, id_club, token, openPopup, closePopup, refreshList, idMatch, addedVideos }) => {

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            selected_videos: [null],
        }
    );

    const {
        data: videoList,
        loading: isVideoListLoading,
    } = useAxiosGet(`upload/get_video_list?id_club=${id_club}&show_storage_data=0&status=1&type=select&token=${token}`);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("matches/add_match_video");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.match,
                languagePack.success_map_match_video
            );
        } else {
            showNotification(
                "danger",
                languagePack.match,
                languagePack.fail_map_match_video
            );
        }
    };

    const saveVideos = () => {
        const payload = {
            id_match: idMatch,
            videos: _.map(_.filter(state.selected_videos, v => v?.value), v => ({ id_video: v.value })),
        };

        addRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => saveVideos(),
        "green",
        "save",
        languagePack.admin_save,
        {},
        true,
        _.some(state.selected_videos, e => e == null),
        loadingAddRequest,
    );

    const addVideo = () => {
        let videos = _.clone(state.selected_videos);
        videos.push(null);
        updateState({ selected_videos: videos });
    }

    const hanldeSelectVideo = (index, video) => {
        let videos = _.clone(state.selected_videos);
        videos[index] = video;
        updateState({ selected_videos: videos });
    }

    const hanldeRemoveVideo = (index) => {
        let videos = _.clone(state.selected_videos);
        videos.splice(index, 1);
        updateState({ selected_videos: videos });
    }

    const getVideoOptions = () => {
        return _.filter(videoList?.list ?? [], e => ((_.findIndex(state.selected_videos, v => v?.value == e.value) == -1) && (_.findIndex(addedVideos, v => v?.id_video == e.value) == -1)));
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.add_video_clip}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            {!isVideoListLoading ?
                <>
                    <div className="mb-5">
                        {_.map(state.selected_videos, (row, index) => (
                            <div className="df df-g20 df-align-end mb-5" key={"video-" + index} >
                                <TFBSelect
                                    label={languagePack.select_video}
                                    value={row ?? null}
                                    options={getVideoOptions()}
                                    onChange={(selected) => hanldeSelectVideo(index, selected)}
                                />
                                {_.size(state.selected_videos) > 1 &&
                                    <TFBIconButton
                                        name="x-large"
                                        color="lightGray"
                                        style={{ height: 50, width: 50, maxWidth: 50 }}
                                        onClick={() => hanldeRemoveVideo(index)}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                    <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="add" />}
                        disabled={_.some(state.selected_videos, e => e == null)}
                        onClick={addVideo}
                    >
                        {languagePack.add_video}
                    </TFBButton>
                </> :
                <CircularLoader />
            }
        </TFBDialog>
    )
}

const DeleteVideo = ({ openPopup, closePopup, refreshList, languagePack, data, idMatch }) => {

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("matches/delete_match_video");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.match,
                languagePack.success_unmap_match_video
            );
        } else {
            showNotification(
                "danger",
                languagePack.match,
                languagePack.fail_unmap_match_video
            );
        }
    };

    const unmapMatchVideo = () => {
        const payload = {
            id_match: idMatch,
            id_video: data?.id_video,
        };

        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => unmapMatchVideo(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_video"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.unmap_video_message}
            </div>
        </TFBDialog>
    );
}

const ViewVideo = ({ openPopup, closePopup, data }) => {
    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            maxWidth="xl"
            fullWidth
            title={data?.video_name}
            open={openPopup}
            closePopup={closePopup}
        >
            <VideoDetails idVideoToView={data?.id_video} videoMatchView={true} />
        </TFBDialog>
    )
}
