import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import delete_icon from "../../../assets/icons/delete.png";
import edit_icon from "../../../assets/icons/edit.png";
import { connect } from "react-redux";
import "./Orders.scss";
import { Icon, Button, TextArea } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ViewOrder from "./ViewOrder";

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders_rendered: [],
      viewOrderDialogOpened: false,
      cancelOrderDialogOpened: false,
      viewOrder: "",
      deleteOrder: "",
    };
  }

  nextData = () => {
    // let nextData = Array.from(
    //   this.props.products.slice(dataRender.length + 1, dataRender.length + 49)
    // );
    setTimeout(() => {
      let nextData = this.props.orders.slice(
        this.state.orders_rendered.length - 1,
        this.state.orders_rendered.length + 49
      );

      this.setState({
        orders_rendered: this.state.orders_rendered.concat(nextData),
      });
    }, 0);
  };


  //https://javascript.works-hub.com/learn/building-a-modular-infinite-scroll-252dd
  componentDidMount() {
    this.setState({ orders_rendered: this.props.orders.slice(0, 50) });
  }

  render() {
    const { languagePack } = this.props;
    return (
      <>
        <InfiniteScroll
          dataLength={this.state.orders_rendered.length}
          loader={
            <h4 style={{ textAlign: "center", paddingTop: 15 }}>Loading...</h4>
          }
          hasMore={true}
          next={this.nextData}
        >
          {this.state.orders_rendered.map((item, index) => {
            return (
              <div
                className="orders-row"
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}
              >
                <div className="mobile-listing">
                  <div className="mobile-list-item">
                    <div>{languagePack["order_id"]}</div>
                    <div>{item.id_delivery}</div>
                  </div>
                  <div className="mobile-list-item">
                    <div>{languagePack["order_date"]}</div>
                    <div>{item.date_added}</div>
                  </div>
                  <div className="mobile-list-item">
                    <div>{languagePack["order_customer"]}</div>
                    <div>{item.client_name}</div>
                  </div>
                  <div className="mobile-list-item">
                    <div>{languagePack["order_shipping_method"]}</div>
                    <div>{item.delivery_type}</div>
                  </div>
                  <div className="mobile-list-item">
                    <div>{languagePack["order_status"]}</div>
                    <div>{item.current_status}</div>
                  </div>
                  <div className="mobile-list-item">
                    <div>{languagePack["order_amount"]}</div>
                    <div>{item.total_order}</div>
                  </div>
                  <div className="mobile-list-item">
                    <div>{languagePack["order_actions"]}</div>
                    <div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() =>
                          this.setState({
                            viewOrderDialogOpened: true,
                            viewOrder: item,
                          })
                        }
                      >
                        <img src={edit_icon} alt="edit" />
                      </button>
                      <button type="button" className="btn btn-secondary">
                        <img
                          src={delete_icon}
                          alt="delete"
                          // style={{ marginLeft: 20 }}
                          onClick={() => {
                            // this.props.deleteGroupAge(item);
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="content-row">
                  <div className="id-column">{item.id_delivery}</div>
                  <div className="date-column">{item.date_added}</div>
                  <div className="client-column">{item.client_name}</div>
                  <div className="shipping-column">{item.delivery_type}</div>
                  <div className="status-column">{item.current_status}</div>
                  <div className="amount-column">{item.total_order}</div>
                  <div className="actions-column">
                    {/* <div
                    className="eye-icon-container"
                    onClick={() =>
                      this.props.history.push(
                        `/training/history/${item.id_training}`
                      )
                    }
                  >
                    <EyeIcon />
                  </div> */}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() =>
                        this.setState({
                          viewOrderDialogOpened: true,
                          viewOrder: item,
                        })
                      }
                    >
                      <img src={edit_icon} alt="edit" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => alert(languagePack.order_delete_alert)}
                    >
                      <img
                        src={delete_icon}
                        alt="delete"
                        // style={{ marginLeft: 20 }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>

        {/* VIZUALIZARE COMANDA */}

        <Dialog
          open={this.state.viewOrderDialogOpened}
          onClose={() => this.setState({ viewOrderDialogOpened: false })}
          maxWidth="md"
          fullWidth="true"
        >
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack.order_details}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ viewOrderDialogOpened: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <DialogContent style={{ minHeight: "100px" }}>
            {/* <AddVideo
           refreshFiles={this.refreshFiles}
           closePopup={() => this.setState({ productDialogOpened: false })}
         /> */}
            <ViewOrder
              closePopup={() => this.setState({ viewOrderDialogOpened: false })}
              onCancelDialog={() =>
                this.setState({ cancelOrderDialogOpened: true })
              }
              order={this.state.viewOrder}
            />
          </DialogContent>
        </Dialog>

        {/* ANULARE COMANDA */}

        <Dialog
          open={this.state.cancelOrderDialogOpened}
          onClose={() => this.setState({ cancelOrderDialogOpened: false })}
        >
          <div className="bonus-options-list-popup-header">
            <div className="bonus-options-list-title">
              {languagePack.order_cancel}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() =>
                  this.setState({ cancelOrderDialogOpened: false })
                }
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>

          <DialogContent style={{ minHeight: "100px" }}>
            <div style={{ paddingBottom: 20 }}>
              {languagePack.order_cancel_message}
            </div>
            <TextArea
              className="text-area-file-form"
              value={languagePack.order_cancel_reason}
              // onChange={(e) => this.setState({ doc_extra_notes: e.target.value })}
              // name="doc_extra_notes"
            />
            <Button
              color="red"
              onClick={() => {
                this.setState({
                  viewOrderDialogOpened: false,
                  cancelOrderDialogOpened: false,
                });
              }}
            >
              {languagePack.order_cancel}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  cancelOrderDialogOpened: false,
                });
              }}
            >
              {languagePack.order_cancel_dismiss}
            </Button>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}
export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(OrderList);
