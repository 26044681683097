import React from "react";
import { connect } from "react-redux";

import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";

function ChangeMatchDurationPopup({ languagePack, closePopup, editMatch }) {
  const { renderBtn: renderBtnRight } = useRenderButton(
    () => editMatch(),
    "red",
    "edit",
    languagePack.edit_anyway
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <TFBDialog
      title={languagePack.edit_minutes_per_half}
      open={true}
      closePopup={closePopup}
      btnRight={renderBtnRight}
      btnLeft={renderBtnLeft}
    >
      <div className="delete-user-popup-container">
        <div className="delete-user-text">
          {languagePack.edit_minutes_per_half_confirmation_line1}
          <br />
          {languagePack.edit_minutes_per_half_confirmation_line2}
        </div>
      </div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ChangeMatchDurationPopup);
