import React, { Component } from "react";
import "./PrivacyPolicy.scss";
import { store } from "react-notifications-component";
import axios from "axios";
import { connect } from "react-redux";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import CircularLoader from "../../../components/reusable/Loader";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            privacy_policy: "",
            is_loading: true,
        };
    }

    componentDidMount = () => {
        this.getPrivacyPolicyContent();
    };

    getPrivacyPolicyContent = () => {
        axios
            .get(`site/privacy_policy_content?id_club=${this.props.currentUser.id_club}`)
            .then((res) => {
                this.setState({
                    privacy_policy: res.data.privacy_policy ?? "",
                    is_loading: false,
                });
            })
            .catch((err) => console.log(err));
    };

    savePrivacyPolicy = () => {
        const payload = {
            id_club: this.props.currentUser.id_club,
            privacy_policy: this.state.privacy_policy,
        }

        axios
            .post("site/save_privacy_policy", payload)
            .then(res => {
                store.addNotification({
                    title: this.props.languagePack.privacy_policy,
                    message: this.props.languagePack.success_save_privacy_policy,
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                    },
                });
            })
    }

    render() {
        const { languagePack } = this.props;

        return (
            <div className="row">
                <div className="col-lg-6 col-lg-padding">
                    <TFBCard style={{ height: "100%" }}>
                        <TFBCardHeader title={languagePack.privacy_policy}>
                            <TFBButton
                                color="green"
                                renderIcon={() => <TFBIcon name="save" />}
                                onClick={this.savePrivacyPolicy}
                            >
                                {languagePack.admin_save}
                            </TFBButton>
                        </TFBCardHeader>
                        <TFBCardBody>
                            {this.state.is_loading ?
                                <CircularLoader /> :
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|', 'undo', 'redo'],
                                    }}
                                    data={this.state.privacy_policy}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        this.setState({
                                            privacy_policy: data
                                        })
                                    }}
                                />
                            }
                        </TFBCardBody>
                    </TFBCard>
                </div>
                <div className="col-lg-6 col-lg-padding">
                    <TFBCard style={{ height: "100%" }}>
                        <TFBCardHeader title={languagePack.admin_preview} />
                        <TFBCardBody>
                            {this.state.is_loading ?
                                <CircularLoader /> :
                                (this.state.privacy_policy.length == 0 ?
                                    <TFBPlaceholder text={languagePack.no_privacy_policy} /> :
                                    <div className="render-privacy-policy" dangerouslySetInnerHTML={{ __html: this.state.privacy_policy }} />
                                )
                            }
                        </TFBCardBody>
                    </TFBCard>
                </div>
            </div>
        );
    }
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(PrivacyPolicy);
