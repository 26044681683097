import React from "react";
import Chart from "react-apexcharts";
import NumberFormat from "react-number-format";
import axios from "axios";
import "./ReportCharts.scss";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class ReportBubbleChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bubbleChartFetch: false,
      series: [],
      options: {
        chart: {
          height: 350,
          toolbar: {
            show: false,
          },
          type: "scatter",
          zoom: {
            enabled: false,
            type: "xy",
          },
          redrawOnParentResize: true,
        },
        grid: {
          show: false,
        },
        xaxis: {
          min: 1,
          max: 5,
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          labels: {
            show: false,
          },
        },
        yaxis: {
          min: 0,
          max: 15,
          tickAmount: 7,
          lines: {
            show: true,
          },
          show: false,
        },
        annotations: {
          position: "back",
          xaxis: [
            {
              x: 3.2,
              strokeDashArray: 5,
              borderWidth: 2,
              borderColor: "#00E396",
            },
          ],
          yaxis: [
            {
              y: 7,
              strokeDashArray: 0,
              borderWidth: 0.4,
              borderColor: "#65686e",
            },
          ],
        },
        tooltip: {
          custom: this.bubbleChartPopup,
        },
      },
    };
  }

  componentDidMount() {
    axios
      .get("report/report_bubble_chart", {
        params: {
          id_test: this.props.id_test,
          // test_type: this.props.test_type,
        },
      })
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              // min: res.data.min_value,
              // max: res.data.max_value,
            },
            annotations: {
              ...prevState.annotations,
              xaxis: [
                {
                  ...prevState.xaxis,
                  x: res.data.test_average,
                },
              ],
            },
          },
          series: res.data.test_data,
        }));
      })
      .then(
        this.setState((prevState) => ({
          ...prevState,
          bubbleChartFetch: true,
        }))
      )
      .catch((err) => console.log(err));
  }

  bubbleChartPopup = ({ series, dataPointIndex }) => {
    const data = this.state.series[0].data[dataPointIndex];
    return (
      "<div className='popup-bubble-chart'>" +
      "<div className='popup-player-name'>" +
      data.player_name +
      "</div>" +
      ":" +
      "<div className='popup-player-grade'>" +
      data.x +
      "</div>" +
      "</div>"
    );
  };

  // formatNumber = (value) => {
  //   return (
  //     <NumberFormat
  //       value={value}
  //       displayType={"text"}
  //       thousandSeparator={true}
  //     />
  //   );
  // };

  render() {
    return (
      <div id="chart" style={{ padding: 10 }}>
        {this.state.bubbleChartFetch && (
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="scatter"
            height={150}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(ReportBubbleChart);
