import React, { useState } from "react";
import "./Auth.scss";
import { Icon, Button } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";

function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConf, setNewPassConf] = useState("");
  const [error, setError] = useState("");
  const { languagePack } = props;

  function submitPassword() {
    const payload = {
      id_user: props.currentUser.id_user,
      oldPassword: oldPassword,
      newPassword: newPass,
      newPasswordConfirmation: newPassConf,
    };
    axios
      .post("user/set_new_password", payload)
      .then((res) => {
        if (res.data.success == 1) {
          props.closePopup();
          store.addNotification({
            title: languagePack["account_settings"],
            message: languagePack["password_success_text"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          setError(res.data.error);
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="change-password-container">
      <div className="change-password-header">
        <div className="change-password-title">
          {languagePack["password_reset"]}
        </div>
        <Icon name="close" onClick={() => props.closePopup()} />
      </div>
      <div className="change-password-row">
        <input
          type="password"
          autoComplete="new-password"
          placeholder="Parola veche"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />
      </div>
      <div className="change-password-row">
        <input
          type="password"
          autoComplete="new-password"
          placeholder="Parola noua"
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
        />
      </div>
      <div className="change-password-row">
        <input
          type="password"
          placeholder="Confirma parola noua"
          value={newPassConf}
          onChange={(e) => setNewPassConf(e.target.value)}
        />
      </div>
      {error !== "" && <div className="error-row">{error}</div>}

      <div className="change-password-buttons-container">
        <Button
          className="custom-button-dual cancel-button"
          onClick={() => props.closePopup()}
        >
          {" "}
          <div className="button-content">
            {languagePack["admin_cancel"]}
            <Icon name="x" className="edit-icon" />
          </div>
        </Button>
        <Button
          className="custom-button-dual save-button"
          onClick={() => submitPassword()}
        >
          {" "}
          <div className="button-content">
            {languagePack["admin_save"]}
            <Icon name="key" className="edit-icon" />
          </div>
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ChangePassword);
