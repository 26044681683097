import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

import styles from "./BonusesMonitoring.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBTabControl from "../../../components/design-system/TabControl/TFBTabControl";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import CircularLoader from "../../../components/reusable/Loader";
import { formatAmountCurrency } from "../../../utils/utilFunctions";
import ImportUpdateBonus from "./ImportUpdateBonus";

const BonusesMonitoring = (props) => {
  const { currentUser, languagePack, currencyRates } = props;

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      team: null,
      season: null,
      active_tab: 0,
      open_import: false,
      import_data: {},
    }
  );

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `finances/get_club_teams?id_club=${currentUser.id_club}`
  );

  useEffect(() => {
    if (teams) {
      if (Number(teams?.success) === 1) {
        if (Array.isArray(teams?.list)) {
          updateState({ team: teams.list[0]?.value });
        }
      }
    }
  }, [teams]);

  const {
    data: seasons,
    loading: isSeasonsLoading,
    refetch: refetchSeasons,
  } = useAxiosGet(`finances/get_team_seasons?id_team=${state.team}`, true);

  useEffect(() => {
    if (seasons) {
      if (Number(seasons?.success) === 1) {
        if (Array.isArray(seasons?.list)) {
          updateState({ season: seasons.list[0].value });
        }
      }
    }
  }, [seasons]);

  const {
    data: bonuses,
    loading: isBonusesLoading,
    refetch: refetchBonuses,
  } = useAxiosGet(
    `finances/get_bonus_monitoring_table?id_club=${currentUser.id_club}&id_season=${state.season}`,
    true
  );

  const {
    data: matches,
    loading: isMatchesLoading,
    refetch: refetchMatches,
  } = useAxiosGet(
    `finances/get_football_api_matches_by_team?id_team=${state.team}&id_season=${state.season}`,
    true
  );

  useEffect(() => {
    if (state.team) {
      refetchSeasons();
      if (state.season) {
        refetchMatches();
      }
    }
  }, [state.team]);

  useEffect(() => {
    if (state.season) {
      refetchBonuses();
      refetchMatches();
    }
  }, [state.season]);

  const handleChangeDropdown = (e, value) => {
    updateState({ [value.name]: value.value });
  };

  const { renderBtn: BtnDownload } = useRenderButton(
    () => {},
    "green",
    null,
    languagePack.download_in_format + " .pdf"
  );

  const updateAfterImport = (match) => {
    refetchBonuses();
    refetchMatches();
  };

  const openImport = (match) => {
    updateState({
      open_import: true,
      import_data: {
        id_match: match.id_rapid_api,
        id_team: state.team,
        id_season: state.season,
        id_club: currentUser.id_club,
      },
    });
  };

  const closeImport = () => {
    updateState({
      open_import: false,
      import_data: {},
    });
  };

  const tab1 = (
    <>
      {_.size(bonuses?.player?.list) > 0 ? (
        <div className={styles.personList}>
          {_.map(bonuses?.player?.list, (row, i) => (
            <TFBCard styleCardInner={{ padding: 10 }} key={"player-bonus-" + i}>
              <TFBPlayerWithAvatar
                playerName={row.person_name}
                avatarUrl={row.person_img_url}
              />
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TFBTableCell>{languagePack.bonus}</TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.bonus_progress}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.match_and_date_of_the_event}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.bonus_value}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payments_made}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.total_paid}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.remaining_payment}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(row?.bonuses, (row2, index2) => (
                      <TableRow
                        key={"staff-contract-" + index2}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor:
                            index2 % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell>
                          {row2.bonus_name}
                          <div className={styles.bonusReceiver}>
                            {row2.receiver_entity
                              ? languagePack?.receiver_entity +
                                ": " +
                                languagePack?.[row2.receiver_entity] +
                                (row2.receiver_entity_name
                                  ? ` (${row2.receiver_entity_name})`
                                  : "")
                              : ""}
                          </div>
                        </TFBTableCell>
                        <TFBTableCell
                          align="center"
                          style={{
                            color:
                              row2.fulfilled == 1 ? "var(--primaryColor)" : "",
                          }}
                        >
                          {row2.bonus_objective > 0
                            ? row2.bonus_progress + "/" + row2.bonus_objective
                            : row2.bonus_progress}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {_.size(row2?.matches) > 0 ? (
                            <div className={styles.matchesList}>
                              {_.map(row2?.matches, (row3, index3) => (
                                <div
                                  className={styles.matchData}
                                  key={"bonus-match-" + index3}
                                >
                                  {row3.quantity_bonus_progress + "x"}
                                  <span className={styles.bonusGreyText}>
                                    {" vs "}
                                  </span>
                                  {row3.opponent_name}
                                  <img
                                    className={styles.matchOpponentImg}
                                    src={row3.opponent_logo_url}
                                    alt="opponent"
                                  />
                                  {`(${row3.own_scored_goals} - ${row3.opponent_scored_goals})`}
                                  <span
                                    className={styles.bonusGreyText}
                                  >{`, ${moment(row3.match_date).format(
                                    "DD.MM.YYYY"
                                  )}`}</span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            "-"
                          )}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {formatAmountCurrency(
                            parseFloat(row2.bonus_value),
                            row2.bonus_currency,
                            row2.bonus_currency,
                            currencyRates
                          )}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {_.size(row2?.payments) > 0 ? "+" : "-"}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {formatAmountCurrency(
                            parseFloat(row2.total_made_payments),
                            row2.bonus_currency,
                            row2.bonus_currency,
                            currencyRates
                          )}
                        </TFBTableCell>
                        <TFBTableCell
                          align="center"
                          style={{
                            color: row2.remaining_to_pay > 0 ? "red" : "",
                          }}
                        >
                          {formatAmountCurrency(
                            parseFloat(row2.remaining_to_pay),
                            row2.bonus_currency,
                            row2.bonus_currency,
                            currencyRates
                          )}
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TFBCard>
          ))}
        </div>
      ) : (
        <TFBPlaceholder text={languagePack.no_bonuses} />
      )}
    </>
  );

  const tab2 = (
    <>
      {_.size(bonuses?.staff_member?.list) > 0 ? (
        <div className={styles.personList}>
          {_.map(bonuses?.staff_member?.list, (row, i) => (
            <TFBCard styleCardInner={{ padding: 10 }} key={"staff-bonus-" + i}>
              <TFBPlayerWithAvatar
                playerName={row.person_name}
                avatarUrl={row.person_img_url}
              />
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TFBTableCell>{languagePack.bonus}</TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.bonus_progress}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.match_and_date_of_the_event}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.bonus_value}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.payments_made}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.total_paid}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {languagePack.remaining_payment}
                      </TFBTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {_.map(row?.bonuses, (row2, index2) => (
                      <TableRow
                        key={"staff-contract-" + index2}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor:
                            index2 % 2 === 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell>{row2.bonus_name}</TFBTableCell>
                        <TFBTableCell
                          align="center"
                          style={{
                            color:
                              Number(row2.fulfilled) === 1
                                ? "var(--primaryColor)"
                                : "",
                          }}
                        >
                          {row2.bonus_objective > 0
                            ? row2.bonus_progress + "/" + row2.bonus_objective
                            : row2.bonus_progress}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {_.size(row2?.matches) > 0 ? (
                            <div className={styles.matchesList}>
                              {_.map(row2?.matches, (row3, index3) => (
                                <div
                                  className={styles.matchData}
                                  key={"staff-match-" + index3}
                                >
                                  {row3.quantity_bonus_progress + "x"}
                                  <span className={styles.bonusGreyText}>
                                    {" vs "}
                                  </span>
                                  {row3.opponent_name}
                                  <img
                                    className={styles.matchOpponentImg}
                                    src={row3.opponent_logo_url}
                                    alt="opponent"
                                  />
                                  {`(${row3.own_scored_goals} - ${row3.opponent_scored_goals})`}
                                  <span
                                    className={styles.bonusGreyText}
                                  >{`, ${moment(row3.match_date).format(
                                    "DD.MM.YYYY"
                                  )}`}</span>
                                </div>
                              ))}
                            </div>
                          ) : (
                            "-"
                          )}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {formatAmountCurrency(
                            parseFloat(row2.bonus_value),
                            row2.bonus_currency,
                            row2.bonus_currency,
                            currencyRates
                          )}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {_.size(row2?.payments) > 0 ? "+" : "-"}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {formatAmountCurrency(
                            parseFloat(row2.total_made_payments),
                            row2.bonus_currency,
                            row2.bonus_currency,
                            currencyRates
                          )}
                        </TFBTableCell>
                        <TFBTableCell
                          align="center"
                          style={{
                            color: row2.remaining_to_pay > 0 ? "red" : "",
                          }}
                        >
                          {formatAmountCurrency(
                            parseFloat(row2.remaining_to_pay),
                            row2.bonus_currency,
                            row2.bonus_currency,
                            currencyRates
                          )}
                        </TFBTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TFBCard>
          ))}
        </div>
      ) : (
        <TFBPlaceholder text={languagePack.no_bonuses} />
      )}
    </>
  );

  const tab3 = (
    <>
      {_.size(matches?.list) > 0 ? (
        <TFBCard styleCardInner={{ padding: 10 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TFBTableCell>{languagePack.match}</TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.score}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.competition}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.round}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.date}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(matches?.list, (row, i) => (
                  <TableRow
                    key={"rapid-api-match" + i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      backgroundColor: i % 2 == 0 ? "#fafafa" : "white",
                    }}
                  >
                    <TFBTableCell>
                      {row?.home_team?.name + " - " + row?.away_team?.name}
                      {_.size(row?.import_obj?.import_warning) > 0 && (
                        <div className={styles.unmappedPlayer}>
                          {languagePack?.unmapped_player +
                            ": " +
                            row?.import_obj?.import_warning}
                        </div>
                      )}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row?.score?.home + " - " + row?.score?.away}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row?.competition}
                    </TFBTableCell>
                    <TFBTableCell align="center">{row?.round}</TFBTableCell>
                    <TFBTableCell align="center">
                      {moment(row?.match_date).format("DD.MM.YYYY")}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {row?.imported ? (
                        <TFBIconButton
                          name="check"
                          color="green"
                          disabled={true}
                          altTitle={languagePack.imported}
                        />
                      ) : (
                        <TFBIconButton
                          name="arrow-right"
                          altTitle={languagePack.import}
                          onClick={() => openImport(row)}
                        />
                      )}
                    </TFBTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <ImportUpdateBonus
            openPopup={state.open_import}
            closePopup={closeImport}
            refreshList={updateAfterImport}
            importData={state.import_data}
          />
        </TFBCard>
      ) : (
        <TFBPlaceholder text={languagePack.no_matches} />
      )}
    </>
  );

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack["bonuses_monitoring"]}>
        {!isTeamsLoading &&
          !isSeasonsLoading &&
          !isBonusesLoading &&
          !isMatchesLoading && (
            <>
              {/* <BtnDownload /> */}
              <TFBDropdown
                placeholder={languagePack["select_team"]}
                selection
                options={teams?.list ?? []}
                value={state.team}
                onChange={handleChangeDropdown}
                name="team"
              />
              <TFBDropdown
                placeholder={languagePack["select_season"]}
                selection
                options={seasons?.list ?? []}
                value={state.season}
                onChange={handleChangeDropdown}
                name="season"
              />
            </>
          )}
      </TFBCardHeader>
      <TFBCardBody>
        {!isTeamsLoading && !isSeasonsLoading && !isBonusesLoading ? (
          <TFBTabControl
            tabs={[
              languagePack.player,
              languagePack.staff_member,
              languagePack.import_bonus_update,
            ]}
            tabsContent={[tab1, tab2, tab3]}
            activeTab={state.active_tab}
            onChangeActiveTab={(i) => updateState({ active_tab: i })}
          />
        ) : (
          <CircularLoader />
        )}
      </TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(BonusesMonitoring);
