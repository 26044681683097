import React, { useContext } from "react";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import moment from "moment";
import _ from "lodash";
import { connect } from "react-redux";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AddCompetitionContext } from "./AddCompetition";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../../components/reusable/useStyles";
import CircularLoader from "../../../../components/reusable/Loader";
import ExternTeamLogos from "../AddMatch/ExternTeamLogos";

function ImportCompetitionConfirmation({ languagePack, currentUser, token }) {
  const context = useContext(AddCompetitionContext);

  const { renderBtn: renderBtnRight } = useRenderButton(
    Number(context.selectedSource.value) === 1 &&
      !context.importConfirmationCheckLogos
      ? context.onGoToCheckLogos
      : context.onImportCompetition,
    "green",
    Number(context.selectedSource.value) === 1 &&
      !context.importConfirmationCheckLogos
      ? "angle-right"
      : "check",
    Number(context.selectedSource.value) === 1 &&
      !context.importConfirmationCheckLogos
      ? languagePack.next_step
      : languagePack.import_matches_and_competition,
    {},
    true,
    context.loadingClubMatches || context.loadingCountyTeamMatches,
    context.loadingImportAlphaCompetition ||
      context.loadingImportBetaCompetition
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    context.onCloseImportConfirmation,
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <TFBDialog
      title={`${languagePack.matches} ${context.selectedClub?.label} ${
        languagePack.in
      } ${context.selectedCompetition?.label} ${
        context.selectedSeries === "" ? "" : "," + context.selectedSeries?.label
      } `}
      open
      fullWidth
      closePopup={context.onCloseImportConfirmation}
      maxWidth="md"
      btnLeft={renderBtnLeft}
      btnRight={renderBtnRight}
    >
      {!context.loadingClubMatches &&
      !context.loadingCountyTeamMatches &&
      !context.importConfirmationCheckLogos ? (
        <TableContainer>
          <Table faria-label="simple table">
            <TableHead>
              <TableRow>
                <TFBTableCell sx={sxStickyColumn} align="left">
                  {languagePack.game_day}
                </TFBTableCell>
                <TFBTableCell align="center"></TFBTableCell>
                <TFBTableCell align="center"></TFBTableCell>
                <TFBTableCell align="center">{languagePack.match}</TFBTableCell>
                <TFBTableCell align="center"></TFBTableCell>
                <TFBTableCell align="center"></TFBTableCell>
                <TFBTableCell align="center">
                  {languagePack.date_time}
                </TFBTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {context[
                Number(context.selectedSource?.value) === 2
                  ? "countyTeamMatchesData"
                  : "clubMatchesData"
              ]?.list.map((row, index) => (
                <TableRow
                  key={"match-" + index}
                  sx={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  }}
                >
                  <TFBTableCell
                    sx={sxStickyColumn}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                    align="left"
                  >
                    {languagePack.game_day + " " + row.stage}
                  </TFBTableCell>

                  <TFBTableCell align="left">
                    <div
                      style={
                        Number(row.id_team_home) ===
                        Number(context.selectedClub?.value)
                          ? { color: "var(--primaryColor)" }
                          : {}
                      }
                    >
                      {Number(row.id_team_home) ===
                      Number(context.selectedClub?.value)
                        ? context.selectedClubTeam?.label
                        : row.team_home}
                    </div>
                  </TFBTableCell>
                  <TFBTableCell align="center"></TFBTableCell>
                  <TFBTableCell align="center">
                    <div>
                      {row.team_home_goals} - {row.team_away_goals}
                    </div>
                  </TFBTableCell>
                  <TFBTableCell
                    style={{ paddingLeft: "0px", paddingRight: "0px" }}
                    align="center"
                  ></TFBTableCell>
                  <TFBTableCell align="right">
                    <div
                      style={
                        Number(row.id_team_away) ===
                        Number(context.selectedClub?.value)
                          ? { color: "var(--primaryColor)" }
                          : {}
                      }
                    >
                      {Number(row.id_team_away) ===
                      Number(context.selectedClub?.value)
                        ? context.selectedClubTeam?.label
                        : row.team_away}
                    </div>
                  </TFBTableCell>
                  <TFBTableCell align="right">
                    {moment(row.match_date).format("DD.MM.YYYY HH:mm")}
                  </TFBTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : context.importConfirmationCheckLogos &&
        Number(context.selectedSource?.value) === 1 ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "#B2B2B2",
              fontFamily: "Poppins",
              fontSize: "1.1em",
              fontWeight: "700",
              marginBottom: "25px",
              textAlign: "justify",
            }}
          >
            {languagePack.confirm_logos}
          </div>
          {_.map(context.competitionClubs, (club, i) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                color: "#666666",
                fontFamily: "Poppins",
                fontSize: "1.5em",
                fontWeight: "700",
              }}
              key={i}
            >
              {club.label} :{" "}
              <ExternTeamLogos
                thumbnails={_.map(club.logos, (logo) => logo.thumbnail_url)}
                selectedLogo={club.selectedLogo}
                setSelectedLogo={context.onChangeSelectedLogo}
                clubIndex={i}
                loading={false}
                uploadedLogo={club.uploadedLogo}
                onRemoveUploadedLogo={context.onRemoveUploadedLogo}
                onChangeFile={context.onChangeUploadedLogo}
              />
            </div>
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: "#B2B2B2",
            fontFamily: "Poppins",
            fontSize: "1em",
            fontWeight: "700",
          }}
        >
          <div>
            Preluăm toate meciurile echipei tale din{" "}
            {context.selectedCompetition?.label}. Acest proces poate dura câteva
            minute...
          </div>
          <CircularLoader />
        </div>
      )}
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ImportCompetitionConfirmation);
