import React from "react";
import axios from "axios";
import "./MatchPlayerStats.scss";
import { Button, Icon } from "semantic-ui-react";
import { Dialog, DialogContent, Avatar } from "@material-ui/core";

import ParametersList from "./ParametersList";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

const sampleData = {
  homeTeam: [
    {
      player_name: "Ion Ionescu",
      id_player: 70,
      player_parameters: [
        { id_parameter: 0, parameter_value: 200 },
        { id_parameter: -1, parameter_value: 800 },
        { id_parameter: 3, parameter_value: 150 },
        { id_parameter: 258, parameter_value: 82 },
        { id_parameter: 265, parameter_value: 43 },
      ],
    },
    {
      player_name: "Radu Radescu",
      id_player: 73,
      player_parameters: [
        { id_parameter: 0, parameter_value: 235 },
        { id_parameter: -1, parameter_value: 1200 },
        { id_parameter: 3, parameter_value: 450 },
        { id_parameter: 258, parameter_value: 72 },
        { id_parameter: 265, parameter_value: 73 },
      ],
    },
    {
      player_name: "David Davidescu",
      id_player: 71,
      player_parameters: [
        { id_parameter: 0, parameter_value: 194 },
        { id_parameter: -1, parameter_value: 678 },
        { id_parameter: 3, parameter_value: 200 },
        { id_parameter: 258, parameter_value: 59 },
        { id_parameter: 265, parameter_value: 28 },
      ],
    },
  ],
  awayTeam: [
    {
      player_name: "Luca Lucescu",
      id_player: 76,
      player_parameters: [
        { id_parameter: 0, parameter_value: 234 },
        { id_parameter: -1, parameter_value: 760 },
        { id_parameter: 3, parameter_value: 165 },
        { id_parameter: 258, parameter_value: 68 },
        { id_parameter: 265, parameter_value: 67 },
      ],
    },
    {
      player_name: "Andrei Andreescu",
      id_player: 74,
      player_parameters: [
        { id_parameter: 0, parameter_value: 300 },
        { id_parameter: -1, parameter_value: 1350 },
        { id_parameter: 3, parameter_value: 500 },
        { id_parameter: 258, parameter_value: 88 },
        { id_parameter: 265, parameter_value: 66 },
      ],
    },
    {
      player_name: "Laur Laurescu",
      id_player: 75,
      player_parameters: [
        { id_parameter: 0, parameter_value: 202 },
        { id_parameter: -1, parameter_value: 50 },
        { id_parameter: 3, parameter_value: 20 },
        { id_parameter: 258, parameter_value: 80 },
        { id_parameter: 265, parameter_value: 100 },
      ],
    },
  ],
};

class MatchPlayerStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters_home: [{ player_name: "Ion Ionescu" }],
      parameters_away: [],
      parametersPopup: false,
      team_home: "",
      team_away: "",
      selectedTeam: "",
      parameter_names: [],
      isFetching: false,
      sortConfig: {
        type: "",
        descending: false,
      },
    };
  }

  componentDidMount = () => {
    this.getPlayerStats();
  };

  getPlayerStats = () => {
    this.setState({ isFetching: true });
    axios
      .get(
        `instat/match_player_stats?id_match=${this.props.id_match}&id_user=${this.props.currentUser.id_user}&lang=${this.props.lang}`
      )
      .then((res) =>
        this.setState({
          stats: res.data.parameters,
          team_home: res.data.team_home_name,
          selectedTeam: res.data.team_home_name,
          team_away: res.data.team_away_name,
          parameters_home: sampleData.homeTeam,
          parameters_away: sampleData.awayTeam,
          parameter_names: res.data.parameter_names,
          isFetching: false,
        })
      )
      .catch((err) => console.log(err));
  };

  changeFilter = (type) => {
    if (this.state.sortConfig.type !== type) {
      this.setState({ sortConfig: { type: type, descending: false } });
    } else {
      this.setState({
        sortConfig: {
          type: type,
          descending: !this.state.sortConfig.descending,
        },
      });
    }
  };

  getPlParamValue = (paramsList, id_param) => {
    let paramData = paramsList?.find((e) => e.id_parameter == id_param);
    if (paramData) return paramData.parameter_value;
    else return "-";
  };

  sortData = () => {
    const items =
      this.state.selectedTeam === this.state.team_home
        ? this.state.parameters_home
        : this.state.parameters_away;
    const sortOption = this.state.sortConfig;

    if (sortOption.type !== "") {
      items.sort(function(a, b) {
        if (sortOption.descending) {
          let paramDataB = b.player_parameters.find(
            (e) => e.id_parameter == sortOption.type
          );
          let paramDataA = a.player_parameters.find(
            (e) => e.id_parameter == sortOption.type
          );
          return paramDataB.parameter_value - paramDataA.parameter_value;
        } else {
          let paramDataB = b.player_parameters.find(
            (e) => e.id_parameter == sortOption.type
          );
          let paramDataA = a.player_parameters.find(
            (e) => e.id_parameter == sortOption.type
          );
          return paramDataA.parameter_value - paramDataB.parameter_value;
        }
      });
    } else return items;
  };

  render() {
    this.sortData();
    const { languagePack } = this.props;
    const list =
      this.state.selectedTeam === this.state.team_home ||
      this.state.selectedTeam === ""
        ? this.state.parameters_home
        : this.state.parameters_away;
    return (
      <div className="card card-custom">
        <div className="match-player-stats-container">
          <div className="match-player-stats-header">
            <div className="player-stats-title">
              {languagePack["player_tech_parameters"]}
            </div>
            <Button
              className="player-stats-header-button"
              onClick={() => this.setState({ parametersPopup: true })}
            >
              {languagePack["parameters_list"]}
            </Button>
          </div>
          {this.state.isFetching ? (
            <div className="loader-container">
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={50}
                width={50}
              />
            </div>
          ) : (
            <div>
              <div className="player-stats-team-menu">
                <div
                  className="team-menu-option"
                  style={{
                    backgroundColor:
                      this.state.selectedTeam === "" ||
                      this.state.selectedTeam === this.state.team_home
                        ? "#fafafa"
                        : "white",
                  }}
                  onClick={() =>
                    this.setState({ selectedTeam: this.state.team_home })
                  }
                >
                  {this.state.team_home}
                </div>
                <div
                  className="team-menu-option"
                  style={{
                    backgroundColor:
                      this.state.selectedTeam === this.state.team_away
                        ? "#fafafa"
                        : "white",
                  }}
                  onClick={() =>
                    this.setState({ selectedTeam: this.state.team_away })
                  }
                >
                  {this.state.team_away}
                </div>
              </div>

              <div className="match-player-stats-list">
                <div className="player-stat-row-header">
                  <div className="player-name">
                    {languagePack["player_name"]}
                  </div>
                  {this.state.parameter_names.map((p, i) => {
                    return (
                      <div
                        className="player-param"
                        key={i}
                        style={{
                          width: `calc(70% / ${this.state.parameter_names.length})`,
                          cursor: "pointer",
                        }}
                        onClick={() => this.changeFilter(p.id_parameter)}
                      >
                        {p.parameter_name}
                        {this.state.sortConfig.type === p.id_parameter &&
                          (this.state.sortConfig.descending ? (
                            <Icon name="arrow down" size="small" />
                          ) : (
                            <Icon name="arrow up" size="small" />
                          ))}
                      </div>
                    );
                  })}
                </div>
                {list.map((item, index) => {
                  return (
                    <div
                      className="player-stat-row"
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
                      }}
                      key={index}
                    >
                      <div className="player-name">
                        <Avatar
                          className="player-avatar"
                          src={`https://instatscout.com/images/players/180/${item.id_player}.png`}
                        />
                        <div>{item.player_name}</div>
                      </div>
                      {/* {item.player_parameters.map((it) => {
                        return (
                          <div
                            className="player-param"
                            style={{
                              width: `calc(70% / ${item.player_parameters.length})`,
                            }}
                          >
                            {it !== "-" ? it.parameter_value : it}
                          </div>
                        );
                      })} */}
                      {this.state.parameter_names.map((it) => (
                        <div
                          className="player-param"
                          style={{
                            width: `calc(70% / ${this.state.parameter_names.length})`,
                          }}
                        >
                          {this.getPlParamValue(
                            item.player_parameters,
                            it.id_parameter
                          )}
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <Dialog
          open={this.state.parametersPopup}
          // onClose={() => this.setState({ parametersPopup: false })}
        >
          <DialogContent>
            <ParametersList
              id_match={this.props.id_match}
              closePopup={() => this.setState({ parametersPopup: false })}
              id_user={this.props.currentUser.id_user}
              refreshParameters={() => this.getPlayerStats()}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(MatchPlayerStats);
