import React from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CircularLoader from "../../../components/reusable/Loader";
import { Icon } from "semantic-ui-react";
import NumberFormat from "react-number-format";
import * as tableConfig from "./tableConfig";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as constants from "./../../../../app/utils/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { store } from "react-notifications-component";
import Decimal from "decimal.js";

const _months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

class ExpandedCashflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      editingRow: "",
      isFetching: true,
      deleteCashflowOpen: false,
      deleteCashfllowEntry: "",
      deleteCashflowEntryName: "",
    };
  }

  componentDidMount() {
    this.getExpandedCashflow(true);
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.selectedYear !== this.props.selectedYear) {
      this.getExpandedCashflow();
    }

    if (prevProps.forceReload !== this.props.forceReload) {
      this.getExpandedCashflow(true);
    }
  };
  getExpandedCashflow = (useLoading) => {
    if (useLoading) {
      this.setState(
        {
          isFetching: true,
        },
        () => {
          this.getExpandedCashflow();
        }
      );
      return;
    }
    this.setState(
      {
        editingRow: "",
      },
      () => {
        axios
          .get(`finances/elements`, {
            params: {
              category: this.props.data.id,
              year: this.props.selectedYear,
              token: this.props.token,
            },
          })
          .then((res) => {
            if (res?.data?.go_to_login === 1) {
              this.props.logout(this.props.token);
            } else {
              this.setState({ data: res.data, isFetching: false });
            }
          })
          .catch((err) => console.log(err));
      }
    );
  };

  handleRowClicked = (row) => {
    this.setState({
      editingRow: `${row.id}`,
    });
  };

  saveEditedRow = (row) => {
    this.setState({
      editingRow: undefined,
    });
    //datable has a problem with input blur on change so we should use this instead of regular reactjs onchange event handling
    const inputNodes = document.querySelectorAll(
      `input[input-column-expanded='${row.id}']`
    );
    const elements = [];
    for (let i = 0; i < inputNodes.length; i++) {
      let estimated_value = inputNodes[i].value;
      const month = inputNodes[i].getAttribute("month");
      const entry_month = _months.indexOf(month) + 1;
      if (estimated_value && entry_month > 0) {
        if (
          this.props.selectedCurrency !== constants.currencies.EUR.uniqueSlug
        ) {
          estimated_value =
            Number(
              new Decimal(Number(estimated_value) || 0).dividedBy(
                Number(this.props.rates[this.props.selectedCurrency]) || 0
              )
            ) || 0;
        }
        elements.push({
          id_cashflow_entry: row.side_info?.[month]?.id_cashflow_entry,
          estimated_value,
          entry_month,
          entry_year: this.props.selectedYear,
        });
      }
    }
    axios
      .post(
        `finances/save_cashflow_entries`,
        {
          elements,
          cashflow_element: row.cashflow_element,
        },
        {
          params: {
            token: this.props.token,
          },
        }
      )
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.getExpandedCashflow();
        }
      });
  };

  handleCloseDialog = () => {
    this.setState({ deleteCashflowOpen: false });
  };

  handleDeleteRow = () => {
    const payload = {
      id_club: "1",
      id_cashflow_entry: this.state.deleteCashfllowEntry,
      id_cashflow_name: this.state.deleteCashflowEntryName,
    };

    axios
      .post("finances/delete_cashflow_entry", payload, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.getExpandedCashflow();
          this.setState({ deleteCashflowOpen: false });
          store.addNotification({
            title: "Congratulations!",
            message: "You have deleted an element!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={Math.round(this.convertCurrency(value))}
        displayType={"text"}
        thousandSeparator={true}
      />
    );
  };

  convertCurrency = (value) => {
    if (this.props.selectedCurrency === constants.currencies.EUR.uniqueSlug) {
      return value;
    } else {
      return value * this.props.rates[this.props.selectedCurrency];
    }
  };

  render() {
    return (
      <div>
        <DataTable
          noTableHead
          center
          noHeader
          columns={tableConfig.columnsExpandedCashflow.map((item) => {
            if (item._hasEditableCell) {
              item.cell = (row) =>
                parseInt(row.id) === parseInt(this.state.editingRow) ? (
                  <input
                    input-column-expanded={`${row.id}`}
                    month={item.selector}
                    type="number"
                    defaultValue={this.convertCurrency(row[item.selector])}
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        this.saveEditedRow(row);
                      }
                    }}
                    style={{ width: 80 }}
                  />
                ) : (
                  this.formatNumber(row[item.selector])
                );
            } else {
              if (item._isEditToggle) {
                item.cell = (row) => {
                  return this.state.editingRow === row.id ? (
                    <div>
                      <Icon
                        className="cursor-pointer"
                        name="check"
                        size="large"
                        color="green"
                        onClick={() => this.saveEditedRow(row)}
                      />
                      <Icon
                        className="cursor-pointer"
                        name="cancel"
                        size="large"
                        color="red"
                        onClick={() => this.setState({ editingRow: undefined })}
                      />
                    </div>
                  ) : (
                    <div>
                      <Icon
                        className="cursor-pointer"
                        name="edit"
                        size="large"
                        color="black"
                        onClick={() => this.handleRowClicked(row)}
                      />
                      <Icon
                        className="cursor-pointer"
                        name="trash"
                        size="large"
                        color="black"
                        style={(StyleSheet.style = { marginLeft: 15 })}
                        onClick={() =>
                          this.setState({
                            deleteCashflowOpen: true,
                            deleteCashfllowEntry: row.id,
                            deleteCashflowEntryName: row.name,
                          })
                        }
                      />
                    </div>
                  );
                };
              }
            }
            return item;
          })}
          data={this.state.data}
          highlightOnHover
          onRowDoubleClicked={this.handleRowClicked}
          progressPending={this.state.isFetching}
          progressComponent={<CircularLoader />}
        />
        <Dialog
          open={this.state.deleteCashflowOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <div style={{ display: "flex" }}>
              Ești sigur/ă de ștergerea {this.state.deleteCashflowEntryName}?
              Acțiunea este ireversibilă!
            </div>
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseDialog} color="primary">
              Anuleaza
            </Button>
            <Button onClick={this.handleDeleteRow}
              color="var(--secondaryColor)"
            >
              Șterge
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};
const mapStateToProps = ({ layoutService: { languagePack }, auth }) => ({
  languagePack,
  token: auth?.user?.token,
});
export default connect(mapStateToProps, mapDispatchToProps)(ExpandedCashflow);
