import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';

import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { forEach, map } from 'lodash';

import styles from "./TrainingBoard.module.scss";

import { BoardContext } from './BoardContext';

import TFBIcon from '../../../components/design-system/Icon/TFBIcon';

import { ReactComponent as Square } from './../../../assets/training-board/square.svg';
import { ReactComponent as Rectangle } from './../../../assets/training-board/rectangle.svg';
import { ReactComponent as Circle } from './../../../assets/training-board/circle.svg';
import { ReactComponent as Oval } from './../../../assets/training-board/oval.svg';

import { ReactComponent as Ball } from './../../../assets/training-board/ball.svg';
import { ReactComponent as XCone } from './../../../assets/training-board/x-cone.svg';
import { ReactComponent as Cone } from './../../../assets/training-board/cone.svg';
import { ReactComponent as Goal } from './../../../assets/training-board/goal.svg';
import { ReactComponent as XGoal } from './../../../assets/training-board/x-goal.svg';
import { ReactComponent as Mannequin } from './../../../assets/training-board/mannequin.svg';
import { ReactComponent as Ladder } from './../../../assets/training-board/ladder.svg';
import { ReactComponent as Hurdle } from './../../../assets/training-board/hurdle.svg';
import { ReactComponent as SlalomPole } from './../../../assets/training-board/slalom-pole.svg';

import { ReactComponent as Player } from './../../../assets/training-board/player.svg';
import { ReactComponent as Goalkeeper } from './../../../assets/training-board/goalkeeper.svg';
import { ReactComponent as TrianglePlayer } from './../../../assets/training-board/triangle-player.svg';
import { ReactComponent as CirclePlayer } from './../../../assets/training-board/circle-player.svg';

import { ReactComponent as Text } from './../../../assets/training-board/text.svg';

import { ReactComponent as Arrow } from './../../../assets/training-board/arrow.svg';
import { ReactComponent as DashedArrow } from './../../../assets/training-board/dashed-arrow.svg';
import { ReactComponent as Line } from './../../../assets/training-board/line.svg';
import { ReactComponent as DashedLine } from './../../../assets/training-board/dashed-line.svg';

import { ReactComponent as WavyLine } from './../../../assets/training-board/wavy-line.svg';
import { ReactComponent as WavyArrow } from './../../../assets/training-board/wavy-arrow.svg';
import { ReactComponent as DashedWavyLine } from './../../../assets/training-board/dashed-wavy-line.svg';
import { ReactComponent as DashedWavyArrow } from './../../../assets/training-board/dashed-wavy-arrow.svg';

import { colorCoding } from './utils/colorCoding';
import { draggableTools, toolColors } from './utils/toolsNomenclature';

gsap.registerPlugin(Draggable);

const Toolbar = ({ languagePack }) => {
    const { selectedShape, setSelectedShape, selectedColorPallet, setSelectedColor, setIsDragging, setIsInCanvas } = useContext(BoardContext);

    useEffect(() => {
        forEach(draggableTools, obj => {
            Draggable.create(`#${obj.figure}`, {
                type: 'x,y',
                cursor: "move",
                onDragEnd: (e) => {
                    const canvas = document.getElementById('canvasId');
                    const rect = canvas.getBoundingClientRect();
                    const isInCanvas = e.x >= rect.left && e.x <= rect.right && e.y >= rect.top && e.y <= rect.bottom;
                    if (isInCanvas) {
                        if (obj.figure === "text") {
                            const addInputEvent = new CustomEvent('inputtext:add', {
                                detail: {
                                    x: e.x,
                                    y: e.y,
                                },
                            });
                            window.dispatchEvent(addInputEvent);
                        } else {
                            const dropEvent = new CustomEvent('figure:drop', {
                                detail: {
                                    x: e.x,
                                    y: e.y,
                                    figure: obj.figure,
                                    color: obj.customColor ? selectedColorPallet[obj.group] : false,
                                    coloringType: obj.coloringType ?? false,
                                    hasLabel: obj.hasLabel ?? false,
                                    group: obj?.group ?? null,
                                    labelColor: obj?.labelColor ?? "black",
                                    defaultLabel: obj?.defaultLabel ?? null,
                                    fontSize: obj?.fontSize ?? 12,
                                    hasShadow: obj?.hasShadow ?? false,
                                },
                            });
                            window.dispatchEvent(dropEvent);
                        }
                        gsap.set(`#${obj.figure}`, { x: 0, y: 0, opacity: 1, scale: 1 });
                    } else {
                        // Animate back to the original position
                        gsap.to(`#${obj.figure}`, {
                            duration: 0.5,
                            x: 0,
                            y: 0,
                            ease: "back.out(1)",
                            opacity: 1,
                            scale: 1,
                        });
                    }
                    setIsDragging(false);
                    setIsInCanvas(false);
                },
                onDragStart: (e) => {
                    setIsDragging(true);
                    setSelectedShape(null);
                    gsap.set(`#${obj.figure}`, { opacity: 0.5, scale: 1.25 });
                },
                onDrag: (e) => {
                    const canvas = document.getElementById('canvasId');
                    const rect = canvas.getBoundingClientRect();
                    const isInCanvas = e.x >= rect.left && e.x <= rect.right && e.y >= rect.top && e.y <= rect.bottom;
                    if (isInCanvas) {
                        gsap.set(`#${obj.figure}`, { opacity: 1 });
                    } else {
                        gsap.set(`#${obj.figure}`, { opacity: 0.5 });
                    }
                    setIsInCanvas(isInCanvas);
                },
            });
        });
    }, [selectedColorPallet]);

    return (
        <div className={styles["diagram-creator-sidebar"]}>
            <div className={styles['category-group']}>
                <div className={styles['category-name']}>{languagePack.shapes} <span className={styles['grey-text']}>{"(click + drag)"}</span></div>
                <div className={styles['category-container']}>
                    <div className={styles['colors-select']}>
                        {map(toolColors, (color, index) => (
                            <button key={"color-group-1-" + index} className={styles['color-btn']} onClick={() => setSelectedColor(1, color)} style={{ backgroundColor: colorCoding[color + "Figure"] }}>
                                {selectedColorPallet["1"] === color && <TFBIcon name='check' color='white' />}
                            </button>
                        ))}
                    </div>
                    <div className={styles['shapes-select']}>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'square' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'square' ? null : 'square')}>
                            <Square style={{ fill: selectedShape === 'square' ? "white" : colorCoding[selectedColorPallet["1"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'rectangle' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'rectangle' ? null : 'rectangle')}>
                            <Rectangle style={{ fill: selectedShape === 'rectangle' ? "white" : colorCoding[selectedColorPallet["1"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'circle' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'circle' ? null : 'circle')}>
                            <Circle style={{ fill: selectedShape === 'circle' ? "white" : colorCoding[selectedColorPallet["1"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'oval' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'oval' ? null : 'oval')}>
                            <Oval style={{ fill: selectedShape === 'oval' ? "white" : colorCoding[selectedColorPallet["1"] + "Figure"] }} />
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles['category-group']}>
                <div className={styles['category-name']}>{languagePack.equipment} <span className={styles['grey-text']}>{"(drag)"}</span></div>
                <div className={styles['category-container']}>
                    <div className={styles['colors-select']}>
                        {map(toolColors, (color, index) => (
                            <button key={"color-group-1-" + index} className={styles['color-btn']} onClick={() => setSelectedColor(2, color)} style={{ backgroundColor: colorCoding[color + "Figure"] }}>
                                {selectedColorPallet["2"] === color && <TFBIcon name='check' color='white' />}
                            </button>
                        ))}
                    </div>
                    <div className={`${styles['shapes-select']} ${styles['shapes-select-3-per-row']}`}>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="ball" className={styles['drag-container']}>
                                <Ball />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="x-cone" className={styles['drag-container']}>
                                <XCone style={{ fill: colorCoding[selectedColorPallet["2"] + "Figure"] }} />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="cone" className={styles['drag-container']}>
                                <Cone style={{ fill: colorCoding[selectedColorPallet["2"] + "Figure"] }} />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="goal" className={styles['drag-container']}>
                                <Goal />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="x-goal" className={styles['drag-container']}>
                                <XGoal />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="mannequin" className={styles['drag-container']}>
                                <Mannequin />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="ladder" className={styles['drag-container']}>
                                <Ladder />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="hurdle" className={styles['drag-container']}>
                                <Hurdle />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="slalom-pole" className={styles['drag-container']}>
                                <SlalomPole />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles['category-group']}>
                <div className={styles['category-name']}>{languagePack.players} <span className={styles['grey-text']}>{"(drag)"}</span></div>
                <div className={styles['category-container']}>
                    <div className={styles['colors-select']}>
                        {map(toolColors, (color, index) => (
                            <button key={"color-group-1-" + index} className={styles['color-btn']} onClick={() => setSelectedColor(3, color)} style={{ backgroundColor: colorCoding[color + "Figure"] }}>
                                {selectedColorPallet["3"] === color && <TFBIcon name='check' color='white' />}
                            </button>
                        ))}
                    </div>
                    <div className={`${styles['shapes-select']} ${styles['shapes-select-2-per-row']}`}>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="player" className={styles['drag-container']}>
                                <Player style={{ fill: colorCoding[selectedColorPallet["3"] + "Figure"] }} />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="goalkeeper" className={styles['drag-container']}>
                                <Goalkeeper style={{ fill: colorCoding[selectedColorPallet["3"] + "Figure"] }} />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="triangle-player" className={styles['drag-container']}>
                                <TrianglePlayer style={{ fill: colorCoding[selectedColorPallet["3"] + "Figure"] }} />
                            </div>
                        </button>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="circle-player" className={styles['drag-container']}>
                                <CirclePlayer style={{ fill: colorCoding[selectedColorPallet["3"] + "Figure"] }} />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles['category-group']}>
                <div className={styles['category-name']}>{"Text"} <span className={styles['grey-text']}>{"(drag)"}</span></div>
                <div className={styles['category-container']}>
                    <div className={`${styles['shapes-select']} ${styles['shapes-select-3-per-row']}`}>
                        <button className={`${styles["shape-btn"]}`}>
                            <div id="text" className={styles['drag-container']}>
                                <Text />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles['category-group']}>
                <div className={styles['category-name']}>{"Arrows + Lines"} <span className={styles['grey-text']}>{"(click + drag)"}</span></div>
                <div className={styles['category-container']}>
                    <div className={styles['colors-select']}>
                        {map(toolColors, (color, index) => (
                            <button key={"color-group-4-" + index} className={styles['color-btn']} onClick={() => setSelectedColor(4, color)} style={{ backgroundColor: colorCoding[color + "Figure"] }}>
                                {selectedColorPallet["4"] === color && <TFBIcon name='check' color='white' />}
                            </button>
                        ))}
                    </div>
                    <div className={`${styles['shapes-select']} ${styles['shapes-select-2-per-row']}`}>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'arrow' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'arrow' ? null : 'arrow')}>
                            <Arrow style={{ stroke: selectedShape === 'arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'dashed-arrow' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'dashed-arrow' ? null : 'dashed-arrow')}>
                            <DashedArrow style={{ stroke: selectedShape === 'dashed-arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'dashed-arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'line' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'line' ? null : 'line')}>
                            <Line style={{ stroke: selectedShape === 'line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'dashed-line' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'dashed-line' ? null : 'dashed-line')}>
                            <DashedLine style={{ stroke: selectedShape === 'dashed-line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'dashed-line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'wavy-arrow' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'wavy-arrow' ? null : 'wavy-arrow')}>
                            <WavyArrow style={{ stroke: selectedShape === 'wavy-arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'wavy-arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'dashed-wavy-arrow' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'dashed-wavy-arrow' ? null : 'dashed-wavy-arrow')}>
                            <DashedWavyArrow style={{ stroke: selectedShape === 'dashed-wavy-arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'dashed-wavy-arrow' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'wavy-line' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'wavy-line' ? null : 'wavy-line')}>
                            <WavyLine style={{ stroke: selectedShape === 'wavy-line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'wavy-line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                        <button className={`${styles["shape-btn"]} ${selectedShape === 'dashed-wavy-line' ? styles['selected'] : ''}`} onClick={() => setSelectedShape(selectedShape === 'dashed-wavy-line' ? null : 'dashed-wavy-line')}>
                            <DashedWavyLine style={{ stroke: selectedShape === 'dashed-wavy-line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"], fill: selectedShape === 'dashed-wavy-line' ? "white" : colorCoding[selectedColorPallet["4"] + "Figure"] }} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(Toolbar);