import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import styles from "./DocumentToSign.module.scss";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBCircularLoader from "../../../components/design-system/CircularLoader/TFBCircularLoader";
import CircularLoader from "../../../components/reusable/Loader";


const AddPlayerToDocument = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, token, id_document, currentPlayers } = props;
    const { id_club } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            teams: [{ team: null }],
            load_players_for_team: null,
        }
    );

    const {
        data: teams,
        loading: isTeamsLoading,
    } = useAxiosGet(`club/get_teams_nomenclature?id_club=${id_club}&type=select&all=0`);

    const {
        data: players,
        loading: isPlayersLoading,
        refetch: refetchPlayers,
    } = useAxiosGet(`club/get_current_team_players?id_team=${state.load_players_for_team}&type=select`, true);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("parentsApp/assign_player_to_document_to_sign");

    useEffect(() => {
        if (state.load_players_for_team > 0) {
            refetchPlayers();
        }
    }, [state.load_players_for_team])

    useEffect(() => {
        if (players?.success == 1) {
            const id_team = players?.id_team;
            const cloned_teams = _.clone(state.teams);
            const team_index = _.findIndex(cloned_teams, e => e?.team?.value == id_team);
            if (team_index > -1) {
                cloned_teams[team_index].players = _.map(players?.list ?? [], e => ({ ...e, checked: true }));
                cloned_teams[team_index].is_loading_players = false;
                updateState({ teams: cloned_teams })
            }
        }
    }, [players])

    const handleSelectTeam = (index, selected) => {
        const cloned_teams = _.clone(state.teams);
        cloned_teams[index].team = selected;
        cloned_teams[index].is_loading_players = true;
        updateState({ teams: cloned_teams, load_players_for_team: selected?.value ?? null })
    }

    const addTeam = () => {
        let cloned_teams = _.clone(state.teams);
        cloned_teams = _.union(cloned_teams, [{ team: null }]);
        updateState({ teams: cloned_teams })
    }

    const deleteTeam = (index) => {
        let cloned_teams = _.clone(state.teams);
        cloned_teams.splice(index, 1);
        updateState({ teams: cloned_teams })
    }

    const getTeamOptions = () => {
        return _.filter(teams?.list ?? [], e => (_.findIndex(state.teams, v => v?.team?.value == e.value)) == -1);
    }

    const handleChangePlayerCheckbox = (e, indexTeam, indexPlayer = "all") => {
        let cloned_teams = _.clone(state.teams);

        if (indexPlayer == "all") {
            cloned_teams[indexTeam].players = _.map(cloned_teams[indexTeam]?.players, p => ({ ...p, checked: e.target.checked }));
        } else {
            cloned_teams[indexTeam].players[indexPlayer].checked = e.target.checked;
        }
        updateState({ teams: cloned_teams })
    }

    const isValidAddPlayers = () => {
        return _.size(_.filter(_.reduce(_.map(state.teams, e => e.players), (result, value, key) => {
            result = _.union(result, _.isArray(value) ? value : []);
            return result;
        }, []), e => e.checked)) > 0 && !_.some(state?.teams, ["team", null])
    }

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.mobile_app,
                languagePack.success_generate_document
            );
        } else {
            showNotification(
                "danger",
                languagePack.mobile_app,
                languagePack.fail_generate_document
            );
        }
    };

    const addPlayers = () => {
        const payload = {
            id_document: id_document,
            players: _.filter(_.filter(_.reduce(_.map(state.teams, e => e.players), (result, value, key) => {
                result = _.union(result, _.isArray(value) ? value : []);
                return result;
            }, []), e => e.checked), e => _.findIndex(currentPlayers ?? [], o => o == e.value) == -1),
        };

        addRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addPlayers(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        !isValidAddPlayers(),
        loadingAddRequest,
    );

    return (
        <TFBDialog
            className={`pop-up-dialog-box prevent-select-text ${styles.addPlayerToDocument}`}
            title={languagePack["add_players"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            {!isTeamsLoading ?
                <>
                    <div className={styles.teamsContainer}>
                        {_.map(state.teams, (value, key) => (
                            <div key={"team-" + key} className={styles.teamContainer}>
                                <div className="df df-g20 df-align-end">
                                    <TFBSelect
                                        label={languagePack.team}
                                        placeholder={languagePack.select_team}
                                        value={value?.team ?? null}
                                        options={getTeamOptions()}
                                        onChange={(selected) => handleSelectTeam(key, selected)}
                                    />
                                    {_.size(state.teams) > 1 &&
                                        <TFBIconButton
                                            name="x-large"
                                            color="red"
                                            onClick={() => deleteTeam(key)}
                                        />
                                    }
                                </div>
                                <div className={styles.playersContainer}>
                                    {value?.is_loading_players ?
                                        <TFBCircularLoader /> :
                                        <div className="df df-gap-15 df-wrap prevent-select-text">
                                            {_.size(value?.players) > 0 &&
                                                <TFBCheckbox
                                                    key={"player-all"}
                                                    checked={!_.some(value?.players, ["checked", false])}
                                                    label={languagePack.all_players}
                                                    onChange={(e) => handleChangePlayerCheckbox(e, key)}
                                                    labelColor={"var(--primaryColor)"}
                                                />
                                            }
                                            {_.map(value?.players, (value2, key2) => (
                                                <TFBCheckbox
                                                    key={"player-" + key2}
                                                    checked={value2.checked}
                                                    label={value2.label}
                                                    onChange={(e) => handleChangePlayerCheckbox(e, key, key2)}
                                                />
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {_.size(getTeamOptions()) > 0 &&
                        <TFBButton
                            color="lightGray"
                            renderIcon={() => <TFBIcon name="add" color="gray" />}
                            disabled={_.some(state?.teams, ["team", null])}
                            onClick={addTeam}
                        >
                            {languagePack.add_team}
                        </TFBButton>
                    }
                </> :
                <CircularLoader />
            }
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(AddPlayerToDocument);
