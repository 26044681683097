import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "./../../../utils/api";
import axios from "axios";
import "./Costs.scss";
import { store } from "react-notifications-component";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

class AddDepartment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department_name: "",
      is_parent: false,
      child_subdepartments: [],
    };
  }

  addNewDepartment = () => {
    const { languagePack, closePopup, refreshList } = this.props;

    const payload = {
      department_name: this.state.department_name,
      child_subdepartments: this.state.is_parent
        ? this.state.child_subdepartments
        : [],
      id_club: this.props.currentUser.id_club,
      is_parent: { false: "0", true: "1" }[this.state.is_parent],
    };
    axios.post("finances/add_department", payload).then((res) => {
      if (res.data > 0) {
        refreshList();
        store.addNotification({
          title: languagePack.finances,
          message: languagePack.success_add_department,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      } else {
        store.addNotification({
          title: languagePack.finances,
          message: languagePack.fail_add_department,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      }
      closePopup();
    });
  };

  handleChangeCheckbox = () => {
    this.setState({ is_parent: !this.state.is_parent });
    if (!this.state.is_parent) {
      if (this.state.child_subdepartments.length == 0) {
        this.state.child_subdepartments.push({
          subdepartment_name: "",
        });
      }
    } else {
      this.setState({ child_subdepartments: [] });
    }
  }

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.add_departament}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={this.addNewDepartment}
            disabled={
              this.state.department_name.length > 0
                ? this.state.is_parent &&
                  this.state.child_subdepartments.length > 0
                  ? !this.state.child_subdepartments.every((elem) => {
                    if (elem.subdepartment_name.length > 0) {
                      return true;
                    }
                    return false;
                  })
                  : false
                : true
            }
          >
            {languagePack.admin_add}
          </TFBButton>
        )}
      >
        <div className="pop-up-section">
          <TFBInput
            label={languagePack.designation}
            placeholder={languagePack.designation}
            value={this.state.department_name}
            fullWidth={true}
            onChange={(e) => {
              this.setState({ department_name: e.target.value });
            }}
          />
        </div>
        <div className="pop-up-section">
          <TFBCheckbox
            label={languagePack.has_subdepartments}
            checked={this.state.is_parent}
            onChange={this.handleChangeCheckbox}
          />
        </div>
        {this.state.is_parent &&
          <div className="pop-up-section">
            <div className="pop-up-section-body">
              {this.state.child_subdepartments.map((elem, index) => (
                <div
                  className="pop-up-row df-g20 df-end"
                  key={"venue-child-" + index}
                >
                  <TFBInput
                    label={languagePack.designation}
                    placeholder={languagePack.subdepartment_designation}
                    value={this.state.child_subdepartments[index].subdepartment_name}
                    fullWidth={true}
                    onChange={(event) => {
                      let child_subdepartments = this.state.child_subdepartments;
                      child_subdepartments[index].subdepartment_name =
                        event.target.value;
                      this.setState({
                        child_subdepartments: child_subdepartments,
                      });
                    }}
                  />
                  <TFBIconButton
                    name="x-large"
                    color="red"
                    onClick={() => {
                      let child_subdepartments = this.state.child_subdepartments;
                      child_subdepartments.splice(index, 1);
                      this.setState({
                        child_subdepartments: child_subdepartments,
                      });
                      if (child_subdepartments.length == 0) {
                        this.setState({ is_parent: false });
                      }
                    }}
                  />
                </div>
              ))}
              {this.state.is_parent &&
                <TFBButton
                  color="lightGray"
                  renderIcon={() => <TFBIcon name="add" color="gray" />}
                  disabled={this.state.child_subdepartments.filter(elem => elem.subdepartment_name == "").length > 0}
                  onClick={() => {
                    let child_subdepartments = this.state.child_subdepartments;
                    child_subdepartments.push({ subdepartment_name: "" });
                    this.setState({ child_subdepartments: child_subdepartments });
                  }}          >
                  {languagePack.add_new_subdepartment}
                </TFBButton>
              }
            </div>
          </div>
        }
      </TFBDialog>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddDepartment);
