import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextArea, Button, Icon } from "semantic-ui-react";
import { store } from "react-notifications-component";
import "./RolesList.scss";
import delete_icon from "../../../../assets/icons/delete.png";
import Loader from "react-loader-spinner";

export default function RolesList(props) {
  const [rolesList, setRolesList] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [addNewRole, setAddNewRole] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const { languagePack } = props;

  useEffect(() => {
    getRoles();
  }, []);

  function getRoles() {
    axios
      .get(`club/get_roles?id_club=${props.id_club}`)
      .then((res) => {
        setRolesList(Array.isArray(res.data) ? res.data : []);
        setDataFetched(true);
      })
      .catch((err) => console.log(err));
  }

  function handleChangeInput(e) {
    setNewRole(e.target.value);
  }

  function submitNewRole() {
    const role = {
      id_club: props.id_club,
      role_name: newRole,
    };

    axios.post("club/add_new_role", role).then(() => {
      getRoles();
      setAddNewRole(false);
      setNewRole("");
      store.addNotification({
        title: languagePack['manage_role_notification_title'],
        message: languagePack['manage_role_notification_succes'],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  }

  function deleteRole(id) {
    const role = {
      id_role: id,
    };

    axios.post("club/delete_role", role).then(() => {
      getRoles();
      store.addNotification({
        title: languagePack['manage_role_notification_title'],
        message: languagePack['manage_role_notification_fail'],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  }

  return (
    <div className="roles-list-container">
      <div className="role-list-header">
        <div className="role-list-title">{languagePack['roles_list']}</div>
        <Icon
          name="close"
          className="close-icon-popup"
          onClick={() => props.closePopup()}
        />
      </div>
      {!dataFetched ? (
        <div className="loader-container">
          <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={25} width={25} />
        </div>
      ) : (
        <div className="roles-list">
          {rolesList.map((item, index) => {
            return (
              <div
                className="role-row"
                style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                key={item.key}
              >
                <div className="role-name">{item.text}</div>
                <img
                  src={delete_icon}
                  alt="delete"
                  onClick={() => deleteRole(item.key)}
                />
              </div>
            );
          })}
        </div>
      )}
      {addNewRole ? (
        <div className="add-role-container-opened">
          <TextArea
            placeholder={languagePack['add_new_role']}
            name="bonus-condition"
            value={newRole}
            onChange={handleChangeInput}
            className="add-role-input"
          />
          <div className="add-role-buttons-container">
            <Button
              className="role-button role-button-dark"
              onClick={() => setAddNewRole(false)}
            >
              {languagePack['admin_cancel']}
              <Icon name="cancel" className="add-white-icon" />
            </Button>
            <Button className="role-button" onClick={submitNewRole}>
              {languagePack['add_role']}
              <Icon name="add" className="add-white-icon" />
            </Button>
          </div>
        </div>
      ) : (
        <div className="add-role-container-closed">
          <Button className="role-button" onClick={() => setAddNewRole(true)}>
            {languagePack['add_role']}
            <Icon name="add" className="add-white-icon" />
          </Button>
        </div>
      )}
    </div>
  );
}
