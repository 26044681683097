import React from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import { Dropdown } from "semantic-ui-react";
import "./Polar.scss";
import { connect } from "react-redux";

const dropdown_options = [
  { value: "heart_rate_avg", text: "Puls mediu" },
  { value: "heart_rate_max", text: "Puls maxim" },
  { value: "distance_meters", text: "Distanta parcursa (m)" },
  { value: "kilo_calories", text: "Calorii consumate" },
  { value: "sprint_counter", text: "Numar de sprinturi" },
  { value: "speed_avg_kmh", text: "Viteza medie" },
  { value: "speed_max_kmh", text: "Viteza maxima" },
  { value: "training_load", text: "Intensitate antrenament" },
];

class PlayerTrainingsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#00d948", "#ddd"],
        stroke: {
          width: [5, 5, 4],
          curve: "straight",
        },
        labels: [],
        xaxis: {},
      },
      parameter: "distance_meters",
    };
  }

  componentDidMount = () => {
    this.getParameters();
  };

  getParameters = () => {
    axios
      .get(
        `training/player_training_chart?id_player=${this.props.id_player}&parameter=${this.state.parameter}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            labels: res.data.labels,
          },
          series: res.data.trainings,
        }));
      })
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value }, this.getParameters);
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom" style={{ padding: 50 }}>
        <div className="polar-trainings-chart-header">
          <div className="polar-trainings-chart-title">
            {languagePack["trainings_evolution"]}
          </div>
          <Dropdown
            options={dropdown_options}
            selection
            value={this.state.parameter}
            onChange={this.handleChangeDropdown}
            name="parameter"
            className="trainings-chart-dropdown"
          />
        </div>
        <Chart
          options={this.state.options}
          series={this.state.series ?? ["a", "b", "c"]}
          type="line"
          height={400}
        />
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(PlayerTrainingsChart);
