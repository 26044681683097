import React, { useState } from "react";
import { TTabControl } from "../types";
import styles from "./TFBTabControl.module.scss";

const TFBTabControl = ({
    tabs = [],
    tabsContent = [],
    style,
    className,
    activeTab,
    onChangeActiveTab,
}: TTabControl) => {

    const [activeTabIndex, setActiveTabIndex] = useState(activeTab != undefined && activeTab < tabs.length ? activeTab : (tabs.length > 0 ? 0 : null));

    const handleActiveTab = (index: React.SetStateAction<number | null>) => {
        setActiveTabIndex(index);
        if (activeTab != undefined && activeTab >= 0 && onChangeActiveTab) {
            onChangeActiveTab(index);
        };
    }

    return (
        <div className={className} style={style}>
            <div className={styles.tabsList}>
                {tabs.map((tab, i) => (
                    <div
                        key={"tab-" + i}
                        className={`${styles.tab} ${i == activeTabIndex ? styles.activeTab : ""}`}
                        onClick={() => handleActiveTab(i)}
                    >
                        {tab}
                    </div>
                ))}
            </div>
            <div className={styles.tabContainer}>
                {activeTabIndex != null && activeTabIndex >= 0 && tabs.length > activeTabIndex &&
                    <div>
                        {tabsContent[activeTabIndex]}
                    </div>
                }
            </div>
        </div>
    );
};

export default TFBTabControl;
