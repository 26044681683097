import React from "react";
import "./FanProfile.scss";
import axios from "axios";
import Moment from "react-moment";
import moment from "moment";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class FanProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fan_data: {},
      actions: [],
      actions_months: [],
      edited_field: "",
      edited_field_value: "",
    };
  }

  componentDidMount = () => {
    this.getBasicDetails();
    this.getFanActions();
  };

  getBasicDetails = () => {
    axios
      .get(`fans/get_fan_basic_details?id_fan=${this.props.match.params.id}`)
      .then((res) => {
        this.setState({
          fan_data: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  getFanActions = () => {
    axios
      .get(`fans/get_fan_actions?id_fan=${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ actions_months: res.data.events });
      })
      .catch((err) => console.log(err));
  };

  handleChangeDate = (value) => {
    this.setState({ edited_field_value: value });
  };

  saveNewField = () => {
    const payload = {
      id_fan: this.props.match.params.id,
      detail_type: this.state.edited_field,
      detail_value: this.state.edited_field_value,
    };
    axios
      .post("fans/edit_profile_info", payload)
      .then(() => {
        this.getBasicDetails();
        this.setState({ edited_field: "" });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div>
        <div className="fan-profile-page-title">
        {languagePack['fan_profile']} - {this.state.fan_data.fan_name}
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="card card-custom">
              <div className="fan-profile-container">
                <div className="fan-profile-title">{languagePack['general_info']}</div>
                <div className="fan-general-detail-row">
                  <div className="detail-label">{languagePack['full_name']}:</div>
                  <div className="detail-value">
                    {this.state.edited_field === "fan_name" ? (
                      <input
                        value={this.state.edited_field_value}
                        onChange={(e) =>
                          this.setState({ edited_field_value: e.target.value })
                        }
                        className="fan-profile-edit-input"
                      />
                    ) : (
                      this.state.fan_data.fan_name
                    )}
                  </div>
                  <div className="detail-options">
                    {this.state.edited_field === "fan_name" ? (
                      <>
                        <Icon
                          name="check"
                          size="small"
                          className="fan-profile-icon"
                          onClick={this.saveNewField}
                        />
                        <Icon
                          name="close"
                          size="small"
                          className="fan-profile-icon"
                          onClick={() =>
                            this.setState({
                              edited_field: "",
                              edited_field_value: "",
                            })
                          }
                        />
                      </>
                    ) : (
                      <Icon
                        name="pencil"
                        size="small"
                        className="fan-profile-icon"
                        onClick={() =>
                          this.setState({
                            edited_field: "fan_name",
                            edited_field_value: this.state.fan_data.fan_name,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="fan-general-detail-row">
                  <div className="detail-label">Email:</div>
                  <div className="detail-value">
                    {this.state.fan_data.emails
                      ? this.state.fan_data.emails.map((it) => (
                          <div className="detail-value-row">{it}</div>
                        ))
                      : "-"}
                  </div>
                </div>
                <div className="fan-general-detail-row">
                  <div className="detail-label">{languagePack['phone_no']}:</div>
                  <div className="detail-value">
                    {this.state.fan_data.phone_numbers
                      ? this.state.fan_data.phone_numbers.map((it) => (
                          <div className="detail-value-row">{it}</div>
                        ))
                      : "-"}
                  </div>
                </div>
                <div className="fan-general-detail-row">
                  <div className="detail-label">{languagePack['birthday']}:</div>
                  <div className="detail-value">
                    {this.state.edited_field === "fan_birthday" ? (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="fan-datepicker-row">
                          <DatePicker
                            value={
                              this.state.edited_field_value !== ""
                                ? this.state.edited_field_value
                                : null
                            }
                            onChange={this.handleChangeDate}
                            emptyLabel="Selecteaza data"
                            format="dd MMMM yyyy"
                            className="fan-datepicker-input"
                          />
                          <Icon
                            name="calendar alternate outline"
                            className="calendar-icon"
                            size="small"
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                    ) : (
                      <>
                        {this.state.fan_data.fan_birthday &&
                        this.state.fan_data.fan_birthday !== "0000-00-00" ? (
                          <Moment format="DD.MM.YYYY">
                            {this.state.fan_data.fan_birthday}
                          </Moment>
                        ) : (
                          "-"
                        )}
                      </>
                    )}
                  </div>
                  <div className="detail-options">
                    {this.state.edited_field === "fan_birthday" ? (
                      <>
                        <Icon
                          name="check"
                          size="small"
                          className="fan-profile-icon"
                          onClick={this.saveNewField}
                        />
                        <Icon
                          name="close"
                          size="small"
                          className="fan-profile-icon"
                          onClick={() =>
                            this.setState({
                              edited_field: "",
                              edited_field_value: "",
                            })
                          }
                        />
                      </>
                    ) : (
                      <Icon
                        name="pencil"
                        size="small"
                        className="fan-profile-icon"
                        onClick={() =>
                          this.setState({
                            edited_field: "fan_birthday",
                            edited_field_value: this.state.fan_data
                              .fan_birthday,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="fan-general-detail-row">
                  <div className="detail-label">{languagePack['age']}:</div>
                  <div className="detail-value">
                    {this.state.fan_data.fan_birthday &&
                    this.state.fan_data.fan_birthday !== "0000-00-00"
                      ? moment().diff(
                          this.state.fan_data.fan_birthday,
                          "years"
                        ) + " " + languagePack['years']
                      : "-"}
                  </div>
                </div>
                <div className="fan-general-detail-row">
                  <div className="detail-label">{languagePack['address']}:</div>
                  <div className="detail-value">
                    {this.state.fan_data.addresses
                      ? this.state.fan_data.addresses.map((it) => (
                          <div className="detail-value-row">{it}</div>
                        ))
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <div className="card card-custom">
              <div className="fan-profile-container">
                <div className="fan-profile-title">{languagePack['activity']}</div>
                <div className="fan-profile-activity-list">
                  {this.state.actions_months.map((month) => (
                    <>
                      <div className="month-subtitle">{month.month_name}</div>
                      <div style={{ marginBottom: 30 }}>
                        {month.month_actions.map((item, index) => {
                          return (
                            <div
                              className="activity-row"
                              style={{
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                            >
                              <div className="name">
                                <div className="username">
                                  {item.action_name}
                                </div>
                                {item.fan_email && (
                                  <div className="email">
                                    {"(" + item.fan_email + ")"}
                                  </div>
                                )}
                              </div>
                              <div className="date">
                                <Moment format="DD.MM.YYYY">
                                  {item.action_date}
                                </Moment>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(FanProfile);
