import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { Popup } from "semantic-ui-react";

class AgeGenderChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#666666", window.clubCustomization.primary_color],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
            endingShape: "flat",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["#fafafa", "transparent"],
        },
        grid: {
          row: {
            colors: ["#fafafa", "transparent"],
            opacity: 1,
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function(y) {
              return y.toLocaleString();
            },
          },
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.year !== this.props.year) {
      this.getData();
    }
  }

  getData = () => {
    axios
      .get(
        `fans/age_gender_distribution_chart?year=${this.props.year}&id_network=${this.props.id_network}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.values,
        }));
      });
  };
  render() {
    return (
      <div className="card card-custom">
        <div className="dashboard-box-container">
          <div className="dashboard-box-title">
            Page impressions by audience
            <Popup
              trigger={<div className="info-button">!</div>}
              content={
                <div>
                  The total number of impressions, grouped by age and gender in
                  the selected period
                </div>
              }
              position="right center"
              size="small"
              basic
            />
          </div>
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default AgeGenderChart;
