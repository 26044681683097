import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

const DeleteCost = (props) => {

    const { languagePack, closePopup, isOpenPopup, refreshList, costData } = props;

    const deleteCost = () => {
        axios
            .post("finances/delete_cost_item", { id_cost_item: costData.id_cost_item })
            .then(res => {
                if (res.data == 1) {
                    refreshList();
                    store.addNotification({
                        title: languagePack.finances,
                        message: languagePack.success_delete_cost,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.finances,
                        message: languagePack.fail_delete_cost,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch(e => console.log(e))
    };

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.delete_cost}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={() => (
                <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    onClick={closePopup}
                >
                    {languagePack.admin_cancel}
                </TFBButton>
            )}
            btnRight={() => (
                <TFBButton
                    color="red"
                    renderIcon={() => <TFBIcon name="delete" />}
                    onClick={deleteCost}
                >
                    {languagePack.admin_delete}
                </TFBButton>
            )}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.cost_delete_message}
            </div>
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(DeleteCost);