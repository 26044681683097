import React, { useState, useEffect } from "react";
import { Icon, Button } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddPlayerBonus from "./AddPlayerBonus";
import { logout } from "./../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import NumberFormat from "react-number-format";
import axios from "axios";
import * as myConstants from "./../../../app/utils/constants";
import { store } from "react-notifications-component";
import { baseUrl } from "../../utils/utilFunctions";

function ContractBonuses(props) {
  const [bonusDialog, setBonusDialog] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const [total, setTotal] = useState(0);
  const [rates, setRates] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteBonusId, setDeleteBonusId] = useState("");

  const getBonuses = async () => {
    const result = await axios(
      `upload/get_bonuses?token=${props.token}&id_contract=${props.id_contract}`
    );
    if (result?.data?.go_to_login === 1) {
      props.logout(props.token);
    } else {
      setBonuses(Array.isArray(result.data.bonuses) ? result.data.bonuses : []);
      // setTotal(getTotalBonuses(result.data.bonuses));
    }
  };

  const getBonusesStaff = async () => {
    const result = await axios(
      `upload/get_bonuses_staff?token=${props.token}&id_contract=${props.id_contract}`
    );
    if (result?.data?.go_to_login === 1) {
      props.logout(props.token);
    } else {
      setBonuses(Array.isArray(result.data.bonuses) ? result.data.bonuses : []);
      // setTotal(getTotalBonuses(result.data.bonuses));
    }
  };

  const { languagePack } = props;

  function formatNumber(value, currency) {
    return (
      <NumberFormat
        value={convertCurrency(value, currency)}
        displayType={"text"}
        thousandSeparator={"."}
        decimalSeparator={","}
        decimalScale={2}
        fixedDecimalScale={2}
      />
    );
  }

  function getCurrency() {
    fetch(baseUrl("club/get_currency_rates"))
      .then((res) => res.json())
      .then((data) => setRates(data));
  }

  const convertCurrency = (value, currency) => {
    const user_currency = props.currencyItemSelected.uniqueSlug;
    if (user_currency == currency) {
      return value;
    } else {
      if (user_currency == "EUR") {
        return value / rates[currency];
      } else {
        return value * rates[user_currency];
      }
    }
  };

  const getTotalBonuses = (bonuses) => {
    let sum = 0;
    bonuses.map((elem) => {
      sum += parseFloat(convertCurrency(elem.amount, elem.currency));
    });

    return formatNumber(sum, props.currencyItemSelected.uniqueSlug);
  };

  useEffect(() => {
    getCurrency();
  }, []);

  useEffect(() => {
    if (props.type == "staff") {
      getBonusesStaff();
    } else if (props.type == "player") {
      getBonuses();
    }
  }, [rates]);

  useEffect(() => {
    // setTotal(getTotalBonuses(bonuses));
  }, [props.currencyItemSelected.uniqueSlug]);

  const closeDeleteDialog = () => {
    setDeleteDialog(false);
    setDeleteBonusId("");
  };

  const deleteBonus = () => {
    if (props.type == "staff") {
      const payload = {
        id_staff_bonus: deleteBonusId,
      };
      axios
        .post("upload/delete_bonus_staff", payload)
        .then((res) => {
          store.addNotification({
            title: "Operatiuni contracte",
            message: "Bonusul a fost sters cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          getBonusesStaff();
          closeDeleteDialog();
        })
        .catch((err) => console.log(err));
    } else if (props.type == "player") {
      const payload = {
        id_player_bonus: deleteBonusId,
      };
      axios
        .post("upload/delete_bonus", payload)
        .then((res) => {
          store.addNotification({
            title: "Operatiuni contracte",
            message: "Bonusul a fost sters cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          getBonuses();
          closeDeleteDialog();
        })
        .catch((err) => console.log(err));
    }
  };

  const iconOsCurrency =
    props.currencyItemSelected.uniqueSlug === "EUR" ? (
      <Icon name="eur" className="mg--0 euro-icon" />
    ) : (
      <span className="font-weight-bold"> RON </span>
    );

  return (
    <>
      <div className="expanded-row-container">
        <div className="expanded-row-header">
          <div className="expanded-row-title">
            {languagePack["contract_bonuses"]}
          </div>

          <Button
            className="add-condition-expaneded-button"
            onClick={() => setBonusDialog(!bonusDialog)}
          >
            <div className="button-flex-content">
              {languagePack["admin_add"]}
              <Icon name="add" className="add-white-icon" />
            </div>
          </Button>
        </div>

        {bonuses.map((item, index) => {
          return (
            <div
              key={"bonus-" + index}
              className="bonus-condition-row"
              style={{ backgroundColor: index % 2 == 1 ? "#fafafa" : "white" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>{item.condition}</div>
                <div
                  style={{
                    color: window.clubCustomization.primary_color,
                    marginLeft: 7,
                  }}
                >
                  {item.nr_fulfillments > 0 &&
                    "(indeplinit de " + item.nr_fulfillments + " ori)"}
                </div>
              </div>
              <div className="amount-container">
                <div className="amount">
                  {formatNumber(item.amount, item.currency)}
                  {iconOsCurrency}
                </div>
                <div
                  className="deleted-icon"
                  onClick={() => {
                    setDeleteDialog(true);
                    setDeleteBonusId(item.id);
                  }}
                >
                  <Icon name="trash" className="trash-hover" />
                </div>
              </div>
            </div>
          );
        })}
        {bonuses.length > 0 && (
          <div className="bonus-condition-row">
            <div style={{ color: "black" }}>
              {languagePack.total_payment_at_present}
            </div>
            <div className="amount-total">
              {total}
              {iconOsCurrency}
            </div>
          </div>
        )}
      </div>

      <Dialog open={bonusDialog}>
        <div
          className="bonus-options-list-popup-header"
          style={{ paddingLeft: 50 }}
        >
          <div className="bonus-options-list-title">
            {languagePack["Add_bonus"]}
          </div>
          <div className="bonus-options-list-close-icon">
            {" "}
            <Icon
              name="close"
              onClick={() => setBonusDialog(false)}
              size="large"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <AddPlayerBonus
            closePopup={() => setBonusDialog(false)}
            id_player={props.id_player}
            id_contract={props.id_contract}
            getBonuses={props.type == "staff" ? getBonusesStaff : getBonuses}
            type={props.type}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={deleteDialog}>
        <div
          className="bonus-options-list-popup-header"
          // style={{ paddingLeft: 50 }}
        >
          <div className="bonus-options-list-title">
            {/* {languagePack["Add_bonus"]} */}
            {languagePack.delete_bonus}
          </div>
          <div className="bonus-options-list-close-icon">
            {" "}
            <Icon
              name="close"
              onClick={() => closeDeleteDialog()}
              size="large"
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <div className="delete-bonus-query">
            {languagePack.delete_bonus_text}
          </div>
          <div className="delete-bonus-buttons">
            <Button
              className="delete-bonus-button grey-btn"
              onClick={() => closeDeleteDialog()}
            >
              <div className="button-content">
                {languagePack["admin_cancel"]}
              </div>
              <Icon name="ban" className="white-icon" />
            </Button>
            <Button
              className="delete-bonus-button"
              color="red"
              onClick={deleteBonus}
              // disabled={this.state.selectedConditions.length === 0}
            >
              <div className="button-content">
                {/* {languagePack["Add_bonus"]} */}
                {languagePack.yes_delete}
              </div>
              <Icon name="trash" className="white-icon" />
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyItemSelected:
      myConstants.currencies[auth?.user?.currency] ||
      myConstants.currencies.EUR,
  }),
  mapDispatchToProps
)(ContractBonuses);
