import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import "../PlayerRadarChart.scss";

import { showNotification, useAxiosPost } from "../../../../customHooks/useAxiosPost";
import useRenderButton from "../../../../customHooks/useRenderButton";
import TFBDialog from "../../../../design-system/Dialog/TFBDialog";
import TFBInput from "../../../../design-system/Input/TFBInput";

const EditEvalution = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, data } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            evaluation_data: data,
        }
    );

    const { postData: editRequest, loading: loadingEditRequest } = useAxiosPost("player/update_evaluation_result");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.evaluation,
                languagePack.update_evaluation_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.evaluation,
                languagePack.update_evaluation_fail
            );
        }
    };

    const editEvaluation = () => {
        const payload = {
            sub_categories: _.reduce(state.evaluation_data, (result, value) => {
                return _.union(result, value.sub_categories);
            }, []),
        };

        editRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => editEvaluation(),
        "green",
        "check",
        languagePack.admin_save,
        {},
        true,
        false,
        loadingEditRequest,
    );

    const handleChangeValue = (index1, index2, value) => {
        if (value == "" || (value >= 1 && value <= 5)) {
            const cloneData = _.clone(state.evaluation_data);
            cloneData[index1]['sub_categories'][index2]['test_grade'] = value;
            updateState({ evaluation_data: cloneData });
        }
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text player-radar-chart-evaluation-widget"
            title={languagePack["edit_evaluation"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
            maxWidth={"xl"}
        >
            <div className="player-radar-chart">
                <div className="player-evaluation-detail" style={{ width: "100%", justifyContent: "space-between" }}>
                    {_.map(state.evaluation_data, (evaluationData, index1) => {
                        return (
                            <div
                                className="player-evaluation-category-wrapper"
                                key={"player-evaluation-category-wrapper-" + index1}
                            >
                                <div className="evaluation-category-header">
                                    <div className="evaluation-category-col">
                                        {evaluationData.category.category_name}
                                    </div>
                                    <div className="evaluation-category-average-col">
                                        {_.round(_.meanBy(evaluationData?.sub_categories ?? [], function (o) { return _.toNumber(o.test_grade); }), 2).toFixed(2)}
                                    </div>
                                </div>
                                {_.map(evaluationData?.sub_categories ?? [], (subcategory, index) => {
                                    return (
                                        <div
                                            className="evaluation-subcategories-row animate-drop"
                                            key={"evaluation-subcategories-row-" + index}
                                            style={{
                                                backgroundColor: index % 2 !== 0 && "#fafafa",
                                                border:
                                                    (subcategory.test_grade == 5 && "2px solid green") ||
                                                    (subcategory.test_grade == 1 && "2px solid red") || (index % 2 !== 0 && "2px solid #fafafa") || "2px solid white",
                                            }}
                                        >
                                            <div className="evaluation-subcategory-name">
                                                {subcategory.sub_cat_name}
                                            </div>
                                            <div className="evaluation-subcategory-grade">
                                                <TFBInput
                                                    style={{ width: 80 }}
                                                    type="number"
                                                    value={subcategory?.test_grade ?? ""}
                                                    onChange={e => handleChangeValue(index1, index, e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditEvalution);
