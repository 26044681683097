import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";

const EditFolder = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, folder } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            folder_name: folder?.folder_name ?? "",
        }
    );

    const { postData: editRequest, loading: loadingEditRequest } = useAxiosPost("upload/edit_folder");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.documents,
                languagePack.edit_folder_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.documents,
                languagePack.edit_folder_fail
            );
        }
    };

    const save = () => {
        const payload = {
            id_folder: folder?.id_folder,
            folder_name: state.folder_name,
        };

        editRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => save(),
        "green",
        "check",
        languagePack.admin_save,
        {},
        true,
        _.size(_.trim(state.folder_name)) == 0,
        loadingEditRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["edit_folder"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBInput
                label={languagePack.folder_name}
                value={state.folder_name}
                onChange={(e) => updateState({ folder_name: e.target.value })}
                style={{ width: "100%" }}
            />
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditFolder);
