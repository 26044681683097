import React from "react";
import Select from "react-select";
import { TSelect } from "../types";
import styles from "./TFBSelect.module.scss";
import CircularLoader from "../../reusable/Loader";

const TFBSelect = (props: TSelect) => {
  const { className, style, ...otherProps } = props;
  const classN = className ? className : "";

  const ReusableSelect = (
    <Select
      className={`${styles.tfbSelect} ${classN}`}
      {...otherProps}
      menuPortalTarget={document.body}
      styles={{
        container: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: "#fafafa",
          width: "100%",
          cursor: "pointer",
          ...style?.container,
        }),
        control: (provided: any, state: any) => ({
          ...provided,
          backgroundColor:
            otherProps?.isDisabled === true ? "#f2f2f2" : "#fafafa",
          cursor: "pointer",
          minHeight: "50px",
          border: "2px solid #F2F2F2",
          borderRadius: "3px",
          "&:hover": {
            border: "none",
            boxShadow: "0 0 0 2px black",
          },
          ...style?.control,
        }),
        valueContainer: (provided: any, state: any) => ({
          ...provided,
          cursor: "pointer",
          paddingLeft: "22px",
          fontSize: "14px",
          fontWeight: 700,
          ...style?.valueContainer,
        }),
        placeholder: (provided: any, state: any) => ({
          ...provided,
          color: "#b2b2b2",
          ...style?.placeholder,
        }),
        indicatorSeparator: (provided: any, state: any) => ({
          ...provided,
          display: "none",
          ...style?.indicatorSeparator,
        }),
        dropdownIndicator: (provided: any, state: any) => ({
          ...provided,
          cursor: "pointer",
          paddingLeft: "0px",
          ...style?.dropdownIndicator,
        }),
        menu: (provided: any, state: any) => ({
          ...provided,
          cursor: "pointer",
          maxHeight: "350px",
        }),
        menuPortal: (provided: any, state: any) => ({
          ...provided,
          zIndex: 9999,
        }),
      }}
      menuPlacement="auto"
    />
  );

  return props.label ? (
    <div
      className={`${styles.selectGroup} custom-select-wrapper`}
      style={style?.component ?? {}}
    >
      {<h6 className={styles.selectLabel}>{props.label}</h6>}
      {!props.isLoading ? ReusableSelect : <CircularLoader />}
    </div>
  ) : !props.isLoading ? (
    ReusableSelect
  ) : (
    <CircularLoader />
  );
};

export default TFBSelect;
