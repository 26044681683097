import React from "react";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import "../Club/Club.styles.scss";
import "./EditPhysicalCategoryItem.scss";

import { store } from "react-notifications-component";

class EditPhysicalCategoryItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category_name: "",

      subcategories: [],
      id_category: "",
      subcategory_to_delete: [],
    };
  }

  componentDidMount = () => {
    axios
      .get(
        `report/get_physical_parameters?id_club=${this.props.id_club}&category=${this.props.categoryData.value}`
      )
      .then((res) => {
        let subcategoriesData = res.data;

        this.setState({
          category_name: this.props.categoryData.label,
          subcategories: subcategoriesData,
          id_category: this.props.categoryData.value,
        });
      })
      .catch((err) => console.log(err));
  };

  updateCategory = () => {
    let subcategory_to_update = [];
    let subcategory_to_add = [];

    this.state.subcategories.forEach((elem) => {
      if (elem?.value) {
        subcategory_to_update.push(elem);
      } else {
        let venueData = {
          label: elem.name,
          um: elem.um,
          id_param_category: this.state.id_category,
        };
        subcategory_to_add.push(venueData);
      }
    });

    const payload = {
      id_club: this.props.id_club,
      id_category: this.state.id_category,
      category_name: this.state.category_name,
      subcategory_to_update: subcategory_to_update,
      parameter_child_to_add: subcategory_to_add,
      parameter_child_to_delete: this.state.subcategory_to_delete,
    };

    axios.post("report/update_physical_category", payload).then(() => {
      store.addNotification({
        title: "Rapoarte",
        message: "Categoria a fost actualizată cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.props.closePopup();
      this.props.getCategories();
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-category-form">
        <div className="add-category-header">
          <div className="add-category-title">{languagePack.edit_category}</div>
          <div className="add-category-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => {
                this.props.closePopup();
              }}
            />
          </div>
        </div>
        <div className="add-category-row">
          <input
            className="add-category-name"
            placeholder={languagePack.designation}
            value={this.state.category_name}
            onChange={(event) => {
              this.setState({ category_name: event.target.value });
            }}
          />
        </div>
        {true &&
          this.state.subcategories.map((elem, index) => {
            return (
              <div
                className="add-category-row"
                style={{
                  marginBottom:
                    index == this.state.subcategories.length - 1 ? "0" : "",
                }}
                key={"venue-child-" + index}
              >
                <input
                  className="add-subcategory-name"
                  placeholder={languagePack.subcategory_designation}
                  value={elem.name}
                  onChange={(event) => {
                    let subcategories = this.state.subcategories;
                    subcategories[index].name = event.target.value;
                    this.setState({ subcategories: subcategories });
                  }}
                />
                <input
                  className="add-um-subcategory-name"
                  placeholder={"um"}
                  value={elem.um}
                  onChange={(event) => {
                    let subcategories = this.state.subcategories;
                    subcategories[index].um = event.target.value;

                    this.setState({ subcategories: subcategories });
                  }}
                />
                {this.state.subcategories.length != 1 && (
                  <Icon
                    name="minus"
                    size="large"
                    className="delete-subcategory-icon"
                    color="red"
                    onClick={() => {
                      let subcategories = this.state.subcategories;
                      let subcategory_to_delete = this.state
                        .subcategory_to_delete;

                      if (elem?.value) {
                        subcategory_to_delete.push(elem);
                      }

                      subcategories.splice(index, 1);

                      this.setState({ subcategories: subcategories });
                      if (subcategories.length == 0) {
                        this.setState({
                          subcategory_to_delete,
                        });
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
        {true && (
          <div
            className="add-new-subcategory"
            onClick={() => {
              let subcategories = this.state.subcategories;
              subcategories.push({ label: "", name: "", um: "" });
              this.setState({ subcategories: subcategories });
            }}
          >
            {languagePack.add_new_subdivision}
          </div>
        )}
        <div className="add-category-buttons">
          <Button
            className="custom-button-dual grey-button"
            onClick={() => this.props.closePopup()}
          >
            {" "}
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon
                name="ban"
                size="small"
                className=""
                style={{ color: "white" }}
              />
            </div>
          </Button>
          <Button
            className="custom-button-dual add-button"
            onClick={this.updateCategory}
            disabled={
              this.state.category_name.length > 0
                ? this.state.subcategories.length > 0
                  ? !this.state.subcategories.every((elem) => {
                      if (elem.name != "" && elem.um != "") {
                        return true;
                      }
                      return false;
                    })
                  : false
                : true
            }
          >
            {" "}
            <div className="button-content">
              {languagePack.admin_save}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default EditPhysicalCategoryItem;
