import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";

import { ProgressBar } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./PresenceReport.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import CircularLoader from "../../../components/reusable/Loader";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import { baseUrl } from "../../../utils/utilFunctions";

const PresenceReport = (props) => {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      team: null,
      date_start: null,
      date_end: null,

      order_by: null,
      order_type: null,

      presence_list: [],
    }
  );

  const { languagePack, token, currentUser } = props;

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `training/get_club_teams?token=${token}&all=0&type=dropdown`
  );

  useEffect(() => {
    if (teams) {
      if (teams?.success == 1) {
        if (Array.isArray(teams?.list)) {
          updateState({ team: teams.list[0]?.value });
        }
      }
    }
  }, [teams]);

  const {
    data: presence,
    loading: isPresenceLoading,
    refetch: refetchPresence,
  } = useAxiosGet(
    `training/get_players_report_presence_2?id_team=${
      state.team
    }&start=${(moment(state.date_start).isValid()
      ? moment(state.date_start).format("YYYY-MM-DD")
      : null) ?? ""}&end=${(moment(state.date_end).isValid()
      ? moment(state.date_end).format("YYYY-MM-DD")
      : null) ?? ""}&token=${token}`,
    true
  );

  useEffect(() => {
    if (state.team > 0) {
      refetchPresence();
      updateState({
        order_by: null,
        order_type: null,
      });
    }
  }, [state.team]);

  useEffect(() => {
    if (presence?.players) {
      updateState({
        presence_list: presence.players,
        date_start: presence?.start ?? null,
        date_end: presence?.end ?? null,
      });
    }
  }, [presence]);

  const handleChangeDropdown = (e, value) => {
    updateState({ [value.name]: value.value });
  };

  const handleDateChange = (value, name) => {
    updateState({ [name]: value });
  };

  const handleFilterBy = (filter_by, filter_type) => {
    const filter_use_cases = {
      percentage: {
        field_name: "percentage",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      grade: {
        field_name: "grade",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
    };

    let sortedArray = _.sortBy(state.presence_list, [
      filter_use_cases[filter_by]["field_name"],
    ]);

    if (filter_use_cases[filter_by][filter_type] == "desc") {
      sortedArray = _.reverse(sortedArray);
    }

    updateState({
      order_by: filter_use_cases[filter_by]["field_name"],
      order_type: filter_use_cases[filter_by][filter_type],
      presence_list: sortedArray,
    });
  };

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.attendance_report}></TFBCardHeader>
      <TFBCardBody>
        {!isTeamsLoading ? (
          <>
            <div className={styles.headerRowBigDiv}>
              <div className={styles.headerRow}>
                <TFBDropdown
                  placeholder={languagePack["select_team"]}
                  selection
                  options={teams?.list ?? []}
                  value={state.team}
                  onChange={handleChangeDropdown}
                  name="team"
                />
                <div className={styles.dateDiv}>
                  <TFBDatePicker
                    label={languagePack.start_date}
                    placeholder={languagePack.start_date}
                    emptyLabel={languagePack.start_date}
                    value={state.date_start}
                    style={{ width: "150px" }}
                    onChange={(value) => handleDateChange(value, "date_start")}
                    name="date_start"
                  />
                  <TFBDatePicker
                    label={languagePack.end_date}
                    placeholder={languagePack.end_date}
                    emptyLabel={languagePack.end_date}
                    value={state.date_end}
                    style={{ width: "150px" }}
                    onChange={(value) => handleDateChange(value, "date_end")}
                    name="date_end"
                  />
                </div>
                <TFBButton
                  color="green"
                  renderIcon={() => <TFBIcon name="check" />}
                  style={{ height: "max-content" }}
                  onClick={refetchPresence}
                >
                  {languagePack.show_presence}
                </TFBButton>
              </div>
              <a
                target="blank"
                href={baseUrl(`training/export_attendence_report?id_team=${state.team}&start=${state.date_start}&end=${state.date_end}&type=D`)}
              >
                <TFBButton
                  size="lg"
                  renderIcon={() => <TFBIcon name="arrow-down" />}
                  style={{ height: "max-content" }}
                  onClick={() => {}}
                >
                  {languagePack.generate_attendance_pdf_report}
                </TFBButton>
              </a>
            </div>
            {!isPresenceLoading ? (
              _.size(state.presence_list) > 0 ? (
                <TableContainer>
                  <Table
                    faria-label="simple table"
                    className={styles.tablePresence}
                  >
                    <TableHead>
                      <TableRow>
                        <TFBTableCell sx={sxStickyColumn}>
                          {languagePack.player_name}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          <div
                            className={styles.headerCell}
                            onClick={() =>
                              handleFilterBy("percentage", state.order_type)
                            }
                          >
                            <div className={styles.headerLable}>
                              {languagePack.presence}
                            </div>
                            <div className={styles.headerFilter}>
                              {!(
                                state.order_by == "percentage" &&
                                state.order_type == "desc"
                              ) && (
                                <TFBIcon name="angle-up" color="lightGray" />
                              )}
                              {!(
                                state.order_by == "percentage" &&
                                state.order_type == "asc"
                              ) && (
                                <TFBIcon name="angle-down" color="lightGray" />
                              )}
                            </div>
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="right">
                          <div
                            className={`${styles.headerCell} ${styles.right}`}
                            onClick={() =>
                              handleFilterBy("grade", state.order_type)
                            }
                          >
                            <div className={styles.headerLable}>
                              {languagePack.average_grade}
                            </div>
                            <div className={styles.headerFilter}>
                              {!(
                                state.order_by == "grade" &&
                                state.order_type == "desc"
                              ) && (
                                <TFBIcon name="angle-up" color="lightGray" />
                              )}
                              {!(
                                state.order_by == "grade" &&
                                state.order_type == "asc"
                              ) && (
                                <TFBIcon name="angle-down" color="lightGray" />
                              )}
                            </div>
                          </div>
                        </TFBTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.map(state.presence_list, (row, index) => (
                        <TableRow
                          key={"notification-number-" + index}
                          sx={{
                            backgroundColor:
                              index % 2 == 0 ? "#fafafa" : "white",
                          }}
                        >
                          <TFBTableCell
                            sx={sxStickyColumn}
                            style={{
                              backgroundColor:
                                index % 2 == 0 ? "#fafafa" : "white",
                            }}
                          >
                            {row.player_name}
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div className={styles.playerRow}>
                              {row.percentage < 1 && (
                                <div className={styles.noPresence}>
                                  {`${row.player_nr_of_presents}/${row.total_nr_of_trainings}`}
                                </div>
                              )}
                              <ProgressBar
                                className={styles.progressBar}
                                label={`${row.player_nr_of_presents}/${row.total_nr_of_trainings}`}
                                now={row.percentage}
                                variant={row.class}
                                placeholder={languagePack.have_no_presence}
                              />
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="right">{row.grade}</TFBTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TFBPlaceholder
                  text={languagePack.no_trainings}
                  className={styles.placeholder}
                />
              )
            ) : (
              <CircularLoader />
            )}
          </>
        ) : (
          <CircularLoader />
        )}
      </TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PresenceReport);
