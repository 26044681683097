import React, { useState, useEffect } from "react";
import "./StaffMembers.scss";
import axios from "axios";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import { useDispatch } from "react-redux";
import { actions } from "../../../../../redux/authRedux";
import { isTFB } from "../../../../utils/utilFunctions";

function AddStaffForm(props) {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [newRole, setNewRole] = useState("");
  const [license, setLicense] = useState("");
  const [roleOptions, setRoleOptions] = useState([]);

  const { languagePack } = props;

  const licenseType = [
    {
      id: "0",
      license: "Nicio licență",
      value: "0",
      // label: "Nicio licență",
      label: languagePack.without_license,
    },
    {
      id: "1",
      license: "UEFA B",
      value: "1",
      // label: "UEFA B",
      label: languagePack.uefa_b,
    },
    {
      id: "2",
      license: "UEFA A",
      value: "2",
      // label: "UEFA A",
      label: languagePack.uefa_a,
    },
    {
      id: "3",
      license: "UEFA A Youth",
      value: "3",
      // label: "UEFA A Youth",
      label: languagePack.uefa_a_youth,
    },
    {
      id: "10",
      license: "Uefa B Youth",
      value: "10",
      label: languagePack.uefa_b_youth,
    },
    {
      id: "4",
      license: "UEFA A GK",
      value: "4",
      // label: "UEFA A GK",
      label: languagePack.uefa_a_gk,
    },
    {
      id: "5",
      license: "UEFA B GK",
      value: "5",
      // label: "UEFA B GK",
      label: languagePack.uefa_b_gk,
    },
    {
      id: "6",
      license: "UEFA Pro",
      value: "6",
      // label: "UEFA Pro",
      label: languagePack.uefa_pro,
    },
    {
      id: "7",
      license: "NON-UEFA",
      value: "7",
      // label: "NON-UEFA",
      label: languagePack.uefa_non_uefa,
    },
    {
      id: "8",
      license: "UEFA C",
      value: "8",
      // label: "UEFA C",
      label: languagePack.uefa_c,
    },
    {
      id: "9",
      license: "Carnet de antrenor",
      value: "9",
      // label: "Carnet de antrenor",
      label: languagePack.uefa_coaching_license,
    },
  ];

  const [licenseOption, setLicenseOption] = useState(licenseType);
  const [dateExpire, setDateExpire] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    getRoles();
  }, []);

  function getRoles() {
    axios
      .get(`club/get_roles?id_club=${props.id_club}`)
      .then((res) => {
        let allRoles = [
          {
            key: "0",
            value: "0",
            label: languagePack.add_new_role,
            text: languagePack.add_new_role,
          },
          ...res.data,
        ];

        setRoleOptions(Array.isArray(allRoles) ? allRoles : []);
      })
      .catch((err) => console.log(err));
  }

  function handleChangeName(e) {
    setName(e.target.value);
  }
  const handleChangeDate = (value) => {
    setDateExpire(value);
  };

  function submitNewMember() {
    let member = {
      id_club: props.id_club,
      staff_member_name: name,
      date_expire_license: dateExpire == "Invalid date" ? null : dateExpire,
      license: license.license,
      is_tfb: isTFB(),
    };

    // Check if user is adding a new member role
    if (role.key != "0") {
      member = { ...member, staff_member_role: role.label };
    } else {
      member = { ...member, new_staff_member_role: newRole };
    }

    axios.post("club/add_new_member_3", member).then((res) => {
      if (res.data.success == 1) {
        props.closePopup();
        props.refreshMembers();

        let clubQuickSetup = res.data.club_quick_setup;
        dispatch(
          actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: false })
        );
      }
    });
  }

  const renderBtn = () => {
    return (
      <TFBButton
        className="custom-button-dual add-button"
        disabled={
          role.key != 0
            ? name == "" || role == "" || license == ""
            : name == "" || newRole == "" || license == ""
        }
        color="green"
        renderIcon={() => <TFBIcon name="add" />}
        onClick={submitNewMember}
      >
        {languagePack["admin_add"]}
      </TFBButton>
    );
  };

  return (
    <TFBDialog
      open={props.open}
      fullWidth={true}
      maxWidth="sm"
      closePopup={props.closePopup}
      title={languagePack["add_member"]}
      // btnRight={() => renderBtn("right", () => exportPlayers())}
      btnLeft={() => renderBtn()}
    >
      <div className="add-staff-container">
        <div className="">
          <TFBInput
            value={name}
            onChange={handleChangeName}
            // placeholder={languagePack["name"]}
            label={languagePack["name"]}
            fullWidth
            style={{ backgroundColor: "#fafafa", marginBottom: "25px" }}
          />
        </div>
        <div className="" style={{ marginBottom: "25px" }}>
          <TFBSelect
            placeholder={languagePack["select"]}
            label={languagePack["staff_member_role"]}
            value={role}
            options={roleOptions}
            onChange={(selected) => {
              setRole(selected);
            }}
          />
        </div>

        {role.key == 0 && (
          <TFBInput
            value={newRole}
            onChange={(e) => setNewRole(e.target.value)}
            label={languagePack.add_new_role}
            fullWidth
            style={{ backgroundColor: "#fafafa", marginBottom: "25px" }}
          />
        )}

        <div className="" style={{ marginBottom: "25px" }}>
          <TFBSelect
            placeholder={languagePack.select}
            label={languagePack.select_license}
            value={license}
            options={licenseOption}
            onChange={(selected) => {
              setLicense(selected);
              setDateExpire(null);
            }}
          />
        </div>

        {license.license != "Nicio licență" && license != "" ? (
          <div className="license-date-end" style={{ marginBottom: "25px" }}>
            <TFBDatePicker
              value={dateExpire}
              onChange={handleChangeDate}
              className="team-add-date-input"
              placeholder={languagePack.select}
              label={languagePack.date_expire_license}
              style={{ width: "100%" }}
            />
          </div>
        ) : (
          false
        )}
      </div>
    </TFBDialog>
  );
}

export default AddStaffForm;
