import React from "react";
import { TextArea, Button, Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { store } from "react-notifications-component";
import "./EditInjuryForm.scss";
import dateFormat from "dateformat";

class EditInjuryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      injury_name: this.props.injury_name,
      injury_date: this.props.injury.date_start,
      estimated_recovery_date: this.props.injury.estimated_recovery,
      recovery_date: this.props.injury.date_end,
      description: this.props.injury.description,
      error: "",
    };
  }

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChangeStart = (value) => {
    this.setState({ injury_date: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ estimated_recovery_date: value });
  };

  handleDateChangeEnd2 = (value) => {
    this.setState({ recovery_date: value });
  };

  saveEdits = () => {
    const payload = {
      id_injury: this.props.injury.id_injury,
      injury_name: this.state.injury_name,
      injury_date: this.state.injury_date,
      injury_description: this.state.description,
      estimated_recovery_date: this.state.estimated_recovery_date,
      recovery_date: this.state.recovery_date,
    };

    if (
      (dateFormat(this.state.recovery_date, "yyyy-mm-dd") >
        dateFormat(this.state.injury_date, "yyyy-mm-dd") &&
        this.props.ended == "1") ||
      (dateFormat(this.state.estimated_recovery_date, "yyyy-mm-dd") >
        dateFormat(this.state.injury_date, "yyyy-mm-dd") &&
        this.props.ended == "0")
    ) {
      axios.post("medical/edit_injury", payload).then((res) => {
        this.props.close_section();
        store.addNotification({
          title: this.props.languagePack['injury_details'],
          message: this.props.languagePack['injury_change_success'],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState({ error: "" });
      });
    } else {
      this.setState({
        error: this.props.languagePack["rec_date_not_before_inj_date"],
      });
    }
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="edit-injury-form-container">
        <div className="edit-injury-row">
          <input
            disabled
            placeholder={languagePack["active_injuries_type"]}
            className="edit-injury-input"
            value={this.state.injury_name}
            onChange={this.handleChangeInput}
            name="injury_name"
          />
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="edit-injury-row">
            <div className="edit-injury-date-group">
              <DatePicker
                value={this.state.injury_date}
                onChange={this.handleDateChangeStart}
                inputVariant="outlined"
                emptyLabel={languagePack["active_injuries_injury_date"]}
                className="injury-date-input"
                format="dd MMMM yyyy"
                name="injury_date"
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
            <div className="edit-injury-date-group">
              {this.props.ended === "0" ? (
                <DatePicker
                  value={this.state.estimated_recovery_date}
                  onChange={this.handleDateChangeEnd}
                  inputVariant="outlined"
                  emptyLabel={
                    languagePack["active_injuries_estimated_recovery"]
                  }
                  className="injury-date-input"
                  format="dd MMMM yyyy"
                  name="estimated_recovery_date"
                />
              ) : (
                <DatePicker
                  value={this.state.recovery_date}
                  onChange={this.handleDateChangeEnd2}
                  inputVariant="outlined"
                  emptyLabel={languagePack["return_date"]}
                  className="injury-date-input"
                  format="dd MMMM yyyy"
                  name="recovery_date"
                />
              )}
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </div>
        </MuiPickersUtilsProvider>

        <TextArea
          placeholder={languagePack["injury_details"]}
          className="text-area-edit-injury"
          value={this.state.description}
          onChange={(e) => this.setState({ description: e.target.value })}
        />
        {this.state.error != "" && (
          <div className="end-injury-error-row">{this.state.error}</div>
        )}
        <div className="edit-injury-buttons-container">
          <Button
            className="custom-button-dual treatment-cancel-button"
            onClick={this.props.close_section}
          >
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon name="x" className="add-icon" />
            </div>
          </Button>
          <Button
            className="custom-button-dual treatment-add-button"
            onClick={this.saveEdits}
          >
            <div className="button-content">
              {languagePack["admin_save"]}
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default EditInjuryForm;
