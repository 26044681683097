import React, { useState, useEffect } from "react";
import "./Fans.scss";
import axios from "axios";
import Moment from "react-moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import { connect } from "react-redux";
import { baseUrl } from "../../utils/utilFunctions";


function TaxRedirectors(props) {
  const [taxRedirectors, setTaxRedirectors] = useState([]);
  const { languagePack } = props;

  useEffect(() => {
    axios
      .get(`club/get_tax_redirectors?id_club=${props.currentUser.id_club}`)
      .then((res) => {
        setTaxRedirectors(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card card-custom">
      <div className="tax-redirectors-container">
        <div className="tax-redirectors-header">
          <div className="tax-redirectors-title">

            {languagePack['tax_redirectors_title']}
          </div>
          <div className="tax-redirectors-total-number">
            <div className="total-label">{languagePack['tax_redirectors_total']}:</div>
            <div className="total-number">{taxRedirectors.length}</div>
          </div>
        </div>
        <div className="tax-redirectors-table">
          <div className="tax-redirectors-table-header">
            <div className="name-column">{languagePack['tax_redirector_name']}</div>
            <div className="date-column">{languagePack['tax_redirector_register_date']}</div>
            <div className="social-column">{languagePack['tax_redirector_email']}</div>
            <div className="social-column">{languagePack['tax_redirector_phone_number']}</div>
            <div className="options-column"></div>
          </div>
          <div className="tax-redirectors-list">
            {taxRedirectors.map((item, index) => {
              return (
                <div
                  className="tax-redirector-row"
                  style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                >
                  <div className="name-column">
                    {item.first_name + " " + item.last_name}
                  </div>
                  <div className="date-column">
                    <Moment format="DD.MM.YYYY">{item.date_add}</Moment>
                  </div>
                  <div className="social-column">{item.email_address}</div>
                  <div className="social-column">{item.phone_number}</div>
                  <div className="options-column">
                    <a
                      style={{ color: "black" }}
                      href={baseUrl(`club/download_tax_form_app?id=${item.id_tax_redirector}&first_name=${item.first_name}&last_name=${item.last_name}&unique_form_id=${item.unique_form_id}`)}
                    >
                      <GetAppIcon className="mr-10" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(TaxRedirectors);
