import React, { useState } from "react";
import TFBIcon from "../design-system/Icon/TFBIcon";
import TFBButton from "../design-system/Button/TFBButton";

const useRenderButton = (
  onClick,
  color,
  iconName,
  text,
  style = {},
  endIcon = true,
  disabled = false,
  loading = false,
  iconColor = "white",
  startIcon = false,
) => {
  const renderBtn = () => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        color={color}
        {...(iconName && {
          renderIcon: () => <TFBIcon name={iconName} color={iconColor} />,
        })}
        disabled={disabled}
        loading={loading}
        style={style}
      >
        {text}
      </TFBButton>
    );
  };

  return {
    renderBtn,
  };
};

export default useRenderButton;
