export function colorCodeMark(mark: number) {
  switch (true) {
    case mark <= 1:
      return "#c52b2b";
    case mark > 1 && mark <= 2:
      return "#e67a29";
    case mark > 2 && mark <= 3:
      return "#d3ad02";
    case mark > 3 && mark <= 4:
      return "#a2bc00";
    case mark > 4 && mark <= 5:
      return "#1dac04";

    default:
      return "#d3ad02";
  }
}
export function colorCodeAttendance(percentage: number) {
  switch (true) {
    case percentage <= 20:
      return "#c52b2b";
    case percentage > 20 && percentage < 40:
      return "#e67a29";
    case percentage > 40 && percentage <= 60:
      return "#d3ad02";
    case percentage > 60 && percentage <= 80:
      return "#a2bc00";
    case percentage > 80:
      return "#1dac04";

    default:
      break;
  }
}
export function getDayOfWeek(date: any) {
  const d = new Date(date);
  const dayNo = d.getDay();
  switch (dayNo) {
    case 0:
      return "sunday";
    case 1:
      return "monday";

    case 2:
      return "tuesday";

    case 3:
      return "wednesday";

    case 4:
      return "thursday";

    case 5:
      return "friday";

    case 6:
      return "saturday";

    default:
      return "error";
  }
}