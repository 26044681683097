import React, { useReducer } from "react";
import { connect } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import _ from "lodash";

import { showNotification, useAxiosPost } from "../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../components/customHooks/useAxiosGet";
import useRenderButton from "../../components/customHooks/useRenderButton";
import TFBInput from "../../components/design-system/Input/TFBInput";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../components/design-system/TFBSelect/TFBSelect";
import TFBPhoneInput from "../../components/design-system/PhoneInput/TFBPhoneInput";
import FadeInOut from "../../components/design-system/FadeInOut/FadeInOut";
import CircularLoader from "../../components/reusable/Loader";

const ShareVideo = (props) => {

    const { openPopup, closePopup, languagePack, currentUser, videoData } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            club_type: null,

            club_name: "",
            full_name: "",
            email: "",
            phone_number: "",

            selected_club: null,
        }
    );

    const {
        data: clubList,
        loading: isClubListLoading,
        refetch: refetchClubList,
    } = useAxiosGet(
        `club/get_club_list_for_share_video?id_club=${currentUser.id_club}`
    );

    const { postData: shareRequest, loading: loadingShare } = useAxiosPost("upload/share_video");


    const isValidPhoneNumberCustom = (phone) => {
        let valid = true;

        if (phone) {
            if (_.size(phone) > 0) {
                if (_.findIndex(_.toArray(phone), (e) => e == "7", 0) == 3) {
                    valid = isValidPhoneNumber(phone);
                } else {
                    valid = false;
                }
            }

        }
        return valid;
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => shareVideo(),
        "green",
        "check",
        languagePack.send,
        {},
        true,
        state.selected_club == null || (state.selected_club?.value == "club_from_external" && (_.size(state.club_name) == 0 || _.size(state.full_name) == 0 || _.size(state.email) == 0 || _.size(state.phone_number) == 0 || !isValidPhoneNumberCustom(state.phone_number))),
        loadingShare,
    );

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            showNotification(
                "success",
                languagePack.video_operation,
                languagePack.video_share_success
            );
        } else {
            if (response?.error_code) {
                showNotification(
                    "danger",
                    languagePack.video_operation,
                    languagePack[response.error_code]
                );
            } else {
                showNotification(
                    "danger",
                    languagePack.video_operation,
                    languagePack.video_share_failed
                );
            }
        }
    };

    const shareVideo = () => {
        const payload = {
            from_club: currentUser.id_club,
            id_video: videoData.id_video,
            from_club_name: currentUser.club.club_name,
        };

        if (state.selected_club?.value == "club_from_external") {
            payload.new_club = 1;
            payload.club = {
                club_name: state.club_name,
            };
            payload.user = {
                full_name: state.full_name,
                email: state.email,
                phone_number: state.phone_number,
            };
        } else {
            payload.to_club = state.selected_club?.value;
            payload.new_club = 0;
        }

        shareRequest(payload, handleResponse);
    }

    const handleClubOnBlur = (e) => {
        if (e.target.value) {
            updateState({ selected_club: { value: "club_from_external", label: "Alt club" }, club_name: e.target.value });
        }
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["share_video"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            {!isClubListLoading ?
                <TFBSelect
                    label={languagePack.select_club}
                    value={state.selected_club}
                    options={_.union([{ value: "club_from_external", label: "Alt club" }], clubList?.list) ?? []}
                    onChange={(selected) => updateState({
                        selected_club: selected,
                        club_name: "",
                        full_name: "",
                        email: "",
                        phone_number: "",
                    })}
                    onBlur={handleClubOnBlur}
                    style={{ container: { marginBottom: 20 } }}
                    isClearable={true}
                /> :
                <CircularLoader />
            }
            {state.selected_club?.value == "club_from_external" &&
                <FadeInOut show={state.selected_club?.value == "club_from_external"}>
                    <TFBInput
                        label={languagePack.club_name}
                        placeholder={languagePack.enter_club_name}
                        value={state.club_name}
                        onChange={(e) => updateState({ club_name: e.target.value })}
                        style={{ marginBottom: 20, width: "100%", backgroundColor: "#fafafa" }}
                    />
                    <TFBInput
                        label={languagePack.name_and_surname}
                        placeholder={languagePack.enter_name_and_surname_share_video}
                        value={state.full_name}
                        onChange={(e) => updateState({ full_name: e.target.value })}
                        style={{ marginBottom: 20, width: "100%", backgroundColor: "#fafafa" }}
                    />
                    <TFBInput
                        label={languagePack.email}
                        placeholder={languagePack.enter_email}
                        value={state.email}
                        onChange={(e) => updateState({ email: e.target.value })}
                        style={{ marginBottom: 20, width: "100%", backgroundColor: "#fafafa" }}
                    />
                    <TFBPhoneInput
                        label={languagePack.phone_number}
                        defaultCountry="RO"
                        countries={["RO"]}
                        initialValueFormat="national"
                        placeholder={"Ex: 0712 345 678"}
                        value={state.phone_number}
                        onChange={(e) => updateState({ phone_number: e ?? "" })}
                    />
                    {!isValidPhoneNumberCustom(state.phone_number) && (
                        <div className="ui red message">{languagePack.wrong_phone_number}</div>
                    )}
                </FadeInOut>
            }
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(ShareVideo);
