import React, { Component } from "react";
import "./CompetitionPage.scss";
import CompetitionRanking from "./CompetitionRanking";
import CompetitionMatches from "./CompetitionMatches";
import { Button, Icon } from "semantic-ui-react";
import AddTeamToGroup from "../AddTeam/AddTeamToGroup";
import { Dialog, DialogContent } from "@material-ui/core";

import axios from "axios";
import { connect } from "react-redux";

import CompetitionTeams from "./CompetitionTeams";
import { Redirect } from "react-router-dom";
import permissions from "./../../../../../app/utils/permissions";

class CompetitionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addTeamToGroupPopup: false,
      teams: [],
      competition_type: "",
      competition_name: "",
      groups: [],
      teamsPerGroups: [],
      teamsInCompetition: [],
      matches: [],
      competition_id_club: "",
      importType: "",

      hasAccessToCompetition: false,
      redirectToCompetitions: false,
      refreshRanking: 0,
    };
  }

  componentDidMount = () => {
    this.hasAccessToCompetition();
  };

  hasAccessToCompetition = () => {
    axios
      .get(
        `user/has_access_to_competition?id_competition=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToCompetition: true,
          });
          this.getCompetitionTeams();
          this.getMatches();
          this.getCompetitionDetails();
          this.getRanking();
        } else {
          this.setState({
            redirectToCompetitions: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getCompetitionDetails = () => {
    axios
      .get(`matches/competition_type?id_comp=${this.props.match.params.id}`)
      .then((res) => {
        let groups = [];
        res.data.groups.map((item) => {
          return groups.push({
            value: item.id_competition_group,
            label: item.group_name,
          });
        });
        this.setState({
          competition_type: res.data.type,
          competition_id_club: res.data.competition_id_club,
          competition_name: res?.data?.name,
          competitionInfo: res?.data,
        });
        this.setState(
          {
            groups: groups,
          },
          async () => {
            if (this.state.competition_type === "group-stage") {
              const promises = [];
              for (const elem of groups) {
                const result = axios.get(
                  `matches/competition_ranking?id_comp=${this.props.match.params.id}&id_competition_group=${elem.value}&id_club=${this.props.currentUser.id_club}`
                );
                promises.push(result);
              }
              const results = await Promise.all(promises);
              const data = [];
              results.map((result, index) => {
                data.push({
                  teams: result.data,
                  group: groups[index],
                });
              });
              this.setState({
                teamsPerGroups: data,
              });
            }
          }
        );
      })
      .catch((err) => console.log(err));
  };

  getRanking = () => {
    axios
      .get(
        `matches/competition_ranking?id_comp=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({ teams: res.data });
      })
      .catch((err) => console.log(err));
  };

  getCompetitionTeams = () => {
    axios
      .get(
        `matches/get_competition_teams2?id_competition=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState({
          teamsInCompetition: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  getMatches = () => {
    axios
      .get(
        `matches/get_competition_matches?id_competition=${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({ matches: res.data });
      })
      .catch((err) => console.log(err));
  };

  render() {
    if (this.state.redirectToCompetitions) {
      return <Redirect to="/club/competitions" />;
    }

    const { languagePack, user_permissions } = this.props;
    return (
      <>
        {this.state.hasAccessToCompetition && (
          <div>
            <div className="row" style={{ marginBottom: "25px" }}>
              <div className="col-lg-12">
                <div className="card card-custom">
                  <div className="competition-page-matches-container">
                    <CompetitionTeams
                      competition_name={this.state.competition_name}
                      id_competition={this.props.match.params.id}
                      languagePack={this.props.languagePack}
                      updateCompetitionDetails={() => {
                        if (this.state.competition_type === "group-stage") {
                          this.getCompetitionDetails();
                        } else {
                          this.getRanking();
                        }
                      }}
                      updateCompetitionMatches={() => {
                        this.getMatches();
                      }}
                      hasAccessToEditCompetition={
                        this.state.competition_id_club ==
                          this.props.currentUser.id_club ||
                        user_permissions?.[permissions.CLUB]?.[
                          permissions.COMPETITION
                        ]?.[permissions.EDIT_COMMON_COMPETITION]
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card card-custom">
                  <div className="competition-page-matches-container">
                    <CompetitionMatches
                      id_competition={this.props.match.params.id}
                      languagePack={this.props.languagePack}
                      groups={this.state.groups}
                      matches={this.state.matches}
                      refreshGroupsResults={() => {
                        if (this.state.competition_type === "group-stage") {
                          this.getCompetitionDetails();
                        } else {
                          this.getRanking();
                        }
                      }}
                      getMatches={() => {
                        this.getMatches();
                      }}
                      competition_type={this.state.competition_type}
                      hasAccessToEditCompetition={
                        this.state.competition_id_club ==
                          this.props.currentUser.id_club ||
                        user_permissions?.[permissions.CLUB]?.[
                          permissions.COMPETITION
                        ]?.[permissions.EDIT_COMMON_COMPETITION]
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card card-custom">
                  <div className="competition-page-ranking-container">
                    <div className="competition-page-ranking-header">
                      <div className="competition-page-ranking-title">
                        {this.state.competition_type === "championship"
                          ? languagePack["ranking"]
                          : languagePack["fixture_table"]}
                      </div>
                      {this.state.competition_type === "group-stage" &&
                        (this.state.competition_id_club ==
                          this.props.currentUser.id_club ||
                          user_permissions?.[permissions.CLUB]?.[
                            permissions.COMPETITION
                          ]?.[permissions.EDIT_COMMON_COMPETITION]) && (
                          <Button
                            className="add-team-button"
                            onClick={() =>
                              this.setState({ addTeamToGroupPopup: true })
                            }
                          >
                            {/* {languagePack["add_team"]} */}
                            {"Add team to group"}
                            <Icon name="add" className="white-icon" />
                          </Button>
                        )}
                    </div>
                    {this.state.competition_type === "championship" && (
                      <CompetitionRanking
                        typeGroup={
                          this.state.competition_type === "group-stage"
                        }
                        competitionInfo={this.state.competitionInfo}
                        teams={this.state.teams}
                        refreshTeams={() => this.getRanking()}
                        refreshRanking={this.state.refreshRanking}
                      />
                    )}
                    {/* {this.state.competition_type === "knockout" && (
                      <KnockoutDiagram />
                    )} */}
                    {this.state.competition_type === "group-stage" &&
                      this.state.teamsPerGroups.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            className="group-container"
                            id={"group-container-" + index}
                          >
                            <div className="group-title">
                              {"Group " + elem.group.label}
                            </div>
                            <CompetitionRanking
                              competitionInfo={this.state.competitionInfo}
                              typeGroup={
                                this.state.competition_type === "group-stage"
                              }
                              teams={elem.teams}
                              updateCompetitionDetails={() => {
                                this.getRanking();
                                if (
                                  this.state.competition_type === "group-stage"
                                ) {
                                  this.getCompetitionDetails();
                                }
                              }}
                              getMatches={() => {
                                this.getMatches();
                              }}
                              hasAccessToEditCompetition={
                                this.state.competition_id_club ==
                                  this.props.currentUser.id_club ||
                                user_permissions?.[permissions.CLUB]?.[
                                  permissions.COMPETITION
                                ]?.[permissions.EDIT_COMMON_COMPETITION]
                              }
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <Dialog open={this.state.addTeamToGroupPopup}>
                <DialogContent className="add-team-manual-wrapper">
                  <AddTeamToGroup
                    id_competition={this.props.match.params.id}
                    closePopup={() =>
                      this.setState({ addTeamToGroupPopup: false })
                    }
                    getTeams={() => {
                      this.getRanking();
                      if (this.state.competition_type === "group-stage") {
                        this.getCompetitionDetails();
                      }
                    }}
                    getMatches={() => {
                      this.getMatches();
                    }}
                    teams={this.state.teamsInCompetition}
                    groups={this.state.groups}
                  />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  user_permissions: auth?.user?.user_permissions,
}))(CompetitionPage);
