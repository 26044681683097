export default {
    DASHBOARD: "DASHBOARD",
    TEAM: "TEAM",
    CALENDAR: "CALENDAR",
    MATCH: "MATCH",
    MATCH_COSTS: "MATCH_COSTS",
    COMPETITION: "COMPETITION",
    EDIT_COMMON_COMPETITION: "EDIT_COMMON_COMPETITION",
    VENUE: "VENUE",
    CARDS_MANAGEMENT: "CARDS_MANAGEMENT",
    GOALS_STATS: "GOALS_STATS",
    PRESS: "PRESS",
    FRF_LIST: "FRF_LIST",
    ENROLMENT_REQUEST: "ENROLMENT_REQUEST",

    CLUB: "CLUB",

    TRAINING: "TRAINING",
    TRAINING_PLANING: "TRAINING_PLANING",
    TRAINING_HISTORY: "TRAINING_HISTORY",
    WEIGHT_CONTROL: "WEIGHT_CONTROL",
    TRAINING_SECTION: "TRAINING_SECTION",
    TRAINING_PRESENCE_REPORT: "TRAINING_PRESENCE_REPORT",

    REPORT: "REPORT",
    PHYSICAL_TEST: "PHYSICAL_TEST",
    EVALUATION: "EVALUATION",

    FINANCIAL: "FINANCIAL",
    PAYMENT_REQUEST: "PAYMENT_REQUEST",
    COST: "COST",
    ONLINE_PAYMENT: "ONLINE_PAYMENT",
    NECCESSITY_REPORT: "NECCESSITY_REPORT",
    JUSTIFYING_DOCUMENT: "JUSTIFYING_DOCUMENT",
    APPROVE_NECESSITY_REPORT: "APPROVE_NECESSITY_REPORT",
    RECEIPT: "RECEIPT",
    IMPORT_FROM_STATEMENT: "IMPORT_FROM_STATEMENT",
    BONUS_MONITORING: "BONUS_MONITORING",
    BUDGET: "BUDGET",
    PAYMENT_MANAGEMENT: "PAYMENT_MANAGEMENT",
    FINANCIAL_REPORT: "FINANCIAL_REPORT",

    MEDICAL: "MEDICAL",
    INJURY: "INJURY",

    DOCUMENT: "DOCUMENT",
    CONTRACT_PLAYER: "CONTRACT_PLAYER",
    CONTRACT_STAFF: "CONTRACT_STAFF",

    FAN: "FAN",
    FANS_LIST: "FANS_LIST",
    FANS_IMPORT: "FANS_IMPORT",
    FANS_CAMPAIGN: "FANS_CAMPAIGN",
    FANS_SOCIAL_MEDIA: "FANS_SOCIAL_MEDIA",
    TAX_REDIRECTION: "TAX_REDIRECTION",

    VIDEOGALLERY: "VIDEOGALLERY",
    VIDEO: "VIDEO",

    SITE: "SITE",
    NEWS: "NEWS",
    ABOUT_US: "ABOUT_US",
    SPONSOR: "SPONSOR",
    PHOTO_VIDEO_GALLERY: "PHOTO_VIDEO_GALLERY",
    CONTACT: "CONTACT",
    SOCIAL_MEDIA_CONNECT: "SOCIAL_MEDIA_CONNECT",
    WEBSITE_SETTINGS: "WEBSITE_SETTINGS",
    TEAMS_SETTINGS: "TEAMS_SETTINGS",
    STAFF_SETTINGS: "STAFF_SETTINGS",
    PRIVACY_POLICY: "PRIVACY_POLICY",

    PARENTS_APP: "PARENTS_APP",
    PARENT_USER: "PARENT_USER",
    NOTIFICATE_USER: "NOTIFICATE_USER",
    MOBILE_APP_NEWS: "MOBILE_APP_NEWS",
    DOC_TO_SIGN: "DOC_TO_SIGN",

    SHOP: "SHOP",
    PRODUCT: "PRODUCT",
    ORDER: "ORDER",
    CATEGORY: "CATEGORY",
    SIZE: "SIZE",

    CLUB_ADMIN: "CLUB_ADMIN",
    USER_LIST: "USER_LIST",
}