import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";

function StatusUser({
  refreshUsers,
  id_user_status,
  closePopup,
  isActive,
  token,
  languagePack,
}) {
  function statusUser() {
    const payload = {
      id_user: id_user_status,
      token: token,
      active: isActive ? "0" : "1",
    };

    axios.post("user/change_user_active_state", payload).then((resp) => {
      if (resp.data.success == 1) {
        store.addNotification({
          title: languagePack.users_management,
          message: isActive
            ? languagePack.inactive_user_success
            : languagePack.active_user_success,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
        refreshUsers();
      }
      if (resp.data.success == 0) {
        store.addNotification({
          title: languagePack.users_management,
          message: isActive
            ? languagePack.active_user_danger
            : languagePack.inactive_user_danger,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
    });

    closePopup();
  }

  return (
    <div className="delete-user-popup-container">
      <div className="delete-user-header">
        <div className="delete-user-title">
          {isActive ? languagePack.disable_user : languagePack.activate_user}
        </div>

        <TFBIconButton
          name="x-small"
          color="darkGray"
          onClick={() => closePopup()}
        />
      </div>
      <div className="delete-user-text">
        {isActive
          ? languagePack.disable_user_confirmation
          : languagePack.activate_user_confirmation}
      </div>
      <div className="delete-user-buttons">
        <TFBButton
          endIcon={true}
          color="darkGray"
          onClick={() => closePopup()}
          renderIcon={() => <TFBIcon name="cancel" />}
          style={{ width: "100%" }}
        >
          {languagePack.no}
        </TFBButton>

        <TFBButton
          color="green"
          endIcon={true}
          onClick={() => statusUser()}
          renderIcon={() => <TFBIcon name="check" />}
          style={{ width: "100%" }}
        >
          {languagePack.yes}
        </TFBButton>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(StatusUser);
