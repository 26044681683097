import { Table } from "@material-ui/core";
import { TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useContext, useEffect, useReducer } from "react";
import styles from "./TrainingDataTable.module.scss";
import {
  TFBTableCell,
  sxStickyColumn,
  sxStickyRow,
} from "../../../components/reusable/useStyles";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import _ from "lodash";
import moment from "moment";

import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import { TrainingDataContext } from "./TrainingData";
import CircularLoader from "../../../components/reusable/Loader";

function TrainingDataTable({ languagePack }) {
  const history = useHistory();
  const { loadingKSportData, kSportData } = useContext(TrainingDataContext);

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      team: {},
      players: kSportData?.players ?? [],

      order_by: null,
      order_type: null,
    }
  );

  const handleFilterBy = (filter_by, filter_type) => {
    const filter_use_cases = {
      player_name: {
        field_name: "player_name",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      age: {
        field_name: "age",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      minute: {
        field_name: "total_distance",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      goal: {
        field_name: "goal",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      assist: {
        field_name: "assist",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      order_by_position: {
        field_name: "order_by_position",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
    };

    let sortedArray = _.sortBy(state.players, [
      filter_use_cases[filter_by]["field_name"],
    ]);

    if (filter_use_cases[filter_by][filter_type] == "desc") {
      sortedArray = _.reverse(sortedArray);
    }

    setState({
      order_by: filter_use_cases[filter_by]["field_name"],
      order_type: filter_use_cases[filter_by][filter_type],
      players: sortedArray,
    });
  };

  useEffect(() => {
    setState({ players: kSportData?.players });
  }, [kSportData]);

  return (
    <>
      {loadingKSportData ? (
        <CircularLoader />
      ) : (
        <TableContainer style={{ maxHeight: "75vh" }}>
          <Table faria-label="simple table">
            <TableHead>
              <TableRow sx={sxStickyRow}>
                <TFBTableCell sx={sxStickyColumn} align="left">
                  <div
                    className={styles.headerCell}
                    onClick={() =>
                      handleFilterBy("player_name", state.order_type)
                    }
                  >
                    <div className={styles.headerLable}>
                      {languagePack.PLAYER_NAME}
                    </div>
                    <div className={styles.headerFilter}>
                      {!(
                        state.order_by === "player_name" &&
                        state.order_type === "desc"
                      ) && <TFBIcon name="angle-up" color="lightGray" />}
                      {!(
                        state.order_by === "player_name" &&
                        state.order_type === "asc"
                      ) && <TFBIcon name="angle-down" color="lightGray" />}
                    </div>
                  </div>
                </TFBTableCell>
                {_.map(kSportData?.params, (el, i) => (
                  <TFBTableCell key={i} align="center">
                    {el.param_name}
                    {el.unit ? `${" (" + el.unit + ")"}` : ""}
                  </TFBTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.players?.map((player, index) => (
                <TableRow
                  key={"player" + index}
                  sx={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  }}
                >
                  <TFBTableCell
                    sx={sxStickyColumn}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                    }}
                    align="left"
                  >
                    {" "}
                    <TFBPlayerWithAvatar
                      playerName={
                        _.find(
                          kSportData?.players,
                          (plr) =>
                            Number(plr.id_player) === Number(player.id_player)
                        )?.player?.data["player_name"]
                      }
                      avatarUrl={
                        _.find(
                          kSportData?.players,
                          (plr) =>
                            Number(plr.id_player) === Number(player.id_player)
                        )?.player?.data["img_url"]
                      }
                      onClick={() =>
                        history.push(`/club/player/${player.id_player}`)
                      }
                    />
                  </TFBTableCell>
                  {_.map(player?.params, (param, i) => (
                    <TFBTableCell key={i} align="center">
                      {moment(param.value, "HH:mm:ss", true).isValid()
                        ? param.value.toLocaleString()
                        : Number(
                            _.replace(param.value, ",", ".")
                          ).toLocaleString()}
                    </TFBTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(TrainingDataTable);
