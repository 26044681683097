import { useEffect, useRef } from 'react';

const useInfiniteScroll = ({ refetch }) => {
    const loaderRef = useRef(null);
    const observerRef = useRef();

    useEffect(() => {
        if (observerRef?.current) {
            observerRef.current.disconnect();
        }
        const observer = new IntersectionObserver((entries) => {
            const target = entries[0];
            if (target.isIntersecting) {
                refetch();
            }
        });
        observerRef.current = observer;
        if (loaderRef?.current) {
            observer.observe(loaderRef.current);
        }
        return () => {
            if (loaderRef?.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [refetch, loaderRef]);
    return { loaderRef };
};

export default useInfiniteScroll;