import React from 'react';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  display:flex;
  justify-content:space-between;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: ${props =>
        props.isDragDisabled
            ? 'lightgrey'
            : props.isDragging
                ? 'lightgreen'
                : '#D1D1D1'};
`

export default class Param extends React.Component {
    render() {
        return (
            <Draggable
                draggableId={this.props.param.id}
                index={this.props.index}
            >
                {(provided, snapshot) => (
                    <Container
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                    >
                        <div>{this.props.param.content}</div><div>{this.props.param.um}</div>
                    </Container>
                )}
            </Draggable>
        )
    }
}