import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { bindActionCreators } from "redux";

import { updateQuickSetup } from "./../../utils/api";
import { showNotification, useAxiosPost } from "../../components/customHooks/useAxiosPost";
import useRenderButton from "../../components/customHooks/useRenderButton";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import { isTFB } from "../../utils/utilFunctions";

const DeleteTraining = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, training, currentUser, updateQuickSetup } = props;

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("training/delete_training_session");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.training_add_title,
                languagePack.training_session_successfully_deleted
            );
            let clubQuickSetup = response?.club_quick_setup;
            updateQuickSetup({
                ...clubQuickSetup,
                isSetupOpen: false,
            });
        } else {
            showNotification(
                "danger",
                languagePack.training_add_title,
                languagePack.training_session_failed_deleted
            );
        }
    };

    const deleteTraining = () => {
        const payload = {
            id_training: training.id_training,
            id_club: currentUser.id_club,
            is_tfb: isTFB(),
        };
        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteTraining(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_training"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_training_text}
            </div>
        </TFBDialog>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateQuickSetup,
        },
        dispatch
    );
}

export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
    }),
    mapDispatchToProps
)(DeleteTraining);
