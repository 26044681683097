import React, { CSSProperties, useRef } from "react";
import styles from "./TFBCheckbox.module.scss";
import { TCheckbox } from "../types";

const TFBCheckbox = ({
  onChange,
  label,
  style,
  startLabel,
  checked = false,
  disabled = false,
  className,
  labelColor,
  mode = "dark",
}: TCheckbox) => {
  const color = labelColor ? labelColor : "#b2b2b2";

  const labelStyle: CSSProperties = {
    color: color,
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Poppins",
    margin: 0,
    cursor: "pointer"
  };

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={`${styles.tfbCheckboxWrapper} ${className ? className : ""}`}
      style={{ flexDirection: startLabel ? "row-reverse" : "row", ...style }}
    >
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        ref={inputRef}
        className={mode == "light" ? styles.light : styles.dark}
        {...!disabled && { onChange: onChange }}
      />

      <label
        className={`${styles.tfbCheckboxLabel}`}
        style={labelStyle}
        {...!disabled && { onClick: () => inputRef.current && inputRef.current.click() }}
      >
        {label}
      </label>
    </div>
  );
};

export default TFBCheckbox;
