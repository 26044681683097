import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";

const DeleteMatchEvent = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, event, eventType = "default" } = props;

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("matches/delete_event_3");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.fixtures_schedule,
                languagePack.delete_match_event_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.fixtures_schedule,
                languagePack.delete_match_event_fail
            );
        }
    };

    const deleteEvent = () => {
        const payload = {
            id_event: event.id_event,
            event_type: eventType,
        };
        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteEvent(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_match_event"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.sure_delete_event}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(DeleteMatchEvent);
