import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import styles from "./Match.module.scss";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import CurrencyPicker from "../../../Financial/PaymentRequest/CurrencyPicker";

const AddMatchEvent = (props) => {
  const {
    openPopup,
    closePopup,
    refreshList,
    languagePack,
    teams,
    players,
    matchDetails,
    staff,
  } = props;

  const { minutes_per_time, id_match } = matchDetails;

  const goalCategory = [
    { value: "free_kick" },
    { value: "cross" },
    { value: "direct_play" },
    { value: "combination_play" },
    { value: "counterattack" },
    { value: "penalty" },
    { value: "individual_action" },
    { value: "opposite_third_recovery" },
    { value: "none" },
  ];

  const eventTypes = [
    { value: "yellow_card", db: "Yellow card" },
    { value: "red_card", db: "Red card" },
    { value: "substitution", db: "Substitution" },
    { value: "goal", db: "Goal" },
  ];

  const goalTypes = [
    { value: "single_goal" },
    { value: "goal_assist" },
    { value: "autogoal" },
  ];

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      minute: "",
      team: null,
      event_type: null,
      player: null,
      card_receiver_type: "player",
      staff_member: null,
      player_assist: null,
      goal_type: null,
      goal_category: null,
      half: 1,
    }
  );

  const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost(
    "matches/add_manual_match_event_2"
  );

  const handleResponse = (response) => {
    if (response?.success == 1) {
      closePopup();
      refreshList();
      showNotification(
        "success",
        languagePack.fixtures_schedule,
        languagePack.event_successfully_added
      );
    } else {
      showNotification(
        "danger",
        languagePack.fixtures_schedule,
        languagePack.event_fail_added
      );
    }
  };

  const addEvent = () => {
    let minute = _.toInteger(state.minute);
    const time_duration = _.toInteger(minutes_per_time);

    if (state.half == 1) {
      if (minute > time_duration) {
        minute = time_duration;
      }
      if (minute < 1) {
        minute = 1;
      }
    }

    if (state.half == 2) {
      if (minute > 2 * time_duration) {
        minute = 2 * time_duration;
      }
      if (minute < time_duration) {
        minute = time_duration + 1;
      }
    }

    const payload = {
      id_match: id_match,
      action_name: state.event_type?.db ?? null,
      minute: state.half == 1 ? minute : minute - time_duration,
      half: state.half,

      id_player: state.player?.value ?? null,
      player_name: state.player?.label ?? null,
      id_staff: state.staff_member?.value ?? null,
      staff_name: state.staff_member?.label ?? null,
      id_opponent: state.player_assist?.value ?? null,
      opponent_name: state.player_assist?.label ?? null,
      id_team: state.team?.own_team_id ?? null,
      id_manual_competition_team: state.team?.value ?? null,
      action_category: state.goal_category?.value ?? null,
      autogoal:
        _.size(
          _.intersection(
            ["autogoal"],
            _.map(state.goal_type ? [state.goal_type] : [], (e) => e.value)
          )
        ) > 0
          ? 1
          : 0,
    };

    addRequest(payload, handleResponse);
  };

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const isValidForm = () => {
    if (
      _.size(_.trim(state.minute)) == 0 ||
      _.size(_.trim(state.half)) == 0 ||
      state.team == null ||
      state.event_type == null
    ) {
      return false;
    } else {
      if (
        _.size(
          _.intersection(
            ["yellow_card", "red_card"],
            _.map(state.event_type ? [state.event_type] : [], (e) => e.value)
          )
        ) > 0
      ) {
        if (
          state.team?.own_team_id != null &&
          state.player == null &&
          state.staff_member == null
        ) {
          return false;
        }
      }
      if (
        _.size(
          _.intersection(
            ["substitution"],
            _.map(state.event_type ? [state.event_type] : [], (e) => e.value)
          )
        ) > 0
      ) {
        if (state.player == null || state.player_assist == null) {
          return false;
        }
      }
      if (
        _.size(
          _.intersection(
            ["goal"],
            _.map(state.event_type ? [state.event_type] : [], (e) => e.value)
          )
        ) > 0
      ) {
        if (state.goal_type == null) {
          return false;
        } else {
          if (state.goal_category == null) {
            return false;
          } else {
            if (state.team?.own_team_id == null) {
              return true;
            } else {
              if (
                _.size(
                  _.intersection(
                    ["goal_assist"],
                    _.map(
                      state.goal_type ? [state.goal_type] : [],
                      (e) => e.value
                    )
                  )
                ) > 0
              ) {
                return state.player != null && state.player_assist != null;
              } else {
                return state.player != null;
              }
            }
          }
        }
      }
    }
    return true;
  };

  const { renderBtn: BtnRight } = useRenderButton(
    () => addEvent(),
    "green",
    "add",
    languagePack.admin_add,
    {},
    true,
    !isValidForm(),
    loadingAddRequest
  );

  const handleSelectTeam = (selected) => {
    updateState({
      team: selected,
      event_type: null,
      player: null,
      player_assist: null,
      goal_type: null,
      goal_category: null,
    });
  };

  const handleSelectEventType = (selected) => {
    updateState({
      event_type: selected,
      player: null,
      player_assist: null,
      goal_type: null,
      goal_category: null,
    });
  };

  const handleSelectPlayer = (selected) => {
    updateState({
      player: selected,
    });
  };
  const handleSelectStaffMember = (selected) => {
    updateState({
      staff_member: selected,
    });
  };

  const handleSelectPlayerAssist = (selected) => {
    updateState({
      player_assist: selected,
    });
  };

  const handleSelectGoalType = (selected) => {
    updateState({
      goal_type: selected,
      goal_category: null,
      player: null,
      player_assist: null,
    });
  };

  const handleSelectGoalCategory = (selected) => {
    updateState({
      goal_category: selected,
    });
  };

  return (
    <TFBDialog
      className={`pop-up-dialog-box prevent-select-text ${styles.addEvent}`}
      title={languagePack["add_event"]}
      open={openPopup}
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <TFBInput
        label={languagePack.event_minute}
        value={state.minute}
        onChange={(e) => updateState({ minute: e.target.value })}
        style={{ marginBottom: 20, width: "100%" }}
        type="number"
      />
      <div className={styles.halfTypeDiv}>
        <TFBCheckbox
          checked={state.half == 1}
          label={languagePack.first_half}
          onChange={() => updateState({ half: 1 })}
        />
        <TFBCheckbox
          checked={state.half == 2}
          label={languagePack.second_half}
          onChange={() => updateState({ half: 2 })}
        />
      </div>
      <TFBSelect
        label={languagePack.select_team}
        value={state.team}
        options={teams}
        onChange={handleSelectTeam}
        style={{ component: { marginBottom: 20 } }}
        isClearable={true}
      />
      {state.team && (
        <TFBSelect
          label={languagePack.select_event_type}
          value={state.event_type}
          options={_.map(
            _.filter(eventTypes, (e) =>
              state.team?.own_team_id != null ? true : e.value != "substitution"
            ),
            (e) => ({ ...e, label: languagePack[e.value] })
          )}
          onChange={handleSelectEventType}
          style={{ component: { marginBottom: 20 } }}
          isClearable={true}
        />
      )}
      {state.team &&
        state.event_type &&
        state.team?.own_team_id != null &&
        _.size(
          _.intersection(
            ["yellow_card", "red_card"],
            _.map(state.event_type ? [state.event_type] : [], (e) => e.value)
          )
        ) > 0 && (
          <div style={{ display: "flex", gap: "12px" }}>
            {state.card_receiver_type === "player" ? (
              <TFBSelect
                label={languagePack.select_player}
                value={state.player}
                options={players[state.team?.value] ?? []}
                onChange={handleSelectPlayer}
                style={{ component: { marginBottom: 20 } }}
                isClearable={true}
              />
            ) : (
              <TFBSelect
                label={languagePack.select_staff_member}
                value={state.staff_member}
                options={staff ?? []}
                onChange={handleSelectStaffMember}
                style={{ component: { marginBottom: 20 } }}
                isClearable={true}
              />
            )}
            <CurrencyPicker
              onClick={(e) =>
                updateState({
                  card_receiver_type:
                    state.card_receiver_type === "player" ? "staff" : "player",
                  player: null,
                  staff_member: null,
                })
              }
              checked={state.card_receiver_type === "player"}
              name={languagePack.player}
            />
            <CurrencyPicker
              onClick={(e) =>
                updateState({
                  card_receiver_type:
                    state.card_receiver_type === "player" ? "staff" : "player",
                  player: null,
                  staff_member: null,
                })
              }
              checked={state.card_receiver_type === "staff"}
              name={languagePack.staff}
            />
          </div>
        )}

      {state.team &&
        state.event_type &&
        state.team?.own_team_id != null &&
        _.size(
          _.intersection(
            ["substitution"],
            _.map(state.event_type ? [state.event_type] : [], (e) => e.value)
          )
        ) > 0 && (
          <>
            <TFBSelect
              label={languagePack.select_player_out}
              value={state.player}
              options={players[state.team?.value] ?? []}
              onChange={handleSelectPlayer}
              style={{ component: { marginBottom: 20 } }}
              isClearable={true}
            />
            <TFBSelect
              label={languagePack.select_player_in}
              value={state.player_assist}
              options={players[state.team?.value] ?? []}
              onChange={handleSelectPlayerAssist}
              style={{ component: { marginBottom: 20 } }}
              isClearable={true}
            />
          </>
        )}
      {state.team &&
        state.event_type &&
        _.size(
          _.intersection(
            ["goal"],
            _.map(state.event_type ? [state.event_type] : [], (e) => e.value)
          )
        ) > 0 && (
          <>
            <TFBSelect
              label={languagePack.select_goal_type}
              value={state.goal_type}
              options={_.map(goalTypes, (e) => ({
                ...e,
                label: languagePack[e.value],
              }))}
              onChange={handleSelectGoalType}
              style={{ component: { marginBottom: 20 } }}
              isClearable={true}
            />
            {((state.team?.own_team_id != null && state.goal_type) ||
              state.team?.own_team_id == null) && (
              <TFBSelect
                label={languagePack.select_goal_category}
                value={state.goal_category}
                options={_.map(goalCategory, (e) => ({
                  ...e,
                  label: languagePack[e.value],
                }))}
                onChange={handleSelectGoalCategory}
                style={{ component: { marginBottom: 20 } }}
                isClearable={true}
              />
            )}
            {state.goal_type && state.team?.own_team_id != null && (
              <>
                <TFBSelect
                  label={languagePack.select_scoring_player}
                  value={state.player}
                  options={players[state.team?.value] ?? []}
                  onChange={handleSelectPlayer}
                  style={{ component: { marginBottom: 20 } }}
                  isClearable={true}
                />
                {_.size(
                  _.intersection(
                    ["goal_assist"],
                    _.map(
                      state.goal_type ? [state.goal_type] : [],
                      (e) => e.value
                    )
                  )
                ) > 0 && (
                  <TFBSelect
                    label={languagePack.select_assisting_player}
                    value={state.player_assist}
                    options={players[state.team?.value] ?? []}
                    onChange={handleSelectPlayerAssist}
                    style={{ component: { marginBottom: 20 } }}
                    isClearable={true}
                  />
                )}
              </>
            )}
          </>
        )}
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(AddMatchEvent);
