import React, { useContext, useState } from "react";
import "./FunctionalityRequest.scss";
import useRenderButton from "../../../../../../../app/components/customHooks/useRenderButton";
import TFBTextArea from "../../../../../../../app/components/design-system/TextArea/TFBTextArea";
import {
  showNotification,
  useAxiosPost,
} from "../../../../../../../app/components/customHooks/useAxiosPost";
import { NewFunctionalityContext } from "../../../SubHeader";
import { connect } from "react-redux";

const REQUEST_FUNCTIONALITY_ROUTE = "/admin/request_functionality";

const FunctionalityRequest = ({ languagePack, ...props }) => {
  const { userId } = useContext(NewFunctionalityContext);
  const [text, setText] = useState("");

  // ============== CUSTOM HOOKS ========================
  // ====================================================
  const { renderBtn: CancelBtn } = useRenderButton(
    () => setText(""),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: SendRequestBtn } = useRenderButton(
    () => sendRequest(),
    "green",
    "check",
    languagePack.send_request,
    {},
    true,
    text == ""
  );

  const { postData: postRequest } = useAxiosPost(REQUEST_FUNCTIONALITY_ROUTE);

  // ============== HANDLERS ========================
  // ====================================================

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  // ============== POST REQUEST ========================
  // ====================================================
  const sendRequest = () => {
    const { full_name: user_name, club } = props.currentUser;
    const { club_name } = club;

    if (text) {
      const payload = {
        description: text,
        id_user: userId,
        user_name: user_name,
        club_name: club_name,
      };

      postRequest(payload, handleResponse);
    }
  };

  const handleResponse = (response) => {
    if (response == "1") {
      showNotification(
        "success",
        languagePack.functionality_request,
        languagePack.functionality_request_success
      );
      setText("");
      return;
    }
    showNotification(
      "danger",
      languagePack.functionality_request,
      languagePack.functionality_request_danger
    );
  };

  return (
    <div className="functionality-request-container">
      <span className="title ds-headline-2">
        {languagePack.functionality_request}
      </span>

      <TFBTextArea
        onChange={handleChangeText}
        value={text}
        label={languagePack.your_message}
        placeholder={languagePack.describe_functionality}
      />

      <div className="request-buttons">
        <CancelBtn />
        <SendRequestBtn />
      </div>
    </div>
  );
};

export default connect(({ auth }) => ({
  currentUser: auth.user,
}))(FunctionalityRequest);
