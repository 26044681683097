import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import styles from "./FinancialJustifyingDocument.module.scss";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";

const EditJustifyingDocument = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, document } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            document_title: document?.title ?? "",
            document_type: document?.type ? { value: document.type, label: languagePack?.[document.type] } : null,
            date_type: document?.date_end ? "period_of_time" : "single_date", // single_date/period_of_time
            date_start: document?.date_start ?? null,
            date_end: document?.date_end ?? null,
            provider: document?.provider ?? "",
            details: document?.details ?? "",
        }
    );

    const { postData: editRequest, loading: loadingEditRequest } = useAxiosPost("finances/edit_justifying_document");

    const isValidEditDocument = () => {
        return _.size(_.trim(state.document_title)) > 0 && state.document_type != null && (state.date_type == "period_of_time" ? state.date_start != null && state.date_end != null : state.date_start != null) && (state.document_type?.value != "transportation_table" ? _.size(_.trim(state.provider)) > 0 : true)
    }

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.justifying_documents,
                languagePack.success_edit_justifying_document
            );
        } else {
            showNotification(
                "danger",
                languagePack.justifying_documents,
                languagePack.fail_edit_justifying_document
            );
        }
    };

    const editDocument = () => {
        const payload = {
            id_document: document.id_document,
            title: state.document_title,
            type: state.document_type?.value,
            provider: state.provider == "" ? null : state.provider,
            date_start: state.date_start,
            date_end: state.date_end,
            details: state.details,
        };

        editRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => editDocument(),
        "green",
        "add",
        languagePack.admin_save,
        {},
        true,
        !isValidEditDocument(),
        loadingEditRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["edit_justifying_document"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className={styles.stepTitle}>{languagePack.document_details}</div>
            <TFBInput
                label={languagePack.document_title}
                value={state.document_title}
                onChange={(e) => updateState({ document_title: e.target.value })}
                style={{ width: "100%", marginBottom: 20 }}
            />
            <TFBSelect
                label={languagePack.document_type}
                value={state.document_type}
                options={_.map(["accommodation_chart", "transportation_table", "meal_time"], e => ({ value: e, label: languagePack?.[e] }))}
                onChange={selected => updateState({ document_type: selected, provider: selected?.value == "transportation_table" ? "" : state.provider })}
                style={{ component: { marginBottom: 20 } }}
            />
            <div className={styles.documentTypeContainer}>
                <div className={styles.documentTypeList}>
                    <TFBCheckbox
                        checked={state.date_type == "single_date"}
                        label={languagePack.single_date}
                        onChange={(e) => updateState({ date_type: "single_date", date_end: null })}
                    />
                    <TFBCheckbox
                        checked={state.date_type == "period_of_time"}
                        label={languagePack.period_of_time}
                        onChange={(e) => updateState({ date_type: "period_of_time" })}
                    />
                </div>
            </div>
            <div className="df df-g20">
                <TFBDatePicker
                    value={state.date_start}
                    onChange={(value) => updateState({ date_start: value })}
                    label={languagePack["start_date"]}
                    style={{ marginBottom: 20 }}
                />
                {state.date_type == "period_of_time" &&
                    <TFBDatePicker
                        value={state.date_end}
                        onChange={(value) => updateState({ date_end: value })}
                        label={languagePack["end_date"]}
                        style={{ marginBottom: 20 }}
                    />
                }
            </div>
            {state.document_type?.value != "transportation_table" &&
                <TFBInput
                    label={languagePack.provider}
                    value={state.provider}
                    onChange={(e) => updateState({ provider: e.target.value })}
                    style={{ width: "100%", marginBottom: 20 }}
                />
            }
            <TFBTextArea
                label={languagePack["document_details"]}
                value={state.details}
                onChange={(e) => updateState({ details: e.target.value })}
            />
        </TFBDialog>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(EditJustifyingDocument);