import React, { useContext } from "react";
import TFBButton from "../../../../../../../app/components/design-system/Button/TFBButton";
import "./NewsItem.scss";
import useRenderButton from "../../../../../../../app/components/customHooks/useRenderButton";
import { useHistory } from "react-router-dom";
import { NewFunctionalityContext } from "../../../SubHeader";

const NewsItem = ({ functionality, languagePack, currentLang }) => {
  const { closeNewsPopup } = useContext(NewFunctionalityContext);
  const history = useHistory();

  const { renderBtn: TryNowBtn } = useRenderButton(
    () => {
      closeNewsPopup();
      history.push(functionality.path);
    },
    "green",
    "angle-right",
    languagePack.try_now
  );

  const functionaliyTitle =
    languagePack[functionality.title] ||
    functionality[`title_slug_${currentLang}`];
  const functionaliyDescription =
    languagePack[functionality.description] ||
    functionality[`slug_${currentLang}`];

  return (
    <div className="news-item-container">
      <span className="date ds-secondary-text-auxiliary">
        {functionality.date_add}
      </span>
      <span className="title ds-headline-2">{functionaliyTitle}</span>
      <span className="description ds-main-text-auxiliary">
        {functionaliyDescription}
      </span>
      <TryNowBtn />
    </div>
  );
};

export default NewsItem;
