import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Icon, TextArea } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import "../MedicalCertificates/MedicalCertificates.scss";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

function AddLicenseFile(props) {
  const [file, setFile] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const params = useParams();

  const { languagePack } = props;

  function handleChangeFile(event) {
    setFile(event.target.files[0]);
  }

  function submitNewFile() {
    setIsFetching(true);
    const data = new FormData();
    data.append("id_staff", params.id);
    data.append("avatar", file);

    axios.post(`/club/add_staff_license_document`, data).then((res) => {
      props.closePopup();
      store.addNotification({
        title: "Document operation",
        message: "Fișierul cu licența a fost adăugat cu succes!",

        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  }

  return (
    <>
      {!isFetching ? (
        <div className="add-team-container">
          <div className="add-team-header">
            <div className="add-team-title">{languagePack.add_certificate}</div>
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => props.closePopup()}
            />
          </div>

          <div className="add-team-row">
            <div className="image-input-container-photos">
              <input type="file" id="customImage" onChange={handleChangeFile} />
              <label htmlFor="customImage">
                {file == ""
                  ? languagePack.gallery_upload_select_file
                  : file.name}
              </label>
              <Icon name="arrow up" size="small" />
            </div>
          </div>
          <div className="add-team-buttons">
            <Button
              className="custom-button-dual add-button"
              onClick={submitNewFile}
              disabled={file == null || file == "undefined" || file == ""}
            >
              {" "}
              <div className="button-content">
                {languagePack["admin_add"]}
                <Icon name="add" className="add-icon" />
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loader
            type="TailSpin"
            color={window.clubCustomization.primary_color}
            height={50}
            width={50}
          />
        </div>
      )}
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddLicenseFile);
