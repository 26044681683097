import React, { createContext, useContext, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { useLocation, useHistory } from "react-router";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import { currencies } from "../../../utils/constants";

export const MatchesContext = createContext();

const MatchesProvider = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      activeItem: new URLSearchParams(location.search).get("type"),
      teams_dropdown: [],
      rounds_dropdown: [],
      competitions_dropdown: [],
      selected_team: "",
      selected_round: "",
      selected_competition: "",
      addMatchPopup: false,
      refreshTimes: 0,

      page: 1,
      limit: 20,
      has_more: true,
      matches: [],
    }
  );

  const {
    data: teamsDropdownData,
    loading: loadingTeamsDropdown,
  } = useAxiosGet(
    `club/get_teams_dropdown?token=${props.token}&id_club=${props.currentUser.id_club}&all=1&id_user=${props.currentUser.id_user}`
  );

  useEffect(() => {
    if (_.get(history, "location.state.redirectFromSetup")) {
      history.replace({
        ...history.location,
        state: undefined,
      });

      setState({ addMatchPopup: true });
    }
  }, [location]);

  const {
    data: matchesData,
    loading: loadingMatches,
    refetch: refetchMatches,
  } = useAxiosGet(
    `matches/get_matches_all_footbal_api_3?id_team=${state.selected_team}&type=${state.activeItem}&currency=${props.currencyItemSelected.uniqueSlug}&token=${props.token}&limit=${state.limit}&page=${state.page}`,
    true
  );
  useEffect(() => {
    refreshList();
  }, [state.activeItem, state.selected_team]);

  useEffect(() => {
    if (matchesData) {
      if (_.size(matchesData) > 0) {
        if (Number(state.page) === 1) {
          setState({ page: state.page + 1, matches: matchesData });
        } else {
          setState({
            page: state.page + 1,
            matches: [...state.matches, ...matchesData],
          });
        }
      } else {
        if (Number(state.page) === 1) {
          setState({ matches: [], has_more: false });
        } else {
          setState({ has_more: false });
        }
      }
    }
  }, [matchesData]);

  const refreshList = () => {
    setState({ matches: [], page: 1, has_more: true });
  };

  useEffect(() => {
    let teamsDropdown = teamsDropdownData;
    teamsDropdown = _.map(teamsDropdown, (team) => {
      return {
        ...team,
        text: props.languagePack.dropdown[team.text] || team.text,
      };
    });

    setState({
      teams_dropdown: Array.isArray(teamsDropdown) ? teamsDropdown : [],
      selected_team: teamsDropdown.length > 0 ? teamsDropdown[0]["value"] : "",
    });
  }, [teamsDropdownData]);

  const handleChangeDropdown = (e, value) => {
    setState({ [value.name]: value.value });
  };

  const handleSetActivePast = () => {
    setState({ activeItem: "past" });
    let url = location.search;
    let search_params = new URLSearchParams(url);
    search_params.set("type", "past");
    window.history.replaceState(null, null, "?" + search_params.toString());
  };

  const handleSetActiveFuture = () => {
    setState({ activeItem: "future" });
    let url = location.search;
    let search_params = new URLSearchParams(url);
    search_params.set("type", "future");
    window.history.replaceState(null, null, "?" + search_params.toString());
  };

  return (
    <MatchesContext.Provider
      value={{
        ...state,
        ...props,
        refetchMatches,
        loadingMatches,
        onRefreshList: refreshList,
        onChangeDropdown: handleChangeDropdown,
        onSetActivePast: handleSetActivePast,
        onSetActiveFuture: handleSetActiveFuture,
        onGetMatches: () => setState({ refreshTimes: state.refreshTimes + 1 }),
        onCloseAddMatchForm: () => setState({ addMatchPopup: false }),
        onAddMatchPopup: () => setState({ addMatchPopup: true }),
      }}
    >
      {props.children}
    </MatchesContext.Provider>
  );
};

export const useMatches = () => {
  const context = useContext(MatchesContext);
  if (context === undefined) {
    console.error("MatchesContext was used outside of the MatchesProvider");
    throw new Error("MatchesContext was used outside of the MatchesProvider");
  }
  return context;
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyItemSelected: currencies[auth?.user?.currency] || currencies.EUR,
}))(MatchesProvider);
