import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from 'react-redux';

function MatchLineup(props) {
  const [playersHome, setPlayersHome] = useState([]);
  const [playersAway, setPlayersAway] = useState([]);
  const [playersHomeSubs, setPlayersHomeSubs] = useState([]);
  const [playersAwaySubs, setPlayersAwaySubs] = useState([]);
  const [teamHome, setTeamHome] = useState({});
  const [teamAway, setTeamAway] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const { languagePack } = props;

  useEffect(() => {
    axios
      .get(`rapidapi/get_match_lineup?id_match=${props.id_match}`)
      .then((res) => {
        setPlayersHome(res.data.players_home);
        setPlayersAway(res.data.players_away);
        setPlayersHomeSubs(res.data.team_home_substitute);
        setPlayersAwaySubs(res.data.team_away_substitute);
        setTeamHome(res.data.team_home);
        setTeamAway(res.data.team_away);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card card-custom"
    // style={{ marginTop: 25 }}
    >
      {isLoaded &&
        <div className="match-lineup-container-instat">
          <div className="match-lineup-title">{languagePack['starting_lineup']}</div>
          <div className="match-lineup-details-container">
            <div className="details-team-container" style={{ justifyContent: 'flex-start' }}>
              <div className="logo-container" style={{ marginRight: 20 }}><img src={`https://media.api-sports.io/football/teams/${teamHome.id}.png`} /></div>
              <div className="team-name">{teamHome.name}</div>
            </div>
            <div className="vs">vs</div>
            <div className="details-team-container" style={{ justifyContent: 'flex-end' }}>

              <div className="team-name" style={{ textAlign: "end" }}>{teamAway.name}</div>
              <div className="logo-container" style={{ marginLeft: 20 }}><img src={`https://media.api-sports.io/football/teams/${teamAway.id}.png`} /></div>
            </div>
          </div>
          <div className="match-lineup-list">
            <div style={{ width: "50%" }}>
              {playersHome.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"
                    // style={{}}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white", justifyContent: "flex-start", paddingLeft: 25
                    }}
                  >
                    <div className="number" style={{ marginRight: 20 }}>
                      {item.player_number == null ? "-" : item.player_number}
                    </div>
                    <div className="name">{item.player_name}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "50%" }}>
              {playersAway.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"

                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white", justifyContent: "flex-end", paddingRight: 25
                    }}
                  >
                    <div className="name">{item.player_name}</div>
                    <div className="number" style={{ marginLeft: 20 }}>
                      {item.player_number == null ? "-" : item.player_number}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="substitutes-title">{languagePack['substitutes']}</div>
          <div className="match-lineup-list">
            <div style={{ width: "50%" }}>
              {playersHomeSubs.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"
                    // style={{}}
                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white", justifyContent: "flex-start", paddingLeft: 25
                    }}
                  >
                    <div className="number" style={{ marginRight: 20 }}>
                      {item.player_number == null ? "-" : item.player_number}
                    </div>
                    <div className="name">{item.player_name}</div>
                  </div>
                );
              })}
            </div>
            <div style={{ width: "50%" }}>
              {playersAwaySubs.map((item, index) => {
                return (
                  <div
                    className="player-lineup-row"

                    style={{
                      backgroundColor: index % 2 == 0 ? "#fafafa" : "white", justifyContent: "flex-end", paddingRight: 25
                    }}
                  >
                    <div className="name">{item.player_name}</div>
                    <div className="number" style={{ marginLeft: 20 }}>
                      {item.player_number == null ? "-" : item.player_number}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchLineup);
