import React from "react";
import TFBDialog from "../../../../../app/components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../../../app/components/customHooks/useRenderButton";

function SuspendedAccountPopup({ languagePack, logout, token }) {
  const { renderBtn: BtnCenter } = useRenderButton(
    () => logout(token),
    "green",
    "arrow-up",
    languagePack.back_to_login,
    {},
    false,
    false
  );

  return (
    <TFBDialog
      btnCenter={BtnCenter}
      title={languagePack.account_suspension}
      open
      maxWidth="sm"
      fullWidth
      hideX={true}
    >
      <div
        style={{
          color: "rgb(178, 178, 178)",
          fontFamily: "Poppins",
          fontSize: "1em",
          fontWeight: 700,
        }}
      >
        {languagePack.suspended_account_text}
      </div>
    </TFBDialog>
  );
}

export default SuspendedAccountPopup;
