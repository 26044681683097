import React, { useEffect, useReducer } from "react";
import "./../ClubProfile.scss";
import _ from "lodash";
import styles from "./ExportPlayers.module.scss";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import { baseUrl } from "../../../../utils/utilFunctions";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import CircularLoader from "../../../../components/reusable/Loader";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";

export default function ExportPlayers({
  exportListPopup,
  closePopup,
  languagePack,
  playerData,
  id_club,
  token,
  id_team,
}) {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      filtersList: [],
      allChecked: false,
      showInputNewDataType: false,
      newDataTypeName: "",
      orientation: "L",
    }
  );

  const { data: filterData, loading: loadingFilters } = useAxiosGet(
    baseUrl(`club/get_export_list_fields`)
  );

  // =========== USE EFFECTS =========================

  useEffect(() => {
    let dataArray = filterData?.filter_fields_list.map((item) => {
      return { ...item, checked: false };
    });
    let filtersArray;
    if (filterData)
      filtersArray = [
        { value: "all_filters", label: "Toate filtrele", checked: false },
        ...dataArray,
      ];
    setState({ filtersList: filtersArray });
  }, [filterData]);

  // =========== HANDLERS =========================

  const renderBtn = (position, onClick) => {
    return (
      <TFBButton
        onClick={onClick}
        endIcon={true}
        color={position == "left" ? "darkGray" : "green"}
        renderIcon={() => (
          <TFBIcon name={position == "left" ? "cancel" : "add"} />
        )}
        prettier-ignore
        disabled={
          position == "right" &&
          !state.filtersList?.some((filter) => filter.checked)
        }
      >
        {position == "left" ? languagePack.Cancel : languagePack.export}
      </TFBButton>
    );
  };

  const handleCheckbox = (e, index) => {
    const filterArray = [...state.filtersList];

    if (filterArray[index].value != "all_filters") {
      filterArray[index].checked = !filterArray[index].checked;
    } else {
      filterArray.forEach((filter) => (filter.checked = e.target.checked));
    }

    const isAllChecked = filterArray.slice(1).every((filter) => filter.checked);

    setState({ filtersList: filterArray, allChecked: isAllChecked });
  };

  const handlePageOrientation = (value) => {
    setState({ orientation: value })
  }

  const handleShowInput = () => {
    setState({ showInputNewDataType: true });
  };
  const handleAddDataType = () => {
    setState({
      filtersList: [
        ...state.filtersList,
        { value: "custom_field", label: state.newDataTypeName, checked: true },
      ],
      showInputNewDataType: false,
      newDataTypeName: "",
    });
  };

  // =========== DOWNLOAD/EXPORT PLAYERS LIST  =========================

  const exportPlayers = () => {
    let fields = state.filtersList
      .filter((filter, index) => {
        return index !== 0 && filter.checked;
      })
      .map((field) => {
        if (field.value === "custom_field") {
          return `${field.value}_${field.label.split(" ").join("_")}`;
        } else return field.value;
      });

    let payloadFields = fields.join("-");
    let playersIds = playerData.join("_");

    let payload = {
      fields: payloadFields,
      id_club: id_club,
      id_players: playersIds,
      token: token,
    };

    const url = baseUrl(
      `club/export_players_list_2?id_club=${payload.id_club}&id_team=${id_team}&token=${payload.token}&fields=${payload.fields}&id_players=${payload.id_players}&orientation=${state.orientation}`
    );
    window.location.assign(url);
    closePopup();
  };

  return (
    <TFBDialog
      open={exportListPopup}
      fullWidth={true}
      maxWidth="sm"
      closePopup={closePopup}
      title={languagePack.export_player_list}
      btnRight={() => renderBtn("right", () => exportPlayers())}
      btnLeft={() => renderBtn("left", () => closePopup())}
    >
      {!loadingFilters ? (
        <div className={styles.exportPlayersPopupContent}>
          <span className={styles.subheaderSpan} style={{ marginTop: 0, marginBottom: 10 }}>
            {languagePack.select_page_orientation}
          </span>
          <div className={styles.typesList} style={{ marginBottom: 0 }}>
            {["L", "P"].map((item, index) => (
              <TFBCheckbox
                key={"page-type-" + index}
                label={languagePack?.["orientation_" + item] ?? item}
                className={styles.allTypesCheckBox}
                onChange={() => handlePageOrientation(item)}
                checked={item === state.orientation}
              />
            ))}
          </div>

          <span className={styles.subheaderSpan}>
            {languagePack.select_data_types_included_in_the_list}
          </span>

          <div className={styles.typesList}>
            {state.filtersList.map((filter, index) => (
              <TFBCheckbox
                key={"export-players-" + index}
                label={
                  filter.value !== "custom_field"
                    ? languagePack?.[filter.value]
                    : filter.label
                }
                className={styles.allTypesCheckBox}
                labelColor={index === 0 ? "var(--primaryColor)" : undefined}
                onChange={(e) => handleCheckbox(e, index)}
                checked={index == 0 ? state.allChecked : filter.checked}
              />
            ))}
          </div>
          {!state.showInputNewDataType && (
            <TFBButton
              style={{ width: "40%" }}
              renderIcon={() => <TFBIcon name="add" />}
              size="sm"
              color="green"
              onClick={handleShowInput}
            >
              {languagePack.add_data_type}
            </TFBButton>
          )}
          {state.showInputNewDataType && (
            <div className={styles.newDataTypeDiv}>
              <TFBInput
                type="text"
                onChange={(e) => {
                  setState({ newDataTypeName: e.target.value });
                }}
                value={state.newDataTypeName}
              />
              <TFBIconButton
                onClick={handleAddDataType}
                color="green"
                size="lg"
                name="add"
                disabled={_.size(state.newDataTypeName) < 1}
              />
            </div>
          )}
        </div>
      ) : (
        <CircularLoader />
      )}
    </TFBDialog>
  );
}
