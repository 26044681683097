import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import packageJson from "../package.json";
import { showNotification } from "./app/components/customHooks/useAxiosPost";

const buildDateGreaterThan = (latestDate, currentDate) => {
    if (latestDate > currentDate) {
        return true;
    } else {
        return false;
    }
};

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const { languagePack } = props;
        const splashRef = useRef(null);
        const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

        useEffect(() => {
            const currentVersionCode = Number((packageJson.version).replaceAll(".", ""));
            axios
                .get(`user/is_last_version?version_code=${currentVersionCode}`)
                .then(res => {
                    if (res.data?.is_up_to_date === false) {
                        const latestVersionCode = res.data.last_version_code;
                        const currentVersionCode = Number((packageJson.version).replaceAll(".", ""));
                        const shouldForceRefresh = buildDateGreaterThan(
                            latestVersionCode,
                            currentVersionCode
                        );
                        if (shouldForceRefresh) {
                            showNotification("success", languagePack.platform_update, String(languagePack?.platform_update_message ?? "").replaceAll("$version", res.data?.last_version), { duration: 4000 });
                            const splashScreen = document.getElementById("splash-screen").cloneNode(true);
                            splashScreen.classList.remove("hidden");
                            if (splashRef?.current) {
                                splashRef.current.appendChild(splashScreen);
                            }
                            setTimeout(() => {
                                setIsLatestBuildDate(false);
                                refreshCacheAndReload();
                            }, 5000)
                        } else {
                            setIsLatestBuildDate(true);
                        }
                    } else {
                        setIsLatestBuildDate(true);
                    }
                })
                .catch(e => console.log(e))
        }, []);

        const refreshCacheAndReload = () => {
            if (caches) {
                // Service worker cache should be cleared with caches.delete()
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
            // delete browser cache and hard reload
            window.location.reload(true);
        };

        return (
            <React.Fragment>
                {isLatestBuildDate ? <Component {...props} /> : <div ref={splashRef}></div>}
            </React.Fragment>
        );
    }

    return connect(({ layoutService }) => ({
        languagePack: layoutService.languagePack,
    }))(ClearCacheComponent);
}

export default withClearCache;