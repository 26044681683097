import Axios from "axios";
import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Popup, Flag, Button as ButtonS, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authReduxFunctions } from "./../../../../../redux/functions/authReduxFunctions";
import { updateUserLanguage } from "./../../../../../app/utils/api";
import * as constants from "./../../../../../app/utils/constants";
import { Dialog, DialogContent } from "@material-ui/core/";
import ChangePassword from "../../../../../app/Auth/ChangePassword";
import { actions } from "../../../../../redux/authRedux";
import UserAvatar from "./UserAvatar";
import { baseUrl } from "../../../../../app/utils/utilFunctions";

const currencies = Object.entries(constants.currencies).map(
  ([uniqueSlug, value]) => value
);

export function QuickUser() {
  const history = useHistory();
  const dispatch = useDispatch();
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };
  const {
    user,
    languages,
    languagePack,
    lang,
    currency,
    currencyItemSelected,
    clubQuickSetup,
  } = useSelector(
    ({ auth, layoutService: { languagePack, languages, lang } }) => ({
      user: auth?.user || {},
      languagePack,
      languages,
      lang,
      token: auth?.user?.token,
      currency: auth?.user?.currency,
      currencyItemSelected:
        constants.currencies[auth?.user?.currency] || constants.currencies.EUR,
      clubQuickSetup: auth?.clubQuickSetup,
    })
  );
  function onChangeLanguage({ language }) {
    const formData = new FormData();
    formData.append("lang", language);

    const lastClubQuickSetup = { ...clubQuickSetup };

    Axios.post(`user/change_lang`, formData, {
      params: {
        token: user.token,
      },
    }).then(({ data }) => {
      if (data.success === 1) {
        authReduxFunctions(dispatch).login(data.user);
        dispatch(actions.updateQuickSetup(lastClubQuickSetup));
      }
      if (data.go_to_login) {
        authReduxFunctions(dispatch).logout(user);
      }
    });
  }
  const [changePassword, setChangePassowrd] = useState(false);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">{languagePack.user_profile}</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <UserAvatar
              className="symbol-label"
              url={
                user.img_url
                  ? user.img_url
                  : baseUrl("/uploads/users/default_profile.jpg")
              }
              languagePack={languagePack}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column" style={{ width: "100%" }}>
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.full_name}
            </a>
            <div className="text-muted mt-1">
              {user?.club?.club_name || languagePack.no_club}
            </div>
            <div className="navi mt-2 dropdown-lang">
              <DropdownButton id="dropdown-item-lang" title={lang}>
                {languages.map((item) => (
                  <Dropdown.Item
                    as="button"
                    key={item.language + "-dropdown-item"}
                    onClick={() => {
                      onChangeLanguage(item);
                    }}
                  >
                    {item.language}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </div>

            <button
              id="logout-btn"
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              {languagePack.sign_out}
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <Popup
            trigger={
              <div
                className="navi-link"
                style={{ display: "flex", cursor: "pointer" }}
              >
                <div className="symbol symbol-40 bg-light mr-3">
                  <div className="symbol-label">
                    <Flag name={currencyItemSelected.flag} />
                  </div>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    {languagePack[currencyItemSelected.translation]}
                  </div>
                  <div className="text-muted">{languagePack.your_currency}</div>
                </div>
              </div>
            }
            content={
              <div>
                {currencies.map((item) => (
                  <ButtonS
                    key={`${item.uniqueSlug}-dropdown-item-income-outcome`}
                    onClick={() =>
                      updateUserLanguage(
                        user,
                        clubQuickSetup,
                        item.uniqueSlug
                      )(dispatch)
                    }
                    style={{
                      width: "100%",
                      display: "flex",
                      marginTop: 10,
                      backgroundColor:
                        currency === item.uniqueSlug
                          ? "var(--primaryColor)"
                          : "grey",
                      color: "white",
                    }}
                    content={
                      <>
                        <Flag name={item.flag} />{" "}
                        <div>{languagePack[item.translation]}</div>
                      </>
                    }
                  />
                ))}
              </div>
            }
            on="click"
            position="bottom right"
          />
        </div>

        <div className="separator separator-dashed my-7" />

        <div
          className="change-password-user-menu"
          onClick={() => setChangePassowrd(true)}
        >
          <div className="change-password-icon-container">
            <Icon name="lock" className="lock-icon" size="large" />
          </div>
          {languagePack["change_password"]}
        </div>

        <Dialog open={changePassword} onClose={() => setChangePassowrd(false)}>
          <DialogContent>
            <ChangePassword closePopup={() => setChangePassowrd(false)} />
          </DialogContent>
        </Dialog>

        <div className="separator separator-dashed my-7" />
      </div>
    </div>
  );
}
