import React from "react";
import "./EditRequest.scss";

function InputLabel({ title, type, value, onChange, placeholder, className }) {
  return (
    <div className="form-group">
      <div className="form-group-title">{title ? title : ""}</div>
      <div className="input-wrapper" style={{ width: "100%" }}>
        <input
          type={type ? type : "text"}
          value={value ? value : ""}
          onChange={onChange}
          placeholder={placeholder ? placeholder : ""}
          className={className ? className : ""}
        />
      </div>
    </div>
  );
}

export default InputLabel;
