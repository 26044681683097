import React, { useEffect, useState } from 'react';
import impressions_icon from '../../../../assets/icons/fans-dashboard/page_impressions.png';
import NumberFormat from "react-number-format";
import axios from "axios";

export default function ImpressionsVsClicks(props) {
    const [videos, setVideos] = useState(0);
    const [views, setViews] = useState(0);

    useEffect(() => {
        getValues();
    }, [])

    function getValues() {
        axios.get(`fans/youtube_videos_views?id_club=${props.id_club}`)
            .then((res) => {
                setVideos(res.data.videos)
                setViews(res.data.views)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom">
            <div className="dashboard-box-container">
                <div className="dashboard-box-title" >Videos vs views</div>
                <div className="stats-row">
                    <div className="stat-column param-value-box">
                        <div className="comparison-content-container">
                            <div className="image-container">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="48px" height="55px" version={1.0} style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }} viewBox="0 0 132850 151280" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n   \n    .str0888 {stroke:#2B2A29;stroke-width:13751.9;stroke-linecap:round;stroke-linejoin:round}\n    .fil0888 {fill:none}\n   \n  " }} />
                                    </defs>
                                    <g id="Layer_x0020_1">
                                        <metadata id="CorelCorpID_0Corel-Layer" />
                                        <path className="fil0888 str0888" d="M125976 75640l-59549 34382 -59549 34384 0 -68766 0 -68762 59549 34384 59549 34378z" />
                                    </g>
                                </svg>
                            </div>

                            <div className="parameter-name">Channel videos</div>
                            <div className="parameter-value">
                                <NumberFormat
                                    value={videos}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="stat-column param-value-box">
                        <div className="comparison-content-container">
                            <div className="image-container"> <img src={impressions_icon} alt="clicks" /></div>
                            <div className="parameter-name">Total views</div>
                            <div className="parameter-value">
                                <NumberFormat
                                    value={views}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}