import React, { useState, useEffect } from 'react';
import followers_icon from '../../../../assets/icons/fans-dashboard/page_followers.png';
import axios from "axios";
import NumberFormat from "react-number-format";

export default function FollowersCount(props) {
    const [followers, setFollowers] = useState(0);

    useEffect(() => {
        getFollowers();
    }, [])

    function getFollowers() {
        axios.get(`fans/instagram_total_followers?id_network=${props.id_network}`)
            .then((res) => setFollowers(res.data.followers))
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom" style={{ height: '100%' }}>
            <div className="dashboard-box-container" style={{ height: '100%' }}>
                <div className="dashboard-box-title" >Page engagement</div>
                <div className="clicks-stat-box">
                    <img src={followers_icon} alt="clicks_icon" />
                    <div>
                        <div className="clicks-stat-number">
                            <NumberFormat
                                value={followers}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                        </div>
                        <div className="clicks-stat-text">Page followers</div>
                    </div>
                </div>
            </div>
        </div>
    )
}