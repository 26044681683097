export const formations = [
    "4-3-3",
    "4-4-2",
    "4-2-3-1",
    "5-3-2",
    "3-5-2",
    "4-1-4-1",
    "3-4-3",
    "4-4-1-1"
];

export const legs = [
    { key: "left", value: "left", text: "Left" },
    { key: "right", value: "right", text: "Right" },
];

export const contractTypes = [
    { key: "loan", value: "loan", text: "On loan" },
    { key: "contract", value: "contract", text: "Under contract" },
    { key: "free", value: "free", text: "Free agent" }
]

export const filterStats = [
    "Expected goals (shots-open play)",
    "Finishing (quality)",
    "Forward passes received (quality)",
    "Key passes (quality)"
]


