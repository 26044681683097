import React from "react";
import ReactApexChart from "react-apexcharts";
import "../../../../../pages/Club/PlayerProfile/PlayerProfile.scss";
import "../PlayerRadarChart.scss";

export default function IndividualChart(props) {
  return (
    <div className="player-radar-chart">
      {/*================ CHART ITSELF =================*/}
      {/*===============================================*/}
      <div className="radar-chart-itself">
        <ReactApexChart
          options={props.options}
          series={props.series}
          type="radar"
          height={550}
        />
      </div>

      {/*================ EVALUATION DETAILS PER CATEGORY =================*/}
      {/*==================================================================*/}
      <div className="player-evaluation-detail">
        {props.evaluationdata.map((evaluationData, index1) => {
          return (
            <div
              className="player-evaluation-category-wrapper"
              key={"player-evaluation-category-wrapper-" + index1}
            >
              <div className="evaluation-category-header">
                <div className="evaluation-category-col">
                  {evaluationData.category.category_name}
                </div>
                <div className="evaluation-category-average-col">
                  {evaluationData.category.test_grade}
                </div>
              </div>
              {evaluationData.sub_categories.map((subcategory, index) => {
                return (
                  <div
                    className="evaluation-subcategories-row animate-drop"
                    key={"evaluation-subcategories-row-" + index}
                    style={{
                      backgroundColor: index % 2 !== 0 && "#fafafa",
                      border:
                        (subcategory.test_grade == 5 && "2px solid green") ||
                        (subcategory.test_grade == 1 && "2px solid red"),
                    }}
                  >
                    <div className="evaluation-subcategory-name">
                      {subcategory.sub_cat_name}
                    </div>
                    <div className="evaluation-subcategory-grade">
                      {subcategory.test_grade}
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
