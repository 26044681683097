import React, { useState, useRef } from "react";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";
import "./ContExtras.scss";
import moment from "moment";
import { Icon, Checkbox, Button } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import Select from "react-select";
import { useEffect } from "react";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

function ContExtras(props) {
  const [playersOptions, setPlayersOptions] = useState([]);
  const [dataToProcess, setDataToProcess] = useState([]);
  const [columns, setColumns] = useState([]);
  const [paymentYear, setPaymentYear] = useState(new Date());
  const [paymentMonth, setPaymentMonth] = useState(new Date());
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const [togglePlayerSelect, setTogglePlayerSelect] = useState(false);
  const [toggleRender, setToggleRender] = useState(false);
  const [columnPayment, setColumnPayment] = useState(null);
  const [payDateColumn, setPayDateColumn] = useState(null);
  const [payerColumn, setPayerColumn] = useState(null);
  const [columnsOptions, setColumnsOptions] = useState([]);
  const [checkBoxesState, setCheckBoxesState] = useState([]);

  const inputOpenFileRef = useRef();

  useEffect(() => {
    axios
      .get(
        `finances/get_label_entities?label_type=player&id_club=${props.currentUser.id_club}`
      )
      .then((res) => {
        setPlayersOptions(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (dataToProcess.length > 0 && file != "") {
      processData(dataToProcess);
    }
  }, [togglePlayerSelect, toggleRender, dataToProcess]);

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    for (var i = 0; i < headers.length; i++) {
      headers[i] = `${props.languagePack["column"]} ${i + 1}`;
    }

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    let acctualData = list;
    let oldData = data;

    const dataIsSame = acctualData.every((element_1) =>
      oldData.some((element_2) =>
        Object.keys(element_1).every((key) => element_1[key] == element_2[key])
      )
    );

    let checkBoxList = [];
    if (!dataIsSame) {
      list.map((element, index) => {
        checkBoxList.push({
          index: index,
          checked: false,
        });
      });
      setCheckBoxesState(checkBoxList);
    } else {
      checkBoxList = checkBoxesState;
    }

    if (checkBoxList.length > 0) {
      let columns = [
        {
          name: (
            <Checkbox
              onClick={() => {
                setTogglePlayerSelect(!togglePlayerSelect);
                checkBoxList.forEach(
                  (checkBox) => (checkBox.checked = !togglePlayerSelect)
                );
                setCheckBoxesState(checkBoxList);
              }}
              checked={checkBoxList.every(
                (checkBox) => checkBox.checked == true
              )}
            />
          ),
          grow: 0.5,

          selector: "checkbox",
          cell: (row, index) => (
            <>
              <div className="extras-cont-checkbox">
                <Checkbox
                  onClick={() => {
                    setToggleRender(!toggleRender);
                    checkBoxList[index].checked = !checkBoxList[index].checked;
                    if (
                      checkBoxList.some((checkbox) => checkbox.checked == false)
                    )
                      setTogglePlayerSelect(false);

                    checkBoxList.forEach((checkBox, index) => {
                      if (!checkBox.year) {
                        checkBoxList[index].year = paymentYear;
                      }
                      if (!checkBox.month) {
                        checkBoxList[index].month = paymentMonth;
                      }
                      if (!checkBox.checked) delete checkBoxList[index].player;
                      if (!checkBox.checked && checkBox.year)
                        delete checkBoxList[index].year;
                      if (!checkBox.checked && checkBox.month)
                        delete checkBoxList[index].month;
                    });

                    setCheckBoxesState(checkBoxList);
                  }}
                  checked={checkBoxList[index]?.checked}
                />
              </div>
            </>
          ),
        },
      ];

      headers.map((c, index) => {
        columns.push({
          name: c,
          selector: c,
          id: index,
          cell: (row, index2) => (
            <>
              <div className="data-extras-cont-wrapper">
                <div className="data-extras-cont">
                  {row[`${props.languagePack["column"]} ${index + 1}`]}
                </div>

                <div className="select-player-extras-cont">
                  {index == 0 && checkBoxList[index2]?.checked && (
                    <TFBSelect
                      placeholder={languagePack.select_player}
                      options={playersOptions}
                      onChange={(selected) => {
                        setToggleRender(!toggleRender);
                        checkBoxList[index2].player = selected;
                        setCheckBoxesState(checkBoxList);
                      }}
                    />
                  )}
                </div>
                {index == 0 && checkBoxList[index2]?.checked && (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <div
                      className="date-table-extras-wrapper"
                      style={{ display: "flex", gap: "12px" }}
                    >
                      <div
                        className="select-player-extras-cont"
                        style={{ width: "47%" }}
                      >
                        <DatePicker
                          value={checkBoxList[index2].month}
                          onChange={(value) =>
                            handleChangePaymentMonth(value, index2)
                          }
                          views={["month"]}
                          emptyLabel={"Selectează luna"}
                          format="MMMM"
                          inputVariant="outlined"
                          className="plan-training-date-input"
                        />
                      </div>
                      <div
                        className="select-player-extras-cont"
                        style={{ width: "47%" }}
                      >
                        <DatePicker
                          value={checkBoxList[index2].year}
                          onChange={(value) =>
                            handleChangePaymentYear(value, index2)
                          }
                          views={["year"]}
                          emptyLabel={"Selectează anul"}
                          format="yyyy"
                          inputVariant="outlined"
                          className="plan-training-date-input"
                        />
                      </div>
                    </div>
                  </MuiPickersUtilsProvider>
                )}
              </div>
            </>
          ),
        });
      });

      let columnsOption = [];
      columns.slice(1).map((column, index) => {
        columnsOption.push({
          label: column.name,
          value: index,
        });
      });
      setColumnsOptions(columnsOption);
      setColumns(columns);
    }
    setData(list);
  };

  const savePlayersPayments = () => {
    let checkBoxListCopy = checkBoxesState;
    let tableDataCopy = data;

    let taxForPlayers = [];

    function containsNumbers(str) {
      return /\d/.test(str);
    }

    for (let i = 0; i < tableDataCopy.length; ++i) {
      if (
        checkBoxListCopy[i].checked &&
        checkBoxListCopy[i].player != undefined &&
        checkBoxListCopy[i].year != undefined
      ) {
        let payerColumnText = tableDataCopy[i][payerColumn.label];
        let splicedColumnText = payerColumnText.split(" ").splice(5);

        let payerName = "";
        splicedColumnText.every((string) => {
          if (!containsNumbers(string)) {
            payerName += string;
            payerName += " ";

            return true;
          } else {
            return false;
          }
        });

        taxForPlayers.push({
          player: checkBoxListCopy[i].player,
          customer_name: payerName.trim(),
          payment_amount: parseFloat(tableDataCopy[i][columnPayment.label]), // cast double
          payment_year: moment(checkBoxListCopy[i].year).format("YYYY"),
          payment_month: moment(checkBoxListCopy[i].month).format("M"),
          payment_payed_date: moment(
            new Date(tableDataCopy[i][payDateColumn.label])
          ).format("YYYY-MM-DD"),
        });
      }
    }
    const payload = {
      id_club: props.currentUser.id_club,
      payments: taxForPlayers,
    };

    axios
      .post("finances/upload_monthly_payments_from_bank_statement_csv", payload)
      .then((res) => {
        if (res.data == 1) {
          resetStates();
          store.addNotification({
            title: "Financiar",
            message: "Datele au fost importate cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Financiar",
            message:
              "Datele nu au fost importate cu succes! Mai incearca o data.",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  // handle file upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const dataProcess = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      setDataToProcess(dataProcess);
    };
    reader.readAsBinaryString(file);
    inputOpenFileRef.current.value = "";
  };

  const handleChangePaymentYear = (value, playerIndex) => {
    let checkBoxesList = checkBoxesState;
    checkBoxesList[playerIndex].year = value;

    setCheckBoxesState(checkBoxesList);
    setToggleRender(!toggleRender);
  };

  const handleChangePaymentMonth = (value, playerIndex) => {
    let checkBoxesList = checkBoxesState;
    checkBoxesList[playerIndex].month = value;

    setCheckBoxesState(checkBoxesList);
    setToggleRender(!toggleRender);
  };

  const resetStates = () => {
    setDataToProcess([]);
    setColumns([]);
    setData([]);
    setFile([]);
    setColumnPayment(null);
    setPayDateColumn(null);
    setPayerColumn(null);
    setColumnsOptions([]);
    setCheckBoxesState([]);
  };

  const { languagePack } = props;
  return (
    <div className="card card-custom">
      <div className="import-csv-page-container">
        <div className="import-csv-title">
          {languagePack.upload_bank_account_statements}
        </div>
        <div className="import-csv-row">
          <div className="step-label">{"1."}</div>
          <div className="import-csv-input-group">
            <input
              className="add-file-form-file-input"
              id="customFile"
              ref={inputOpenFileRef}
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={(e) => {
                handleFileUpload(e);
              }}
            />
            <label className="add-file-form-file-label" htmlFor="customFile">
              {file.name
                ? file.name
                : `${props.languagePack["upload_file"]} .csv`}
            </label>
            <Icon name="arrow up" size="small" />
          </div>
        </div>

        {data.length > 0 && (
          <DataTable
            highlightOnHover
            columns={columns}
            data={data}
            customStyles={customStyles}
            noHeader={true}
            style={{ marginBottom: "15px" }}
          />
        )}
        {data.length > 0 && (
          <div className="import-csv-row">
            <div className="step-label">{"2."}</div>
            <div className="import-csv-row-text" style={{ marginRight: "8px" }}>
              {languagePack.upload_bank_account_statements_amount_column}
            </div>
            {columns.length > 0 && (
              <div
                className="select-column-extras-cont"
                style={{ width: "22%" }}
              >
                <TFBSelect
                  placeholder={languagePack.select_column}
                  options={columnsOptions}
                  onChange={(selected) => {
                    setColumnPayment(selected);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {data.length > 0 && (
          <div className="import-csv-row">
            <div className="step-label">{"3."}</div>
            <div className="import-csv-row-text" style={{ marginRight: "8px" }}>
              {languagePack.upload_bank_account_statements_date_column}
            </div>
            {columns.length > 0 && (
              <div
                className="select-column-extras-cont"
                style={{ width: "22%" }}
              >
                <TFBSelect
                  placeholder={languagePack.select_column}
                  options={columnsOptions}
                  onChange={(selected) => {
                    setPayDateColumn(selected);
                  }}
                />
              </div>
            )}
          </div>
        )}
        {data.length > 0 && (
          <div className="import-csv-row">
            <div className="step-label">{"4."}</div>
            <div className="import-csv-row-text" style={{ marginRight: "8px" }}>
              {languagePack.upload_bank_account_statements_payer_column}
            </div>
            {columns.length > 0 && (
              <div
                className="select-column-extras-cont"
                style={{ width: "22%" }}
              >
                <TFBSelect
                  placeholder={languagePack.select_column}
                  options={columnsOptions}
                  onChange={(selected) => {
                    setPayerColumn(selected);
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="import-csv-row" style={{ justifyContent: "flex-end" }}>
          <Button className="import-csv-button-cancel" onClick={resetStates}>
            {languagePack.admin_cancel}
            <Icon name="ban" size="small" className="import-csv-icon" />
          </Button>
          <Button
            className="import-csv-button"
            onClick={savePlayersPayments}
            disabled={
              columnPayment == null ||
              payDateColumn == null ||
              payerColumn == null ||
              checkBoxesState.every((checkBox) => !checkBox.checked) ||
              checkBoxesState.some(
                (checkBox) => checkBox.checked && checkBox.player == undefined
              )
            }
          >
            {props.languagePack["admin_save"]}

            <Icon name="check" size="small" className="import-csv-icon" />
          </Button>
        </div>
      </div>
    </div>
  );
}

const customStyles = {
  headCells: {
    style: {
      justifyContet: "center",
      backgroundColor: "rgb(250 250 250)",
    },
  },
  cells: {
    style: {
      justifyContet: "center",
      alignItems: "baseline",
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      marginBottom: "20px",
    },
  },
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ContExtras);
