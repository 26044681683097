import React from "react";
import axios from "axios";
import { logout } from "./../../utils/api";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import { connect } from "react-redux";
import "../Club/Club.styles.scss";
import IndividualPlayerReport from "./IndividualPlayerReport";
import { bindActionCreators } from "redux";
import ReportBubbleChart from "../../components/charts/Reports/ReportBubbleChart";
import TFBPlayerWithAvatar from "../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import { baseUrl } from "../../utils/utilFunctions";

class ReportPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playersList: [],
      parameters: [],
      test_name: "",
      test_evaluator: "",
      dialogOpen: false,
      selectedPlayer: "",
      selectedPlayerId: "",
      expandedRows: [],
    };
  }

  componentDidMount = () => {
    const id_test = this.props.match.params.id;
    const test_type = this.props.match.params.type;
    axios
      .get(`report/get_test_data`, {
        params: {
          id_test,
          test_type,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            playersList: Array.isArray(res.data.list) ? res.data.list : [],
            parameters: res.data.evaluated_params,
            test_name: res.data.test_name,
            test_evaluator: res.data.test_evaluator,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleRowClick(rowId) {
    const currentExpandedRows = this.state.expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter((id) => id !== rowId)
      : currentExpandedRows.concat(rowId);

    this.setState({ expandedRows: newExpandedRows });
  }

  render() {
    const { languagePack } = this.props;
    const type = this.props.match.params.type;

    return (
      <>
        <div className="row">
          <div className="col-lg-12 col-xxl-12 widget test-header">
            <div className="test-name">{this.state.test_name}</div>
            <div className="test-evaluator">
              {languagePack["made_by"]}
              <div style={{ marginLeft: 5 }}>{this.state.test_evaluator}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-xxl-8 widget">
            <div className="card card-custom">
              <div className="report-page-players-container">
                <div className="report-page-players-list-header">
                  {languagePack["players_eval"]}
                </div>
                <div className="report-page-players-list">
                  <div className="player-row report-column-titles">
                    <div className="name">{languagePack["player_name"]}</div>
                    <div className="grade">
                      {languagePack["average_rating"]}
                    </div>
                    <div className="see-more"></div>
                  </div>
                  {this.state.playersList.map((row, index) => {
                    return (
                      <div
                        className="player-row"
                        key={row.id_player}
                        style={{
                          backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="name">
                          <TFBPlayerWithAvatar
                            avatarUrl={baseUrl(`uploads/players/${row.id_player}.jpg`)}
                            playerName={row.player_name}
                          />
                        </div>
                        <div className="grade"> {row.average_rating}</div>
                        <div className="see-more">
                          <div
                            className="report-player-see-more-button"
                            onClick={() =>
                              this.setState({
                                dialogOpen: true,
                                selectedPlayer: row.player_name,
                                selectedPlayerId: row.id_player,
                              })
                            }
                          >
                            <EyeIcon />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* {this.state.playersList.length > 0 ? (
                
                <div className="placeholder-files">
                  <Icon
                    name="search minus"
                    size="huge"
                    className="placeholder-icon"
                  />
                  <div className="placeholder-text">No players found</div>
                </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xxl-4 widget">
            <div className="card card-custom" style={{ marginBottom: 20 }}>
              <div className="bubble-chart">
                {" "}
                <div className="bubble-chart-title">
                  {languagePack["player_comparison"]}
                </div>
                <ReportBubbleChart
                  id_test={this.props.match.params.id}
                  test_type={type}
                />
              </div>
            </div>

            <div className="card card-custom">
              <div className="report-parameters-list">
                <div className="parameters-list-title">
                  {languagePack["evaluated_params"]}
                </div>
                <div className="parameters-list">
                  {this.props.match.params.type === "physical" && (
                    <>
                      <div className="parameter-row param-list-column-titles">
                        <div className="name">{languagePack["parameter"]}</div>
                        <div className="um">
                          {languagePack["unit_of_measure"]}
                        </div>
                      </div>
                      {this.state.parameters.map((row, index) => {
                        return (
                          <div
                            className="parameter-row"
                            key={index}
                            style={{
                              backgroundColor: index % 2 === 0 ? "#fafafa" : "",
                            }}
                          >
                            <div className="name">
                              {row.physical_parameter_name}
                            </div>
                            <div className="um">{row.um}</div>
                          </div>
                        );
                      })}
                    </>
                  )}

                  {/* {this.props.match.params.type === "technical" && (
                    <>
                      <div className="parameter-row param-list-column-titles">
                        <div className="name">{languagePack["parameter"]}</div>
                      </div>
                      {this.state.parameters.map((row, index) => {
                        return (
                          <>
                            <div
                              className="parameter-row"
                              key={row.category_name}
                              onClick={() =>
                                this.handleRowClick(row.id_category)
                              }
                              style={{
                                backgroundColor:
                                  index % 2 === 0 ? "#fafafa" : "",
                              }}
                            >
                              <div className="name">{row.category_name}</div>
                              <div className="expand-more-arrow">
                                {this.state.expandedRows.includes(
                                  row.id_category
                                ) ? (
                                  <img src={arrow_up} alt="arrow_up" />
                                ) : (
                                  <img src={arrow_down} alt="arrow_down" />
                                )}
                              </div>
                            </div>

                            {this.state.expandedRows.includes(
                              row.id_category
                            ) && (
                              <>
                                {row.params.map((item, index2) => {
                                  return (
                                    <div
                                      className="technical-parameter-row"
                                      style={{
                                        backgroundColor:
                                          index % 2 === 0 ? "#fafafa" : "",
                                      }}
                                      key={index2}
                                    >
                                      <div className="technical-parameter-name">
                                        {item.param_name}
                                      </div>
                                      <div className="technical-parameter-detail">
                                        {item.foot === "L"
                                          ? "(cu piciorul stang)"
                                          : item.foot === "R"
                                          ? "(cu piciorul drept)"
                                          : ""}
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </>
                        );
                      })}
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xl"
        >
          <DialogContent>
            {/* {type === "physical" ? ( */}
            <IndividualPlayerReport
              id_player={this.state.selectedPlayerId}
              player_name={this.state.selectedPlayer}
              // closePopup={() => this.setState({ dialogOpen: false })}
              id_physical_test={this.props.match.params.id}
            />
            {/* ) : (
              <PlayerTechTest
                id_player={this.state.selectedPlayerId}
                player_name={this.state.selectedPlayer}
                id_test={this.props.match.params.id}
                // closePopup={() => this.setState({ dialogOpen: false })}
              />
            )} */}
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(ReportPage);
