import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
import "./TrainingDetailsPage.scss";
import { connect } from "react-redux";

function DeletePlayer({
  closePopup,
  languagePack,
  idPlayerToDelete,
  idTrainingSession,
  refreshPlayerList,
}) {
  function removePlayer() {
    const payload = {
      id_training_session: idTrainingSession,
      id_player: idPlayerToDelete,
    };

    axios.post("training/delete_player_from_training", payload).then((res) => {
      if (res.data.success == 1) {
        refreshPlayerList();
        store.addNotification({
          title: languagePack["training_add_title"],
          message: languagePack.player_deleted_successfully,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      } else {
        store.addNotification({
          title: languagePack["training_add_title"],
          message: languagePack.player_deleted_failed,
          type: "danger",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true,
          },
        });
      }
      closePopup();
    });
  }

  return (
    <div className="delete-player-training-popup-container">
      <div className="delete-player-training-header">
        <div className="delete-player-training-title">
          {languagePack.delete_training}
        </div>
        <Icon
          name="close"
          className="close"
          size="large"
          onClick={closePopup}
        />
      </div>
      <div className="delete-player-training-text">
        {languagePack.delete_player_from_training}
      </div>
      <div className="delete-player-training-buttons">
        <Button className="cancel-button" onClick={closePopup}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button className="delete-comp-button" onClick={removePlayer}>
          {languagePack["admin_delete"]}
          <Icon name="trash alternate outline" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeletePlayer);
