import React, { Fragment, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./DocumentToSign.module.scss";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";

import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBSearch from "../../../components/design-system/TFBSearch/TFBSearch";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

import CircularLoader from "../../../components/reusable/Loader";

import AddPlayerToDocument from "./AddPlayerToDocument";
import DeletePlayerFromDocument from "./DeletePlayerFromDocument";

import {
  baseUrl,
  encryptBase64String,
  prepareStringForCompare,
} from "../../../utils/utilFunctions";
import { docTypeBySign } from "../../../utils/constants";

const ViewDocumentToSign = (props) => {
  const { id } = useParams();
  const { languagePack, token } = props;

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      limit: 20,
      has_more: true,
      players: [],

      search_value: "",
      team: { value: "0", label: "All teams" },
      type: {
        ...docTypeBySign[0],
        label: languagePack[docTypeBySign[0]["lang_pack"]],
      },

      open_add_player_to_doc: false,

      open_delete_player_doc: false,
      deleting_player_doc: {},
    }
  );

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `training/get_club_teams?all=1&type=select&token=${token}`
  );

  useEffect(() => {
    if (teams) {
      if (teams?.success == 1) {
        if (Array.isArray(teams?.list)) {
          if (_.size(teams?.list) > 0) {
            let team = {
              ...teams.list[0].value,
              label: languagePack.all_teams,
            };
            updateState({ team: team });
          }
        }
      }
    }
  }, [teams]);

  const {
    data: documentData,
    loading: isDocumentDataLoading,
    refetch: refetchDocumentData,
  } = useAxiosGet(
    `parentsApp/get_document_to_sign_data?id_document=${id}&page=${state.page
    }&limit=${state.limit}&search_player_name=${prepareStringForCompare(
      state.search_value
    )}${state.team?.value > 0 ? `&id_team=${state.team.value}` : ""}${state.type?.value > -1 ? `&signed=${state.type.value}` : ""
    }`,
    true
  );

  useEffect(() => {
    if (state.search_value == "") {
      refetchDocumentData();
    }
  }, [state.search_value]);

  useEffect(() => {
    if (documentData?.players) {
      if (_.size(documentData.players) > 0) {
        if (state.page == 1) {
          updateState({ page: state.page + 1, players: documentData.players });
        } else {
          updateState({
            page: state.page + 1,
            players: [...state.players, ...documentData.players],
          });
        }
      } else {
        if (state.page == 1) {
          updateState({ players: [], has_more: false });
        } else {
          updateState({ has_more: false });
        }
      }
    }
  }, [documentData]);

  const { loaderRef } = useInfiniteScroll({
    refetch:
      !isTeamsLoading && !isDocumentDataLoading && state.has_more
        ? refetchDocumentData
        : () => { },
  });

  const openAddPlayerToDoc = () => {
    updateState({ open_add_player_to_doc: true });
  };

  const closeAddPlayerToDoc = () => {
    updateState({ open_add_player_to_doc: false });
  };

  const openDeletePlayerDoc = (doc) => {
    updateState({ open_delete_player_doc: true, deleting_player_doc: doc });
  };

  const closeDeletePlayerDoc = () => {
    updateState({ open_delete_player_doc: false, deleting_player_doc: {} });
  };

  const CustomCell = ({ children, ...otherProps }) => (
    <TFBTableCell className={styles.customCell} {...otherProps}>
      {children}
    </TFBTableCell>
  );

  const refreshList = () => {
    updateState({ players: [], page: 1, has_more: true });
  };

  const handleChangeSelect = (selected, value) => {
    updateState({ [value.name]: selected, page: 1, has_more: true });
  };

  const cancelFilters = () => {
    let team = null;
    if (teams) {
      if (teams?.success == 1) {
        if (Array.isArray(teams?.list)) {
          if (_.size(teams?.list) > 0) {
            team = { ...teams.list[0].value, label: languagePack.all_teams };
          }
        }
      }
    }
    updateState({
      search_value: "",
      page: 1,
      has_more: true,
      type: {
        ...docTypeBySign[0],
        label: languagePack[docTypeBySign[0]["lang_pack"]],
      },
      team: team,
    });
  };

  return (
    <TFBCard>
      <TFBCardHeader title={documentData?.document_title}>
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="add" />}
          onClick={openAddPlayerToDoc}
        >
          {languagePack.add_player}
        </TFBButton>
      </TFBCardHeader>
      <TFBCardBody>
        <div className={styles.searchDiv}>
          <TFBSearch
            label={"Cautare"}
            placeholder={"Introdu nume jucator..."}
            classNameSearch={styles.searchVideo}
            fluid
            showNoResults={false}
            value={state.search_value}
            onSearchChange={(e) =>
              updateState({
                search_value: e.target.value,
                page: 1,
                has_more: true,
              })
            }
            classNameComponent={styles.searchComponent}
          />
          {!isTeamsLoading && (
            <div className={styles.selectDiv}>
              <TFBSelect
                label={languagePack["select_team"]}
                options={_.map(teams?.list ?? [], (e) =>
                  e.value == 0 ? { ...e, label: languagePack.all_teams } : e
                )}
                value={state.team}
                onChange={(selected, value) =>
                  handleChangeSelect(selected, value)
                }
                name="team"
                className={styles.fullWidth}
              />
            </div>
          )}
          <div className={styles.selectDiv}>
            <TFBSelect
              label={languagePack["select_type"]}
              options={_.map(docTypeBySign, (e) => ({
                ...e,
                label: languagePack[e.lang_pack],
              }))}
              value={state.type}
              onChange={(selected, value) =>
                handleChangeSelect(selected, value)
              }
              name="type"
              className={styles.fullWidth}
            />
          </div>
        </div>
        <div className={styles.searchDiv}>
          {(_.size(_.trim(state.search_value)) > 0 || state.team) && (
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="check" />}
              onClick={refetchDocumentData}
              className={styles.fullWidth}
            >
              {languagePack["apply_filter"]}
            </TFBButton>
          )}
          {_.size(state.search_value) > 0 && (
            <TFBButton
              color="lightGray"
              renderIcon={() => <TFBIcon name="cancel" color="gray" />}
              onClick={cancelFilters}
              className={styles.fullWidth}
            >
              {languagePack["cancel_filter"]}
            </TFBButton>
          )}

          <a
            target="blank"
            href={baseUrl(`parentsApp/get_contracts_status?id_contract=${id}${state.search_value ? `&player_name=${state.search_value}` : ""}${state.team?.value ? `&id_team=${state.team?.value}` : ""}${state.type?.value ? (state.type?.value == 0 || state.type?.value == 1 ? `&signed=${state.type?.value}` : "") : ""}`)}
          >
            <TFBButton size="lg" onClick={() => { }}>
              {languagePack.generate_pdf_report}
            </TFBButton>
          </a>
        </div>
        {_.size(state.players) > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <CustomCell sx={sxStickyColumn}>
                    {languagePack.players}
                  </CustomCell>
                  <CustomCell align="center">{languagePack.team}</CustomCell>
                  <CustomCell align="left">{languagePack.signed_by}</CustomCell>
                  <CustomCell align="center">
                    {languagePack.download_view_document}
                  </CustomCell>
                  <CustomCell align="right">
                    {languagePack.product_actions}
                  </CustomCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(state.players, (row, index) => (
                  <Fragment key={"player-" + index}>
                    <TableRow
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <CustomCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                        rowSpan={
                          _.size(row?.users) > 0 ? _.size(row?.users) + 1 : 2
                        }
                      >
                        <TFBPlayerWithAvatar
                          playerName={row?.player?.data?.player_name}
                          avatarUrl={row?.player?.data?.img_url}
                        />
                      </CustomCell>
                      <CustomCell
                        align="center"
                        rowSpan={
                          _.size(row?.users) > 0 ? _.size(row?.users) + 1 : 2
                        }
                      >
                        {_.join(
                          _.map(
                            row?.player?.data?.teams?.list ?? [],
                            (e) => e.team_name
                          ),
                          ", "
                        )}
                      </CustomCell>
                    </TableRow>
                    {_.map(row?.users, (row2, index2) => (
                      <TableRow
                        key={"player-" + index + "-" + index2}
                        sx={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <CustomCell align="left">
                          {`${row2?.user?.data?.surname} ${row2?.user?.data?.name}`}
                          <div className={styles.relationship}>
                            {
                              _.find(
                                row2?.user?.data?.players,
                                (e) => e?.id_player == row?.id_player
                              )?.relationship?.relationship_name
                            }
                          </div>
                        </CustomCell>
                        <CustomCell align="right">
                          <div className="df df-center-center df-gap-15">
                            <a
                              target="_blank"
                              href={baseUrl(
                                `parentsApp/get_user_player_document_signed?id_document=${encryptBase64String(
                                  row?.id_document ?? ""
                                )}&id_player=${encryptBase64String(
                                  row?.id_player ?? ""
                                )}&id_user=${encryptBase64String(
                                  row2?.user?.data?.id_user_external ?? ""
                                )}&type=I`
                              )}
                            >
                              <TFBIconButton name="view" />
                            </a>
                            <a
                              target="_blank"
                              href={baseUrl(
                                `parentsApp/get_user_player_document_signed?id_document=${encryptBase64String(
                                  row?.id_document ?? ""
                                )}&id_player=${encryptBase64String(
                                  row?.id_player ?? ""
                                )}&id_user=${encryptBase64String(
                                  row2?.user?.data?.id_user_external ?? ""
                                )}&type=D`
                              )}
                            >
                              <TFBIconButton name="arrow-down" />
                            </a>
                          </div>
                        </CustomCell>
                        {index2 == 0 && (
                          <CustomCell
                            align="right"
                            rowSpan={_.size(row?.users)}
                          >
                            <div className="df df-end df-g10">
                              <TFBIconButton
                                name="delete"
                                onClick={() => openDeletePlayerDoc(row)}
                              />
                            </div>
                          </CustomCell>
                        )}
                      </TableRow>
                    ))}
                    {_.size(row?.users) == 0 && (
                      <TableRow
                        key={"player-" + index + "-" + index}
                        sx={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <CustomCell align="left"></CustomCell>
                        <CustomCell align="right"></CustomCell>
                        <CustomCell align="right">
                          <div className="df df-end df-g10">
                            <TFBIconButton
                              name="delete"
                              onClick={() => openDeletePlayerDoc(row)}
                            />
                          </div>
                        </CustomCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {_.size(state.players) == 0 &&
          isDocumentDataLoading == false &&
          isTeamsLoading == false && (
            <TFBPlaceholder text={languagePack.no_documents_to_sign} />
          )}
        {<div ref={loaderRef}></div>}
        {(isDocumentDataLoading || isTeamsLoading) && <CircularLoader />}

        {state.open_add_player_to_doc && (
          <AddPlayerToDocument
            openPopup={state.open_add_player_to_doc}
            closePopup={closeAddPlayerToDoc}
            refreshList={refreshList}
            id_document={id}
            currentPlayers={_.map(state.players, (e) => e.id_player)}
          />
        )}

        {state.open_delete_player_doc && (
          <DeletePlayerFromDocument
            openPopup={state.open_delete_player_doc}
            closePopup={closeDeletePlayerDoc}
            refreshList={refreshList}
            document={state.deleting_player_doc}
          />
        )}
      </TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ViewDocumentToSign);
