import React from "react";
import "./VenueManagement.scss";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Icon, Modal } from "semantic-ui-react";
import Switch from "@material-ui/core/Switch";
import { store } from "react-notifications-component";
import { getWeekYearWithOptions } from "date-fns/fp";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT,
} from "react-big-scheduler";
//include `react-big-scheduler/lib/css/style.css` for styles, link it in html or import it here
import "react-big-scheduler/lib/css/style.css";
import moment from "moment";
import { DndContext, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Calendar } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./VenueManagement.scss";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import AddVenue from "./AddVenue";
import EditChildVenue from "./EditVenueItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AddEvent from "./AddEvent";
import EditVenue from "./EditVenue";

import { date } from "yup";
import EventVenuePopup from "./EventVenuePopup";
import roLocale from "@fullcalendar/core/locales/ro";
import enLocale from "@fullcalendar/core/locales/en-gb";

class VenueManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calendar: "",
      venues: [],
      venues_schedule: [],
      resources: [],
      events: [],
      resource_group_field: "",

      add_venue: false,
      add_event: false,
      edit_venue: false,

      selectedEvent: {},
      selectedEventModal: false,
    };
  }

  componentDidMount = () => {
    this.getClubVenues();
    this.getClubVenuesSchedule();
  };

  getClubVenues = () => {
    axios
      .get(`club/get_venues?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        this.setState({
          venues: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  getClubVenuesSchedule = () => {
    axios
      .get(`club/get_venues_schedule?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        this.setState({
          resources: res.data.resources,
          events: res.data.events,
          resource_group_field: res.data.resource_group_field,
        });
      })
      .catch((err) => console.log(err));
  };

  saveEventChange = (payload) => {
    axios.post("club/update_venue_event", payload).then((res) => {
      if (res.data == 1) {
        store.addNotification({
          title: "Management club",
          message: "Evenimentul a fost actualizat cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.getClubVenuesSchedule();
      }
    });
  };

  eventClick = (info) => {
    const event = {
      id: info.event.id,
      name: info.event.title,
      date_start: moment(info.event.start).format("DD.MM.YYYY"),
      date_end: moment(info.event.end).format("DD.MM.YYYY"),
      hour_start: moment(info.event.start).format("HH:mm"),
      hour_end: moment(info.event.end).format("HH:mm"),
    };

    this.setState({
      selectedEventModal: true,
      selectedEvent: event,
    });
  };

  render() {
    const { languagePack, language } = this.props;

    return (
      <div className="card card-custom">
        <div className="venue-management-page">
          <div className="venue-management-header">
            <div className="venue-management-title">
              {languagePack.venue_management}
            </div>
            <div className="venue-management-buttons">
              <Button
                onClick={() => this.setState({ edit_venue: true })}
                className="calendar-button"
                style={{ backgroundColor: "#666666" }}
              >
                {languagePack.edit_category_list}
              </Button>
              <Button
                onClick={() => this.setState({ add_venue: true })}
                className="calendar-button"
                style={{ backgroundColor: "var(--secondaryColor)" }}
              >
                {languagePack.add_venue}
                <Icon name="add" className="plus-icon-button" />
              </Button>
              <Button
                onClick={() => this.setState({ add_event: true })}
                className="calendar-button"
                style={{ backgroundColor: "var(--primaryColor)" }}
              >
                {languagePack.add_event}
                <Icon name="add" className="plus-icon-button" />
              </Button>
            </div>
          </div>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              interactionPlugin,
              timeGridPlugin,
              resourceTimelinePlugin,
            ]}
            initialView="resourceTimelineDay"
            slotMinTime="08:00:00"
            slotMaxTime="22:00:00"
            // slotLabelFormat={[
            //     {
            //         hour: 'numeric',
            //         minute: '2-digit',
            //         meridiem: 'short',
            //         hour12: false,
            //     },
            // ]}
            height="652px"
            nowIndicator={true}
            // locale={"ro"}
            locales={
              this.props.currentUser.lang == "ro" ? [roLocale] : [enLocale]
            }
            schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
            headerToolbar={{
              left: "today prev,next",
              center: "title",
              right: "resourceTimelineDay,resourceTimelineWeek",
            }}
            aspectRatio={1.5}
            editable={true}
            eventDrop={(info) => {
              let payload = {
                id_venue_event: info.event._def.publicId,
              };
              const resource_is_updated = info.newResource != null;
              if (resource_is_updated) {
                payload.update_venue = {
                  id_venue: info.newResource._resource.id,
                  id_venue_old: info.oldResource._resource.id,
                };
              }
              const delta_time =
                info.delta.years +
                info.delta.months +
                info.delta.days +
                info.delta.milliseconds;
              if (delta_time != 0) {
                const delta = info.delta;
                let date_start = new Date(info.oldEvent.start);
                let date_end = new Date(info.oldEvent.end);
                date_start.setFullYear(date_start.getFullYear() + delta.years);
                date_start.setMonth(date_start.getMonth() + delta.months);
                date_start.setDate(date_start.getDate() + delta.days);
                date_start.setHours(
                  date_start.getHours() +
                    Math.trunc(delta.milliseconds / 1000 / 60 / 60)
                );
                date_start.setMinutes(
                  date_start.getMinutes() +
                    ((delta.milliseconds / 1000 / 60) % 60)
                );
                const final_date_start = moment(date_start).format(
                  "YYYY-MM-DDTHH:mm:00"
                );
                date_end.setFullYear(date_end.getFullYear() + delta.years);
                date_end.setMonth(date_end.getMonth() + delta.months);
                date_end.setDate(date_end.getDate() + delta.days);
                date_end.setHours(
                  date_end.getHours() +
                    Math.trunc(delta.milliseconds / 1000 / 60 / 60)
                );
                date_end.setMinutes(
                  date_end.getMinutes() +
                    ((delta.milliseconds / 1000 / 60) % 60)
                );
                const final_date_end = moment(date_end).format(
                  "YYYY-MM-DDTHH:mm:00"
                );
                payload.update_data = {
                  time_start: final_date_start,
                  time_end: final_date_end,
                };
              }
              if (resource_is_updated || delta_time != 0) {
                this.saveEventChange(payload);
              }
            }}
            eventResize={(info) => {
              const endDelta =
                info.endDelta.years +
                info.endDelta.months +
                info.endDelta.days +
                info.endDelta.milliseconds;
              const startDelta =
                info.startDelta.years +
                info.startDelta.months +
                info.startDelta.days +
                info.startDelta.milliseconds;
              let delta;
              let field_to_change;
              let value_to_change;
              if (endDelta != 0) {
                delta = info.endDelta;
                field_to_change = "end";
                value_to_change = info.oldEvent.end;
              } else if (startDelta != 0) {
                delta = info.startDelta;
                field_to_change = "start";
                value_to_change = info.oldEvent.start;
              }
              let date = new Date(value_to_change);
              date.setFullYear(date.getFullYear() + delta.years);
              date.setMonth(date.getMonth() + delta.months);
              date.setDate(date.getDate() + delta.days);
              date.setHours(
                date.getHours() +
                  Math.trunc(delta.milliseconds / 1000 / 60 / 60)
              );
              date.setMinutes(
                date.getMinutes() + ((delta.milliseconds / 1000 / 60) % 60)
              );
              const final_date = moment(date).format("YYYY-MM-DDTHH:mm:00");
              let payload;
              if (field_to_change == "start") {
                payload = {
                  id_venue_event: info.event._def.publicId,
                  update_data: {
                    time_start: final_date,
                  },
                };
              } else if (field_to_change == "end") {
                payload = {
                  id_venue_event: info.event._def.publicId,
                  update_data: {
                    time_end: final_date,
                  },
                };
              }
              this.saveEventChange(payload);
            }}
            eventColor="var(--primaryColor)"
            // buttonText={{
            //     today: languagePack.today,
            //     month: languagePack.month,
            //     day: languagePack.day,
            //     week: languagePack.week,
            // }}
            eventClick={this.eventClick}
            resourceAreaWidth={"250px"}
            resourceAreaHeaderContent={"Venues"}
            resources={this.state.resources}
            events={this.state.events}
          />
        </div>

        {/* ######### ADD VENUE ###########*/}
        <Dialog open={this.state.add_venue}>
          <DialogContent>
            <AddVenue
              closePopup={() => this.setState({ add_venue: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              getVenues={this.getClubVenues}
              getVenuesSchedule={this.getClubVenuesSchedule}
            />
          </DialogContent>
        </Dialog>
        <Dialog open={this.state.add_event}>
          <DialogContent>
            <AddEvent
              closePopup={() => this.setState({ add_event: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              getVenuesSchedule={this.getClubVenuesSchedule}
              venues={this.state.venues}
            />
          </DialogContent>
        </Dialog>

        {/* ######### EDIT VENUE #############*/}
        <Dialog open={this.state.edit_venue}>
          <DialogContent>
            <EditVenue
              closePopup={() => this.setState({ edit_venue: false })}
              id_club={this.props.currentUser.id_club}
              languagePack={this.props.languagePack}
              venues={this.state.resources}
              getVenues={this.getClubVenues}
              getVenuesSchedule={this.getClubVenuesSchedule}
            />
          </DialogContent>
        </Dialog>
        <Modal
          open={this.state.selectedEventModal}
          size="tiny"
          onClose={() => this.setState({ selectedEventModal: false })}
          style={{ position: "initial", height: "auto" }}
        >
          <EventVenuePopup
            event={this.state.selectedEvent}
            refreshEvents={() => {
              this.getClubVenuesSchedule();
            }}
            closePopup={() => {
              this.setState({ selectedEventModal: false });
            }}
          />
        </Modal>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(VenueManagement);
