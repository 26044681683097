import React, { useEffect, useReducer } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import CurrencyPicker from "./CurrencyPicker";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";

const AddPaymentForPlayer = (props) => {
  const {
    languagePack,
    closePopup,
    lastNrReceipt,
    isOpenPopup = true,
    refreshList,
    paymentRequestData,
    requestTitle,
  } = props;
  const defaultReceiptName = requestTitle;

  const paymentTypes = [
    {
      value: "cash",
      label: languagePack.add_payment_type["cash"],
    },
    {
      value: "bank_transfer",
      label: languagePack.add_payment_type["bank_transfer"],
    },
  ];

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      month: null,
      amount:
        paymentRequestData?.amount - paymentRequestData?.amount_paid ?? "",
      payment_type: null,
      generate_receipt: false,
      receipt_name: "",
      receipt_payer: "",
      receipt_serial: "",
      nr_receipt: lastNrReceipt + 1,
      date_of_payment: "",
      payment_date: null,
      description: requestTitle,
      currency: paymentRequestData.currency,

      open_confirm_add_payment: false,
    }
  );
  useEffect(() => {
    if (state.payment_type) {
      if (state.payment_type?.value !== "cash") {
        resetReceiptData();
      }
    }
  }, [state.payment_type]);

  const onChangeInput = (e) => {
    setState({ ...state, ...{ amount: e.target.value } });
  };

  const closeConfirmPayment = () => {
    setState({ open_confirm_add_payment: false });
  };
  const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost(
    `finances/add_payment_for_player_payment_request_3`
  );
  const addPaymentForPlayer = () => {
    let payload = {
      payment_data: {
        id_payment_request: paymentRequestData?.id_payment_request,
        id_player: paymentRequestData?.id_player,
        id_team: paymentRequestData?.id_team,
        id_club: paymentRequestData?.id_club,
        amount: paymentRequestData?.amount,
        amount_paid: state?.amount,
        currency: state?.currency,
        payment_type_label: state?.payment_type?.value,
        payment_payed_date: state?.payment_payed_date,
        was_paid: 1,
      },
      generate_receipt: state.generate_receipt,
    };

    if (state.generate_receipt) {
      payload.receipt_data = {
        nr_receipt: state.nr_receipt,
        amount: state.amount,
        paid_by: state.receipt_payer,
        receipt_description: state.receipt_name,
        receipt_date: state.payment_date,
        receipt_series: state.receipt_serial,
      };
    }

    const handleResponse = (response) => {
      closeConfirmPayment();
      if (Number(response?.success) === 1) {
        closePopup();
        refreshList();
        showNotification(
          "success",
          languagePack.finances,
          languagePack.success_add_payment
        );
      } else {
        showNotification(
          "danger",
          languagePack.finances,
          languagePack.fail_add_payment
        );
      }
    };
    addRequest(payload, handleResponse);
  };
  const resetReceiptData = () => {
    setState({
      generate_receipt: false,
      receipt_name: "",
      receipt_payer: "",
      receipt_serial: "",
      nr_receipt: lastNrReceipt + 1,
    });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.add_payment}
      open={isOpenPopup}
      closePopup={closePopup}
      btnLeft={() => (
        <TFBButton
          color="darkGray"
          renderIcon={() => <TFBIcon name="cancel" />}
          onClick={closePopup}
        >
          {languagePack.admin_cancel}
        </TFBButton>
      )}
      btnRight={() => (
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="check" />}
          disabled={state.payment_type == null || state.payment_date == null}
          loading={loadingAddRequest}
          onClick={addPaymentForPlayer}
        >
          {languagePack.apply}
        </TFBButton>
      )}
    >
      <div className="pop-up-section">
        <div className="pop-up-section-body">
          <div className="pop-up-row">
            <label className="row-label">
              {languagePack.payment_request_amount}
            </label>
            <br />
            <div className="row-input-with-currency">
              <input
                className="row-input"
                type="number"
                placeholder={`${languagePack.enter_payment_request_amount} (${state.currency})`}
                value={state.amount}
                name="amount"
                onChange={onChangeInput}
              />
              {/* <div className="currency-options">
                <CurrencyPicker
                  name="RON"
                  checked={state.currency === "RON"}
                  onClick={() => setState({ ...state, ...{ currency: "RON" } })}
                />
                <CurrencyPicker
                  name="EUR"
                  checked={state.currency === "EUR"}
                  onClick={() => setState({ ...state, ...{ currency: "EUR" } })}
                />
              </div> */}
            </div>
          </div>
          <div className="pop-up-row">
            <label className="row-label">{languagePack.payment_type}</label>
            <br />
            <div className="row-team">
              <TFBSelect
                placeholder={languagePack.choose_payment_type}
                options={paymentTypes}
                value={state.payment_type}
                onChange={(selected) =>
                  setState({ ...state, ...{ payment_type: selected } })
                }
              />
            </div>
          </div>
          {state.payment_type?.value === "cash" && (
            <TFBCheckbox
              label={"Generează chitanță"}
              checked={state.generate_receipt}
              onChange={(e) =>
                setState({
                  generate_receipt: e.target.checked,
                  receipt_name:
                    e.target.checked && _.size(_.trim(state.receipt_name)) === 0
                      ? defaultReceiptName
                      : state.receipt_name,
                })
              }
              style={{ marginBottom: 20 }}
            />
          )}
          {state.generate_receipt && (
            <>
              <TFBInput
                label={languagePack.payment_name}
                value={state.receipt_name}
                onChange={(e) => setState({ receipt_name: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBInput
                label={languagePack.payer_name}
                value={state.receipt_payer}
                onChange={(e) => setState({ receipt_payer: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBInput
                label={languagePack.series}
                value={state.receipt_serial}
                onChange={(e) => setState({ receipt_serial: e.target.value })}
                onKeyPress={(e) =>
                  !/\b[a-zA-Z]\b/.test(e.key) ? e.preventDefault() : {}
                }
                inputProps={{ maxLength: 3 }}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBInput
                label={languagePack.receipt_number}
                type="number"
                value={state.nr_receipt}
                onChange={(e) => setState({ nr_receipt: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
              />
            </>
          )}
          {state.payment_type && (
            <>
              <TFBDatePicker
                value={state.payment_date}
                onChange={(value) => setState({ payment_date: value })}
                emptyLabel={languagePack["add_payment_date"]}
                label={languagePack["payment_date"]}
                style={{ marginBottom: 20, width: "100%" }}
              />
              <TFBTextArea
                label={languagePack["add_payment_description"]}
                value={state.description}
                onChange={(e) => setState({ description: e.target.value })}
              />
            </>
          )}
        </div>
      </div>
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddPaymentForPlayer);
