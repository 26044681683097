import React from "react";
import moduleInactive from "./../assets/module-inactive.png";
import { logout } from "./../utils/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TFBCard from "../components/design-system/Card/TFBCard";
import TFBPlaceholder from "../components/design-system/NoDataPlaceholder/TFBPlaceholder";


class InactivePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
  }


  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard style={{ textAlign: "center" }}>
        <h1>{"404"}</h1>
        <h3>{"Not found"}</h3>
        <h6>{"The resource requested could not be found on this server!"}</h6>
      </TFBCard>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(InactivePage);
