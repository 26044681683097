import React from "react";
import axios from "axios";
import { Dropdown, Button, Icon, Checkbox } from "semantic-ui-react";
import "../Club.styles.scss";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { store } from "react-notifications-component";
import Select from "react-select";
import moment from "moment";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

class AddEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event_name: "",
      time_start: null,
      time_end: null,
      venues: [],

      is_recurent: false,
      recurent_until: null,
    };
  }

  addVenueEvent = () => {
    let payload = {
      event_name: this.state.event_name,
      time_start: moment(this.state.time_start).format("YYYY-MM-DDTHH:mm:00"),
      time_end: moment(this.state.time_end).format("YYYY-MM-DDTHH:mm:00"),
      venues: this.state.venues,
      id_club: this.props.id_club,
      is_recurent: { true: "1", false: "0" }[this.state.is_recurent],
    };
    if (this.state.is_recurent) {
      payload.recurent_until = moment(this.state.recurent_until).format(
        "YYYY-MM-DD"
      );
    }
    axios.post("club/add_venue_event", payload).then(() => {
      store.addNotification({
        title: "Management club",
        message: "Evenimentul a fost adaugat cu succes!",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
      this.props.closePopup();
      this.props.getVenuesSchedule();
    });
  };

  handleDateChange = (value) => {
    this.setState({ match_date: value });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-player-form">
        <div className="add-player-header">
          <div className="add-player-title">
            {languagePack.add_event_for_venue}
          </div>
          <div className="add-player-popup-close">
            {" "}
            <Icon
              name="close"
              className="close-icon-popup"
              onClick={() => this.props.closePopup()}
            />
          </div>
        </div>
        <div className="add-player-row">
          <input
            className="add-video-title"
            placeholder={languagePack.event_name}
            onChange={(event) => {
              this.setState({ event_name: event.target.value });
            }}
          />
        </div>
        <div className="add-match-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="date-input">
              <DateTimePicker
                value={this.state.time_start}
                onChange={(value) => this.setState({ time_start: value })}
                ampm={false}
                format="dd MMMM HH:mm"
                inputVariant="outlined"
                name="match_date"
                className="add-match-date-picker"
                emptyLabel={languagePack.start_hour}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="add-match-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="date-input">
              <DateTimePicker
                value={this.state.time_end}
                onChange={(value) => this.setState({ time_end: value })}
                ampm={false}
                format="dd MMMM HH:mm"
                inputVariant="outlined"
                name="match_date"
                className="add-match-date-picker"
                emptyLabel={languagePack.end_hour}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="add-player-row">
          <div className="add-checkbox-input">
            <Checkbox
              label={languagePack.is_recurrent}
              checked={this.state.is_recurent}
              onChange={(e, data) => {
                this.setState({
                  is_recurent: data.checked,
                });
                if (!data.checked) {
                  this.setState({ recurent_until: null });
                }
              }}
            />
          </div>
        </div>
        {this.state.is_recurent && (
          <div className="add-match-row">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="date-input">
                <DatePicker
                  value={this.state.recurent_until}
                  onChange={(value) => this.setState({ recurent_until: value })}
                  // ampm={false}
                  format="dd MMMM yyyy"
                  inputVariant="outlined"
                  name="match_date"
                  className="add-match-date-picker"
                  emptyLabel={languagePack.recurring_until}
                />
                <Icon
                  name="calendar alternate outline"
                  className="calendar-icon"
                  size="small"
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
        )}
        <div className="add-match-row">
          <TFBSelect
            placeholder={languagePack.select_venue}
            isMulti={true}
            options={this.props.venues}
            onChange={(selected) => {
              this.setState({ venues: selected });
            }}
          />
        </div>
        <div className="add-player-buttons">
          <Button
            className="custom-button-dual add-button"
            onClick={this.addVenueEvent}
            disabled={
              this.state.event_name == "" ||
              this.state.time_start == null ||
              this.state.time_end == null ||
              this.state.venues.length == 0 ||
              (this.state.is_recurent && this.state.recurent_until == null)
            }
          >
            {" "}
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" size="small" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default AddEvent;
