import { Table, TableContainer, TableHead, TableBody } from "@material-ui/core";
import _ from "lodash";
import React, { useReducer } from "react";
import {
  TFBTableCell,
  TFBTableRow,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import EditTagCategoryPopup from "./EditTagCategoryPopup";

function EditTagsPopup({ languagePack, onClosePopup, tags, refetchFilters }) {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open_edit_tag_category: false,
      categoryToEdit: {},
    }
  );

  function handleOpenEditTag(category) {
    updateState({ open_edit_tag_category: true, categoryToEdit: category });
  }
  return (
    <>
      <TFBDialog
        open
        closePopup={onClosePopup}
        title={languagePack.Edit_labels}
      >
        <TableContainer>
          <Table faria-label="simple table">
            <TableHead>
              <TFBTableRow>
                <TFBTableCell
                  sx={{
                    ...sxStickyColumn,
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: "#B2B2B2",
                    fontWeight: "700",
                    borderBottom: "0",
                    minHeight: "40px",
                    backgroundColor: "#fff",
                    width: "250px",
                  }}
                >
                  {languagePack.category_name}
                </TFBTableCell>

                <TFBTableCell
                  sx={{
                    ...sxStickyColumn,
                    fontFamily: "Poppins",
                    fontSize: "1em",
                    color: "#B2B2B2",
                    fontWeight: "700",
                    borderBottom: "0",
                    minHeight: "40px",
                    backgroundColor: "#fff",
                  }}
                  align="right"
                >
                  {languagePack.product_actions}
                </TFBTableCell>
              </TFBTableRow>
            </TableHead>
            <TableBody>
              {_.map(tags, (row, index) => (
                <TFBTableRow
                  key={"row-" + index}
                  sx={{
                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                  }}
                >
                  <TFBTableCell
                    sx={{
                      ...sxStickyColumn,
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      color: "#666666",
                      fontWeight: "700",
                      borderBottom: "0",
                      minHeight: "55px",
                    }}
                  >
                    {row.label}
                  </TFBTableCell>
                  <TFBTableCell sx={{ borderBottom: "0" }}>
                    {row.can_edit && (
                      <div
                        className="actions-btns-container"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <TFBIconButton
                          name="edit"
                          color="darkGray"
                          onClick={() => handleOpenEditTag(row)}
                        />
                        {/* <TFBIconButton
                      name="delete"
                      color="darkGray"
                      onClick={() => {}}
                    /> */}
                      </div>
                    )}
                  </TFBTableCell>
                </TFBTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TFBDialog>
      {state.open_edit_tag_category && (
        <EditTagCategoryPopup
          refetchFilters={refetchFilters}
          closePopup={() => updateState({ open_edit_tag_category: false })}
          category={state.categoryToEdit}
        />
      )}
    </>
  );
}

export default EditTagsPopup;
