import React from "react";
import MatchBasicDetails from "./MatchBasicDetails";
import MatchEvents from "./MatchEvents";
import MatchLineup from "./MatchLineup";
import "./MatchDetails.scss";
import axios from "axios";
import { connect } from "react-redux";
import { logout } from "../../../../utils/api";
import { bindActionCreators } from "redux";
import permissions from "./../../../../../app/utils/permissions";
import DocumentsWidget from "./DocumentsWidget";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import PlayersMatchRatingWidget from "./PlayersMatchRatingWidget";
import MatchSquad from "./MatchSquad/MatchSquad";
import MatchReportInfo from "./MatchReportInfo.js";
import { baseUrl } from "../../../../utils/utilFunctions";
import MatchVideo from "./MatchVideo/MatchVideo";
import PolarSessionResults from "../../../../components/data-providers/polar/PolarSessionResults";

class MatchDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      match_details: null,
      match_teams: [],
      teams_players: {},
      update_players_rating: 0,
      update_lineups: 0,
      staff: [],
    };
  }

  componentDidMount = () => {
    this.hasAccessToMatch();
  };

  hasAccessToMatch = () => {
    axios
      .post(`user/has_access_to_match`, {
        id_club: this.props.currentUser.id_club,
        id_match: this.props.match.params.id,
      })
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToMatch: true,
          });
          this.getMatchDetails();
          this.getStaff();
        } else {
          this.setState({
            redirectToMatches: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getMatchDetails = () => {
    axios
      .get(
        `matches/get_manual_match_data?id_match=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        if (res.data.success == 1) {
          this.setState({
            match_details: res.data.match_data,
            match_teams: res.data.match_teams,
            teams_players: res.data.teams_players,
            isLoad: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getStaff = () => {
    axios
      .get(
        `matches/get_staff_member_with_role?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        if (res.data.success == 1) {
          this.setState({
            staff: res.data.list,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  generateMatchReport = () => {
    const url = baseUrl(
      `matches/generate_manual_match_report_4?id_match=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}`
    );
    window.location.assign(url);
  };

  generateRefreeReport = () => {
    const { own_team_away_id, own_team_home_id } = this.state.match_details;
    const isHomeTeam = own_team_home_id ? true : false;
    const id_team = own_team_home_id ? own_team_home_id : own_team_away_id;

    const url = baseUrl(
      `matches/download_refree_report_2?id_match=${this.props.match.params.id}&is_home_team=${isHomeTeam}&id_team=${id_team}`
    );

    window.location.assign(url);
  };

  render() {
    const { languagePack, user_permissions } = this.props;

    if (this.state.redirectToMatches) {
      return <Redirect to="/club/matches?type=past" />;
    }

    return (
      <div className="football-api-match-page-container">
        {this.state.isLoad ? (
          <>
            <div className="football-api-match-title">
              <div className="football-api-match-title-text">
                {this.state.match_details.home_team_name +
                  " vs " +
                  this.state.match_details.away_team_name}
              </div>
              <TFBButton
                color="green"
                onClick={this.generateRefreeReport}
                style={{ marginLeft: "auto", marginRight: "25px" }}
              >
                {languagePack.generate_refree_report}
              </TFBButton>
              <TFBButton color="green" onClick={this.generateMatchReport}>
                {languagePack.generate_match_report}
              </TFBButton>
            </div>
            <div className="row">
              <div className="col-lg-6 col-lg-padding">
                <MatchBasicDetails
                  languagePack={languagePack}
                  matchDetails={this.state.match_details}
                />
                <MatchVideo matchDetails={this.state.match_details} />
                <MatchEvents
                  matchDetails={this.state.match_details}
                  matchTeams={this.state.match_teams}
                  teamsPlayers={this.state.teams_players}
                  staff={this.state.staff}
                  hasAccessToDeleteHomeEvents={
                    this.props.currentUser.id_club ==
                      this.state.match_details.home_team_club ||
                    this.state.match_details.home_team_club == null
                  }
                  hasAccessToDeleteAwayEvents={
                    this.props.currentUser.id_club ==
                      this.state.match_details.away_team_club ||
                    this.state.match_details.away_team_club == null
                  }
                  token={this.props.token}
                  languagePack={languagePack}
                  hasAccessToEditMatch={
                    user_permissions?.[permissions.CLUB]?.[
                      permissions.COMPETITION
                    ]?.[permissions.EDIT_COMMON_COMPETITION]
                  }
                  updatePlayersRating={() =>
                    this.setState({
                      update_players_rating:
                        this.state.update_players_rating + 1,
                    })
                  }
                  updateLineups={() =>
                    this.setState({
                      update_lineups: this.state.update_lineups + 1,
                    })
                  }
                />

                <MatchReportInfo
                  matchDetails={this.state.match_details}
                  token={this.props.token}
                  languagePack={languagePack}
                />
              </div>
              {
                <div className="row" style={{ marginTop: 20 }}>
                  <div className="col-lg-12">
                    {/* <PolarSessionResults
                      parameters={[
                        {
                          player_name: "Ionel",
                          heart_rate_avg: "142",
                          heart_rate_max: "189",
                          distance_meters: "6430",
                          kcal: "2300",
                          sprint_counter: "20",
                          speed_avg: "12",
                          speed_max: "23",
                        },
                        {
                          player_name: "Sandu",
                          heart_rate_avg: "132",
                          heart_rate_max: "199",
                          distance_meters: "6830",
                          kcal: "2230",
                          sprint_counter: "22",
                          speed_avg: "14",
                          speed_max: "25",
                        },
                      ]}
                    /> */}
                  </div>
                </div>
              }

              <div className="col-lg-6 col-lg-padding">
                <MatchLineup
                  matchDetails={this.state.match_details}
                  matchTeams={this.state.match_teams}
                  teamsPlayers={this.state.teams_players}
                  staff={this.state.staff}
                  hasAccessToDeleteHomeEvents={
                    this.props.currentUser.id_club ==
                    this.state.match_details.home_team_club
                  }
                  hasAccessToDeleteAwayEvents={
                    this.props.currentUser.id_club ==
                    this.state.match_details.away_team_club
                  }
                  hasAccessToEditMatch={
                    this.state.match_details.home_team_club ==
                      this.props.currentUser.id_club ||
                    this.state.match_details.away_team_club ==
                      this.props.currentUser.id_club ||
                    user_permissions?.[permissions.CLUB]?.[
                      permissions.COMPETITION
                    ]?.[permissions.EDIT_COMMON_COMPETITION]
                  }
                  updatePlayersRating={() =>
                    this.setState({
                      update_players_rating:
                        this.state.update_players_rating + 1,
                    })
                  }
                  refreshMatchDetails={this.getMatchDetails}
                  doUpdateLineups={this.state.update_lineups}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <MatchSquad idMatch={this.props.match.params.id} />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <PlayersMatchRatingWidget
                    idMatch={this.props.match.params.id}
                    teamsArray={this.state.match_teams
                      .filter((team) => team.own_team_id != null)
                      .map((team) => team.own_team_id)}
                    doUpdate={this.state.update_players_rating}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-lg-padding">
                <div className="card card-custom">
                  <DocumentsWidget id_match={this.props.match.params.id} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="loader-container" style={{ marginTop: 50 }}>
            <Loader
              type="TailSpin"
              color={window.clubCustomization.primary_color}
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    user_permissions: auth?.user?.user_permissions,
  }),
  mapDispatchToProps
)(MatchDetails);
