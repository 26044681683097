import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { connect } from "react-redux";

class AccountMonthly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 330,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            colors: {
              ranges: [
                {
                  from: -9999999,
                  to: 0,
                  color: "#F15B46",
                },
                {
                  from: 0,
                  to: 9999999,
                  color: window.clubCustomization.primary_color,
                },
              ],
            },
            columnWidth: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: this.props.languagePack["sum"],
          },
          labels: {
            formatter: function(y) {
              return y.toLocaleString();
            },
          },
        },
        xaxis: {
          title: {
            text: this.props.languagePack["period"],
          },
          categories: [],
          labels: {
            rotate: -90,
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.getChartData();
  };

  getChartData = () => {
    axios
      .get(
        `thinkout/weekly_total_chart?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.amounts,
        }));
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="financial-chart-dashboard-container">
        <div className="financial-chart-title">
          {languagePack["weekly_report"]}
        </div>
        <div id="chart">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="bar"
            height={330}
          />
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AccountMonthly);
