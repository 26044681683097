import { connect } from "react-redux";
import React, { useEffect, useReducer } from "react";
import _ from "lodash";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { Input } from "semantic-ui-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";

import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton.tsx";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import { baseUrl } from "../../../utils/utilFunctions";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";

import TFBTeamLabel from "../../../components/design-system/TFBTeamLabel/TFBTeamLabel";
import CircularLoader from "../../../components/reusable/Loader";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";

function Receipt({ currentUser, languagePack, history, token }) {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      receipts: [],

      open_edit_dialog: false,
      editing_receipt: {},

      open_delete_dialog: false,
      deleting_receipt: {},

      page: 1,
      limit: 20,
      has_more: true,
    }
  );

  const {
    data: receiptsData,
    loading: loadingReceipts,
    refetch: refetchReceipts,
  } = useAxiosGet(
    `finances/get_receipts_list_2?id_club=${currentUser.id_club}&page=${state.page}&limit=${state.limit}`,
    true
  );

  useEffect(() => {
    if (receiptsData?.list) {
      if (_.size(receiptsData.list) > 0) {
        if (state.page == 1) {
          updateState({ page: state.page + 1, receipts: receiptsData.list });
        } else {
          updateState({
            page: state.page + 1,
            receipts: [...state.receipts, ...receiptsData.list],
          });
        }
      } else {
        if (Number(state.page) === 1) {
          updateState({ receipts: [], has_more: false });
        } else {
          updateState({ has_more: false });
        }
      }
    }
  }, [receiptsData]);

  const { loaderRef } = useInfiniteScroll({
    refetch: !loadingReceipts && state.has_more ? refetchReceipts : () => {},
  });

  const refreshList = () => {
    updateState({ receipts: [], page: 1, has_more: true });
  };

  const {
    loading: loadingUpdateReceipt,
    postData: postUpdateReceipt,
  } = useAxiosPost("finances/update_receipt");
  const {
    loading: loadingDeleteReceipt,
    postData: postDeleteReceipt,
  } = useAxiosPost("finances/delete_receipt");

  const openEditDialog = (data) => {
    updateState({
      open_edit_dialog: true,
      editing_receipt: data,
    });
  };

  const closeEditDialog = () => {
    updateState(
      {
        open_edit_dialog: false,
      },
      () => {
        updateState({
          editing_receipt: {},
        });
      }
    );
  };

  const changeReceiptSerial = (value) => {
    if (value.length > 3) return false;

    updateState({
      editing_receipt: {
        ...state.editing_receipt,
        ...{ receipt_series: value },
      },
    });
  };

  const handleDateChange = (value) => {
    updateState({
      editing_receipt: {
        ...state.editing_receipt,
        ...{ receipt_date: value },
      },
    });
  };

  const openDeleteDialog = (data) => {
    updateState({
      open_delete_dialog: true,
      deleting_receipt: data,
    });
  };

  const closeDeleteDialog = () => {
    updateState({
      open_delete_dialog: false,
      deleting_receipt: {},
    });
  };

  const updateReceipt = () => {
    const payload = {
      id_receipt: state.editing_receipt.id_receipt,
      receipt_series: state.editing_receipt.receipt_series,
      nr_receipt: state.editing_receipt.nr_receipt,
      receipt_date: state.editing_receipt.receipt_date,
      paid_by: state.editing_receipt.paid_by,
      receipt_description: state.editing_receipt.receipt_description,
    };

    function handleResponse(response) {
      if (Number(response.success) === 1) {
        refreshList();
        showNotification(
          "success",
          languagePack.receipts,
          languagePack.success_save_receipt
        );
        closeEditDialog();
      } else {
        showNotification(
          "danger",
          languagePack.receipts,
          languagePack.fail_save_receipt
        );
      }
    }
    postUpdateReceipt(payload, handleResponse);
  };

  const deleteReceipt = () => {
    const payload = {
      id_receipt: state.deleting_receipt.id_receipt,
    };
    function handleResponse(response) {
      if (Number(response.success) === 1) {
        refreshList();

        showNotification(
          "success",
          languagePack.receipts,
          languagePack.success_delete_receipt
        );
        closeDeleteDialog();
      } else {
        showNotification(
          "danger",
          languagePack.receipts,
          languagePack.fail_delete_receipt
        );
      }
    }
    postDeleteReceipt(payload, handleResponse);
  };

  return (
    <div className="receipt-container">
      <TFBCard>
        <TFBCardHeader title={languagePack.receipts}></TFBCardHeader>
        <TFBCardBody>
          {_.size(state.receipts) > 0 && (
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.player}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.team}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.payer}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.series}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.num_and_date_receipt}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.category_cost_sum}
                    </TFBTableCell>
                    <TFBTableCell width={200} align="center">
                      {languagePack.payment_month +
                        "/ " +
                        languagePack.payment_request}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.receipts.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        align="center"
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBPlayerWithAvatar
                          avatarUrl={row.player?.data?.img_url}
                          playerName={row.player_name}
                          onClick={() =>
                            history.push("/club/player/" + row.id_player)
                          }
                        />
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            gap: "6px",
                            flexWrap: "wrap",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {_.map(row.player?.data?.teams?.list, (t, i) => (
                            <TFBTeamLabel
                              // style={{ maxWidth: "60%" }}
                              onClick={() =>
                                history.push("/club/team/" + t.id_team)
                              }
                              key={i}
                              name={t.team_name}
                              color={t.team_color}
                            />
                          ))}
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.paid_by}</TFBTableCell>
                      <TFBTableCell align="center">
                        {row.receipt_series}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.nr_receipt +
                          " / " +
                          moment(row.receipt_date).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.amount + " RON"}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.type === "payment"
                          ? languagePack[
                              "month_" + row.payment_month
                            ]?.[0].toUpperCase() +
                            languagePack["month_" + row.payment_month]?.slice(
                              1
                            ) +
                            " " +
                            row.payment_year
                          : row.receipt_description}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="actions-btns-container">
                          <a
                            href={baseUrl(
                              `finances/download_receipt?id_receipt=${row.id_receipt}&token=${token}`
                            )}
                          >
                            <TFBIconButton name="arrow-down" color="black" />
                          </a>
                          <TFBIconButton
                            onClick={() => openEditDialog(row)}
                            name="edit"
                            color="darkGray"
                          />
                          <TFBIconButton
                            onClick={() => openDeleteDialog(row)}
                            name="delete"
                            color="darkGray"
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {_.size(state.receipts) === 0 && loadingReceipts === false && (
            <TFBPlaceholder text={languagePack.no_data_receipt} />
          )}
          {<div ref={loaderRef}></div>}
          {loadingReceipts && <CircularLoader />}
        </TFBCardBody>
      </TFBCard>

      {/* ----------------- EDIT RECEIPT ----------------*/}
      <TFBDialog
        className="pop-up-dialog-box"
        title={languagePack.edit_receipt}
        open={state.open_edit_dialog}
        closePopup={closeEditDialog}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closeEditDialog}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="check" />}
            disabled={
              state.editing_receipt?.receipt_series === "" ||
              state.editing_receipt?.paid_by === "" ||
              state.editing_receipt?.receipt_description === ""
            }
            loading={loadingUpdateReceipt}
            onClick={updateReceipt}
          >
            {languagePack.admin_save}
          </TFBButton>
        )}
      >
        <div className="add-payment-row">
          <Input
            placeholder={languagePack.series}
            onKeyPress={(event) => {
              if (!/\b[a-zA-Z]\b/.test(event.key)) {
                event.preventDefault();
              }
            }}
            className="add-payment-receipt-serial"
            name="receipt_serial"
            onChange={(e) => {
              changeReceiptSerial(e.target.value);
            }}
            value={state.editing_receipt?.receipt_series ?? ""}
          />
        </div>
        <div className="add-payment-row">
          <input
            placeholder={languagePack.receipt_number}
            className="input-text"
            type="number"
            value={state.editing_receipt?.nr_receipt ?? ""}
            onChange={(e) => {
              updateState({
                editing_receipt: {
                  ...state.editing_receipt,
                  ...{ nr_receipt: e.target.value },
                },
              });
            }}
          />
        </div>
        <div className="add-payment-row">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="add-payment-row">
              <DatePicker
                value={state.editing_receipt?.receipt_date ?? null}
                onChange={handleDateChange}
                ampm={false}
                format="dd.MM.yyyy"
                name="payment_date"
                inputVariant="outlined"
                className="date-input"
                emptyLabel={languagePack.issue_date}
              />
              <TFBIcon name="calendar" color="gray" className="calendar-icon" />
            </div>
          </MuiPickersUtilsProvider>
        </div>
        <div className="add-payment-row">
          <input
            className="input-text"
            type="text"
            value={state.editing_receipt?.paid_by ?? ""}
            onChange={(e) => {
              updateState({
                editing_receipt: {
                  ...state.editing_receipt,
                  ...{ paid_by: e.target.value },
                },
              });
            }}
            placeholder={languagePack.payer_name}
          />
        </div>
        <div className="add-payment-row" style={{ height: "auto" }}>
          <textarea
            className="input-text"
            rows={3}
            type="text"
            value={state.editing_receipt?.receipt_description ?? ""}
            onChange={(e) => {
              updateState({
                editing_receipt: {
                  ...state.editing_receipt,
                  ...{ receipt_description: e.target.value },
                },
              });
            }}
            placeholder={languagePack.receipt_description}
          />
        </div>
      </TFBDialog>

      {/* ----------------- DELETE RECEIPT ----------------*/}
      <TFBDialog
        className="pop-up-dialog-box"
        title={languagePack.delete_receipt}
        open={state.open_delete_dialog}
        closePopup={closeDeleteDialog}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closeDeleteDialog}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="red"
            loading={loadingDeleteReceipt}
            renderIcon={() => <TFBIcon name="delete" />}
            onClick={deleteReceipt}
          >
            {languagePack.admin_delete}
          </TFBButton>
        )}
      >
        <div className="pop-up-dialog-delete-text">
          {languagePack.delete_receipt_message}
        </div>
      </TFBDialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(Receipt);
