import React, { Component } from "react";
import "./AboutUs.scss";
import { Button, Icon, TextArea } from "semantic-ui-react";
import delete_icon from "../../../assets/icons/delete.png";
import edit_icon from "../../../assets/icons/edit.png";
import { store } from "react-notifications-component";
import axios from "axios";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EditSection from "../News/EditNews/EditSection";

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: [],
      image: "",
      elementToAdd: "",
      elementToAddValue: "",
      file: "",
      fileSection: "",
      contentUploaded: false,
      changesSaved: true,

      editedElementValue: "",
      editedElementIndex: "",
    };
  }

  componentDidMount = () => {
    this.getAboutContent();
  };

  getAboutContent = () => {
    axios
      .get(`site/about_us_content?id_club=${this.props.currentUser.id_club}`)
      .then((res) => {
        this.setState({
          image: res.data.image,
          content: res.data.content,
          contentUploaded: true,
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeInput = (e, { name, value }) => this.setState({ [name]: value });

  handleChangeDropdown = (e, value) => {
    this.setState({
      [value.name]: value.value,
    });
  };

  refreshInputs = () => {
    this.setState({
      elementToAdd: "",
      elementToAddValue: "",
      file: "",
    });
  };

  handleImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file: file,
        elementToAddValue: reader.result,
      });
    };
  };

  handleMainImageChange = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        image: reader.result,
      });
    };
  };

  addSection = () => {
    const newSection = {
      type: this.state.elementToAdd,
      value: this.state.elementToAddValue,
    };

    this.setState(
      { content: [...this.state.content, newSection] },
      this.refreshInputs
    );
  };

  deleteSection = (id) => {
    let deletedElement = this.state.content.splice(id, 1);
    let deletedElementValue = deletedElement.value;
    let filteredArray = this.state.content.filter(
      (item) => item.value !== deletedElementValue
    );
    this.setState({ content: filteredArray });
  };

  submitArticle = () => {
    this.setState({ changesSaved: false });
    const data = new FormData();
    data.append("image", this.state.image);
    data.append("id_club", this.props.currentUser.id_club);

    var total_images = 0;
    var total_text_elements = 0;
    var order = 0;
    for (var i = 0; i < this.state.content.length; i++) {
      if (this.state.content[i]["type"] === "Imagine") {
        data.append(`image_${order}`, this.state.content[i]["value"]);
        total_images++;
      } else {
        data.append(
          `text_${total_text_elements}`,
          `${order}_${this.state.content[i]["type"]}_${this.state.content[i]["value"]}`
        );
        total_text_elements++;
      }
      order++;
    }
    data.append("total_images", total_images);
    data.append("total_text_elements", total_text_elements);

    axios
      .post("site/about_us", data)
      .then(() => {
        store.addNotification({
          title: "Despre noi",
          message: "Modificarile au fost salvate cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.setState({ changesSaved: true });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="about-us-container">
              <div className="about-us-header">
                <div className="about-us-title">
                  {languagePack["about_us_title"]}
                </div>
              </div>
              <div className="image-input-container">
                <input
                  type="file"
                  className="add-news-image-input"
                  id="customFile"
                  onChange={this.handleMainImageChange}
                />
                <label className="add-file-form-file-label" htmlFor="customFile">
                  {this.state.image === ""
                    ? languagePack["upload_main_image"]
                    : languagePack["upload_another_main_image"]}
                </label>
                <Icon
                  name="arrow up"
                  className="cusor-pointer grey-button-icon"
                  size="small"
                />
              </div>
              <div className="sections-title">{languagePack["sections"]}</div>
              {!this.state.contentUploaded ? (
                <div className="loader-container">
                  <Loader
                    type="TailSpin"
                    color={window.clubCustomization.primary_color}
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <div className="about-us-sections-list">
                  {this.state.content.map((item, index) => {
                    return (
                      <div
                        className="about-us-section"
                        key={index}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                        }}
                      >
                        <div className="about-us-section-type">
                          {index + 1}. {item.type}
                        </div>

                        <div className="about-us-section-preview">
                          {item.type === "Paragraf" ||
                            item.type === "Subtitlu" ||
                            item.type === "Text evidentiat"
                            ? item.value
                            : item.value.name}
                        </div>

                        <div className="about-us-section-options">
                          {item.type !== "Imagine" && (
                            <img
                              src={edit_icon}
                              alt="edit"
                              onClick={() =>
                                this.setState({
                                  editedElementIndex: index,
                                  editedElementValue: item.value,
                                })
                              }
                            />
                          )}
                          <img
                            src={delete_icon}
                            alt="delete"
                            onClick={() => this.deleteSection(index)}
                            style={{ marginLeft: 20 }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {(this.state.elementToAdd === "Paragraf" ||
                this.state.elementToAdd === "Subtitlu" ||
                this.state.elementToAdd === "Text evidentiat") && (
                  <div className="element-to-add-container">
                    <TextArea
                      placeholder={this.state.elementToAdd}
                      className="text-area-about-us"
                      value={this.state.elementToAddValue}
                      onChange={this.handleChangeInput}
                      name="elementToAddValue"
                    />

                    <div className="element-to-add-buttons">
                      <Button
                        className="about-us-button dark-background"
                        onClick={this.addSection}
                        style={{ width: "48%" }}
                      >
                        {languagePack["admin_add"]}
                        <Icon name="add" className="about-us-button-icon" />
                      </Button>
                      <Button
                        className="about-us-button light-background"
                        style={{
                          width: "48%",
                        }}
                        onClick={() =>
                          this.setState({
                            elementToAdd: "",
                            elementToAddValue: "",
                          })
                        }
                      >
                        {languagePack["admin_cancel"]}
                        <Icon
                          name="close"
                          className="about-us-button-icon-dark"
                        />
                      </Button>
                    </div>
                  </div>
                )}
              {this.state.elementToAdd === "Imagine" && (
                <div className="add-news-image-container">
                  <div className="add-news-image-preview">
                    {this.state.elementToAddValue !== "" && (
                      <img src={this.state.elementToAddValue} />
                    )}
                  </div>
                  <div className="add-news-image-right-side">
                    <div className="image-input-container  element-to-add-image-input">
                      <input
                        type="file"
                        className="add-news-image-input"
                        id="section-image"
                        onChange={this.handleImageChange}
                      />
                      <label
                        className="add-file-form-file-label"
                        htmlFor="section-image"
                      >
                        {this.state.elementToAddValue
                          ? this.state.elementToAddValue.name
                          : languagePack["upload_image"]}
                      </label>
                      <Icon
                        name="arrow up"
                        className="cusor-pointer grey-button-icon"
                        size="small"
                      />
                    </div>

                    <div className="element-to-add-buttons">
                      <Button
                        className="about-us-button dark-background"
                        onClick={this.addSection}
                        style={{ width: "48%" }}
                      >
                        {languagePack["admin_add"]}
                        <Icon
                          name="checkmark"
                          className="about-us-button-icon"
                        />
                      </Button>
                      <Button
                        className="about-us-button light-background"
                        style={{
                          width: "48%",
                          marginLeft: 15,
                        }}
                        onClick={() =>
                          this.setState({
                            elementToAdd: "",
                            elementToAddValue: "",
                          })
                        }
                      >
                        {languagePack["admin_cancel"]}
                        <Icon
                          name="close"
                          className="about-us-button-icon-dark"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="add-options-buttons">
                <Button
                  className="about-us-button dark-background"
                  onClick={() =>
                    this.setState({
                      elementToAdd: "Subtitlu",
                      elementToAddValue: "",
                    })
                  }
                  style={{ marginBottom: 15 }}
                >
                  Subtitlu
                  <Icon name="add" className="about-us-button-icon" />
                </Button>
                <Button
                  className="about-us-button dark-background"
                  onClick={() =>
                    this.setState({
                      elementToAdd: "Paragraf",
                      elementToAddValue: "",
                    })
                  }
                  style={{ marginBottom: 15 }}
                >
                  Paragraf
                  <Icon name="add" className="about-us-button-icon" />
                </Button>
                <Button
                  className="about-us-button dark-background"
                  onClick={() =>
                    this.setState({
                      elementToAdd: "Text evidentiat",
                      elementToAddValue: "",
                    })
                  }
                >
                  Text evidentiat
                  <Icon name="add" className="about-us-button-icon" />
                </Button>
                <Button
                  className="about-us-button dark-background"
                  onClick={() =>
                    this.setState({
                      elementToAdd: "Imagine",
                      elementToAddValue: "",
                    })
                  }
                >
                  Imagine
                  <Icon name="add" className="about-us-button-icon" />
                </Button>
              </div>
              <div className="about-us-final-buttons">
                <Button
                  className="about-us-button"
                  onClick={this.submitArticle}
                  style={{ width: "66%" }}
                >
                  {this.state.changesSaved ? (
                    <>
                      {languagePack["admin_save"]}
                      <Icon name="add" className="about-us-button-icon" />
                    </>
                  ) : (
                    <Loader
                      type="TailSpin"
                      color="#ffffff"
                      height={25}
                      width={25}
                    />
                  )}
                </Button>
                <Button
                  className="about-us-button dark-background"
                  style={{ width: "32%" }}
                  onClick={() => this.props.history.push("/site/news")}
                >
                  {languagePack["admin_cancel_modif"]}
                  <Icon name="close" className="about-us-button-icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="about-us-container">
              <div className="about-us-header">
                <div className="about-us-title">
                  {languagePack["admin_preview"]}
                </div>
              </div>
              {!this.state.contentUploaded ? (
                <div className="loader-container" style={{ marginTop: 50 }}>
                  <Loader
                    type="TailSpin"
                    color={window.clubCustomization.primary_color}
                    height={40}
                    width={40}
                  />
                </div>
              ) : (
                <div className="about-us-preview-content">
                  {this.state.image !== "" && (
                    <div className="preview-main-image-container">
                      <img src={this.state.image} />
                    </div>
                  )}
                  <div className="preview-title">Despre noi</div>
                  <div className="about-us-preview-text-container">
                    {this.state.content.map((item, index) => {
                      return (
                        <div key={"preview-" + index}>
                          {item.type === "Paragraf" && (
                            <div className="preview-paragraph-container">
                              {item.value}
                            </div>
                          )}
                          {item.type === "Subtitlu" && (
                            <div className="preview-subtitle-container">
                              {item.value}
                            </div>
                          )}
                          {item.type === "Imagine" && (
                            <div className="preview-image-container">
                              <img src={item.value} />
                            </div>
                          )}
                          {item.type === "Text evidentiat" && (
                            <div className="preview-highlighted-container">
                              {item.value}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.editedElementIndex !== ""}
          // onClose={() => this.setState({ editedElementIndex: "" })}
          maxWidth="xl"
        >
          <DialogContent>
            <EditSection
              closePopup={() =>
                this.setState({ editedElementIndex: "" }, this.getAboutContent)
              }
              value={this.state.editedElementValue}
              index={this.state.editedElementIndex}
              id_news="about_us"
              id_club={this.props.currentUser.id_club}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AboutUs);
