import React, { Component } from "react";
import { connect } from "react-redux";
import "./AddSize.scss";
import { store } from "react-notifications-component";
import axios from "axios";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";

class AddSize extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size_name: "",
    };
  }

  addSize = () => {
    const { languagePack, closePopup, refreshList } = this.props;

    const payload = {
      new_size: 1,
      size_name: this.state.size_name,
      id_club: this.props.currentUser.id_club,
    };

    axios
      .post("/size", payload)
      .then((res) => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.sizes,
            message: languagePack.success_add_size,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.sizes,
            message: languagePack.fail_add_size,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.add_size}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            disabled={!this.state.size_name}
            onClick={this.addSize}
          >
            {languagePack.admin_add}
          </TFBButton>
        )}
      >
        <TFBInput
          label={languagePack.size}
          placeholder={languagePack.add_size_name}
          value={this.state.size_name}
          fullWidth={true}
          onChange={(e) => {
            this.setState({ size_name: e.target.value });
          }} />
      </TFBDialog>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddSize);
