import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { showNotification, useAxiosPost } from "../../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";

const DeleteNationalTeamCallUp = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, data } = props;

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("player/delete_national_team_call_up");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.player,
                languagePack.success_delete_national_team_call_up
            );
        } else {
            showNotification(
                "danger",
                languagePack.player,
                languagePack.fail_delete_national_team_call_up
            );
        }
    };

    const deleteCallUp = () => {
        const payload = {
            id_national_team_call_up: data.id_national_team_call_up,
        };
        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteCallUp(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_national_call_up"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_national_call_up_message}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(DeleteNationalTeamCallUp);
