import React, { useReducer } from "react";
import { connect } from "react-redux";
import { size } from "lodash";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";

const AddFile = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, token, idFolder } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            file: [],
            doc_extra_notes: "",
        }
    );

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("upload/upload_document");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.documents,
                languagePack.upload_file_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.documents,
                languagePack.upload_file_fail
            );
        }
    };

    const uploadFile = () => {
        const formData = new FormData();
        formData.append("file", Array.from(state.file)[0]);
        formData.append("document_name", Array.from(state.file)[0].name);
        formData.append("token", token);
        formData.append("extra_notes", state.doc_extra_notes);
        if (idFolder) {
            formData.append("id_folder", idFolder);
        }
        addRequest(formData, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => uploadFile(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        size(Array.from(state.file)) == 0,
        loadingAddRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_document"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBFileInput
                label={languagePack["upload_file"]}
                files={Array.from(state.file).map(elem => elem.name)}
                onChange={(e) => updateState({ file: e.target.files })}
                style={{ marginBottom: 20 }}
            />
            <TFBTextArea
                label={languagePack.file_details}
                value={state.doc_extra_notes}
                onChange={(e) => updateState({ doc_extra_notes: e.target.value })}
            />
        </TFBDialog>
    )
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(AddFile);