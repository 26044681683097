import _ from "lodash";
import React, { useContext, useEffect, useReducer } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import { chartColors } from "../../../utils/utilFunctions";
import { TrainingDataContext } from "./TrainingData";

function TrainingDataChart({ languagePack, clubPlayers }) {
  const { chartParameters, kSportData } = useContext(TrainingDataContext);
  const initialOptions = {
    chart: {
      type: "bar",
      height: 450,

      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth:
          _.size(kSportData?.params) > 2
            ? "70%"
            : _.size(kSportData?.params) > 4
            ? "100%"
            : "40%",
        borderRadius: "3px",
      },
    },
    grid: {
      row: {
        colors: ["#f5f5f5", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    xaxis: {
      categories: _.map(
        kSportData?.players,
        (player) =>
          _.split(player.player.data.player_name, " ")?.[
            _.size(_.split(player.player.data.player_name, " ")) - 1
          ]
      ),
      labels: {
        style: {
          colors: " #B2B2B2",
          fontFamily: "Poppins",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "9px",
        },
      },
    },
    yaxis: _.map(kSportData.params, (param, index) => {
      return {
        seriesName: param?.label,
        labels: {
          style: {
            colors: chartColors()?.[index],
            fontFamily: "Poppins",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "9px",
          },
        },
      };
    }),

    colors: chartColors(),
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return val.toLocaleString();
        },
      },
    },
  };
  const initialSeries = _.map(chartParameters, (param) => {
    return {
      data: _.map(
        kSportData?.players,
        (player) =>
          _.find(
            player.params,
            (obj) => Number(obj?.id_param) === Number(param?.value)
          )?.value
      ),
      name: param?.param_name,
    };
  });

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      tableOptions: initialOptions,
      tableSeries: initialSeries,
      year: 2024,
      team: { value: 0, label: "All" },
    }
  );

  useEffect(() => {
    setState({
      tableSeries: _.map(chartParameters, (param) => {
        return {
          data: _.map(
            kSportData?.players,
            (player) =>
              _.find(
                player?.params,
                (obj) => Number(obj?.id_param) === Number(param?.value)
              )?.value
          ),
          name: param?.label,
        };
      }),
      tableOptions: {
        ...state.tableOptions,
        yaxis: _.map(chartParameters, (param, index) => {
          return {
            seriesName: param?.label,
            labels: {
              style: {
                colors: chartColors()?.[index],
                fontFamily: "Poppins",
                fontSize:
                  _.size(chartParameters) > 2
                    ? "11px"
                    : _.size(chartParameters) > 4
                    ? "8px"
                    : "13px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "9px",
              },
            },
          };
        }),
      },
    });
  }, [chartParameters, kSportData]);

  return (
    <div>
      <Chart
        options={state.tableOptions}
        series={state.tableSeries}
        type="bar"
        height={350}
        width={"100%"}
      />
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(TrainingDataChart);
