import React from "react";
import "./VideoTrainingSection.scss";
import { logout } from "../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class VideoTrainingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render() {
    const { languagePack } = this.props;
    return (
      <>
        <div className="video-training-section">
          <video
            id={this.props.id}
            className="video"
            src={this.props.src}
            controls
            type="video/mp4"
            style={{ width: window.innerWidth * 0.3, margin: "auto" }}
          />
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ logout }, dispatch);
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(VideoTrainingSection);
