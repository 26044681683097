import React from "react";
import "./WebsiteSettings.scss";
import { connect } from "react-redux";
import axios from "axios";
import Switch from "@material-ui/core/Switch";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import { FormControlLabel } from "@mui/material";
import CircularLoader from "../../../components/reusable/Loader";

class WebsiteSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loading: false,
            settings: {},
        };
    }

    componentDidMount = () => {
        this.getSettings();
    };

    getSettings = (loading = true) => {
        this.setState({ is_loading: loading }, () => {
            axios
                .get(`site/get_website_settings?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`)
                .then((res) => {
                    if (res.data.success == 1) {
                        const true_false = {
                            1: true,
                            0: false,
                        };
                        let settings = res.data.list;
                        for (const key in settings) {
                            settings[key] = true_false[settings[key]]
                        }
                        this.setState({
                            settings: settings,
                            is_loading: false,
                        })
                    }
                })
                .catch((err) => console.log(err));
        })
    };

    handleSettingSwitch = (e) => {
        this.setState({
            settings: { ...this.state.settings, ...{ [e.target.value]: e.target.checked } }
        }, () => {
            const true_false = {
                true: 1,
                false: 0,
            };
            let settings = { ...this.state.settings };
            for (const key in settings) {
                settings[key] = true_false[settings[key]]
            }
            axios.post("site/update_website_settings", {
                id_club: this.props.currentUser.id_club,
                id_user: this.props.currentUser.id_user,
                token: this.props.token,
                settings: settings,
            }).then(() => {
                this.getSettings(false);
            });
        });
    }

    render() {
        const { languagePack } = this.props;

        return (
            <div className="row">
                <div className="col-lg-6 col-lg-padding">
                    <TFBCard style={{ height: "100%" }}>
                        <TFBCardHeader title={languagePack.online_payments_settings} />
                        <TFBCardBody>
                            {this.state.is_loading ?
                                <CircularLoader /> :
                                <div className="payment-settings-container">
                                    <FormControlLabel
                                        value="show_online_payments"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch">{languagePack.enable_online_payments_module}</div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.show_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                    <FormControlLabel
                                        value="show_only_online_payments"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch">{languagePack.only_online_payments}</div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.show_only_online_payments}
                                        disabled={!this.state.settings.show_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                    <FormControlLabel
                                        value="show_only_online_payments_iframe"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch" dangerouslySetInnerHTML={{ __html: languagePack.iframe }}></div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.show_only_online_payments_iframe}
                                        disabled={!this.state.settings.show_online_payments || !this.state.settings.show_only_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                    <FormControlLabel
                                        value="fee_passed_to_customer"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch" dangerouslySetInnerHTML={{ __html: languagePack.stripe_fee_passed_to_customer }}></div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.fee_passed_to_customer}
                                        disabled={!this.state.settings.show_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                    <FormControlLabel
                                        value="editable_monthly_tax"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch">{languagePack.client_edit_monthly_tax}</div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.editable_monthly_tax}
                                        disabled={!this.state.settings.show_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                </div>
                            }
                        </TFBCardBody>
                    </TFBCard>
                </div>
                <div className="col-lg-6 col-lg-padding">
                    <TFBCard style={{ height: "100%" }}>
                        <TFBCardHeader title={languagePack.payment_types_settings} />
                        <TFBCardBody>
                            {this.state.is_loading ?
                                <CircularLoader /> :
                                <div className="payment-settings-container">
                                    <FormControlLabel
                                        value="show_another_tax"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch" dangerouslySetInnerHTML={{ __html: languagePack.enable_another_tax }}></div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.show_another_tax}
                                        disabled={!this.state.settings.show_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                    <FormControlLabel
                                        value="show_payment_request"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch" dangerouslySetInnerHTML={{ __html: languagePack.enable_payment_request }}></div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.show_payment_request}
                                        disabled={!this.state.settings.show_online_payments}
                                        onChange={this.handleSettingSwitch}
                                    />
                                </div>
                            }
                        </TFBCardBody>
                    </TFBCard>
                </div>
                <div className="col-lg-6 col-lg-padding">
                    <TFBCard style={{ height: "100%" }}>
                        <TFBCardHeader title={languagePack.enrolment_request_settings} />
                        <TFBCardBody>
                            {this.state.is_loading ?
                                <CircularLoader /> :
                                <div className="payment-settings-container">
                                    <FormControlLabel
                                        value="show_enrolment_request_form"
                                        control={<Switch color="primary" />}
                                        label={<div className="payment-setting-switch">{languagePack.enable_enrolment_form}</div>}
                                        labelPlacement="end"
                                        checked={this.state.settings.show_enrolment_request_form}
                                        onChange={this.handleSettingSwitch}
                                    />
                                </div>
                            }
                        </TFBCardBody>
                    </TFBCard>
                </div>
            </div>
        );
    }
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(WebsiteSettings);