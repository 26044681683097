import React, { ReactElement, ReactNode } from "react";
import { ButtonProps } from "../types";
import styles from "./TFBButton.module.scss";
import { CircularProgress } from "@material-ui/core";

const TFBButton = ({
  disabled = false,
  loading = false,
  children,
  color = "darkGray",
  onClick,
  startIcon,
  endIcon = true,
  size = "lg",
  className,
  renderIcon,
  style,
  badge = undefined,
}: ButtonProps) => {
  let icon;
  if (renderIcon) {
    icon = renderIcon();
  } else {
    icon = "";
  }
  const cssColor = color;
  const cssSize = size;
  return (
    <button
      className={`tfb-button-custom ${styles.tfbbutton} ${className ? className : ""
        } ${styles[cssColor]} ${styles[cssSize]} ${disabled || loading ? styles.disabledBtn : ""
        }`}
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
    >
      <div
        className={styles.tfbbuttonContent}
        style={{
          flexDirection: startIcon ? "row-reverse" : "row",
          justifyContent: "space-between",
        }}
      >
        {children}
        {loading && <CircularProgress size={20} className={styles.loader} />}
        {!loading && (startIcon || endIcon) && icon}
      </div>

      {badge && (
        <div className={styles.badgeContainer}>
          <div className={styles.badgeCircle}>
            <div className={styles.badgeNumber}>{badge}</div>
          </div>
        </div>
      )}
    </button>
  );
};
export default TFBButton;
