import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

const DeleteOrder = (props) => {

    const { languagePack, closePopup, isOpenPopup, refreshList, orderData, token } = props;

    const deleteOrder = () => {
        axios
            .post("/delivery", {
                id_delivery: orderData.id_delivery,
                delete_delivery: 1,
                token: token,
            })
            .then(res => {
                if (res.data.success == 1) {
                    refreshList();
                    store.addNotification({
                        title: languagePack.orders,
                        message: languagePack.success_delete_order,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.orders,
                        message: languagePack.fail_delete_order,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch(e => console.log(e))
    };

    const renderActionButtons = (status) => {
        let buttonsProps = {};

        switch (status) {
            case "5": buttonsProps = {
                btnLeft: () => (
                    <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="cancel" />}
                        onClick={closePopup}
                    >
                        {languagePack.admin_cancel}
                    </TFBButton>
                ),
                btnRight: () => (
                    <TFBButton
                        color="red"
                        renderIcon={() => <TFBIcon name="delete" />}
                        onClick={deleteOrder}
                    >
                        {languagePack.admin_delete}
                    </TFBButton>
                )
            };
                break;
            default: buttonsProps = {
                btnRight: () => (
                    <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="cancel" />}
                        onClick={closePopup}
                    >
                        {languagePack.admin_cancel}
                    </TFBButton>
                )
            };
        }
        return buttonsProps;
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.product_delete_confirmation}
            open={isOpenPopup}
            closePopup={closePopup}
            {...renderActionButtons(orderData.current_status)}
        >
            <div className="pop-up-dialog-delete-text">
                {orderData.current_status == "5" ? languagePack.order_delete_message : languagePack.order_delete_alert}
            </div>
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(DeleteOrder);