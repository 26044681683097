import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Instat.scss";
import { connect } from "react-redux";

const Filler = (props) => {
  return (
    <div className="filler" style={{ width: `${props.percentage}%` }}></div>
  );
};
const ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};

function MatchTeamStats(props) {
  const [stats, setStats] = useState([]);
  const { languagePack } = props;

  useEffect(() => {
    axios
      .get(`instat/match_stats?id_match=${props.id_match}`)
      .then((res) => setStats(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card card-custom">
      <div className="instat-match-stats">
        <div className="match-stats-title">{languagePack["teams_stats"]}</div>
        <div className="match-stats-list">
          {stats.map((item, index) => {
            return (
              <div className="match-stat-row" key={index}>
                <div className="name">{item.name}</div>
                <div className="bar">
                  <div className="value">{item.val_home}</div>
                  <ProgressBar percentage={item.percentage_home} />
                  <div className="value">{item.val_away}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchTeamStats);