import React, { useContext, useEffect, useState } from "react";
import TFBDialog from "../../../../../app/components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../../../app/components/design-system/TFBSelect/TFBSelect";
import useRenderButton from "../../../../../app/components/customHooks/useRenderButton";
import { useHistory } from "react-router-dom";
import { actions } from "../../../../../redux/authRedux";
import { connect, useDispatch } from "react-redux";
import { QuickSetupContext } from "./QuickSetup";
import _ from "lodash";

const SelectTeamPopup = ({
  selectTeamPopup,
  languagePack,
  closePopup,
  clubQuickSetup,
  redirectPage,
}) => {
  // HOOKS ================================================
  // =======================================================================
  const history = useHistory();
  const dispatch = useDispatch();
  const [teamOptions, setTeamOptions] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const { teamsData } = useContext(QuickSetupContext);

  // PROCESS DATA FROM API TO SELECT TEAM OPTIONS ================================================
  // =======================================================================
  useEffect(() => {
    if (teamsData) {
      let teamsArray = _.map(teamsData, (team) => {
        return { label: team.team_name, value: team.id_team };
      });
      teamsArray.unshift({ label: "Adauga o echipa", value: 0 });
      setTeamOptions(teamsArray);
    }
  }, [teamsData]);

  // RENDER DIALOG BUTTONS ================================================
  // =======================================================================
  const { renderBtn: renderBtnRight } = useRenderButton(
    () => confirmTeam(redirectPage),
    "green",
    "check",
    languagePack.admin_confirm
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  // HANDLERS ================================================
  // =======================================================================

  const selectTeam = (team) => {
    setSelectedTeam(team);
  };

  // REDIRECT FUNCTION ================================================
  // =======================================================================
  const confirmTeam = (page) => {
    let historyState = { redirectFromSetup: true };

    if (selectedTeam.value != 0) {
      history.push(`/club/team/${selectedTeam.value}`, historyState);
    } else {
      history.push("/club", historyState);
    }

    dispatch(
      actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: false })
    );
  };

  // RENDER ================================================
  // =======================================================================
  return (
    <TFBDialog
      open={selectTeamPopup}
      fullWidth={true}
      maxWidth={"sm"}
      title={languagePack.select_team}
      closePopup={closePopup}
      btnLeft={renderBtnLeft}
      btnRight={renderBtnRight}
    >
      <TFBSelect
        options={teamOptions}
        value={selectedTeam}
        onChange={selectTeam}
      />
    </TFBDialog>
  );
};

export default connect(
  ({ auth }) => ({
    clubQuickSetup: auth.clubQuickSetup,
  }),
  { updateQuickSetup: actions.updateQuickSetup }
)(SelectTeamPopup);
