import React from "react";
import { TextArea, Button, Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import "./AddTreatmentForm.scss";

class AddTreatmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treatment_name: "",
      treatment_description: "",
      treatment_date_start: null,
      treatment_date_end: null,
      treatment_cost: "",
    };
  }

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChangeStart = (value) => {
    this.setState({ treatment_date_start: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ treatment_date_end: value });
  };

  submitNewTreatment = () => {
    const payload = {
      id_injury: this.props.id_injury,
      treatment_name: this.state.treatment_name,
      treatment_cost: this.state.treatment_cost,
      treatment_description: this.state.treatment_description,
      treatment_date_start: this.state.treatment_date_end,
      treatment_date_end: this.state.treatment_date_end,
    };
    axios.post("medical/add_treatment", payload).then(() => {
      this.props.get_treatment(this.props.id_injury);
      this.props.set_popup(false);
      this.setState({
        treatment_name: "",
        treatment_description: "",
        treatment_date_start: new Date(),
        treatment_date_end: new Date(),
        treatment_cost: "",
      });
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="add-treatment-form-container">
        <div className="add-treatment-row">
          <input
            placeholder={languagePack["name"]}
            className="add-treatment-input"
            value={this.state.treatment_name}
            onChange={this.handleChangeInput}
            name="treatment_name"
          />

          <input
            placeholder="Cost (euro)"
            className="add-treatment-input"
            type="number"
            value={this.state.treatment_cost}
            onChange={this.handleChangeInput}
            name="treatment_cost"
          />
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="add-treatment-row">
            <div className="add-treatment-date-group">
              <DatePicker
                value={this.state.treatment_date_start}
                onChange={this.handleDateChangeStart}
                format="dd MMMM yyyy"
                name="treatment_date_start"
                className="injury-date-input"
                inputVariant="outlined"
                emptyLabel={languagePack["start_date"]}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
            <div className="add-treatment-date-group">
              <DatePicker
                value={this.state.treatment_date_end}
                onChange={this.handleDateChangeEnd}
                format="dd MMMM yyyy"
                name="treatment_date_end"
                inputVariant="outlined"
                className="injury-date-input"
                emptyLabel={languagePack["end_date"]}
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </div>
        </MuiPickersUtilsProvider>

        <TextArea
          placeholder={languagePack["injury_details"]}
          className="text-area-add-treatment"
          value={this.state.treatment_description}
          onChange={(e) =>
            this.setState({ treatment_description: e.target.value })
          }
          name="add_tretment_description"
        />
        <div className="add-treatment-buttons-container">
          <Button
            className="custom-button-dual treatment-cancel-button"
            onClick={this.props.set_popup}
          >
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon name="x" className="add-icon" />
            </div>
          </Button>
          <Button
            className="custom-button-dual treatment-add-button"
            onClick={this.submitNewTreatment}
          >
            <div className="button-content">
              {languagePack["admin_add"]}
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default AddTreatmentForm;
