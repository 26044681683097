import React, { useEffect, useReducer } from "react";
import moment from "moment";
import { connect } from "react-redux";

import styles from "./AddEvent.module.scss";
import useAxiosGet from "../../customHooks/useAxiosGet";
import { baseUrl } from "../../../utils/utilFunctions";
import { showNotification, useAxiosPost } from "../../customHooks/useAxiosPost";
import TFBInput from "../../design-system/Input/TFBInput";
import TFBDropdown from "../../design-system/Dropdown/TFBDropdown";
import TFBSelect from "../../design-system/TFBSelect/TFBSelect";
import TFBDateTimePicker from "../../design-system/DateTimePicker/TFBDateTimePicker";
import TFBTextArea from "../../design-system/TextArea/TFBTextArea";
import TFBDialog from "../../design-system/Dialog/TFBDialog";
import useRenderButton from "../../customHooks/useRenderButton";
import TFBButton from "../../design-system/Button/TFBButton";
import TFBIcon from "../../design-system/Icon/TFBIcon";
import EditEventTypePopup from "./EditEventTypePopup";

function AddEvent({
  token,
  languagePack,
  closePopup,
  currentUser,
  refreshEvents,
}) {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      event_types: [],
      newEventName: "",
      newEventTeam: "",
      newEventCategory: "",
      newEventDescription: "",
      newEventStart: null,
      newEventEnd: null,
      newCategoryName: "",
      newCategoryColor: null,
      addNewCategory: false,
      teamsSelect: null,

      openEditEventTypePopup: false,
    }
  );

  const {
    data: eventTypeData,
    loading: eventTypeLoading,
    refetch: eventTypeRefetch,
  } = useAxiosGet(baseUrl(`calendar/get_event_type_2?token=${token}`));

  const { data: teamsData, loading: teamsLoading } = useAxiosGet(
    baseUrl(`training/get_club_teams?all=0&type=select&token=${token}`)
  );

  const { loading: loadingAddEvent, postData: postAddEvent } = useAxiosPost(
    baseUrl(`calendar/add_event_2`)
  );

  useEffect(() => {
    if (eventTypeData) {
      let eventCategories = eventTypeData;
      eventCategories.forEach(
        (cat) => (cat.text = languagePack?.[cat.text] ?? cat.text)
      );
      setState({ event_types: eventCategories });
    }
  }, [eventTypeData, languagePack]);

  useEffect(() => {
    teamsData && setState({ teamsSelect: teamsData?.list });
  }, [teamsData]);

  const handleChangeDropdown = (e, value) => {
    setState({
      [value.name]: value.value,
    });
  };
  const handleChangeSelect = (selected, value) => {
    setState({ [value.name]: selected });
  };

  const handleDateChangeStart = (value) => {
    setState({ newEventStart: value });
  };

  const handleDateChangeEnd = (value) => {
    setState({ newEventEnd: value });
  };

  const handleSubmitEvent = () => {
    const payload = new FormData();
    payload.append("id_club", currentUser.id_club);
    payload.append("id_team", state.newEventTeam?.value);
    payload.append("title", state.newEventName);
    payload.append("type", state.newEventCategory);
    payload.append("token", token);
    if (state.newEventDescription?.length > 0) {
      payload.append("description", state.newEventDescription);
    }
    payload.append(
      "start",
      moment(state.newEventStart.$d).format("YYYY-MM-DD HH:mm:ss")
    );
    payload.append(
      "end",
      moment(state.newEventEnd.$d).format("YYYY-MM-DD HH:mm:ss")
    );
    payload.append("id_user_added", currentUser.id_user);

    const responseHandler = (response) => {
      if (Number(response.success) === 1) {
        setState({
          addEventDialogOpen: false,
          newEventName: "",
          newEventTeam: "",
          newEventStart: null,
          newEventEnd: null,
          newEventCategory: "",
          newEventDescription: "",
          newCategoryColor: "",
        });
        refreshEvents();
        showNotification(
          "success",
          languagePack.calendar_title,
          languagePack.add_event_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.calendar_title,
          languagePack.add_event_fail
        );
      }
    };
    postAddEvent(payload, responseHandler);
  };

  const { renderBtn: renderBtnRight } = useRenderButton(
    handleSubmitEvent,
    "green",
    "check",
    languagePack.admin_confirm,
    {},
    true,
    state.newEventName?.length == 0 ||
      !state.newEventTeam?.value ||
      state.newEventCategory?.length == 0 ||
      state.newEventStart == null ||
      state.newEventEnd == null ||
      state.addNewCategory,
    loadingAddEvent
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <TFBDialog
      open={true}
      fullWidth={true}
      maxWidth="sm"
      closePopup={closePopup}
      className={styles.dialogWrapper}
      title={languagePack.add_event}
      btnLeft={renderBtnLeft}
      btnRight={renderBtnRight}
    >
      <TFBInput
        label={languagePack.event_name}
        fullWidth
        type="text"
        value={state.newEventName}
        onChange={(e) => setState({ newEventName: e.target.value })}
        placeholder={languagePack.event_name}
        className="add-event-input"
      />
      <TFBSelect
        placeholder={languagePack["choose_team"]}
        label={languagePack["choose_team"]}
        // selection
        isDisabled={teamsLoading}
        isSearchable
        options={state.teamsSelect}
        onChange={handleChangeSelect}
        name="newEventTeam"
        value={state.newEventTeam}
      />
      <div className={styles.flexDivRow}>
        <TFBDropdown
          color="lightGray"
          clearable
          disabled={eventTypeLoading}
          placeholder={languagePack.event_category}
          selection
          label={languagePack.event_category}
          groupStyle={{ width: "100%" }}
          options={state.event_types}
          onChange={handleChangeDropdown}
          name="newEventCategory"
          value={state.newEventCategory}
          className="add-event-dropdown"
        />
        {!state.addNewCategory && (
          <TFBButton
            style={{ minWidth: "202px" }}
            size="lg"
            color="green"
            name="add"
            renderIcon={() => <TFBIcon name="edit" />}
            onClick={() =>
              setState({
                openEditEventTypePopup: true,
              })
            }
          >
            {languagePack.edit_categories}
          </TFBButton>
        )}
      </div>

      <TFBDateTimePicker
        fullWidth
        label={languagePack.starting_date}
        value={state.newEventStart}
        onChange={handleDateChangeStart}
        ampm={false}
        format="dd MMMM  HH:mm"
        name="newEventStart"
        className="add-event-date-input"
      />
      <TFBDateTimePicker
        fullWidth
        label={languagePack.ending_date}
        value={state.newEventEnd}
        onChange={handleDateChangeEnd}
        ampm={false}
        format="dd MMMM  HH:mm"
        name="newEventStart"
        className="add-event-date-input"
      />

      <TFBTextArea
        placeholder={languagePack["description"]}
        label={languagePack["description"]}
        name="newEventDescription"
        value={state.newEventDescription}
        onChange={(e) => {
          setState({ newEventDescription: e.target.value });
        }}
        className="text-area-add-event"
      />

      {state.openEditEventTypePopup && (
        <EditEventTypePopup
          refetchCategories={eventTypeRefetch}
          categories={state.event_types}
          closePopup={() => setState({ openEditEventTypePopup: false })}
        />
      )}
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  language: layoutService.lang,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddEvent);
