import _ from "lodash";
import React, { Component } from "react";
import { Search, Flag, Item } from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { logout } from "./../../utils/api";
const initialState = { isLoading: false, results: [], value: "" };

class SearchExampleStandard extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState, source: [] };
  }

  resultRenderer = (player) => {
    const { player_name, first_nationality } = player;
    return (
      <Item>
        <Item.Group verticalAlign="middle">
          <Item.Header>
            <Flag name={first_nationality.toLowerCase()} />
            {player_name}
          </Item.Header>
          <Item.Description verticalAlign="middle">
            <span className="text-muted font-weight-bold d-block search-bar-item">
              <img
                alt=""
                src={player.image_url}
                style={{
                  width: 15,
                  marginRight: 10,
                }}
              />
              {player.team_name}
            </span>
          </Item.Description>
        </Item.Group>
      </Item>
    );
  };

  componentDidMount = () => {
    document.addEventListener("wheel", this.onWheel);
  };

  onWheel = (event) => {
    event.stopPropagation();
  };

  callPriority = 0;

  searchResults = (value, callPriority) => {
    if (!this.state.isLoading) {
      this.setState(
        {
          isLoading: true,
        },
        () => {
          this.searchResults();
        }
      );
    } else {
      axios
        .get(`${window.secondaryBaseURL}searchbar_player`, {
          params: {
            token: this.props.token,
            value,
          },
        })
        .then((res) => {
          if (this.callPriority === callPriority || 1) {
            if (res?.data?.go_to_login === 1) {
              this.props.logout(this.props.token);
            } else {
              this.setState({ results: res.data });
            }
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  };

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.player_name });
    // window.location.href = `/scouting/player/${result.id_player_tm}`;
  };

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value }, () => {
      if (this.state.value.length < 1) return this.setState(initialState);

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      // const isMatch = (result) => re.test(result.player_name);

      this.callPriority = this.callPriority + 1;
      this.searchResults(value, this.callPriority);
    });
  };

  render() {
    const { languagePack } = this.props;
    const { isLoading, value, results } = this.state;
    return (
      <Search
        style={{ alignSelf: "center" }}
        fluid
        placeholder={`${languagePack.fast_search}...`}
        input={{ icon: "search", iconPosition: "left" }}
        loading={isLoading}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        className="ui active search visible focus search-bar-patch"
        minCharacters={3}
        results={results}
        resultRenderer={this.resultRenderer}
        value={value}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(SearchExampleStandard);
