import React from "react";
import Moment from "react-moment";
import "./MatchDetails.scss";
import calendar from "../../../../assets/icons/calendar.png";
import location_pin from "../../../../assets/icons/location_pin.png";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";

function MatchBasicDetails(props) {

  const { matchDetails } = props;

  return (
    <TFBCard className="card-mb">
      <TFBCardBody>
        <div className="match-basic-details-container">
          <div className="match-teams">
            <div className="team-container">
              <img
                src={matchDetails.home_team_logo_url}
                alt="team_home"
              />
            </div>
            <div className="score-container">
              <div className="score-number">{matchDetails.team_home_goals}</div>
              <div className="score-line">:</div>
              <div className="score-number">{matchDetails.team_away_goals}</div>
            </div>
            <div className="team-container">
              <img
                src={matchDetails.away_team_logo_url}
                alt="team_away"
              />
            </div>
          </div>
          <div className="match-details">
            <div className="match-date">
              <img src={calendar} alt="calendar" />
              <Moment format="DD.MM.YYYY, HH:mm">{matchDetails.match_date}</Moment>
            </div>
            <div className="match-location">
              <img src={location_pin} alt="location" />
              {matchDetails.location}
            </div>
          </div>
        </div>
      </TFBCardBody>
    </TFBCard>
  );
}

export default MatchBasicDetails;
