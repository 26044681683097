import React from "react";
import _ from "lodash";

import { usePress } from "./PressContext";
import styles from "./NewsBox.module.scss";

function NewsBox({ article }) {
  const {
    onClickTag,
    onClickAuthor,
    onClickSource,
    onClickArticleBox,
  } = usePress();

  const {
    labels: tags,
    image: imgSrc,
    title,
    date,
    author,
    source,
    source_logo: sourceLogo,
  } = article;

  return (
    <div onClick={() => onClickArticleBox(article)} className={styles.wrapper}>
      <div
        style={{ backgroundImage: `url(${'"' + imgSrc + '"'})` }}
        className={styles.thumbnailImg}
      ></div>

      <div className={styles.content}>
        <img src={sourceLogo} alt="source" width={75} />
        <div className={styles.title}>
          {_.size(title.replace(/&amp;/g, "&")) < 125
            ? title
            : title.slice(0, 125) + "..."}
        </div>
        <div className={styles.tags}>
          {_.size(tags) > 0 &&
            _.map(tags, (tag) => (
              <div
                key={tag}
                onClick={(e) => onClickTag(e, tag)}
                className={styles.tag}
              >
                {tag}
              </div>
            ))}
        </div>
        <div className={styles.description}>
          {date} {author && " | "}
          <span
            onClick={(e) => onClickAuthor(e, author.name)}
            className={styles.author}
          >
            {" "}
            {author?.name?.replace(/&amp;/g, "&")}
          </span>{" "}
          |{" "}
          <span
            className={styles.source}
            onClick={(e) => onClickSource(e, source)}
          >
            {source}
          </span>
        </div>
      </div>
    </div>
  );
}

export default NewsBox;
