import React, { useEffect, useRef } from "react";
import TFBDialog from "../../../../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../../../components/customHooks/useRenderButton";
import {
  showNotification,
  useAxiosPost,
} from "../../../../../components/customHooks/useAxiosPost";

export default function DeleteNotes({
  openPopup,
  closePopup,
  refreshNotes,
  languagePack,
  id_note,
}) {
  const { data: responseDeleteNote, postData: postDeleteNote } = useAxiosPost(
    "player/delete_player_note"
  );

  const { renderBtn: CancelButton } = useRenderButton(
    closePopup,
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: DeleteButton } = useRenderButton(
    () => handleDelete(),
    "red",
    "delete",
    languagePack.Delete
  );

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (responseDeleteNote.success == 1) {
      showNotification(
        "success",
        languagePack.player_profile,
        languagePack.note_delete_success
      );
      closePopup();
      refreshNotes();
    } else {
      showNotification(
        "danger",
        languagePack.player_profile,
        languagePack.note_delete_fail
      );
    }
  }, [responseDeleteNote]);

  const handleDelete = () => {
    const payload = {
      id_note: id_note,
    };

    postDeleteNote(payload);
  };

  return (
    <TFBDialog
      open={openPopup}
      fullWidth={true}
      maxWidth="sm"
      scroll="paper"
      closePopup={closePopup}
      title={languagePack.delete_note}
      btnRight={DeleteButton}
      btnLeft={CancelButton}
    >
      <div className="ds-main-text-auxiliary" style={{ textAlign: "center" }}>
        {languagePack.delete_note_text}
      </div>
    </TFBDialog>
  );
}
