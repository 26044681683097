import React from "react";
import { IconButton } from "../types";
import styles from "./TFBIconButton.module.scss";
import { CircularProgress } from "@material-ui/core";

const TFBIconButton = ({
  disabled = false,
  loading = false,
  color = "darkGray",
  size = "sm",
  onClick,
  className,
  classNameIcon,
  name,
  style,
  badge = undefined,
  id = "",
  altTitle,
}: IconButton) => {
  const cssColor = color;
  const cssSize = size;
  const iconPath = require(`../../../assets/icons/design-system/${name}.svg`);
  return (
    <button
      className={`${styles.tfbIconButton} ${className ? className : ""} ${styles[cssColor]
        } ${styles[cssSize]} ${disabled || loading ? styles.disabledBtn : ""}`}
      onClick={onClick}
      disabled={disabled || loading}
      style={style}
      id={id}
      title={altTitle}
    >
      {loading && <CircularProgress size={20} className={styles.loader} />}

      {!loading &&
        <img
          src={iconPath}
          className={`${styles.tfbbtnicon} ${classNameIcon ? classNameIcon : ""}`}
          alt={`${name} icon`}
        />
      }
      {badge && (
        <div className={styles.badgeContainer}>
          <div className={styles.badgeCircle}>
            <div className={styles.badgeNumber}>{badge}</div>
          </div>
        </div>
      )}
    </button>
  );
};

export default TFBIconButton;
