import React from "react";
import "./Datatable.scss";
import { Dropdown, Pagination } from "semantic-ui-react";

const itemsPerPage = [
    { key: 1, text: 10, value: 10 },
    { key: 2, text: 20, value: 20 },
    { key: 3, text: 50, value: 50 },
    { key: 4, text: 100, value: 100 },
    { key: 5, text: 500, value: 500 },
  ];

class Datatable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableHeader: props.tableHeader || [],
      contentData: props.contentData || [],
      pagination: props.pagination || {
        currentPage: 1,
        perPage: 20,
        totalPages: 10,
      },
    };
  }
  render() {
    const { languagePack } = this.props;
    return (
      <>
        <div className="bweb-datatable">
          <div className="datatable-controls"></div>
          <div className="datatable-header desktop-header">
            {this.props.tableHeader.map((element, index) => {
              return (
                <div
                  key={index}
                  className={
                    "dt-header-item" + (element.slug ? " " + element.slug : "")
                  }
                  style={{ width: element.width || "auto" }}
                >
                  {element.label}
                </div>
              );
            })}
          </div>
          <div className="datatable-rows">
            {this.props.contentData.map((elementRow, index) => {
              return (
                <div key={index} className="data-row">
                  <div className="header-row">
                    <div className="datatable-header mobile-header">
                      {this.props.tableHeader.map((element, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              "dt-header-item" + (element.slug ? " " + element.slug : "")
                            }
                            style={{ width: element.width || "auto" }}
                          >
                            {element.label}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="content-row">
                    {elementRow.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            "data-row-item" +
                            (this.props.tableHeader[index].slug
                              ? " " + this.props.tableHeader[index].slug
                              : "")
                          }
                          style={{
                            width: this.props.tableHeader[index].width || "auto",
                          }}
                        >
                          {element}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="datatable-pagination">
              <div className="dropdown-wrapper">
                <div className="dropdown-label">{languagePack.campaign_pagination}:</div>
                <Dropdown
                options={itemsPerPage}
                selection
                value={this.state.pagination.perPage}
                style={{ width: 100, minWidth: 100 }}
                />
              </div>
              <Pagination 
                activePage={this.state.pagination.currentPage}
                onPageChange={() => void 0}
                totalPages={this.state.pagination.totalPages}
              />
          </div>
        </div>
      </>
    );
  }
}

export default Datatable;
