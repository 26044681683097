import React from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { Icon, Button } from "semantic-ui-react";
import "./DeleteMatch.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateQuickSetup } from "../../../../utils/api";
import { isTFB } from "../../../../utils/utilFunctions";

function DeleteMatch(props) {
  const { languagePack, currentUser } = props;

  const deletedMatch = () => {
    const payload = {
      id_match: props?.id_match,
      id_club: currentUser.id_club,
      is_tfb: isTFB(),
    };
    axios.post("matches/delete_match_2", payload).then((res) => {
      if (res.data.success == 1) {
        store.addNotification({
          title: props.languagePack["fixtures_schedule"],
          message: props.languagePack["match_successfully_added"],
          message: "Meciul a fost sters cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });

        let clubQuickSetup = res.data.club_quick_setup;
        props.updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });

        props.getMatches();
        props.closePopup();
      }
    });
  };

  return (
    <div className="delete-match-program-popup-container">
      <div className="delete-match-program-header">
        <div className="delete-match-program-title">{"Șterge meci"}</div>
        <Icon
          name="close"
          className="close"
          onClick={() => props.closePopup()}
        />
      </div>
      <div className="delete-match-program-text">
        {
          "Esti sigur ca doresti sa stergi aceast meci? Toate datele aferente vor fi pierdute."
        }
      </div>
      <div className="delete-match-program-buttons">
        <Button className="cancel-button" onClick={() => props.closePopup()}>
          {languagePack["admin_cancel"]}
          <Icon name="close" className="white-icon" />
        </Button>
        <Button className="delete-match-program-button" onClick={deletedMatch}>
          {languagePack["admin_delete"]}
          <Icon name="trash alternate outline" className="white-icon" />
        </Button>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
  }),
  mapDispatchToProps
)(DeleteMatch);
