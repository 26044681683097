import React from "react";
import styles from "./TFBInput.module.scss";
import { TextField } from "@material-ui/core";
import { TInput } from "../types";
import { makeStyles } from "@material-ui/core";

const TFBInput = ({
  className,
  defaultValue,
  disabled,
  error,
  fullWidth,
  id,
  label,
  onBlur,
  multiline,
  onChange,
  placeholder,
  required,
  type,
  variant = "outlined",
  style,
  value,
  componentStyle,
  makeStylesContent,
  inputProps,
  minHeight = "50px",
  ...otherProps
}: TInput) => {
  const classes = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        minHeight: minHeight,
      },
      ...makeStylesContent,
    },
  });

  const ReusableInput = (
    <TextField
      onBlur={onBlur}
      className={`${styles.tfbInput} ${className ? className : ""} ${
        classes().root
      }`}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      id={id}
      multiline={multiline}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      type={type}
      variant={variant}
      style={{
        border: "2px solid #F2F2F2",
        borderColor: "#F2F2F2",
        borderRadius: "3px",
        ...style,
      }}
      inputProps={inputProps}
      {...otherProps}
    />
  );

  return (
    <div className={styles.inputGroup} style={componentStyle}>
      {label && <h6 className={styles.inputLabel}>{label}</h6>}
      {ReusableInput}
    </div>
  );
};

export default TFBInput;
