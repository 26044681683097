import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NumberFormat from "react-number-format";

import views_icon from "../../../../assets/icons/fans-dashboard/video_views.png";
import likes_icon from "../../../../assets/icons/fans-dashboard/video_likes.png";
import comm_icon from "../../../../assets/icons/fans-dashboard/video_comm.png";
import video_link from "../../../../assets/icons/fans-dashboard/video_link.png";


export default function TopVideos(props) {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        getVideos();
    }, [])

    function getVideos() {
        axios.get(`fans/youtube_videos_top?id_club=${props.id_club}`)
            .then((res) => {
                setVideos(res.data)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom">
            <div className="dashboard-box-container">
                <div className="dashboard-box-title" >Most watched videos</div>

                {videos.map((item, index) => (
                    <div className="youtube-video-row">
                        <div className="thumbnail-container">
                            <img className="video-thumbnail" alt="thumnbail" src={item.thumbnail} />
                        </div>
                        <div className="video-details">
                            <div className="video-name">

                                {item.title}
                            </div>
                            <div className="video-stats-row">
                                <div className="video-stat">
                                    <img src={views_icon} alt="view" className="stat-icon" />
                                    <div className="stat-value">
                                        <NumberFormat
                                            value={item.views}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                    <div className="stat-name">
                                        Views
                                    </div>

                                </div>
                                <div className="video-stat">
                                    <img src={likes_icon} alt="like" className="stat-icon" />
                                    <div className="stat-value">
                                        {item.likes}
                                    </div>
                                    <div className="stat-name">
                                        Likes
                                        </div>
                                </div>
                                <div className="video-stat">
                                    <img src={comm_icon} alt="comm" className="stat-icon" />
                                    <div className="stat-value">
                                        {item.comments}
                                    </div>
                                    <div className="stat-name">
                                        Comments
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="redirect-button" onClick={() => window.open(`https://www.youtube.com/watch?v=${item.id_video}`, "_blank")}>
                            <img src={video_link} alt="link" />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}