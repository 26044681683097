import React from "react";
import styles from "./PlayerGallery.module.scss";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

export default function ExpandedImage({ image, closeExpand }) {
  return (
    <div className={styles.photoViewerContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.imgContainer}>
          <img
            className={styles.image}
            src={image}
            alt="expanded_image"
          />
          <TFBIconButton
            name="close-popup"
            color="white"
            onClick={closeExpand}
          />
        </div>
      </div>
    </div>
  );
}
