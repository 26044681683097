//CLUB - DASHBOARD
import team from "./../assets/icons/aside-menu-list-svg/team.svg";
import calendar from "./../assets/icons/aside-menu-list-svg/calendar.svg";
import match from "./../assets/icons/aside-menu-list-svg/match.svg";
import competition from "./../assets/icons/aside-menu-list-svg/competition.svg";
import venue from "./../assets/icons/aside-menu-list-svg/venue.svg";
import match_cost from "./../assets/icons/aside-menu-list-svg/match_cost.svg";
import cards_management from "./../assets/icons/aside-menu-list-svg/cards_management.svg";
import goals_stats from "./../assets/icons/aside-menu-list-svg/goals_stats.svg";
import frf_list from "./../assets/icons/aside-menu-list-svg/frf_list.svg";
import enrolment_request from "./../assets/icons/aside-menu-list-svg/enrolment_request.svg";

//TRAINING
import training_planing from "./../assets/icons/aside-menu-list-svg/training_planing.svg";
import training_history from "./../assets/icons/aside-menu-list-svg/training_history.svg";
import weight_control from "./../assets/icons/aside-menu-list-svg/weight_control.svg";
import training_section from "./../assets/icons/aside-menu-list-svg/training_section.svg";
import training_presence_report from "./../assets/icons/aside-menu-list-svg/training_presence_report.svg";

//REPORT
import physical_test from "./../assets/icons/aside-menu-list-svg/physical_test.svg";
import evaluation from "./../assets/icons/aside-menu-list-svg/evaluation.svg";

//FINANCIAL
import bonus_monitoring from "./../assets/icons/aside-menu-list-svg/bonus_monitoring.svg";
import cost from "./../assets/icons/aside-menu-list-svg/cost.svg";
import online_payment from "./../assets/icons/aside-menu-list-svg/online_payment.svg";
import neccessity_report from "./../assets/icons/aside-menu-list-svg/neccessity_report.svg";
import justifying_document from "./../assets/icons/aside-menu-list-svg/justifying_document.svg";
import statement from "./../assets/icons/aside-menu-list-svg/statement.svg";
import receipt from "./../assets/icons/aside-menu-list-svg/receipt.svg";
import payment_request from "./../assets/icons/aside-menu-list-svg/payment_request.svg";
import budget from "./../assets/icons/aside-menu-list-svg/budget.svg";
import payment_management from "./../assets/icons/aside-menu-list-svg/payment_management.svg";
import financial_report from "./../assets/icons/aside-menu-list-svg/financial_report.svg";

//MEDICAL
import injury from "./../assets/icons/aside-menu-list-svg/injury.svg";

//DOCUMENTS
import document from "./../assets/icons/aside-menu-list-svg/document.svg";
import contract_player from "./../assets/icons/aside-menu-list-svg/contract_player.svg";
import contract_staff from "./../assets/icons/aside-menu-list-svg/contract_staff.svg";

//FANS
import fans_list from "./../assets/icons/aside-menu-list-svg/fans_list.svg";
import fans_import from "./../assets/icons/aside-menu-list-svg/fans_import.svg";
import fans_campaign from "./../assets/icons/aside-menu-list-svg/fans_campaign.svg";
import fans_social_media from "./../assets/icons/aside-menu-list-svg/fans_social_media.svg";
import tax_redirection from "./../assets/icons/aside-menu-list-svg/tax_redirection.svg";

//VIDEO
import video from "./../assets/icons/aside-menu-list-svg/video.svg";

//WEBSITE ADMINISTRATION
import news from "./../assets/icons/aside-menu-list-svg/news.svg";
import about_us from "./../assets/icons/aside-menu-list-svg/about_us.svg";
import sponsor from "./../assets/icons/aside-menu-list-svg/sponsor.svg";
import photo_video_gallery from "./../assets/icons/aside-menu-list-svg/photo_video_gallery.svg";
import contact from "./../assets/icons/aside-menu-list-svg/contact.svg";
import social_media_connect from "./../assets/icons/aside-menu-list-svg/social_media_connect.svg";
import website_settings from "./../assets/icons/aside-menu-list-svg/website_settings.svg";
import teams_settings from "./../assets/icons/aside-menu-list-svg/teams_settings.svg";
import staff_settings from "./../assets/icons/aside-menu-list-svg/staff_settings.svg";
import privacy_policy from "./../assets/icons/aside-menu-list-svg/privacy_policy.svg";

//PARENTS APP ADMINISTRATION
import parent_user from "./../assets/icons/aside-menu-list-svg/parent_user.svg";
import notificate_user from "./../assets/icons/aside-menu-list-svg/notification.svg";

//SHOP ADMINISTRATION
import product from "./../assets/icons/aside-menu-list-svg/product.svg";
import order from "./../assets/icons/aside-menu-list-svg/order.svg";
import category from "./../assets/icons/aside-menu-list-svg/category.svg";
import size from "./../assets/icons/aside-menu-list-svg/size.svg";

//PLATFORM ADMINISTRATION
import platform_administration from "./../assets/icons/aside-menu-list-svg/platform_administration.svg";

export default {
  TEAM: team,
  CALENDAR: calendar,
  MATCH: match,
  COMPETITION: competition,
  VENUE: venue,
  MATCH_COST: match_cost,
  CARDS_MANAGEMENT: cards_management,
  GOALS_STATS: goals_stats,
  FRF_LIST: frf_list,
  ENROLMENT_REQUEST: enrolment_request,

  TRAINING_PLANING: training_planing,
  TRAINING_HISTORY: training_history,
  WEIGHT_CONTROL: weight_control,
  TRAINING_SECTION: training_section,
  TRAINING_PRESENCE_REPORT: training_presence_report,

  PHYSICAL_TEST: physical_test,
  EVALUATION: evaluation,

  BONUS_MONITORING: bonus_monitoring,
  COST: cost,
  ONLINE_PAYMENT: online_payment,
  NECCESSITY_REPORT: neccessity_report,
  JUSTIFYING_DOCUMENT: justifying_document,
  RECEIPT: receipt,
  PAYMENT_REQUEST: payment_request,
  STATEMENT: statement,
  BUDGET: budget,
  PAYMENT_MANAGEMENT: payment_management,
  FINANCIAL_REPORT: financial_report,

  INJURY: injury,

  DOCUMENT: document,
  CONTRACT_PLAYER: contract_player,
  CONTRACT_STAFF: contract_staff,

  FANS_LIST: fans_list,
  FANS_IMPORT: fans_import,
  FANS_CAMPAIGN: fans_campaign,
  FANS_SOCIAL_MEDIA: fans_social_media,
  TAX_REDIRECTION: tax_redirection,

  VIDEO: video,

  NEWS: news,
  ABOUT_US: about_us,
  SPONSOR: sponsor,
  PHOTO_VIDEO_GALLERY: photo_video_gallery,
  CONTACT: contact,
  SOCIAL_MEDIA_CONNECT: social_media_connect,
  WEBSITE_SETTINGS: website_settings,
  TEAMS_SETTINGS: teams_settings,
  STAFF_SETTINGS: staff_settings,
  PRIVACY_POLICY: privacy_policy,

  PARENT_USER: parent_user,
  NOTIFICATE_USER: notificate_user,
  MOBILE_APP_NEWS: news,
  DOC_TO_SIGN: document,

  PRODUCT: product,
  ORDER: order,
  CATEGORY: category,
  SIZE: size,

  PLATFORM_ADMINISTRATION: platform_administration,
};
