import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";

import styles from "./TrainingBoard.module.scss";

import { BoardContext, BoardProvider } from './BoardContext';

import TFBDialog from '../../../components/design-system/Dialog/TFBDialog';
import CircularLoader from '../../../components/reusable/Loader';

import PickPitch from './PickPitch';
import Header from './Header';
import Toolbar from './Toolbar';
import FootballField from './FootballField';

const DiagramBuilder = () => {
  const { step, setStep, loadingInitialBoardData, initialBoardData } = useContext(BoardContext);

  useEffect(() => {
    if (!loadingInitialBoardData) {
      if (initialBoardData?.json) {
        setStep(2);
      } else {
        setStep(1);
      }
    }
  }, [loadingInitialBoardData])

  const fieldRef = useRef();

  const deleteSelected = () => {
    if (fieldRef?.current) {
      fieldRef.current.deleteSelected();
    }
  }

  const rotateSelected = () => {
    if (fieldRef?.current) {
      fieldRef.current.rotateSelected();
    }
  }

  const clearAll = () => {
    if (fieldRef?.current) {
      fieldRef.current.clearAll();
    }
  }

  const getCanvasObjects = () => {
    if (fieldRef?.current) {
      return fieldRef.current.getCanvasObjects();
    }
  }

  const getCanvasImage = () => {
    if (fieldRef?.current) {
      return fieldRef.current.getCanvasImage();
    }
  }

  if (loadingInitialBoardData) {
    return <CircularLoader />
  }

  return (
    <>
      {step === 1 &&
        <PickPitch />
      }
      {step === 2 &&
        <>
          <Header {...{ deleteSelected, rotateSelected, clearAll, getCanvasObjects, getCanvasImage }} />
          <div className={styles.background}>
            <Toolbar />
            <FootballField ref={fieldRef} />
          </div>
        </>
      }
    </>
  );
}

const TrainingBoard = (props) => {

  const { openPopup, closePopup, refreshList, languagePack, section, token } = props;

  const [isCanvasDirty, setIsCanvasDirty] = useState(false);

  const handlePopupClose = () => {
    if (isCanvasDirty) {
      const confirmLeave = window.confirm(languagePack.tactic_board_unsaved_changes);
      if (!confirmLeave) {
        return; // Prevent closing
      }
    }
    closePopup();
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["tactic_board"]}
      open={openPopup}
      closePopup={handlePopupClose}
      fullWidth
      maxWidth="xl"
    >
      <BoardProvider id_training_section={section.id_training_section} section_name={section.section_name} token={token} closePopup={closePopup} refreshList={refreshList} setIsCanvasDirty={setIsCanvasDirty}>
        <DiagramBuilder />
      </BoardProvider>
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(TrainingBoard);