import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../utils/api";
import moment from "moment";
import { Icon, Flag, Button, Dropdown, Image } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye-white.svg";
import "./Notifications.scss";
import TFBPlayerWithAvatar from "../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";

class Notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_sort_btn: "all",
            notifications: [],
        };
    }

    componentWillMount = () => {
    };

    componentDidMount = () => {
        // this.setUpPusherForNotifications();
        this.getNotifications();
    };

    setUpPusherForNotifications = () => {
        // const pusher = new Pusher('ded84999dbba94030977', {
        //     cluster: 'eu'
        // })

        // const channel = pusher.subscribe('notifications-channel-user-' + this.props.currentUser.id_user);
        // const this2 = this;
        // channel.bind('sent-new-notification', function (data) {
        //     if (data.refresh_notifications == 1) {
        //         this2.getNotifications();
        //     }
        // });
    }

    getNotifications = () => {
        axios
            .get(`notification/get_notifications_page?id_user=${this.props.currentUser.id_user}&type=${this.state.selected_sort_btn}`)
            .then((res) => {
                this.setState({
                    notifications: res.data
                })
            })
            .catch((err) => console.log(err));
    }

    seenNotification = (id) => {
        const payload = {
            id_notification: id,
            id_user: this.props.currentUser.id_user,
        };
        axios
            .post(`notification/seen_notification`, payload)
            .then((res) => {
                // setRefreshNotifications(refreshNotifications + 1);
            })
            .catch((err) => console.log(err));
    }

    handleSortNotifications = (type) => {
        this.setState({ selected_sort_btn: type }, this.getNotifications)
    }

    render() {
        const { languagePack } = this.props;

        return (
            <div className="card card-custom">
                <div className="notifications-page">
                    <div className="notifications-header">
                        <div className="header-text">{"Notificari"}</div>
                        <div className="header-sort-buttons">
                            <Button
                                className={"sort-btn " + (this.state.selected_sort_btn == 'all' ? "selected-sort-type" : "unselected-sort-type")}
                                onClick={(event, data) => this.handleSortNotifications(data.type)}
                                type="all"
                            >{"Toate"}</Button>
                            <Button
                                className={"sort-btn " + (this.state.selected_sort_btn == "today" ? "selected-sort-type" : "unselected-sort-type")}
                                onClick={(event, data) => this.handleSortNotifications(data.type)}
                                type="today"
                            >{"Astazi"}</Button>
                            <Button
                                className={"sort-btn " + (this.state.selected_sort_btn == "this_week" ? "selected-sort-type" : "unselected-sort-type")}
                                onClick={(event, data) => this.handleSortNotifications(data.type)}
                                type="this_week"
                            >{"Saptamana aceasta"}</Button>
                            <Button
                                className={"sort-btn " + (this.state.selected_sort_btn == "this_month" ? "selected-sort-type" : "unselected-sort-type")}
                                onClick={(event, data) => this.handleSortNotifications(data.type)}
                                type="this_month"
                            >{"Luna aceasta"}</Button>
                            <Button
                                className={"sort-btn " + (this.state.selected_sort_btn == "this_year" ? "selected-sort-type" : "unselected-sort-type")}
                                onClick={(event, data) => this.handleSortNotifications(data.type)}
                                type="this_year"
                            >{"Anul acesta"}</Button>
                        </div>

                    </div>
                    {this.state.notifications.length > 0 ?
                        <>
                            <div className="notifications-table">
                                <div className="table-header">
                                    <div className="not-type col-header">{"Tip notificare"}</div>
                                    <div className="not-date col-header">{"Data notificarii"}</div>
                                    <div className="not-description col-header">{"Descriere"}</div>
                                    <div className="not-view col-header"></div>
                                </div>
                                <div className="table-rows">
                                    {this.state.notifications.map((elem, index) => {
                                        return <div className="notification-container"
                                            style={{
                                                backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                                            }}
                                        >
                                            <div className="not-type col1">
                                                <TFBPlayerWithAvatar avatarUrl={elem.notification_avatar} />
                                            </div>
                                            <div className={"not-date col1 " + (elem.seen == 1 ? "read" : "unread")}>
                                                {moment(elem.notification_date).format('DD.MM.YYYY, HH:mm')}
                                            </div>
                                            <div className={"not-description col1 " + (elem.seen == 1 ? "read" : "unread")}>
                                                {elem.notification_title + ": " + elem.notification_subtitle}
                                            </div>
                                            <div className="not-view col1">
                                                <div className="see-icon"
                                                    onClick={() => {
                                                        this.props.history.push(elem.redirect_url);
                                                        this.seenNotification(elem.id_notification);
                                                    }}
                                                >
                                                    <EyeIcon />
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                            <div className="notifications-table-mobile">
                                <div className="table-rows">
                                    {this.state.notifications.map((elem, index) => {
                                        return <div className="notification-container"
                                            style={{
                                                backgroundColor: index % 2 == 1 ? "#fafafa" : "",
                                            }}
                                        >
                                            <div className="row-first row-not">
                                                <div className="not-icon">
                                                    <TFBPlayerWithAvatar avatarUrl={elem.notification_avatar} />
                                                </div>
                                                <div className={"not-date " + (elem.seen == 1 ? "read" : "unread")}>
                                                    {moment(elem.notification_date).format('DD.MM.YYYY, HH:mm')}
                                                </div>
                                            </div>
                                            <div className="row-second row-not">
                                                <div className={"not-description " + (elem.seen == 1 ? "read" : "unread")}>
                                                    {elem.notification_title + ": " + elem.notification_subtitle}
                                                </div>
                                                <div className="not-view ">
                                                    <div className="see-icon"
                                                        onClick={() => {
                                                            this.props.history.push(elem.redirect_url);
                                                            this.seenNotification(elem.id_notification);
                                                        }}
                                                    >
                                                        <EyeIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </> :
                        <div className="no-notifications-message">
                            <Icon name="search minus" color="black" size="big" />
                            <div className="no-injuries-placeholder-text">
                                {"Nu sunt notificari"}
                            </div>
                        </div>
                    }
                </div>
            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
}
export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(Notifications);
