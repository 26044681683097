import React from 'react';
import SubscribersCount from './SubscribersCount';
import TopVideos from './TopVideos';
import VideosVsViews from './VideosVsViews';

export default function YoutubeDashboard(props) {
    return (
        <>
            <div className="row">
                <div className="col-lg-6">
                    <VideosVsViews id_club={props.id_club} />
                </div>
                <div className="col-lg-6">
                    <SubscribersCount id_club={props.id_club} />
                </div>
            </div>
            <div className="row" style={{ marginTop: 25 }}>
                <div className="col-lg-6">
                    <TopVideos id_club={props.id_club} />
                </div>
                <div className="col-lg-6">

                </div>
            </div>
        </>
    )
}