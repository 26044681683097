import * as layoutServiceActions from "../LayoutService/layout.service.actions";
import languages from "./../../app/utils/languages";

export const REQUEST_LANGUAGE = "language/";

export default function layoutServiceFunctions(dispatch) {
    return {
        setLanguage: function(lang) {
            if(languages.findIndex(item => item.language === lang) === -1) {
                lang = languages[0].language;
            }
            dispatch(layoutServiceActions.setLanguage({lang}));
            localStorage.setItem("last-lang", lang);
        },
        setClub: function(club) {
            dispatch(layoutServiceActions.setClub({club}));
            localStorage.setItem("club", club);
        }
    }
}