import React, { useState, useEffect } from "react";

import { store } from "react-notifications-component";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";

import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

const ImportLineup = (props) => {

    const { languagePack, closePopup, isOpenPopup, idMatch, idTeam, idManualCompetitionTeam, refreshLineupsAndSubs } = props;

    const [matches, setMatches] = useState([]);
    const [selectedMatch, setSelectedMatch] = useState(null);
    const [importSubstitutes, setImportSubstitutes] = useState(false);

    useEffect(() => {
        getMatches();
    }, [])

    const getMatches = () => {
        axios
            .get(`matches/get_matches_for_import_lineups?id_team=${idTeam}&id_match=${idMatch}`)
            .then((res) => {
                setMatches(res.data.matches);
            })
            .catch((err) => console.log(err));
    }

    const importLineup = () => {
        const payload = {
            id_team: idTeam,
            id_manual_competition_team: idManualCompetitionTeam,
            id_match: idMatch,
            import_substitutes: importSubstitutes,
            id_match_from: selectedMatch?.value,
        }

        axios
            .post(`matches/import_lineup_from_another_match`, payload)
            .then((res) => {
                if (res.data.success == 1) {
                    refreshLineupsAndSubs();
                    store.addNotification({
                        title: languagePack.match,
                        message: languagePack.success_import_lineup,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.match,
                        message: languagePack.fail_import_lineup,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch((err) => console.log(err));
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.import_lineups}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={() => (
                <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    onClick={closePopup}
                >
                    {languagePack.admin_cancel}
                </TFBButton>
            )}
            btnRight={() => (
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="check" />}
                    onClick={importLineup}
                    disabled={selectedMatch == null}
                >
                    {languagePack.import_data}
                </TFBButton>
            )}
        >
            <TFBSelect
                label={languagePack.select_match}
                value={selectedMatch}
                options={matches}
                onChange={selected => setSelectedMatch(selected)}
                style={{ control: { marginBottom: 20 } }}
            />
            <TFBCheckbox
                label={languagePack.import_also_substitutes}
                disabled={selectedMatch == null}
                checked={importSubstitutes}
                onChange={() => setImportSubstitutes(!importSubstitutes)}
                className="tab-control-line"
            />
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(ImportLineup);