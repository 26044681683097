import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import styles from "./AddTrainingSectionPopup.module.scss";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import { TAddTrainingSessionPopup } from "../../../components/design-system/types";

const AddTrainingSessionPopup = ({
  openPopup,
  filters,
  refetchSections,
  closePopup,
  languagePack,
  token,
}: TAddTrainingSessionPopup) => {
  const initialState = {
    img: null,
    addImg: false,
    videoUrl: "",
    token: token,
    sectionName: "",
    sectionDescription: "",

    age_group: [],
    section_type: [],
    accessibility: null,
    players_no: "",
    goalkeepers_no: "",

    openImage: false,
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "changeName":
        return { ...state, sectionName: action.payload };
      case "changeDescription":
        return { ...state, sectionDescription: action.payload };
      case "updatePlayersNo":
        return { ...state, players_no: action.payload };
      case "updateGoalkeepersNo":
        return { ...state, goalkeepers_no: action.payload };

      case "addImage":
        return { ...state, img: action.payload, addImg: true };
      case "addVideo":
        return { ...state, videoUrl: action.payload };
      case "noFile":
        return { ...state, addImg: false };
      case "openImagePopup":
        return { ...state, openImage: true };
      case "closeImagePopup":
        return { ...state, openImage: false };
      case "removeImage":
        return { ...state, img: null, addImg: false };

      case "updateAge_group":
        return { ...state, age_group: action.payload };
      case "updateSection_type":
        return { ...state, section_type: action.payload };
      case "updateAccessibility":
        return { ...state, accessibility: action.payload };

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { loading, postData } = useAxiosPost("training/add_training_section_3");

  function handleChangeName(e: any) {
    dispatch({ type: "changeName", payload: e.target.value });
  }
  function handleChangeDescription(e: any) {
    dispatch({ type: "changeDescription", payload: e.target.value });
  }

  function handleResponse(response: { success: number }) {
    if (response.success === 1) {
      refetchSections && refetchSections();

      showNotification(
        "success",
        languagePack.training_sections,
        languagePack.add_training_section_success
      );
      closePopup();
    } else {
      showNotification(
        "danger",
        languagePack.training_sections,
        languagePack.add_training_section_fail
      );
    }
  }
  const sectionTagsToPost = [
    ..._.map(state.age_group, (t): object => {
      return { id_category: 1, id_tag: Number(t?.value) };
    }),
    ..._.map(state.section_type, (t): object => {
      return { id_category: 2, id_tag: Number(t?.value) };
    }),
    { id_category: 3, id_tag: state.accessibility?.value },
  ];

  function addNewSection() {
    const payload = {
      section: {
        section_description: state.sectionDescription,
        section_name: state.sectionName,
        no_of_players: state.players_no,
        no_of_gks: state.goalkeepers_no,
      },
      add_img: state.addImg,
      img_base64: state.img,
      token: state.token,
      tags: sectionTagsToPost,
    };
    postData(payload, handleResponse);
  }

  const getImagefromFile = (files: any, file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      dispatch({ type: "addImage", payload: reader.result });
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };
  // const getVideofromFile = (files: any, file: any) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function() {
  //     dispatch({ type: "addVideo", payload: reader.result });
  //   };
  //   reader.onerror = function(error) {
  //     console.log("Error: ", error);
  //   };
  // };

  const onChangeImage = (e: any) => {
    if (_.size(e.target.files) > 0) {
      getImagefromFile(e.target.files, e.target.files[0]);
    } else {
      dispatch({ action: "noFile" });
    }
  };
  // const onChangeVideo = (e: any) => {
  //   console.log(e.target.files);
  //   if (_.size(e.target.files) > 0) {
  //     getVideofromFile(e.target.files, e.target.files[0]);
  //   } else {
  //     dispatch({ action: "noFile" });
  //   }
  // };

  const handleChangeSelect = (selected: any, value: any) => {
    dispatch({
      type: `update${value.name[0].toUpperCase() + value.name.slice(1)}`,
      payload: selected,
    });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["add_section"]}
      open={true}
      closePopup={closePopup}
      fullWidth
      maxWidth="sm"
      btnRight={() => (
        <TFBButton
          onClick={addNewSection}
          disabled={!state.sectionName}
          color="green"
          renderIcon={() => <TFBIcon name="add" />}
          loading={loading}
        >
          {languagePack.add_section}
        </TFBButton>
      )}
      btnLeft={() => (
        <TFBButton
          onClick={closePopup}
          color="darkGray"
          renderIcon={() => <TFBIcon name="cancel" />}
        >
          {languagePack.admin_cancel}
        </TFBButton>
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.addNameDiv}>
          <TFBInput
            value={state.sectionName}
            onChange={handleChangeName}
            label={languagePack.name}
            placeholder={languagePack.add_section_name}
            style={{ backgroundColor: "#fafafa", width: "100%" }}
          ></TFBInput>
        </div>
        {/* <span className={styles.subtitle}>{languagePack.Section_labels}</span> */}
        {/* //// Labels ////// */}
        <div className={styles.labelsDiv}>
          <TFBSelect
            label={languagePack.age_group}
            isMulti={true}
            options={filters?.[0]?.children ?? []}
            value={state.age_group}
            name="age_group"
            onChange={(selected, value) => handleChangeSelect(selected, value)}
          />
          <TFBSelect
            label={languagePack.section_type}
            isMulti={true}
            options={filters?.[1]?.children ?? []}
            value={state.section_type}
            name="section_type"
            onChange={(selected, value) => handleChangeSelect(selected, value)}
          />
          <TFBSelect
            label={languagePack.accessibility}
            options={filters?.[2]?.children ?? []}
            value={state.accessibility}
            isClearable={true}
            name="accessibility"
            onChange={(selected, value) => handleChangeSelect(selected, value)}
          />
          <div className={styles.numberInputFilters}>
            <TFBInput
              fullWidth={true}
              label={languagePack.number_of_players}
              value={state.players_no}
              onChange={(e) =>
                dispatch({ type: "updatePlayersNo", payload: e.target.value })
              }
              type="number"
            />
            <TFBInput
              fullWidth={true}
              value={state.goalkeepers_no}
              onChange={(e) =>
                dispatch({
                  type: "updateGoalkeepersNo",
                  payload: e.target.value,
                })
              }
              label={languagePack.number_of_goalkeepers}
              type="number"
            />
          </div>
        </div>

        <div className={styles.descriptionDiv}>
          <TFBTextArea
            value={state.sectionDescription}
            onChange={handleChangeDescription}
            style={{ outline: "none", border: "2px solid #f2f2f2 " }}
            label={languagePack.description}
            placeholder={languagePack.add_section_description}
          ></TFBTextArea>
        </div>

        {/* <span className={styles.subtitle}>{languagePack.Media}</span> */}
        <div className={styles.fileInputs} style={{ justifyContent: "start" }}>
          {state.img ? (
            <div className={styles.imgDiv}>
              <TFBIconButton
                name="x-small"
                onClick={() => dispatch({ type: "removeImage" })}
                style={{
                  height: "20px",
                  borderRadius: "10px",
                  padding: "0px",
                  width: "20px",
                  position: "absolute",
                  top: "-10px",
                  right: "-17.5px",
                }}
              />
              <img
                onClick={() => dispatch({ type: "openImagePopup" })}
                style={{ height: "100%" }}
                src={state.img}
                alt="section"
              />
            </div>
          ) : (
            <TFBFileInput
              style={{ width: "45%" }}
              inputFileStyle={{
                height: "96px",
                flexDirection: "column",
                justifyContent: "center",
                gap: "12.5px",
              }}
              accept=".png, .jpg, .jpeg, .webp"
              onChange={onChangeImage}
              files={state.img}
              label={languagePack.Image}
              placeholder={languagePack.upload_image}
            />
          )}

          {/* ////////////////////// VIDEO INPUT ////////////////////////  */}
          {/* {state.videoUrl ? (
            <div
              style={{
                position: "relative",
                height: "150px",
              }}
            >
              <TFBIconButton
                name="x-small"
                onClick={() => dispatch({ type: "removeImage" })}
                style={{
                  height: "20px",
                  borderRadius: "10px",
                  padding: "0px",
                  width: "20px",
                  position: "absolute",
                  zIndex: "111111111",
                  top: "-10px",
                  right: "-17.5px",
                }}
              />
              <TFBIconButton
                name="play"
                color="green"
                style={{
                  position: "absolute",
                  top: "57.5px",
                  left: "115.83px",
                  zIndex: "111111000",
                }}
              />
              <img
                style={{ height: "100%", opacity: "0.5" }}
                src="/media/video-thumbnails/training-video-thumbnail.jpeg"
                alt="training video"
              />{" "}
            </div>
          ) : (
            <TFBFileInput
              style={{ width: "45%" }}
              inputFileStyle={{
                height: "96px",
                flexDirection: "column",
                justifyContent: "center",
                gap: "12.5px",
              }}
              onChange={
                onChangeVideo
                // console.log(e.target);
                // dispatch({ type: "addVideo", payload: e.target.files });
              }
              files={state.videoUrl}
              accept=".mp4, .mov"
              label={languagePack.Video}
              placeholder={languagePack.upload_video}
            />
          )} */}
        </div>
        {/* <TFBButton
          style={{ width: "45%" }}
          renderIcon={() => <TFBIcon name="edit" />}
        >
          {" "}
          {languagePack.Create_new_tactic_board}{" "}
        </TFBButton> */}
      </div>
      {state.openImage && (
        <TFBDialog
          open
          fullWidth={true}
          closePopup={() => {
            dispatch({ type: "closeImagePopup" });
          }}
          maxWidth="lg"
          title={state.sectionName}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "70%" }}
            src={state.img}
            alt="training section"
          ></img>
        </TFBDialog>
      )}
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }: any) => ({
  languagePack: layoutService.languagePack,
  token: auth?.user?.token,
  currentUser: auth?.user,
}))(AddTrainingSessionPopup);
