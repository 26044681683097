import React from "react";
import axios from "axios";
import { TextArea } from "semantic-ui-react";
import { connect } from "react-redux";
import { store } from "react-notifications-component";
import moment from "moment";

import { ReactComponent as XlsLogo } from "./../../../assets/SVG/xls.svg";
import { ReactComponent as DocLogo } from "./../../../assets/SVG/doc.svg";
import { ReactComponent as PdfLogo } from "./../../../assets/SVG/pdf.svg";
import { ReactComponent as JpgLogo } from "./../../../assets/SVG/jpg.svg";
import { ReactComponent as PngLogo } from "./../../../assets/SVG/png.svg";
import { ReactComponent as TxtLogo } from "./../../../assets/SVG/txt.svg";
import { ReactComponent as CsvLogo } from "./../../../assets/SVG/csv.svg";
import { ReactComponent as ZipLogo } from "./../../../assets/SVG/zip.svg";
import { ReactComponent as VideoLogo } from "./../../../assets/SVG/video.svg";
import { ReactComponent as SearchLogo } from "./../../../assets/SVG/search.svg";
import { ReactComponent as AudioLogo } from "./../../../assets/SVG/audio.svg";

import { TableContainer } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { sxStickyColumn, TFBTableCell } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import { baseUrl } from "../../../utils/utilFunctions";

class DocumentsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addDocOpenDialog: false,
      addedFile: "",
      doc_extra_notes: "",
      error: "",
      fileUploading: false,

      docs: [],

      open_delete_dialog: false,
      deleting_doc: "",
    };
  }

  componentDidMount = () => {
    this.getDocs();
  };

  getDocs = () => {
    axios
      .get(
        `upload/get_documents_for_entity_profile?type_entity=player&id_entity=${this.props.id_player}&id_club=${this.props.currentUser.id_club}`
      )
      .then((res) =>
        this.setState({
          docs: res.data,
        })
      )
      .catch((err) => console.log(err));
  };

  getFileTypeIcon = (value) => {
    if (value === "xlsx" || value === "xls")
      return <XlsLogo className="type-logo doc-icon" />;
    if (value === "doc" || value === "docx")
      return <DocLogo className="type-logo doc-icon" />;
    if (value === "pdf") return <PdfLogo className="type-logo doc-icon" />;
    if (value === "jpg") return <JpgLogo className="type-logo doc-icon" />;
    if (value === "png") return <PngLogo className="type-logo doc-icon" />;
    if (value === "txt") return <TxtLogo className="type-logo doc-icon" />;
    if (value === "csv") return <CsvLogo className="type-logo doc-icon" />;
    if (value === "mp3") return <AudioLogo className="type-logo doc-icon" />;
    if (value === "zip") return <ZipLogo className="type-logo doc-icon" />;
    if (
      value === "m4v" ||
      value === "avi" ||
      value === "mpg" ||
      value === "mp4"
    )
      return <VideoLogo className="type-logo doc-icon" />;
    else return <SearchLogo className="type-logo doc-icon" />;
  };

  closePopup = () => {
    this.setState({
      addDocOpenDialog: false,
      addedFile: "",
      doc_extra_notes: "",
      error: "",
    });
  };

  closeDeleteDialog = () => {
    this.setState({ open_delete_dialog: false, deleting_doc: "" })
  }

  onFileChange = (e) => {
    this.setState({
      addedFile: e.target.files[0],
    });
  };

  addFile = () => {
    const file = this.state.addedFile;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id_club", this.props.currentUser.id_club);
    formData.append("uploaded_by", this.props.currentUser.nickname);
    formData.append("extra_notes", this.state.doc_extra_notes);
    formData.append("entity", "player");
    formData.append("id_entity", this.props.id_player);
    formData.append("id_user", this.props.currentUser.id_user);

    axios
      .post("upload/upload_file_from_entity_profile", formData, {
        params: {
          token: this.props.token,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data == 1) {
          this.getDocs();
          this.closePopup();
          store.addNotification({
            title: "Document",
            message: this.props.languagePack["documents_upload_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          this.closePopup();
          store.addNotification({
            title: "Document",
            message: "Documentul nu a fost incarcat cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteDoc = () => {
    axios
      .post("upload/delete_document_from_entity_profile", {
        id_document: this.state.deleting_doc.id_document,
        token: this.props.token,
      })
      .then((res) => {
        this.setState({
          deleting_doc: "",
          open_delete_dialog: false,
        });
        if (res.data == 1) {
          this.getDocs();
          store.addNotification({
            title: "Document",
            message: "Documentul a fost sters cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: "Document",
            message: "Documentul nu a fost sters cu succes!",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  downloadDoc = (id) => {
    axios
      .get("upload/download_document_from_entity_profile", {
        params: {
          id_document: id,
          token: this.props.token,
        },
      })
      .then((res) => { })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.documents}>
          <TFBButton
            color="green"
            onClick={() => this.setState({ addDocOpenDialog: true })}
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack.add_document}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.docs.length > 0 ?
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>{languagePack.designation}</TFBTableCell>
                    <TFBTableCell>{languagePack.campaign_type}</TFBTableCell>
                    <TFBTableCell>{languagePack.dimension}</TFBTableCell>
                    <TFBTableCell>{languagePack.added_at}</TFBTableCell>
                    <TFBTableCell>{languagePack.added_by}</TFBTableCell>
                    <TFBTableCell align="center">{languagePack.order_actions}</TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.docs.map((doc, index) => (
                    <TableRow
                      key={"match-doc-" + index}
                      sx={{
                        backgroundColor:
                          index % 2 == 0
                            ? "var(--lineBackgroundGray)"
                            : "white",
                      }}
                    >
                      <TFBTableCell
                        component="th"
                        scope="row"
                        sx={[sxStickyColumn, {
                          backgroundColor:
                            index % 2 == 0
                              ? "var(--lineBackgroundGray)"
                              : "white",
                        }]}
                      >
                        <div className="doc-name">
                          {this.getFileTypeIcon(String(doc['type']).toLowerCase())}
                          {doc['document_name']}
                        </div>
                      </TFBTableCell>
                      <TFBTableCell>
                        {String(doc['type']).toLowerCase()}
                      </TFBTableCell>
                      <TFBTableCell>
                        {doc['size']}
                      </TFBTableCell>
                      <TFBTableCell>
                        {moment(doc['date_add']).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell>
                        {doc['uploaded_by']}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container">
                          <a href={baseUrl(`upload/download_document_from_entity_profile?id_document=${doc.id_document}&token=${this.props.token}`)}>
                            <TFBIconButton name="arrow-down" color="black" />
                          </a>
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => this.setState({
                              deleting_doc: doc,
                              open_delete_dialog: true,
                            })}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_data_documents} />
          }
        </TFBCardBody>

        {/* ADAUGARE FISIER */}
        <TFBDialog
          className="pop-up-dialog-box"
          title={languagePack.add_document}
          open={this.state.addDocOpenDialog}
          closePopup={this.closePopup}
          btnLeft={() => (
            <TFBButton
              color="darkGray"
              renderIcon={() => <TFBIcon name="cancel" />}
              onClick={this.closePopup}
            >
              {languagePack.admin_cancel}
            </TFBButton>
          )}
          btnRight={() => (
            <TFBButton
              color="green"
              renderIcon={() => <TFBIcon name="add" />}
              disabled={this.state.fileUploading || !this.state.addedFile}
              onClick={this.addFile}
            >
              {languagePack.admin_add}
            </TFBButton>
          )}
        >
          <div className="add-file-container">
            <div
              className="add-file-form-row"
              style={{
                justifyContent: "space-between",
                paddingRight: 20,
                backgroundColor: "#fafafa",
              }}
            >
              <input
                type="file"
                className="add-file-form-file-input"
                id="customFile"
                onChange={this.onFileChange}
              />
              <label
                className="add-file-form-file-label"
                htmlFor="customFile"
              >
                {this.state.addedFile
                  ? this.state.addedFile.name
                  : languagePack["upload_file"]}
              </label>
              <TFBIcon name="arrow-up" color="gray" className="" />
            </div>

            <TextArea
              placeholder={languagePack["file_details"]}
              className="text-area-file-form"
              value={this.state.doc_extra_notes}
              onChange={(e) =>
                this.setState({ doc_extra_notes: e.target.value })
              }
              name="doc_extra_notes"
            />
            {this.state.error != "" && (
              <div className="end-injury-error-row">{this.state.error}</div>
            )}
          </div>

        </TFBDialog>

        {/* STERGERE FISIER*/}

        <TFBDialog
          className="pop-up-dialog-box"
          title={languagePack.delete_document}
          open={this.state.open_delete_dialog}
          closePopup={this.closeDeleteDialog}
          btnLeft={() => (
            <TFBButton
              color="darkGray"
              renderIcon={() => <TFBIcon name="cancel" />}
              onClick={this.closeDeleteDialog}
            >
              {languagePack.admin_cancel}
            </TFBButton>
          )}
          btnRight={() => (
            <TFBButton
              color="red"
              renderIcon={() => <TFBIcon name="delete" />}
              onClick={this.deleteDoc}
            >
              {languagePack.admin_delete}
            </TFBButton>
          )}
        >
          <div className="pop-up-dialog-delete-text">
            {languagePack.delete_document_message}
          </div>
        </TFBDialog>

      </TFBCard >
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(DocumentsWidget);
