import React from "react";
import { TextArea, Button, Icon } from "semantic-ui-react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { store } from "react-notifications-component";
import "./EditTreatmentForm.scss";

class EditTreatmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treatment_name: this.props.treatment_name,
      treatment_cost: this.props.treatment_cost,
      date_start: this.props.date_start,
      date_end: this.props.date_end,
      description: this.props.treatment_description,
    };
  }

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDateChangeStart = (value) => {
    this.setState({ date_start: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ date_end: value });
  };

  saveEdits = () => {
    const payload = {
      id_treatment: this.props.id_treatment,
      name: this.state.treatment_name,
      cost: this.state.treatment_cost,
      date_start: this.state.date_start,
      date_end: this.state.date_end,
      description: this.state.description,
    };
    axios.post("medical/edit_treatment", payload).then(() => {
      this.props.close_section();
      store.addNotification({
        title: this.props.languagePack['injury_details'],
        message: this.props.languagePack['treatment_edit_success'],
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 2000,
        },
      });
    });
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="edit-treatment-form-container">
        <div className="edit-treatment-row">
          <input
            placeholder={languagePack["name"]}
            className="edit-treatment-input"
            value={this.state.treatment_name}
            onChange={this.handleChangeInput}
            name="treatment_name"
          />

          <input
            placeholder={languagePack['cost']}
            className="edit-treatment-input"
            value={this.state.treatment_cost}
            onChange={this.handleChangeInput}
            name="treatment_cost"
          />
        </div>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="edit-treatment-row">
            <div className="edit-treatment-date-group">
              <DatePicker
                value={this.state.date_start}
                onChange={this.handleDateChangeStart}
                inputVariant="outlined"
                emptyLabel={languagePack["start_date"]}
                format="dd MMMM yyyy"
                name="date_start"
                className="injury-date-input"
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
            <div className="edit-treatment-date-group">
              <DatePicker
                value={this.state.date_end}
                onChange={this.handleDateChangeEnd}
                inputVariant="outlined"
                emptyLabel={languagePack["end_date"]}
                format="dd MMMM yyyy"
                name="date_end"
                className="injury-date-input"
              />
              <Icon
                name="calendar alternate outline"
                className="calendar-icon"
                size="small"
              />
            </div>
          </div>
        </MuiPickersUtilsProvider>

        <TextArea
          placeholder={languagePack["treatment_details"]}
          className="text-area-edit-treatment"
          value={this.state.description}
          onChange={(e) => this.setState({ description: e.target.value })}
        />
        <div className="edit-treatment-buttons-container">
          <Button
            className="custom-button-dual treatment-cancel-button"
            onClick={this.props.close_section}
          >
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon name="x" className="add-icon" />
            </div>
          </Button>
          <Button
            className="custom-button-dual treatment-add-button"
            onClick={this.saveEdits}
          >
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon name="add" className="add-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default EditTreatmentForm;
