import React, { useState } from "react";
import axios from "axios";
import "./Photos.scss";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

const AddPhotoToPlayerGallery = ({ languagePack, closePopup, isOpenPopup, refreshList, id }) => {

    const [files, setFiles] = useState([]);

    const handleChange = (event) => {
        setFiles(event.target.files);
    }

    const handleSubmitUpload = () => {
        const data = new FormData();

        for (let i = 0; i < files.length; i++) {
            data.append("photos[]", files[i]);
        }
        data.append("id_entity", id);

        axios
            .post(`player/upload_image_to_player_gallery?`, data)
            .then((res) => {
                closePopup();
                if (res.data.success == 1) {
                    store.addNotification({
                        title: languagePack['gallery_notification_title'],
                        message: languagePack['gallery_notification_success'],
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                    refreshList();
                } else {
                    if (res.data?.error_type == 'partial_uploaded') {
                        store.addNotification({
                            title: languagePack['gallery_notification_title'],
                            message: languagePack.gallery_notification_partial_upload,
                            type: "warning",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2000,
                            },
                        });
                        refreshList();
                    } else {
                        store.addNotification({
                            title: languagePack['gallery_notification_title'],
                            message: languagePack.gallery_notification_fail,
                            type: "danger",
                            insert: "bottom",
                            container: "bottom-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2000,
                            },
                        });
                    }
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.upload_image}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={() => (
                <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    onClick={closePopup}
                >
                    {languagePack.admin_cancel}
                </TFBButton>
            )}
            btnRight={() => (
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                    onClick={handleSubmitUpload}
                >
                    {languagePack.admin_add}
                </TFBButton>
            )}
        >
            <div className="image-input-container-photos">
                <input
                    id="customFile"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    multiple
                    onChange={handleChange}
                />
                <label htmlFor="customFile">
                    {files.length > 0
                        ? files.length + " " + (files.length === 1
                            ? languagePack['gallery_upload_nr_singular']
                            : languagePack['gallery_upload_nr_plural']) +
                        " (" + Array.from(files).map(elem => elem.name).join(", ") + ")"
                        : languagePack['gallery_upload_select_files']}
                </label>
                <TFBIcon
                    name="arrow-up"
                    color="gray"
                />
            </div>
        </TFBDialog>

    );
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(AddPhotoToPlayerGallery);
