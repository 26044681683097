import React, { createContext, useContext, useEffect, useReducer } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  baseUrl,
  isTFB,
  removeAgeGroupFromTeamName,
} from "../../../../utils/utilFunctions";
import styles from "./AddCompetition.module.scss";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import ImportCompetitionForm from "./ImportCompetitionForm";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import ImportCompetitionConfirmation from "./ImportCompetitionConfirmation";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import AddManualCompetition from "./AddManualCompetition";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import { CompetitionsContext } from "./Competitions";

export const AddCompetitionContext = createContext("");

function AddCompetition({ languagePack, token, currentUser }) {
  const competitionsContext = useContext(CompetitionsContext);

  const history = useHistory();

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      competitionOption: "import",

      selectedSource: "",

      selectedCounty: "",

      selectedCompetition: "",
      selectedSeries: "",
      selectedClub: "",
      competitionClubs: [],
      selectedClubTeam: "",
      showCompetitionMatches: false,
      importedCompetitionMinutesPerHalf: 45,
      importConfirmationCheckLogos: false,

      manualCompetitionName: "",
      manualCompetitionNoTeams: "",
      manualCompetitionNoGroups: 0,
      manualCompetitionHasMultipleAgeGroups: false,
      manualCompetitionType: "",
      manualCompetitionMinutesPerHalf: 45,
      manualCompetitionDateStart: null,
      manualCompetitionDateEnd: null,
      manualCompetitionAgeGroup: "",
    }
  );
  const {
    data: countiesData,
    loading: loadingCounties,
    refetch: refetchCounties,
  } = useAxiosGet(baseUrl(`competitionSourceBeta/get_county`), true);

  const {
    data: countyCompetitionsData,
    loading: loadingCountyCompetitions,
    refetch: refetchCountyCompetitions,
  } = useAxiosGet(
    baseUrl(
      `competitionSourceBeta/get_competition_by_county?county=${state.selectedCounty?.value}`
    ),
    true
  );
  const {
    data: countyCompetitionTeamsData,
    loading: loadingCountyCompetitionTeams,
    refetch: refetchCountyCompetitionTeams,
  } = useAxiosGet(
    baseUrl(
      `competitionSourceBeta/get_competition_teams?county=${state.selectedCounty?.value}&competition_path=${state.selectedCompetition?.path}`
    ),
    true
  );
  const {
    data: countyTeamMatchesData,
    loading: loadingCountyTeamMatches,
    refetch: refetchCountyTeamMatches,
  } = useAxiosGet(
    baseUrl(
      `competitionSourceBeta/get_team_matches_from_competition?county=${state.selectedCounty?.value}&competition_path=${state.selectedCompetition?.path}&id_team=${state.selectedClub?.id_team}`
    ),
    true
  );

  const {
    data: competitionsData,
    loading: loadingCompetitions,
    refetch: refetchCompetitions,
  } = useAxiosGet(baseUrl(`competitionSourceAlfa/get_competition`), true);

  const {
    data: seriesData,
    loading: loadingSeries,
    refetch: refetchSeries,
  } = useAxiosGet(
    baseUrl(
      `competitionSourceAlfa/get_serie?id_competition=${state.selectedCompetition?.["value"]}`
    ),
    true
  );
  const {
    data: competitionClubsData,
    loading: loadingCompetitionClubs,
    refetch: refetchCompetitionClubs,
  } = useAxiosGet(
    baseUrl(
      `competitionSourceAlfa/get_competition_teams?id_competition=${
        state.selectedCompetition?.["value"]
      }${
        seriesData?.success === 1 && state.selectedSeries !== ""
          ? `&id_serie=${state.selectedSeries?.value}`
          : ""
      } `
    ),
    true
  );

  const {
    data: clubTeamsData,
    loading: loadingClubTeams,
    refetch: refetchClubTeams,
  } = useAxiosGet(
    baseUrl(
      `club/get_teams_nomenclature?id_club=${currentUser.id_club}&type=select&all=0`
    ),
    true
  );

  const {
    data: clubMatchesData,
    loading: loadingClubMatches,
    refetch: refetchClubMatches,
  } = useAxiosGet(
    baseUrl(
      `competitionSourceAlfa/get_team_matches_from_competition?id_competition=${
        state.selectedCompetition?.["value"]
      }${
        seriesData?.success === 1 && state.selectedSeries !== ""
          ? `&id_serie=${state.selectedSeries?.value}`
          : ""
      }&id_team=${state.selectedClub?.value} `
    ),
    true
  );

  const {
    data: manualCompetitionData,
    loading: loadingAddManualCompetition,
    postData: postManualCompetition,
  } = useAxiosPost("matches/add_competition_2");
  const {
    loading: loadingImportAlphaCompetition,
    postData: postAlphaImportCompetition,
  } = useAxiosPost("competitionSourceAlfa/import_competition_for_team");
  const {
    loading: loadingImportBetaCompetition,
    postData: postBetaImportCompetition,
  } = useAxiosPost("competitionSourceBeta/import_competition_for_team");

  const { loading: loadingLogos, postData: postLogosData } = useAxiosPost(
    "https://api.thefootballbrain.app/matches/get_team_logo",
    true
  );

  useEffect(() => {
    if (Number(state.selectedSource?.value) === 1) {
      refetchCompetitions();
    } else if (Number(state.selectedSource?.value) === 2) {
      refetchCounties();
    }
  }, [state.selectedSource]);

  useEffect(() => {
    if (state.selectedCounty !== "") refetchCountyCompetitions();
  }, [state.selectedCounty]);

  useEffect(() => {
    if (competitionsContext.addAgeGroup) {
      setState({ competitionOption: "create" });
    }
  }, [competitionsContext.addAgeGroup]);

  useEffect(() => {
    setState({ selectedSeries: "" });
    if (
      state.selectedCompetition !== "" &&
      Number(state.selectedSource?.value) === 2
    ) {
      refetchCountyCompetitionTeams();
    } else if (
      state.selectedCompetition !== "" &&
      Number(state.selectedSource?.value) === 1
    ) {
      if (state.selectedCompetition !== "") refetchSeries();
    }
  }, [state.selectedCompetition]);

  useEffect(() => {
    if (
      state.selectedCompetition !== "" &&
      Number(state.selectedSource?.value) === 1
    )
      refetchCompetitionClubs();
  }, [state.selectedSeries, state.selectedCompetition]);

  useEffect(() => {
    setState({
      competitionClubs: _.map(competitionClubsData?.list, (club) => {
        return {
          ...club,
          logos: [],
          selectedLogo: 0,
          uploadedLogo: "",
        };
      }),
    });
  }, [competitionClubsData]);

  useEffect(() => {
    refetchClubTeams();
  }, [state.selectedClub]);

  const handleChangeSelect = (selected, value) => {
    setState({ [value.name]: selected });
  };
  const handleChangeSource = (selected, value) => {
    handleChangeSelect(selected, value);
    setState({
      selectedCounty: "",
      selectedCompetition: "",
      selectedSeries: "",
      selectedClub: "",
      selectedClubTeam: "",
    });
  };
  const handleChangeCounty = (selected, value) => {
    handleChangeSelect(selected, value);
    setState({
      selectedCompetition: "",
      selectedSeries: "",
      selectedClub: "",
      selectedClubTeam: "",
    });
  };
  const handleChangeCompetition = (selected, value) => {
    handleChangeSelect(selected, value);
    setState({
      selectedSeries: "",
      selectedClub: "",
      selectedClubTeam: "",
    });
  };
  const handleChangeSeries = (selected, value) => {
    handleChangeSelect(selected, value);
    setState({
      selectedClub: "",
      selectedClubTeam: "",
    });
  };

  const handleNextStep = () => {
    if (Number(state.selectedSource?.value) === 1) {
      refetchClubMatches();
    } else {
      refetchCountyTeamMatches();
    }
    setState({ showCompetitionMatches: true });
  };
  let loadingLogoss = true;
  const handleGoToCheckLogos = async () => {
    let apiToFetch = [];

    setState({ importConfirmationCheckLogos: true });

    _.forEach(state.competitionClubs, (club) => {
      const payload = {
        query: "logo " + removeAgeGroupFromTeamName(club.label),
      };
      apiToFetch.push(postLogosData(payload));
    });
    await Promise.all(apiToFetch).then((responses) => {
      setState({
        competitionClubs: state.competitionClubs.map((club, i) => {
          return { ...club, logos: responses[i].slice(0, 3) };
        }),
      });
    });
    loadingLogoss = false;
  };

  const handleChangeSelectedLogo = (clubIndex, logoIndex) => {
    setState({
      competitionClubs: _.map(state.competitionClubs, (club, index) =>
        index === clubIndex ? { ...club, selectedLogo: logoIndex } : { ...club }
      ),
    });
  };

  const getImageFromFile = (files, file, clubIndex) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      setState({
        competitionClubs: _.map(state.competitionClubs, (club, index) =>
          index === clubIndex
            ? { ...club, uploadedLogo: reader.result }
            : { ...club }
        ),
      });
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const handleChangeUploadedLogo = (e, clubIndex) => {
    if (_.size(e.target.files) > 0) {
      getImageFromFile(e.target.files, e.target.files[0], clubIndex);
    } else {
      setState({
        competitionClubs: _.map(state.competitionClubs, (club, index) =>
          index === clubIndex ? { ...club, uploadedLogo: "" } : { ...club }
        ),
      });
    }
  };

  const handleRemoveUploadedLogo = (clubIndex) => {
    setState({
      competitionClubs: _.map(state.competitionClubs, (club, index) =>
        index === clubIndex ? { ...club, uploadedLogo: "" } : { ...club }
      ),
    });
  };

  const handleImportCompetition = () => {
    const payload =
      Number(state.selectedSource?.value) === 1
        ? {
            token: token,
            competition: {
              frfotbal_id_competition: state.selectedCompetition?.value,
              frfotbal_id_serie: state.selectedSeries?.value ?? null,
              frfotbal_id_team: state.selectedClub?.value,
              competition_name: state.selectedCompetition?.label,
              minutes_per_time: state.importedCompetitionMinutesPerHalf,
              teams_number: _.size(competitionClubsData?.list),
              date_start: clubMatchesData?.competition_date_start,
              date_end: clubMatchesData?.competition_date_end,
            },
            teams: _.map(competitionClubsData?.list, (el, i) => {
              return {
                frfotbal_id_team: el.value,
                logo_type:
                  state.competitionClubs[i]?.uploadedLogo !== ""
                    ? "base_64"
                    : "url",
                uploaded_logo:
                  state.competitionClubs[i]?.uploadedLogo !== ""
                    ? state.competitionClubs[i]?.uploadedLogo
                    : null,
                logo_url:
                  state.competitionClubs[i]?.uploadedLogo === ""
                    ? state.competitionClubs[i]?.logos[
                        state.competitionClubs[i]?.selectedLogo
                      ]?.thumbnail_url
                    : null,
                team_name:
                  el.value === state.selectedClub?.value
                    ? state.selectedClubTeam?.label
                    : el.label,
                own_team: el.value === state.selectedClub?.value ? 1 : 0,
                id_team:
                  el.value === state.selectedClub?.value
                    ? state.selectedClubTeam?.value
                    : null,
              };
            }),
            matches: _.map(clubMatchesData?.list, (el) => {
              return {
                frfotbal_id_match: el.frfotbal_id_match,
                round: el.stage,
                id_team_home: el.id_team_home,
                team_home_goals: el.team_home_goals,
                id_team_away: el.id_team_away,
                team_away_goals: el.team_away_goals,
                match_date: el.match_date,
              };
            }),
          }
        : {
            token: token,
            competition: {
              frfajf_county: state.selectedCounty?.value,
              frfajf_id_competition: state.selectedCompetition?.value,
              frfajf_competition_path: state.selectedCompetition?.path,
              frfajf_id_team: state.selectedClub?.id_team,
              competition_name: state.selectedCompetition?.label,
              minutes_per_time: state.importedCompetitionMinutesPerHalf,
              teams_number: _.size(countyCompetitionTeamsData?.list),
              date_start: countyTeamMatchesData?.competition_date_start,
              date_end: countyTeamMatchesData?.competition_date_end,
            },
            teams: _.map(countyCompetitionTeamsData?.list, (el) => {
              return {
                frfajf_id_team: el.id_team,
                team_name: el.label,
                own_team: state.selectedClub?.id_team === el.id_team ? 1 : 0,
                id_team:
                  state.selectedClub?.id_team === el.id_team
                    ? state.selectedClubTeam?.value
                    : null,
                logo: el.logo,
              };
            }),
            matches: _.map(countyTeamMatchesData?.list, (el) => {
              return {
                frfajf_id_match: el.frfajf_id_match,
                round: el.stage,
                id_team_home: el.id_team_home,
                team_home_goals: el.team_home_goals,
                id_team_away: el.id_team_away,
                team_away_goals: el.team_away_goals,
                match_date: el.match_date,
              };
            }),
          };

    const responseHandler = (response) => {
      if (Number(response.success === 1)) {
        history.push(`/club/competitions/${response.id_competition}`);
        competitionsContext.refetchCompetitions();
        setState({
          showCompetitionMatches: false,
          importConfirmationCheckLogos: false,
        });
        competitionsContext.onSetState({ addCompetitionPopup: false });

        showNotification(
          "success",
          languagePack.competitions,
          languagePack.competition_add_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.competitions,
          languagePack.competition_add_fail
        );
      }
    };

    if (Number(state.selectedSource?.value) === 1) {
      postAlphaImportCompetition(payload, responseHandler);
    } else {
      if (Number(state.selectedSource?.value) === 2)
        postBetaImportCompetition(payload, responseHandler);
    }
  };

  const handleAddManualCompetition = () => {
    const payload = {
      competition_name: state.manualCompetitionName,
      competition_type: state.manualCompetitionType,
      id_club: currentUser.id_club,
      teams_number: state.manualCompetitionNoTeams,
      groups_number: state.manualCompetitionNoGroups,
      date_start: state.manualCompetitionDateStart,
      date_end: state.manualCompetitionDateEnd,
      multiple_age_groups: state.manualCompetitionHasMultipleAgeGroups ? 1 : 0,
      is_tfb: isTFB(),
    };
    if (state.manualCompetitionAgeGroup !== "") {
      payload.category = state.manualCompetitionAgeGroup?.label;
    }
    if (competitionsContext.addAgeGroup) {
      payload.id_parent_competition =
        competitionsContext?.wantToAddGroupComp?.id_competition;
    }
    const responseHandler = (response) => {
      if (Number(response.success === 1)) {
        competitionsContext.refetchCompetitions();

        competitionsContext.onSetState({
          addCompetitionPopup: false,
          editCompetitionPopup: false,
        });
        showNotification(
          "success",
          languagePack.competitions,
          languagePack.competition_add_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.competitions,
          languagePack.competition_add_fail
        );
      }
    };

    postManualCompetition(payload, responseHandler);
  };

  const checkRequiredFields = () => {
    return (
      (state.competitionOption === "import" &&
        (state.selectedCompetition === "" ||
          state.selectedClub === "" ||
          state.selectedClubTeam === "")) ||
      (state.competitionOption === "create" &&
        ((state.manualCompetitionHasMultipleAgeGroups &&
          (state.manualCompetitionName === "" ||
            state.manualCompetitionDateEnd === "" ||
            state.manualCompetitionDateStart === "")) ||
          (!state.manualCompetitionHasMultipleAgeGroups &&
            (state.manualCompetitionNoTeams === "" ||
              state.manualCompetitionType === "" ||
              (state.manualCompetitionType === "group-stage" &&
                Number(state.manualCompetitionNoGroups) === 0) ||
              Number(state.manualCompetitionMinutesPerHalf) === 0 ||
              state.manualCompetitionDateEnd === "" ||
              state.manualCompetitionDateStart === "" ||
              state.manualCompetitionAgeGroup === ""))))
    );
  };

  const { renderBtn: renderBtnRight } = useRenderButton(
    state.competitionOption === "import"
      ? handleNextStep
      : handleAddManualCompetition,
    "green",
    "angle-right",
    state.competitionOption === "import"
      ? languagePack.next_step
      : languagePack.add,
    {},
    true,
    checkRequiredFields(),
    loadingAddManualCompetition
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    () => competitionsContext.onSetState({ addCompetitionPopup: false }),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <AddCompetitionContext.Provider
      value={{
        ...state,
        onSetState: (newState) => setState(newState),
        competitionsData,
        loadingCompetitions,
        seriesData,
        loadingSeries,
        competitionClubsData,
        loadingCompetitionClubs,
        clubTeamsData,
        loadingClubTeams,
        clubMatchesData,
        loadingClubMatches,
        countiesData,
        loadingCounties,
        countyCompetitionsData,
        loadingCountyCompetitions,
        countyCompetitionTeamsData,
        loadingCountyCompetitionTeams,
        countyTeamMatchesData,
        loadingCountyTeamMatches,
        loadingImportAlphaCompetition,
        loadingImportBetaCompetition,
        onImportCompetition: handleImportCompetition,
        onChangeSelect: (selected, value) =>
          handleChangeSelect(selected, value),
        onChangeSource: (selected, value) =>
          handleChangeSource(selected, value),
        onChangeCounty: (selected, value) =>
          handleChangeCounty(selected, value),
        onChangeCompetition: (selected, value) =>
          handleChangeCompetition(selected, value),
        onChangeSeries: (selected, value) =>
          handleChangeSeries(selected, value),

        onCloseImportConfirmation: () =>
          setState({
            showCompetitionMatches: false,
            importConfirmationCheckLogos: false,
          }),
        onGoToCheckLogos: handleGoToCheckLogos,
        onChangeSelectedLogo: handleChangeSelectedLogo,
        loadingLogos,
        loadingLogoss,
        onChangeUploadedLogo: handleChangeUploadedLogo,
        onRemoveUploadedLogo: handleRemoveUploadedLogo,
      }}
    >
      <TFBDialog
        title={
          competitionsContext.addAgeGroup
            ? languagePack.add_age_group_competition +
              " " +
              competitionsContext.wantToAddGroupComp?.competition_name
            : languagePack.add_competition
        }
        open
        fullWidth
        closePopup={competitionsContext.onCloseAddCompetition}
        maxWidth={window.innerWidth < 1024 ? "xs" : "sm"}
        btnLeft={renderBtnLeft}
        btnRight={renderBtnRight}
      >
        {!competitionsContext.addAgeGroup && (
          <div className={styles.add_competition_manual_options}>
            <div
              className={styles.add_competition_option}
              onClick={() => setState({ competitionOption: "import" })}
              style={{
                backgroundColor:
                  state.competitionOption === "import" ? "#fafafa" : "",
              }}
            >
              <div>{languagePack["import_competition"]}</div>
            </div>
            <div
              className={styles.add_competition_option}
              onClick={() =>
                setState({
                  competitionOption: "create",
                })
              }
              style={{
                backgroundColor:
                  state.competitionOption === "create" ? "#fafafa" : "",
              }}
            >
              <div>{languagePack["create_competition"]}</div>
            </div>
          </div>
        )}
        {state.competitionOption === "create" ||
        competitionsContext.addAgeGroup ? (
          <AddManualCompetition />
        ) : (
          <ImportCompetitionForm />
        )}
        {state.showCompetitionMatches && <ImportCompetitionConfirmation />}
      </TFBDialog>
    </AddCompetitionContext.Provider>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddCompetition);
