import React, { useContext, useState } from "react";
import useRenderButton from "../../components/customHooks/useRenderButton";
import CheckCode from "../CheckCode/CheckCode";
import { CreateAccountContext } from "../CreateAccount";
import {
  showNotification,
  useAxiosPost,
} from "../../components/customHooks/useAxiosPost";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { login } from "../../../redux/authCrud";
import { useHistory } from "react-router-dom";
import { authReduxFunctions } from "../../../redux/functions/authReduxFunctions";
import * as auth from "../../../redux/authRedux";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import { isTFB } from "../../utils/utilFunctions";
import { size, trim } from "lodash";

const CHECK_CODE_ROUTE = "user/check_email_code";
const DASHBOARD = "/dashboard";

const StartTrial = ({ languagePack }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [incompleteCode, setIncompleteCode] = useState(false);
  const [focusInput, setFocusInput] = useState(0);

  const { accountEmail, idUser, goPreviousStep, trialCode } = useContext(
    CreateAccountContext
  );

  const {
    loading: loadingCheck,
    postData: postCheckCode,
  } = useAxiosPost(CHECK_CODE_ROUTE);

  const handleCheckResponse = (responseCheckCode) => {
    if (responseCheckCode.success == 1) {
      showNotification("success", languagePack.create_account, languagePack.success_create_account);
      authReduxFunctions(dispatch).login(responseCheckCode.user, languagePack);
      const clubQuickSetup = responseCheckCode.club_quick_setup;
      dispatch(auth.actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: false }));
      history.push(DASHBOARD);
    } else {
      setFocusInput(focusInput + 1);
      let ERROR_CODE = "";
      if (responseCheckCode.error_code == "wrong_code")
        ERROR_CODE = languagePack.wrong_code;
      if (responseCheckCode.error_code == "no_such_user")
        ERROR_CODE = languagePack.wrong_user_no_found;
      if (responseCheckCode.error_code == "no_user_email_code")
        ERROR_CODE = languagePack.already_registered_account;
      if (responseCheckCode.error_code == "error_disable_code")
        ERROR_CODE = languagePack.disabled_code;
      if (responseCheckCode.error_code == "error_activate_user")
        ERROR_CODE = languagePack.error_activation_user;

      showNotification(
        "danger",
        languagePack.create_account,
        ERROR_CODE || languagePack.general_error_message
      );
    }
  }

  const { renderBtn: StartTrialButton } = useRenderButton(
    () => submitTrial(),
    "green",
    "arrow-right",
    languagePack.start_trial,
    { width: "100%" },
    true,
    size(trim(trialCode)) != 6,
    loadingCheck,
  );

  const submitTrial = () => {
    if (!trialCode) {
      setIncompleteCode(true);
    }

    if (trialCode) {
      const payload = {
        id_user: idUser,
        code: trialCode,
        is_tfb: isTFB(),
      };

      postCheckCode(payload, handleCheckResponse);
    }
  };

  return (
    <div>
      <div
        className="ds-headline-1"
        style={{ marginBottom: 50, lineHeight: "unset" }}
        dangerouslySetInnerHTML={{ __html: String(languagePack.send_6_digit_code_on_email).replaceAll("${accountEmail}", `<span style="color:var(--primaryColor)">${accountEmail}</span>`) }}
      ></div>

      <CheckCode loadingCheck={loadingCheck} languagePack={languagePack} focusInput={focusInput} />

      {incompleteCode && (
        <div className="text-danger">{languagePack.complete_code_from_email}</div>
      )}
      <div className="d-flex df-gap-10">
        <TFBIconButton color="darkGray" name="arrow-left" style={{ height: 50, width: 50, maxWidth: 50 }} onClick={goPreviousStep} />
        <StartTrialButton />
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  );
}
export default connect(null, mapDispatchToProps)(StartTrial);
