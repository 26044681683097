import React, { useState } from 'react';
import axios from 'axios';
import './CompetitionInfoPage';
import { Icon, Button, Dropdown, Checkbox } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from 'react-redux';
import { ContactSupportOutlined } from '@material-ui/icons';

const AddPrize = (props) => {

    const { languagePack } = props;

    const [player, setPlayer] = useState("");
    const [number, setNumber] = useState("");
    const [prize, setPrize] = useState("");
    const [photo, setPhoto] = useState("");
    const [image, setImage] = useState("");
    const [prizeTitle, setPrizeTitle] = useState("");

    const prizes = [
        {
            id: 1,
            title: "Tournament top scorer"
        },
        {
            id: 2,
            title: "Tournament best player"
        },
        {
            id: 3,
            title: "Tournament best goalkeper"
        },
    ];

    const handleChangeImage = (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setPhoto(reader.result)
            setImage(file);
        };
    };

    const addPrize = () => {

        const payload = new FormData();
        payload.append("id_competition", props.id_competition);
        payload.append("prize_name", prizeTitle);
        payload.append("player_name", player);
        payload.append("player_image", image);
        payload.append("prize_type", prize);
        payload.append("prize_number", number != "" ? number : null);

        // const payload = {
        //     id_competition: this.props.id_competition,
        //     prize_name: prizeTitle,
        //     player_name: player,
        //     player_photo: photo,
        //     prize_type: prize,
        //     prize_number: number != "" ? number : null,
        // }
        axios
            .post("site/add_prize", payload)
            .then((res) => {
                if (res.data.success == 1) {
                    props.getPrizes();
                    props.closePopup();
                    store.addNotification({
                        title: this.props.languagePack["competitions_actions"],
                        // message: res.data.error,
                        message: "Titlul a fost adaugat cu succes!",
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: this.props.languagePack["competitions_actions"],
                        message: res.data.error,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    }

    return (
        <div className="add-prize-container">
            <div className="add-prize-header">
                <div className="add-prize-title">
                    {/* {languagePack["add_competition"]} */}
                    {"Add prize for player"}
                </div>
                <Icon name="close" className="close" size="large" onClick={() => props.closePopup()} />
            </div>
            <div className="add-prize-row">
                <input
                    placeholder={"Prize title"}
                    className="add-prize-input"
                    name="number"
                    value={prizeTitle}
                    onChange={(e) => setPrizeTitle(e.target.value)}
                />
            </div>
            <div className="add-prize-row">
                <input
                    placeholder={"Player name"}
                    className="add-prize-input"
                    name="number"
                    value={player}
                    onChange={(e) => setPlayer(e.target.value)}
                />
            </div>
            <div className="add-prize-row-photo">
                <div className="image-input-container">
                    <div className="title">{"Player photo"}</div>
                    <input
                        type="file"
                        className="add-news-image-input"
                        id="customFile"
                        onChange={handleChangeImage}
                        accept="image/*"
                    />
                    <label
                        className="add-file-form-file-label"
                        htmlFor="customFile"
                    >
                        <div className="image-view">
                            {photo !== "" ? <img src={photo} /> : <span><Icon name="arrow up" className="cusor-pointer" size="small" /></span>}
                        </div>
                    </label>
                </div>
            </div>
            <div className="add-prize-row">
                <select
                    id="team-select"
                    value={prize}
                    onChange={(e) => setPrize(e.target.value)}
                >
                    <option value="" disabled selected>
                        {/* {languagePack["choose_team"]} */}
                        {"Choose prize type"}
                    </option>
                    {prizes.map((item) => {
                        return (
                            <option value={item.title} key={item.id}>
                                {item.title}
                            </option>
                        );
                    })}
                </select>
            </div>
            {prize === "Tournament top scorer" &&
                <div className="add-prize-row">
                    <input
                        placeholder={"Number of goals"}
                        className="add-prize-input"
                        name="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                    />
                </div>}
            <div className="add-group-buttons">
                <Button
                    className="cancel-button"
                    onClick={() => props.closePopup()}
                >
                    {languagePack["admin_cancel"]}
                    <Icon name="close" className="white-icon" />
                </Button>
                <Button
                    className="add-group-button"
                    onClick={addPrize}
                    disabled={!(player.length > 0 && prize.length > 0)}
                >
                    {languagePack["admin_add"]}
                    <Icon name="add" className="white-icon" />
                </Button>
            </div>
        </div>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(AddPrize);