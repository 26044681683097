import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import Avatar from "@material-ui/core/Avatar";

export default function TeamPlayersTop(props) {
  const [players, setPlayers] = useState([]);
  const [dropdownOption, setDropdownOption] = useState(25);
  const history = useHistory();

  const options = [
    { key: 25, text: "Goluri", value: 25 },
    { key: 64, text: "Assisturi", value: 64 },
    { key: 61, text: "Suturi", value: 61 },
    { key: -1, text: "Minute jucate", value: -1 },
    { key: 46, text: "Driblinguri reusite", value: 46 },
    { key: 258, text: "Acuratete pase", value: 258 },
    { key: 0, text: "inStat index", value: 0 },
  ];

  useEffect(() => {
    axios
      .get(`instat/team_ranking?parameter=25&id_team=${props.id_team}`)
      .then((res) => setPlayers(res.data))
      .catch((err) => console.log(err));
  }, []);

  function fetchData(option) {
    axios
      .get(`instat/team_ranking?parameter=${option}&id_team=${props.id_team}`)
      .then((res) => setPlayers(res.data))
      .catch((err) => console.log(err));
  }

  function handleChange(e, { value }) {
    setDropdownOption(value);
    fetchData(value);
  }

  return (
    <div className="card card-custom">
      <div className="instat-players-top-container">
        <div className="instat-players-top-header">
          <div className="instat-players-top-title">Top jucatori</div>
          <Dropdown
            selection
            options={options}
            value={dropdownOption}
            onChange={handleChange}
            className="team-top-instat-dropdown"
          />
        </div>
        <div className="instat-players-top-list-header">
          <div className="place">Pozitie</div>
          <div className="player-name">Nume jucator</div>
          <div className="value">Valoare</div>
        </div>
        <div className="instat-players-top-list">
          {players.map((item, index) => {
            return (
              <div
                className="player-row"
                key={index}
                style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
              >
                <div className="place">{index + 1}.</div>
                <div
                  className="player-name"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/club/player/${item.id_player}`)}
                >
                  <Avatar
                    src={`${window.baseURL}uploads/players/${item.id_player}.jpg`}
                    className="player-avatar"
                  />
                  {item.player_name}
                </div>

                <div className="value">
                  {item.parameter_value}
                  {dropdownOption === 258 ? "%" : ""}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
