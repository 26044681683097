import React, { useState } from "react";
import axios from "axios";
import { Icon, Button } from "semantic-ui-react";
import "./Photos.scss";
import { store } from "react-notifications-component";
import styles from "./PlayerGallery.module.scss";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

export default function PhotoElem({ src, onClick, onDelete }) {

    return (
        <div
            className={styles.photoElemContainer}
        >
            <img
                src={src}
                alt="gallery-photo"
                onClick={onClick}
            />
            <TFBIconButton
                name="delete"
                color="white"
                className={styles.deleteBtn}
                onClick={onDelete}
            />
            <div
                className={styles.expandButton}
                onClick={onClick}
            >
                <TFBIcon
                    name="enlarge"
                    color="white"
                />
            </div>
        </div>
    );
}
