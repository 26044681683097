import React, { useEffect, useReducer } from "react";
import useAxiosGet from "../../components/customHooks/useAxiosGet";

import { connect } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { TFBTableCell } from "../../components/reusable/useStyles";
import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBSearch from "../../components/design-system/TFBSearch/TFBSearch";
import TFBSelect from "../../components/design-system/TFBSelect/TFBSelect";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../components/reusable/Loader";

import styles from "./VideoGallery.module.scss";
import AddVideo from "./AddVideo";
import DeleteVideo from "./DeleteVideo";

import Pusher from "pusher-js";
import { CircularProgress } from "@material-ui/core";
import { videoSources } from "../../utils/constants";
import ShareVideo from "./ShareVideo";

const VideoGallery = (props) => {

    const { currentUser, token, languagePack } = props;

    const pusher = new Pusher("ded84999dbba94030977", {
        cluster: "eu",
    });

    const subscribePusher = () => {
        const channel = pusher.subscribe("videos-channel-" + currentUser.id_user);
        channel.bind("refresh-videos-club", (data) => {
            refetchVideoList();
        });
    }

    const unsubscribePusher = () => {
        pusher.disconnect();
    }

    useEffect(() => {
        return (() => {
            unsubscribePusher();
        })
    }, []);

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_add_video: false,
            open_delete_video: false,
            delete_video: {},
            open_share_video: false,
            share_video: {},

            video_list: [],

            video_source: null,
            search_value: "",
        }
    );

    const {
        data: videoList,
        loading: isVideoListLoading,
        refetch: refetchVideoList,
    } = useAxiosGet(
        `upload/get_video_list?id_club=${currentUser.id_club}&token=${token}`
    );

    useEffect(() => {
        if (videoList) {
            if (_.size(videoList?.list) > 0) {
                updateState({
                    video_list: videoList?.list
                })
                if (_.size(_.filter(videoList?.list, (e) => e.status == 0)) > 0) {
                    subscribePusher();
                }
            } else {
                updateState({
                    video_list: []
                })
                unsubscribePusher();
            }
        }
    }, [videoList])

    const openAddVideo = () => {
        updateState({ open_add_video: true })
    }

    const closeAddVideo = () => {
        updateState({ open_add_video: false })
    }

    const openDeleteVideo = (video) => {
        updateState({ open_delete_video: true, delete_video: video })
    }

    const closeDeleteVideo = () => {
        updateState({ open_delete_video: false, delete_video: {} })
    }

    const openShareVideo = (video) => {
        updateState({ open_share_video: true, share_video: video })
    }

    const closeShareVideo = () => {
        updateState({ open_share_video: false, share_video: {} })
    }

    useEffect(() => {
        if (state.video_source) {
            const filteredArray = [...state.video_list].filter(v => v.video_source == state.video_source.value);
            updateState({ video_list: filteredArray });
        } else {
            updateState({ video_list: videoList?.list });
        }
    }, [state.video_source]);

    useEffect(() => {
        if (_.size(state.search_value) > 1) {
            var filteredArray = [...videoList?.list];
            if (state.video_source) {
                filteredArray = filteredArray.filter(v => v.video_source == state.video_source.value);
            }
            updateState({ video_list: filteredArray.filter(v => String(v.video_name).toLocaleLowerCase().includes(String(state.search_value).toLocaleLowerCase())) });
        } else {
            updateState({ video_list: videoList?.list });
        }
    }, [state.search_value]);

    const cancelFilters = () => {
        updateState({ video_source: null, search_value: "" });
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack["gallery_videos_title"]}>
                {videoList?.storage_data.availability_percent > 0 &&
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="add" />}
                        onClick={openAddVideo}
                    >
                        {languagePack["add_video"]}
                    </TFBButton>
                }
            </TFBCardHeader>
            {videoList?.storage_data &&
                <div className={styles.progressBarContainer}>
                    <ProgressBar
                        className={styles.progressBar}
                        now={100 - (videoList?.storage_data.availability_percent ?? 0)}
                    />
                    <div className={styles.progressBarLabel}>
                        <span className={styles.available}>{`${videoList?.storage_data.available_space_pretty} ${languagePack.available_plural}`}&nbsp;</span>
                        <span className={styles.percentAvailable}>{` (${videoList?.storage_data.availability_percent}%) / ${videoList?.storage_data.total_space_pretty}`}</span>
                    </div>
                </div>
            }
            <TFBCardBody>
                <div className={styles.searchDiv}>
                    <TFBSearch
                        label={"Cautare"}
                        placeholder={"Introdu nume video..."}
                        classNameSearch={styles.searchVideo}
                        fluid
                        showNoResults={false}
                        value={state.search_value}
                        onSearchChange={(e) => updateState({ search_value: e.target.value })}
                    />
                    <div className={styles.selectDiv}>
                        <TFBSelect
                            label={"Sursa video"}
                            style={{ container: { width: "130px" } }}
                            value={state.video_source}
                            options={videoSources}
                            onChange={(selected) => updateState({ video_source: selected })}
                        />
                        <div className={styles.btnDiv}>
                            {(state.video_source || _.size(state.search_value) > 1) &&
                                <TFBButton
                                    color="lightGray"
                                    renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                                    onClick={cancelFilters}
                                >
                                    {languagePack["cancel_filter"]}
                                </TFBButton>
                            }
                        </div>
                    </div>
                </div>
                {state.open_add_video &&
                    <AddVideo
                        openPopup={state.open_add_video}
                        closePopup={closeAddVideo}
                        refreshList={refetchVideoList}
                    />
                }
                {!isVideoListLoading ?
                    (_.size(state.video_list) > 0 ?
                        <TFBCardBody>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TFBTableCell>{languagePack.video_title}</TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.video_source}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.share}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.video_date_hour_add}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.added_by}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                            </TFBTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(state.video_list, (row, index) => (
                                            <TableRow
                                                key={"video-" + index}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                                                    opacity: row.status == 1 ? "1" : "0.3",
                                                }}
                                            >
                                                <TFBTableCell>
                                                    <div
                                                        {...row.status == 1 && {
                                                            className: "cursor-pointer",
                                                            onClick: () => props.history.push(`/video-gallery/${row.id_video}`),

                                                        }}
                                                    >
                                                        {row.video_name}
                                                    </div>
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {row.video_source == "veo" && <TFBIcon className="tfb-icon-button-no-filter" name="veo" />}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {row.status == 1 &&
                                                        <TFBButton
                                                            color="green"
                                                            onClick={() => openShareVideo(row)}
                                                        >
                                                            {languagePack.share_with_another_team}
                                                        </TFBButton>
                                                    }
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {moment(row.date_add).format("DD.MM.YYYY, HH:mm")}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {row.uploaded_by}
                                                </TFBTableCell>
                                                <TFBTableCell align="right">
                                                    {row.status == 1 ?
                                                        <div className="actions-btns-container df-end">
                                                            <TFBIconButton
                                                                name="view"
                                                                onClick={() => props.history.push(`/video-gallery/${row.id_video}`)}
                                                            />
                                                            <a
                                                                href={row.video_url}
                                                                download
                                                                target="_blank"
                                                            >
                                                                <TFBIconButton
                                                                    name="arrow-down"
                                                                />
                                                            </a>
                                                            <TFBIconButton
                                                                name="delete"
                                                                onClick={() => openDeleteVideo(row)}
                                                            />
                                                        </div> :
                                                        <CircularProgress
                                                            size={25}
                                                            color={"inherit"}
                                                        />
                                                    }
                                                </TFBTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TFBCardBody> :
                        <TFBPlaceholder text={languagePack.There_are_no_records_to_display} />
                    ) :
                    <CircularLoader />
                }
            </TFBCardBody>

            {state.open_delete_video &&
                <DeleteVideo
                    openPopup={state.open_delete_video}
                    closePopup={closeDeleteVideo}
                    refreshList={refetchVideoList}
                    videoData={state.delete_video}
                />
            }

            {state.open_share_video &&
                <ShareVideo
                    openPopup={state.open_share_video}
                    closePopup={closeShareVideo}
                    videoData={state.share_video}
                />
            }
        </TFBCard>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(VideoGallery);