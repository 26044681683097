import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

type ChartTypes = {
  labels: string[];
  series: string[];
  colors?: string[];
  chartTitle: string;
  languagePack: any;
};

function DonutChart({
  labels,
  series,
  chartTitle,
  colors,
  languagePack,
}: ChartTypes) {
  let chartOptions = {
    title: {
      text: chartTitle,
      offsetX: 30,
      style: {
        fontSize: "16px",
        fontWeight: "700",
        fontFamily: "Poppins",
        color: "#000000",
      },
    },
    legend: {
      show: true,
      showForZeroSeries: true,
      horizontalAlign: "center",
      offsetY: 40,
      position: "right",
      fontSize: "14px",
      fontWeight: 700,
      fontFamily: "Poppins",
      markers: {
        width: 25,
        height: 25,
        radius: 3,
      },
    },
    labels: labels.map((label) => languagePack[String(label)]),

    dataLabels: {
      enabled: labels[0] == "-" ? false : true,
      formatter(value: any, opts: any) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    colors: colors,

    responsive: [
      {
        breakpoint: 1123,
        options: {
          chart: {
            width: 320,
          },
          legend: {
            position: "bottom",
          },
        },
      },
      {
        breakpoint: 676,
        options: {
          title: {
            offsetX: 20,
            style: {
              fontSize: "12px",
              fontWeight: "700",
              fontFamily: "Poppins",
              color: "#000000",
            },
          },
          chart: {
            width: 220,
          },
          legend: {
            position: "bottom",
            fontSize: "12px",
          },
        },
      },
    ],
  };

  const [options, setOptions] = useState(chartOptions);

  useEffect(() => {
    setOptions(chartOptions);
  }, [labels, languagePack]);

  return (
    <ReactApexChart
      series={series}
      type="donut"
      width="500px"
      height="500px"
      options={options}
    />
  );
}

export default DonutChart;
