import React from "react";
import axios from "axios";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { Icon } from "semantic-ui-react";
import "../Club.styles.scss";
import "./PlayerProfile.scss";
import { connect } from "react-redux";

class ContractWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractData: {},
      bonuses: [],
    };
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.id_season !== this.props.id_season) {
    // this.getContractData();
    // this.getBonuses();
    // }
  }

  formatNumber = (value) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="contract-info-staff-container">
        <div className="contract-info-title-container">
          <Icon name="file alternate outline" size="large" color="black" />
          <div className="contract-info-title">
            {languagePack.contract_details}
          </div>
        </div>

        {this.props.hasFinancialAccess ? (
          this.props.contractData.has_active_contract == 1 ? (
            <>
              <div className="contract-info-subtitle">
                {languagePack.active_contract}:
              </div>
              <div className="active-contract-row">
                <div className="active-contract-date">
                  {languagePack.starting_date}
                </div>
                <div className="active-contract-date">
                  {languagePack.expiry_date}
                </div>
                <div className="active-contract-wage">
                  {languagePack.monthly_wage}
                </div>
              </div>
              <div className="active-contract-row cream-bg">
                <div className="active-contract-date">
                  {this.props.contractData.contract_start ? (
                    <Moment format="DD.MM.YYYY">
                      {this.props.contractData.contract_start}
                    </Moment>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="active-contract-date">
                  {this.props.contractData.contract_end ? (
                    <Moment format="DD.MM.YYYY">
                      {this.props.contractData.contract_end}
                    </Moment>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="active-contract-wage">
                  {this.props.contractData.monthly_wage > 0 ? (
                    <>
                      {this.formatNumber(this.props.contractData.monthly_wage)}
                      {this.state.contractData.currency == "EUR" ? (
                        <Icon name="euro" color="black" />
                      ) : (
                        " RON"
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div
                className="contract-info-subtitle"
                style={{ paddingTop: 20 }}
              >
                {languagePack.active_bonuses}:
              </div>
              <div className="bonuses-container">
                {this.props.bonuses.length > 0 ? (
                  <div>
                    {this.props.bonuses.map((item, index) => {
                      return (
                        <div
                          className="contract-widget-bonus"
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                        >
                          <div className="bonus-title">{item.condition}</div>
                          <div className="bonus-element">
                            {this.formatNumber(item.amount)}{" "}
                            {item.currency == "EUR" ? (
                              <Icon name="euro" color="black" />
                            ) : (
                              " RON"
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div style={{ alignSelf: "center" }}>
                    <div style={{ textAlign: "center" }}>
                      <Icon name="search minus" size="huge" />
                    </div>
                    <div className="placeholder-bonuses">
                      {languagePack.placeholder_bonuses}
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="no-access-placeholder-staff">
              <Icon name="search minus" color="black" size="big" />
              <div className="no-access-text">
                {languagePack.no_data_activ_contract}
              </div>
            </div>
          )
        ) : (
          <div className="no-access-placeholder-staff">
            <Icon name="lock" color="black" size="big" />
            <div className="no-access-text">
              {languagePack["section_access_forbidden"]}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ContractWidget);
