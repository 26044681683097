export const columnsExpandedCashflow = [
  {
    name: "Nume",
    selector: "name",
    sortable: true,
    grow: 2,
  },
  {
    center: true,
    name: "Ianuarie",
    selector: "january",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Februarie",
    selector: "february",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Martie",
    selector: "march",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Aprilie",
    selector: "april",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Mai",
    selector: "may",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Iunie",
    selector: "june",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Iulie",
    selector: "july",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "August",
    selector: "august",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Septembrie",
    selector: "september",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Octombrie",
    selector: "october",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Noiembrie",
    selector: "november",
    sortable: true,
    _hasEditableCell: true
  },
  {
    center: true,
    name: "Decembrie",
    selector: "december",
    sortable: true,
    _hasEditableCell: true
  },
  {
    right: false,
    name: "",
    selector: "edit",
    button: true,
    _isEditToggle: true
  },
];