export const wavyConst = {
    amplitude: 6,      // Height of the wave
    frequency: 0.2,    // Frequency of the wave (controls how "tight" the wave is)
    taperLength: 12,      // Length over which the wave tapers off
    triangleHeight: 10,   // Height of the triangle (arrowhead)
    triangleWidth: 10,    // Width of the triangle (arrowhead)
}

export const calculateWavePoints = (startX, startY, endX, endY, withArrow = false, withoutAngle = false) => {
    const { amplitude, frequency, taperLength, triangleHeight, triangleWidth } = wavyConst;
    const waveLength = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));
    const angle = Math.atan2(endY - startY, endX - startX);
    const points = [];

    // Check if there’s enough length to draw both the wave and arrowhead
    const effectiveWaveLength = withArrow ? waveLength - taperLength : waveLength;
    if (effectiveWaveLength > 0) {
        // Create points for the wavy line
        for (let i = 0; i <= effectiveWaveLength; i += 1) {
            const x = i;
            const y = amplitude * Math.sin(frequency * i);

            // Rotate the point by the calculated angle
            const rotatedX = withoutAngle ? startX + x : startX + x * Math.cos(angle) - y * Math.sin(angle);
            const rotatedY = withoutAngle ? y : startY + x * Math.sin(angle) + y * Math.cos(angle);

            points.push({ x: rotatedX, y: rotatedY });
        }

        if (withArrow) {
            // Taper off the wave
            for (let i = effectiveWaveLength; i <= waveLength; i += 1) {
                const currentAmplitude = amplitude * ((waveLength - i) / taperLength);
                const x = i;
                const y = currentAmplitude * Math.sin(frequency * i);

                const rotatedX = withoutAngle ? startX + x : startX + x * Math.cos(angle) - y * Math.sin(angle);
                const rotatedY = withoutAngle ? y : startY + x * Math.sin(angle) + y * Math.cos(angle);

                points.push({ x: rotatedX, y: rotatedY });
            }
        }
    }

    // If an arrowhead is required, calculate triangle points
    let arrowPoints = null;
    if (withArrow) {
        const baseX = startX + waveLength * Math.cos(angle);
        const baseY = startY + waveLength * Math.sin(angle);

        // Calculate triangle points based on end of the wave
        const leftTipX = withoutAngle ? baseX - (triangleWidth / 2) : baseX - (triangleWidth / 2) * Math.sin(angle);
        const leftTipY = withoutAngle ? baseY : baseY + (triangleWidth / 2) * Math.cos(angle);
        const rightTipX = withoutAngle ? baseX + (triangleWidth / 2) : baseX + (triangleWidth / 2) * Math.sin(angle);
        const rightTipY = withoutAngle ? baseY : baseY - (triangleWidth / 2) * Math.cos(angle);
        const tipX = withoutAngle ? baseX : baseX + triangleHeight * Math.cos(angle);
        const tipY = withoutAngle ? baseY - triangleHeight : baseY + triangleHeight * Math.sin(angle);

        arrowPoints = [
            { x: leftTipX, y: leftTipY },
            { x: tipX, y: tipY },
            { x: rightTipX, y: rightTipY },
            { x: leftTipX, y: leftTipY } // Closing point for the triangle
        ];
    }

    return { linePoints: points, trianglePoints: arrowPoints };
}