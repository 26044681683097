import React, { useState, useEffect } from 'react';
import followers_icon from '../../../../assets/icons/fans-dashboard/page_followers.png';
import axios from "axios";
import NumberFormat from "react-number-format";

export default function SubscribersCount(props) {
    const [subscribers, setSubscribers] = useState(0);

    useEffect(() => {
        getSubscribers();
    }, [])

    function getSubscribers() {
        axios.get(`fans/youtube_subscribers?id_club=${props.id_club}`)
            .then((res) => setSubscribers(res.data.subscribers))
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom" style={{ height: '100%' }}>
            <div className="dashboard-box-container" style={{ height: '100%' }}>
                <div className="dashboard-box-title" >Fan engagement</div>
                <div className="clicks-stat-box">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="130px" height="105px" version={1.0} style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }} viewBox="0 0 386819 312433" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <defs>
                            <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n   \n    .str0636 {stroke:black;stroke-width:14876.4;stroke-linecap:round;stroke-linejoin:round}\n    .fil0636 {fill:none}\n   \n  " }} />
                        </defs>
                        <g id="Layer_x0020_1">
                            <metadata id="CorelCorpID_0Corel-Layer" />
                            <rect className="fil0636 str0636" x={7440} y={7440} width={371939} height={297553} />
                            <path className="fil0636 str0636" d="M7440 66942l371939 0" />
                            <path className="fil0636 str0636" d="M37189 37193l229142 0" />
                            <path className="fil0636 str0636" d="M346653 37193l2976 0" />
                            <path className="fil0636 str0636" d="M321367 37193l2976 0" />
                            <path className="fil0636 str0636" d="M296081 37193l2976 0" />
                            <path className="fil0636 str0636" d="M115240 167837l-1187 0 0 -64630 235576 0 0 64630 -78054 0" />
                            <rect className="fil0636 str0636" x={37189} y={103207} width={47114} height={201786} />
                            <rect className="fil0636 str0636" x={290932} y={204102} width={58690} height={35706} />
                            <path className="fil0636 str0636" d="M114053 304993l0 -28920 40738 0" />
                            <path className="fil0636 str0636" d="M232024 276073l29159 0 0 28920" />
                            <path className="fil0636 str0636" d="M290932 304993l0 -28920 58690 0 0 28920" />
                            <path className="fil0636 str0636" d="M244419 239169l-102023 0m38261 19128c0,7043 5708,12751 12751,12751 7042,0 12750,-5708 12750,-12751m-63762 -31879l0 -38261c0,-28055 22956,-51008 51008,-51008 28059,0 51015,22953 51015,51008l0 38261c0,7043 5707,12751 12750,12751l-127523 0c7043,0 12750,-5708 12750,-12751z" />
                        </g>
                    </svg>
                    <div style={{ marginLeft: 170 }}>
                        <div className="clicks-stat-number">
                            <NumberFormat
                                value={subscribers}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                        </div>
                        <div className="clicks-stat-text">Channel subscribers</div>
                    </div>
                </div>
            </div>
        </div>
    )
}