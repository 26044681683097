import React from "react";
import styles from "./TFBTextArea.module.scss";
import { TextArea } from "semantic-ui-react";
import { TTextArea } from "../types";

const TFBTextArea = ({
  label,
  placeholder,
  onChange,
  className,
  value,
  rows,
  style,
  componentStyle,
  autoFocus,
}: TTextArea) => {
  const ReusableInput = (
    <TextArea
      className={`${styles.tfbTextArea} ${className ? className : ""}`}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      style={{ border: "1px solid #ccc", borderRadius: "3px", ...style }}
      autoFocus={autoFocus}
    />
  );

  return (
    <div className={styles.textAreaGroup} style={componentStyle}>
      <h6 className={styles.areaLabel}>{label}</h6>
      {ReusableInput}
    </div>
  );
};

export default TFBTextArea;
