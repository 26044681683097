import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { TCircularLoader } from "../types";
import styles from "./TFBCircularLoader.module.scss";

const TFBCircularLoader = (props: TCircularLoader) => {

    const { size, ...otherProps } = props;

    return (
        <CircularProgress size={size} className={styles.loader} />
    );
};

export default TFBCircularLoader;