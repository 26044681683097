import React, { useEffect, useReducer } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  TFBTableCell,
  TFBTableRow,
  sxStickyColumn,
} from "../../../components/reusable/useStyles";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBCircularLoader from "../../../components/design-system/CircularLoader/TFBCircularLoader";
import TFBShape from "../../../components/design-system/Shape/TFBShape";
import TFBTeamLabel from "../../../components/design-system/TFBTeamLabel/TFBTeamLabel";
import CircularLoader from "../../../components/reusable/Loader";

import DeleteTraining from "../DeleteTraining";

const TrainingHistory = (props) => {
  const location = useLocation();
  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      team: null,
      trainings: [],
      type: "history",
      page: 1,
      limit: 20,
      has_more: true,

      open_delete_training: false,
      deleting_training: {},

      stats: {},
    }
  );

  const { languagePack, token, currentUser } = props;
  const { lang } = props.currentUser;

  const { data: teams, loading: isTeamsLoading } = useAxiosGet(
    `training/get_club_teams?token=${token}&all=1&type=dropdown`
  );

  useEffect(() => {
    if (teams) {
      if (teams?.success == 1) {
        if (Array.isArray(teams?.list)) {
          if (_.size(teams?.list) > 0) {
            const params = new URLSearchParams(location.search);

            let team = teams.list[0].value;
            let team_from_url = params.get("team");
            if (_.size(_.intersection(_.map(teams.list, e => e.value), [team_from_url]))) {
              team = team_from_url
            } else {
              params.delete("team");
              history.replace({ pathname: location.pathname, search: _.toString(params) });
            }
            updateState({ team: team })
          }
        }
      }
    }
  }, [teams])

  const {
    data: trainingList,
    loading: isTrainingListLoading,
    refetch: refetchTrainingList
  } = useAxiosGet(`training/get_training_sessions?${state.team > 0 ? "id_team=" + state.team : ""}&type=${state.type}&page=${state.page}&limit=${state.limit}&token=${token}`, true);

  useEffect(() => {
    if (trainingList?.list) {
      if (_.size(trainingList.list) > 0) {
        refetchTrainingStatsList();
        if (state.page == 1) {
          updateState({ page: state.page + 1, trainings: trainingList.list });
        } else {
          updateState({ page: state.page + 1, trainings: [...state.trainings, ...trainingList.list] });
        }
      } else {
        if (state.page == 1) {
          updateState({ trainings: [], has_more: false });
        } else {
          updateState({ has_more: false })
        }
      }
    }
  }, [trainingList])

  const {
    data: trainingStatsList,
    loading: isTrainingStatsListLoading,
    refetch: refetchTrainingStatsList,
  } = useAxiosGet(
    `training/get_training_sessions_stats?${state.team > 0 ? "id_team=" + state.team : ""
    }&type=${state.type}&page=${state.page}&limit=${state.limit
    }&token=${token}`,
    true
  );

  useEffect(() => {
    if (trainingStatsList?.stats) {
      if (_.size(trainingStatsList.stats) > 0) {
        updateState({ stats: { ...state.stats, ...trainingStatsList.stats } });
      }
    }
  }, [trainingStatsList]);

  const { loaderRef } = useInfiniteScroll({ refetch: !isTeamsLoading && state.has_more ? refetchTrainingList : () => { } });

  const handleChangeDropdown = (e, value) => {
    updateState({ [value.name]: value.value, trainings: [], page: 1, has_more: true })
    if (value.name == "team") {
      const params = new URLSearchParams(location.search);
      params.set(value.name, value.value);
      history.replace({ pathname: location.pathname, search: _.toString(params) });
    }
  }

  const openDeleteTraining = (training) => {
    updateState({ open_delete_training: true, deleting_training: training });
  };

  const closeDeleteTraining = () => {
    updateState({ open_delete_training: false, deleting_training: {} });
  };

  const refreshAfterDelete = () => {
    updateState({ trainings: [], page: 1, has_more: true })
  };

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack.trainings_history}>
        {!isTeamsLoading && (
          <TFBDropdown
            placeholder={languagePack["select_team"]}
            selection
            options={_.map(teams?.list ?? [], (e) =>
              e.value == 0 ? { ...e, text: languagePack.all_teams } : e
            )}
            value={state.team}
            onChange={handleChangeDropdown}
            name="team"
          />
        )}
      </TFBCardHeader>
      <TFBCardBody>
        {_.size(state.trainings) > 0 && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TFBTableCell sx={sxStickyColumn}>
                    {languagePack["team_name"]}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.presence_at_training}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack.location}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack["injury_date"]}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack["start_hour"]}
                  </TFBTableCell>
                  <TFBTableCell align="center">
                    {languagePack["end_hour"]}
                  </TFBTableCell>
                  <TFBTableCell align="right">
                    {languagePack.product_actions}
                  </TFBTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(state.trainings, (row, index) => (
                  <TFBTableRow key={"trainings-" + index}>
                    <TFBTableCell sx={sxStickyColumn}>
                      <TFBTeamLabel
                        name={row.team_name}
                        color={row?.team_color ?? null}
                        onClick={() =>
                          history.push(`/training/history/${row.id_training}`)
                        }
                      />
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {!isTrainingStatsListLoading ||
                        state.stats?.[row.id_training_session] ? (
                        <div className="df df-center-center df-gap-5">
                          <TFBShape
                            name="square"
                            color={
                              state.stats?.[row.id_training_session]
                                ?.color_was_attend
                            }
                            text={
                              state.stats?.[row.id_training_session]
                                ?.nr_was_attend
                            }
                          />
                          {" / " +
                            state.stats?.[row.id_training_session]
                              ?.nr_total_players}
                        </div>
                      ) : (
                        <TFBCircularLoader size={20} />
                      )}
                    </TFBTableCell>
                    <TFBTableCell align="center">{row.venue}</TFBTableCell>
                    <TFBTableCell align="center">
                      {moment(row.date)
                        .locale(lang ?? "ro")
                        .format("dddd") +
                        ", " +
                        moment(row.date).format("DD.MM.YYYY")}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {" "}
                      {row.hour_start}
                    </TFBTableCell>
                    <TFBTableCell align="center">{row.hour_end}</TFBTableCell>
                    <TFBTableCell align="right">
                      <div className="df df-gap-10 df-end">
                        <TFBIconButton
                          name="view"
                          color="darkGray"
                          onClick={() =>
                            history.push(`/training/history/${row.id_training}`)
                          }
                        />
                        {currentUser.user_team_access.teams.includes(
                          row.id_team
                        ) && (
                            <TFBIconButton
                              name="report"
                              color="darkGray"
                              onClick={() =>
                                history.push(
                                  `/training/history/details/${row.id_training}`
                                )
                              }
                            />
                          )}
                        {currentUser.user_team_access.teams.includes(
                          row.id_team
                        ) && (
                            <TFBIconButton
                              name="rpe"
                              color="darkGray"
                              onClick={() =>
                                history.push(`/training/rpe/${row.id_training}`)
                              }
                            />
                          )}

                        {currentUser.user_team_access.teams.includes(
                          row.id_team
                        ) && (
                            <TFBIconButton
                              name="delete"
                              onClick={() => openDeleteTraining(row)}
                              color="darkGray"
                            />
                          )}
                      </div>
                    </TFBTableCell>
                  </TFBTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {_.size(state.trainings) == 0 &&
          isTrainingListLoading == false &&
          isTeamsLoading == false &&
          state.team >= 0 && (
            <TFBPlaceholder text={languagePack.no_trainings} />
          )}
        {state.team >= 0 && <div ref={loaderRef}></div>}
        {isTrainingListLoading && <CircularLoader />}
        {state.open_delete_training && (
          <DeleteTraining
            openPopup={state.open_delete_training}
            closePopup={closeDeleteTraining}
            refreshList={refreshAfterDelete}
            training={state.deleting_training}
          />
        )}
      </TFBCardBody>
    </TFBCard>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(TrainingHistory);
