import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { Popup } from "semantic-ui-react";

class NewFollowersChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: [window.clubCustomization.primary_color, "#666666"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#fafafa", "transparent"],
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  }

  componentDidMount = () => {
    this.getData();
  };

  getData = () => {
    axios
      .get(
        `fans/instagram_new_followers_chart?id_network=${this.props.id_network}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.values,
        }));
      });
  };
  render() {
    return (
      <div className="card card-custom">
        <div className="dashboard-box-container">
          <div className="dashboard-box-title">
            New followers evolution
            <Popup
              trigger={<div className="info-button">!</div>}
              content={
                <div>The number of new followers in the last 20 days</div>
              }
              position="right center"
              size="small"
              basic
            />
          </div>
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="line"
            height={350}
          />
        </div>
      </div>
    );
  }
}

export default NewFollowersChart;
