import React, { useState } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import styles from "./PlayerGallery.module.scss";

const EditBiography = ({ languagePack, closePopup, isOpenPopup, refreshList, id, bio, currentUser, token }) => {

    const [newBio, setNewBio] = useState(bio);

    const handleChangeBio = (e) => {
        setNewBio(e.target.value);
    }

    const saveBio = () => {
        const payload = {
            id_entity: id,
            id_user: currentUser.id_user,
            id_club: currentUser.id_club,
            token: token,
            bio: newBio,
        };

        axios
            .post("player/edit_player_bio", payload)
            .then(res => {
                if (res.data.success == 1) {
                    refreshList();
                    store.addNotification({
                        title: languagePack.gallery_bio_title,
                        message: languagePack.admin_contact_notif_text_success,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.gallery_bio_title,
                        message: languagePack.general_error_message,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch(e => console.log(e))
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.edit_biography}
            open={isOpenPopup}
            closePopup={closePopup}
            btnLeft={() => (
                <TFBButton
                    color="darkGray"
                    renderIcon={() => <TFBIcon name="cancel" />}
                    onClick={closePopup}
                >
                    {languagePack.admin_cancel}
                </TFBButton>
            )}
            btnRight={() => (
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="check" />}
                    onClick={saveBio}
                >
                    {languagePack.admin_save}
                </TFBButton>
            )}
        >
            <TFBInput
                value={newBio}
                multiline
                label={languagePack.gallery_bio_title}
                className={styles.editBio}
                onChange={handleChangeBio}
            />
        </TFBDialog>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(EditBiography);