import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import {
  showNotification,
  useAxiosPost,
} from "../../components/customHooks/useAxiosPost";
import useRenderButton from "../../components/customHooks/useRenderButton";

function DeleteUser(props) {
  const { languagePack } = props;
  const isFirstRender = useRef(true);

  const { data: responseDelete, postData: deleteUserPost } = useAxiosPost(
    "user/delete_user"
  );

  // RENDER DIALOG BUTTONS ================================================
  // =======================================================================
  const { renderBtn: renderBtnRight } = useRenderButton(
    () => deleteUser(),
    "red",
    "delete",
    languagePack.Delete
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    () => props.closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  // USE EFFECT, HANDLE RESPONSE FROM SERVER ================================================
  // =====================================================================================

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (responseDelete.success == 1) {
      showNotification(
        "success",
        languagePack.users_management,
        languagePack.delete_user_success
      );
      props.refreshUsers();
      props.closePopup();
    } else {
      showNotification(
        "danger",
        languagePack.users_management,
        languagePack.delete_user_danger
      );
      props.closePopup();
    }
  }, [responseDelete]);

  // DELETE USER ================================================
  // =======================================================================

  function deleteUser() {
    const payload = {
      id_user: props.id_user_to_delete,
      token: props.token,
    };

    deleteUserPost(payload);
  }

  const { closePopup, deleteUserPopup } = props;
  return (
    <TFBDialog
      title={languagePack.delete_user}
      open={deleteUserPopup}
      closePopup={closePopup}
      btnRight={renderBtnRight}
      btnLeft={renderBtnLeft}
    >
      <div className="delete-user-popup-container">
        <div className="delete-user-text">
          {languagePack.delete_user_confirmation}
        </div>
      </div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteUser);
