import React, { useContext } from 'react';
import { map } from 'lodash';

import styles from "./TrainingBoard.module.scss";

import { BoardContext } from './BoardContext';
import { TrainingSectionsContext } from '../Sections/TrainingSectionsContext';

import CircularLoader from '../../../components/reusable/Loader';

const PickPitch = () => {
    const { setPitchSelection } = useContext(BoardContext);
    const { pitchNomenclature, loadingPitchNomenclature } = useContext(TrainingSectionsContext);

    const selectPitch = (img_url, img_url_grid, id_pitch) => {
        setPitchSelection({ img_url, img_url_grid, id_pitch, })
    }

    return (
        <>
            {!loadingPitchNomenclature ?
                <div className={styles['pick-pitch-container']}>
                    <div className={styles['pick-pitch-title']}>{"Pick your pitch"}</div>
                    <div className={`${styles['pitch-list']}`}>
                        {map(pitchNomenclature?.list ?? [], (value, index) => (
                            <div key={"field-" + index} className='col-sm-6 col-md-3' onClick={() => selectPitch(value.img_url_base64, value.img_url_grid_base64, value.id_pitch)}>
                                <div className={styles['item-container']}>
                                    <div className={styles['image-container']}>
                                        <img className={styles['item-image']} src={value.img_url} />
                                    </div>
                                    <div className={styles['field-name']}>{value.pich_name}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> :
                <CircularLoader />
            }
        </>
    );
};

export default PickPitch;