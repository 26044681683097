import React, { useState } from "react";
import axios from "axios";
import { Icon, Button } from "semantic-ui-react";
import "./Photos.scss";
import { store } from "react-notifications-component";

export default function AddPhotos(props) {
  const [files, setFiles] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const { languagePack } = props;

  function handleChange(event) {
    setFiles(event.target.files);
  }

  function handleSubmitUpload() {
    const data = new FormData();

    for (let i = 0; i < files.length; i++) {
      data.append(`images[${i}]`, files[i]);
    }
    data.append("nr_files", files.length);

    axios
      .post(`site/upload_photos?id_club=${props.id_club}`, data)
      .then((res) => {
        setFilesUploaded(true);
        if (res.data.success == 1) {
          store.addNotification({
            title: languagePack['gallery_notification_title'],
            message: languagePack['gallery_notification_success'],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          props.closePopup();
          props.refreshPhotos();
        } else {
          store.addNotification({
            title: languagePack['gallery_notification_title'],
            message: res.data.error,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="add-photos-container">
      <div className="image-input-container-photos">
        <input type="file" multiple id="customFile" onChange={handleChange} />
        <label htmlFor="customFile">
          {files.length > 0
            ? files.length === 1
              ? files.length + languagePack['gallery_upload_nr_singular']
              : files.length + languagePack['gallery_upload_nr_plural']
            : languagePack['gallery_upload_select_files']}
        </label>
        <Icon name="arrow up" size="small" />
      </div>
      <div className="file-upload-buttons">
        <Button
          onClick={() => {
            props.closePopup();
          }}
          className="file-upload-submit-button cancel-button"
        >
          {languagePack.admin_cancel}
          <Icon name="ban" className="file-upload-button-icon" />
        </Button>
        <Button
          className="file-upload-submit-button"
          onClick={handleSubmitUpload}
        >
          {languagePack.admin_add} <Icon name="add" className="file-upload-button-icon" />
        </Button>
      </div>
    </div>
  );
}
