import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { ProgressBar } from "react-bootstrap";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

import styles from "./TrainingStatsWidget.module.scss";
import { colorCodeAttendance, colorCodeMark } from "./playerProfileFunctions";

import { sxStickyColumn, TFBTableCell, TFBTableRow } from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBShape from "../../../components/design-system/Shape/TFBShape";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

class TrainingStatsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
    };
  }
  componentDidMount = () => {
    this.getPlayerTrainings();
  };

  getPlayerTrainings = () => {
    axios
      .get(
        `player/get_player_training_stats_3?id_player=${this.props.id_player}&id_season=${this.props.id_season}&token=${this.props.token}`
      )
      .then((res) => {
        this.setState({
          table_data: _.map(res.data.list, (e, i) => ({ ...e, open: i == 0 ? true : false })),
        });
      })
      .catch((err) => console.log(err));
  };

  handleRow = (index) => {
    const cloned = _.clone(this.state.table_data);
    cloned[index].open = !cloned[index].open;
    this.setState({ table_data: cloned })
  }

  render() {
    const { languagePack, lang } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.training_statistics} />
        <TFBCardBody className={styles.trainingStatsContainer}>
          {_.size(this.state.table_data) > 0 ?
            <TableContainer>
              <Table>
                <TableBody>
                  {_.map(this.state.table_data, (row, index) => (
                    <React.Fragment key={"t-" + index}>
                      <TFBTableRow className={styles.monthHeadRow}>
                        <TFBTableCell sx={sxStickyColumn}>
                          {_.capitalize(moment().locale(lang).month(row['month'] - 1).year(row['year']).format("MMMM YYYY"))}
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          <div className="df df-center-center df-gap-5">
                            {languagePack.average_mark + ":"}
                            <TFBShape
                              name="square"
                              backgroundColor={colorCodeMark(row['rating_average'])}
                              text={_.toString(row['rating_average'])}
                            />
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          <div className="df df-center-center df-gap-5">
                            {languagePack.attendance + ":"}
                            <TFBShape
                              name="square"
                              backgroundColor={colorCodeAttendance(Math.round(row['month_presence_percent_average']))}
                              text={row['month_was_present'] + "/" + row['month_presence_need']}
                            />
                            {`(${row['month_presence_percent_average']}%)`}
                          </div>
                        </TFBTableCell>
                        <TFBTableCell style={{ width: "45%" }}>
                          <ProgressBar
                            className={styles.progressBar}
                            now={row['month_presence_percent_average'] ?? 0}
                          />
                        </TFBTableCell>
                        <TFBTableCell align="right">
                          <div className="df df-end">
                            <TFBIconButton
                              onClick={() => this.handleRow(index)}
                              name={`${row.open ? "angle-up" : "angle-down"}`}
                              color="xLightGray"
                            />
                          </div>
                        </TFBTableCell>
                      </TFBTableRow>
                      <TFBTableRow className={styles.monthHeadTableRow} style={!row.open ? { display: "none" } : {}}>
                        <TFBTableCell sx={sxStickyColumn}>
                          {languagePack.date_team}
                        </TFBTableCell>
                        <TFBTableCell align="center">{languagePack.mark}</TFBTableCell>
                        <TFBTableCell align="center">{languagePack.attendance}</TFBTableCell>
                        <TFBTableCell style={{ width: "45%" }}>{languagePack.remarks}</TFBTableCell>
                        <TFBTableCell align="center"></TFBTableCell>
                      </TFBTableRow >
                      {_.map(row['trainings'], (value, key) => (
                        <TFBTableRow key={"t-h-t-" + index + "-" + key} style={{ backgroundColor: key % 2 == 1 ? "#fafafa" : "white", ...(!row.open ? { display: "none" } : {}) }}>
                          <TFBTableCell sx={sxStickyColumn}>
                            {_.capitalize(moment(value['date_start']).locale(lang).format("dddd, DD.MM.YYYY"))}
                            <TFBShape
                              backgroundColor={value?.team?.team_color}
                              text={value?.team?.team_name}
                            />
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div className="df df-center-center df-gap-5">
                              {value.training_rating > 0 ?
                                <TFBShape
                                  backgroundColor={colorCodeMark(Number(value.training_rating))}
                                  text={value.training_rating}
                                /> :
                                "-"
                              }
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center" style={{ color: value.was_present == 1 ? "green" : "red" }}>
                            {_.upperCase(value.was_present == 1 ? languagePack.attended : languagePack.absent)}
                          </TFBTableCell>
                          <TFBTableCell style={{ width: "45%" }}>
                            {value.training_comment ? value.training_comment : "-"}
                          </TFBTableCell>
                          <TFBTableCell align="center"></TFBTableCell>
                        </TFBTableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_training_stats} />
          }
        </TFBCardBody>
      </TFBCard >
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(TrainingStatsWidget);
