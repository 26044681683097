import React, { useEffect } from "react";
import TFBDialog from "../../../../../../app/components/design-system/Dialog/TFBDialog";
import NewsItem from "./NewsItem/NewsItem";
import "./News.scss";
import FunctionalityRequest from "./FunctionalityRequest/FunctionalityRequest";
import useAxiosGet from "../../../../../../app/components/customHooks/useAxiosGet";
import _ from "lodash";
import CircularLoader from "../../../../../../app/components/reusable/Loader";
import TFBPlaceholder from "../../../../../../app/components/design-system/NoDataPlaceholder/TFBPlaceholder";

const News = ({ isOpenNews, closePopup, languagePack, currentLang }) => {
  const {
    data: functionalities,
    loading: loadingFunctionalities,
  } = useAxiosGet(`admin/new_functionality`);

  return (
    <TFBDialog
      title={languagePack.new_functionalities}
      open={isOpenNews}
      closePopup={closePopup}
      maxWidth={"sm"}
      fullWidth
      isNews
    >
      {loadingFunctionalities && <CircularLoader />}

      {!loadingFunctionalities && (
        <div className="news-functionalities-wrapper">
          {_.size(functionalities) > 0 ? (
            _.map(functionalities, (data, index) => (
              <NewsItem
                functionality={data}
                key={index}
                languagePack={languagePack}
                currentLang={currentLang}
              />
            ))
          ) : (
            <TFBPlaceholder text={languagePack.no_functionality_yet} />
          )}
          <FunctionalityRequest languagePack={languagePack} />
        </div>
      )}
    </TFBDialog>
  );
};

export default News;
