import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { TImageAvatar } from "../types";
import styles from "./TFBImageAvatar.module.scss";

const TFBImageAvatar = ({
    src,
}: TImageAvatar) => {
    return (
        <div
            className={`${styles.imageAvatarContainer}`}
        >
            <img src={src} className={styles.imageAvater} />
        </div>
    );
};

export default TFBImageAvatar;
