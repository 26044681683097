import React from "react";
import styles from "./TFBTimerInput.module.scss";
import { TTimerInput } from "../types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimeField } from "@mui/x-date-pickers/TimeField";

const TFBTimerInput = ({
  onChange,
  label,
  value,
  fullWidth,
  className,
  onKeyDown,
}: TTimerInput) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label ? (
        <div className={styles.tfbDateGroup} style={{ width: "100%" }}>
          <h6 className={styles.dateLabel}>{label}</h6>

          <TimeField
            onKeyDown={onKeyDown}
            className={`${styles.tfbDateTimePicker} ${
              className ? className : ""
            }`}
            // sx={{
            //   height: "35px",
            //   display: "flex",
            // }}
            value={value}
            onChange={onChange}
            format="HH:mm:ss"
          />
        </div>
      ) : (
        <TimeField
          className={`${styles.tfbDateTimePicker} ${
            className ? className : ""
          }`}
          // sx={{
          //   height: "35px",
          //   display: "flex",
          // }}
          value={value}
          onChange={onChange}
          format="HH:mm:ss"
        />
      )}
    </LocalizationProvider>
  );
};

export default TFBTimerInput;
