import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import CircularLoader from "../../../components/reusable/Loader";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBPlayerWithAvatar from "../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import moment from "moment";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { baseUrl, formatAmountCurrency } from "../../../utils/utilFunctions";
import AddStaffContract from "./AddStaffContract";
import EditStaffContract from "./EditStaffContract";
import DeleteStaffContract from "./DeleteStaffContract";

const StaffContract = (props) => {

    const { currentUser, languagePack, currencyRates } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_add_contract: false,
            open_edit_contract: false,
            edit_contract: {},
            open_delete_contract: false,
            delete_contract: {},
            contract_list: [],
        }
    );

    const {
        data: contractList,
        loading: isContractListLoading,
        refetch: refetchContractList,
    } = useAxiosGet(
        `finances/get_staff_member_contract?id_club=${currentUser.id_club}`
    );

    useEffect(() => {
        if (contractList) {
            if (_.size(contractList?.list) > 0) {
                updateState({
                    contract_list: [...contractList?.list].map(elem1 => {
                        const contract = [...state.contract_list].find(elem2 => elem1.id_contract == elem2.id_contract);
                        if (contract) {
                            return { ...contract, ...elem1 };
                        } else {
                            return { ...elem1, extend: false }
                        }
                    })
                })
            }
        }
    }, [contractList])

    const handleExtendContract = (index) => {
        const local_contract_list = [...state.contract_list];
        local_contract_list[index]['extend'] = !local_contract_list[index]['extend'];
        updateState({ contract_list: local_contract_list });
    }

    const openAddContract = () => {
        updateState({ open_add_contract: true })
    }

    const closeAddContract = () => {
        updateState({ open_add_contract: false })
    }

    const openEditContract = (contract) => {
        updateState({ open_edit_contract: true, edit_contract: contract })
    }

    const closeEditContract = () => {
        updateState({ open_edit_contract: false, edit_contract: {} })
    }

    const openDeleteContract = (contract) => {
        updateState({ open_delete_contract: true, delete_contract: contract })
    }

    const closeDeleteContract = () => {
        updateState({ open_delete_contract: false, delete_contract: {} })
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack["staff_contracts"]}>
                {/* <TFBButton
                    color="darkGray"
                // onClick={}
                >
                    {languagePack["bonuses_list"]}
                </TFBButton> */}
                {/* <TFBButton
                    color="darkGray"
                // onClick={}
                >
                    {languagePack["remove_contract"]}
                </TFBButton> */}
                <TFBButton
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                    onClick={openAddContract}
                >
                    {languagePack["add_contract"]}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                {!isContractListLoading ?
                    (_.size(state.contract_list) > 0 ?
                        <TFBCardBody>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TFBTableCell>{languagePack.staff_member_name}</TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.occupation}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.starting_date}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.ending_date}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {languagePack.monthly_wage}
                                            </TFBTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(state.contract_list, (row, index) => (
                                            <TableRow
                                                key={"staff-contract-" + index}
                                                sx={{
                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                    backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                                                }}
                                            >
                                                <TFBTableCell align="center">
                                                    <TFBPlayerWithAvatar
                                                        playerName={row.member_name}
                                                        avatarUrl={row.img_url}
                                                    />
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {row.member_role}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {moment(row.date_start).format("DD.MM.YYYY")}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {moment(row.date_end).format("DD.MM.YYYY")}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    {formatAmountCurrency(row.wage, row.currency, currentUser.currency, currencyRates)}
                                                </TFBTableCell>
                                                <TFBTableCell align="center">
                                                    <div className="actions-btns-container df-end">
                                                        {row.contract_path &&
                                                            <a
                                                                style={{ color: "black" }}
                                                                className="download-contract"
                                                                href={baseUrl(`upload/download_contract?path=${row.contract_path}`)}
                                                            >
                                                                <TFBIconButton
                                                                    name="arrow-down"
                                                                    color="black"
                                                                />
                                                            </a>
                                                        }
                                                        <TFBIconButton
                                                            name="edit"
                                                            color="darkGray"
                                                            onClick={() => openEditContract(row?.edit_data)}
                                                        />
                                                        <TFBIconButton
                                                            name="delete"
                                                            color="darkGray"
                                                            onClick={() => openDeleteContract(row?.edit_data)}
                                                        />
                                                        <TFBIconButton
                                                            name={`angle-${row?.extend ? "up" : "down"}`}
                                                            color={index % 2 == 0 ? "xLightGray" : "white"}
                                                            onClick={() => handleExtendContract(index)}
                                                        />
                                                    </div>
                                                </TFBTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TFBCardBody> :
                        <TFBPlaceholder text={languagePack.no_contract} />
                    ) :
                    <CircularLoader />
                }
            </TFBCardBody>

            {state.open_add_contract &&
                <AddStaffContract
                    openPopup={state.open_add_contract}
                    closePopup={closeAddContract}
                    refreshList={refetchContractList}
                />
            }

            {state.open_edit_contract &&
                <EditStaffContract
                    openPopup={state.open_edit_contract}
                    closePopup={closeEditContract}
                    refreshList={refetchContractList}
                    contractData={state.edit_contract}
                />
            }

            {state.open_delete_contract &&
                <DeleteStaffContract
                    openPopup={state.open_delete_contract}
                    closePopup={closeDeleteContract}
                    refreshList={refetchContractList}
                    contractData={state.delete_contract}
                />
            }
        </TFBCard>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(StaffContract);