import React, { useContext } from "react";
import "./Tabs.scss";
import { CreateAccountContext } from "../CreateAccount";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";

const StepCircle = ({ stepNumber, isCompleted }) => {
  return (
    <div
      className="create-account-step-circle-container"
      style={{ backgroundColor: isCompleted && "var(--primaryColor)" }}
    >
      <span
        className="number-value"
        style={{
          color: "white",
          right: isCompleted && "12.5px",
        }}
      >
        {!isCompleted ? stepNumber : <TFBIcon name="check" />}
      </span>
    </div>
  );
};

const Tabs = ({ languagePack }) => {
  const { step } = useContext(CreateAccountContext);

  return (
    <div className="tabs-container">
      <div className="tabs">
        {/* ===== FIRST TAB ===== */}
        {/* ===================== */}
        <div
          className="create-account"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StepCircle stepNumber={1} isCompleted={step == 1} />
          <div
            className="text ds-headline-2"
            style={{ color: step == 1 && "var(--primaryColor)" }}
          >
            {languagePack.create_account}
          </div>
        </div>

        <div className="vertical-bar"></div>

        {/* ===== SECOND TAB ===== */}
        {/* ====================== */}
        <div
          className="start-trial"
          style={{
            display: "flex",
            flexDirection: "column",
            opacity: step == 0 && 0.2,
          }}
        >
          <StepCircle stepNumber={2} />
          <div className="text ds-headline-2">{languagePack.start_trial}</div>
        </div>
      </div>

      <div className="horizontal-bar"></div>
    </div>
  );
};

export default Tabs;
