import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import "./TrainingSections.scss";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBSearch from "../../../components/design-system/TFBSearch/TFBSearch";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import CircularLoader from "../../../components/reusable/Loader";
import AddTrainingSectionPopup from "./AddTrainingSectionPopup";
import EditTrainingSectionPopup from "./EditTrainingSectionPopup";
import TrainingSectionBox from "../../../components/widgets/TrainingSection/TrainingSectionBox";
import DeleteTrainingSection from "./DeleteTrainingSection";
import {
  checkForFilterChange,
  createSelectedFiltersString,
  prepareStringForCompare,
} from "../../../utils/utilFunctions";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import EditTagsPopup from "./EditTagsPopup";
import ViewTrainingSectionPopup from "./ViewTrainingSectionPopup";
import styles from "./../Training.module.scss";
import { TFBRangeSlider } from "../../../components/design-system/RangeSlider/TFBRangeSlider";
import TrainingBoard from "../TrainingBoard/TrainingBoard";
import { TrainingSectionsProvider } from "./TrainingSectionsContext";

const TrainingSections = (props) => {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open_add_section: false,

      open_edit_section: false,
      section_to_edit: {},

      open_view_section: false,
      section_to_view: {},

      open_delete_section: false,
      deleting_section: {},

      open_training_board: false,
      training_board_section: {},

      open_edit_tags: false,

      filters: [],
      section_type: [],
      age_group: [],
      accessibility: null,
      players_no: [0, 40],
      goalkeepers_no: [0, 5],
      range_filter_was_changed: false,

      page: 1,
      limit: 20,
      has_more: true,
      sections: [],

      search_value: "",
    }
  );

  const { languagePack, token, currentUser } = props;

  const selectedFiltersString = createSelectedFiltersString(
    state.age_group,
    state.section_type,
    state.accessibility
  );

  const {
    data: sectionList,
    loading: isSectionListLoading,
    refetch: refetchSectionList,
  } = useAxiosGet(
    `training/get_training_sections_2?token=${token}&page=${state.page}&limit=${state.limit
    }&search_section_name=${prepareStringForCompare(state.search_value)}${selectedFiltersString ? `&tags=${selectedFiltersString}` : ""
    }${!_.includes(state.players_no, 0) || !_.includes(state.players_no, 40)
      ? `&no_of_players=${state.players_no.join("-")}`
      : ""
    }${!_.includes(state.goalkeepers_no, 0) ||
      !_.includes(state.goalkeepers_no, 5)
      ? `&no_of_gks=${state.goalkeepers_no.join("-")}`
      : ""
    }`,
    true
  );

  const { data: filtersData, refetch: refetchFiltersData } = useAxiosGet(
    `training/get_training_section_tag_nomenclature?token=${token}`
  );

  useEffect(() => {
    updateState({
      filters: filtersData?.list,
    });
  }, [filtersData]);

  useEffect(() => {
    if (
      state.search_value == "" &&
      state.accessibility === null &&
      _.size(state.age_group) === 0 &&
      _.size(state.section_type) === 0
    ) {
      refetchSectionList();
    }
  }, [
    state.accessibility,
    state.age_group,
    state.search_value,
    state.section_type,
  ]);

  useEffect(() => {
    if (sectionList?.list) {
      if (_.size(sectionList.list) > 0) {
        if (Number(state.page) === 1) {
          updateState({ page: state.page + 1, sections: sectionList.list });
        } else {
          updateState({
            page: state.page + 1,
            sections: [...state.sections, ...sectionList.list],
          });
        }
      } else {
        if (Number(state.page) === 1) {
          updateState({ sections: [], has_more: false });
        } else {
          updateState({ has_more: false });
        }
      }
    }
  }, [sectionList]);

  const { loaderRef } = useInfiniteScroll({
    refetch:
      !isSectionListLoading && state.has_more ? refetchSectionList : () => { },
  });

  const openAddSection = () => {
    updateState({ open_add_section: true });
  };

  const closeAddSection = () => {
    updateState({ open_add_section: false });
  };

  const openEditSection = (section) => {
    updateState({ open_edit_section: true, section_to_edit: section });
  };
  const openViewSection = (section) => {
    updateState({ open_view_section: true, section_to_view: section });
  };
  const handleCloseViewSectionPopup = (section) => {
    updateState({ open_view_section: false, section_to_view: {} });
  };

  const closeEditSection = () => {
    updateState({ open_edit_section: false, section_to_edit: {} });
  };

  const openDeleteSection = (section) => {
    updateState({ open_delete_section: true, deleting_section: section });
  };

  const closeDeleteSection = () => {
    updateState({ open_delete_section: false, deleting_section: {} });
  };

  const openTrainingBoard = (section) => {
    updateState({ open_training_board: true, training_board_section: section });
  };

  const closeTrainingBoard = () => {
    updateState({ open_training_board: false, training_board_section: {} });
  };

  const refreshList = () => {
    updateState({ sections: [], page: 1, has_more: true });
  };
  const handleChangeSelect = (selected, value) => {
    updateState({ [value.name]: selected, page: 1 });
  };
  function resetFilters() {
    updateState({
      accessibility: null,
      section_type: [],
      age_group: [],
      search_value: "",
      page: 1,
      has_more: true,
      players_no: [0, 40],
      goalkeepers_no: [0, 5],
      range_filter_was_changed: false,
    });
  }

  return (
    <TrainingSectionsProvider token={token}>
      <TFBCard>
        <TFBCardHeader title={languagePack.training_sections}>
          <TFBButton
            renderIcon={() => <TFBIcon name="edit" />}
            onClick={() => updateState({ open_edit_tags: true })}
          >
            {" "}
            {languagePack.Edit_labels}{" "}
          </TFBButton>
          <TFBButton
            onClick={openAddSection}
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
          >
            {languagePack.add_training_sections}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          <div className={styles.searchAndFiltersDiv}>
            <div className={styles.searchDiv}>
              <TFBSearch
                label={"Cautare"}
                placeholder={
                  window.screen.width > 1024
                    ? languagePack.find_training_section
                    : languagePack.find_training_section_short
                }
                classNameSearch={styles.searchVideo}
                classNameComponent={styles.searchComponent}
                fluid
                showNoResults={false}
                value={state.search_value}
                onSearchChange={(e) =>
                  updateState({
                    search_value: e.target.value,
                    page: 1,
                    has_more: true,
                  })
                }
              />
              <div className={styles.filtersDiv}>
                <div className={styles.firstRowDiv}>
                  <TFBSelect
                    style={{
                      container: {
                        width:
                          window.screen.width < 1500 &&
                            window.screen.width >= 1024
                            ? "200px"
                            : window.screen.width <= 1024
                              ? "100%"
                              : "260px",
                      },
                    }}
                    label={languagePack.age_group}
                    isMulti={true}
                    options={filtersData?.list[0].children ?? []}
                    value={state.age_group}
                    name="age_group"
                    onChange={(selected, value) =>
                      handleChangeSelect(selected, value)
                    }
                  />
                  <TFBSelect
                    style={{
                      container: {
                        width:
                          window.screen.width < 1500 &&
                            window.screen.width >= 1024
                            ? "200px"
                            : window.screen.width <= 1024
                              ? "100%"
                              : "260px",
                      },
                    }}
                    label={languagePack.section_type}
                    isMulti={true}
                    options={filtersData?.list[1].children ?? []}
                    value={state.section_type}
                    name="section_type"
                    onChange={(selected, value) =>
                      handleChangeSelect(selected, value)
                    }
                  />
                  <TFBSelect
                    style={{
                      container: {
                        width: window.screen.width <= 991 ? "100%" : "140px",
                      },
                    }}
                    label={languagePack.accessibility}
                    options={filtersData?.list[2].children ?? []}
                    value={state.accessibility}
                    isClearable={true}
                    name="accessibility"
                    onChange={(selected, value) =>
                      handleChangeSelect(selected, value)
                    }
                  />
                </div>
                <div className={styles.secondRowDiv}>
                  <div className={styles.playersNoDiv}>
                    <div className={styles.rangeSliderLabel}>
                      {languagePack.number_of_players}
                    </div>
                    <TFBRangeSlider
                      min={0}
                      max={40}
                      value={state.players_no}
                      onChange={(e, value) => {
                        updateState({
                          players_no: value,
                          range_filter_was_changed: true,
                        });
                      }}
                      valueLabelDisplay="on"
                    />
                  </div>{" "}
                  <div className={styles.goalkeepersNoDiv}>
                    <div className={styles.rangeSliderLabel}>
                      {languagePack.number_of_goalkeepers}
                    </div>
                    <TFBRangeSlider
                      min={0}
                      max={5}
                      value={state.goalkeepers_no}
                      onChange={(e, value) =>
                        updateState({
                          goalkeepers_no: value,
                          range_filter_was_changed: true,
                        })
                      }
                      valueLabelDisplay="on"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonsDiv}>
            {checkForFilterChange(state) && (
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="check" />}
                onClick={() => {
                  updateState({ range_filter_was_changed: false });
                  refreshList();
                }}
                className={styles.fullWidth}
              >
                {languagePack["apply_filter"]}
              </TFBButton>
            )}
            {checkForFilterChange(state) && (
              <div className={styles.selectDiv}>
                <TFBButton
                  color="lightGray"
                  renderIcon={() => <TFBIcon name="cancel" color="gray" />}
                  onClick={() => resetFilters()}
                  className={styles.fullWidth}
                >
                  {languagePack["cancel_filter"]}
                </TFBButton>
              </div>
            )}{" "}
          </div>

          {_.size(state.sections) > 0 && (
            <div className="sectionList">
              {_.map(state.sections, (section, index) => {
                return (
                  <TrainingSectionBox
                    key={"section-" + index}
                    onClickEdit={() => openEditSection(section)}
                    onClickDelete={() => openDeleteSection(section)}
                    onClickView={() => openViewSection(section)}
                    onClickOpenTrainingBoard={() => openTrainingBoard(section)}
                    style={{
                      // eslint-disable-next-line no-restricted-globals
                      width: window.screen.width > 1024 ? "40%" : "100%",
                      // eslint-disable-next-line no-restricted-globals
                      maxWidth: window.screen.width > 1024 ? "49.7%" : "100%",
                    }}
                    tags={_.map(section.tags.list, (tag) => tag.label)}
                    playersNo={Number(section?.no_of_players)}
                    gksNo={Number(section?.no_of_gks)}
                    showEdit={
                      Number(currentUser?.["id_user"]) ===
                        Number(section.id_user) ||
                        section.id_user === null ||
                        // Gabriel Para access to edit sections
                        Number(currentUser?.["id_user"]) === 503
                        ? true
                        : false
                    }
                    showDelete={
                      Number(currentUser?.["id_user"]) ===
                        Number(section.id_user) || section.id_user === null
                        ? true
                        : false
                    }
                    name={section.section_name}
                    imgSrc={section.section_img}
                    imgSrcFromCanvas={section.has_section_img_from_canvas ? section.section_img_from_canvas : null}
                  >
                    {section.added_by && (
                      <div style={{ maxWidth: "100px" }}>
                        {" "}
                        {"Added by " + section.added_by}
                      </div>
                    )}
                  </TrainingSectionBox>
                );
              })}
            </div>
          )}
          {_.size(state.sections) === 0 && isSectionListLoading === false && (
            <TFBPlaceholder text={languagePack.no_training_sections} />
          )}
          {<div ref={loaderRef}></div>}
          {isSectionListLoading && <CircularLoader />}
        </TFBCardBody>

        {state.open_add_section && (
          <AddTrainingSectionPopup
            closePopup={closeAddSection}
            refetchSections={refreshList}
            filters={state.filters}
          />
        )}

        {state.open_edit_section && (
          <EditTrainingSectionPopup
            filters={filtersData?.list}
            closePopup={closeEditSection}
            refetchSections={refreshList}
            sectionId={state.section_to_edit.id_training_section}
          />
        )}
        {state.open_view_section && (
          <ViewTrainingSectionPopup
            sectionId={state.section_to_view.id_training_section}
            closePopup={handleCloseViewSectionPopup}
          />
        )}

        {state.open_delete_section && (
          <DeleteTrainingSection
            openPopup={state.open_delete_section}
            closePopup={closeDeleteSection}
            refreshList={refreshList}
            section={state.deleting_section}
          />
        )}

        {state.open_training_board &&
          <TrainingBoard
            openPopup={state.open_training_board}
            closePopup={closeTrainingBoard}
            refreshList={refreshList}
            section={state.training_board_section}

          />
        }

        {state.open_edit_tags && (
          <EditTagsPopup
            refetchFilters={refetchFiltersData}
            languagePack={languagePack}
            onClosePopup={() => updateState({ open_edit_tags: false })}
            tags={state.filters}
          />
        )}
      </TFBCard>
    </TrainingSectionsProvider>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(TrainingSections);
