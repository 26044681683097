import React from "react";
import { Dropdown, Input, Button, TextArea } from "semantic-ui-react";
import axios from "axios";
import { logout } from "./../../utils/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DragParameters from "../../components/reusable/report-form";
import AddNewParamForm from "./AddNewParamForm";

class AddReportForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownTeams: [],
      selected_team: "",
      test_name: "",
      test_start: new Date(),
      test_end: new Date(),
      test_description: "",
      addNewParameter: false,
      selected_params: [],
    };
  }

  componentDidMount = () => {
    this.getTeamsDropdown();
  };

  getTeamsDropdown = () => {
    axios
      .get(`upload/get_teams_dropdown`, {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({ dropdownTeams: res.data });
        }
      })
      .catch((err) => console.log(err));
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleDateChangeStart = (value) => {
    this.setState({ test_start: value });
  };

  handleDateChangeEnd = (value) => {
    this.setState({ test_end: value });
  };

  addParam = (value) => {
    this.setState({
      selected_params: this.state.selected_params.concat(value),
    });
  };

  submitReport = () => {
    const report_data = {
      id_team: this.state.selected_team,
      physical_test_name: this.state.test_name,
      physical_test_start: this.state.test_start,
      physical_test_end: this.state.test_end,
    };
    axios
      .post("report/submit_report", report_data)
      .then(() => { });
  };

  render() {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ width: 500 }}>
          <Dropdown
            placeholder="Selecteaza echipa"
            fluid
            selection
            value={this.state.selected_team}
            options={this.state.dropdownTeams}
            onChange={this.handleChangeDropdown}
            name="selected_team"
            className="form-item"
          />
          <Input
            placeholder="Numele testului"
            fluid
            value={this.state.test_name}
            onChange={this.handleChangeInput}
            name="test_name"
            className="form-item"
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div
              style={{
                display: "flex",
                width: "80%",
                justifyContent: "space-between",
                margin: "0 auto",
                marginBottom: 15,
              }}
            >
              <DateTimePicker
                value={this.state.test_start}
                onChange={this.handleDateChangeStart}
                label="Data inceperii"
                format="dd MMMM, HH:mm"
                name="test_start"
                ampm={false}
              />
              <DateTimePicker
                value={this.state.test_end}
                onChange={this.handleDateChangeEnd}
                label="Data incheierii"
                format="dd MMMM, HH:mm"
                name="test_end"
                ampm={false}
              />
            </div>
          </MuiPickersUtilsProvider>

          <TextArea
            placeholder="Descriere..."
            value={this.state.test_description}
            style={{ width: "100%" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button primary style={{ width: 220 }}>
              Anuleaza
            </Button>
            <Button primary style={{ width: 220 }} onClick={this.submitReport}>
              Salveaza
            </Button>
          </div>
        </div>
        <div>
          <DragParameters addParam={this.addParam} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 15,
              marginRight: 4,
            }}
          >
            <Button
              primary
              style={{ width: 220 }}
              onClick={() =>
                this.setState({ addNewParameter: !this.state.addNewParameter })
              }
            >
              Adauga parametru nou
            </Button>
          </div>
          {this.state.addNewParameter ? <AddNewParamForm /> : ""}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddReportForm);
