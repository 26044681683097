import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import { Flag } from "semantic-ui-react";
import { useParams, useHistory, Redirect } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import styles from "./Team.module.scss";
import CircularLoader from "../../components/reusable/Loader";
import useAxiosGet from "../../components/customHooks/useAxiosGet";
import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../components/design-system/Button/TFBButton";
import TFBIcon from "../../components/design-system/Icon/TFBIcon";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import TFBDropdown from "../../components/design-system/Dropdown/TFBDropdown";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBPlayerWithAvatar from "../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBPlaceholder from "../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBCheckbox from "../../components/design-system/Checkbox/TFBCheckbox";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../components/reusable/useStyles";
import { isValidSecondCountry } from "../../utils/utilFunctions";

import HandlePlayerInactiveStatus from "./ClubProfile/Teams/HandlePlayerInactiveStatus";
import AddPlayer from "./AddPlayer.js";
import DeleteTeam from "./ClubProfile/Teams/DeleteTeam";
import ExportPlayers from "./ClubProfile/ExportPlayers/ExportPlayers";
import TFBCircularLoader from "../../components/design-system/CircularLoader/TFBCircularLoader";
import { updateQuickSetup } from "./../../utils/api";
import { showNotification } from "../../components/customHooks/useAxiosPost";
import TeamPerformance from "./TeamPerformance";

const Team = (props) => {
  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      redirect_to_teams: false,

      open_add_player: false,

      open_delete_team: false,

      open_change_status_player: false,
      changing_status_player: {},

      open_export_player: false,
      export_player: {},

      team: {},
      season: null,
      current_season: null,
      players: [],

      hide_inactive_players: true,

      stats: {},

      order_by: null,
      order_type: null,
    }
  );

  const { languagePack, updateQuickSetup } = props;
  const { id_club, id_user, token } = props.currentUser;
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (_.get(history, "location.state.redirectFromSetup")) {
      openAddPlayer();
      history.replace({
        ...history.location,
        state: undefined,
      });
    }
  }, []);

  const { data: hasAccessToTeam, loading: isUserAccessLoading } = useAxiosGet(
    `user/has_access_to_team_2?id_team=${id}&id_user=${id_user}`
  );

  useEffect(() => {
    if (hasAccessToTeam) {
      if (hasAccessToTeam?.has_access_to_resource == 1) {
        updateState({ team: hasAccessToTeam.team?.data ?? {} });
        refetchSeasons();
      } else {
        updateState({ redirect_to_teams: true });
      }
    }
  }, [hasAccessToTeam]);

  const {
    data: seasons,
    loading: isSeasonsLoading,
    refetch: refetchSeasons,
  } = useAxiosGet(`club/get_team_seasons?id_team=${id}`, true);

  useEffect(() => {
    if (seasons) {
      if (seasons?.success == 1) {
        if (Array.isArray(seasons?.list)) {
          updateState({
            season: seasons.current_season?.data?.id_season ?? null,
            current_season: seasons.current_season?.data?.id_season ?? null,
          });
        }
      }
    }
  }, [seasons]);

  const {
    data: playerList,
    loading: isPlayerListLoading,
    refetch: refetchPlayerList,
  } = useAxiosGet(
    `club/get_players_2?id_team=${id}&id_season=${state.season}&token=${token}`,
    true
  );

  useEffect(() => {
    if (state.season) {
      refetchPlayerList();
    }
  }, [state.season]);

  const {
    data: statList,
    loading: isStatListLoading,
    refetch: refetchStatList,
  } = useAxiosGet(
    `club/get_player_list_with_stats?id_club=${id_club}&id_team=${id}&id_season=${state.season}&token=${token}`,
    true
  );

  useEffect(() => {
    if (statList) {
      if (statList?.success == 1) {
        updateState({
          stats: statList.stats,
          players: _.map(state.players, (p) => ({
            ...p,
            minute: statList.stats?.[p.id_player]?.minute ?? null,
            goal: statList.stats?.[p.id_player]?.goal ?? null,
            assist: statList.stats?.[p.id_player]?.assist ?? null,
          })),
        });
      } else {
        updateState({ stats: {} });
      }
    }
  }, [statList]);

  useEffect(() => {
    if (playerList) {
      if (playerList?.success == 1) {
        if (Array.isArray(playerList?.list)) {
          refetchStatList();
          updateState({
            players: _.map(
              _.filter(playerList.list, (e) =>
                state.hide_inactive_players ? e.inactive == 0 : true
              ),
              (e) => ({
                ...e,
                checked: false,
                age: e.player_dob ? moment().diff(e.player_dob, "years") : null,
                order_by_position:
                  { goalkeeper: 0, defender: 1, midfielder: 2, forward: 3 }[
                    String(e.player_position).toLowerCase()
                  ] ?? 100,
              })
            ),
            average_age: playerList.average_age,
          });
        }
      }
    }
  }, [playerList]);

  useEffect(() => {
    updateState({
      players: state.hide_inactive_players
        ? _.filter(state.players, (e) =>
            state.hide_inactive_players ? e.inactive == 0 : true
          )
        : _.concat(
            state.players,
            _.map(
              _.filter(playerList?.list ?? [], (e) => e.inactive == 1),
              (e) => ({ ...e, checked: false })
            )
          ),
    });
  }, [state.hide_inactive_players]);

  const openAddPlayer = () => {
    updateState({ open_add_player: true });
  };

  const closeAddPlayer = () => {
    updateState({ open_add_player: false });
  };

  const openDeleteTeam = () => {
    updateState({ open_delete_team: true });
  };

  const closeDeleteTeam = () => {
    updateState({ open_delete_team: false });
  };

  const openChangeStatusPlayer = (player) => {
    updateState({
      open_change_status_player: true,
      changing_status_player: player,
    });
  };

  const closeChangeStatusPlayer = () => {
    updateState({
      open_change_status_player: false,
      changing_status_player: {},
    });
  };

  const openExportPlayer = () => {
    if (_.some(state.players, (player) => player.checked)) {
      updateState({
        open_export_player: true,
        export_player: _.map(
          _.filter(state.players, (e) => e.checked),
          (e) => e.id_player
        ),
      });
    } else {
      showNotification(
        "danger",
        languagePack.club_management,
        languagePack.select_at_least_one_player
      );
    }
  };

  const closeExportPlayer = () => {
    updateState({ open_export_player: false, export_player: {} });
  };

  const handleChangeDropdown = (e, value) => {
    updateState({ [value.name]: value.value });
  };

  const handleChangeHideInactivePlayers = (e) => {
    updateState({ hide_inactive_players: e.target.checked });
  };

  const handleChangePlayerCheckbox = (e, index = "all") => {
    let players = _.clone(state.players);
    if (index == "all") {
      players = _.map(players, (p) => ({ ...p, checked: e.target.checked }));
    } else {
      players[index]["checked"] = e.target.checked;
    }
    updateState({ players: players });
  };

  const handleFilterBy = (filter_by, filter_type) => {
    const filter_use_cases = {
      player_name: {
        field_name: "player_name",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      age: {
        field_name: "age",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      minute: {
        field_name: "minute",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      goal: {
        field_name: "goal",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      assist: {
        field_name: "assist",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
      order_by_position: {
        field_name: "order_by_position",
        asc: "desc",
        desc: "asc",
        null: "asc",
      },
    };

    let sortedArray = _.sortBy(state.players, [
      filter_use_cases[filter_by]["field_name"],
    ]);

    if (filter_use_cases[filter_by][filter_type] == "desc") {
      sortedArray = _.reverse(sortedArray);
    }

    updateState({
      order_by: filter_use_cases[filter_by]["field_name"],
      order_type: filter_use_cases[filter_by][filter_type],
      players: sortedArray,
    });
  };

  if (state.redirect_to_teams) {
    return <Redirect to="/club" />;
  }

  return (
    <TFBCard>
      {!isUserAccessLoading ? (
        <>
          <TFBCardHeader
            title={
              languagePack.team_players + " " + (state.team?.team_name ?? "")
            }
            titleChildren={() => (
              <TFBIconButton
                name="delete"
                color="white"
                onClick={openDeleteTeam}
              />
            )}
          >
            {!isPlayerListLoading && state.season && (
              <>
                <TFBButton
                  color={"green"}
                  renderIcon={() => <TFBIcon name={"arrow-down"} />}
                  onClick={openExportPlayer}
                >
                  {languagePack.export_player_list}
                </TFBButton>
                {state.season == state.current_season && (
                  <TFBButton
                    color={"green"}
                    renderIcon={() => <TFBIcon name={"add"} />}
                    onClick={openAddPlayer}
                  >
                    {languagePack["add_player"]}
                  </TFBButton>
                )}
              </>
            )}
            {!isSeasonsLoading && (
              <TFBDropdown
                placeholder={languagePack["select_season"]}
                selection
                options={seasons?.list ?? []}
                value={state.season}
                onChange={handleChangeDropdown}
                name="season"
              />
            )}
          </TFBCardHeader>
          <TFBCardBody>
            {!isPlayerListLoading && state.season ? (
              _.size(state.players) > 0 ? (
                <>
                  <div className={styles.selectActivePlayer}>
                    <TFBCheckbox
                      label={languagePack.hide_inactiv_players}
                      checked={state.hide_inactive_players}
                      onChange={handleChangeHideInactivePlayers}
                    />
                  </div>
                  <TableContainer>
                    <Table faria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TFBTableCell sx={sxStickyColumn} align="left">
                            <div className="df">
                              <TFBCheckbox
                                checked={
                                  _.size(
                                    _.filter(state.players, (e) => e.checked)
                                  ) === _.size(state.players)
                                }
                                onChange={handleChangePlayerCheckbox}
                                mode="light"
                              />
                              <div
                                className={styles.headerCell}
                                onClick={() =>
                                  handleFilterBy(
                                    "player_name",
                                    state.order_type
                                  )
                                }
                              >
                                <div className={styles.headerLable}>
                                  {languagePack.PLAYER_NAME}
                                </div>
                                <div className={styles.headerFilter}>
                                  {!(
                                    state.order_by == "player_name" &&
                                    state.order_type == "desc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-up"
                                      color="lightGray"
                                    />
                                  )}
                                  {!(
                                    state.order_by == "player_name" &&
                                    state.order_type == "asc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-down"
                                      color="lightGray"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            {languagePack.player_main_foot}
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div
                              className={styles.headerCell}
                              onClick={() =>
                                handleFilterBy(
                                  "order_by_position",
                                  state.order_type
                                )
                              }
                            >
                              <div> {languagePack.POSITION}</div>
                              <div className={styles.headerFilter}>
                                {!(
                                  state.order_by == "order_by_position" &&
                                  state.order_type == "desc"
                                ) && (
                                  <TFBIcon name="angle-up" color="lightGray" />
                                )}
                                {!(
                                  state.order_by == "order_by_position" &&
                                  state.order_type == "asc"
                                ) && (
                                  <TFBIcon
                                    name="angle-down"
                                    color="lightGray"
                                  />
                                )}
                              </div>
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            {languagePack.NATIONALITY}
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div
                              className={styles.headerCell}
                              onClick={() =>
                                handleFilterBy("age", state.order_type)
                              }
                            >
                              <div className={styles.headerLable}>
                                {languagePack.AGE}
                                <div>
                                  {state.average_age > 0 && (
                                    <div
                                      className={styles.averageAge}
                                    >{`${languagePack["average"]}: ${state.average_age} ${languagePack["age_years"]}`}</div>
                                  )}
                                </div>
                              </div>
                              <div className={styles.headerFilter}>
                                {!(
                                  state.order_by == "age" &&
                                  state.order_type == "desc"
                                ) && (
                                  <TFBIcon name="angle-up" color="lightGray" />
                                )}
                                {!(
                                  state.order_by == "age" &&
                                  state.order_type == "asc"
                                ) && (
                                  <TFBIcon
                                    name="angle-down"
                                    color="lightGray"
                                  />
                                )}
                              </div>
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div
                              className={styles.headerCell}
                              onClick={() =>
                                !isStatListLoading &&
                                handleFilterBy("minute", state.order_type)
                              }
                            >
                              <div className={styles.headerLable}>
                                {languagePack.minutes_played_uppercase}
                              </div>
                              {!isStatListLoading && (
                                <div className={styles.headerFilter}>
                                  {!(
                                    state.order_by == "minute" &&
                                    state.order_type == "desc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-up"
                                      color="lightGray"
                                    />
                                  )}
                                  {!(
                                    state.order_by == "minute" &&
                                    state.order_type == "asc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-down"
                                      color="lightGray"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div
                              className={styles.headerCell}
                              onClick={() =>
                                !isStatListLoading &&
                                handleFilterBy("goal", state.order_type)
                              }
                            >
                              <div className={styles.headerLable}>
                                {languagePack.goals}
                              </div>
                              {!isStatListLoading && (
                                <div className={styles.headerFilter}>
                                  {!(
                                    state.order_by == "goal" &&
                                    state.order_type == "desc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-up"
                                      color="lightGray"
                                    />
                                  )}
                                  {!(
                                    state.order_by == "goal" &&
                                    state.order_type == "asc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-down"
                                      color="lightGray"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="center">
                            <div
                              className={styles.headerCell}
                              onClick={() =>
                                !isStatListLoading &&
                                handleFilterBy("assist", state.order_type)
                              }
                            >
                              <div className={styles.headerLable}>
                                {languagePack.assists}
                              </div>
                              {!isStatListLoading && (
                                <div className={styles.headerFilter}>
                                  {!(
                                    state.order_by == "assist" &&
                                    state.order_type == "desc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-up"
                                      color="lightGray"
                                    />
                                  )}
                                  {!(
                                    state.order_by == "assist" &&
                                    state.order_type == "asc"
                                  ) && (
                                    <TFBIcon
                                      name="angle-down"
                                      color="lightGray"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                          </TFBTableCell>
                          <TFBTableCell align="right">
                            {languagePack.product_actions}
                          </TFBTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {state.players.map((row, index) => (
                          <TableRow
                            key={"player-" + index}
                            sx={{
                              backgroundColor:
                                index % 2 == 0 ? "#fafafa" : "white",
                            }}
                            className={`${
                              row.inactive == 1 ? styles.inactivePlayer : ""
                            } ${row.checked ? styles.checkedRow : ""}`}
                          >
                            <TFBTableCell
                              sx={sxStickyColumn}
                              style={{
                                backgroundColor:
                                  index % 2 == 0 ? "#fafafa" : "white",
                              }}
                              className={`${
                                row.inactive == 1 ? styles.inactivePlayer : ""
                              } ${row.checked ? styles.checkedRow : ""}`}
                              align="left"
                            >
                              <div className="df df-center">
                                <TFBCheckbox
                                  mode="light"
                                  checked={row.checked}
                                  onChange={(e) =>
                                    handleChangePlayerCheckbox(e, index)
                                  }
                                />
                                <TFBPlayerWithAvatar
                                  playerName={row.player_name}
                                  avatarUrl={`${
                                    window.baseURL
                                  }uploads/players/${row.id_player}.jpg?v=${(
                                    Math.random() * 100
                                  ).toFixed(2)}`}
                                  onClick={() =>
                                    history.push(
                                      `/club/player/${row.id_player}?id_season=${state.season}`
                                    )
                                  }
                                />
                              </div>
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {row.player_main_foot && (
                                <div className={styles.mainFootDiv}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {row.player_main_foot !== "Left" && (
                                      <TFBIcon
                                        style={{
                                          height: "20px",
                                          marginRight: "4px",
                                        }}
                                        name={"right-soccer-boot"}
                                      />
                                    )}
                                    {(row.player_main_foot === "Left" ||
                                      row.player_main_foot === "Both") && (
                                      <TFBIcon
                                        style={{
                                          height: "20px",
                                          marginLeft: "4px",
                                        }}
                                        name="left-soccer-boot"
                                      />
                                    )}
                                  </div>
                                  <div>
                                    {row.player_main_foot !== "Left" && (
                                      <span
                                        style={{
                                          marginRight:
                                            row.player_main_foot === "Both"
                                              ? "8px"
                                              : "",
                                        }}
                                      >
                                        R
                                      </span>
                                    )}
                                    {(row.player_main_foot === "Left" ||
                                      row.player_main_foot === "Both") && (
                                      <span
                                        style={{
                                          marginLeft:
                                            row.player_main_foot === "Both"
                                              ? "8px"
                                              : "",
                                        }}
                                      >
                                        L
                                      </span>
                                    )}
                                  </div>
                                </div>
                              )}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {row.player_position}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              <div className="df df-center-center">
                                <Flag
                                  name={_.lowerCase(
                                    _.toString(row.birth_country)
                                  )}
                                  className={
                                    row.inactive == 1
                                      ? styles.inactivePlayerFlag
                                      : ""
                                  }
                                />
                                {row.birth_country}
                              </div>
                              {isValidSecondCountry(
                                row.birth_country,
                                row.second_country
                              ) && (
                                <div className="df df-center-center">
                                  <Flag
                                    name={_.lowerCase(
                                      _.toString(row.second_country)
                                    )}
                                    className={
                                      row.inactive == 1
                                        ? styles.inactivePlayerFlag
                                        : ""
                                    }
                                  />
                                  {row.second_country}
                                </div>
                              )}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {row.player_dob
                                ? moment().diff(row.player_dob, "years")
                                : "-"}
                              <div className={styles.age}>
                                {row.player_dob
                                  ? moment(row.player_dob).format("DD.MM.YYYY")
                                  : ""}
                              </div>
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {!isStatListLoading ? (
                                state.stats?.[row.id_player]?.minute
                              ) : (
                                <TFBCircularLoader size={20} />
                              )}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {!isStatListLoading ? (
                                state.stats?.[row.id_player]?.goal
                              ) : (
                                <TFBCircularLoader size={20} />
                              )}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              {!isStatListLoading ? (
                                state.stats?.[row.id_player]?.assist
                              ) : (
                                <TFBCircularLoader size={20} />
                              )}
                            </TFBTableCell>
                            <TFBTableCell align="center">
                              <div className="df df-end df-gap-10">
                                <TFBIconButton
                                  name={`${
                                    row.inactive == 0 ? "active" : "inactive"
                                  }-player`}
                                  className="tfb-icon-button-no-filter"
                                  color="darkGray"
                                  onClick={() => openChangeStatusPlayer(row)}
                                />
                                <TFBIconButton
                                  name="view"
                                  color="darkGray"
                                  onClick={() =>
                                    history.push(
                                      `/club/player/${row.id_player}?id_season=${state.season}`
                                    )
                                  }
                                />
                                <TFBIconButton
                                  name="people-gallery"
                                  color="darkGray"
                                  onClick={() =>
                                    history.push(
                                      `/club/player-gallery/${row.id_player}`
                                    )
                                  }
                                />
                              </div>
                            </TFBTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <TFBPlaceholder text={languagePack.no_players_placeholder} />
              )
            ) : (
              <CircularLoader />
            )}

            {state.open_add_player && (
              <AddPlayer
                openPopup={state.open_add_player}
                closePopup={closeAddPlayer}
                refreshList={refetchPlayerList}
                id_club={id_club}
                id_team={id}
                id_season={state.season}
              />
            )}

            {state.open_delete_team && (
              <DeleteTeam
                openPopup={state.open_delete_team}
                closePopup={closeDeleteTeam}
                refreshList={() => history.push(`/club`)}
                team={state.team}
                updateQuickSetup={updateQuickSetup}
              />
            )}

            {state.open_change_status_player && (
              <HandlePlayerInactiveStatus
                isOpenPopup={state.open_change_status_player}
                closePopup={closeChangeStatusPlayer}
                idPlayer={state.changing_status_player?.id_player}
                setActive={state.changing_status_player?.inactive == 1}
                refreshList={refetchPlayerList}
              />
            )}

            {state.open_export_player && (
              <ExportPlayers
                exportListPopup={state.open_export_player}
                closePopup={closeExportPlayer}
                languagePack={languagePack}
                token={token}
                playerData={state.export_player}
                id_club={id_club}
                id_team={id}
              />
            )}
          </TFBCardBody>
        </>
      ) : (
        <CircularLoader />
      )}
    </TFBCard>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Team);
