import React, { useState, useEffect } from 'react';
import axios from "axios";
import NumberFormat from "react-number-format";


export default function FacebookGeneralNumbers(props) {
    const [totalReactions, setTotal] = useState(0);
    const [reactions, setReactions] = useState([]);

    useEffect(() => {
        getData();
    }, [props.year])

    function getData() {
        axios.get(`fans/facebook_reactions_stats?year=${props.year}&id_network=${props.id_network}`)
            .then(res => {
                setReactions(res.data.positive_feedback)
                setTotal(res.data.total_reactions)
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom">
            <div className="dashboard-box-container">
                <div className="dashboard-box-title">Facebook page interactions </div>
                <div className="facebook-total-likes-number">
                    <NumberFormat
                        value={totalReactions}
                        displayType={"text"}
                        thousandSeparator={true}
                    />

                </div>
                <div className="facebook-total-likes-text">
                    Total posts reactions in {props.year}
                </div>
                <div className="stats-row">
                    <div className="stat-column">
                        <div className='stat-column-title'>Reactions distribution</div>
                        {reactions.filter((i, index) => (index < 3)).map((item, index) => (
                            <div className="stat-row" style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "#ffffff" }}>
                                <div className="stat-name">
                                    <img src={`/assets/facebook_icons/${item.type.toLowerCase()}.png`} alt="" />
                                    {item.type}</div>
                                <div className="stat-value">  <NumberFormat
                                    value={item.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                /></div>
                            </div>
                        ))}
                    </div>
                    <div className="stat-column">
                        <div className='stat-column-title'></div>
                        {reactions.filter((i, index) => (index >= 3 && index < 6)).map((item, index) => (
                            <div className="stat-row" style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "#ffffff" }}>
                                <div className="stat-name">
                                    <img src={`/assets/facebook_icons/${item.type.toLowerCase()}.png`} alt="" />
                                    {item.type}</div>
                                <div className="stat-value">  <NumberFormat
                                    value={item.value}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                /></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}