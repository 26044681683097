import React from "react";
import "./TacticBoard.scss";
import pitch from "../../../assets/tactic-board/pitch.jpg";
import cont_arrow from "../../../assets/tactic-board/cont-arrow.png";
import dash_arrow from "../../../assets/tactic-board/dash-arrow.png";
import * as htmlToImage from "html-to-image";
import Draggable from "react-draggable";
import { Button, Icon, TextArea } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import Xarrow from "react-xarrows";
import useMouse from "@react-hook/mouse-position";
import parseISOWithOptions from "date-fns/esm/fp/parseISOWithOptions/index.js";
import $ from "jquery";
import { sum } from "lodash";

class TacticBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: [
        { id: 0, team: 0 },
        { id: 1, team: 0 },
        { id: 2, team: 0 },
        { id: 3, team: 0 },
        { id: 4, team: 0 },
      ],

      cones: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
      teams: [{ id: 0, players: 0 }],
      smallCones: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
      inflatableIcon: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
      ballIcon: [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],

      totalPlayers: 5,

      image: "",
      selected_item: "",
      sliderValue: 0,
      arrows: [
        { start: "player-3", end: "cone-12", type: "running" },
        { start: "player-2", end: "player-4", type: "normal" },
      ],

      mousePosition: {
        X: 0,
        Y: 0,
      },
    };
    this.onMouseMove = this.onMouseMove.bind(this);
  }

  arrowDesign() {
    this.state.arrows.concat({
      start: "asdasd",
      end: "asdasd1",
      type: "running",
    });
  }

  addNote() {
    let heading = document.createElement("div");
    let content = document.createElement("div");
    heading.id = "asdasd";
    content.id = "asdasd1";
  }

  // arrowDesign(e) {
  //   $(function() {
  //     /* var fadeDelay = 1000;
  //     var fadeDuration = 1000; */
  //     $(".tactic-board-container").on("click", function(e) {
  //       var div = $('<div class="image-wrapper-add-tactic">')
  //         .css({
  //           left: e.nativeEvent.offsetX + "px",
  //           top: e.nativeEvent.offsetY + "px",
  //         })
  //         .append($('<img src="" alt="myimage" />'))
  //         .appendTo(".tactic-board-pitch");
  //     });
  //   });
  // }

  onMouseMove(e) {
    const pitch = document.getElementById("pitch");
    const boundingRect = pitch.getBoundingClientRect();
    this.setState(
      {
        mousePosition: {
          X: e.clientX - boundingRect.left,
          Y: e.clientY - boundingRect.top,
        },
      }
      // console.log(this.state.mousePosition)
    );
  }

  // positions.X = e.nativeEvent.offsetX;
  // positions.Y = e.nativeEvent.offsetY;

  handleDrag = (e, ui) => {
    const { x, y } = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      },
    });
  };

  onStart = () => {
    this.setState({ activeDrags: ++this.state.activeDrags });
  };

  onStop = () => {
    this.setState({ activeDrags: --this.state.activeDrags });
  };

  handleOnMouseDown = () => {
    document.getElementById("onMouseDown").style.display = "block";
  };

  handleOnDragStop = () => {
    document.getElementById("onMouseDown").style.display = "none";
  };

  updateColors = () => {
    let colorsArray = [];
    let items = [...this.state.players];

    for (var j = 0; j < this.state.teams.length; j++) {
      let nr_pl = this.state.teams[j].players;
      let id = this.state.teams[j].id;
      for (var k = 0; k < nr_pl; k++) {
        colorsArray.push(id);
      }
    }
    for (var i = 0; i < this.state.players.length; i++) {
      let item = { ...items[i] };
      item.team = colorsArray[i];
      items[i] = item;
    }
    this.setState({ players: items });
  };

  dragOver(e) {
    e.preventDefault();
    e.target.classList.add("drag-over");
  }

  componentDidMount() {
    const box = document.getElementById("jjj");
    box.addEventListener("dragover", this.dragOver);
  }

  getTotalPlayersDistributed = () => {
    let sum = 0;
    this.state.teams.forEach((element) => {
      // console.log("element", element);
      let currentElement = element.players;
      sum += currentElement;
    });

    return sum;
  };

  handleChangeTeamPlayers = (e, id_team, type) => {
    let items = [...this.state.teams];
    let item = { ...items[id_team] };
    // console.log("items", items);
    // console.log("item", item);

    if (type === "increase") item.players = parseInt(item.players, 10) + 1;
    else if (item.players >= 1) item.players = parseInt(item.players, 10) - 1;
    else alert("nu poti introduce un numar mai mic ca 0");

    items[id_team] = item;
    this.setState({ teams: items }, this.updateColors);

    // this.totalPlayerDisponibility();
  };

  saveBoard = () => {
    var node = document.getElementById("my-node");
    htmlToImage
      .toPng(node)
      .then((dataUrl) => {
        const payload = {
          image: dataUrl,
        };
        axios
          .post("training/save_tactic_board", payload)
          .then((res) => {
            store.addNotification({
              title: "Gestionare antrenamente",
              message: "Detaliile sectiunii au fost adaugate cu succes!",
              type: "success",
              insert: "bottom",
              container: "bottom-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 2000,
              },
            });
            this.props.closePopup();
          })
          .catch((err) => console.log(err));
      })

      .catch((err) => console.log(err));
  };

  handleChangeSlider = (event) => {
    this.setState({ sliderValue: event.target.value });
  };

  render() {
    const dragHandlers = { onStart: this.onStart, onStop: this.onStop };
    const { X, Y } = this.state.mousePosition;
    return (
      <div className="tactic-board-container" onMouseMove={this.onMouseMove}>
        {/* <div className="tactic-board-container" onMouseMove={this.onMouseMove}> */}
        <div className="tactic-board-header">
          {/* <div className="tactic-board-title">{this.props.section_id}</div>
          <div>
            <h1>
              Mouse coordinates: {X} {Y}
            </h1>
          </div> */}
        </div>
        <div className="tactic-board-area">
          <div className="left-side-container">
            <div className="tactic-board-elements" draggable="false">
              <div
                className="element-container"
                draggable="false"
                onClick={() => {
                  const newItem = {
                    id: this.state.cones.length,
                  };
                  this.setState({
                    cones: this.state.cones.concat(newItem),
                  });
                }}
              >
                {" "}
                <div className="cone-icon" />
              </div>

              {/* <div className="element-container" draggable="false">
                {[...Array(15)].map((item, index) => {
                  return (
                    <Draggable {...dragHandlers} id={`cone-${index}`}>
                      <div className="cone-icon" id={`cone-${index}`} />
                    </Draggable>
                  );
                })}
              </div> */}

              <div
                className="element-container"
                draggable="false"
                onClick={() => {
                  const newItem = {
                    id: this.state.smallCones.length,
                  };
                  this.setState({
                    smallCones: this.state.smallCones.concat(newItem),
                  });
                }}
              >
                {" "}
                <div className="small-cone-icon" />
              </div>

              {/* <div className="element-container">
                {[...Array(15)].map((item, index) => {
                  return (
                    <Draggable {...dragHandlers} id={`small-cone-${index}`}>
                      <div
                        className="small-cone-icon"
                        id={`small-cone-${index}`}
                      />
                    </Draggable>
                  );
                })}
              </div> */}
              {/* <div className="element-container"> */}

              <div
                className="element-container"
                draggable="false"
                onClick={() => {
                  const newItem = {
                    id: this.state.inflatableIcon.length,
                  };
                  this.setState({
                    inflatableIcon: this.state.inflatableIcon.concat(newItem),
                  });
                }}
              >
                {" "}
                <div className="inflatable-icon" />
              </div>

              {/* <div className="element-container" draggable="false">
                {[...Array(10)].map((item, index) => {
                  return (
                    <Draggable {...dragHandlers} id={`inflatable-${index}`}>
                      <div
                        className="inflatable-icon"
                        id={`inflatable-${index}`}
                      />
                    </Draggable>
                  );
                })}
              </div> */}
              {/* <div className="element-container" style={{ marginBottom: 30 }}> */}

              <div
                className="element-container"
                draggable="false"
                onClick={() => {
                  const newItem = {
                    id: this.state.ballIcon.length,
                  };
                  this.setState({
                    ballIcon: this.state.ballIcon.concat(newItem),
                  });
                }}
              >
                {" "}
                <div className="ball-icon" />
              </div>

              {/* <div
                className="element-container"
                style={{ marginBottom: 30 }}
                draggable="false"
              >
                {[...Array(5)].map((item, index) => {
                  return (
                    <Draggable {...dragHandlers} id={`ball-${index}`}>
                      <div className="ball-icon" id={`ball-${index}`} />
                    </Draggable>
                  );
                })}
              </div> */}

              <div className="element-container-border" />
              {/* <div className="element-container element-container-button">
                <img src={cont_arrow} alt="arrow-1" /> */}
              <div
                // onClick={this.mousePosition}
                className="element-container element-container-button"
              >
                <img
                  src={cont_arrow}
                  // onClick={() => {
                  //   this.addNote();
                  //   this.arrowDesign();
                  // }}
                  alt="arrow-1"
                />
              </div>
              <div className="element-container element-container-button">
                <img src={dash_arrow} alt="arrow-2" />
              </div>

              <div
                id="onMouseDown"
                className="element-container element-container-button"
              >
                <Icon
                  // onDrop={this.dropped}
                  // ondrop="dropped(event, this.id)"
                  id="jjj"
                  className="trash alternate delete-field-icon"
                  size="large"
                />
              </div>
            </div>
            <div className="tactic-board-pitch" id="my-node">
              {/* <img src={pitch} alt="pitch" className="pitch-image" /> */}

              <img
                src={pitch}
                alt="pitch"
                id="pitch"
                className="pitch-image"
                draggable="false"
              />
              {/* {[...Array(6)].map((item, index) => {
                return (
                  <Draggable {...dragHandlers} id={`goal-${index}`}>
                    <div
                      className="goal-icon"
                      style={{
                        position: "absolute",
                        left: 10,
                        top: 115,
                      }}
                    ></div>
                  </Draggable>
                );
              })} */}
              {this.state.ballIcon.map((item, index) => {
                return (
                  <Draggable
                    {...dragHandlers}
                    id={`ball-icon-${index}`}
                    onMouseDown={() => this.handleOnMouseDown()}
                    onStop={() => this.handleOnDragStop()}
                  >
                    <div
                      className="ball-icon"
                      style={{
                        position: "absolute",
                        bottom: 400,
                        left: -620 + 35 * index,
                      }}
                      id={`ball-icon-${index}`}
                    >
                      {/* <img src={shirt_red} alt="shirt" /> */}
                    </div>
                  </Draggable>
                );
              })}

              {this.state.inflatableIcon.map((item, index) => {
                return (
                  <Draggable
                    {...dragHandlers}
                    onMouseDown={() => this.handleOnMouseDown()}
                    onStop={() => this.handleOnDragStop()}
                    id={`inflatable-icon-${index}`}
                  >
                    <div
                      className="inflatable-icon"
                      style={{
                        position: "absolute",
                        bottom: 450,
                        left: -620 + 35 * index,
                      }}
                      id={`inflatable-icon-${index}`}
                    >
                      {/* <img src={shirt_red} alt="shirt" /> */}
                    </div>
                  </Draggable>
                );
              })}
              {this.state.smallCones.map((item, index) => {
                return (
                  <Draggable
                    {...dragHandlers}
                    onMouseDown={() => this.handleOnMouseDown()}
                    onStop={() => this.handleOnDragStop()}
                    id={`small-coneTest-${index}`}
                  >
                    <div
                      className="small-cone-icon"
                      style={{
                        position: "absolute",
                        bottom: 500,
                        left: -620 + 35 * index,
                      }}
                      id={`small-cones-${index}`}
                    >
                      {/* <img src={shirt_red} alt="shirt" /> */}
                    </div>
                  </Draggable>
                );
              })}
              {this.state.cones.map((item, index) => {
                return (
                  <Draggable
                    {...dragHandlers}
                    onMouseDown={() => this.handleOnMouseDown()}
                    onStop={() => this.handleOnDragStop()}
                    id={`coneTest-${index}`}
                  >
                    <div
                      className="cone-icon"
                      style={{
                        position: "absolute",
                        bottom: 554,
                        left: -620 + 35 * index,
                      }}
                      id={`cones-${index}`}
                    >
                      {/* <img src={shirt_red} alt="shirt" /> */}
                    </div>
                  </Draggable>
                );
              })}
              {this.state.players.map((item, index) => {
                return (
                  <Draggable
                    {...dragHandlers}
                    onMouseDown={() => this.handleOnMouseDown()}
                    onStop={() => this.handleOnDragStop()}
                    id={`player-${index}`}
                  >
                    <div
                      className={
                        item.team === 0
                          ? "player-blue"
                          : item.team === 1
                            ? "player-yellow"
                            : "player-red"
                      }
                      style={{
                        position: "absolute",
                        bottom: 20,
                        left: 60 + 35 * index,
                      }}
                      id={`player-${index}`}
                    >
                      {/* <img src={shirt_red} alt="shirt" /> */}
                    </div>
                  </Draggable>
                );
              })}
            </div>
          </div>
          <div className="right-side-container">
            <div className="tactic-board-details">
              <div
                className="tactic-board-column"
                style={{ paddingRight: 25, borderRight: "solid 1px #f2f2f2" }}
              >
                <div className="tactic-board-form-set">
                  <div className="tactic-board-input-label">
                    Nr. total de jucatori
                  </div>
                  <div className="tactic-board-input-group">
                    <div className="tactic-board-input-button">
                      <Icon
                        name="minus"
                        className="tactic-board-button-icon"
                        id="total-players-minus"
                        size="small"
                        onClick={() => {
                          let newPlayers = this.state.players.slice(0, -1);
                          this.setState({
                            players: newPlayers,
                            totalPlayers: newPlayers.length,
                          });
                        }}
                      />
                    </div>
                    <input
                      className="tactic-board-input"
                      placeholder="Numar de jucatori"
                      value={this.state.players.length}
                      type="number"
                      min="0"
                      disabled
                    />
                    <div className="tactic-board-input-button">
                      <Icon
                        name="plus"
                        id="total-players-plus"
                        className="tactic-board-button-icon"
                        size="small"
                        onClick={() => {
                          const newItem = {
                            id: this.state.players.length,
                            team: 0,
                          };
                          let newPlayers = this.state.players.concat(newItem);
                          this.setState({
                            players: newPlayers,
                            totalPlayers: newPlayers.length,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="tactic-board-form-set">
                  <div className="tactic-board-input-label">
                    Nr. de grupe/echipe
                  </div>
                  <div className="tactic-board-input-group">
                    <div className="tactic-board-input-button">
                      <Icon
                        name="minus"
                        className="tactic-board-button-icon"
                        size="small"
                        onClick={() =>
                          this.setState({
                            teams: this.state.teams.slice(0, -1),
                          })
                        }
                      />
                    </div>
                    <input
                      className="tactic-board-input"
                      placeholder="Numar de echipe"
                      value={this.state.teams.length}
                      type="number"
                      disabled
                    />
                    <div className="tactic-board-input-button">
                      <Icon
                        name="plus"
                        className="tactic-board-button-icon"
                        size="small"
                        onClick={() => {
                          const newItem = {
                            id: this.state.teams.length,
                            players: 0,
                          };
                          if (this.state.teams.length < 4) {
                            this.setState({
                              teams: this.state.teams.concat(newItem),
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tactic-board-column"
                style={{
                  display: "flex",
                  flexFlow: "column wrap",
                  height: 225,
                }}
              >
                {" "}
                {this.state.teams.map((item) => {
                  return (
                    <div className="tactic-board-form-set">
                      <div className="tactic-board-input-label">
                        {`Nr. de jucatori echipa ${item.id + 1}`}
                      </div>
                      <div className="tactic-board-input-group">
                        <div className="tactic-board-input-button">
                          <Icon
                            name="minus"
                            className="tactic-board-button-icon"
                            size="small"
                            onClick={(e) =>
                              this.handleChangeTeamPlayers(
                                e,
                                item.id,
                                "decrease"
                              )
                            }
                          />
                        </div>
                        <input
                          className="tactic-board-input"
                          value={item.players}
                          type="number"
                          disabled
                        />
                        <div className="tactic-board-input-button">
                          <Icon
                            name="plus"
                            id="players-number"
                            className="tactic-board-button-icon"
                            size="small"
                            onClick={(e) => {
                              const nrUsedPlayers = this.getTotalPlayersDistributed();
                              if (nrUsedPlayers < this.state.totalPlayers) {
                                this.handleChangeTeamPlayers(
                                  e,
                                  item.id,
                                  "increase"
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <TextArea
              className="tactic-board-description"
              placeholder="Detalii/descriere"
            />
            <Button
              className="tactic-board-final-button"
              onClick={this.saveBoard}
            >
              Salveaza detalii sectiune
              <Icon name="add" className="tactic-board-button-icon" />
            </Button>
            {/* <div className="tactic-board-input-label">
              Roteste elementul selectat
            </div>
            <div className="slidecontainer">
              <input
                type="range"
                min="0"
                max="360"
                value={this.state.sliderValue}
                className="slider"
                id="myRange"
                onChange={this.handleChangeSlider}
              />
            </div> */}
          </div>
        </div>

        {/* {this.state.arrows.map((item) => {
          return (
            <Xarrow
              start={item.start}
              end={item.end}
              path={"straight"}
              dashness={item.type === "running" ? true : false}
            />
          );
          // })}
        })} */}
      </div>
    );
  }
}

export default TacticBoard;
