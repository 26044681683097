import React from "react";
import "./SocialMedia.scss";
import { connect } from "react-redux";
import axios from "axios";
import { Button, Icon } from "semantic-ui-react";
import { store } from "react-notifications-component";
import { baseUrl } from "../../../utils/utilFunctions";

class SocialMedia extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            youtube_connect: {
                youtube_URL: "",
            },
            fb_insta_connect: {
                fb: {
                    page_name: "",
                    target_id: ""
                },
                insta: {
                    page_name: "",
                    target_id: ""
                }
            },
            checkedConnection: false,
            uncheckedConnection: false,
            refreshConnection: false
        };
    }

    componentDidMount = () => {
        this.getYoutubeInfo();
        this.getFbInstaInfo();
    };

    getYoutubeInfo = () => {
        axios
            .get(`/site/connect_youtube?id_club=${this.props.currentUser.id_club}`)
            .then((res) => {
                this.setState({
                    youtube_connect: {
                        youtube_URL: res.data.youtube_page_url,
                    }
                });
            })
            .catch((err) => console.log(err));
    };

    getFbInstaInfo = () => {
        axios
            .get(`/site/connect_fb_insta?id_club=${this.props.currentUser.id_club}`)
            .then((res) => {
                if (res.data.length > 0) {
                    let data = {
                        fb: {
                            page_name: "",
                            target_id: ""
                        },
                        insta: {
                            page_name: "",
                            target_id: ""
                        }
                    };
                    res.data.map(item => {
                        if (item.network_type == "facebook") {
                            data.fb = {
                                page_name: item.page_name,
                                target_id: item.target_id
                            }
                        } else {
                            data.insta = {
                                page_name: item.page_name,
                                target_id: item.target_id
                            }
                        }
                    })
                    this.setState({
                        fb_insta_connect: data
                    })
                }
            })
            .catch((err) => console.log(err));
    }

    editValue = (event) => {
        event.persist();
        this.setState(() => ({
            checkedConnection: false,
            uncheckedConnection: false,
            refreshConnection: false,
            youtube_connect: {
                ...this.state.youtube_connect,
                [event.target.name]: event.target.value,
            },
        }));
    };

    saveChanges = () => {
        const payload = {
            id_club: this.props.currentUser.id_club,
            youtube_page_url: this.state.youtube_connect.youtube_URL,
        };
        axios
            .post("/site/connect_youtube", payload)
            .then(() => {
                this.setState({
                    youtube_connect: {
                        youtube_URL: "",
                    }
                })
                this.getYoutubeInfo();
                store.addNotification({
                    title: this.props.languagePack["admin_contact_notif_title"],
                    message: this.props.languagePack["admin_contact_notif_text_success"],
                    type: "success",
                    insert: "bottom",
                    container: "bottom-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                    },
                });
            })
            .catch((err) => console.log(err));
    };

    checkConnection = () => {
        this.setState({
            refreshConnection: true
        })
        const payload = {
            youtube_page_url: this.state.youtube_connect.youtube_URL
        }
        axios
            .post("/site/check_connection_youtube", payload)
            .then((res) => {
                this.setState({
                    checkedConnection: res.data > 0,
                    uncheckedConnection: res.data == 0,
                    refreshConnection: false
                })
                // this.setState({
                //     youtube_connect: {
                //         youtube_URL: "",
                //     }
                // })
                // this.getYoutubeInfo();
                // store.addNotification({
                //     title: this.props.languagePack["admin_contact_notif_title"],
                //     message: this.props.languagePack["admin_contact_notif_text_success"],
                //     type: "success",
                //     insert: "bottom",
                //     container: "bottom-right",
                //     animationIn: ["animate__animated", "animate__fadeIn"],
                //     animationOut: ["animate__animated", "animate__fadeOut"],
                //     dismiss: {
                //         duration: 2000,
                //     },
                // });
            })
            .catch((err) => console.log(err));
    }

    connectFbInsta = () => {
        window.open(
            baseUrl(`fans/login_url?id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}`),
            "_blank"
        )
    }

    render() {
        const { languagePack } = this.props;
        return (
            <div className="card card-custom">
                <div className="site-social-media-container">
                    <div className="site-social-media-title">
                        {/* {languagePack["admin_contact_title"]} */}
                        {"Social media connect"}
                    </div>
                    <div className="site-social-media-section">
                        <div className="site-social-media-section-title">
                            {"Connect Youtube"}
                        </div>
                        <div className="site-social-media-row">
                            <div className="input-group" style={{ display: "inline-grid" }}>
                                <div className="input-label">
                                    {/* {languagePack["admin_contact_phone"]} */}
                                    {"Youtube channel URL"}
                                </div>
                                <div className="input-check">
                                    <input
                                        className="social-media-input"
                                        value={this.state.youtube_connect.youtube_URL}
                                        name="youtube_URL"
                                        onChange={this.editValue}
                                        placeholder="https://www.youtube.com/channel/channelID"
                                    />
                                    {this.state.checkedConnection && <Icon name="check" color="green" style={{ marginTop: "15px", paddingTop: "7px" }} />}
                                    {this.state.uncheckedConnection && <Icon name="close" color="red" style={{ marginTop: "15px", paddingTop: "7px" }} />}
                                    {this.state.refreshConnection && <Icon name="refresh" color="grey" style={{ marginTop: "15px", paddingTop: "7px" }} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-social-media-button-row">
                        <Button
                            className="site-social-media-details-button button-save"
                            onClick={this.saveChanges}
                        >
                            {/* {languagePack["admin_contact_save_changes"]} */}
                            {"Save"}
                            <Icon name="save" className="site-details-icon" />
                        </Button>
                        <Button
                            className="site-social-media-details-button"
                            // color="dark-background"
                            // disabled={this.state.youtube_connect.youtube_URL == ""}
                            onClick={this.checkConnection}
                        >
                            {/* {languagePack["admin_contact_save_changes"]} */}
                            {"Check connection"}
                            <Icon name="arrow right" className="site-details-icon" />
                        </Button>
                    </div>
                    <div className="site-social-media-section" style={{ marginTop: "50px" }}>
                        <div className="site-social-media-section-title">
                            {"Connect Facebook"}
                        </div>
                        {this.state.fb_insta_connect.fb.page_name !== "" ?
                            <div className="site-social-media-row">
                                <div className="input-group" style={{ display: "inline-grid" }}>
                                    <div className="input-label">
                                        {/* {languagePack["admin_contact_phone"]} */}
                                        {"Facebook page name"}
                                    </div>
                                    <div className="input-check">
                                        <div className="social-media-input" style={{ padding: "15px" }}>
                                            {this.state.fb_insta_connect.fb.page_name}
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="site-social-media-row" style={{ height: "auto" }}>
                                <div className="input-group" style={{ display: "inline-grid" }}>
                                    <div className="input-label">
                                        {/* {languagePack["admin_contact_phone"]} */}
                                        {"No connected any Facebook page"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.fb_insta_connect.fb.page_name == "" && <div className="site-social-media-button-row">
                        <Button
                            className="site-social-media-details-button button-save"
                            onClick={this.connectFbInsta}
                        >
                            {/* {languagePack["admin_contact_save_changes"]} */}
                            {"Connect"}
                            <Icon name="plug" className="site-details-icon" />
                        </Button>
                    </div>}
                    {false && <div className="site-social-media-button-row">
                        <Button
                            className="site-social-media-details-button button-save"
                            onClick={this.connectFbInsta}
                        >
                            {/* {languagePack["admin_contact_save_changes"]} */}
                            {"Reconnect"}
                            <Icon name="plug" className="site-details-icon" />
                        </Button>
                    </div>}
                    <div className="site-social-media-section" style={{ marginTop: "50px" }}>
                        <div className="site-social-media-section-title">
                            {"Connect Instagram"}
                        </div>
                        {this.state.fb_insta_connect.insta.page_name !== "" ?
                            <div className="site-social-media-row">
                                <div className="input-group" style={{ display: "inline-grid" }}>
                                    <div className="input-label">
                                        {/* {languagePack["admin_contact_phone"]} */}
                                        {"Instagram page name"}
                                    </div>
                                    <div className="input-check">
                                        <div className="social-media-input" style={{ padding: "15px" }}>
                                            {this.state.fb_insta_connect.insta.page_name}
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="site-social-media-row" style={{ height: "auto" }}>
                                <div className="input-group" style={{ display: "inline-grid" }}>
                                    <div className="input-label">
                                        {/* {languagePack["admin_contact_phone"]} */}
                                        {"No connected any Instagram page"}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {this.state.fb_insta_connect.insta.page_name == "" && <div className="site-social-media-button-row">
                        <Button
                            className="site-social-media-details-button button-save"
                            onClick={this.connectFbInsta}
                        >
                            {/* {languagePack["admin_contact_save_changes"]} */}
                            {"Connect"}
                            <Icon name="plug" className="site-details-icon" />
                        </Button>
                    </div>}
                    {false && <div className="site-social-media-button-row">
                        <Button
                            className="site-social-media-details-button button-save"
                            onClick={this.connectFbInsta}
                        >
                            {/* {languagePack["admin_contact_save_changes"]} */}
                            {"Reconnect"}
                            <Icon name="plug" className="site-details-icon" />
                        </Button>
                    </div>}
                </div>
            </div>
        );
    }
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
}))(SocialMedia);