import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import axios from "axios";
import { Dialog, DialogContent } from "@material-ui/core";
import { Modal, Icon, Button } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./Calendar.scss";
import { logout } from "./../../../utils/api";
import AddEvent from "./AddEvent";
import EventPopup from "./EventPopup";
import TFBDropdown from "../../design-system/Dropdown/TFBDropdown";
import { getContrastTextColor } from "../../../utils/utilFunctions";
import { isEmpty } from "lodash";

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teams_dropdown: [],
      addEventDialogOpen: false,
      key: `calendar-${Date.now()}`,
      chosen_team: "0",
      selectedEvent: [],
      selectedEventModal: false,
      events: [],
    };
  }

  dropdownRef = React.createRef();

  componentDidMount = () => {
    this.getEvents();
    this.getTeams();
    this.getBirthdaysMatchesTrainigs();

    this.nodeContent = document.getElementById("kt_aside_toggle");
    if (this.nodeContent) {
      this.nodeContent.addEventListener("click", this.onAside);
      this.nodeContent.addEventListener("touchstart", this.onAside);
    }
  };

  componentWillUnmount = () => {
    if (this.nodeContent) {
      this.nodeContent.removeEventListener("click", this.onAside);
      this.nodeContent.removeEventListener("touchstart", this.onAside);
    }
  };

  onAside = (event) => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300); //transition is like 0.3s
  };
  getEvents = () => {
    axios
      .get(`calendar/get_events_2`, {
        params: {
          id_team: this.state.chosen_team,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            events: this.state.events
              .filter(
                (item) =>
                  item?.extendedProps?.description === "match" ||
                  item?.extendedProps?.description === "birthday" ||
                  item?.extendedProps?.description === "training"
              )
              .concat(res.data),
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getTeams = () => {
    axios
      .get("calendar/get_teams_dropdown", {
        params: {
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            teams_dropdown: Array.isArray(res.data) ? res.data : [],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleChangeDropdownTeam = (e, value) => {
    this.setState(
      {
        [value.name]: value.value,
      },
      this.refreshEvents
    );
  };

  refreshEvents = () => {
    this.setState(
      {
        events: [],
      },
      () => {
        this.getEvents();
        this.getBirthdaysMatchesTrainigs();
      }
    );
  };

  async getBirthdaysMatchesTrainigs() {
    const [birthdays, matches, trainings] = await Promise.all([
      axios.get(
        `calendar/get_birthdays?id_club=${this.props.currentUser.id_club}&id_team=${this.state.chosen_team}`
      ),
      axios.get(
        `calendar/get_matches?id_club=${this.props.currentUser.id_club}&id_team=${this.state.chosen_team}`
      ),
      axios.get(
        `calendar/get_trainings?id_club=${this.props.currentUser.id_club}&id_team=${this.state.chosen_team}`
      ),
    ]);
    this.setState({
      events: this.state.events.concat(
        birthdays.data,
        matches.data,
        trainings.data
      ),
    });
  }

  updateSize = (param) => {};

  renderEventContent(eventInfo) {
    return (
      <>
        <div style={{ overflow: "hidden" }}>
          {eventInfo.event.extendedProps.description === "birthday" ? (
            <div
              style={{
                display: "flex",
                color: getContrastTextColor(
                  isEmpty(eventInfo.backgroundColor)
                    ? "#fff"
                    : eventInfo.backgroundColor
                ),
              }}
            >
              <Icon
                name="birthday cake"
                style={{
                  color: getContrastTextColor(
                    isEmpty(eventInfo.backgroundColor)
                      ? "#fff"
                      : eventInfo.backgroundColor
                  ),
                }}
              />
              <b>Birthday</b>
            </div>
          ) : (
            ""
          )}
          {eventInfo.event.extendedProps.description === "match" ? (
            <div
              style={{
                display: "flex",
                color: getContrastTextColor(
                  isEmpty(eventInfo.backgroundColor)
                    ? "#fff"
                    : eventInfo.backgroundColor
                ),
              }}
            >
              <Icon
                name="futbol"
                style={{
                  color: getContrastTextColor(
                    isEmpty(eventInfo.backgroundColor)
                      ? "#fff"
                      : eventInfo.backgroundColor
                  ),
                }}
              />
              <b>
                {"Match - " +
                  moment(eventInfo.event.extendedProps.date_start).format(
                    "HH:mm"
                  )}
              </b>
            </div>
          ) : (
            ""
          )}
          {eventInfo.event.extendedProps.description === "training" ? (
            <div
              style={{
                display: "flex",
                color: getContrastTextColor(
                  isEmpty(eventInfo.backgroundColor)
                    ? "#fff"
                    : eventInfo.backgroundColor
                ),
              }}
            >
              <Icon
                name="soccer"
                style={{
                  color: getContrastTextColor(
                    isEmpty(eventInfo.backgroundColor)
                      ? "#fff"
                      : eventInfo.backgroundColor
                  ),
                }}
              />
              <b>
                {"Training - " +
                  moment(eventInfo.event.extendedProps.date_start).format(
                    "HH:mm"
                  )}
              </b>
            </div>
          ) : (
            ""
          )}
          {!["birthday", "match", "training"].includes(
            eventInfo.event.extendedProps.description
          ) ? (
            <div
              style={{
                display: "flex",
                color: getContrastTextColor(
                  isEmpty(eventInfo.backgroundColor)
                    ? "#fff"
                    : eventInfo.backgroundColor
                ),
              }}
            >
              <Icon
                name="calendar check outline"
                style={{
                  color: getContrastTextColor(
                    isEmpty(eventInfo.backgroundColor)
                      ? "#fff"
                      : eventInfo.backgroundColor
                  ),
                }}
              />
              <b>{eventInfo.event.extendedProps.type}</b>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              color: getContrastTextColor(
                isEmpty(eventInfo.backgroundColor)
                  ? "#fff"
                  : eventInfo.backgroundColor
              ),
            }}
          >
            {eventInfo.event.title}
          </div>
        </div>
      </>
    );
  }

  eventClick = (eventClickInfo) => {
    const event = {
      id: eventClickInfo.event.id,
      name: eventClickInfo.event.title,
      date_start: moment(eventClickInfo.event.start).format("DD.MM.YYYY"),
      date_end: moment(eventClickInfo.event.end).format("DD.MM.YYYY"),
      event_added_description:
        eventClickInfo.event.extendedProps.event_added_description,
      location: eventClickInfo.event.extendedProps.location,
      description: eventClickInfo.event.extendedProps.description,
      hour_start: moment(eventClickInfo.event.extendedProps.date_start).format(
        "HH:mm"
      ),
      hour_end: moment(eventClickInfo.event.extendedProps.date_end).format(
        "HH:mm"
      ),
      full_date_start: eventClickInfo.event.extendedProps.date_start,
      full_date_end: eventClickInfo.event.extendedProps.date_end,
    };

    this.setState({ selectedEventModal: true, selectedEvent: event });
  };

  handleItemClick = (_e, x) => {
    this.dropdownRef.current.handleItemClick(_e, x);
  };

  render() {
    const { languagePack, language } = this.props;

    const selected_team = this.state.teams_dropdown.find(
      (item) => item.value === this.state.chosen_team
    );
    return (
      <>
        <div
          className="card card-custom"
          style={{ marginTop: 0, flexGrow: 1, padding: "50px" }}
        >
          <div className="calendar-header">
            <div className="calendar-header-title">
              {languagePack["calendar_title"]}
            </div>
            <div className="calendar-header-buttons">
              <TFBDropdown
                placeholder={languagePack["select_team"]}
                selection
                options={this.state.teams_dropdown.map((item) => ({
                  ...item,
                  text: languagePack?.dropdown?.[item.text] || item.text,
                }))}
                value={this.state.chosen_team}
                onChange={this.handleChangeDropdownTeam}
                name="chosen_team"
                className="calendar-dropdown"
              />
              <Button
                onClick={() => this.setState({ addEventDialogOpen: true })}
                className="calendar-button"
              >
                {languagePack.add_event}
                <Icon name="add" className="plus-icon-button" />
              </Button>
            </div>
          </div>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            firstDay={1}
            key={this.state.key}
            locale={language}
            // getApi={this.updateSize}
            eventContent={this.renderEventContent}
            headerToolbar={{
              start: "",
              center: "prev,title,next",
              //end: "dayGridMonth,timeGridWeek,timeGridDay",
              end: "",
            }}
            buttonText={{
              today: languagePack.today,
              month: languagePack.month,
              day: languagePack.day,
              week: languagePack.week,
            }}
            events={this.state.events}
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
            }}
            eventClick={this.eventClick}
          />

          <Modal
            open={this.state.selectedEventModal}
            size="tiny"
            onClose={() => this.setState({ selectedEventModal: false })}
            style={{ position: "initial", height: "auto" }}
          >
            <EventPopup
              event={this.state.selectedEvent}
              refreshEvents={() => {
                this.refreshEvents();
                this.setState({ selectedEventModal: false });
              }}
              closePopup={() => this.setState({ selectedEventModal: false })}
            />
          </Modal>
        </div>
        <Dialog
          open={this.state.addEventDialogOpen}
          // onClose={() => this.setState({ addEventDialogOpen: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
        >
          <div
            className="bonus-options-list-popup-header"
            style={{ paddingLeft: 50 }}
          >
            <div className="bonus-options-list-title">
              {languagePack.add_new_event}
            </div>
            <div className="bonus-options-list-close-icon">
              {" "}
              <Icon
                name="close"
                onClick={() => this.setState({ addEventDialogOpen: false })}
                size="large"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <DialogContent>
            <AddEvent
              refreshEvents={() => {
                this.refreshEvents();
                this.setState({ addEventDialogOpen: false });
              }}
              closePopup={() => this.setState({ addEventDialogOpen: false })}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    language: layoutService.lang,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Calendar);
