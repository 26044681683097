import { connect } from "react-redux";
import React, { useContext } from "react";

import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import styles from "./AddManualCompetition.module.scss";
import { AddCompetitionContext } from "./AddCompetition";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import TFBDropdown from "../../../../components/design-system/Dropdown/TFBDropdown";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import { CompetitionsContext } from "./Competitions";

export const ageCategoryOptions = [
  {
    value: 0,
    label: "Seniori",
  },
  {
    value: 1,
    label: "U6",
  },
  {
    value: 2,
    label: "U7",
  },
  {
    value: 3,
    label: "U8",
  },
  {
    value: 4,
    label: "U9",
  },
  {
    value: 5,
    label: "U10",
  },
  {
    value: 6,
    label: "U11",
  },
  {
    value: 7,
    label: "U12",
  },
  {
    value: 8,
    label: "U13",
  },
  {
    value: 9,
    label: "U14",
  },
  {
    value: 10,
    label: "U15",
  },
  {
    value: 11,
    label: "U16",
  },
  {
    value: 12,
    label: "U17",
  },
  {
    value: 13,
    label: "U18",
  },
  {
    value: 14,
    label: "U19",
  },
  {
    value: 15,
    label: "U20",
  },
  {
    value: 16,
    label: "U21",
  },
  {
    value: 17,
    label: "U22",
  },
  {
    value: 18,
    label: "U23",
  },
];

function AddManualCompetition({ languagePack, currentUser, token }) {
  const comp_types = [
    {
      key: 1,
      value: "championship",
      text: languagePack.competition_type.elements["championship"],
    },
    {
      key: 2,
      value: "knockout",
      text: languagePack.competition_type.elements["knockout"],
    },
    {
      key: 3,
      value: "group-stage",
      text: languagePack.competition_type.elements["group_stage"],
    },
  ];

  const context = useContext(AddCompetitionContext);
  const competitionsContext = useContext(CompetitionsContext);

  const handleChangeDropdown = (e, value) => {
    context.onSetState({ [value.name]: value.value });
  };
  const handleChangeSelect = (selected, value) => {
    context.onSetState({ [value.name]: selected });
  };
  return (
    <div className={styles.wrapper}>
      <TFBInput
        fullWidth
        type="text"
        label={languagePack.name}
        placeholder={languagePack.type_competition_name}
        value={context.manualCompetitionName}
        name="manualCompetitionName"
        onChange={(e) =>
          context.onSetState({ manualCompetitionName: e.target.value })
        }
      />
      {competitionsContext.addAgeGroup || (
        <TFBCheckbox
          label={languagePack?.["competition_has_more_cat"]}
          checked={context.manualCompetitionHasMultipleAgeGroups}
          mode="light"
          onChange={(e) =>
            context.onSetState({
              manualCompetitionHasMultipleAgeGroups: !context.manualCompetitionHasMultipleAgeGroups,
            })
          }
        />
      )}
      {context.manualCompetitionHasMultipleAgeGroups || (
        <TFBInput
          type="number"
          fullWidth
          label={languagePack["teams_number"]}
          placeholder={languagePack.type_teams_number}
          value={context.manualCompetitionNoTeams}
          onChange={(e) =>
            context.onSetState({ manualCompetitionNoTeams: e.target.value })
          }
        />
      )}

      <TFBDropdown
        color="lightGray"
        fluid
        selection
        label={languagePack.competition_type.name}
        placeholder={languagePack.select_competition_type}
        name="manualCompetitionType"
        onChange={handleChangeDropdown}
        value={context.manualCompetitionType}
        options={comp_types}
        isClearable
      />

      {context.manualCompetitionType === "group-stage" && (
        <TFBInput
          type="number"
          fullWidth
          label={languagePack["number_of_groups"]}
          placeholder={languagePack.type_number_of_groups}
          value={context.manualCompetitionNoGroups}
          onChange={(e) =>
            context.onSetState({
              manualCompetitionNoGroups: e.target.value,
            })
          }
        />
      )}
      {context.manualCompetitionHasMultipleAgeGroups || (
        <TFBInput
          type="number"
          fullWidth
          label={languagePack.minutes_per_time}
          placeholder={languagePack.type_minutes_per_time}
          value={context.manualCompetitionMinutesPerHalf}
          onChange={(e) =>
            context.onSetState({
              manualCompetitionMinutesPerHalf: e.target.value,
            })
          }
        />
      )}
      <TFBDatePicker
        fullWidth
        label={languagePack.starting_date}
        value={context.manualCompetitionDateStart}
        onChange={(value) =>
          context.onSetState({ manualCompetitionDateStart: value })
        }
        placeholder={languagePack.select_end_date}
        ampm={false}
        format="dd MM yyyy"
        name="newEventStart"
        className="add-event-date-input"
      />
      <TFBDatePicker
        fullWidth
        label={languagePack.ending_date}
        value={context.manualCompetitionDateEnd}
        onChange={(value) =>
          context.onSetState({ manualCompetitionDateEnd: value })
        }
        ampm={false}
        placeholder={languagePack.select_start_date}
        format="dd MM yyyy"
        name="newEventStart"
      />
      {context.manualCompetitionHasMultipleAgeGroups || (
        <TFBSelect
          label={languagePack.age_category}
          placeholder={languagePack.select_age_category}
          name="manualCompetitionAgeGroup"
          onChange={handleChangeSelect}
          value={context.manualCompetitionAgeGroup}
          options={ageCategoryOptions}
          isClearable
        />
      )}
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddManualCompetition);
