import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './column';
import axios from 'axios';


class DragParameters extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: {},
            columnOrder: ['selected-parameters', 'all-parameters'],
            params: {},
        }
    }

    onDragEnd = result => {
        const { destination, source, draggableId } = result

        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const start = this.state.columns[source.droppableId]
        const finish = this.state.columns[destination.droppableId]

        if (start === finish) {
            const newTaskIds = Array.from(start.taskIds)
            newTaskIds.splice(source.index, 1)
            newTaskIds.splice(destination.index, 0, draggableId)

            const newColumn = {
                ...start,
                taskIds: newTaskIds
            }

            const newState = {
                ...this.state,
                columns: {
                    ...this.state.columns,
                    [newColumn.id]: newColumn
                }
            }

            this.setState(newState)
            return
        }

        // Moving from one list to another
        const startTaskIds = Array.from(start.taskIds)
        startTaskIds.splice(source.index, 1)
        const newStart = {
            ...start,
            taskIds: startTaskIds
        }

        const finishTaskIds = Array.from(finish.taskIds)
        finishTaskIds.splice(destination.index, 0, draggableId)
        const newFinish = {
            ...finish,
            taskIds: finishTaskIds
        }

        const newState = {
            ...this.state,
            columns: {
                ...this.state.columns,
                [newStart.id]: newStart,
                [newFinish.id]: newFinish
            }
        }
        this.setState(newState)
        this.props.addParam(result.draggableId);
    }

    componentDidMount = () => {
        axios.get('report/get_parameters')
            .then((res) => this.setState({ params: res.data.elements, columns: res.data.columns }))
    }
    render() {
        return (
            <div>
                {Object.keys(this.state.params).length > 0 ?
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <div style={{ display: 'flex', marginLeft: 20 }}>
                            {this.state.columnOrder.map(columnId => {
                                const column = this.state.columns[columnId]
                                const params = column.taskIds.map(
                                    taskId => this.state.params[taskId]
                                )

                                return (
                                    <Column key={column.id} column={column} params={params} />
                                )
                            })}
                        </div>
                    </DragDropContext> : ""}
            </div>
        )
    }
}

export default DragParameters;