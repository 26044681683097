import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MatchDetails.scss";
import goal_icon from "../../../../assets/icons/goal.png";
import substitute_in_icon from "../../../../assets/icons/match-events-types/substitute_in_arrow.png";
import substitute_out_icon from "../../../../assets/icons/match-events-types/substitute_out_arrow.png";
import { connect } from 'react-redux';

function MatchEvents(props) {
  const [events, setEvents] = useState([]);
  const [teamHome, setTeamHome] = useState("");
  const [teamAway, setTeamAway] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const { languagePack } = props;

  useEffect(() => {
    axios
      .get(`rapidapi/get_match_events?id_match=${props.id_match}`)
      .then((res) => {
        setEvents(res.data.events);
        setTeamHome(res.data.team_home_id);
        setTeamAway(res.data.team_away_id);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card card-custom" style={{ marginTop: 20 }}>
      {isLoaded &&
        <div className="match-events-container-instat">
          <div className="match-events-title">{languagePack['match_events']}</div>
          <div className="events-list">
            {events.map((item, index) => {
              return (
                <>
                  {item.id_team === teamHome ? (
                    <div
                      className="event-row"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        className="minute"
                        style={{ borderRight: "solid 1px #e6e6e6" }}
                      >
                        {item.event_minute}'
                      </div>
                      <div
                        className="event-image"
                        style={{ marginLeft: 20, marginRight: 10 }}
                      >
                        {item.event_type === "Goal" && (
                          <div className="goal-icon-container"><img src={goal_icon} alt="goal" /></div>

                        )}
                        {item.event_type_detail === "Yellow Card" && (
                          <div className="yellow-card-icon" />
                        )}
                        {item.event_type_detail === "Red Card" && (
                          <div className="red-card-icon" />
                        )}
                        {item.event_type === "subst" && (
                          <img src={substitute_out_icon} alt="sub_out" />
                        )}
                      </div>
                      <div className="player-name">
                        {item.player_name}
                      </div>
                      {item.event_type === "subst" && (
                        <>
                          <div
                            className="event-image"
                            style={{ marginLeft: 20, marginRight: 10 }}
                          >
                            <img src={substitute_in_icon} alt="sub_in" />
                          </div>
                          <div className="player-assist">{item.player_assist_name}</div>
                        </>
                      )}
                      {item.player_assist_name && item.event_type !== "subst" && (
                        <div className="player-assist">
                          {"(" + item.player_assist_name + ")"}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className="event-row away-team"
                      style={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      {item.player_assist_name && item.event_type !== "subst" && (
                        <div className="player-assist">
                          {"(" + item.player_assist_name + ")"}
                        </div>
                      )}
                      {item.event_type === "subst" && (
                        <>
                          <div className="player-assist">{item.player_assist_name}</div>
                          <div
                            className="event-image"
                            style={{ marginLeft: 10, marginRight: 20 }}
                          >
                            <img src={substitute_in_icon} alt="sub_in" />
                          </div>
                        </>
                      )}
                      <div className="player-name">
                        {item.player_name}
                      </div>
                      <div
                        className="event-image"
                        style={{ marginLeft: 10, marginRight: 20 }}
                      >
                        {item.event_type === "Goal" && (
                          <div className="goal-icon-container"><img src={goal_icon} alt="goal" /></div>
                        )}
                        {item.event_type_detail === "Yellow Card" && (
                          <div className="yellow-card-icon" />
                        )}
                        {item.event_type_detail === "Red Card" && (
                          <div className="red-card-icon" />
                        )}
                        {item.event_type === "subst" && (
                          <img src={substitute_out_icon} alt="sub_out" />
                        )}
                      </div>
                      <div
                        className="minute"
                        style={{
                          borderLeft: "solid 1px #e6e6e6",
                          textAlign: "end",
                        }}
                      >
                        {item.event_minute}'
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      }
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(MatchEvents);