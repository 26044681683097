import React, { createContext, useEffect, useState } from "react";
import MainStepGroup from "./MainStepGroup";
import StepGroup from "./StepGroup";
import TFBDialog from "../../../../../app/components/design-system/Dialog/TFBDialog";

import { connect, useDispatch } from "react-redux";
import { actions } from "../../../../../redux/authRedux";
import useAxiosGet from "../../../../../app/components/customHooks/useAxiosGet";

export const QuickSetupContext = createContext();

const QuickSetup = ({ clubQuickSetup, languagePack, currentUser, token }) => {
  const [mainStep, setMainStep] = useState(0);
  const dispatch = useDispatch();

  // GET TEAMS DATA ===============================
  // ========================================

  const {
    data: teamsData,
    loading: teamsLoading,
    refetch: refetchTeams,
  } = useAxiosGet(
    `club/get_teams_2?id_club=${currentUser.id_club}&token=${token}`
  );

  // HANDLERS ===============================
  // ========================================

  const handleClosePopup = () => {
    dispatch(
      actions.updateQuickSetup({ ...clubQuickSetup, isSetupOpen: false })
    );
  };

  // RENDER COMPONENTS ===============================
  // ========================================

  return (
    <TFBDialog
      open={clubQuickSetup?.isSetupOpen ?? false}
      fullWidth={true}
      maxWidth={"md"}
      title={
        clubQuickSetup?.setup_type == "quick_action"
          ? languagePack?.quickSetup?.quick_action
          : languagePack?.quickSetup?.information_about_your_club
      }
      closePopup={handleClosePopup}
    >
      <QuickSetupContext.Provider
        value={{ mainStep, setMainStep, teamsData, teamsLoading }}
      >
        <div className="quick-setup-wrapper">
          <MainStepGroup
            mainSteps={clubQuickSetup?.module}
            languagePack={languagePack}
            isQuickAction={clubQuickSetup?.setup_type == "quick_action"}
          />
          <StepGroup
            currentMainStep={mainStep}
            mainSteps={clubQuickSetup?.module}
            languagePack={languagePack}
            isQuickAction={clubQuickSetup?.setup_type == "quick_action"}
            refetchTeams={refetchTeams}
          />
        </div>
      </QuickSetupContext.Provider>
    </TFBDialog>
  );
};

export default connect(
  ({ layoutService, auth }) => ({
    clubQuickSetup: auth.clubQuickSetup,
    currentUser: auth.user,
    languagePack: layoutService.languagePack,
    token: auth?.user?.token,
  }),
  { updateQuickSetup: actions.updateQuickSetup }
)(QuickSetup);
