import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import styles from "./DocumentToSign.module.scss";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";

import VariablesSection from "./VariablesSection";

const EditDocumentToSign = (props) => {
  const { openPopup, closePopup, refreshList, languagePack, document } = props;

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      document_title: document?.document_title ?? "",
      doc_type: document?.document_type ?? "contract",
      active: document?.need_to_sign ?? 1,
      document_content: document?.document_text_html,
    }
  );

  // useEffect(() => {
  //   updateState({ document_content: document?.document_text_html });
  // }, []);

  const { postData: editRequest, loading: loadingEditRequest } = useAxiosPost(
    "parentsApp/save_document_to_sign"
  );

  const handleResponse = (response) => {
    if (response?.success == 1) {
      closePopup();
      refreshList();
      showNotification(
        "success",
        languagePack.mobile_app,
        languagePack.success_add_players_to_payment_request
      );
    } else {
      showNotification(
        "danger",
        languagePack.mobile_app,
        languagePack.fail_add_players_to_payment_request
      );
    }
  };

  const saveDocument = () => {
    const payload = {
      id_document: document?.id_document,
      document_title: state.document_title,
      document_type: state.doc_type,
      need_to_sign: state.active,
      document_content: state.document_content,
    };

    editRequest(payload, handleResponse);
  };

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => saveDocument(),
    "green",
    "check",
    languagePack.admin_save,
    {},
    true,
    _.size(_.trim(state.document_title)) == 0,
    loadingEditRequest
  );

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      maxWidth="lg"
      title={languagePack["edit_document"]}
      open={openPopup}
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <div className={styles.documentTypeContainer} style={{ marginTop: 0 }}>
        <TFBInput
          label={languagePack.document_title}
          value={state.document_title}
          onChange={(e) => updateState({ document_title: e.target.value })}
          style={{ width: "100%" }}
        />
      </div>
      <div className={styles.documentTypeContainer}>
        <div className={styles.title}>{languagePack.document_type}</div>
        <div className={styles.documentTypeList}>
          <TFBCheckbox
            checked={state.doc_type == "contract"}
            label={languagePack.contract}
            onChange={(e) => updateState({ doc_type: "contract" })}
          />
          <TFBCheckbox
            checked={state.doc_type == "contract_annex"}
            label={languagePack.contract_annex}
            onChange={(e) => updateState({ doc_type: "contract_annex" })}
          />
        </div>
      </div>
      <div className={styles.documentTypeContainer}>
        <div className={styles.title}>{languagePack.document_will_be}</div>
        <div className={styles.documentTypeList}>
          <TFBCheckbox
            checked={state.active == 1}
            label={languagePack.document_active}
            onChange={(e) => updateState({ active: 1 })}
          />
          <TFBCheckbox
            checked={state.active == 0}
            label={languagePack.document_archived}
            onChange={(e) => updateState({ active: 0 })}
          />
        </div>
      </div>
      <div className={styles.documentTypeContainer}>
        <div className={styles.title}>{languagePack.document_content}</div>
        <CKEditor
          editor={ClassicEditor}
          data={state.document_content}
          onChange={(event, editor) => {
            const data = editor.getData();
            updateState({ document_content: data });
          }}
          config={{
            placeholder: languagePack.enter_document_content,
            // Add any additional CKEditor configuration options here
          }}
        />
      </div>
      <VariablesSection languagePack={languagePack} />
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(EditDocumentToSign);
