import React from "react";
import "./AddNews.scss";
import { Form, Button, Icon, TextArea } from "semantic-ui-react";
import axios from "axios";
import { store } from "react-notifications-component";
import delete_icon from "../../../../assets/icons/delete.png";
import edit_icon from "../../../../assets/icons/edit.png";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

const options = [
  { key: "competitions", text: "Competitii", value: "Competitii" },
  { key: "results", text: "Rezultate", value: "Rezultate" },
  { key: "birthdays", text: "Aniversari", value: "Aniversari" },
  { key: "general", text: "General", value: "General" },
  { key: "club_history", text: "Istoria clubului", value: "Istoria clubului" },
];

class AddNews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      tag: "",
      image: "",
      elementToAdd: "",
      elementToAddValue: "",
      content: [],
      editedElementValue: "",
      editedElementIndex: "",
    };
  }

  handleChangeInput = (e, { name, value }) => this.setState({ [name]: value });

  handleChangeDropdown = (e, value) => {
    this.setState({
      [value.name]: value.value,
    });
  };

  refreshInputs = () => {
    this.setState({
      elementToAdd: "",
      elementToAddValue: "",
    });
  };

  addSection = () => {
    const newSection = {
      type: this.state.elementToAdd,
      value: this.state.elementToAddValue,
    };

    this.setState(
      { content: [...this.state.content, newSection] },
      this.refreshInputs
    );
  };

  handleChangeImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file: file,
        elementToAddValue: reader.result,
      });
    };
  };

  handleChangeMainImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        fileSection: file,
        image: reader.result,
      });
    };
  };

  deleteSection = (id) => {
    let deletedElement = this.state.content.splice(id, 1);
    let deletedElementValue = deletedElement.value;
    let filteredArray = this.state.content.filter(
      (item) => item.value !== deletedElementValue
    );
    this.setState({ content: filteredArray });
  };

  saveEditedSection = () => {
    const newValue = this.state.editedElementValue;
    const index = this.state.editedElementIndex;

    const content = [...this.state.content];
    let item = { ...content[index] };
    item.value = newValue;
    content[index] = item;
    this.setState({
      content: content,
      editedElementIndex: "",
      editedElementValue: "",
    });
  };

  submitArticle = () => {
    const data = new FormData();
    data.append("id_club", this.props.currentUser.id_club);
    data.append("image", this.state.image);
    data.append("title", this.state.title);
    data.append("tag", this.state.tag);

    var total_images = 0;
    var total_text_elements = 0;
    var order = 0;
    for (var i = 0; i < this.state.content.length; i++) {
      if (this.state.content[i]["type"] === "Imagine") {
        data.append(`image_${order}`, this.state.content[i]["value"]);
        total_images++;
      } else {
        data.append(
          `text_${total_text_elements}`,
          `${order}_${this.state.content[i]["type"]}_${this.state.content[i]["value"]}`
        );
        total_text_elements++;
      }
      order++;
    }
    data.append("total_images", total_images);
    data.append("total_text_elements", total_text_elements);

    axios
      .post("site/add_news", data)
      .then((res) => {
        if (res.data.success === 1) {
          store.addNotification({
            title: "Administrare stiri",
            message: "Stirea a fost adaugata cu succes!",
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
          this.props.history.push("/site/news");
        } else {
          store.addNotification({
            title: "Administrare stiri",
            message: "Eroare la publicarea stirii. Incercati din nou.",
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="add-news-container">
              <div className="add-news-header">
                <div className="add-news-title">
                  {languagePack["add_news_title"]}
                </div>
              </div>
              <div className="add-news-form-container">
                <Form className="add-news-form">
                  <div className="add-news-row">
                    <input
                      className="title-input"
                      placeholder={languagePack["add_news_article_title"]}
                      value={this.state.title}
                      onChange={(e) => this.setState({ title: e.target.value })}
                    />
                  </div>
                  <div
                    className="add-news-row custom-row"
                    style={{ marginBottom: 50 }}
                  >
                    <div className="image-input-container">
                      <input
                        type="file"
                        className="add-news-image-input"
                        id="customFile"
                        onChange={this.handleChangeMainImage}
                      />
                      <label
                        className="add-file-form-file-label"
                        htmlFor="customFile"
                      >
                        {this.state.image !== ""
                          ? languagePack["upload_another_main_image"]
                          : languagePack["upload_main_image"]}
                      </label>
                      <Icon
                        name="arrow up"
                        className="cusor-pointer grey-button-icon"
                        size="small"
                      />
                    </div>
                    <select
                      value={this.state.tag}
                      onChange={(e) => this.setState({ tag: e.target.value })}
                    >
                      <option value="" disabled selected>
                        Tag
                      </option>
                      {options.map((item) => {
                        return (
                          <option value={item.value} key={item.key}>
                            {item.text}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="sections-title">
                    {languagePack["sections"]}
                  </div>
                  <div className="add-news-sections-list">
                    {this.state.content.map((item, index) => {
                      return (
                        <div
                          className="add-news-section"
                          key={index}
                          style={{
                            backgroundColor: index % 2 == 0 ? "#fafafa" : "",
                          }}
                        >
                          <div className="add-news-section-type">
                            {index + 1}. {item.type}
                          </div>

                          <div className="add-news-section-preview">
                            {item.type === "Paragraf" ||
                            item.type === "Subtitlu" ||
                            item.type === "Text evidentiat"
                              ? item.value
                              : item.value.name}
                          </div>

                          <div className="add-news-section-options">
                            {item.type !== "Imagine" && (
                              <img
                                src={edit_icon}
                                alt="edit"
                                onClick={() =>
                                  this.setState({
                                    editedElementIndex: index,
                                    editedElementValue: item.value,
                                  })
                                }
                              />
                            )}
                            <img
                              src={delete_icon}
                              alt="delete"
                              onClick={() => this.deleteSection(index)}
                              style={{ marginLeft: 20 }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {(this.state.elementToAdd === "Paragraf" ||
                    this.state.elementToAdd === "Subtitlu" ||
                    this.state.elementToAdd === "Text evidentiat") && (
                    <div className="element-to-add-container">
                      <TextArea
                        placeholder={this.state.elementToAdd}
                        className="text-area-add-news"
                        value={this.state.elementToAddValue}
                        onChange={this.handleChangeInput}
                        name="elementToAddValue"
                      />

                      <div className="element-to-add-buttons">
                        <Button
                          className="add-news-button dark-background"
                          onClick={this.addSection}
                          style={{ width: "48%" }}
                        >
                          {languagePack["admin_add"]}
                          <Icon name="add" className="add-news-button-icon" />
                        </Button>
                        <Button
                          className="add-news-button light-background"
                          style={{
                            width: "48%",
                          }}
                          onClick={() =>
                            this.setState({
                              elementToAdd: "",
                              elementToAddValue: "",
                            })
                          }
                        >
                          {languagePack["admin_cancel"]}
                          <Icon
                            name="close"
                            className="add-news-button-icon-dark"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                  {this.state.elementToAdd === "Imagine" && (
                    <div className="add-news-image-container">
                      <div className="add-news-image-preview">
                        {this.state.elementToAddValue !== "" && (
                          <img src={this.state.elementToAddValue} />
                        )}
                      </div>
                      <div className="add-news-image-right-side">
                        <div className="image-input-container  element-to-add-image-input">
                          <input
                            type="file"
                            className="add-news-image-input"
                            id="section-image"
                            onChange={this.handleChangeImage}
                          />
                          <label
                            className="add-file-form-file-label"
                            htmlFor="section-image"
                          >
                            {this.state.elementToAddValue !== ""
                              ? languagePack["upload_other_image"]
                              : languagePack["upload_image"]}
                          </label>
                          <Icon
                            name="arrow up"
                            className="cusor-pointer grey-button-icon"
                            size="small"
                          />
                        </div>

                        <div className="element-to-add-buttons">
                          <Button
                            className="add-news-button dark-background"
                            onClick={this.addSection}
                            style={{ width: "48%" }}
                          >
                            {languagePack["admin_add"]}
                            <Icon name="add" className="add-news-button-icon" />
                          </Button>
                          <Button
                            className="add-news-button light-background"
                            style={{
                              width: "48%",
                              marginLeft: 15,
                            }}
                            onClick={() =>
                              this.setState({
                                elementToAdd: "",
                                elementToAddValue: "",
                              })
                            }
                          >
                            {languagePack["admin_cancel"]}
                            <Icon
                              name="close"
                              className="add-news-button-icon-dark"
                            />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="add-options-buttons">
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({
                          elementToAdd: "Subtitlu",
                          elementToAddValue: "",
                        })
                      }
                      style={{ marginBottom: 15 }}
                    >
                      Subtitlu
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({
                          elementToAdd: "Paragraf",
                          elementToAddValue: "",
                        })
                      }
                      style={{ marginBottom: 15 }}
                    >
                      Paragraf
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({
                          elementToAdd: "Text evidentiat",
                          elementToAddValue: "",
                        })
                      }
                    >
                      Text evidentiat
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                    <Button
                      className="add-news-button dark-background"
                      onClick={() =>
                        this.setState({
                          elementToAdd: "Imagine",
                          elementToAddValue: "",
                        })
                      }
                    >
                      Imagine
                      <Icon name="add" className="add-news-button-icon" />
                    </Button>
                  </div>
                </Form>
              </div>

              <div className="news-article-final-buttons">
                <Button
                  className="add-news-button publish-button"
                  onClick={this.submitArticle}
                  // style={{ width: "65%" }}
                >
                  {languagePack["add_news_publish_button"]}
                  <Icon name="add" className="add-news-button-icon" />
                </Button>
                <Button
                  className="add-news-button dark-background cancel-button"
                  // style={{ width: "32%" }}
                  onClick={() => this.props.history.push("/site/news")}
                >
                  {languagePack["add_news_cancel_button"]}
                  <Icon name="close" className="add-news-button-icon" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom">
            <div className="news-article-preview-container">
              <div className="news-article-preview-header">
                <div className="preview-title">
                  {languagePack["article_preview"]}
                </div>
              </div>
              <div className="news-article-preview-content">
                {this.state.image !== "" && (
                  <div className="preview-main-image-container">
                    <img src={this.state.image} />
                  </div>
                )}
                <div className="preview-article-title">{this.state.title}</div>
                <div className="preview-text-container">
                  {this.state.content.map((item, index) => {
                    return (
                      <>
                        {item.type === "Paragraf" && (
                          <div className="preview-paragraph-container">
                            {item.value}
                          </div>
                        )}
                        {item.type === "Subtitlu" && (
                          <div className="preview-subtitle-container">
                            {item.value}
                          </div>
                        )}
                        {item.type === "Imagine" && (
                          <div className="preview-image-container">
                            <img src={item.value} />
                          </div>
                        )}
                        {item.type === "Text evidentiat" && (
                          <div className="preview-highlighted-container">
                            {item.value}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={this.state.editedElementIndex !== ""}
          // onClose={() => this.setState({ editedElementIndex: "" })}
          maxWidth="xl"
        >
          <DialogContent>
            <div className="edit-section-add-news-container">
              <div className="edit-news-popup-header">
                <div className="edit-news-popup-title">
                  {languagePack["edit_section"]}
                </div>
                <Icon
                  name="close"
                  size="large"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.setState({ editedElementIndex: "" })}
                />
              </div>

              <TextArea
                className="edit-section-text-area"
                value={this.state.editedElementValue}
                onChange={(e) =>
                  this.setState({ editedElementValue: e.target.value })
                }
              />

              <div className="edit-section-popup-buttons">
                <Button
                  className="edit-section-button"
                  style={{ backgroundColor: "#666666" }}
                  onClick={() => this.setState({ editedElementIndex: "" })}
                >
                  {languagePack["admin_cancel"]}{" "}
                  <Icon name="ban" className="edit-section-icon" />
                </Button>
                <Button
                  className="edit-section-button"
                  style={{ backgroundColor: "#00d948" }}
                  onClick={this.saveEditedSection}
                >
                  {languagePack["admin_add"]}{" "}
                  <Icon name="add" className="edit-section-icon" />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddNews);
