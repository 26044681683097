import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { logout } from "./../../../utils/api";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class PlayerTestsResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "bar",
          height: 450,

          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "35%",
          },
        },
        grid: {
          row: {
            colors: ["#f5f5f5", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: function(value) {
              return value.toLocaleString();
            },
          },
        },
        colors: [window.clubCustomization.primary_color, "#666666"],
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toLocaleString();
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.getChartData();
  };

  getChartData = () => {
    const id_p = this.props.id_player;
    const id_c = this.props.id_category;
    const id_t = this.props.id_test;
    axios
      .get(`club/individual_test_comparison`, {
        params: {
          id_player: id_p,
          id_category: id_c,
          id_test: id_t,
          token: this.props.token,
        },
      })
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: Array.isArray(res.data.labels) ? res.data.labels : [],
            },
          },
          series: Array.isArray(res.data.values) ? res.data.values : [],
        }));
        // }
      });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.id_category !== this.props.id_category ||
      prevProps.id_test !== this.props.id_test
    ) {
      this.getChartData();
    }
  }

  render() {
    return (
      <div className="profile-test-chart-container-graph">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(PlayerTestsResult);
