import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import "../Instat.scss";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";

class IndexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      dropdownOption: "0",
      dropdownOptions: [],
      options: {
        chart: {
          type: "bar",
          width: "100%",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: 15,
          colors: ["transparent"],
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "70%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: [window.clubCustomization.primary_color],
        },
        grid: {
          column: {
            colors: ["#fafafa", "#ffffff"],
          },
          show: false,
        },

        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            minWidth: 400,
            style: {
              colors: ["#b2b2b2"],
              cssClass: "index-chart-instat-y-axis",
            },
          },
        },
        tooltip: {
          y: {
            formatter: function(
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return Math.round(value);
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.getDropdownOptions();
    this.fetchData();
  };

  fetchData = () => {
    axios
      .get(
        `instat/instat_index_chart?id_team=${this.props.id_team}&id_parameter=${this.state.dropdownOption}&lang=${this.props.lang}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.indexes,
        }));
      })
      .catch((err) => console.log(err));
  };

  getDropdownOptions = () => {
    axios
      .get(`instat/instat_index_chart_dropdown?lang=${this.props.lang}`)
      .then((res) => this.setState({ dropdownOptions: res.data }))
      .catch((err) => console.log(err));
  };

  handleChange = (e, { value }) => {
    this.setState({ dropdownOption: value }, this.fetchData);
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: 50 }}>
        <div className="team-chart-header">
          <div className="team-chart-title">Comparatie la nivelul echipei</div>
          <Dropdown
            selection
            options={this.state.dropdownOptions}
            value={this.state.dropdownOption}
            onChange={this.handleChange}
            className="team-chart-instat-dropdown"
          />
        </div>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
        />
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
}))(IndexChart);
