import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

const EditCategory = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, data } = props;

    const { id_club } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            parent_name: data?.label,
            children: _.map([...data?.children], elem => ({ ...elem, child_name: elem.label })),
            children_to_delete: [],
        }
    );

    const { postData: saveRequest, loading: loadingSaveRequest } = useAxiosPost("report/update_evaluation_category");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.players_evaluations,
                languagePack.success_update_category
            );
        } else {
            showNotification(
                "danger",
                languagePack.players_evaluations,
                languagePack.fail_update_category
            );
        }
    };

    const updateCategory = () => {
        const payload = {
            id_category: data?.value,
            category_data: { category_name: state.parent_name },
            new_params: _.map(_.filter(state.children, e => (e?.value ?? null) == null), v => ({ evaluation_parameter_name: v.child_name, id_club: id_club })),
            params_to_update: _.map(_.filter(state.children, e => (e?.value ?? null) != null), v => ({ id_evaluation_parameter: v.value, evaluation_parameter_name: v.child_name })),
            params_to_delete: _.map(state.children_to_delete, v => ({ id_evaluation_parameter: v.value })),
        };

        saveRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => updateCategory(),
        "green",
        "save",
        languagePack.admin_save,
        {},
        true,
        false,
        loadingSaveRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack.edit_category}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-section">
                <TFBInput
                    label={languagePack.category_name}
                    placeholder={languagePack.category_name}
                    value={state.parent_name}
                    fullWidth={true}
                    onChange={(e) => updateState({ parent_name: e.target.value })}
                />
            </div>
            <div className="pop-up-section">
                <div className="pop-up-section-body">
                    {_.map(state.children, (elem, index) => (
                        <div
                            className="pop-up-row df-g20 align-items-end"
                            key={"venue-child-" + index}
                        >
                            <TFBInput
                                label={languagePack.parameter_name}
                                placeholder={languagePack.parameter_name}
                                value={state.children[index].child_name}
                                fullWidth={true}
                                onChange={(event) => {
                                    let children = state.children;
                                    children[index].child_name = event.target.value;
                                    updateState({ children: children });
                                }}
                            />
                            {_.size(state.children) > 1 &&
                                <TFBIconButton
                                    name="x-large"
                                    color="red"
                                    onClick={() => {
                                        let children = state.children;
                                        children.splice(index, 1);
                                        let to_delete = [...state.children_to_delete];
                                        if (elem?.value) {
                                            to_delete = [...to_delete, elem];
                                        }
                                        updateState({ children: children, children_to_delete: to_delete });
                                    }}
                                />
                            }
                        </div>
                    ))}
                    <TFBButton
                        color="lightGray"
                        renderIcon={() => <TFBIcon name="add" color="gray" />}
                        disabled={_.some(state.children, ["child_name", ""])}
                        onClick={() => {
                            let children = state.children;
                            children.push({ child_name: "" });
                            updateState({ children: children });
                        }}          >
                        {languagePack.add_new_parameter}
                    </TFBButton>
                </div>
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditCategory);
