import React, { useState, useEffect, useRef } from "react";

import { store } from "react-notifications-component";
import { connect } from "react-redux";
import _ from "lodash";
import axios from "axios";

import styles from "./Match.module.scss";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBPlayerWithAvatar from "../../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import * as htmlToImage from 'html-to-image';

const AddPlayingFormation = (props) => {

    const { languagePack, closePopup, isOpenPopup, idMatch, idTeam, players, existingPlayerPositionAssoc, matchFormation, half, type, refreshMatchDetails, refreshLineupsAndSubs, matchDetails, idManualCompetitionTeam, currentUser } = props;

    const [selectedFormation, setSelectedFormation] = useState(matchFormation);
    const [selectedFormationConfig, setSelectedFormationConfig] = useState([
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
    ]);
    const [selectedPosition, setSelectedPosition] = useState({ x: null, y: null })
    const [formations, setFormations] = useState([]);
    const [formationsView, setFormationsView] = useState({});

    const [playerPositionAssoc, setPlayerPositionAssoc] = useState({});
    const [openAlertChangeFormation, setOpenAlertChangeFormation] = useState(false);
    const [alertFormation, setAlertFormation] = useState(null);

    const formationImgRef = useRef(null);

    useEffect(() => {
        getFormations();
        setUpExistingAssoc();
    }, []);

    useEffect(() => {
    }, [])

    const setUpExistingAssoc = () => {
        let obj = {};
        [...existingPlayerPositionAssoc].forEach(elem => obj[elem.associated_position] = [...players].find(elem2 => elem2.id_player == elem.id_player));
        setPlayerPositionAssoc(obj);
    }

    const getFormations = () => {
        axios
            .get(`matches/playing_formations_2?match_format=${matchDetails?.match_format}`)
            .then((res) => {
                setFormations(res.data.formations);
                setFormationsView(res.data.formations_view);
                if (selectedFormation != null) {
                    setSelectedFormationConfig(res.data.formations_view?.[selectedFormation?.value])
                    setSelectedFormation([...res.data.formations].filter(elem => elem?.value == selectedFormation?.value)[0])
                }
            })
            .catch((err) => console.log(err));
    };

    const Formation = ({ text, index, selected = false, onClick }) => (
        <div
            className={`${styles.formationContainer} ${selected ? styles.selectedFormation : ""}`}
            onClick={onClick}

        >
            <img className={styles.formationImg} src={`../../assets/formations/${index}.png`} />
            <div className={styles.formationTitle}>{text}</div>
        </div>
    );

    const Player = ({ name, id, selected = false, disabledConnect = false, onConnect, onCancel }) => (
        <div
            className={`${styles.playerContainer} ${selected ? styles.selectedFormation : ""}`}
            {...!disabledConnect && { onClick: onConnect }}
        >
            <TFBPlayerWithAvatar
                playerName={name}
                avatarUrl={`${window.baseURL}uploads/players/${id}.jpg`}
                style={{ maxWidth: "calc(100% - 55px)" }}
            />
            {selected ?
                <TFBIconButton
                    color="darkGray"
                    name="x-small"
                    onClick={(e) => {
                        e.stopPropagation();
                        onCancel();
                    }}
                /> :
                <TFBIconButton
                    color="darkGray"
                    name="connect"
                    onClick={onConnect}
                    disabled={disabledConnect}
                />
            }
        </div>
    );


    const handleFormation = (formation, isChanging = true) => {
        if (!isChanging) {
            openAlert(formation);
        } else {
            setSelectedFormation(formation);
            setSelectedFormationConfig(formationsView[formation?.value]);
            setSelectedPosition({ x: null, y: null });
            setPlayerPositionAssoc({});
            if (openAlertChangeFormation) {
                closeAlert();
            }
        }
    }

    const handlePosition = (x, y) => {
        setSelectedPosition({ x: x, y: y });
    }

    const handlePlayerConnect = (player, position) => {
        //cancel previous assoc if exists
        const index = Object.values(playerPositionAssoc).findIndex(e => e.id_player == player.id_player);
        let previosAssocObj = playerPositionAssoc;
        if (index > -1) {
            const key = Object.keys(playerPositionAssoc)[index];
            const { [key]: { }, ...restAssoc } = playerPositionAssoc;
            previosAssocObj = restAssoc;
        }
        //add new assoc
        setPlayerPositionAssoc({ ...previosAssocObj, [position]: player });
    }

    const handlePlayerCancel = (player) => {
        const index = Object.values(playerPositionAssoc).findIndex(e => e.id_player == player.id_player);
        if (index > -1) {
            const key = Object.keys(playerPositionAssoc)[index];
            const { [key]: { }, ...restAssoc } = playerPositionAssoc;
            setPlayerPositionAssoc(restAssoc);
        }
    }

    const openAlert = (formation) => {
        setOpenAlertChangeFormation(true);
        setAlertFormation(formation);
    }

    const closeAlert = () => {
        setOpenAlertChangeFormation(false);
        setAlertFormation(null);
    }

    const savePlayingFormation = async () => {

        const blob = await htmlToImage.toBlob(formationImgRef.current);

        const form = new FormData();
        form.append("id_club", currentUser.id_club);
        form.append("id_match", idMatch);
        form.append("half", { first: 1, second: 2 }[half]);
        form.append("id_team", idTeam);
        form.append("id_manual_competition_team", idManualCompetitionTeam);
        form.append("match_details_update", JSON.stringify({
            [`${type}_team_playing_formation_${half}_half`]: selectedFormation?.value,
        }));
        form.append("player_formation_update", JSON.stringify(Object.keys(playerPositionAssoc).map(elem => ({
            id_player: playerPositionAssoc[elem].id_player,
            id_formation_elem: selectedFormation?.elements[elem],
        }))));
        form.append('file_blob', blob);
        form.append('filte_type', blob.type);

        axios
            .post("matches/update_match_playing_formation_2", form, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                },
            })
            .then((res) => {
                if (res.data.success == 1) {
                    refreshMatchDetails();
                    refreshLineupsAndSubs();
                    store.addNotification({
                        title: languagePack.match,
                        message: languagePack.success_save_playing_formation,
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    store.addNotification({
                        title: languagePack.match,
                        message: languagePack.fail_save_playing_formation,
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
                closePopup();
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <TFBDialog
                className="pop-up-dialog-box prevent-select-text"
                maxWidth="xl"
                title={languagePack[`${props.half}_half_playing_formation`]}
                open={isOpenPopup}
                closePopup={closePopup}
                btnLeft={() => (
                    <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="cancel" />}
                        onClick={closePopup}
                    >
                        {languagePack.admin_cancel}
                    </TFBButton>
                )}
                btnRight={() => (
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="check" />}
                        disabled={selectedFormation == null}
                        onClick={savePlayingFormation}
                    >
                        {languagePack.admin_save_changes}
                    </TFBButton>
                )}
            >
                <div className={styles.sectionsContainer}>
                    <div className={styles.container}>
                        <div className={styles.containerTitle}>{languagePack.choose_playing_formation}</div>
                        <div className={styles.containerContent}>
                            <div className={styles.formationsList}>
                                {formations.map((row, i) => (
                                    <Formation
                                        index={row?.value}
                                        key={"formation-" + i}
                                        text={row.label}
                                        selected={_.isEqual(row, selectedFormation)}
                                        onClick={() => handleFormation(row, Object.values(playerPositionAssoc).length == 0)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.containerTitle}>{languagePack.select_position}</div>
                        <div className={styles.containerContent}>
                            <div ref={formationImgRef} className={styles.pitchContainerImg} style={{ backgroundImage: "url(../../assets/formations/pitch_playing_formation.png)" }}>
                                {selectedFormationConfig.map((row, i) => (
                                    <div className={styles.formationRow} key={"formation-row-" + i}>
                                        {row.map((elem, j) => (
                                            <div
                                                className={styles.playerRow}
                                                key={"row-player-" + i + "-" + j}
                                            >
                                                {elem != 0 && <div className={styles.player}>
                                                    <div
                                                        className={styles.shirtContainer}
                                                        style={{ backgroundImage: `url(${`../../assets/formations/pitch_${selectedFormationConfig.length - 1 == i ? "goalkeeper" : "player"}${_.isEqual(selectedPosition, { x: i, y: j }) ? "_selected" : ""}.png`})` }}
                                                        onClick={() => handlePosition(i, j)}
                                                    >
                                                        <div className={styles.playerNumber} style={{ color: selectedFormationConfig.length - 1 == i ? "black" : "white" }}>{playerPositionAssoc?.[elem] ? (playerPositionAssoc?.[elem]?.number ?? "-") : ""}</div>
                                                    </div>
                                                    <div className={styles.playerName}>{playerPositionAssoc?.[elem]?.name}</div>
                                                </div>}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.containerTitle}>{languagePack.associate_player}</div>
                        <div className={styles.containerContent}>
                            <div className={styles.playersList}>
                                {[...players].length > 0 ?
                                    [...players].map((row, i) => (
                                        <Player
                                            key={"player-formation-" + i}
                                            name={row.name}
                                            id={row.id_player}
                                            onConnect={() => handlePlayerConnect(row, selectedFormationConfig[selectedPosition.x][selectedPosition.y])}
                                            disabledConnect={selectedFormation == null || _.isEqual(selectedPosition, { x: null, y: null })}
                                            selected={Object.values(playerPositionAssoc).filter(e => e.id_player == row.id_player).length > 0}
                                            onCancel={() => handlePlayerCancel(row)}
                                        />
                                    )) :
                                    <TFBPlaceholder text={languagePack.no_players} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </TFBDialog>
            <TFBDialog
                className="pop-up-dialog-box prevent-select-text"
                title={languagePack.change_playing_formation}
                open={openAlertChangeFormation}
                closePopup={closeAlert}
                btnLeft={() => (
                    <TFBButton
                        color="darkGray"
                        renderIcon={() => <TFBIcon name="cancel" />}
                        onClick={closeAlert}
                    >
                        {languagePack.admin_cancel}
                    </TFBButton>
                )}
                btnRight={() => (
                    <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="check" />}
                        onClick={() => handleFormation(alertFormation)}
                    >
                        {languagePack.yes_change}
                    </TFBButton>
                )}
            >
                <div className="pop-up-dialog-delete-text">
                    {languagePack.change_playing_formation_message}
                </div>
            </TFBDialog>
        </>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(AddPlayingFormation);