import React from "react";
import "./KnockoutDiagram.scss";

class KnockoutDiagram extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rounds: [
        {
          name: "Optimi",
          matches: [1, 2, 3],
        },
        {
          name: "Sferturi",
          matches: [1, 2, 3],
        },
        {
          name: "Semifinale",
          matches: [1, 2, 3],
        },
        {
          name: "Finala",
          matches: [1, 2, 3],
        },
      ],
    };
  }
  render() {
    return (
      <div className="knockout-diagram-container">
        {this.state.rounds.map((item, index) => {
          return (
            <div
              className="diagram-column"
              style={{ width: `calc(100% / ${this.state.rounds.length})` }}
            >
              <div className="round-name">{item.name}</div>
              {item.matches.map((el) => {
                return <div className="match-container">{el}</div>;
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default KnockoutDiagram;
