import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import "./Teams.scss";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../../components/design-system/TextArea/TFBTextArea";
import Swal from "sweetalert2";

import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import {
  useAxiosPost,
  showNotification,
} from "../../../../components/customHooks/useAxiosPost";
import { updateQuickSetup } from "../../../../utils/api";
import { bindActionCreators } from "redux";
import moment from "moment";
import TFBColorPicker from "../../../../components/design-system/TFBColorPicker/TFBColorPicker";
import { isTFB } from "../../../../utils/utilFunctions";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

function AddTeamForm({ ...props }) {
  const [teamTypes, setTeamTypes] = useState([]);
  const [name, setName] = useState("");

  const [category, setCategory] = useState("");
  const [newTeamType, setNewTeamType] = useState("");

  const [transfermarktLink, setTransfermarktLink] = useState("");
  const [coachOptions, setCoachOptions] = useState([]);

  const [coach, setCoach] = useState("");
  const [newCoach, setNewCoach] = useState("");

  const [seasonStartDate, setSeasonStartDate] = useState(null);
  const [seasonEndDate, setSeasonEndDate] = useState(null);

  const [teamColor, setTeamColor] = useState(null);

  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState("");
  const [newRole, setNewRole] = useState("");
  const [dateExpire, setDateExpire] = useState(null);

  const { languagePack, token } = props;

  const licenseType = [
    {
      id: "0",
      license: "Nicio licență",
      value: "0",
      // label: "Nicio licență",
      label: languagePack.without_license,
    },
    {
      id: "1",
      license: "UEFA B",
      value: "1",
      // label: "UEFA B",
      label: languagePack.uefa_b,
    },
    {
      id: "2",
      license: "UEFA A",
      value: "2",
      // label: "UEFA A",
      label: languagePack.uefa_a,
    },
    {
      id: "3",
      license: "UEFA A Youth",
      value: "3",
      // label: "UEFA A Youth",
      label: languagePack.uefa_a_youth,
    },
    {
      id: "10",
      license: "Uefa B Youth",
      value: "10",
      label: languagePack.uefa_b_youth,
    },
    {
      id: "4",
      license: "UEFA A GK",
      value: "4",
      // label: "UEFA A GK",
      label: languagePack.uefa_a_gk,
    },
    {
      id: "5",
      license: "UEFA B GK",
      value: "5",
      // label: "UEFA B GK",
      label: languagePack.uefa_b_gk,
    },
    {
      id: "6",
      license: "UEFA Pro",
      value: "6",
      // label: "UEFA Pro",
      label: languagePack.uefa_pro,
    },
    {
      id: "7",
      license: "NON-UEFA",
      value: "7",
      // label: "NON-UEFA",
      label: languagePack.uefa_non_uefa,
    },
    {
      id: "8",
      license: "UEFA C",
      value: "8",
      // label: "UEFA C",
      label: languagePack.uefa_c,
    },
    {
      id: "9",
      license: "Carnet de antrenor",
      value: "9",
      // label: "Carnet de antrenor",
      label: languagePack.uefa_coaching_license,
    },
  ];

  const [license, setLicense] = useState("");
  const [licenseOption, setLicenseOption] = useState(licenseType);

  // GET COACH LIST ================================================
  // =============================================================
  const {
    data: coachListData,
    error: coachListError,
    loading: coachListLoading,
  } = useAxiosGet(`club/get_coaches?id_club=${props.id_club}`);

  // GET TEAM TYPES ================================================
  // =============================================================
  const {
    data: teamTypesData,
    error: teamTypesError,
    loading: teamTypesLoading,
  } = useAxiosGet(`club/get_types?id_club=${props.currentUser.id_club}`);

  // GET ROLES TYPES ================================================
  // =============================================================
  const {
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
    refetch: rolesRefetch,
  } = useAxiosGet(`club/get_roles?id_club=${props.currentUser.id_club}`);

  // POST NEW TEAM ================================================
  // =============================================================
  const { data: responsePostTeam, postData: postNewTeam } = useAxiosPost(
    `club/add_new_team_4?id_club=${props.id_club}`
  );
  // =============================================================
  // =============================================================

  useEffect(() => {
    if (coachListError) {
      console.log(coachListError);
    }

    if (teamTypesError) {
      console.log(teamTypesError);
    }

    if (rolesError) {
      console.log(rolesError);
    }
  }, [coachListError, teamTypesError, rolesError]);

  useEffect(() => {
    if (coachListData) {
      let coachList = [
        {
          key: "0",
          label: languagePack.add_new_team_coordinator,
          text: languagePack.add_new_team_coordinator,
          value: "0",
        },
        ...coachListData,
      ];

      setCoachOptions(Array.isArray(coachList) ? coachList : []);
    }

    if (teamTypesData) {
      let teamTypes = [
        {
          key: "0",
          label: languagePack.team_type_add,
          text: languagePack.team_type_add,
          value: "0",
        },
        ...teamTypesData,
      ];

      setTeamTypes(Array.isArray(teamTypes) ? teamTypes : []);
    }

    if (rolesData) {
      let allRoles = [
        {
          key: "0",
          value: "0",
          label: languagePack.add_new_role,
          text: languagePack.add_new_role,
        },
        ...rolesData,
      ];

      setRoleOptions(Array.isArray(allRoles) ? allRoles : []);
    }
  }, [coachListData, teamTypesData, rolesData]);

  // =============================================================
  // =============================================================

  useEffect(() => {
    if (coach && coach.key == 0) rolesRefetch();
  }, [coach]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (responsePostTeam.success == 1) {
      showNotification(
        "success",
        languagePack["teams_notification_title"],
        languagePack["teams_notification_success"]
      );

      props.closePopup();
      props.refreshTeams();
      props.refreshStaffMembers();

      let clubQuickSetup = responsePostTeam.club_quick_setup;
      props.updateQuickSetup({
        ...clubQuickSetup,
        isSetupOpen: false,
      });
    }
  }, [responsePostTeam]);

  const renderBtn = () => {
    const hasLicenseValid =
      license.license != "Nicio licență" && dateExpire == null;

    const newValidRole =
      role.key != "0"
        ? role == "" || hasLicenseValid
        : newRole.trim() < 1 || hasLicenseValid;

    const isNewMember =
      coach.key == "0" && (newCoach.trim().length < 1 || newValidRole);

    return (
      <TFBButton
        // className="custom-button-dual add-button"
        onClick={checkSeasonDate}
        color="green"
        renderIcon={() => <TFBIcon name="add" />}
        disabled={
          (category.key == "0" && newTeamType.trim().length < 1) ||
          isNewMember ||
          !(name.trim().length > 0) ||
          category == {} ||
          coach.length == {} ||
          seasonStartDate == null ||
          seasonEndDate == null ||
          moment(seasonEndDate) < moment(seasonStartDate)
        }
      >
        {languagePack["admin_add"]}
      </TFBButton>
    );
  };

  function handleChangeInput(e) {
    setName(e.target.value);
  }

  function handleChangeDateStart(value) {
    setSeasonStartDate(value);
  }

  function handleChangeDateEnd(value) {
    setSeasonEndDate(value);
  }

  const handleChangeExpireDate = (value) => {
    setDateExpire(value);
  };

  function handleChangeTmLink(e) {
    setTransfermarktLink(e.target.value);
  }

  function handleAlertSeasonDate() {
    Swal.fire({
      title: languagePack.too_short_season_error,
      showDenyButton: true,
      confirmButtonText: languagePack.yes,
      denyButtonText: languagePack.no,
      customClass: {
        container: "custom-swals-container-add-team",
        confirmButton: "custom-swals-confirm-button",
        denyButton: "custom-swals-deny-button",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isDenied) {
        setSeasonEndDate(null);
      } else if (result.isConfirmed) {
        submitNewTeam();
      }
    });
  }

  function checkSeasonDate() {
    if (
      seasonEndDate &&
      seasonStartDate &&
      !(moment(seasonEndDate) < moment(seasonStartDate)) &&
      moment(seasonEndDate).diff(moment(seasonStartDate), "days") <= 30
    ) {
      handleAlertSeasonDate();
    } else {
      submitNewTeam();
    }
  }

  function submitNewTeam() {
    let team = {
      team_name: name,
      id_club: props.id_club,
      id_user: props.currentUser.id_user,
      season_date_start: dateFormat(seasonStartDate, "yyyy-mm-dd"),
      season_date_end: dateFormat(seasonEndDate, "yyyy-mm-dd"),
      transfermarktLink: transfermarktLink,
      team_color: teamColor,
      is_tfb: isTFB(),
      token: token,
    };

    //Check if is a new team type
    if (category.key != "0") {
      team = { ...team, team_type: category.value };
    } else {
      team = { ...team, new_team_type: newTeamType };
    }

    //Check if is a new member/coach
    if (coach.key != "0") {
      team = { ...team, id_coach: coach.value };
    } else {
      team = {
        ...team,
        new_staff_member_name: newCoach,
        date_expire_license: dateExpire == "Invalid date" ? null : dateExpire,
        license: license.license,
      };

      //Check if is a new member role
      if (role.key != "0") {
        team = { ...team, staff_member_role: role.label };
      } else {
        team = { ...team, new_staff_member_role: newRole };
      }
    }

    postNewTeam(team);
  }

  return (
    <TFBDialog
      open={props.open}
      fullWidth={true}
      maxWidth="sm"
      closePopup={props.closePopup}
      title={languagePack["add_team"]}
      // btnRight={() => renderBtn("right", () => exportPlayers())}
      btnLeft={() => renderBtn()}
    >
      <div className="add-team-container">
        <div className="df df-gap-15">
          <TFBInput
            value={name}
            onChange={handleChangeInput}
            label={languagePack["team_name"]}
            style={{ backgroundColor: "#fafafa", marginBottom: "25px" }}
            fullWidth
          />
          <TFBColorPicker
            label={languagePack.team_color}
            noColor={teamColor === null}
            returnColor={(color) => setTeamColor(color)}
            initialColor={teamColor ?? ""}
            text={languagePack.select_color}
          />
        </div>
        <TFBCheckbox
          style={{ marginBottom: "15px" }}
          onChange={(e) => (e.target.checked ? setTeamColor(null) : "")}
          checked={teamColor === null}
          label={languagePack["no_color"]}
        />
        <div className="">
          <TFBSelect
            label={languagePack["team_type"]}
            value={category}
            options={teamTypes}
            onChange={(selected) => {
              setCategory(selected);
            }}
          />

          {category.key == 0 && (
            <div className="">
              <TFBInput
                value={newTeamType}
                onChange={(e) => setNewTeamType(e.target.value)}
                label={languagePack["team_type_add"]}
                style={{ backgroundColor: "#fafafa", marginBottom: "25px" }}
                fullWidth
              />
            </div>
          )}
        </div>
        <div className="">
          <TFBSelect
            label={languagePack["team_coordinator"]}
            value={coach}
            options={coachOptions}
            onChange={(selected) => {
              setCoach(selected);
            }}
          />

          {coach.key == 0 && (
            <>
              <div className="animate-slide">
                <TFBInput
                  value={newCoach}
                  onChange={(e) => setNewCoach(e.target.value)}
                  label={languagePack["add_new_team_coordinator"]}
                  style={{ backgroundColor: "#fafafa", marginBottom: "25px" }}
                  fullWidth
                />
              </div>

              <div className="animate-slide" style={{ marginBottom: "25px" }}>
                <TFBSelect
                  placeholder={languagePack["select"]}
                  label={languagePack["staff_member_role"]}
                  value={role}
                  options={roleOptions}
                  onChange={(selected) => {
                    setRole(selected);
                  }}
                />
              </div>

              {role.key == 0 && (
                <div className="animate-slide">
                  <TFBInput
                    value={newRole}
                    onChange={(e) => setNewRole(e.target.value)}
                    label={languagePack.add_new_role}
                    fullWidth
                    style={{ backgroundColor: "#fafafa", marginBottom: "25px" }}
                  />
                </div>
              )}

              <div className="animate-slide" style={{ marginBottom: "25px" }}>
                <TFBSelect
                  placeholder={languagePack.select}
                  label={languagePack.select_license}
                  value={license}
                  options={licenseOption}
                  onChange={(selected) => {
                    setLicense(selected);
                    setDateExpire(null);
                  }}
                />
              </div>

              {license.license != "Nicio licență" && license != "" && (
                <div
                  className="license-date-end animate-slide"
                  style={{ marginBottom: "25px" }}
                >
                  <TFBDatePicker
                    value={dateExpire}
                    onChange={handleChangeExpireDate}
                    placeholder={languagePack.select}
                    label={languagePack.date_expire_license}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </>
          )}
        </div>

        <div className="add-team-season">
          <TFBDatePicker
            value={seasonStartDate}
            onChange={handleChangeDateStart}
            className="team-add-date-input"
            label={languagePack["season_date_start_add_team"]}
            style={{ width: "100%" }}
          />
        </div>
        <div className="add-team-season">
          <TFBDatePicker
            value={seasonEndDate}
            onChange={handleChangeDateEnd}
            className="team-add-date-input"
            label={languagePack["season_date_end_add_team"]}
            style={{ width: "100%" }}
          />
        </div>

        {/* {seasonEndDate &&
          seasonStartDate &&
          !(moment(seasonEndDate) < moment(seasonStartDate)) &&
          moment(seasonEndDate).diff(moment(seasonStartDate), "days") <= 30 &&
          handleAlertSeasonDate()} */}

        <TFBTextArea
          label={languagePack["tm_link_team"]}
          value={transfermarktLink}
          onChange={handleChangeTmLink}
          className="tm-link-input"
        />

        {moment(seasonEndDate) < moment(seasonStartDate) && (
          <div className="df-g20 tab-control-line">
            <div className="error-message">
              {languagePack.start_end_season_error}
            </div>
          </div>
        )}
      </div>
    </TFBDialog>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(AddTeamForm);
