import React, { useContext, useReducer } from "react";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import { connect } from "react-redux";
import ImportKSport from "./ImportKSport";
import { TrainingDataContext } from "../TrainingData";
function ImportDataFilePopup({
  languagePack,
  currentUser,

  token,
}) {
  const { closeDataFilePopup } = useContext(TrainingDataContext);
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      dialogSize: "sm",
      importType: "",
    }
  );
  return (
    <TFBDialog
      open
      title={languagePack.upload_file}
      closePopup={closeDataFilePopup}
      fullWidth
      maxWidth={state.dialogSize}
      style={{ display: "flex", flexDirection: "column", gap: "25px" }}
    >
      <TFBSelect
        label={languagePack.select_source}
        placeholder={languagePack.select_source}
        value={state.importType}
        onChange={(selected) => setState({ importType: selected })}
        options={[
          { value: "k_sports", label: "kSports" },
          { value: "stat_sports", label: "StatSports" },
          { value: "catapult", label: "Catapult" },
          { value: "polar", label: "Polar" },
        ]}
      />
      {state.importType.value === "k_sports" && (
        <ImportKSport
          onSetDialogSize={(size) => setState({ dialogSize: size })}
        />
      )}
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyRates: auth?.user?.currency_rates,
}))(ImportDataFilePopup);
