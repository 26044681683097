import React, { useState, useEffect } from "react";

const useError = (errorMesage) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(errorMesage);
  }, []);

  const handleSetError = (errorName) => {
    setError(errorName);
  };

  const ErrorComponent = () => {
    return <div className="ui red message">{error}</div>;
  };

  return { error, handleSetError, ErrorComponent };
};

export default useError;
