import React from "react";
import "./EditNews.scss";
import { Icon, Button, TextArea } from "semantic-ui-react";
import axios from "axios";
import { connect } from "react-redux";

class EditSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input_value: this.props.value,
    };
  }

  saveChanges = () => {
    const payload = {
      id: this.props.index,
      id_news: this.props.id_news,
      new_value: this.state.input_value,
      id_club: this.props.id_club,
    };
    axios
      .post("site/edit_section", payload)
      .then(() => {
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="edit-news-setion-container">
        <div className="edit-news-popup-header">
          <div className="edit-news-popup-title">
            {languagePack["edit_section"]}
          </div>
          <Icon
            name="close"
            size="large"
            onClick={() => this.props.closePopup()}
            style={{ cursor: "pointer" }}
          />
        </div>

        <TextArea
          className="edit-section-text-area"
          value={this.state.input_value}
          onChange={(e) => this.setState({ input_value: e.target.value })}
          name="input_value"
          style={{ minHeight: 250 }}
        />

        <div className="edit-section-popup-buttons">
          <Button
            className="edit-section-button"
            style={{ backgroundColor: "#666666" }}
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}{" "}
            <Icon name="ban" className="edit-section-icon" />
          </Button>
          <Button
            className="edit-section-button"
            style={{ backgroundColor: "#00d948" }}
            onClick={this.saveChanges}
          >
            {languagePack["admin_add"]}{" "}
            <Icon name="add" className="edit-section-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditSection);
