import React from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { logout } from "./../../../utils/api";
import { Menu } from "semantic-ui-react";
import PlayerEvaluationResult from "../../../components/charts/Evaluations/PlayerEvaluationResult";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Icon } from "semantic-ui-react";

class IndividualPlayerEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test_categories: [],
      selectedMenuOption: "0",
      series: [],
      options: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val;
          },
          // offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: [],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          // tooltip: {
          //     enabled: true,
          // }
        },
        yaxis: {
          min: 0,
          max: 5,
          tickAmount: 5,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function(val) {
              return val.toLocaleString();
            },
          },
        },
        title: {
          text: "",
          align: "left",
        },
        fill: {
          colors: [
            function({ value, seriesIndex, w }) {
              if (value < 2) {
                return "#bd0b0b";
              } else if (value > 1 && value < 4) {
                return "#dbd114";
              } else {
                return "#32bf08";
              }
            },
          ],
        },
      },
    };
  }

  componentDidMount = () => {
    const id = this.props.id_player;
    axios
      .get(`club/individual_evaluation_results`, {
        params: {
          id_player: id,
          // token: this.props.token,
          // id_evaluation_test: this.props.id_evaluation_test,
          token: this.props.token,
          id_evaluation_test: this.props.id_evaluation_test,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState((prevState) => ({
            options: {
              ...prevState.options,
              xaxis: {
                ...prevState.xaxis,
                categories: res.data.test_params,
              },
              title: {
                ...prevState.title,
                text: this.props.player_name,
              },
            },
            series: res.data.series,
          }));
        }
      })
      .catch((err) => console.log(err));
    this.getCategories();
  };

  getCategories = () => {
    axios
      .get("club/get_evaluation_categories", {
        params: {
          token: this.props.token,
          id_player: this.props.id_player,
          id_evaluation_test: this.props.id_evaluation_test,
        },
      })
      .then((res) => {
        if (res?.data?.go_to_login === 1) {
          this.props.logout(this.props.token);
        } else {
          this.setState({
            test_categories: res.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="card card-custom" style={{ padding: 20 }}>
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
          width={"100%"}
        />
        <div style={{ alignSelf: "center", width: "100%" }}>
          <Menu pointing secondary>
            {this.state.test_categories.map((item, index) => {
              return (
                <Menu.Item
                  key={"menu-evaluation" + index}
                  name={item.category_name}
                  active={
                    this.state.selectedMenuOption === item.id_param_category
                  }
                  onClick={() =>
                    this.setState({
                      selectedMenuOption: item.id_param_category,
                    })
                  }
                />
              );
            })}
          </Menu>
          <PlayerEvaluationResult
            id_player={this.props.id_player}
            id_category={this.state.selectedMenuOption}
            id_test={this.props.id_evaluation_test}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(IndividualPlayerEvaluation);
