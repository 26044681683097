import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { showNotification, useAxiosPost } from "../../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../../components/customHooks/useRenderButton";

import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../../components/design-system/Input/TFBInput";

const EditMatchLineup = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, event } = props;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            minutes: _.isInteger(_.parseInt(event?.played_minutes ?? "")) ? _.parseInt(event?.played_minutes ?? "") : "",
            number: _.isInteger(_.parseInt(event?.number ?? "")) ? _.parseInt(event?.number ?? "") : "",
        }
    );

    const { postData: editRequest, loading: loadingEditRequest } = useAxiosPost("matches/edit_manual_match_event");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.fixtures_schedule,
                languagePack.update_record_event_success
            );
        } else {
            showNotification(
                "danger",
                languagePack.fixtures_schedule,
                languagePack.update_record_event_fail
            );
        }
    };

    const editEvent = () => {
        const payload = {
            id_event: event?.id_event,
            payload_to_edit: {
                played_minutes: _.size(_.toString(state.minutes)) > 0 ? state.minutes : null,
                player_number: _.size(_.toString(state.number)) > 0 ? state.number : null,
            },
        };

        editRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => editEvent(),
        "green",
        "check",
        languagePack.admin_save,
        {},
        true,
        false,
        loadingEditRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["admin_edit"] + " " + event.name}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="df df-gap-10">
                <TFBInput
                    label={languagePack.shirt_number}
                    value={state.number}
                    onChange={(e) => updateState({ number: e.target.value })}
                    style={{ width: "100%", backgroundColor: "#FAFAFA" }}
                    type="number"
                />
                <TFBInput
                    label={languagePack.minutes_played}
                    value={state.minutes}
                    onChange={(e) => updateState({ minutes: e.target.value })}
                    style={{ width: "100%", backgroundColor: "#FAFAFA" }}
                    type="number"
                />
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditMatchLineup);
