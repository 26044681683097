import React, { useState, useEffect } from "react";
import "../Instat.scss";
import axios from "axios";
import { ReactComponent as EyeIcon } from "../../../../assets/icons/eye-white.svg";
import { useHistory } from "react-router-dom";

export default function LastMatches(props) {
  const [matches, setMatches] = useState([]);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`instat/last_matches?id_team=${props.id_team}`)
      .then((res) => setMatches(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card card-custom" style={{ height: 610 }}>
      <div className="instat-last-matches-container">
        <div className="instat-last-matches-title">Ultimele meciuri</div>
        <div className="instat-last-matches-list">
          {matches.map((item, index) => {
            return (
              <div
                className="match-row"
                style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                key={index}
              >
                <div className="team team-home">{item.team_home_name}</div>
                <div className="score">
                  <div className="score-digit">{item.team_home_score}</div>:{" "}
                  <div className="score-digit">{item.team_away_score}</div>
                </div>
                <div className="team team-away">{item.team_away_name}</div>
                <div
                  className="details-icon"
                  onClick={() =>
                    {
                      props.id_club == 1 || props.id_club == 10 ?
                      history.push(`/club/match/instat/${item.id_match_football_api}`) : 
                      history.push(`/club/match/${item.id_match_football_api}`)
                    }
                  }
                >
                  <EyeIcon />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
