import React, { createContext, useContext, useEffect, useReducer } from "react";
import { connect } from "react-redux";

import { Table, TableRow } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import styles from "./Competitions.module.scss";
import AddCompetition from "./AddCompetition";

import AddAgeGroup from "./AddAgeGroup";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import {
  baseUrl,
  prepareStringForCompare,
} from "../../../../utils/utilFunctions";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import { TableBody, TableContainer, TableHead } from "@mui/material";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../../components/reusable/useStyles";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import _ from "lodash";
import TFBTeamLabel from "../../../../components/design-system/TFBTeamLabel/TFBTeamLabel";
import moment from "moment";
import CircularLoader from "../../../../components/reusable/Loader";
import EditCompetitionPopup from "./EditCompetitionPopup";
import DeleteCompetition from "./DeleteCompetition";
import { useAxiosPost } from "../../../../components/customHooks/useAxiosPost";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import { ageCategoryOptions } from "./AddManualCompetition";
import TFBSearch from "../../../../components/design-system/TFBSearch/TFBSearch";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";

export const CompetitionsContext = createContext("");

function Competitions({ languagePack, currentUser, token }) {
  const history = useHistory();

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      competitions: [],
      addCompetitionPopup: false,
      deleteCompetitionPopup: false,
      wantToDeleteComp: "",
      editCompetitionPopup: false,
      wantToEditComp: "",
      addAgeGroup: false,
      wantToAddGroupComp: "",
      extendParentComp: false,
      editAgeGroup: false,
      wantToEditAgeGroup: "",
      competitionCategory: "active",
      team: null,
      ageGroup: null,
      season: null,
      searchValue: "",
      teamList: [],
      usedFilters: false,
    }
  );

  const {
    data: competitionsData,
    loading: loadingCompetitionsData,
    refetch: refetchCompetitions,
  } = useAxiosGet(
    baseUrl(
      `matches/get_competitions_2?token=${token}&history=${
        state.competitionCategory === "active" ? 0 : 1
      }${state.ageGroup !== null ? `&age_group=${state.ageGroup?.label}` : ""}${
        state.team !== null ? `&id_team=${state.team?.value}` : ""
      }${
        state.searchValue !== ""
          ? `&competition_name=${prepareStringForCompare(state.searchValue)}`
          : ""
      }${
        state.season !== null ? `&date_start=${state.season?.date_start}` : ""
      }${state.season !== null ? `&date_end=${state.season?.date_end}` : ""}`
    ),
    true
  );

  const {
    data,
    loading: loadingEditCompetition,
    postData: postEditCompetition,
  } = useAxiosPost(baseUrl("matches/edit_competition"));

  useEffect(() => {
    setState({
      competitions: competitionsData,
    });
  }, [competitionsData, state.competitionCategory]);

  useEffect(() => {
    resetFilters();
    refetchCompetitions();
  }, [state.competitionCategory]);

  useEffect(() => {
    if (
      state.ageGroup === null &&
      state.team === null &&
      state.season === null &&
      state.usedFilters === true &&
      state.searchValue === ""
    )
      refetchCompetitions();
  }, [
    refetchCompetitions,
    state.ageGroup,
    state.competitionCategory,
    state.searchValue,
    state.season,
    state.team,
    state.usedFilters,
  ]);

  const editComp = (item) => {
    setState({
      wantToEditComp: item,
      editCompetitionPopup: true,
    });
  };
  const handleChangeSelect = (selected, value) => {
    setState({ [value.name]: selected, usedFilters: true });
  };

  const deleteComp = (item) => {
    setState({
      wantToDeleteComp: item.key ?? item.id_competition,
      deleteCompetitionPopup: true,
    });
  };

  const addGroupAge = (item) => {
    setState({
      wantToAddGroupComp: item,
      addAgeGroup: true,
      addCompetitionPopup: true,
    });
  };

  const editGroupAge = (item) => {
    setState({
      wantToEditAgeGroup: item,
      editAgeGroup: true,
    });
  };

  const deleteGroupAge = (item) => {
    setState({
      wantToDeleteComp: item.id_competition,
      deleteCompetitionPopup: true,
    });
  };

  const handleClickName = (comp) => {
    if (Number(comp.multiple_age_groups) === 1) {
      editComp(comp);
    } else {
      history.push(`/club/competitions/${comp.key}`);
    }
  };

  const handleChangeCategoryType = (type) => {
    setState({ competitionCategory: type });
  };
  const resetFilters = () => {
    setState({ ageGroup: null, team: null, season: null, searchValue: "" });
  };
  const checkForFilterChange = (state) => {
    return (
      state.ageGroup !== null ||
      state.team !== null ||
      state.season !== null ||
      state.searchValue !== ""
    );
  };
  useEffect(() => {
    if (!checkForFilterChange(state)) {
      setState({
        teamList: _.uniqBy(
          _.flatten(
            _.map(competitionsData?.list, (comp) =>
              _.map(comp.own_teams, (team) => {
                return { label: team.team_name, value: team.id_team };
              })
            )
          ),
          "value"
        ),
      });
    }
  }, [competitionsData]);

  return (
    <CompetitionsContext.Provider
      value={{
        ...state,
        loadingCompetitionsData,
        refetchCompetitions,
        loadingEditCompetition,
        postEditCompetition,
        onAddGroupAge: (item) => addGroupAge(item),
        onEditGroupAge: (item) => editGroupAge(item),
        onEditComp: (item) => editComp(item),
        onDeleteComp: (item) => deleteComp(item),
        onSetState: (newState) => setState(newState),
        onCloseAddCompetition: () =>
          setState({ addCompetitionPopup: false, addAgeGroup: false }),
        onCloseEditCompetition: () =>
          setState({
            wantToEditComp: {},
            editCompetitionPopup: false,
          }),
        onCloseDeleteCompetition: () =>
          setState({
            deleteCompetitionPopup: false,
            editCompetitionPopup: false,
          }),
      }}
    >
      <TFBCard>
        <TFBCardHeader title={languagePack.competitions}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={() => setState({ addCompetitionPopup: true })}
          >
            {languagePack.add_competition}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody className={styles.dialogueBody}>
          <div className={styles.headBar}>
            <div className={styles.typeDivs}>
              <div
                onClick={() => handleChangeCategoryType("active")}
                className={` ${
                  state.competitionCategory === "active" ? styles.active : ""
                } ${styles.typeDiv}`}
              >
                {languagePack.active_competitions}
              </div>
              <div
                onClick={() => handleChangeCategoryType("history")}
                className={` ${
                  state.competitionCategory === "history" ? styles.active : ""
                } ${styles.typeDiv}`}
              >
                {languagePack.competition_history}
              </div>
            </div>
          </div>
          {state.competitionCategory === "history" &&
            !loadingCompetitionsData &&
            (_.size(state.competitions?.list) > 0 || state.usedFilters) && (
              <div className={styles.secondRow}>
                <div className={styles.searchBarDiv}>
                  <TFBSearch
                    placeholder={languagePack.find_competition}
                    classNameSearch={styles.searchVideo}
                    classNameComponent={styles.searchComponent}
                    fluid
                    showNoResults={false}
                    value={state.searchValue}
                    onSearchChange={(e) =>
                      setState({
                        searchValue: e.target.value,
                        usedFilters: true,
                        page: 1,
                        has_more: true,
                      })
                    }
                  />
                  {checkForFilterChange(state) && (
                    <div className={styles.buttonsDiv}>
                      <TFBButton
                        color="green"
                        renderIcon={() => <TFBIcon name="check" />}
                        onClick={refetchCompetitions}
                        className={styles.fullWidth}
                        disabled={
                          _.size(state.searchValue) < 3 &&
                          state.searchValue !== ""
                        }
                      >
                        {languagePack["apply_filter"]}
                      </TFBButton>

                      <div className={styles.selectDiv}>
                        <TFBButton
                          color="lightGray"
                          renderIcon={() => (
                            <TFBIcon name="cancel" color="gray" />
                          )}
                          onClick={resetFilters}
                          className={styles.fullWidth}
                        >
                          {languagePack["cancel_filter"]}
                        </TFBButton>
                      </div>
                    </div>
                  )}
                </div>
                {state.competitionCategory === "history" && (
                  <div className={styles.filters}>
                    <TFBSelect
                      name="ageGroup"
                      // isClearable
                      value={state.ageGroup}
                      onChange={handleChangeSelect}
                      isSearchable
                      placeholder={languagePack.age_group}
                      options={ageCategoryOptions}
                      style={{
                        container: {
                          maxWidth: "420px",
                        },
                      }}
                    />
                    <TFBSelect
                      name="team"
                      // isClearable
                      value={state.team}
                      onChange={handleChangeSelect}
                      isSearchable
                      placeholder={languagePack.team}
                      options={state.teamList}
                      style={{
                        container: {
                          width: "420px",
                          maxWidth: "420px",
                        },
                      }}
                    />
                    <TFBSelect
                      name="season"
                      // isClearable
                      value={state.season}
                      onChange={handleChangeSelect}
                      isSearchable
                      placeholder={languagePack.season}
                      options={_.reverse(competitionsData?.seasons)}
                      style={{
                        container: {
                          maxWidth: "420px",
                          width: "420px",
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          {loadingCompetitionsData ? (
            <CircularLoader />
          ) : _.size(state.competitions?.list) > 0 ? (
            <TableContainer
              style={{
                padding:
                  state.competitionCategory === "history"
                    ? "0px 50px 50px 50px"
                    : "50px",
                backgroundColor: "#fafafa",
              }}
            >
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn} align="left">
                      {languagePack.competition_name}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.categories} / {languagePack.own_club_teams}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.teams_number}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.starting_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.ending_date}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.news_page_header_options}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.competitions?.list?.map((row, index) => (
                    <TableRow
                      key={"active-competition-" + index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                        className={styles.hoverDiv}
                        align="left"
                        onClick={() => handleClickName(row)}
                      >
                        {row.text}
                      </TFBTableCell>
                      <TFBTableCell
                        style={{
                          color: _.size(row.own_teams) === 0 ? "red" : "",
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                        align="center"
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {_.size(row.own_teams) > 0
                            ? _.map(row.own_teams, (el, i) => (
                                <TFBTeamLabel
                                  key={i}
                                  color={el.team_color}
                                  name={el.team_name}
                                  onClick={() =>
                                    history.push(`/club/team/${el.id_team}`)
                                  }
                                />
                              ))
                            : _.size(row.sub_comps) > 0
                            ? _.map(row.sub_comps, (comp, i) => (
                                <TFBTeamLabel
                                  key={i}
                                  color="#666666"
                                  name={comp.category?.label}
                                />
                              ))
                            : Number(row.multiple_age_groups) === 1
                            ? languagePack.no_subgroup_added
                            : languagePack.no_selected_own_team}{" "}
                        </div>
                      </TFBTableCell>
                      <TFBTableCell
                        align="center"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row.teams_number}
                      </TFBTableCell>
                      <TFBTableCell
                        align="center"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {moment(row.date_start).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell
                        align="center"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {moment(row.date_end).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell
                        align="center"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <div
                          className="actions-btns-container"
                          style={{ justifyContent: "flex-end" }}
                        >
                          {Number(row.multiple_age_groups) === 1 && (
                            <TFBIconButton
                              name="report"
                              color="darkGray"
                              altTitle={languagePack.altTitle.report}
                              onClick={() =>
                                history.push(
                                  `/club/competitions/info/${row.key}`
                                )
                              }
                            />
                          )}
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            altTitle={languagePack.altTitle.view_competition}
                            onClick={() => handleClickName(row)}
                          />
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            altTitle={languagePack.altTitle.edit_competition}
                            onClick={() => editComp(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            altTitle={languagePack.altTitle.delete}
                            onClick={() => deleteComp(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder
              text={
                state.competitionCategory === "active"
                  ? languagePack.no_active_competitions_placeholder
                  : languagePack.no_historic_competitions_placeholder
              }
            />
          )}
          {state.editCompetitionPopup && <EditCompetitionPopup />}
          {state.deleteCompetitionPopup && <DeleteCompetition />}
        </TFBCardBody>{" "}
        {state.addCompetitionPopup && <AddCompetition />}
      </TFBCard>
    </CompetitionsContext.Provider>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(Competitions);
