import React from "react";
import "./CompetitionInfoPage.scss";
import { Button, Icon } from "semantic-ui-react";

import {
  Switch,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core/";

import axios from "axios";
import { connect } from "react-redux";
import { store } from "react-notifications-component";

import AddPrize from "./AddPrize";
import "draft-js/dist/Draft.css";
import { css } from "@emotion/css";
import { Redirect } from "react-router-dom";
import { baseUrl } from "../../../../utils/utilFunctions";
import TFBPlayerWithAvatar from "../../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";

class PrizesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_prizes: false,
      show_add_prize: false,
      delete_prize_for_player: false,
      id_prize_delete: "",
      prizes: [],
      hasAccessToCompetition: false,
      redirectToCompetitions: false,
    };
  }

  componentDidMount = () => {
    this.hasAccessToCompetition();
  };

  hasAccessToCompetition = () => {
    axios
      .get(
        `user/has_access_to_competition?id_competition=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToCompetition: true,
          });
          this.getDetails();
          this.getPrizes();
        } else {
          this.setState({
            redirectToCompetitions: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getDetails = () => {
    axios
      .get(
        `site/get_competition_app?id_competition=${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({
          show_prizes: res.data.show_prizes == "0" ? false : true,
          show_add_prize: false,
        });
      })
      .catch((err) => console.log(err));
  };

  getPrizes = () => {
    axios
      .get(
        `site/get_competition_prizes?id_competition=${this.props.match.params.id}`
      )
      .then((res) => {
        this.setState({
          prizes: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

  deletePrize = () => {
    axios
      .post("site/delete_prize", {
        id_competition_prize: this.state.id_prize_delete,
      })
      .then(() => {
        this.setState({
          delete_prize_for_player: false,
          id_prize_delete: "",
        });
        this.getPrizes();
        store.addNotification({
          title: this.props.languagePack["fixtures_schedule"],
          message: this.props.languagePack["payment_successfully_deleted"],
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      });
  };

  handleChangeShowPrizes = () => {
    this.setState({
      show_prizes: !this.state.show_prizes,
    });
    axios
      .post("site/update_prizes", {
        id_competition: this.props.match.params.id,
        show_prizes: {
          true: "1",
          false: "0",
        }[!this.state.show_prizes],
      })
      .then(() => {
        this.getDetails();
      });
  };

  render() {
    if (this.state.redirectToCompetitions) {
      return <Redirect to="/club/competitions" />;
    }
    const { languagePack } = this.props;

    return (
      <>
        {this.state.hasAccessToCompetition && (
          <div className="card card-custom">
            <div className="prizes-container">
              {/* <div className="title-enable-container">
                        {"Enable/disable sections"}
                    </div> */}
              <div className="enable-registration-form">
                <div className="title">
                  {"Enable/disable special prizes sections for players"}
                </div>
                <Switch
                  checked={this.state.show_prizes}
                  disabled={this.state.prizes.length == 0}
                  onChange={this.handleChangeShowPrizes}
                  color="primary"
                  name="anotherDatePayment"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </div>
              <div
                className="table-prizes"
                style={{
                  filter: !this.state.show_prizes
                    ? "opacity(0.5)"
                    : "opacity(1)",
                }}
              >
                <div className="header-line">
                  <div className="col1 name">Player name</div>
                  <div className="col1 prize">Prize title</div>
                  <div className="col1 prize-type">Prize type</div>
                  <div className="col1 add-button">
                    <Button
                      className="info-button"
                      onClick={() => {
                        this.setState({
                          show_add_prize: true,
                        });
                      }}
                      style={{ width: "100%" }}
                    >
                      {/* {languagePack["admin_save"]} */}
                      <span
                        className={css`
                          @media only screen and (max-width: 1280px) {
                            display: none;
                          }
                        `}
                      >
                        {"Add prize"}
                      </span>
                      <Icon name="add" className="info-button-icon" />
                    </Button>
                  </div>
                </div>
                <div className="content-lines">
                  {this.state.prizes.map((item, index) => {
                    return (
                      <div
                        className="line"
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                        id={index}
                      >
                        <div className="col1 name">
                          <TFBPlayerWithAvatar
                            avatarUrl={baseUrl(
                              `uploads/competition/prizes/${item.id_competition_prize}.png`
                            )}
                            playerName={item.player_name}
                          />
                        </div>
                        <div className="col1 prize">{item.prize_name}</div>
                        <div className="col1 prize-type">
                          {item.prize_type +
                            (item.prize_type === "Tournament top scorer"
                              ? ` / ${item.prize_number} ${
                                  item.prize_number > 1 ? "goals" : "goal"
                                }`
                              : "")}
                        </div>
                        <div className="col1 add-button">
                          {/* <Icon name="add" color="green" /> */}
                          <Icon
                            name="close"
                            color="red"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                delete_prize_for_player: true,
                                id_prize_delete: item.id_competition_prize,
                              })
                            }
                          />
                        </div>
                      </div>
                    );
                  })}
                  {this.state.prizes.length == 0 && (
                    <div className="no-line">
                      {"There are no prizes added."}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Dialog
              open={this.state.show_add_prize}
              // onClose={() => this.setState({ show_add_prize: false })}
            >
              <DialogContent className="add-team-manual-wrapper">
                <AddPrize
                  closePopup={() => this.setState({ show_add_prize: false })}
                  getPrizes={this.getPrizes}
                  id_competition={this.props.match.params.id}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              open={this.state.delete_prize_for_player}
              // onClose={() => this.setState({ delete_prize_for_player: false })}
            >
              <DialogContent className="add-team-manual-wrapper">
                <div>
                  {
                    "Are you sure that you want to delete this prize for player?"
                  }
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.deletePrize}
                  style={{ backgroundColor: "#00d948", color: "white" }}
                >
                  {languagePack["delete_payment_button_delete"]}
                </Button>
                <Button
                  onClick={() => {
                    this.setState({
                      delete_prize_for_player: false,
                      id_prize_delete: "",
                    });
                  }}
                  color="var(--secondaryColor)"
                >
                  {languagePack["add_payment_button_cancel"]}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(PrizesPage);
