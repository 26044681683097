import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

const DeleteEvaluation = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, data } = props;

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("report/delete_evaluation");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.players_evaluations,
                languagePack.success_delete_evaluation
            );
        } else {
            showNotification(
                "danger",
                languagePack.players_evaluations,
                languagePack.fail_delete_evaluation
            );
        }
    };

    const deleteCategory = () => {
        const payload = {
            id_evaluation: data?.id_evaluation_test,
        };

        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteCategory(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_evaluation"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_evalaution_message}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(DeleteEvaluation);
