import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

const DeleteTrainingSection = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, section } = props;

    const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("training/delete_training_section_2");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                languagePack.training_sections,
                languagePack.section_deleted_successfully
            );
        } else {
            showNotification(
                "danger",
                languagePack.training_sections,
                languagePack.section_deleted_failed
            );
        }
    };

    const deleteSection = () => {
        const payload = {
            id_training_section: section.id_training_section,
        };
        deleteRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => deleteSection(),
        "red",
        "delete",
        languagePack.admin_delete,
        {},
        true,
        false,
        loadingDeleteRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["delete_user"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {languagePack.delete_section_text}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
}))(DeleteTrainingSection);
