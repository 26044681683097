export const colorCoding = {
    greenShape: "rgba(69 214 149 / 0.5)",
    greenFigure: "rgb(69 214 149)",
    blueShape: "rgba(63 149 220 / 0.5)",
    blueFigure: "rgb(63 149 220)",
    yellowShape: "rgba(236 196 52 / 0.5)",
    yellowFigure: "rgb(236, 196, 52)",
    redShape: "rgba(229 79 58 / 0.5)",
    redFigure: "rgb(229 79 58)",
    blackShape: "rgba(47 49 60 / 0.5)",
    blackFigure: "rgb(47, 49, 60)",
    greyShape: "rgba(213 213 213 / 0.5)",
    greyFigure: "rgb(213, 213, 213)",
};