import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { logout } from "./../../../utils/api";
import moment from "moment";
import { Icon, Flag, Button, Dropdown, TextArea } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import "./StaffLicense.scss";
import AddLicenseFile from "./AddLicenseFile";

import dateFormat from "dateformat";
import ExpandedImage from "../../SiteAdmin/Photos/ExpandedImage";
import { baseUrl } from "../../../utils/utilFunctions";

class StaffLicense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedImage: false,
      expandedImageSrc: "",

      addFilePopup: false,

      year: "toate",
      payment_years: [],
    };
  }

  render() {
    const { languagePack } = this.props;

    return (
      <div className="card card-custom" style={{ height: "inherit" }}>
        <div className="license-container">
          <div className="staff-license-header">
            <div className="staff-license-header-title">
              {languagePack.staff_license}
            </div>
            <div className="buttons">
              <Button
                className="ui button add-team-button"
                onClick={() =>
                  this.setState({
                    addFilePopup: true,
                  })
                }
              >
                {languagePack.add_new_file}
                <Icon
                  name="add"
                  size="small"
                  className="add-team-button-icon"
                />
              </Button>
            </div>
          </div>

          {this.props.license_type == "Nicio licență" ||
          this.props.license_type == null ||
          this.props.license_type == "undefined" ? (
            <div className="no-access-placeholder-staff">
              <Icon name="search minus" color="black" size="big" />
              <div className="no-access-text">
                {languagePack.no_data_active_license}
              </div>
            </div>
          ) : (
            <div className="licenses-table">
              <div className="license-header-row">
                <div className="license-staff-name col-header">
                  {languagePack.type}
                </div>
                <div className="date-expire-license col-header">
                  {languagePack.date_of_examination}
                </div>
                <div className="date-license col-header">
                  {languagePack.license_file}
                </div>
              </div>
              <div
                className="license-row"
                style={{
                  backgroundColor: "#fafafa",
                }}
              >
                <div className="license-staff-name row1">
                  {this.props.license_type}
                </div>
                <div className="date-expire-license row1">
                  {this.props.date_expire_license == "" ||
                  this.props.date_expire_license == null
                    ? "-"
                    : this.props.date_expire_license}
                </div>
                <div className="date-license row1">
                  <div style={{ cursor: "pointer" }}>
                    {this.props.license_document == "udnefined" ||
                    this.props.license_document == null ? (
                      "-"
                    ) : (
                      <a
                        href={baseUrl(`club/download_staff_license_document?id_staff=${this.props.id_staff}`)}
                      >
                        <Icon
                          name="download"
                          size="small"
                          style={{ color: "#666666" }}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* ADAUGARE FISIER LICENTA */}
        <Dialog open={this.state.addFilePopup}>
          <DialogContent className="add-file-container-all">
            <AddLicenseFile
              // id_team={this.props.match.params.id}
              closePopup={() => {
                this.setState({
                  addFilePopup: false,
                });
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(StaffLicense);
