import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "./../../../utils/api";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import DataTable from "react-data-table-component";
import CircularLoader from "../../../components/reusable/Loader";
import { Redirect } from "react-router-dom";
import AddPlayersToPaymentRequest from "./AddPlayersToPaymentRequest";
import EditPlayerAmountToPay from "./EditPlayerAmountToPay";
import AddPaymentForPlayer from "./AddPaymentForPlayer";
import DeletePlayerPaymentRequest from "./DeletePlayerPaymentRequest";
import _ from "lodash";

class PaymentRequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      has_access: false,
      redirect_to_list: false,
      id: props.match.params.id,
      is_fetching: true,
      table_rows: [],
      data: {},

      add_players_to_request_dialog: false,

      edit_player_amount_to_pay_for_request_dialog: false,
      editing_player_request: {},

      add_payment_for_player: false,
      adding_payment_request_player: {},

      delete_payment_request_for_player: false,
      deleting_payment_request_for_player: {},
    };
  }

  componentDidMount = () => {
    this.hasAccess();
  };

  hasAccess = () => {
    const { token } = this.props;
    axios
      .get(
        `finances/has_access_to_payment_request?id_entity=${this.state.id}&token=${this.props.token}`
      )
      .then((res) => {
        if (res.data.has_access == 1) {
          this.setState(
            {
              has_access: true,
              is_fetching: true,
            },
            this.getPaymentRequestLines
          );
        } else {
          this.setState({
            redirect_to_list: true,
          });
        }
      })
      .catch((err) => console.log(err));
    const year = new Date().getFullYear();
    axios
      .get(`finances/get_players_payments?year=${year}&token=${token}`)
      .then((res) => {
        if (Number(res.data.success) === 1)
          this.setState({ players: res.data.list });
      });
  };

  getPaymentRequestLines = () => {
    axios
      .get(`finances/get_payment_request_data`, {
        params: {
          id_entity: this.state.id,
          token: this.props.token,
        },
      })
      .then((res) => {
        if (res.data.success == 1) {
          this.setState({
            table_rows: res.data.players,
            last_number_receipt: res.data.last_number_receipt,
            data: res.data.data,
            is_fetching: false,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  openAddPlayersToPaymentRequestDialog = () => {
    this.setState({
      add_players_to_request_dialog: true,
    });
  };

  closeAddPlayersToPaymentRequestDialog = () => {
    this.setState({
      add_players_to_request_dialog: false,
    });
  };

  openEditPlayerAmountToPayForPaymentRequestDialog = (data) => {
    this.setState({
      edit_player_amount_to_pay_for_request_dialog: true,
      editing_player_request: data,
    });
  };

  closeEditPlayerAmountToPayForPaymentRequestDialog = () => {
    this.setState({
      edit_player_amount_to_pay_for_request_dialog: false,
      editing_player_request: {},
    });
  };

  openAddPaymentForPlayerDialog = (data) => {
    this.setState({
      add_payment_for_player: true,
      adding_payment_request_player: data,
    });
  };

  closeAddPaymentForPlayerDialog = () => {
    this.setState({
      add_payment_for_player: false,
      adding_payment_request_player: {},
    });
  };

  openDeletePaymentRequestForPlayerDialog = (data) => {
    this.setState({
      delete_payment_request_for_player: true,
      deleting_payment_request_for_player: data,
    });
  };

  closeDeletePaymentRequestForPlayerDialog = () => {
    this.setState({
      delete_payment_request_for_player: false,
      deleting_payment_request_for_player: {},
    });
  };

  render() {
    const { languagePack } = this.props;

    if (this.state.redirect_to_list) {
      return <Redirect to="/financial/payment-request" />;
    }

    const columns = [
      {
        name: languagePack["players"],
        selector: "name",
        style: {
          backgroundColor: "#FAFAFA",
          fontFamily: "Poppins",
          fontWeight: 700,
          fontSize: 14,
          color: "#666666",
        },
        maxWidth: "fit-content",
        minWidth: "200px",
      },
      {
        name: "",
        selector: "actions",
        center: true,
        cell: (row) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <div
                className="cursor-pointer"
                onClick={() => this.openAddPaymentForPlayerDialog(row)}
              >
                <TFBIcon name="add" color="green" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  this.openEditPlayerAmountToPayForPaymentRequestDialog(row)
                }
              >
                <TFBIcon name="edit" color="gray" />
              </div>
              <div
                className="cursor-pointer"
                onClick={() =>
                  this.openDeletePaymentRequestForPlayerDialog(row)
                }
              >
                <TFBIcon name="delete" color="gray" />
              </div>
            </div>
          );
        },
        width: "150px",
      },
      {
        center: true,
        name:
          languagePack.amount_to_pay + " (" + this.state.data?.currency + ")",
        selector: "amount",
        cell: (row) => <div style={{ textAlign: "center" }}>{row.amount}</div>,
        conditionalCellStyles: [
          {
            when: (row) => row.full_paid == 1,
            style: {
              backgroundColor: "#00D948",
            },
          },
          {
            when: (row) => row.full_paid == 0,
            style: {
              backgroundColor: "#D91200",
            },
          },
        ],
        maxWidth: "150px",
        minWidth: "150px",
        style: {
          textTransform: "lowercase !important",
          color: "white",
          fontWeight: 700,
          fontSize: 14,
        },
      },
      {
        center: true,
        name:
          languagePack.amount_received + " (" + this.state.data?.currency + ")",
        selector: "amount_received",
        cell: (row) => (
          <div style={{ textAlign: "center" }}>
            {[...row.payments].length > 0
              ? [...row.payments].map((elem, index) => (
                  <div key={"payment-request-player-payment-" + index}>
                    {elem.amount_paid +
                      " - " +
                      languagePack.payment_types[elem.payment_type_label]}
                    <br />
                  </div>
                ))
              : 0}
          </div>
        ),
        conditionalCellStyles: [
          {
            when: (row) => row.full_paid == 1,
            style: {
              backgroundColor: "#00D948",
            },
          },
          {
            when: (row) => row.full_paid == 0,
            style: {
              backgroundColor: "#D91200",
            },
          },
          {
            when: (row) => row.full_paid == 0 && row.amount_paid > 0,
            style: {
              backgroundColor: "#FF9500",
            },
          },
        ],
        maxWidth: "250px",
        minWidth: "150px",
        style: {
          textTransform: "lowercase !important",
          color: "white",
          fontWeight: 700,
          fontSize: 14,
        },
      },
    ];

    return (
      <>
        {this.state.has_access && (
          <TFBCard>
            <TFBCardHeader title={this.state.data?.title}>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={this.openAddPlayersToPaymentRequestDialog}
              >
                {languagePack.add_players}
              </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
              <DataTable
                noHeader
                center
                columns={columns}
                data={this.state.table_rows}
                highlightOnHover
                customStyles={customStyles}
                progressPending={this.state.is_fetching}
                progressComponent={<CircularLoader />}
                noDataComponent={
                  <TFBPlaceholder text={languagePack.no_players} />
                }
              />
            </TFBCardBody>

            {/* ----------------- ADD PLAYERS TO PAYMENT REQUEST ----------------*/}
            {this.state.add_players_to_request_dialog && (
              <AddPlayersToPaymentRequest
                isOpenPopup={this.state.add_players_to_request_dialog}
                closePopup={this.closeAddPlayersToPaymentRequestDialog}
                refreshList={this.getPaymentRequestLines}
                exceptedPlayers={this.state.table_rows.map(
                  (player) => player.id_player
                )}
                id={this.state.id}
                paymentRequestData={this.state.data}
              />
            )}

            {/* ----------------- EDIT PLAYER AMOUNT TO PAY FOR PAYMENT REQUEST ----------------*/}
            {this.state.edit_player_amount_to_pay_for_request_dialog && (
              <EditPlayerAmountToPay
                isOpenPopup={
                  this.state.edit_player_amount_to_pay_for_request_dialog
                }
                closePopup={
                  this.closeEditPlayerAmountToPayForPaymentRequestDialog
                }
                refreshList={this.getPaymentRequestLines}
                paymentRequestData={this.state.editing_player_request}
              />
            )}

            {/* ----------------- ADD PAYMENT FOR PLAYER ----------------*/}

            {this.state.add_payment_for_player && (
              <AddPaymentForPlayer
                openPopup={this.state.add_payment_for_player}
                closePopup={this.closeAddPaymentForPlayerDialog}
                refreshList={this.getPaymentRequestLines}
                paymentRequestData={this.state.adding_payment_request_player}
                lastNrReceipt={Number(this.state.last_number_receipt)}
                requestTitle={this.state.data.title}
              />
            )}

            {/* ----------------- DELETE PAYMENT REQUEST FOR PLAYER ----------------*/}

            {this.state.delete_payment_request_for_player && (
              <DeletePlayerPaymentRequest
                isOpenPopup={this.state.delete_payment_request_for_player}
                closePopup={this.closeDeletePaymentRequestForPlayerDialog}
                refreshList={this.getPaymentRequestLines}
                id={
                  this.state.deleting_payment_request_for_player
                    ?.id_payment_request
                }
                id_player={
                  this.state.deleting_payment_request_for_player?.id_player
                }
              />
            )}
          </TFBCard>
        )}
      </>
    );
  }
}

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "white !important",
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: 13,
      color: "#B2B2B2",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  header: {
    style: {
      marginBottom: "20px",
    },
  },
  rows: {
    style: {
      minHeight: 60,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
};
const mapStateToProps = ({ layoutService: { languagePack }, auth }) => ({
  languagePack,
  token: auth?.user?.token,
  currentUser: auth.user,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentRequestDetails);
