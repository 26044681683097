import React, { useState } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import CurrencyPicker from "./CurrencyPicker";

const EditPaymentRequest = (props) => {
  const {
    languagePack,
    closePopup,
    isOpenPopup,
    refreshList,
    paymentRequestData,
  } = props;

  const [editData, setEditData] = useState({
    title: paymentRequestData?.title ?? "",
  });

  const editPaymentrequest = () => {
    const payload = {
      id_entity: paymentRequestData?.id_payment_request,
      title: editData?.title,
    };

    axios
      .post("finances/edit_payment_request", payload)
      .then((res) => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.payment_requests,
            message: languagePack.success_edit_payment_request_amount,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.payment_requests,
            message: languagePack.fail_edit_payment_request_amount,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch((e) => console.log(e));
  };

  const onChangeInput = (e) => {
    setEditData({ ...editData, ...{ title: e.target.value } });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack.edit_payment_request}
      open={isOpenPopup}
      closePopup={closePopup}
      btnLeft={() => (
        <TFBButton
          color="darkGray"
          renderIcon={() => <TFBIcon name="cancel" />}
          onClick={closePopup}
        >
          {languagePack.admin_cancel}
        </TFBButton>
      )}
      btnRight={() => (
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="check" />}
          disabled={String(editData.title).length == 0}
          onClick={editPaymentrequest}
        >
          {languagePack.apply}
        </TFBButton>
      )}
    >
      <div className="pop-up-section">
        <div className="pop-up-section-body">
          <div className="pop-up-row">
            <label className="row-label">
              {languagePack.payment_request_title}
            </label>
            <br />
            <input
              className="row-input"
              placeholder={languagePack.enter_payment_request_title}
              value={editData.title}
              name="title"
              onChange={onChangeInput}
            />
          </div>
        </div>
      </div>
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditPaymentRequest);
