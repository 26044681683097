import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";

import _ from "lodash";
import moment from "moment";

import styles from "./EditTrainingSessionPopup.module.scss";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import CircularLoader from "../../../components/reusable/Loader";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";

import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import {
  showNotification,
  useAxiosPost,
} from "../../../components/customHooks/useAxiosPost";
import { TEditTrainingSessionPopup } from "../../../components/design-system/types";
import TFBInput from "../../../components/design-system/Input/TFBInput";

const EditTrainingSessionPopup = ({
  openPopup,

  filters,
  refetchSections,
  token,
  sectionId,
  closePopup,
  languagePack,
}: TEditTrainingSessionPopup) => {
  const initialState = {
    img: "",
    addImg: false,
    videoUrl: "",
    token: token,
    sectionName: "",
    sectionDescription: "",
    age_group: [],
    section_type: [],
    accessibility: null,
    players_no: 0,
    goalkeepers_no: 0,
    sectionTags: [],
    openEditTags: false,
  };

  function reducer(state: any, action: any) {
    switch (action.type) {
      case "changeName":
        return { ...state, sectionName: action.payload };
      case "changeDescription":
        return { ...state, sectionDescription: action.payload };

      case "addImage":
        return {
          ...state,
          img: action.payload,
          addImg: action.payload ? true : false,
        };
      case "noFile":
        return { ...state, addImg: false };
      case "removeImage":
        return { ...state, img: null, addImg: false };
      case "setSectionTags":
        return { ...state, sectionTags: action.payload };
      case "updateAge_group":
        return { ...state, age_group: action.payload };
      case "updateSection_type":
        return { ...state, section_type: action.payload };
      case "updateAccessibility":
        return { ...state, accessibility: action.payload };
      case "updatePlayersNo":
        return { ...state, players_no: action.payload ?? 0 };
      case "updateGoalkeepersNo":
        return { ...state, goalkeepers_no: action.payload ?? 0 };
      case "openEditTags":
        return { ...state, openEditTags: true };
      case "closeEditTags":
        return { ...state, openEditTags: false };
      case "openImagePopup":
        return { ...state, openImage: true };
      case "closeImagePopup":
        return { ...state, openImage: false };
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const { data: sectionData, loading } = useAxiosGet(
    `training/get_training_section?token=${token}&id_training_section=${sectionId}`
  );

  const sectionTagsToPost = [
    ..._.map(state.age_group, (t): object => {
      return { id_category: 1, id_tag: Number(t?.value) };
    }),
    ..._.map(state.section_type, (t): object => {
      return { id_category: 2, id_tag: Number(t?.value) };
    }),
    { id_category: 3, id_tag: state.accessibility?.value },
  ];

  useEffect(() => {
    dispatch({
      type: "changeName",
      payload: sectionData?.["data"]?.["section_name"],
    });
    dispatch({
      type: "changeDescription",
      payload: sectionData?.["data"]?.["section_description"],
    });
    dispatch({
      type: "addImage",
      payload: sectionData?.["data"]?.["section_img"],
    });
    dispatch({
      type: "setSectionTags",
      payload: sectionData?.["data"]?.["tags"]?.["list"],
    });
    dispatch({
      type: "updatePlayersNo",
      payload: sectionData?.["data"]?.["no_of_players"],
    });
    dispatch({
      type: "updateGoalkeepersNo",
      payload: sectionData?.["data"]?.["no_of_gks"],
    });
    dispatch({
      type: "updateAccessibility",
      payload: _.map(sectionData?.["data"]?.["tags"]?.["list"], (tag) => {
        return Number(tag?.["id_tag_category" as keyof typeof tag]) === 3
          ? tag
          : null;
      }),
    });
    dispatch({
      type: "updateSection_type",
      payload: _.map(sectionData?.["data"]?.["tags"]?.["list"], (tag) => {
        return Number(tag?.["id_tag_category" as keyof typeof tag]) === 2
          ? tag
          : null;
      }),
    });
    dispatch({
      type: "updateAge_group",
      payload: _.map(sectionData?.["data"]?.["tags"]?.["list"], (tag) => {
        return Number(tag?.["id_tag_category" as keyof typeof tag]) === 1
          ? tag
          : null;
      }),
    });
  }, [sectionData]);

  const { loading: postLoading, postData } = useAxiosPost(
    "training/update_training_section"
  );

  function handleChangeName(e: any) {
    dispatch({ type: "changeName", payload: e.target.value });
  }
  function handleChangeDescription(e: any) {
    dispatch({ type: "changeDescription", payload: e.target.value });
  }

  function handleResponse(response: { success: number }) {
    if (response.success === 1) {
      refetchSections && refetchSections();
      closePopup();
      showNotification(
        "success",
        languagePack.training_sections,
        languagePack.edit_training_section_success
      );
    } else {
      showNotification(
        "danger",
        languagePack.training_sections,
        languagePack.edit_training_section_fail
      );
    }
  }

  const getImageFromFile = (files: any, file: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      dispatch({ type: "addImage", payload: reader.result });
    };
    reader.onerror = function(error) {
      console.log("Error: ", error);
    };
  };

  const onChangeFile = (e: any) => {
    if (_.size(e.target.files) > 0) {
      getImageFromFile(e.target.files, e.target.files[0]);
    } else {
      dispatch({ action: "noFile" });
    }
  };

  const payload = {
    token: token,
    id_section: sectionId,
    section: {
      section_name: state.sectionName,
      section_description: state.sectionDescription,
      no_of_players: state.players_no,
      no_of_gks: state.goalkeepers_no,
    },
    add_img: state.addImg,
    img_base64: state.img,
    tags: sectionTagsToPost,
  };
  function saveEditSection() {
    postData(payload, handleResponse);
  }

  const handleChangeSelect = (selected: any, value: any) => {
    dispatch({
      type: `update${value.name[0].toUpperCase() + value.name.slice(1)}`,
      payload: selected,
    });
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["Edit"] + " " + languagePack.section.toLowerCase()}
      open={true}
      closePopup={closePopup}
      fullWidth
      maxWidth="sm"
      btnRight={() => (
        <TFBButton
          onClick={saveEditSection}
          color="green"
          renderIcon={() => <TFBIcon name="save" />}
          loading={postLoading}
        >
          {languagePack.admin_save_changes}
        </TFBButton>
      )}
      btnLeft={() => (
        <TFBButton
          onClick={closePopup}
          renderIcon={() => <TFBIcon name="x-small" />}
        >
          {languagePack.admin_cancel}
        </TFBButton>
      )}
    >
      {!loading ? (
        <div className={styles.wrapper}>
          <p className={styles.addedBy}>
            {sectionData?.["data"]?.["added_by"]
              ? languagePack.added_by + ":"
              : languagePack.added}{" "}
            {sectionData?.["data"]?.["added_by"]
              ? sectionData?.["data"]?.["added_by"] + ", "
              : ""}{" "}
            {languagePack.added_on_date}:{" "}
            {moment(sectionData?.["data"]?.["date_add"]).format("DD.MM.yyyy")}
          </p>
          <TFBInput
            value={state.sectionName}
            onChange={handleChangeName}
            type="text"
            fullWidth={true}
            label={languagePack.name}
          />

          {/* //// Labels ////// */}
          <div className={styles.labelsDiv}>
            <span style={{ marginTop: "0px" }} className={styles.subtitle}>
              {languagePack.Section_labels}
            </span>
            <TFBSelect
              label={languagePack.age_group}
              isMulti={true}
              options={filters?.[0]?.children ?? []}
              value={state.age_group}
              name="age_group"
              onChange={(selected, value) =>
                handleChangeSelect(selected, value)
              }
            />
            <TFBSelect
              label={languagePack.section_type}
              isMulti={true}
              options={filters?.[1]?.children ?? []}
              value={state.section_type}
              name="section_type"
              onChange={(selected, value) =>
                handleChangeSelect(selected, value)
              }
            />
            <TFBSelect
              label={languagePack.accessibility}
              options={filters?.[2]?.children ?? []}
              value={state.accessibility}
              isClearable={true}
              name="accessibility"
              onChange={(selected, value) =>
                handleChangeSelect(selected, value)
              }
            />
            <div className={styles.numberInputFilters}>
              <TFBInput
                fullWidth={true}
                label={languagePack.number_of_players}
                value={state.players_no}
                onChange={(e) =>
                  dispatch({ type: "updatePlayersNo", payload: e.target.value })
                }
                type="number"
              />
              <TFBInput
                fullWidth={true}
                value={state.goalkeepers_no}
                onChange={(e) =>
                  dispatch({
                    type: "updateGoalkeepersNo",
                    payload: e.target.value,
                  })
                }
                label={languagePack.number_of_goalkeepers}
                type="number"
              />
            </div>
          </div>
          <div className={styles.descriptionDiv}>
            <div className={styles.subtitle}>
              {languagePack.Section_description}
            </div>

            <TFBTextArea
              style={{
                outline: "none",
                border: "2px solid #f2f2f2 ",
                minHeight: "250px",
              }}
              value={state.sectionDescription}
              onChange={handleChangeDescription}
              label={languagePack.description}
            ></TFBTextArea>
          </div>
          <div className={styles.mediaDiv}>
            {" "}
            <div className={styles.subtitle}>{languagePack.Media}</div>
            <div
              className={styles.fileInputs}
              style={{ justifyContent: "start" }}
            >
              {state.img ? (
                <div className={styles.imgDiv}>
                  <TFBIconButton
                    name="x-small"
                    onClick={() => dispatch({ type: "removeImage" })}
                    style={{
                      height: "20px",
                      borderRadius: "10px",
                      padding: "0px",
                      width: "20px",
                      position: "absolute",
                      top: "-10px",
                      right: "-17.5px",
                    }}
                  />
                  <img
                    style={{ height: "100%" }}
                    src={state.img}
                    alt="section"
                    onClick={() => dispatch({ type: "openImagePopup" })}
                  />
                </div>
              ) : (
                <TFBFileInput
                  style={{ width: "45%" }}
                  inputFileStyle={{
                    height: "96px",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "12.5px",
                  }}
                  accept=".png, .jpg, .jpeg, .webp"
                  onChange={onChangeFile}
                  files={state.img}
                  label={languagePack.Image}
                  placeholder={languagePack.upload_image}
                ></TFBFileInput>
              )}
              {/* {state.videoUrl ? (
            <div className={styles.imgDiv}>
              <TFBIconButton
                name="x-small"
                onClick={() => dispatch({ type: "removeImage" })}
                style={{
                  height: "20px",
                  borderRadius: "10px",
                  padding: "0px",
                  width: "20px",
                  position: "absolute",
                  top: "-10px",
                  right: "-17.5px",
                }}
              />
              <img style={{ width: "100%" }} src={state.img} alt="section" />
            </div>
          ) : (
            <TFBFileInput
              style={{ width: "45%" }}
              inputFileStyle={{
                height: "96px",
                flexDirection: "column",
                justifyContent: "center",
                gap: "12.5px",
              }}
              label={languagePack.Video}
              placeholder={languagePack.upload_video}
            ></TFBFileInput>
          )} */}
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
            className={styles.buttonsDiv}
          ></div>
          {/* <TFBButton
          style={{ width: "45%" }}
          renderIcon={() => <TFBIcon name="edit" />}
        >
          {" "}
          {languagePack.Create_new_tactic_board}{" "}
        </TFBButton> */}
        </div>
      ) : (
        <CircularLoader />
      )}
      {state.openImage && (
        <TFBDialog
          open
          fullWidth={true}
          closePopup={() => {
            dispatch({ type: "closeImagePopup" });
          }}
          maxWidth="lg"
          title={sectionData?.["data"]?.["section_name"]}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{ width: "70%" }}
            src={state.img}
            alt="training section"
          ></img>
        </TFBDialog>
      )}
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }: any) => ({
  languagePack: layoutService.languagePack,
  token: auth?.user?.token,
  currentUser: auth.user,
}))(EditTrainingSessionPopup);
