import React from "react";
import _ from "lodash";
import { Dialog, DialogContent } from "@material-ui/core";

import "./Matches.scss";
import ManualLastMatches from "./List/ManualLastMatches";
import AddMatchFormDirect from "./AddMatch/AddMatchFormDirect";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBDropdown from "../../../components/design-system/Dropdown/TFBDropdown";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import { useMatches } from "./MatchesContext";
import CircularLoader from "../../../components/reusable/Loader";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import useInfiniteScroll from "../../../components/customHooks/useInfiniteScroll";

export function Matches() {
  const {
    languagePack,
    teams_dropdown,
    selected_team,
    onChangeDropdown,
    onAddMatchPopup,
    onSetActivePast,
    onSetActiveFuture,
    activeItem,
    refetchMatches,
    refreshTimes,
    addMatchPopup,
    onCloseAddMatchForm,
    onRefreshList,
    has_more,
    matches,
    loadingMatches,
  } = useMatches();

  const { loaderRef } = useInfiniteScroll({
    refetch: !loadingMatches && has_more ? refetchMatches : () => {},
  });

  return (
    <TFBCard>
      <TFBCardHeader title={languagePack["fixtures_schedule"]}>
        <TFBDropdown
          selection
          options={teams_dropdown}
          value={selected_team}
          className="team-dropdown"
          name="selected_team"
          onChange={onChangeDropdown}
          placeholder={languagePack["select_team"]}
        />
        <TFBButton
          color="green"
          renderIcon={() => <TFBIcon name="add" color="white" />}
          onClick={onAddMatchPopup}
        >
          {languagePack["add_match"]}
        </TFBButton>
      </TFBCardHeader>
      <TFBCardBody>
        <div className="matches-menu-options">
          <div
            className="matches-option"
            onClick={onSetActivePast}
            style={{
              backgroundColor: activeItem === "past" ? "#fafafa" : "",
            }}
          >
            <div>{languagePack["past_matches"]}</div>
          </div>
          <div
            className="matches-option"
            onClick={onSetActiveFuture}
            style={{
              backgroundColor: activeItem === "future" ? "#fafafa" : "",
            }}
          >
            <div>{languagePack["future_matches"]}</div>
          </div>
        </div>

        {_.size(matches) > 0 && (
          <ManualLastMatches
            id_team={selected_team}
            onRefreshList={refetchMatches}
            refreshTimes={refreshTimes}
          />
        )}

        <Dialog open={addMatchPopup} fullWidth maxWidth="sm">
          <DialogContent className="add-team-manual-wrapper">
            <AddMatchFormDirect
              closePopup={onCloseAddMatchForm}
              getMatches={onRefreshList}
            />
          </DialogContent>
        </Dialog>
        {_.size(matches) === 0 && loadingMatches === false && (
          <TFBPlaceholder
            text={languagePack["no_matches_for_selected_options"]}
          />
        )}
        {<div ref={loaderRef}></div>}
        {loadingMatches && <CircularLoader />}
      </TFBCardBody>
    </TFBCard>
  );
}
