import React, { createContext } from 'react';

import useAxiosGet from '../../../components/customHooks/useAxiosGet';

export const TrainingSectionsContext = createContext();

export const TrainingSectionsProvider = ({ children, token }) => {

    const { data: pitchNomenclature, loading: loadingPitchNomenclature } = useAxiosGet(`training/get_training_board_pitch_nomenclature?token=${token}`);

    return (
        <TrainingSectionsContext.Provider value={{
            pitchNomenclature, loadingPitchNomenclature
        }}>
            {children}
        </TrainingSectionsContext.Provider>
    );
}