import React, { useState, useEffect } from "react";
import axios from "axios";
import { store } from "react-notifications-component";
import "./MedicalCertificates.scss";
import { connect } from "react-redux";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBSelect from "../../../components/design-system/TFBSelect/TFBSelect";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import moment from "moment";

function AddMedicalCertificate(props) {
  const [certificateDate, setCertificateDate] = useState(null);
  const [player, setPlayer] = useState(null);
  const [players, setPlayers] = useState([]);
  const [certificate, setCertificate] = useState([]);

  const { languagePack, closePopup, isOpenPopup, id, token, currentUser, refreshList } = props;

  useEffect(() => {
    getPlayers();
  }, []);

  const getPlayers = () => {
    axios
      .get(`club/get_players_for_select`, {
        params: {
          id_team: id,
          token: token,
        },
      })
      .then((res) => {
        setPlayers(res.data.players);
      })
      .catch((err) => console.log(err));
  }

  const handleChangeCertificateDate = (value) => {
    setCertificateDate(value);
  }

  const submitNewCertificate = () => {
    const data = new FormData();
    data.append("id_club", currentUser.id_club);
    data.append("id_entity", player?.value);
    data.append("type", "medical_certificate");
    data.append("date_examination", moment(certificateDate).format("YYYY-MM-DD"));
    if (certificate.length > 0) {
      data.append("certificate_img", certificate[0]);
    }

    axios
      .post(`club/add_medical_certificate_2`, data)
      .then((res) => {
        closePopup();
        if (res.data?.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack["medical_certificates"],
            message: languagePack["documents_upload_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack["medical_certificates"],
            message: res.data.error,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      });
  }

  const handleCertificateFileChange = (event) => {
    setCertificate(event.target.files);
  }

  return (
    <div className="add-team-container">
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.add_certificate}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            disabled={player == null || certificateDate == null}
            onClick={submitNewCertificate}
          >
            {languagePack.admin_add}
          </TFBButton>
        )}
      >
        <div className="pop-up-section">
          <div className="pop-up-section-body">
            <div className="pop-up-row">
              <TFBSelect
                label={languagePack.player}
                placeholder={languagePack.select_player}
                options={players}
                value={player}
                onChange={(selected) => setPlayer(selected)}
              />
            </div>
            <div className="pop-up-row">
              <TFBDatePicker
                label={languagePack.date_of_certificate_examination}
                placeholder={languagePack.date_of_certificate_examination}
                value={certificateDate}
                onChange={handleChangeCertificateDate}
                style={{ width: "100%" }}
              />
            </div>
            <div className="pop-up-row">
              <TFBFileInput
                label={languagePack.file}
                accept="image/jpg, image/png, image/jpeg"
                placeholder={languagePack.gallery_upload_select_file}
                onChange={handleCertificateFileChange}
                files={Array.from(certificate).map(elem => elem.name)}
              />
            </div>
          </div>
        </div>
      </TFBDialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(AddMedicalCertificate);
