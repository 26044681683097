import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Redirect, useLocation } from "react-router-dom";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import CircularLoader from "../../../components/reusable/Loader";

const EmailMobileUserActivation = (props) => {

    const { languagePack } = props;

    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            id_user_external: null,
            activation_key: null,
            redirect_to_user_list: false,
        }
    );

    useEffect(() => {
        const id_user_external = urlParams.get("id_user_external");
        const activation_key = urlParams.get("activation_key");

        if (id_user_external && activation_key) {
            updateState({
                id_user_external: id_user_external,
                activation_key: activation_key,
            });
            activateUser(id_user_external, activation_key);
        } else {
            updateState({ redirect_to_user_list: true });
            showNotification(
                "danger",
                languagePack.activate_user,
                languagePack.no_valid_user_key,
            );
        }
    }, []);

    const { postData: activateRequest } = useAxiosPost("parentsApp/activate_user_external_by_email");

    const activateUser = (id_user_external, activation_key) => {
        const payload = {
            id_user_external: id_user_external,
            activation_key: activation_key,
        };
        activateRequest(payload, handleResponse);
    }

    const handleResponse = (response) => {
        updateState({ redirect_to_user_list: true });
        if (response?.success == 1) {
            showNotification(
                "success",
                languagePack.activate_user,
                languagePack.notif_activate_mobile_user_success_message,
            );
        } else {
            if (response?.error_code) {
                showNotification(
                    "danger",
                    languagePack.activate_user,
                    languagePack[response.error_code],
                );
            } else {
                showNotification(
                    "danger",
                    languagePack.activate_user,
                    languagePack.notif_activate_mobile_user_failed_message,
                );
            }
        }
    };

    if (state.redirect_to_user_list) {
        return <Redirect to="/application/users" />
    }

    return (
        <CircularLoader />
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EmailMobileUserActivation);
