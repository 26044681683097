import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import TFBInput from "../../../../components/design-system/Input/TFBInput";
import styles from "./EditTeamCompetition.module.scss";
import TFBSelect from "../../../../components/design-system/TFBSelect/TFBSelect";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import {
  baseUrl,
  removeAgeGroupFromTeamName,
} from "../../../../utils/utilFunctions";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import ExternTeamLogos from "../AddMatch/ExternTeamLogos";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

function EditTeamCompetition(props) {
  const {
    editedTeam,
    getTeams,
    currentUser,
    updateCompetitionDetails,
    updateCompetitionMatches,
    closePopup,
    languagePack,
  } = props;

  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      teamOptions: null,
      teamTab: Number(editedTeam.own_team) === 1 ? "club" : "new",
      editTeamId: editedTeam.id_manual_competition_team,
      newName: editedTeam.team_name,
      initialPoints: editedTeam.initial_points,
      teamImage:
        editedTeam.team_logo == null
          ? ""
          : baseUrl(
            `uploads/team_logos/${props.editedTeam.id_manual_competition_team}logo.png`
          ),
      idGroup: editedTeam.id_team,
      selectedGroup:
        Number(editedTeam.own_team) === 1
          ? {
            value: editedTeam.id_team,
            label: editedTeam.group_name,
          }
          : null,
      new_team_logos: [],
      new_team_selected_logo: 0,
      show_new_team_logos: false,
      loading_logos: false,
      logos_checkbox: false,
      new_team_uploaded_logo: "",
      old_logo_checkbox: editedTeam.logo_url === false ? false : true,
      hide_old_logo: false,
    }
  );

  const { data: teamsData, loading: loadingTeamsData } = useAxiosGet(
    baseUrl(
      `club/get_teams_nomenclature?id_club=${currentUser.id_club}&type=select&all=0`
    )
  );
  const {
    loading: loadingLogos,

    postData: postOpponentLogo,
  } = useAxiosPost(baseUrl("matches/get_team_logo"));

  const { loading: loadingEditTeams, postData: postEditTeams } = useAxiosPost(
    baseUrl("matches/edit_team_2")
  );

  useEffect(() => {
    setState({ teamOptions: teamsData?.list });
  }, [teamsData]);

  useEffect(() => {
    if (
      state.new_team_logos?.[state.new_team_selected_logo] ===
      editedTeam?.logo_url
    )
      setState({ old_logo_checkbox: true });

    if (state.new_team_uploaded_logo !== "")
      setState({ old_logo_checkbox: false });

    if (state.logos_checkbox) setState({ old_logo_checkbox: false });
  }, [
    editedTeam.logo_url,
    state.logos_checkbox,
    state.new_team_logos,
    state.new_team_selected_logo,
    state.new_team_uploaded_logo,
  ]);

  const getOpponentLogo = () => {
    const payload = {
      query: "logo " + removeAgeGroupFromTeamName(state.newName),
    };

    const responseHandler = (res) => {
      setState({
        new_team_logos: res?.slice(0, 3),
        logos_checkbox: false,
        old_logo_checkbox: false,
      });
    };

    postOpponentLogo(payload, responseHandler);
  };

  const handleRemoveUploadedLogo = () => {
    setState({ new_team_uploaded_logo: "" });
  };
  const handleSearchTeamLogo = () => {
    if (_.size(state.newName) >= 3) {
      setState({ hide_old_logo: true });
      getOpponentLogo();
      setState({ show_new_team_logos: true });
    }
  };

  const handleChangeSelectedLogo = (i) => {
    setState({ new_team_selected_logo: i });
  };

  const handleChangeOldLogoCheckbox = (e) =>
    setState({
      old_logo_checkbox: e.target.checked,
      new_team_uploaded_logo: "",
      logos_checkbox: false,
      new_team_selected_logo: 0,
    });

  const getImageFromFile = (files, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setState({ new_team_uploaded_logo: reader.result });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChangUploadedLogo = (e) => {
    if (_.size(e.target.files) > 0) {
      getImageFromFile(e.target.files, e.target.files[0]);
    } else {
      setState({ new_team_uploaded_logo: "" });
    }
  };

  const editTeam = () => {
    const payload = {
      id_manual_competition_team: editedTeam.id_manual_competition_team,
      team_name: state.newName,
      logo_type:
        (state.new_team_uploaded_logo === "" &&
          !state.new_team_logos?.[state.new_team_selected_logo] &&
          state.teamTab === "new") ||
          state.logos_checkbox ||
          (state.old_logo_checkbox && state.teamTab === "new")
          ? null
          : state.new_team_uploaded_logo === ""
            ? "url"
            : "base_64",
      logo_url:
        state.new_team_uploaded_logo === "" &&
          !state.logos_checkbox &&
          !state.old_logo_checkbox
          ? state.new_team_logos?.[state.new_team_selected_logo]
            ?.thumbnail_url ?? null
          : state.new_team_uploaded_logo === "" && state.teamTab === "club"
            ? `uploads/club_logos/${currentUser.id_club}.png`
            : null,
      logo_base_64:
        state.new_team_uploaded_logo === "" || state.logos_checkbox
          ? null
          : state.new_team_uploaded_logo,
      initial_points: state.initialPoints,
      own_team: state.teamTab === "club" ? 1 : 0,
      delete_logo: state.logos_checkbox && editedTeam.logo_url ? 1 : 0,
    };

    if (state.teamTab === "club") {
      payload.id_group = state.idGroup;
    } else {
      payload.id_group = null;
    }

    const responseHandler = (res) => {
      if (Number(res.success) === 1) {
        getTeams();
        updateCompetitionMatches();
        updateCompetitionDetails();
        closePopup();
        showNotification(
          "success",
          languagePack.competitions,
          languagePack.edit_team_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.competitions,
          languagePack.edit_team_fail
        );
      }
    };

    postEditTeams(payload, responseHandler);
  };
  const { renderBtn: renderBtnRight } = useRenderButton(
    editTeam,
    "green",
    "edit",
    languagePack["admin_save"],
    {},
    true,
    state.teamTab === "club" &&
    (state.selectedGroup?.value == null ||
      state.selectedGroup?.label == null),
    loadingEditTeams
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    closePopup,
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <TFBDialog
      maxWidth="md"
      closePopup={closePopup}
      btnRight={renderBtnRight}
      btnLeft={renderBtnLeft}
      style={{ width: "600px" }}
      open
      title={languagePack.editTeam}
    >
      <div className={styles.editTeamManualOptions}>
        <div
          className={styles.editTeamOption}
          onClick={() =>
            setState({ teamTab: "new", newName: "", selectedGroup: null })
          }
          style={{
            backgroundColor: state.teamTab === "new" ? "#fafafa" : "",
          }}
        >
          <div>{languagePack["new_team"]}</div>
        </div>
        <div
          className={styles.editTeamOption}
          onClick={() =>
            setState({ teamTab: "club", newName: "", selectedGroup: null })
          }
          style={{
            backgroundColor: state.teamTab === "club" ? "#fafafa" : "",
          }}
        >
          <div>{languagePack["clubs_team"]}</div>
        </div>
      </div>

      {state.teamTab === "new" && (
        <div className={styles.wrapper}>
          <div style={{ margin: "0px" }} className="team-name-logo-div">
            <TFBInput
              fullWidth
              value={state.newName}
              onChange={(e) => setState({ newName: e.target.value })}
              placeholder={languagePack["team_name"]}
              label={languagePack.team_name}
            />
            <TFBButton
              size="sm"
              style={{ height: "54px", width: "200px" }}
              onClick={handleSearchTeamLogo}
              renderIcon={() => <TFBIcon name="search" />}
              color="green"
              disabled={_.size(state.newName) < 3}
            >
              {languagePack.search_logo}
            </TFBButton>
          </div>
          <TFBInput
            value={state.initialPoints}
            style={{ width: "502px" }}
            onChange={(e) => setState({ initialPoints: e.target.value })}
            placeholder={languagePack.initial_points}
            label={languagePack.initial_points}
          />
          {(state.show_new_team_logos || editedTeam.logo_url) && (
            <>
              {!state.logos_checkbox && (
                <ExternTeamLogos
                  uploadedLogo={state.new_team_uploaded_logo}
                  onRemoveUploadedLogo={handleRemoveUploadedLogo}
                  onChangeFile={handleChangUploadedLogo}
                  loading={loadingLogos}
                  setSelectedLogo={handleChangeSelectedLogo}
                  selectedLogo={state.new_team_selected_logo}
                  backgroundColor="#fafafa"
                  thumbnails={
                    (editedTeam.logo_url && !state.hide_old_logo) ||
                      state.old_logo_checkbox
                      ? [editedTeam.logo_url]
                      : _.map(
                        state.new_team_logos,
                        (logo) => logo.thumbnail_url
                      )
                  }
                />
              )}
              <div className="add-checkbox-input extern-team-checkbox">
                <TFBCheckbox
                  label={languagePack.continue_without_external_team_logo}
                  checked={state.logos_checkbox}
                  onChange={(e) =>
                    setState({
                      logos_checkbox: e.target.checked,
                    })
                  }
                  mode="light"
                />
              </div>
            </>
          )}
          {editedTeam.logo_url && (
            <TFBCheckbox
              label={languagePack.continue_with_previous_logo}
              checked={state.old_logo_checkbox}
              onChange={handleChangeOldLogoCheckbox}
              mode="light"
            />
          )}
        </div>
      )}

      {state.teamTab === "club" && (
        <div className={styles.wrapper}>
          <TFBSelect
            options={state.teamOptions}
            isClearable
            value={state.selectedGroup}
            placeholder={languagePack.select_team}
            label={languagePack.team}
            isLoading={loadingTeamsData}
            onChange={(selected) => {
              if (!selected) return;
              setState({ selectedGroup: selected });
              setState({ newName: selected?.label });
              setState({ idGroup: selected?.value });
            }}
          />

          <TFBInput
            label={languagePack.name}
            placeholder={languagePack.type_team_name}
            fullWidth
            value={state.newName}
            onChange={(e) => setState({ newName: e.target.value })}
          />

          <TFBInput
            value={state.initialPoints}
            fullWidth
            label={languagePack.initial_points}
            onChange={(e) => setState({ initialPoints: e.target.value })}
            placeholder={languagePack.type_initial_points}
          />
        </div>
      )}
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(EditTeamCompetition);
