import React from "react";
import styles from "./Card.module.scss";
import CardHeader from "./TFBCardHeader";
import { TCard } from "../types";

const TFBCard = ({
  children,
  className,
  style,
  styleCardInner,
  classNameInner,
}: TCard) => {
  const classN = className ? className : "";
  return (
    <div
      className={`${styles.tfbCard} ${classN} tfb-card-custom`}
      style={style}
    >
      <div
        className={styles.inner + " " + classNameInner}
        style={styleCardInner}
      >
        {children}
      </div>
    </div>
  );
};

export default TFBCard;
