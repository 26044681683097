import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./Products.scss";
import AddProduct from "./AddProduct";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditProduct from "./EditProduct";
import DeleteProduct from "./DeleteProduct";
import TFBImageAvatar from "../../../components/design-system/ImageAvatar/TFBImageAvatar";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isFetching: true,

      open_add_dialog: false,

      open_edit_dialog: false,
      editing_product: {},

      open_delete_dialog: false,
      deleting_product: {},
    };
  }

  componentDidMount() {
    this.getProducts();
  }

  getProducts = () => {
    axios
      .get(`/product/${this.props.currentUser.id_club}/id_venue`)
      .then((res) => this.setState({ products: res.data, isFetching: false }))
      .catch((err) => console.log(err));
  };

  openAddProduct = () => {
    this.setState({ open_add_dialog: true })
  }

  closeAddProduct = () => {
    this.setState({ open_add_dialog: false })
  }

  openEditProduct = (product) => {
    this.setState({ open_edit_dialog: true, editing_product: product })
  }

  closeEditProduct = () => {
    this.setState({ open_edit_dialog: false, editing_product: {} })
  }

  openDeleteProduct = (product) => {
    this.setState({ open_delete_dialog: true, deleting_product: product })
  }

  closeDeleteProduct = () => {
    this.setState({ open_delete_dialog: false, deleting_product: {} })
  }

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.products}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={this.openAddProduct}
          >
            {languagePack.add_product}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.products.length > 0 ?
            <TableContainer>
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.product_image}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.product_name}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.product_description}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.product_price}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.product_status}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.products.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        {row.product_image == null ?
                          languagePack.without_image :
                          <TFBImageAvatar src={row.product_image} />
                        }
                      </TFBTableCell>
                      <TFBTableCell>
                        {row.product_name}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div dangerouslySetInnerHTML={{ __html: row.product_description }} />
                      </TFBTableCell>
                      <TFBTableCell>
                        {row.product_price}
                      </TFBTableCell>
                      <TFBTableCell>
                        {{ 1: languagePack.active, 0: languagePack.inactive }[row.product_status]}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => this.openEditProduct(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => this.openDeleteProduct(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_products} />
          }
        </TFBCardBody>

        {/* ------------------- ADD CATEGORY ---------------- */}
        {this.state.open_add_dialog &&
          <AddProduct
            isOpenPopup={this.state.open_add_dialog}
            closePopup={this.closeAddProduct}
            refreshList={this.getProducts}
          />
        }

        {/* ------------------- EDIT CATEGORY ---------------- */}
        {this.state.open_edit_dialog &&
          <EditProduct
            isOpenPopup={this.state.open_edit_dialog}
            closePopup={this.closeEditProduct}
            refreshList={this.getProducts}
            productData={this.state.editing_product}
          />
        }

        {/* ------------------- DELETE CATEGORY ---------------- */}
        {this.state.open_delete_dialog &&
          <DeleteProduct
            isOpenPopup={this.state.open_delete_dialog}
            closePopup={this.closeDeleteProduct}
            refreshList={this.getProducts}
            productData={this.state.deleting_product}
          />
        }
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Products);
