import React, { useReducer } from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../components/customHooks/useRenderButton";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBDatePicker from "../../../components/design-system/DatePicker/TFBDatePicker";
import TFBTextArea from "../../../components/design-system/TextArea/TFBTextArea";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

import DeletePayment from "./DeletePayment";

const EditPayment = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, currentUser, data, } = props;

    const { lang } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            player_first_month_year_for_payment: data?.first_month_year_for_payment ?? null,
            monthly_tax: data?.monthly_tax ?? "",
            tax_reason: data?.tax_reason ?? "",
            serial_number: data?.serial_number ?? "",

            open_delete_payment: false,
            deleting_payment: {},

            payments: data?.payments?.list ?? [],
        }
    );

    const { postData: updateRequest, loading: loadingUpdateRequest } = useAxiosPost("payment/update_player_payment_details");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            refreshList(data.id_player, null, state.serial_number, state.player_first_month_year_for_payment, state.monthly_tax, state.tax_reason);
            closePopup();
            showNotification(
                "success",
                languagePack.finances,
                languagePack.update_record_event_success,
            );
        } else {
            showNotification(
                "danger",
                languagePack.finances,
                languagePack.update_record_event_fail,
            );
        }
    };

    const openDeletePayment = (payment) => {
        updateState({ open_delete_payment: true, deleting_payment: payment });
    }

    const closeDeletePayment = () => {
        updateState({ open_delete_payment: false, deleting_payment: {} });
    }

    const updatePaymentDetails = () => {
        let payload = {
            id_player: data.id_player,
            player_data: {
                first_month_year_for_payment: state.player_first_month_year_for_payment,
                monthly_tax: state.monthly_tax,
                tax_reason: state.tax_reason,
                serial_number: state.serial_number,
            },
        };

        updateRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => updatePaymentDetails(),
        "green",
        "add",
        languagePack.admin_save,
        {},
        true,
        false,
        loadingUpdateRequest,
    );

    const refreshPaymentList = (id_payment) => {
        const index = _.findIndex(state.payments, e => e.id_payment == id_payment);
        if (index > -1) {
            updateState({ payments: _.filter(state.payments, (e, i) => i != index) })
            refreshList(data.id_player, id_payment);
        }
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["edit_payments_title"] + " " + data.player_name}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <TFBInput
                label={"Matricol"}
                value={state.serial_number}
                onChange={(e) => updateState({ serial_number: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBDatePicker
                value={state.player_first_month_year_for_payment}
                onChange={(value) => updateState({ player_first_month_year_for_payment: value })}
                openTo="month"
                views={["year", "month"]}
                label={"Luna si anul primei cotizatii"}
                format="MMMM yyyy"
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBInput
                label={languagePack.monthly_tax}
                value={state.monthly_tax}
                onChange={(e) => updateState({ monthly_tax: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            <TFBTextArea
                label={"Motivare taxă"}
                value={state.tax_reason}
                onChange={(e) => updateState({ tax_reason: e.target.value })}
                style={{ marginBottom: 20, width: "100%" }}
            />
            {_.size(state.payments) > 0 ?
                <>
                    <div className="ds-headline-1">{"Lista plati lunare"}</div>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TFBTableCell align="center">{languagePack.month}</TFBTableCell>
                                    <TFBTableCell align="center">{languagePack.sum}</TFBTableCell>
                                    <TFBTableCell align="center">{languagePack.payment_type}</TFBTableCell>
                                    <TFBTableCell align="center">{languagePack.product_actions}</TFBTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {_.map(state.payments, (payment, index) => (
                                    <TableRow
                                        key={"player-payment-" + index}
                                        sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white", }}
                                    >
                                        <TFBTableCell>
                                            {_.capitalize(moment().set("month", payment.payment_month - 1).locale(lang ?? "ro").format('MMMM'))}
                                        </TFBTableCell>
                                        <TFBTableCell>
                                            {payment.payment_amount + " RON"}
                                        </TFBTableCell>
                                        <TFBTableCell>
                                            {languagePack.payment_types?.[payment.payment_type_label]}
                                        </TFBTableCell>
                                        <TFBTableCell>
                                            <div className="actions-btns-container">
                                                {!["parents_app", "online_website",].includes(payment.payment_type_label) &&
                                                    <TFBIconButton
                                                        name="delete"
                                                        color="darkGray"
                                                        onClick={() => openDeletePayment(payment)}
                                                    />
                                                }
                                            </div>
                                        </TFBTableCell>
                                    </TableRow>
                                )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </> :
                <TFBPlaceholder text={languagePack["edit_payments_empty"]} />
            }
            {state.open_delete_payment &&
                <DeletePayment
                    openPopup={state.open_delete_payment}
                    closePopup={closeDeletePayment}
                    refreshList={refreshPaymentList}
                    payment={state.deleting_payment}
                />
            }
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(EditPayment);
