import React, { Component } from "react";
import { connect } from "react-redux";
import "./AddCategory.scss";
import { store } from "react-notifications-component";
import axios from "axios";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBInput from "../../../components/design-system/Input/TFBInput";
import TFBCheckbox from "../../../components/design-system/Checkbox/TFBCheckbox";
import TFBFileInput from "../../../components/design-system/FileInput/TFBFileInput";
import { handleFileInput } from "../../../utils/utilFunctions";

class EditCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category_name: props?.categoryData?.category_name,
      is_active: props?.categoryData?.category_status == "1" ? true : false,
      category_image: [],
    };
  }

  updateCategory = async () => {
    const { languagePack, closePopup, refreshList, categoryData } = this.props;

    const payload = {
      modify_category: 1,
      category_name: this.state.category_name,
      category_status: this.state.is_active ? 1 : 0,
      id_club: this.props.currentUser.id_club,
      id_category: categoryData.id_category,
    };

    if (this.state.category_image.length > 0) {
      const img_content = await handleFileInput(this.state.category_image[0]);
      if (img_content != null) {
        payload.image_name = this.state.category_image[0].name;
        payload.image_type = this.state.category_image[0].type;
        payload.image_file = img_content;
      }
    }

    axios
      .post("/category", payload)
      .then((res) => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.categories,
            message: languagePack.success_update_category,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.categories,
            message: languagePack.fail_update_category,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack, closePopup, isOpenPopup } = this.props;

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.edit_category}
        open={isOpenPopup}
        closePopup={closePopup}
        btnLeft={() => (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={closePopup}
          >
            {languagePack.admin_cancel}
          </TFBButton>
        )}
        btnRight={() => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="check" />}
            disabled={!this.state.category_name}
            onClick={this.updateCategory}
          >
            {languagePack.apply}
          </TFBButton>
        )}
      >
        <TFBInput
          label={languagePack.category_name}
          placeholder={languagePack.add_category_name}
          value={this.state.category_name}
          fullWidth={true}
          onChange={(e) => {
            this.setState({ category_name: e.target.value });
          }}
        />
        <div className="tfb-check-form-row">
          <TFBCheckbox
            label={languagePack.category_is_active}
            checked={this.state.is_active}
            onChange={() => this.setState({ is_active: !this.state.is_active })}
          />
        </div>
        <TFBFileInput
          label={languagePack.category_image}
          accept="image/jpg, image/png, image/jpeg"
          placeholder={languagePack.upload_file}
          onChange={(event) => this.setState({ category_image: event.target.files })}
          files={Array.from(this.state.category_image).map(elem => elem.name)}
        />
      </TFBDialog>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(EditCategory);
