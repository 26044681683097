import React from "react";
import "./Players.scss";
import "./SearchFilters.scss";
import { Search } from "semantic-ui-react";
import _ from "lodash";
import TFBDatePicker from "../../../../components/design-system/DatePicker/TFBDatePicker";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";

export default function SearchFilters(props) {
  return (
    <>
      <div className="search-filters-wrapper">
        <div className="searchable-players">
          <Search
            style={{
              alignSelf: "center",
            }}
            fluid
            placeholder={props.languagePack.find_a_player}
            input={{
              icon: "search",
            }}
            loading={props.isLoading}
            onSearchChange={props.handleSearchChange}
            defaultValue={""}
            className="ui active search visible focus search-bar-patch search-component"
            minCharacters={2}
          />
        </div>

        <div className="dates-filters">
          <div className="players-date-pickers">
            <TFBDatePicker
              placeholder={props.languagePack.born_between}
              label={props.languagePack.born_between}
              style={{
                width: "100%",
              }}
              value={props.startDate}
              onChange={(date) => props.handleChangeStartDate(date)}
            />
          </div>

          <span
            style={{
              marginTop: "18px",
            }}
          >
            {"-"}
          </span>

          <div className="players-date-pickers">
            <TFBDatePicker
              placeholder={props.languagePack.and_between}
              label={props.languagePack.and_between}
              style={{
                width: "100%",
              }}
              value={props.endDate}
              onChange={(date) => props.handleChangeEndDate(date)}
            />
          </div>
        </div>

        <div className="filter-buttons">
          <TFBButton
            onClick={props.handleApplyFilters}
            style={{
              width: "50%",
            }}
            endIcon="true"
            color={"darkGray"}
            renderIcon={() => <TFBIcon name={"check"} />}
          >
            {props.languagePack.apply_filters}
          </TFBButton>

          <TFBButton
            onClick={props.handleResetFilters}
            style={{
              width: "50%",
            }}
            endIcon="true"
            color={"darkGray"}
            renderIcon={() => <TFBIcon name={"cancel"} />}
          >
            {props.languagePack.Reset_filters}
          </TFBButton>
        </div>
      </div>
      <div>
        <TFBCheckbox
          label={props.languagePack.hide_inactiv_players}
          checked={props.hideInactivePlayers}
          onChange={props.handleChangeInactivePlayers}
        />
      </div>
    </>
  );
}
