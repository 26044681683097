import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "./../../_metronic/layout";

import { bindActionCreators } from "redux";

import { logout } from "./../utils/api";

class Logout extends Component {

  componentDidMount() {
    this.props.logout({ token: this.props.token });
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
        <Redirect to="/auth/login" />
      );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  );
}
export default connect(
  ({ auth }) => ({
    hasAuthToken: Boolean(auth.authToken),
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(Logout);
