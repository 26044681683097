import React from "react";
import styles from "./TFBTimePicker.module.scss";
import {
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TTimePicker } from "../types";
import TFBIcon from "../Icon/TFBIcon";

const TFBTimePicker = ({
  onChange,
  label,
  value,
  style,
  placeholder,
  className,
}: TTimePicker) => {

  const ReusableComponent = (
    <div className={styles.tfbDatePickerContainer}>
      <TimePicker
        showTodayButton
        ampm={false}
        value={value}
        minutesStep={5}
        inputVariant="outlined"
        className={`${styles.tfbTimePicker} ${className ? className : ""}`}
        onChange={onChange}
        style={{
          border: "1px solid #F2F2F2",
          borderColor: "#F2F2F2",
          borderRadius: "3px",
          ...style,
        }}
        emptyLabel={placeholder}
      />
      <TFBIcon
        name="time"
        color="gray"
        className={styles.tfbDatePikerIcon}
      />
    </div>
  );

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={styles.tfbTimeGroup}>
        <h6 className={styles.timeLabel}>{label}</h6>
        {ReusableComponent}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TFBTimePicker;
