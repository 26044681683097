import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import useRenderButton from "../../../components/customHooks/useRenderButton";
import { showNotification, useAxiosPost } from "../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";

const ChangeStatusMobileUser = (props) => {

    const { openPopup, closePopup, refreshList, languagePack, user } = props;

    const { postData: changeStatusRequest, loading: loadingChangeStatusRequest } = useAxiosPost("parentsApp/update_user_mobile");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification(
                "success",
                user.is_accepted == 1 ? languagePack.desactivate_user : languagePack.activate_user,
                user.is_accepted == 1 ? languagePack.notif_desactivate_mobile_user_success_message : languagePack.notif_activate_mobile_user_success_message,
            );
        } else {
            showNotification(
                "danger",
                user.is_accepted == 1 ? languagePack.desactivate_user : languagePack.activate_user,
                user.is_accepted == 1 ? languagePack.notif_desactivate_mobile_user_failed_message : languagePack.notif_activate_mobile_user_failed_message,
            );
        }
    };

    const changeStatusUserMobile = () => {
        const payload = {
            id_user_external: user.id_user_external,
            is_accepted: user.is_accepted == 1 ? 0 : 1,
        };
        changeStatusRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => changeStatusUserMobile(),
        user.is_accepted == 1 ? "red" : "green",
        user.is_accepted == 1 ? "x-small" : "check",
        user.is_accepted == 1 ? languagePack.desactivate : languagePack.activate,
        {},
        true,
        false,
        loadingChangeStatusRequest,
    );

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={user.is_accepted == 1 ? languagePack["desactivate_user"] : languagePack["activate_user"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="pop-up-dialog-delete-text">
                {user.is_accepted == 1 ? languagePack.desactivate_user_popup_text : languagePack.activate_user_popup_text}
            </div>
        </TFBDialog>
    );
};

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(ChangeStatusMobileUser);
