import React from "react";
import styles from "./Card.module.scss";
import { TCardBody } from "../types";

const TFBCardBody = ({ children, className, style }: TCardBody) => {
  const classN = className ? className : "";
  return <div className={`${styles.cardBody} ${classN}`} style={style}>{children}</div>;
};

export default TFBCardBody;
