import React from "react";
import Ranking from "../components/widgets/Ranking/Ranking";
import NextMatches from "../components/widgets/NextMatches/NextMatches";
import Calendar from "../components/widgets/Calendar/Calendar";
import TopMvPlayers from "../components/widgets/TopMvPlayers/TopMvPlayers";

import IncomeOutcomeChart from "../components/charts/Financial/IncomeOutcomeChart";
import BudgetChart from "../components/charts/Financial/BudgetChart";

import AccountBalance from "../components/charts/Financial/AccountBalance";
import AccountMonthly from "../components/charts/Financial/AccountMonthly";
import OptionsPopup from "./Scouting/OptionsPopup";

export function DashboardPage() {
  return (
    <>
      {/* <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom">
            <AccountBalance />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="card card-custom">
            <AccountMonthly />
          </div>
        </div>
      </div> */}
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-lg-8 col-xxl-8 widget dash-next-matches no-margin-bottom">
          <NextMatches />
          <Calendar />
        </div>
        <div className="col-lg-4 col-xxl-4 widget">
          <Ranking />
          {/* <TopMvPlayers /> */}
        </div>
      </div>
    </>
  );
}
