import React, { useState } from "react";
import { store } from "react-notifications-component";
import { connect } from "react-redux";
import "./ViewNotification.scss";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TFBTableCell } from "../../../../components/reusable/useStyles";

const ViewNotification = (props) => {
  const [users, setUsers] = useState(props.notificationDetail.data);

  const { languagePack } = props;

  return (
    <TFBDialog
      open={props.openDialog}
      fullWidth={true}
      maxWidth="md"
      closePopup={props.closePopup}
      title={languagePack.details_notification_sent}
    >
      <div className="view-users-notifications-container">
        <div className="sub-header ds-headline-2">
          {languagePack.notification_message}
        </div>

        <div className="message-text ds-main-text-auxiliary">
          {props.notificationDetail.notification_title}
        </div>

        <div className="audience ds-headline-2" style={{ marginTop: "50px" }}>
          {languagePack.audience}
        </div>

        {users != [] && (
          <div className="users-table">
            <TableContainer>
              <Table faria-label="simple table" sx={{ minWidth: "471px" }}>
                <TableHead>
                  <TableRow>
                    <TFBTableCell>
                      <div className="header-checkbox">
                        <div>{languagePack.User_name}</div>
                      </div>
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.plyers_names}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        <TFBTableCell
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#fafafa" : "white",
                          }}
                        >
                          <div className="row-checkbox-player">
                            <div className="user-details">
                              <div>{row.full_name}</div>
                              <div className="relationship ds-secondary-text-auxiliary">
                                {row.relationship}
                              </div>
                            </div>
                          </div>
                        </TFBTableCell>
                        <TFBTableCell align="center">
                          {row.players_name}
                        </TFBTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </TFBDialog>
  );
};

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(ViewNotification);
