import dashboard from "./../assets/icons/aside-menu-svg/dashboard.svg";
import document from "./../assets/icons/aside-menu-svg/document.svg";
import fan_engagement from "./../assets/icons/aside-menu-svg/fan_engagement.svg";
import financial from "./../assets/icons/aside-menu-svg/financial.svg";
import medical from "./../assets/icons/aside-menu-svg/medical.svg";
import my_club from "./../assets/icons/aside-menu-svg/my_club.svg";
import online_shop_administration from "./../assets/icons/aside-menu-svg/online_shop_administration.svg";
import parents_app_administration from "./../assets/icons/aside-menu-svg/parents_app_administration.svg";
import platform_administration from "./../assets/icons/aside-menu-svg/platform_administration.svg";
import report from "./../assets/icons/aside-menu-svg/report.svg";
import site_administration from "./../assets/icons/aside-menu-svg/site_administration.svg";
import training from "./../assets/icons/aside-menu-svg/training.svg";
import user_profile from "./../assets/icons/aside-menu-svg/user_profile.svg";
import video_gallery from "./../assets/icons/aside-menu-svg/video_gallery.svg";

export default {
    DASHBOARD: dashboard,
    DOCUMENT: document,
    FAN_ENGAGEMENT: fan_engagement,
    FINANCIAL: financial,
    MEDICAL: medical,
    MY_CLUB: my_club,
    ONLINE_SHOP_ADMINISTRATION: online_shop_administration,
    PARENTS_APP_ADMINISTRATION: parents_app_administration,
    PLATFORM_ADMINISTRATION: platform_administration,
    REPORT: report,
    SITE_ADMINISTRATION: site_administration,
    TRAINING: training,
    USER_PROFILE: user_profile,
    VIDEO_GALLERY: video_gallery,
}