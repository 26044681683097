import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TacticBoard from "./TacticBoard";

const TacticBoardPopup = (props) => {

    const { openPopup, closePopup, languagePack } = props;

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["tactic_board"]}
            open={openPopup}
            closePopup={closePopup}
            fullWidth
            maxWidth="xl"
        >
            <TacticBoard />
        </TFBDialog>
    );
};

export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
    })
)(TacticBoardPopup);
