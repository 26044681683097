import { connect } from "react-redux";
import React from "react";
import ReceiptsStats from "./ReceiptsStats";
import PerTeamReceiptsStats from "./PerTeamReceiptsStats";

function FinancialReport() {
  return (
    <>
      <ReceiptsStats />
      <PerTeamReceiptsStats />
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(FinancialReport);
