import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import moment from "moment";
import TextInput from "react-autocomplete-input";

import useAxiosGet from "../../components/customHooks/useAxiosGet";
import { showNotification, useAxiosPost } from "../../components/customHooks/useAxiosPost";
import useRenderButton from "../../components/customHooks/useRenderButton";
import TFBDialog from "../../components/design-system/Dialog/TFBDialog";
import TFBSelect from "../../components/design-system/TFBSelect/TFBSelect";
import TFBDropdown from "../../components/design-system/Dropdown/TFBDropdown";
import TFBDatePicker from "../../components/design-system/DatePicker/TFBDatePicker";
import TFBInput from "../../components/design-system/Input/TFBInput";
import TFBPhoneInput from "../../components/design-system/PhoneInput/TFBPhoneInput";
import TFBCheckbox from "../../components/design-system/Checkbox/TFBCheckbox";

import { updateQuickSetup } from "./../../utils/api";
import { countries } from "../../utils/countries";
import { getCountryName, isTFB, isValidPhoneNumberCustom } from "../../utils/utilFunctions";
import { frfListDropdown, frfListDropdownU21, frfListNoDob, mainFootDropdown, positionsDropdown } from "../../utils/constants";

const AddPlayer = (props) => {
    const { openPopup, closePopup, refreshList, languagePack, currentUser, id_team, id_season, updateQuickSetup } = props;

    const { id_club } = currentUser;

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            countries: _.union([{
                key: "noCountry",
                value: "noCountry",
                text: languagePack.no_country,
            }], countries),

            player_name: "",

            player_position: null,
            player_second_position: null,

            main_foot: null,
            birth_country: "ro",

            second_country: null,
            player_dob: null,

            player_shirt_number: "",
            player_frf_list: null,
            is_u21: false,
            is_on_frf_list: false,

            player_identity_card: "",
            player_fifa_id: "",

            legitimation_date: null,
            enrolment_date: null,

            serial_number: "",

            player_phone_number: "",

            player_has_professional_license: false,

            already_been: false,
            display_input: true,
            id_player: "",

            keep_in_previous_teams: false,
            player_teams: [],
            selected_player_teams: null,

            get_player_info: false,
        }
    );

    const {
        data: playerSugestion,
        loading: isPlayerSugestionLoading,
    } = useAxiosGet(`club/player_suggestions_update?id_club=${id_club}&id_team=${id_team}&id_season=${id_season}`);

    const {
        data: playerInfo,
        loading: isPlayerInfoLoading,
        refetch: refetchPlayerInfo
    } = useAxiosGet(`club/add_player_info_2?player_name=${state.player_name}&id_team=${id_team}`, true);

    useEffect(() => {
        if (state.get_player_info) {
            refetchPlayerInfo();
        }
    }, [state.get_player_info]);

    useEffect(() => {
        if (playerInfo) {
            updateState({
                id_player: playerInfo.id_player,
                player_position: playerInfo?.player_position ? (_.size(_.filter(positionsDropdown, (e) => e.value == playerInfo.player_position)) > 0 ? _.filter(positionsDropdown, (e) => e.value == playerInfo.player_position)[0] : { value: playerInfo.player_position, label: playerInfo.player_position }) : null,
                player_second_position: playerInfo?.player_position_alternative ? (_.size(_.filter(positionsDropdown, (e) => e.value == playerInfo.player_position_alternative)) > 0 ? _.filter(positionsDropdown, (e) => e.value == playerInfo.player_position_alternative)[0] : { value: playerInfo.player_position_alternative, label: playerInfo.player_position_alternative }) : null,
                main_foot: playerInfo?.player_main_foot ? (_.size(_.filter(mainFootDropdown, (e) => e.value == playerInfo.player_main_foot)) > 0 ? _.filter(mainFootDropdown, (e) => e.value == playerInfo.player_main_foot)[0] : { value: playerInfo.player_main_foot, label: playerInfo.player_main_foot }) : null,
                birth_country: playerInfo?.birth_country ? (_.size(_.filter(state.countries, (e) => e.text == playerInfo.birth_country)) > 0 ? _.filter(state.countries, (e) => e.text == playerInfo.birth_country)[0]['value'] : null) : null,
                second_country: playerInfo?.second_country ? (_.size(_.filter(state.countries, (e) => e.text == playerInfo.second_country)) > 0 ? _.filter(state.countries, (e) => e.text == playerInfo.second_country)[0]['value'] : null) : null,
                player_dob: playerInfo?.player_dob ?? null,
                player_shirt_number: playerInfo?.shirt_number ?? "",
                player_frf_list: playerInfo?.frf_list ? (_.size(_.filter(frfListDropdownU21, (e) => e.value == playerInfo.frf_list)) > 0 ? _.filter(frfListDropdownU21, (e) => e.value == playerInfo.frf_list)[0] : { value: playerInfo.frf_list, label: playerInfo.frf_list }) : null,
                player_identity_card: playerInfo?.cnp ?? "",
                player_fifa_id: playerInfo?.fifa_id ?? "",
                legitimation_date: playerInfo?.legitimation_date ?? null,
                enrolment_date: playerInfo?.enrolment_date ?? null,
                serial_number: playerInfo?.serial_number ?? "",
                player_phone_number: playerInfo?.phone_number ?? "",
                player_has_professional_license: { "1": true, "0": false }[playerInfo?.has_professional_license] ?? false,
                player_teams: playerInfo?.player_teams ?? [],
                selected_player_teams: playerInfo?.player_teams ?? [],
                already_been: true,
                get_player_info: false,
            });
        }
    }, [playerInfo]);

    const isOnFrfList = () => {
        if (_.size(_.intersection(["A", "B"], _.map([state.player_frf_list], (e) => e?.value))) > 0) {
            updateState({ is_on_frf_list: true });
        } else {
            updateState({ is_on_frf_list: false });
        }
    };

    useEffect(() => {
        isOnFrfList();
    }, [state.player_frf_list])

    const isU21 = () => {
        if (state.player_dob != null) {
            if (moment(state.player_dob) <= moment("01-01-" + (moment().year() - 21), "DD-MM-YYYY")) {
                updateState({ is_u21: false })
                if (_.size(_.intersection(["B"], _.map([state.player_frf_list], (e) => e?.value))) > 0) {
                    updateState({ player_frf_list: null });
                }
            } else {
                updateState({ is_u21: true });
            }
        }
    };

    useEffect(() => {
        isU21();
    }, [state.player_dob]);

    const { postData: addRequest, loading: loadingAddRequest } = useAxiosPost("club/add_player_4");

    const handleResponse = (response) => {
        if (response?.success == 1) {
            closePopup();
            refreshList();
            showNotification("success", languagePack.club_management, languagePack.success_added_player);
            let clubQuickSetup = response?.club_quick_setup;
            updateQuickSetup({
                ...clubQuickSetup,
                isSetupOpen: false,
            });
        } else {
            let message = languagePack.fail_added_player;
            if (response?.error_code == "used_phone_number_by_another_user") {
                message = languagePack.used_phone_number_by_another_user;
            } else if (response?.error_code == "used_phone_number_by_another_player") {
                message = languagePack.used_phone_number_by_another_player;
            }
            showNotification("danger", languagePack.club_management, message);
        }
    };

    const addPlayer = () => {
        const payload = {
            id_club: id_club,
            id_team: id_team,
            id_player: state.id_player,
            player_name: _.trim(state.player_name),
            player_dob: state.player_dob,
            player_position: state.player_position?.value ?? null,
            player_position_alternative: state.player_second_position?.value ?? null,
            player_main_foot: state.main_foot?.value ?? null,
            birth_country: getCountryName(state.birth_country, state.countries),
            second_country: getCountryName(state.second_country, state.countries),
            shirt_number: state.player_shirt_number,
            frf_list: state.player_frf_list?.value ?? 0,
            cnp: state.player_identity_card,
            fifa_id: state.player_fifa_id,
            has_professional_license: { true: 1, false: 0 }[state.player_has_professional_license],
            already_been: state.already_been,
            keep_in_previous_teams: state.keep_in_previous_teams,
            previous_teams: state.selected_player_teams ?? [],
            legitimation_date: state.legitimation_date,
            enrolment_date: state.enrolment_date,
            serial_number: state.serial_number,
            phone_number: _.size(_.toString(state.player_phone_number)) > 0 ? state.player_phone_number : null,
            is_tfb: isTFB(),
        };

        addRequest(payload, handleResponse);
    }

    const { renderBtn: BtnLeft } = useRenderButton(
        () => closePopup(),
        "darkGray",
        "cancel",
        languagePack.admin_cancel
    );

    const { renderBtn: BtnRight } = useRenderButton(
        () => addPlayer(),
        "green",
        "add",
        languagePack.admin_add,
        {},
        true,
        _.size(_.trim(state.player_name)) == 0 || _.size(_.trim(state.birth_country)) == 0 || !isValidPhoneNumberCustom(_.trim(state.player_phone_number)) || (state.is_on_frf_list && _.size(_.toString(state.player_shirt_number)) == 0) || isPlayerInfoLoading,
        loadingAddRequest,
    );

    const handleSearchPlayer = (value) => {
        updateState({ player_name: value });
        if (value == "") {
            updateState({
                player_name: "",

                player_position: null,
                player_second_position: null,

                main_foot: null,
                birth_country: "ro",

                second_country: null,
                player_dob: null,

                player_shirt_number: "",
                player_frf_list: null,
                is_u21: false,
                is_on_frf_list: false,

                player_identity_card: "",
                player_fifa_id: "",

                legitimation_date: null,
                enrolment_date: null,

                serial_number: "",

                player_phone_number: "",

                player_has_professional_license: false,

                already_been: false,
                display_input: true,
                id_player: "",

                keep_in_previous_teams: false,
                player_teams: [],
                selected_player_teams: null,
            });
        }
    };

    const handleSelectPlayer = (selection) => {
        updateState({ get_player_info: true })
    };

    const hanldeChangeSelect = (selected, value) => {
        updateState({ [value.name]: selected })
    };

    const hanldeBlurSelect = (name, e) => {
        if (e.target.value) {
            updateState({ [name]: { value: e.target.value, label: e.target.value } });
        }
    }

    const handleChangeDropdown = (e, value) => {
        updateState({ [value.name]: value.value })
    }

    return (
        <TFBDialog
            className="pop-up-dialog-box prevent-select-text"
            title={languagePack["add_player"]}
            open={openPopup}
            closePopup={closePopup}
            btnLeft={BtnLeft}
            btnRight={BtnRight}
        >
            <div className="tab-control-line">
                {state.display_input && (
                    <div className="react-text-autocomplete-input">
                        <div className="custom-label">{languagePack.player_name}</div>
                        <TextInput
                            options={playerSugestion ?? []}
                            style={{
                                height: "50px",
                                width: "100%",
                                backgroundColor: "#fafafa",
                                border: "1px solid #cccccc",
                            }}
                            onChange={(value) => handleSearchPlayer(value)}
                            trigger=""
                            Component="input"
                            onSelect={handleSelectPlayer}
                            placeholder={languagePack["player_name"]}
                            regex="[a-zA-Z0-9_\-]+"
                            matchAny
                        />
                    </div>
                )}
            </div>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBSelect
                    placeholder={languagePack["POSITION"]}
                    label={languagePack["POSITION"]}
                    value={state.player_position ? { ...state.player_position, label: languagePack?.[state.player_position.lang_pack] ?? state.player_position?.label } : null}
                    name="player_position"
                    options={_.map(positionsDropdown, e => ({ ...e, label: languagePack?.[e.lang_pack] }))}
                    onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                    onBlur={(e) => hanldeBlurSelect("player_position", e)}
                    isClearable={true}
                />
                <TFBSelect
                    placeholder={languagePack["SECONDARY_POSITION"]}
                    label={languagePack["SECONDARY_POSITION"]}
                    value={state.player_second_position ? { ...state.player_second_position, label: languagePack?.[state.player_second_position.lang_pack] ?? state.player_second_position?.label } : null}
                    name="player_second_position"
                    options={_.map(positionsDropdown, e => ({ ...e, label: languagePack?.[e.lang_pack] }))}
                    onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                    onBlur={(e) => hanldeBlurSelect("player_second_position", e)}
                    isClearable={true}
                />
            </div>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <div className="tab-control-btn">
                    <TFBSelect
                        placeholder={languagePack["MAIN_FOOT"]}
                        label={languagePack["MAIN_FOOT"]}
                        value={state.main_foot ? { ...state.main_foot, label: languagePack?.[state.main_foot.lang_pack] ?? state.main_foot?.label } : null}
                        name="main_foot"
                        options={_.map(mainFootDropdown, e => ({ ...e, label: languagePack?.[e.lang_pack] }))}
                        onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                        onBlur={(e) => hanldeBlurSelect("main_foot", e)}
                        isClearable={true}
                    />
                </div>
                <div className="tab-control-btn">
                    <TFBDropdown
                        color="lightGray"
                        placeholder={languagePack["NATIONALITY"]}
                        label={languagePack["NATIONALITY"]}
                        value={state.birth_country}
                        options={state.countries}
                        selection
                        name="birth_country"
                        search
                        onChange={handleChangeDropdown}
                    />
                </div>
            </div>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <div className="tab-control-btn">
                    <TFBDropdown
                        color="lightGray"
                        placeholder={languagePack["NATIONALITY"]}
                        label={languagePack["NATIONALITY"]}
                        value={state.second_country}
                        options={state.countries}
                        selection
                        name="second_country"
                        search
                        onChange={handleChangeDropdown}
                    />
                </div>
                <div className="tab-control-btn">
                    <TFBDatePicker
                        value={state.player_dob}
                        onChange={(value) => updateState({ player_dob: value })}
                        placeholder={languagePack["birth_date"]}
                        label={languagePack["birth_date"]}
                        style={{ width: "100%" }}
                    />
                </div>
            </div>
            <div className={`df-g20 tab-control-line-mw-500 ${state.is_on_frf_list && _.size(_.toString(state.player_shirt_number)) == 0 ? "" : "tab-control-line"}`}>
                <TFBInput
                    placeholder={languagePack.shirt_number}
                    label={languagePack.shirt_number}
                    value={state.player_shirt_number}
                    onChange={(e) => updateState({ player_shirt_number: e.target.value })}
                    fullWidth
                    type="number"
                    style={{ backgroundColor: "#fafafa" }}
                />
                <TFBSelect
                    placeholder={"Lista FRF"}
                    label={"Lista FRF"}
                    value={state.player_frf_list ? { ...state.player_frf_list, label: languagePack?.[state.player_frf_list.lang_pack] ?? state.player_frf_list?.label } : null}
                    name="player_frf_list"
                    options={state.player_dob ? (state.is_u21 ? _.map(frfListDropdownU21, e => ({ ...e, label: languagePack?.[e.lang_pack] })) : _.map(frfListDropdown, e => ({ ...e, label: languagePack?.[e.lang_pack] }))) : _.map(frfListNoDob, e => ({ ...e, label: languagePack?.[e.lang_pack] }))}
                    onChange={(selected, value) => hanldeChangeSelect(selected, value)}
                    onBlur={(e) => hanldeBlurSelect("player_frf_list", e)}
                    isClearable={true}
                />
            </div>
            {state.is_on_frf_list && _.size(_.toString(state.player_shirt_number)) == 0 &&
                <div className="tab-control-line error-message">
                    {languagePack.insert_shirt_number}
                </div>
            }
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBInput
                    placeholder={languagePack.identity_card_id}
                    label={languagePack.identity_card_id}
                    value={state.player_identity_card}
                    fullWidth
                    onChange={(e) => updateState({ player_identity_card: e.target.value })}
                    style={{ backgroundColor: "#fafafa" }}
                />
                <TFBInput
                    placeholder={"Fifa ID"}
                    label={"Fifa ID"}
                    fullWidth
                    value={state.player_fifa_id}
                    onChange={(e) => updateState({ player_fifa_id: e.target.value })}
                    style={{ backgroundColor: "#fafafa" }}
                />
            </div>
            <div className="df-g20 tab-control-line tab-control-line-mw-500">
                <TFBDatePicker
                    value={state.legitimation_date}
                    onChange={(value) => updateState({ legitimation_date: value })}
                    placeholder={languagePack.legitimation_date}
                    label={languagePack.legitimation_date}
                    style={{ width: "100%" }}
                />
                <TFBDatePicker
                    value={state.enrolment_date}
                    onChange={(value) => updateState({ enrolment_date: value })}
                    placeholder={languagePack.enrolment_date}
                    label={languagePack.enrolment_date}
                    style={{ width: "100%" }}
                />
            </div>
            <div className="tab-control-line">
                <TFBInput
                    label="Matricol"
                    fullWidth
                    style={{ backgroundColor: "#fafafa" }}
                    value={state.serial_number}
                    onChange={(e) => updateState({ serial_number: e.target.value })}
                />
            </div>
            <div className="tab-control-line">
                <TFBPhoneInput
                    initialValueFormat="national"
                    defaultCountry="RO"
                    countries={["RO"]}
                    label={languagePack.tax_redirector_phone_number}
                    placeholder={"Ex: 0712 345 678"}
                    value={state.player_phone_number}
                    onChange={(e) => updateState({ player_phone_number: e ?? "" })}
                />
            </div>
            {!isValidPhoneNumberCustom(state.player_phone_number) &&
                <div className="ui red message">
                    {languagePack.incorect_phone_number}
                </div>
            }
            <div className="tab-control-line">
                <TFBCheckbox
                    label={languagePack.has_professional_license_issued}
                    checked={state.player_has_professional_license}
                    onChange={(e) => updateState({ player_has_professional_license: e.target.checked })}
                />
            </div>
            {state.already_been && _.size(state.player_teams) > 0 &&
                <>
                    <div className="tab-control-line">
                        <TFBCheckbox
                            label={languagePack.keep_it_in_previous_teams}
                            checked={state.keep_in_previous_teams}
                            onChange={(e) => updateState({ keep_in_previous_teams: e.target.checked, selected_player_teams: state.player_teams })}
                        />
                    </div>
                    {state.keep_in_previous_teams &&
                        <TFBSelect
                            value={state.selected_player_teams}
                            options={state.player_teams}
                            onChange={(selected) => updateState({ selected_player_teams: selected, keep_in_previous_teams: selected == null ? false : true, })}
                            isMulti
                            isClearable
                            isSearchable
                        />
                    }
                </>
            }
        </TFBDialog>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            updateQuickSetup,
        },
        dispatch
    );
}
export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(AddPlayer);