import React, { Component } from "react";
import * as constants from "./ScoutingConstants";
import { Dropdown, Button, Icon } from "semantic-ui-react";
import axios from "axios";
import InputRange from "react-input-range";
import shirt from "../../assets/shirt.png";
import shirt_selection from "../../assets/shirt_selection.png";

class OptionsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFormation: "4-3-3",
      selectedLeague: "",
      selectedNationality: "",
      selectedProfile: "",
      selectedFoot: "",
      selectedContractType: "",
      formationConfig: [
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0],
      ],
      selectedPosition: "",

      selectedAge: { min: 0, max: 0 },
      selectedHeight: { min: 0, max: 0 },

      leaguesDropdown: [],
      nationalitiesDropdown: [],
      profilesDropdown: [],
      heightSliderMin: 0,
      heightSliderMax: 0,
      ageSliderMin: 0,
      ageSliderMax: 0,
    };
  }

  componentDidMount = () => {
    this.getDropdownOptions();
    this.getFormationConfig();
  };

  getFormationConfig = () => {
    axios
      .get(
        `transferlab/formation_pitch_config?formation=${this.state.selectedFormation}`
      )
      .then((res) => this.setState({ formationConfig: res.data }))
      .catch((err) => console.log(err));
  };

  getProfileDropdownOptions = () => {
    axios
      .get(
        `transferlab/profile_dropdown_options?position=${this.state.selectedPosition}`
      )
      .then((res) => {
        this.setState({ profilesDropdown: res.data });
      })
      .catch((err) => console.log(err));
  };

  getDropdownOptions = () => {
    axios
      .get("transferlab/filters_dropdown_options")
      .then((res) => {
        this.setState({
          leaguesDropdown: res.data.leagues,
          nationalitiesDropdown: res.data.nationalities,
          heightSliderMin: res.data.minHeight,
          heightSliderMax: res.data.maxHeight,
          ageSliderMin: res.data.minAge,
          ageSliderMax: res.data.maxAge,
          selectedAge: {
            min: res.data.minAge,
            max: res.data.maxAge,
          },
          selectedHeight: {
            min: res.data.minHeight,
            max: res.data.maxHeight,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  redirectPlayersList = () => {
    this.props.closePopup(
      this.state.selectedProfile,
      this.state.selectedNationality,
      this.state.selectedFoot,
      this.state.selectedHeight.min,
      this.state.selectedHeight.max,
      this.state.selectedAge.min,
      this.state.selectedAge.max,
      this.state.selectedLeague,
      this.state.selectedFormation
    );
  };

  render() {
    return (
      <div className="options-popup-container">
        <div className="formation-column">
          <div className="formation-title">Choose formation</div>
          <div className="formations-list">
            {constants.formations.map((item, index) => (
              <div
                className={
                  item == this.state.selectedFormation
                    ? "formation-box-selected"
                    : "formation-box"
                }
                key={index}
                onClick={() =>
                  this.setState(
                    { selectedFormation: item },
                    this.getFormationConfig
                  )
                }
              >
                <img
                  src={
                    this.state.selectedFormation == item
                      ? `../../assets/formations_white/${index}.png`
                      : `../../assets/formations/${index}.png`
                  }
                  className="formation-preview"
                />
                <div>{item}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="pitch-column">
          <div className="pitch-title">Choose position</div>
          <div className="pitch-container-img">
            {this.state.formationConfig.map((row) => {
              return (
                <div className="formation-row">
                  {row.map((el) => (
                    <div className="row-box">
                      {el != 0 && (
                        <div className="shirt-container">
                          <img
                            src={shirt}
                            className="shirt-img"
                            alt="player"
                            onClick={() =>
                              this.setState(
                                { selectedPosition: el },
                                this.getProfileDropdownOptions
                              )
                            }
                          />
                          {this.state.selectedPosition == el && (
                            <img
                              src={shirt_selection}
                              alt=""
                              className="selection-round"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        <div className="filters-column">
          <div className="filters-title">Filters</div>
          <div className="filters-row">
            <div className="filter-group">
              <div className="filter-label">Contract type</div>
              <Dropdown
                className="sh-filter-dropdown"
                placeholder="Any"
                options={constants.contractTypes}
                value={this.state.selectedContractType}
                name="selectedContractType"
                onChange={this.handleChangeDropdown}
                disabled
              />
            </div>
            <div className="filter-group">
              <div className="filter-label">League</div>
              <Dropdown
                className="sh-filter-dropdown"
                placeholder="Any"
                options={this.state.leaguesDropdown}
                value={this.state.selectedLeague}
                name="selectedLeague"
                onChange={this.handleChangeDropdown}
              />
            </div>
          </div>
          <div className="filters-row">
            <div className="filter-group">
              <div className="filter-label">Foot</div>
              <Dropdown
                className="sh-filter-dropdown"
                placeholder="Any"
                options={constants.legs}
                value={this.state.selectedFoot}
                onChange={this.handleChangeDropdown}
                name="selectedFoot"
              />
            </div>
            <div className="filter-group">
              <div className="filter-label">Nationality</div>
              <Dropdown
                className="sh-filter-dropdown"
                placeholder="Any"
                options={this.state.nationalitiesDropdown}
                value={this.state.selectedNationality}
                name="selectedNationality"
                onChange={this.handleChangeDropdown}
              />
            </div>
          </div>
          <div className="filters-row">
            <div className="filter-group">
              <div className="filter-label">Age</div>
              <InputRange
                maxValue={this.state.ageSliderMax}
                minValue={this.state.ageSliderMin}
                value={this.state.selectedAge}
                onChange={(value) => this.setState({ selectedAge: value })}
              />
            </div>
            <div className="filter-group">
              <div className="filter-label">Height</div>
              <InputRange
                maxValue={parseInt(this.state.heightSliderMax)}
                minValue={parseInt(this.state.heightSliderMin)}
                value={this.state.selectedHeight}
                onChange={(value) => this.setState({ selectedHeight: value })}
              />
            </div>
          </div>
          {this.state.selectedPosition !== "" && (
            <>
              <div className="player-profile-row">
                <div className="filter-label">Player profile</div>
                <Dropdown
                  className="sh-filter-dropdown"
                  placeholder="Any"
                  value={this.state.selectedProfile}
                  options={this.state.profilesDropdown}
                  onChange={this.handleChangeDropdown}
                  name="selectedProfile"
                />
              </div>

              <div className="sliders-list">
                {constants.filterStats.map((el, idx) => (
                  <div className="filter-slider-container" key={idx}>
                    <div className="filter-slider-label">{el}</div>
                    <InputRange
                      maxValue={100}
                      minValue={0}
                      //   value={this.state.sliderValue1}
                      //   onChange={(value) => this.setState({ sliderValue1: value })}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          <Button
            className="generate-sh-button"
            onClick={this.redirectPlayersList}
            disabled={this.state.selectedProfile == ""}
          >
            Generate shortlist
            <Icon name="arrow right" className="shortlist-button-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

export default OptionsPopup;
