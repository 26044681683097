import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import "./MatchDetails.scss";
import calendar from "../../../../assets/icons/calendar.png";
import location_pin from "../../../../assets/icons/location_pin.png";

function MatchBasicDetails(props) {
  const [matchData, setMatchData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`rapidapi/get_basic_match_details?id_match=${props.id_match}`)
      .then((res) => {
        setMatchData(res.data);
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="card card-custom">
      {isLoaded &&
        <div className="match-basic-details-container-instat">
          <div className="match-teams">
            <div className="team-container">

              <img
                src={`https:\/\/media.api-sports.io\/football\/teams\/${matchData.id_team_home}.png`}
                alt="team_home"
              />


            </div>
            <div className="score-container">
              <div className="score-number">{matchData.home_goals}</div>
              <div className="score-line">:</div>
              <div className="score-number">{matchData.away_goals}</div>
            </div>
            <div className="team-container">

              <img
                src={`https:\/\/media.api-sports.io\/football\/teams\/${matchData.id_team_away}.png`}
                alt="team_away"
              />

            </div>
          </div>
          <div className="match-details-instat">
            <div className="match-date">
              <img src={calendar} alt="calendar" style={{ marginRight: 10, height: 13 }} />
              <Moment format="DD.MM.YYYY, HH:mm">{matchData.match_date}</Moment>
            </div>
            <div className="match-location">
              <img src={location_pin} alt="location" style={{ marginRight: 10, height: 13 }} />
              {matchData.venue_name}, {matchData.venue_city}{" "}
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default MatchBasicDetails;
