import React from "react";
import styles from "./PlayerGallery.module.scss";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

export default function ExpandedVideo({ video, closeExpand }) {
    return (
        <div className={styles.photoViewerContainer}>
            <div className={styles.contentContainer}>
                <div className={styles.imgContainer}>
                    <video
                        className={styles.image}
                        src={video}
                        alt="expanded_image"
                        controls
                    />
                    <TFBIconButton
                        name="close-popup"
                        color="white"
                        onClick={closeExpand}
                    />
                </div>
            </div>
        </div>
    );
}
