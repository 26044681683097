import React from "react";
import styles from "./TFBPhoneInput.module.scss";
import { TPhoneInput } from "../types";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const TFBPhoneInput = ({
  className,
  defaultCountry,
  value,
  label,
  onChange,
  placeholder,
  style,
  initialValueFormat,
  countries,
  inputStyle,
}: TPhoneInput) => {
  return (
    <div className={styles.phoneInputGroup} style={style}>
      {label && <h6 className={styles.phoneInputLabel}>{label}</h6>}
      <PhoneInput
        initialValueFormat={initialValueFormat}
        defaultCountry={defaultCountry}
        countries={countries}
        placeholder={placeholder}
        value={value}
        style={{ border: "2px solid #F2F2F2", borderRadius: "3px", ...inputStyle }}
        className={`${styles.phoneInputWrapper} ${className ? className : ""}`}
        onChange={(e) => onChange(e)}
      />
    </div>
  );
};

export default TFBPhoneInput;
