import React, { useState, useEffect } from "react";
import "./CompetitionPage.scss";
import edit from "../../../../assets/icons/edit.png";
import delete_icon from "../../../../assets/icons/delete.png";
import { Dialog, DialogContent } from "@material-ui/core/";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";
import axios from "axios";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import _ from "lodash";

function CompetitionRanking(props) {
  const [editTeamId, setEditPopup] = useState("");
  const [newName, setNewName] = useState("");
  const [teamImage, setNewTeamImage] = useState("");
  const [deletedTeam, setDeletedTeam] = useState("");
  const [deleteTeamPopup, setDeleteTeamPopup] = useState(false);
  const [rankings, setRankings] = useState([]);
  const { languagePack } = props;

  const deleteTeam = () => {
    const payload = {
      id_team: deletedTeam,
    };
    axios
      .post("matches/delete_team_from_group", payload)
      .then(() => {
        props.getMatches();
        props.updateCompetitionDetails();
        setDeleteTeamPopup(false);
        setDeletedTeam("");
      })
      .catch((err) => console.log(err));
  };

  const {
    data: alphaRankingData,
    loading: loadingAlphaRankingData,
    refetch: refetchAlphaRankingData,
  } = useAxiosGet(
    `competitionSourceAlfa/get_competition_ranking_by_team?id_competition=${
      props.competitionInfo?.frfotbal_id_competition
    }${
      props.competitionInfo?.frfotbal_id_serie
        ? `&id_serie=${props.competitionInfo?.frfotbal_id_serie}`
        : ""
    }&id_team=${props.competitionInfo?.frfotbal_id_team}`,
    true
  );

  const {
    data: betaRankingData,
    loading: loadingBetaRankingData,
    refetch: refetchBetaRankingData,
  } = useAxiosGet(
    `competitionSourceBeta/get_competition_ranking_by_team?county=${props.competitionInfo?.frfajf_county}&competition_path=${props.competitionInfo?.frfajf_competition_path}&id_team=${props.competitionInfo?.frfajf_id_team}`,
    true
  );

  useEffect(() => {
    if (
      !props.competitionInfo?.frfotbal_id_competition &&
      !props.competitionInfo?.frfajf_id_competition
    )
      setRankings(props.teams);
  }, [
    props.competitionInfo.frfajf_id_competition,
    props.competitionInfo.frfotbal_id_competition,
    props.teams,
  ]);

  useEffect(() => {
    if (props.competitionInfo?.frfotbal_id_competition) {
      refetchAlphaRankingData();
    }
  }, [props.competitionInfo.frfotbal_id_competition]);

  useEffect(() => {
    if (props.competitionInfo?.frfotbal_id_competition) {
      setRankings(alphaRankingData);
    }
  }, [alphaRankingData, props.competitionInfo.frfotbal_id_competition]);

  useEffect(() => {
    if (props.competitionInfo?.frfajf_id_competition) {
      refetchBetaRankingData();
    }
  }, [props.competitionInfo.frfajf_id_competition]);

  useEffect(() => {
    if (props.competitionInfo?.frfajf_id_competition) {
      setRankings(betaRankingData);
    }
  }, [betaRankingData, props.competitionInfo.frfajf_id_competition]);

  const editTeamName = () => {
    const payload = {
      id_team: editTeamId,
      new_name: newName,
    };
    axios
      .post("matches/edit_team_name", payload)
      .then(() => {
        props.refreshTeams();
        setEditPopup("");
      })
      .catch((err) => console.log(err));
  };

  const handleChangeImage = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setNewTeamImage(reader.result);
    };
  };

  const editTeam = () => {
    const payload = {
      id_team: editTeamId,
      new_name: newName,
      new_image: teamImage,
    };
    axios
      .post("matches/edit_team", payload)
      .then(() => {
        props.refreshTeams();
        setEditPopup("");
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {}, [props.teams]);

  return (
    <div className="competition-ranking-list-container">
      <div className="ranking-row-table-header">
        <div className="ranking-position">{languagePack["position"]}</div>
        <div className="ranking-team">{languagePack["team_name"]}</div>
        <div className="ranking-stat">M</div>
        <div className="ranking-stat">W</div>
        <div className="ranking-stat">D</div>
        <div className="ranking-stat">L</div>
        <div className="ranking-points">{languagePack["points_short"]}</div>
        {/* <div className="ranking-options"></div> */}
      </div>
      {_.map(rankings?.list, (item, index) => {
        return (
          <div
            style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
            key={"ranking-comp-" + index}
            className={
              item.own_team == 1
                ? "ranking-row ranking-row-outlined"
                : "ranking-row"
            }
          >
            <div className="ranking-position">{index + 1}.</div>
            <div className="ranking-team">{item?.team_name?.toUpperCase()}</div>
            <div className="ranking-stat">{item?.matches_played}</div>
            <div className="ranking-stat">{item?.matches_won}</div>
            <div className="ranking-stat">{item?.matches_draw}</div>
            <div className="ranking-stat">{item?.matches_lost}</div>
            {/* <div className="ranking-stat">{item.goals_diff}</div> */}
            <div className="ranking-points">{item?.points}</div>
            {/* <div className="ranking-options">
                            <img src={edit} alt="" style={{ cursor: 'pointer' }} onClick={() => {
                                setEditPopup(item.id_team)
                                setNewName(item.team_name)
                                if(item.team_logo != "") { 
                                    setNewTeamImage(window.baseURL+"uploads/team_logos/"+item.team_logo);
                                } else {
                                    setNewTeamImage("");
                                }
                            }} />
                        </div> */}
            {props.typeGroup && props.hasAccessToEditCompetition && (
              <div className="ranking-options" style={{ cursor: "pointer" }}>
                {/* <img src={edit} alt="" style={{ cursor: 'pointer' }} onClick={() => {
                            }} /> */}
                <img
                  src={delete_icon}
                  alt="delete"
                  onClick={() => {
                    setDeletedTeam(item.id_team);
                    setDeleteTeamPopup(true);
                  }}
                />
              </div>
            )}
          </div>
        );
      })}

      {/* <Dialog open={editTeamId !== ""} onClose={() => setEditPopup("")}>
                <DialogContent>
                    <div className="edit-team-popup-container">
                        <div className="edit-team-popup-title">{languagePack["edit_team"]}</div>
                        <input className="edit-team-input" value={newName} onChange={(e) => setNewName(e.target.value)} />
                        <div className="add-team-manual-image">
                            <div className="image-input-container">
                                <span>{languagePack["team_logo"]}</span>
                                <input
                                    type="file"
                                    className="add-news-image-input"
                                    id="customFile"
                                    onChange={(e) => handleChangeImage(e)}
                                />
                                <label
                                    className="add-file-form-file-label"
                                    htmlFor="customFile"
                                >
                                    <div className="image-view">
                                        {teamImage !== "" ? <img src={teamImage} /> : <span><Icon name="arrow up" className="cusor-pointer" size="small" /></span>}
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="edit-team-buttons-container">
                            <Button className="edit-team-button" onClick={() => deleteTeam()} style={{ backgroundColor: '#666666' }}>{props.languagePack['admin_delete']} <Icon name="delete" size="small" className="white-icon" /></Button>
                            <Button className="edit-team-button" onClick={() => editTeam()} style={{ backgroundColor: window.clubCustomization.primary_color }}>{props.languagePack['admin_save']} <Icon name="check" size="small" className="white-icon" /></Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog> */}
      <Dialog
        open={deleteTeamPopup}
        // onClose={() => setDeleteTeamPopup(false)}
      >
        <DialogContent className="add-team-manual-wrapper">
          <div className="edit-team-popup-container">
            <div className="edit-team-popup-title">
              {/* {languagePack["edit_team"]} */}
              {"Delete team from group"}
            </div>
            <div className="delete-popup-message">
              {"Are you sure you want to remove this team from group?"}
            </div>
            <div className="edit-team-buttons-container">
              <Button
                className="edit-team-button"
                onClick={() => setDeleteTeamPopup(false)}
                style={{ backgroundColor: "#666666" }}
              >
                {props.languagePack["admin_cancel"]}
                <Icon name="delete" className="white-icon" />
              </Button>
              <Button
                className="edit-team-button"
                onClick={() => deleteTeam()}
                style={{ backgroundColor: "red" }}
              >
                {/* {props.languagePack['admin_save']} */}
                {"Yes, delete."}
                <Icon name="trash alternate" className="white-icon" />
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(CompetitionRanking);
