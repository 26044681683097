import React from "react";
import { Dropdown, Button, Icon } from "semantic-ui-react";
import { store } from "react-notifications-component";
import axios from "axios";
import { connect } from "react-redux";
import * as myConstants from "./../../../app/utils/constants";
import { baseUrl } from "../../utils/utilFunctions";

class AddPlayerBonus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conditions_dropdown: [],
      addedCondition: "",
      addedValue: "",
      addedAmount: "",
      addedCurrency: props.currentUser.currency,
      selectedConditions: [],
    };
  }

  componentDidMount = () => {
    this.getConditions();
    this.getCurrency();
  };

  getConditions() {
    axios
      .get(
        `upload/get_bonus_conditions?id_club=${this.props.currentUser.id_club}`
      )
      .then((res) => this.setState({ conditions_dropdown: res.data }))
      .catch((err) => console.log(err));
  }

  handleChangeDropdown = (e, value) => {
    this.setState({ [value.name]: value.value });
  };

  handleChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeCurrency = (event, newCurrency) => {
    this.setState({ addedCurrency: newCurrency });
  };

  refreshInputs = () => {
    this.setState({
      addedCondition: "",
      addedValue: "",
    });
  };

  addCondition = () => {
    const newCondition = {
      name: this.state.addedCondition,
      value: this.state.addedValue,
    };

    this.setState(
      {
        selectedConditions: this.state.selectedConditions.concat(newCondition),
      },
      this.refreshInputs
    );
  };

  convertCurrency = (value) => {
    if (this.state.addedCurrency === "EUR") return value;
    else return value / this.state.rates["RON"];
  };

  handleSubmitBonus = () => {
    const bonus = {
      id_player: this.props.id_player,
      id_player_contract: this.props.id_contract,
      bonus_amount: this.state.addedAmount,
      bonus_conditions: this.state.selectedConditions,
      currency: this.state.addedCurrency,
    };

    if (this.props.type == "player") {
      axios.post("upload/add_bonus", bonus).then(() => {
        store.addNotification({
          title: this.props.languagePack.contract_operations,
          message: this.props.languagePack.bonus_added_successfully,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.props.closePopup();
        this.props.getBonuses();
      });
    } else if (this.props.type == "staff") {
      axios.post("upload/add_bonus_staff", bonus).then(() => {
        store.addNotification({
          title: this.props.languagePack.contract_operations,
          message: this.props.languagePack.bonus_added_successfully,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        this.props.closePopup();
        this.props.getBonuses();
      });
    }
  };

  getCurrency = () => {
    fetch(baseUrl("club/get_currency_rates"))
      .then((res) => res.json())
      .then((data) => this.setState({ rates: data }));
  };
  convertOneToTrue = (value) => {
    if (value) {
      if (value.require_value === "1") return true;
      else return false;
    } else return false;
  };

  render() {
    const element = this.state.conditions_dropdown.find(
      (item) => item.value === this.state.addedCondition
    );
    const { languagePack } = this.props;
    return (
      <div className="add-bonus-container">
        <div className="add-bonus-row">
          <Dropdown
            placeholder={languagePack["select_condition"]}
            options={this.state.conditions_dropdown}
            value={this.state.addedCondition}
            onChange={this.handleChangeDropdown}
            name="addedCondition"
            selection
            className="condition-name"
          />
        </div>

        {this.convertOneToTrue(element) && (
          <div className="add-bonus-row">
            <input
              type="text"
              value={this.state.addedValue}
              onChange={(e) => this.setState({ addedValue: e.target.value })}
              placeholder={languagePack["add_condition_value"]}
              className="condition-value"
            />
          </div>
        )}

        <div className="add-bonus-buttons-row">
          <Button
            onClick={() =>
              this.setState({ addedCondition: "", addedValue: "" })
            }
            className="custom-button-add-bonus cancel-button"
          >
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon name="ban" className="darkgrey-icon" />
            </div>
          </Button>
          <Button
            onClick={this.addCondition}
            className="custom-button-add-bonus add-button"
          >
            <div className="button-content">
              {languagePack["add_condition"]}
              <Icon name="add" className="white-icon" />
            </div>
          </Button>
        </div>

        <div className="active-cond-number">
          {languagePack["active_conditions"]}:{" "}
          {this.state.selectedConditions.length}
        </div>
        {this.state.selectedConditions.length > 0 ? (
          <div className="conditions-container">
            {this.state.selectedConditions.map((item, index) => {
              return (
                <div
                  className="condition-container"
                  style={{ backgroundColor: index % 2 === 0 ? "#fafafa" : "" }}
                >
                  <div className="condition-name">{item.name}</div>
                  {item.value && (
                    <div style={{ display: "flex" }}>
                      <div className="condition-name">:</div>
                      <div className="condition-value">{item.value}</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
        <div className="active-cond-number">{languagePack["bonus"]}</div>
        <div className="add-bonus-row" style={{ marginBottom: 30 }}>
          <input
            type="text"
            value={this.state.addedAmount}
            onChange={(e) => {
              const new_value = e.target.value.replace(/[^0-9]*/g, "");
              this.setState({ addedAmount: new_value });
            }}
            placeholder={languagePack.add_bonus_active_conditions}
            className="condition-value"
          />

          <div
            className="currency-container"
            style={{ marginRight: 50, marginLeft: 20 }}
            onClick={() => this.setState({ addedCurrency: "RON" })}
          >
            <div
              className={
                this.state.addedCurrency === "RON"
                  ? "currency-checkbox currency-checkbox-selected"
                  : "currency-checkbox"
              }
            />
            <div className="currency-name">RON</div>
          </div>

          <div
            className="currency-container"
            onClick={() => this.setState({ addedCurrency: "EUR" })}
          >
            <div
              className={
                this.state.addedCurrency === "EUR"
                  ? "currency-checkbox currency-checkbox-selected"
                  : "currency-checkbox"
              }
            />
            <div className="currency-name">EUR</div>
          </div>
        </div>

        <div className="add-bonus-buttons-row add-button">
          <Button
            className="custom-button-add-bonus add-button"
            onClick={() => this.props.closePopup()}
          >
            <div className="button-content">
              {languagePack["admin_cancel"]}
              <Icon name="ban" className="white-icon" />
            </div>
          </Button>
          <Button
            className="custom-button-add-bonus green-button"
            onClick={this.handleSubmitBonus}
            disabled={this.state.selectedConditions.length === 0}
          >
            <div className="button-content">
              {languagePack["Add_bonus"]}
              <Icon name="add" className="white-icon" />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
  currencyItemSelected:
    myConstants.currencies[auth?.user?.currency] || myConstants.currencies.EUR,
}))(AddPlayerBonus);
