import React, { useContext } from "react";
import { connect } from "react-redux";
import { isTFB } from "../../../../utils/utilFunctions";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import { CompetitionsContext } from "./Competitions";
import { updateQuickSetup } from "../../../../utils/api";

function DeleteCompetition({ languagePack, currentUser }) {
  const {
    loading: loadingDeleteCompetition,
    postData: postDeleteCompetition,
  } = useAxiosPost("matches/delete_competition_2");

  const competitionsContext = useContext(CompetitionsContext);

  function deleteCompetition() {
    const payload = {
      id: competitionsContext.wantToDeleteComp,
      id_club: currentUser.id_club,
      is_tfb: isTFB(),
    };
    const responseHandler = (res) => {
      if (Number(res?.success) === 1) {
        competitionsContext.onCloseDeleteCompetition();
        competitionsContext.refetchCompetitions();
        showNotification(
          "success",
          languagePack["manage_competitions"],
          languagePack["competition_deleted_success"]
        );

        let clubQuickSetup = res?.club_quick_setup;
        updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });
      }
    };

    postDeleteCompetition(payload, responseHandler);
  }
  const { renderBtn: renderBtnRight } = useRenderButton(
    deleteCompetition,
    "red",
    "delete",
    languagePack.admin_delete,
    {},
    true,
    false,
    loadingDeleteCompetition
  );
  const { renderBtn: renderBtnLeft } = useRenderButton(
    competitionsContext.onCloseDeleteCompetition,
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  return (
    <TFBDialog
      title={languagePack.edit_minutes_per_half}
      open
      closePopup={competitionsContext.onCloseDeleteCompetition}
      btnRight={renderBtnRight}
      btnLeft={renderBtnLeft}
    >
      <div className="delete-user-popup-container">
        <div className="delete-user-text">
          {languagePack.delete_competition_text}
        </div>
      </div>
    </TFBDialog>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(DeleteCompetition);
