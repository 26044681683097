import React from "react";
import { Modal, Header, Icon, Button } from "semantic-ui-react";
// import "./Calendar.scss";
import axios from "axios";
import { store } from "react-notifications-component";
import { logout } from "./../../../utils/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class EventVenuePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    //   editEvent = () => {
    //     axios.post("calendar/edit_event");
    //   };

    deleteEvent = () => {
        const event = {
            id_event: this.props.event.id,
        };
        axios
            .post("club/delete_venue_event", event)
            .then((res) => {
                if (res.data == 1) {
                    this.props.refreshEvents();
                    this.props.closePopup();
                    store.addNotification({
                        title: "Evenimente calendar",
                        message: "Evenimentul a fost sters din programul echipei",
                        type: "success",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                } else {
                    this.props.closePopup();
                    store.addNotification({
                        title: "Evenimente calendar",
                        message: "Evenimentul nu a fost sters. Mai incearca o data!",
                        type: "danger",
                        insert: "bottom",
                        container: "bottom-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 2000,
                        },
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    render() {
        const { event, language, languagePack } = this.props;

        return (
            <>
                <Header
                    icon="calendar alternate"
                    style={{ display: "flex" }}
                    className="event-modal-header-container"
                    content={
                        <div className="event-modal-header">
                            <div className="header-text">{languagePack['event_details']}</div>
                            <Icon
                                name="close"
                                onClick={() => this.props.closePopup()}
                                size="large"
                                style={{ cursor: "pointer" }}
                            />
                        </div>
                    }
                />
                <Modal.Content className="event-detail-content">
                    <div>
                        {" "}
                        {event.description === "birthday" && (
                            <>
                                <div className="event-content-row">
                                    <Icon name="birthday cake" size="large" />
                                    <div>{event.name}</div>
                                </div>
                                <div className="event-content-row">
                                    <div className="date-label">{languagePack['injury_date']}:</div>
                                    <div className="event-date">
                                        {event.date_start !== event.date_end &&
                                            event.date_end !== "Invalid date"
                                            ? event.date_start + "-" + event.date_end
                                            : event.date_start}
                                    </div>
                                </div>
                            </>
                        )}
                        {event.description === "match" && (
                            <>
                                <div className="event-content-row">
                                    <Icon name="futbol" color="green" />
                                    <b>{event.name}</b>
                                </div>
                                <div className="event-content-row">
                                    <div className="date-label">{languagePack['injury_date']}:</div>
                                    <div className="event-date">
                                        {event.date_start !== event.date_end &&
                                            event.date_end !== "Invalid date"
                                            ? event.date_start + "-" + event.date_end
                                            : event.date_start}
                                    </div>
                                </div>
                                {typeof event.location !== "undefined" && (
                                    <div className="event-content-row">
                                        <div className="date-label">{languagePack['location']}:</div>
                                        <div className="event-date">{event.location}</div>
                                    </div>
                                )}
                                <div className="event-content-row">
                                    <div className="hour-label">{languagePack['hour']}:</div>
                                    <div className="event-hour">
                                        {event.hour_start !== "02:00"
                                            ? event.hour_end !== "Invalid date"
                                                ? event.hour_start + "-" + event.hour_end
                                                : event.hour_start
                                            : "-"}
                                    </div>
                                </div>
                            </>
                        )}
                        {event.description !== "birthday" && event.description !== "match" && (
                            <>
                                <div className="event-content-row">
                                    {languagePack['staff_member_name']}: <div className="event-date">{event.name}</div>
                                </div>
                                <div className="event-content-row">
                                    <div className="date-label-event">{languagePack['injury_date']}:</div>
                                    <div className="event-date">
                                        {event.date_start !== event.date_end &&
                                            event.date_end !== "Invalid date"
                                            ? event.date_start + "-" + event.date_end
                                            : event.date_start}
                                    </div>
                                </div>
                                <div className="event-content-row">
                                    <div className="hour-label">{languagePack['hour']}:</div>
                                    <div className="event-hour">
                                        {event.hour_end !== "Invalid date"
                                            ? event.hour_start + "-" + event.hour_end
                                            : event.hour_start}
                                    </div>
                                </div>
                                <div className="event-content-row" style={{ display: "block" }}>
                                    {languagePack['staff_description']}:
                                    <div className="event-desc-content">
                                        {event.event_added_description}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Content>
                {event.description === "birthday" ? '' : (
                    <div className="event-modal-buttons">
                        <Button
                            color="red"
                            onClick={this.deleteEvent}
                        >
                            <Icon
                                name="remove"
                            /> {languagePack['admin_delete']}
                        </Button>
                        {/* <Button color="green">
              <Icon name="checkmark" /> {languagePack['admin_save']}
            </Button> */}
                    </div>)}
            </>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logout,
        },
        dispatch
    );
}
export default connect(
    ({ layoutService, auth }) => ({
        languagePack: layoutService.languagePack,
        language: layoutService.lang,
        currentUser: auth.user,
        token: auth?.user?.token,
    }),
    mapDispatchToProps
)(EventVenuePopup);
