import React from "react";
import { connect } from "react-redux";

import { updateQuickSetup } from "../../../../utils/api";
import { bindActionCreators } from "redux";
import { showNotification, useAxiosPost } from "../../../../components/customHooks/useAxiosPost";
import useRenderButton from "../../../../components/customHooks/useRenderButton";
import TFBDialog from "../../../../components/design-system/Dialog/TFBDialog";
import { isTFB } from "../../../../utils/utilFunctions";

const DeleteTeam = (props) => {

  const { openPopup, closePopup, refreshList, languagePack, team, updateQuickSetup, token } = props;

  const { postData: deleteRequest, loading: loadingDeleteRequest } = useAxiosPost("club/delete_team_3");

  const handleResponse = (response) => {
    if (response?.success == 1) {
      closePopup();
      refreshList();
      showNotification(
        "success",
        languagePack.teams,
        languagePack.success_delete_team
      );
      let clubQuickSetup = response.club_quick_setup;
      if (clubQuickSetup) {
        updateQuickSetup({
          ...clubQuickSetup,
          isSetupOpen: false,
        });
      }
    } else {
      showNotification(
        "danger",
        languagePack.teams,
        languagePack.fail_delete_team
      );
    }
  };

  const deleteTeam = () => {
    const payload = {
      id_team: team.id_team,
      id_club: team.id_club,
      is_tfb: isTFB(),
      token: token,
    };

    deleteRequest(payload, handleResponse);
  }

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => deleteTeam(),
    "red",
    "delete",
    languagePack.admin_delete,
    {},
    true,
    false,
    loadingDeleteRequest,
  );

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack["delete_team"]}
      open={openPopup}
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <div className="pop-up-dialog-delete-text">
        {languagePack.delete_team_message}
      </div>
    </TFBDialog>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateQuickSetup,
    },
    dispatch
  );
}

export default connect(
  ({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
  }),
  mapDispatchToProps
)(DeleteTeam);
