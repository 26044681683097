import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./StaffMembers.scss";

import AddStaffForm from "./AddStaffForm";
import RolesList from "../RolesList/RolesList.js";
// import EditStaffDetails from "./EditStaffDetails";

import {
  TFBTableCell,
  sxStickyColumn,
} from "../../../../components/reusable/useStyles";
import TFBCard from "../../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBCardBody from "../../../../components/design-system/Card/TFBCardBody";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";
import TFBPlaceholder from "../../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBPlayerWithAvatar from "../../../../components/design-system/PlayerWithAvatar/TFBPlayerWithAvatar";
import _ from "lodash";
import { baseUrl } from "../../../../utils/utilFunctions";

function StaffMembers(props) {
  const [staffMembers, setStaffMembers] = useState(props.staffMembers);
  const [dialog, setDialog] = useState(props.openAddStaffPopup);
  const [listDialog, setListDialog] = useState(false);
  const [dataFetched, setDataFetched] = useState(props.staffDataFetched);
  const [editStaffItem, setEditStaffItem] = useState({});

  const { languagePack, history } = props;

  useEffect(() => {
    setStaffMembers(props.staffMembers);
  }, [props.staffMembers]);

  useEffect(() => {
    if (_.get(props.history, "location.state.openAddStaff") || false) {
      props.history.replace({ ...history.location, state: undefined });
      setDialog(true);
    }
  }, [history.location]);

  useEffect(() => {
    setDataFetched(props.staffDataFetched);
  }, [props.staffDataFetched]);

  return (
    <TFBCard>
      <TFBCardHeader
        title={languagePack.staff_members}
        className="col-lg-4-header-btn"
        contentClassName="col-lg-4-header-btn"
      >
        <div className="col-lg-4-header-btn">
          <TFBButton onClick={() => setListDialog(true)} color={"darkGray"}>
            {languagePack["roles_list"]}
          </TFBButton>
          <TFBButton
            onClick={() => setDialog(true)}
            endIcon="true"
            color={"green"}
            renderIcon={() => <TFBIcon name={"add"} />}
          >
            {languagePack["add_member"]}
          </TFBButton>
        </div>
      </TFBCardHeader>
      <TFBCardBody>
        {dataFetched ? (
          staffMembers.length > 0 ? (
            <TableContainer>
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn} align="left">
                      {languagePack.staff_member_name}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.staff_member_role}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.staff_license}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffMembers.map((row, index) => (
                    <TableRow
                      key={"staff-member-" + index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                        align="left"
                      >
                        <TFBPlayerWithAvatar
                          playerName={row.full_name}
                          avatarUrl={baseUrl(`uploads/staff_members/${row.id_staff_member}.jpg`)}
                          onClick={() =>
                            history.push(`/club/staff/${row.id_staff_member}`)
                          }
                        />
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.user_role}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.license != languagePack.no_license &&
                        row.license != null
                          ? row.license
                          : "-"}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div
                          className="actions-btns-container"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            onClick={() =>
                              history.push(`/club/staff/${row.id_staff_member}`)
                            }
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack.no_staff_members} />
          )
        ) : (
          <div className="loader-container" style={{ marginTop: 50 }}>
            <Loader
              type="TailSpin"
              color={window.clubCustomization.primary_color}
              height={40}
              width={40}
            />
          </div>
        )}
      </TFBCardBody>

      {/* <Dialog
        open={dialog}
      >
        <DialogContent className="add-staff-wrapper"> */}
      {dialog && (
        <AddStaffForm
          closePopup={() => setDialog(false)}
          id_club={props.id_club}
          refreshMembers={() => props.refreshStaffMembers()}
          languagePack={languagePack}
          open={dialog}
        />
      )}
      {/* </DialogContent>
      </Dialog> */}

      <Dialog open={listDialog} onClose={() => setListDialog(false)}>
        <DialogContent className="roles-list-wrapper">
          <RolesList
            closePopup={() => setListDialog(false)}
            id_club={props.id_club}
            languagePack={languagePack}
          />
        </DialogContent>
      </Dialog>
    </TFBCard>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
}))(StaffMembers);
