import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

import styles from "./ExternTeamLogos.module.scss";
import TFBIconButton from "../../../../components/design-system/IconButton/TFBIconButton";
import TFBFileInput from "../../../../components/design-system/FileInput/TFBFileInput";
import CircularLoader from "../../../../components/reusable/Loader";

function ExternTeamLogos({
  thumbnails,
  languagePack,
  selectedLogo,
  setSelectedLogo,
  loading,
  uploadedLogo,
  clubIndex = undefined,
  onChangeFile,
  onRemoveUploadedLogo,
  backgroundColor = "white",
}) {
  return (
    <div className={styles.wrapper}>
      {loading ? (
        <CircularLoader />
      ) : (
        _.map(thumbnails, (img, i) => (
          <div style={{ display: "flex", gap: "10px" }} key={i}>
            {" "}
            <div
              onClick={
                clubIndex >= 0
                  ? () => setSelectedLogo(clubIndex, i)
                  : () => setSelectedLogo(i)
              }
              className={`${i === selectedLogo && uploadedLogo === "" ? styles.selected : ""
                } ${styles.logoContainer}`}
            >
              <img height={75} src={img} alt="logo" />
            </div>{" "}
            {i === thumbnails.length - 1 && (
              <div
                className={`${uploadedLogo !== "" ? styles.selected : ""}  ${styles.logoContainer
                  }`}
              >
                {uploadedLogo !== "" ? (
                  <div className={styles.imgDiv}>
                    <TFBIconButton
                      name="x-small"
                      onClick={
                        clubIndex >= 0
                          ? () => onRemoveUploadedLogo(clubIndex)
                          : onRemoveUploadedLogo
                      }
                      style={{
                        height: "20px",
                        borderRadius: "10px",
                        padding: "0px",
                        width: "20px",
                        position: "absolute",
                        top: "-16px",
                        zIndex: "1000000",
                        right: "-17.5px",
                      }}
                    />
                    <img
                      style={{ height: "100%" }}
                      src={uploadedLogo}
                      alt="section"
                    />
                  </div>
                ) : (
                  <TFBFileInput
                    placeholder={languagePack.upload_image}
                    inputFileStyle={{
                      flexDirection: "column",
                      gap: "8px",
                      minHeight: "none",
                      justifyContent: "center",
                      backgroundColor: backgroundColor,
                      border: "none",
                      alignItems: "center",
                    }}
                    accept=".png, .jpg, .jpeg, .webp"
                    onChange={
                      clubIndex >= 0
                        ? (e) => onChangeFile(e, clubIndex)
                        : onChangeFile
                    }
                    insideLabelStyle={{
                      textAlign: "center",
                    }}
                  />
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ExternTeamLogos);
