import React from "react";
import axios from "axios";
import "./Photos.scss";
import ExpandedImage from "./ExpandedImage";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import PhotoElem from "./PhotoElem";
import styles from "./PlayerGallery.module.scss";
import DeletePhotoFromPlayerGallery from "./DeletePhotoFromPlayerGallery";
import AddPhotoToPlayerGallery from "./AddPhotoToPlayerGallery";
import CircularLoader from "../../../components/reusable/Loader";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import VideoElem from "./VideoElem";
import AddVideoToPlayerGallery from "./AddVideoToPlayerGallery";
import ExpandedVideo from "./ExpandedVideo";
import DeleteVideoFromPlayerGallery from "./DeleteVideoFromPlayerGallery";
import EditBiography from "./EditBiography";
import { CircularProgressbar } from "react-circular-progressbar";

class PlayerGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAccessToPlayer: false,
      redirectToTeams: false,

      loaded_images: false,
      images: [],
      open_image_viewer: false,
      current_image_viewer_index: 0,
      open_delete_image: false,
      deleting_image: {},
      open_add_image: false,

      loaded_videos: false,
      videos: [],
      open_video_viewer: false,
      current_video_viewer_index: 0,
      open_delete_video: false,
      deleting_video: {},
      open_add_video: false,
      uploading_video: false,
      uploading_percentage: 0,

      loaded_bio: false,
      bio: "",
      open_edit_bio: false,
    };
  }

  componentDidMount = () => {
    this.hasAccessToPlayer();
  };

  hasAccessToPlayer = () => {
    axios
      .get(
        `user/has_access_to_player?id_player=${this.props.match.params.id}&id_user=${this.props.currentUser.id_user}`
      )
      .then((res) => {
        if (res.data == 1) {
          this.setState({
            hasAccessToPlayer: true,
          });
          this.getGalleryImages();
          this.getGalleryVideos();
          this.getPlayerBio();
        } else {
          this.setState({
            redirectToTeams: true,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  getGalleryImages = () => {
    axios
      .get(`player/get_player_photo_gallery?id_entity=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`)
      .then((res) => {
        this.setState({
          loaded_images: false,
        }, () => {
          this.setState({
            images: res.data.list,
            loaded_images: true,
          });
        })
      })
      .catch((err) => console.log(err));
  }

  getGalleryVideos = () => {
    axios
      .get(`player/get_player_video_gallery?id_entity=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`)
      .then((res) => {
        this.setState({
          loaded_videos: false,
        }, () => {
          this.setState({
            videos: res.data.list,
            loaded_videos: true,
          });
        })
      })
      .catch((err) => console.log(err));
  }

  getPlayerBio = () => {
    axios
      .get(`player/get_player_bio?id_entity=${this.props.match.params.id}&id_club=${this.props.currentUser.id_club}&id_user=${this.props.currentUser.id_user}&token=${this.props.token}`)
      .then((res) => {
        this.setState({
          loaded_bio: false,
        }, () => {
          this.setState({
            bio: res.data.bio,
            loaded_bio: true,
          });
        })
      })
      .catch((err) => console.log(err));
  }

  openImageViewer = (index) => {
    this.setState({
      open_image_viewer: true,
      current_image_viewer_index: index,
    })
  }

  closeImageViewer = () => {
    this.setState({
      open_image_viewer: false,
      current_image_viewer_index: 0,
    })
  }

  openDeleteImage = (item) => {
    this.setState({
      open_delete_image: true,
      deleting_image: item,
    });
  }

  closeDeleteImage = () => {
    this.setState({
      open_delete_image: false,
      deleting_image: {},
    });
  }

  openAddImage = () => {
    this.setState({
      open_add_image: true,
    })
  }

  closeAddImage = () => {
    this.setState({
      open_add_image: false,
    })
  }

  openVideoViewer = (index) => {
    this.setState({
      open_video_viewer: true,
      current_video_viewer_index: index,
    })
  }

  closeVideoViewer = () => {
    this.setState({
      open_video_viewer: false,
      current_video_viewer_index: 0,
    })
  }

  openDeleteVideo = (item) => {
    this.setState({
      open_delete_video: true,
      deleting_video: item,
    });
  }

  closeDeleteVideo = () => {
    this.setState({
      open_delete_video: false,
      deleting_video: {},
    });
  }

  openAddVideo = () => {
    this.setState({
      open_add_video: true,
    })
  }

  closeAddVideo = () => {
    this.setState({
      open_add_video: false,
    })
  }

  openEditBio = () => {
    this.setState({
      open_edit_bio: true,
    })
  }

  closeEditBio = () => {
    this.setState({
      open_edit_bio: false,
    })
  }

  updateVideoUplaoding = (state, percentage) => {
    this.setState({
      uploading_video: state,
      uploading_percentage: percentage,
    })
  }

  render() {
    if (this.state.redirectToTeams) {
      return <Redirect to="/club" />;
    }

    const { languagePack } = this.props;
    return (
      <div className="row">
        <div className="col-lg-6 col-lg-padding">
          <TFBCard style={{ height: "100%" }}>
            <TFBCardHeader title={languagePack["gallery_photos_title"]}>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={this.openAddImage}
              >
                {languagePack.gallery_add_button_images}
              </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
              {this.state.loaded_images ?
                (this.state.images.length > 0 ?
                  <div className={styles.playerPhotosContainer}>
                    {this.state.images.map((item, index) => {
                      return (
                        <PhotoElem
                          key={"gallery-photo-" + index}
                          src={item.image_url}
                          onClick={() => this.openImageViewer(index)}
                          onDelete={() => this.openDeleteImage(item)}
                        />
                      );
                    })}
                  </div> :
                  <TFBPlaceholder text={languagePack.player_gallery_no_images} />
                ) :
                <CircularLoader />
              }
              {this.state.open_image_viewer &&
                <ExpandedImage
                  closeExpand={this.closeImageViewer}
                  image={this.state.images[this.state.current_image_viewer_index].image_url}
                />
              }
              {this.state.open_delete_image &&
                <DeletePhotoFromPlayerGallery
                  isOpenPopup={this.state.open_delete_image}
                  closePopup={this.closeDeleteImage}
                  refreshList={this.getGalleryImages}
                  id={this.state.deleting_image?.id_player_image}
                />
              }
              {this.state.open_add_image &&
                <AddPhotoToPlayerGallery
                  isOpenPopup={this.state.open_add_image}
                  closePopup={this.closeAddImage}
                  refreshList={this.getGalleryImages}
                  id={this.props.match.params.id}
                />
              }
            </TFBCardBody>
          </TFBCard>
        </div>
        <div className="col-lg-6 col-lg-padding">
          <TFBCard style={{ height: "100%" }}>
            <TFBCardHeader title={languagePack["gallery_videos_title"]}>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={this.openAddVideo}
              >
                {languagePack["gallery_add_button_videos"]}
              </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
              {this.state.loaded_videos ?
                (this.state.videos.length > 0 ?
                  <div className={styles.playerPhotosContainer}>
                    {this.state.videos.map((item, index) => {
                      return (
                        <VideoElem
                          key={"gallery-video-" + index}
                          src={item.video_url}
                          onClick={() => this.openVideoViewer(index)}
                          onDelete={() => this.openDeleteVideo(item)}
                        />
                      );
                    })}
                    {this.state.uploading_video &&
                      <div className={styles.photoElemContainer}>
                        <div className={styles.loaderContainer}>
                          <CircularProgressbar
                            value={this.state.uploading_percentage}
                            text={`${this.state.uploading_percentage}%`}
                          />
                        </div>
                      </div>
                    }
                  </div> :
                  <TFBPlaceholder text={languagePack.player_gallery_no_videos} />
                ) :
                <CircularLoader />
              }
              {this.state.open_video_viewer &&
                <ExpandedVideo
                  closeExpand={this.closeVideoViewer}
                  video={this.state.videos[this.state.current_video_viewer_index].video_url}
                />
              }
              {this.state.open_delete_video &&
                <DeleteVideoFromPlayerGallery
                  isOpenPopup={this.state.open_delete_video}
                  closePopup={this.closeDeleteVideo}
                  refreshList={this.getGalleryVideos}
                  id={this.state.deleting_video?.id_player_video}
                />
              }
              {this.state.open_add_video &&
                <AddVideoToPlayerGallery
                  isOpenPopup={this.state.open_add_video}
                  closePopup={this.closeAddVideo}
                  refreshList={this.getGalleryVideos}
                  id={this.props.match.params.id}
                  updateVideoUplaoding={this.updateVideoUplaoding}
                />
              }
            </TFBCardBody>
          </TFBCard>
        </div>
        <div className="col-lg-12 col-lg-padding">
          <TFBCard>
            <TFBCardHeader title={languagePack["gallery_bio_title"]}>
              <TFBButton
                color="green"
                renderIcon={() => <TFBIcon name="add" />}
                onClick={this.openEditBio}
              >
                {languagePack["gallery_add_button_bio"]}
              </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
              {this.state.loaded_bio ?
                (this.state.bio != "" ?
                  <div> {this.state.bio}</div> :
                  <TFBPlaceholder text={languagePack.gallery_bio_placeholder} />
                ) :
                <CircularLoader />
              }
              {this.state.open_edit_bio &&
                <EditBiography
                  isOpenPopup={this.state.open_edit_bio}
                  closePopup={this.closeEditBio}
                  refreshList={this.getPlayerBio}
                  id={this.props.match.params.id}
                  bio={this.state.bio}
                />
              }
            </TFBCardBody>
          </TFBCard>
        </div>
      </div>
    )

  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(PlayerGallery);
