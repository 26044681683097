import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "./TFBFileInput.module.scss";
import { TFileInput } from "../types";
import TFBIcon from "../Icon/TFBIcon";
import useId from "@mui/material/utils/useId";

const TFBFileInput = forwardRef(
  (
    {
      label,
      accept = "*/*",
      multiple = false,
      files,
      onChange,
      placeholder = "Select file",
      style,
      inputFileStyle,
      insideLabelStyle,
    }: TFileInput,
    ref
  ) => {
    const id = useId();

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      openFileInput() {
        if (inputRef?.current) {
          inputRef.current.click();
        }
      },
      clearFileInput() {
        if (inputRef?.current) {
          inputRef.current.value = "";
        }
      },
    }));
    const handleClickEl = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };
    return (
      <div className={styles.inputGroup} style={style}>
        {label && <h6 className={styles.inputLabel}>{label}</h6>}
        <div
          className={styles.tfbInputFileContainer}
          onClick={handleClickEl}
          style={inputFileStyle}
        >
          <input
            id={"tfbInputFile-" + id}
            ref={inputRef}
            className="tfbInputFile"
            type="file"
            accept={accept}
            multiple={multiple}
            onChange={onChange}
          />
          <label
            style={insideLabelStyle}
            className="tfbInputFile"
          >
            {Array.isArray(files) && Array.from(files).length > 0
              ? Array.from(files).join(",\r\n")
              : placeholder}
          </label>
          <TFBIcon name="arrow-up" color="gray" />
        </div>
      </div>
    );
  }
);

export default TFBFileInput;
