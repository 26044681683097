import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import "./BasicLastMatchesStats.scss";

import useRenderButton from "../../../customHooks/useRenderButton";
import { sxStickyColumn, TFBTableCell } from "../../../reusable/useStyles";
import TFBCard from "../../../design-system/Card/TFBCard";
import TFBCardHeader from "../../../design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../design-system/IconButton/TFBIconButton";
import TFBButton from "../../../design-system/Button/TFBButton";
import TFBIcon from "../../../design-system/Icon/TFBIcon";
import TFBDialog from "../../../design-system/Dialog/TFBDialog";
import TFBCheckbox from "../../../design-system/Checkbox/TFBCheckbox";
import CircularLoader from "../../../reusable/Loader";

export default function BasicLastMatchesStats(props) {
  const { languagePack, stats, competitions, handleFilters, isLoading } = props;

  const history = useHistory();

  const [state, updateState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      open_filters: false,
    }
  );

  const openFilters = () => {
    updateState({ open_filters: true });
  };

  const closeFilters = () => {
    updateState({ open_filters: false });
  };

  const countSelect = _.size(_.filter(competitions, (e) => e.checked));

  return (
    <TFBCard style={{ height: "100%" }}>
      <TFBCardHeader title={languagePack?.last_matches_stats}>
        {_.size(competitions) > 0 && (
          <TFBButton
            color="darkGray"
            renderIcon={() => <TFBIcon name="filter" />}
            onClick={openFilters}
          >
            {countSelect > 0 && countSelect < _.size(competitions)
              ? countSelect + " " + languagePack?.selected
              : languagePack?.filter}
          </TFBButton>
        )}
      </TFBCardHeader>
      <TFBCardBody>
        {!isLoading ? (
          _.size(stats) > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack?.match}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.score}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.minutes_played}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.goals_scored_pl}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.key_pass}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.yellow_cards_pl}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.red_cards_pl}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.player_match_rating}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack?.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stats.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row["match"]}
                        <br></br>
                        <span style={{ color: "var(--primaryColor)" }}>
                          {moment(row["match_date"]).format("DD.MM.yyyy")}
                        </span>
                      </TFBTableCell>
                      <TFBTableCell align="center">{row.score}</TFBTableCell>
                      <TFBTableCell align="center">
                        {row.played_minutes}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="player-last-matches-stat">
                          <div
                            className={`stat ${
                              row["scored_goals"] > 0 ? "green" : ""
                            }`}
                          >
                            {row["scored_goals"]}
                          </div>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="player-last-matches-stat">
                          <div
                            className={`stat ${
                              row["assits"] > 0 ? "green" : ""
                            }`}
                          >
                            {row["assits"]}
                          </div>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="player-last-matches-stat">
                          <div
                            className={`stat ${
                              row["yellow_cards"] > 0 ? "yellow" : ""
                            }`}
                          >
                            {row["yellow_cards"]}
                          </div>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="player-last-matches-stat">
                          <div
                            className={`stat ${
                              row["red_cards"] > 0 ? "red" : ""
                            }`}
                          >
                            {row["red_cards"]}
                          </div>
                        </div>
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.match_rating > 0 ? row.match_rating + "/5" : "-"}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container">
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            onClick={() =>
                              history.push(`/club/match/${row.id_match}`)
                            }
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack?.no_matches} />
          )
        ) : (
          <CircularLoader />
        )}
      </TFBCardBody>
      <Filters
        openPopup={state.open_filters}
        closePopup={closeFilters}
        languagePack={languagePack}
        competitions={competitions}
        handleFilters={handleFilters}
      />
    </TFBCard>
  );
}

const Filters = ({
  openPopup,
  closePopup,
  languagePack,
  competitions,
  handleFilters,
}) => {
  const [list, setList] = useState(_.clone(competitions));

  useEffect(() => {
    setList(_.clone(competitions));
  }, [competitions]);

  const applyFilters = () => {
    handleFilters(list);
    closePopup();
  };

  const { renderBtn: BtnLeft } = useRenderButton(
    () => closePopup(),
    "darkGray",
    "cancel",
    languagePack?.admin_cancel
  );

  const { renderBtn: BtnRight } = useRenderButton(
    () => applyFilters(),
    "green",
    "check",
    languagePack?.apply_filters,
    {},
    true,
    _.size(_.filter(list, (e) => e.checked)) == 0
  );

  const handleChangeParamCheckbox = (e, index = "all") => {
    let cloned_list = _.clone(list);

    if (index == "all") {
      cloned_list = _.map(cloned_list, (p) => ({
        ...p,
        checked: e.target.checked,
      }));
    } else {
      cloned_list[index].checked = e.target.checked;
    }
    setList(cloned_list);
  };

  return (
    <TFBDialog
      className="pop-up-dialog-box prevent-select-text"
      title={languagePack?.filters}
      open={openPopup}
      closePopup={closePopup}
      btnLeft={BtnLeft}
      btnRight={BtnRight}
    >
      <div className="ds-headline-2 mb-6">{languagePack?.select + ":"}</div>
      <div className="df df-gap-15 df-wrap prevent-select-text">
        {_.size(list) > 0 && (
          <TFBCheckbox
            key={"params-all"}
            checked={!_.some(list, ["checked", false])}
            label={languagePack?.all_competitions}
            onChange={(e) => handleChangeParamCheckbox(e, "all")}
            labelColor={"var(--primaryColor)"}
          />
        )}
        {_.map(list, (value2, key2) => (
          <TFBCheckbox
            key={"param-" + key2}
            checked={value2.checked}
            label={
              value2.id_competition == -1
                ? languagePack?.frendly_match
                : value2.competition_name
            }
            onChange={(e) => handleChangeParamCheckbox(e, key2)}
          />
        ))}
      </div>
    </TFBDialog>
  );
};
