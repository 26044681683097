import React, { useReducer } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { useHistory } from "react-router-dom";

import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";
import CircularLoader from "../../../components/reusable/Loader";
import { TFBTableCell } from "../../../components/reusable/useStyles";
import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import useAxiosGet from "../../../components/customHooks/useAxiosGet";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import DeleteNews from "./DeleteNews";

// import styles from "./BonusesMonitoring.module.scss"

const NewsList = (props) => {

    const { currentUser, languagePack } = props;
    const history = useHistory();

    const [state, updateState] = useReducer(
        (prev, next) => {
            return { ...prev, ...next };
        },
        {
            open_delete_news: false,
            delete_news: {},
        }
    );

    const {
        data: news,
        loading: isNewsLoading,
        refetch: refetchNewsList,
    } = useAxiosGet(
        `ParentsApp/get_news_list_in_platform?id_user=${currentUser.id_user}`
    );

    const openDeleteNews = (news) => {
        updateState({ open_delete_news: true, delete_news: news })
    }

    const closeDeleteNews = () => {
        updateState({ open_delete_news: false, delete_news: {} })
    }

    return (
        <TFBCard>
            <TFBCardHeader title={languagePack["news"]}>
                <TFBButton
                    onClick={() => history.push("/application/news/add")}
                    color="green"
                    renderIcon={() => <TFBIcon name="add" />}
                >
                    {languagePack.add_news_title}
                </TFBButton>
            </TFBCardHeader>
            <TFBCardBody>
                {!isNewsLoading ?
                    (_.size(news?.list) > 0 ?
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TFBTableCell>
                                            {languagePack.news_page_header_title}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.news_page_header_tag}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                            {languagePack.news_page_header_date}
                                        </TFBTableCell>
                                        <TFBTableCell align="center">
                                        </TFBTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {_.map(news?.list, (row, index) => (
                                        <TableRow
                                            key={"news-" + index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                                backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                                            }}
                                        >
                                            <TFBTableCell>
                                                {row.news_title}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {_.join(_.map(row.tags, e => e.tag_name), ", ")}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                {moment(row.date_add).format("DD.MM.YYYY, HH:MM")}
                                            </TFBTableCell>
                                            <TFBTableCell align="center">
                                                <div className="actions-btns-container df-end">
                                                    <TFBIconButton
                                                        name="edit"
                                                        color="darkGray"
                                                        onClick={() => history.push(`/application/news/edit/${row.id_news}`)}
                                                    />
                                                    <TFBIconButton
                                                        name="delete"
                                                        color="darkGray"
                                                        onClick={() => openDeleteNews(row)}
                                                    />
                                                </div>
                                            </TFBTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> :
                        <TFBPlaceholder
                            text={languagePack.no_news}
                        />
                    ) :
                    <CircularLoader />
                }
            </TFBCardBody>

            {state.open_delete_news &&
                <DeleteNews
                    openPopup={state.open_delete_news}
                    closePopup={closeDeleteNews}
                    refreshList={refetchNewsList}
                    newsData={state.delete_news}
                />
            }
        </TFBCard>
    )
}

export default connect(({ layoutService, auth }) => ({
    languagePack: layoutService.languagePack,
    currentUser: auth.user,
    token: auth?.user?.token,
    currencyRates: auth?.user?.currency_rates
}))(NewsList);