import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useAxiosGet = (url, noCallInit = false) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get(url);
      setData(response.data);
      setError(null);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [url]);

  useEffect(() => {
    if (!noCallInit) {
      fetchData();
    }
  }, [fetchData]);

  const refetch = useCallback(() => {
    fetchData();
  }, [fetchData]);

  return { data, error, loading, refetch };
};

export default useAxiosGet;
