import React, { useState, useEffect } from 'react';
import likes_icon from '../../../../assets/icons/fans-dashboard/page_likes.png';
import axios from "axios";
import NumberFormat from "react-number-format";
import { Popup } from "semantic-ui-react";

export default function PageFans(props) {
    const [fans, setFans] = useState(0);

    useEffect(() => {
        getFans();
    }, [])

    function getFans() {
        axios.get(`fans/total_fans?id_network=${props.id_network}`)
            .then((res) => setFans(res.data.fans))
            .catch(err => console.log(err))
    }

    return (
        <div className="card card-custom" style={{ height: '100%' }}>
            <div className="dashboard-box-container" style={{ height: '100%' }}>
                <div className="dashboard-box-title" >Page engagement
                <Popup
                        trigger={<div className="info-button">!</div>}
                        content={
                            <div>
                                The total number of people who have liked your Page
                </div>
                        }
                        position="right center"
                        size="small"
                        basic
                    /></div>
                <div className="clicks-stat-box">
                    <img src={likes_icon} alt="clicks_icon" />
                    <div>
                        <div className="clicks-stat-number">
                            <NumberFormat
                                value={fans}
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                        </div>
                        <div className="clicks-stat-text">Current page likes</div>
                    </div>
                </div>
            </div>
        </div>
    )
}