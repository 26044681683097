import React from "react";
import "./NewsPage.scss";
import { Button, Icon } from "semantic-ui-react";
import axios from "axios";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { store } from "react-notifications-component";
import edit_icon from "../../../../assets/icons/edit.png";
import delete_icon from "../../../../assets/icons/delete.png";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";

class NewsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: [],
      wantToDeleteNews: "",
      newsLoaded: false,
    };
  }

  componentDidMount = () => {
    this.getNews();
  };

  getNews = () => {
    axios
      .get(`site/get_news?id_club=${this.props.currentUser.id_club}`)
      .then((res) => this.setState({ news: res.data, newsLoaded: true }))
      .catch((err) => console.log(err));
  };

  deleteNews = () => {
    const payload = {
      id_news: this.state.wantToDeleteNews,
    };

    axios
      .post("site/delete_news", payload)
      .then(() => {
        this.getNews();
        this.setState({ wantToDeleteNews: "" });
        store.addNotification({
          title: "Administrare stiri",
          message: "Stirea a fost stearsa cu succes!",
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="card card-custom">
        {" "}
        <div className="news-page-container">
          <div className="news-page-header">
            <div className="news-page-title">
              {languagePack["news_page_title"]}
            </div>
            <Button
              className="add-news-button"
              onClick={() => this.props.history.push("/site/news/add")}
            >
              {languagePack["news_page_add_button"]}
              <Icon name="add" className="add-news-button-icon" />
            </Button>
          </div>
          {!this.state.newsLoaded ? (
            <div className="loader-container">
              <Loader
                type="TailSpin"
                color={window.clubCustomization.primary_color}
                height={40}
                width={40}
              />
            </div>
          ) : (
            <div className="last-news-list">
              <div className="last-news-row-header desktop-header">
                <div className="news-title">
                  {languagePack["news_page_header_title"]}
                </div>
                <div className="news-tag">
                  {languagePack["news_page_header_tag"]}
                </div>
                <div className="news-date">
                  {languagePack["news_page_header_date"]}
                </div>
                <div className="news-option">
                  {languagePack["news_page_header_options"]}
                </div>
              </div>
              {this.state.news.map((item, index) => {
                return (
                  <div
                    className="last-news-row"
                    key={index}
                    style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "" }}
                  >
                    <div className="header-row">
                      <div className="mobile-header">
                        <div className="news-title">
                          {languagePack["news_page_header_title"]}
                        </div>
                        <div className="news-tag">
                          {languagePack["news_page_header_tag"]}
                        </div>
                        <div className="news-date">
                          {languagePack["news_page_header_date"]}
                        </div>
                        <div className="news-option">
                          {languagePack["news_page_header_options"]}
                        </div>
                      </div>
                    </div>
                    <div className="content-row">
                      <div className="news-title">{item.news_title}</div>
                      <div className="news-tag">{item.news_tag}</div>
                      <div className="news-date">
                        <Moment format="DD.MM.YYYY,  HH:mm">
                          {item.date_add}
                        </Moment>
                      </div>
                      <div className="news-option">
                        <img
                          src={edit_icon}
                          alt="edit"
                          onClick={() =>
                            this.props.history.push(
                              `/site/news/edit/${item.id_site_news}`
                            )
                          }
                        />
                        <img
                          src={delete_icon}
                          alt="delete"
                          onClick={() =>
                            this.setState({
                              wantToDeleteNews: item.id_site_news,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <Dialog
          open={this.state.wantToDeleteNews !== ""}
          // onClose={() => this.setState({ wantToDeleteNews: "" })}
        >
          <DialogContent className="news-options-delete-popup-container">
            <div className="news-options-delete-popup">
              <div className="header">
                {languagePack["news_page_delete_popup_title"]}
                <Icon
                  name="close"
                  onClick={() => this.setState({ wantToDeleteNews: "" })}
                />
              </div>
              <div className="delete-message">
                {languagePack["news_page_delete_popup_text"]}
              </div>
              <div className="delete-buttons">
                <Button
                  className="news-options-button dark-background"
                  onClick={() => this.setState({ wantToDeleteNews: "" })}
                >
                  {languagePack["admin_cancel"]}
                  <Icon name="close" className="news-options-button-icon" />
                </Button>
                <Button
                  className="news-options-button"
                  onClick={this.deleteNews}
                >
                  {languagePack["admin_delete"]}
                  <Icon
                    name="trash alternate"
                    className="news-options-button-icon"
                  />
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(NewsPage);
