import React, { useEffect, useState } from "react";
import { CirclePicker, ChromePicker } from "react-color";
import { Popup } from "semantic-ui-react";
import { TColorPicker } from "../types";
import styles from "./TFBColorPicker.module.scss";
import TFBButton from "../Button/TFBButton";
import TFBIconButton from "../IconButton/TFBIconButton";
import TFBIcon from "../Icon/TFBIcon";

const TFBColorPicker = ({
  label,
  initialColor,
  noColor,
  text,
  style,
  returnColor,
}: TColorPicker) => {
  const [color, setColor] = useState("");
  const [customColor, setCustomColor] = useState("");
  const [openCustomColorSelect, setOpenCustomColorSelect] = useState(false);

  useEffect(() => {
    if (noColor && color != "") {
      setColor("");
    }
  }, [noColor])

  const handleChangeColor = (color: any) => {
    setColor(color.hex);
    if (returnColor) {
      returnColor(color.hex);
    }
  };

  const selectedColor = color ? color : initialColor ? initialColor : "";

  const ReusableColorPicker = (
    <Popup
      trigger={
        selectedColor == "" || noColor ? (
          <TFBButton
            color="lightGray"
            style={{ width: "-webkit-fill-available", minHeight: 53 }}
          >
            <div className="df df-center-center" style={{ width: "100%" }}>
              <TFBIcon name="cancel" color="gray" />
            </div>
          </TFBButton>
        ) : (
          <div className={styles.colorContainer}>
            <div
              className={styles.colorDiv}
              style={{ backgroundColor: selectedColor }}
            ></div>
          </div>
        )
      }
      content={
        !openCustomColorSelect ? (
          <>
            <CirclePicker color={selectedColor} onChange={handleChangeColor} />
            {/* <TFBIconButton name="add" onClick={() => setOpenCustomColorSelect(true)} /> */}
          </>
        ) : (
          <>
            <ChromePicker
              color={customColor}
              onChange={(color) => setCustomColor(color.hex)}
              disableAlpha
            />
            <TFBIconButton
              name="check"
              onClick={() => setOpenCustomColorSelect(false)}
            />
            <TFBIconButton
              name="x-large"
              onClick={() => setOpenCustomColorSelect(false)}
            />
          </>
        )
      }
      on="click"
      position="bottom right"
    />
  );

  return label ? (
    <div className={`${styles.selectGroup}`} style={style ?? {}}>
      {<h6 className={styles.selectLabel}>{label}</h6>}
      {ReusableColorPicker}
    </div>
  ) : (
    ReusableColorPicker
  );
};

export default TFBColorPicker;
