import React from "react";
import styles from "./TFBCurrencyInput.module.scss";
import { TextField } from "@material-ui/core";
import { TCurrencyInput } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import TFBInput from "../Input/TFBInput";
import TFBSelect from "../TFBSelect/TFBSelect";

const TFBCurrencyInput = ({
    className,
    disabled,
    error,
    label,
    onValueChange,
    onCurrencyChange,
    placeholder,
    style,
    value,
    currency = null,
    currencyOptions = [],
}: TCurrencyInput) => {
    const classes = makeStyles({
        root: {
            "& .MuiOutlinedInput-root": {
                height: "50px",
            },
        },
    });

    const ReusableInput = <div
        className={styles.tfbCurrencyInput}
        style={style}
    >
        <TFBInput
            className={styles.tfbCurrencyInputValue}
            placeholder={placeholder}
            value={value}
            onChange={onValueChange}
            type="number"
            error={error}
            disabled={disabled}
        />
        <TFBSelect
            className={styles.tfbCurrencyInputCurrency}
            value={currency}
            options={currencyOptions}
            onChange={onCurrencyChange}
            isDisabled={disabled}
        />
    </div>;

    return (
        <div className={styles.tfbCurrencyInputGroup} >
            {label && <h6 className={styles.tfbCurrencyInputLabel}>{label}</h6>}
            {ReusableInput}
        </div>
    );
};

export default TFBCurrencyInput;
