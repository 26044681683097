import React, { useState, useEffect, useRef } from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import axios from "axios";
import { Popup, Icon, Accordion } from "semantic-ui-react";
import Moment from "react-moment";
import Dialog from "@material-ui/core/Dialog";
import HealingIcon from "@material-ui/icons/Healing";
import DialogContent from "@material-ui/core/DialogContent";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import moment from "moment";
import dateFormat from "dateformat";
import * as myConstants from "./../../../app/utils/constants";
import ExpandedImage from "../SiteAdmin/Photos/ExpandedImage";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  TFBTableCell,
  sxStickyColumn,
} from "../../components/reusable/useStyles";
import TFBPlaceholder from "../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import TFBIconButton from "../../components/design-system/IconButton/TFBIconButton";
import TFBCardBody from "../../components/design-system/Card/TFBCardBody";
import TFBCard from "../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../components/design-system/Card/TFBCardHeader";

function MedicalPlayerProfile(props) {

  const humanRef = useRef(null);

  const [injuries, setInjuries] = useState([]);
  const [injury_dialog, setInjuryDialog] = useState(false);
  const [detailedInjury, setDetailedInjury] = useState([]);
  const [activeTreatment, setActiveTreatment] = useState([]);
  const [treatmentTotal, setTreatmentTotal] = useState(0);
  const [activeIndexes, setActiveIndexes] = useState([]);
  const [certificates, setCertificates] = useState([]);

  const { languagePack } = props;

  useEffect(() => {
    fetchData();
    getPlayerCertificatesTable();
  }, []);

  useEffect(() => {
    fetchData();
  }, [props.refreshInjuries]);

  const fetchData = async () => {
    const result = await axios(
      `medical/get_player_injuries?id_player=${props.id_player}&season=${props.id_season}`
    );
    setInjuries(Array.isArray(result.data) ? result.data : []);
  };

  const getPlayerCertificatesTable = () => {
    axios
      .get(
        `medical/get_player_certificates?id_player=${props.id_player}&id_season=${props.id_season}`
      )
      .then((res) => {
        setCertificates(res.data);
      })
      .catch((err) => console.log(err));
  };

  function get_treatment(id) {
    axios
      .get(`medical/get_injury_treatment?id_injury=${id}`)
      .then((res) => {
        setActiveTreatment(res.data.treatments);
        setTreatmentTotal(res.data.total);
      })
      .catch((err) => console.log(err));
  }

  function convertCurrency(value) {
    return value;
  }

  function formatNumber(value) {
    return (
      <NumberFormat
        value={convertCurrency(value)}
        displayType={"text"}
        decimalScale={2}
        thousandSeparator={true}
      />
    );
  }

  function expandRow(value) {
    if (activeIndexes.includes(value)) {
      setActiveIndexes((oldArray) => oldArray.filter((x) => x != value));
    } else {
      setActiveIndexes((oldArray) => [...oldArray, value]);
    }
  }

  const iconOsCurrency = <Icon name="eur" className="green-eur-icon mg--0" />;

  return (
    <>
      <TFBCard style={{ boxShadow: "none" }}>
        <TFBCardBody>
          <div id="human-body" ref={humanRef} style={{ backgroundImage: `url(${toAbsoluteUrl("/media/medical/Body.jpg")})` }}>
            {injuries.map((item, index) => {
              return item.another_zone == 0 && item.coord_x_front && item.coord_y_front && (
                <Popup
                  key={"injury-on-img-" + index}
                  trigger={
                    <div
                      style={{
                        height: 32,
                        width: 32,
                        position: "absolute",
                        left: ((humanRef?.current?.offsetWidth ?? 400) / 400) * parseInt(item.coord_x_front), //400px is the initial width/height in dependence with is set initial x/y
                        top: ((humanRef?.current?.offsetHeight ?? 400) / 400) * parseInt(item.coord_y_front),
                      }}
                    >
                      <SVG src={toAbsoluteUrl("/media/medical/bulina1.svg")} />
                    </div>
                  }
                  on="hover"
                  position="top center"
                  content={
                    <div>
                      <div className="injury-popup-item">
                        <div className="injury-popup-item-title">
                          {languagePack["injury_zone"]}:
                        </div>
                        {props.languagePack[item.inj_name]}
                      </div>
                      <div className="injury-popup-item">
                        <div className="injury-popup-item-title">
                          {languagePack["injury_date"]}:
                        </div>
                        <Moment format="DD.MM.YYYY">{item.injury_date}</Moment>
                      </div>
                      {item.absence_period > 0 && (
                        <div className="injury-popup-item">
                          <div className="injury-popup-item-title">
                            {languagePack["absence_period"]}:
                          </div>
                          {item.absence_period}{" "}
                          {item.absence_period == 1
                            ? languagePack["injury_day"]
                            : languagePack["injury_days"]}
                        </div>
                      )}
                      <div className="injury-popup-item">
                        <div className="injury-popup-item-title">
                          {languagePack["total_cost"]}:
                        </div>
                        {item.total_cost ? item.total_cost : 0}
                        <Icon name="euro" color="black" />
                      </div>
                    </div>
                  }
                />
              );
            })}
          </div >
          {injuries.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.injury_zone}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.injury_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.injury_details}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {injuries.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row.another_zone == 1
                          ? row.inj_name
                          : languagePack[row.inj_name]}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {moment(row.injury_date).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="actions-btns-container">
                          <TFBIconButton
                            name="view"
                            color="darkGray"
                            onClick={() => {
                              setInjuryDialog(true);
                              setDetailedInjury(row);
                              get_treatment(row.id_injury);
                            }}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack["no_injuries_found"]} />
          )}
          <TFBCardHeader title={languagePack.medical_visa} className="mt-10" />
          {certificates.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.date_of_examination}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.visa_expiration_date}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.expires_in_days}
                    </TFBTableCell>
                    <TFBTableCell align="center">
                      {languagePack.medical_certificate}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {certificates.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                      }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{
                          backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                        }}
                      >
                        {row.date_examination == null
                          ? "-"
                          : moment(row.date_examination).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        {row.date_expire == null
                          ? "-"
                          : moment(row.date_expire).format("DD.MM.YYYY")}
                      </TFBTableCell>
                      <TFBTableCell
                        align="center"
                        style={{ color: row.days_left == 0 ? "red" : "" }}
                      >
                        {row.days_left == 0
                          ? languagePack.expired
                          : row.days_left}
                      </TFBTableCell>
                      <TFBTableCell align="center">
                        <div className="actions-btns-container">
                          <TFBIconButton
                            name="enlarge"
                            color="darkGray"
                            onClick={() =>
                              props.setExpandImage(
                                true,
                                row.doc_url
                              )
                            }
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TFBPlaceholder text={languagePack["no_data_medical_visa"]} />
          )}
        </TFBCardBody>
      </TFBCard>

      <Dialog
        open={injury_dialog}
        // onClose={() => setInjuryDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <div className="injury-popup-item-subtitle">
          <CloseIcon onClick={() => setInjuryDialog(false)} fontSize="large" />
        </div>
        <DialogContent>
          <>
            <div className="injury-big-popup-title">
              {languagePack["injury_details"]}
            </div>
            <div className="injury-big-content-container">
              <div className="injury-big-popup-column-titles">
                <div className="injury-column-title">
                  {languagePack["active_injuries_injury_date"]}
                </div>
                <div className="injury-column-title">
                  {languagePack["injury_zone"]}
                </div>
                <div className="injury-column-title">
                  {languagePack["return_date"]}
                </div>
              </div>
              <div className="injury-big-popup-values cream-bg">
                <div className="injury-column-title">
                  <Moment format="DD.MM.YYYY">
                    {detailedInjury.injury_date}
                  </Moment>
                </div>
                <div className="injury-column-title">
                  {detailedInjury.another_zone == 1
                    ? detailedInjury.inj_name
                    : props.languagePack[detailedInjury.inj_name]}
                </div>
                <div className="injury-column-title">
                  {detailedInjury.recovery_date ? (
                    <Moment format="DD.MM.YYYY">
                      {detailedInjury.recovery_date}
                    </Moment>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
              <div className="expanded-row-description">
                {detailedInjury.description}
              </div>
              {activeTreatment.length > 0 ? (
                <>
                  <div className="injury-big-popup-subtitle">
                    {languagePack["treatment"]}
                  </div>
                  <div className="treatment-container">
                    {activeTreatment.map((item, index) => {
                      return (
                        <Accordion exclusive={false} key={"treatment-" + index}>
                          <Accordion.Title
                            style={{ padding: 0 }}
                            onClick={() => expandRow(index)}
                            active={activeIndexes.includes(index)}
                          >
                            <div
                              className={
                                index % 2 == 0
                                  ? "unexpanded-row cream-bg"
                                  : "unexpanded-row"
                              }
                            >
                              <div className="unexpanded-row-title">
                                {item.treatment_name}
                              </div>
                              <div className="unexpanded-row-right">
                                <div className="unexpanded-row-cost">
                                  {formatNumber(item.treatment_cost)}
                                  {iconOsCurrency}
                                </div>
                                {activeIndexes.includes(index) ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </div>
                            </div>
                          </Accordion.Title>
                          <Accordion.Content
                            // active
                            className={
                              index % 2 == 0
                                ? "expanded-row cream-bg"
                                : "expanded-row"
                            }
                            active={activeIndexes.includes(index)}
                          >
                            <div className="expanded-row-date">
                              <Moment format="DD.MM.YYYY">
                                {item.treatment_date_start}
                              </Moment>
                              -
                              <Moment format="DD.MM.YYYY">
                                {item.treatment_date_end}
                              </Moment>
                            </div>
                            <div className="expanded-row-description">
                              {item.treatment_description}
                            </div>
                          </Accordion.Content>
                        </Accordion>
                      );
                    })}
                  </div>
                </>
              ) : (
                ""
              )}
              {treatmentTotal > 0 ? (
                <div className="injury-big-popup-total-container">
                  <div className="injury-big-popup-total-text">
                    {languagePack["total_treatment_cost"]}
                  </div>
                  <div className="injury-big-popup-total-amount">
                    {formatNumber(treatmentTotal)}
                    {iconOsCurrency}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  currencyItemSelected:
    myConstants.currencies[auth?.user?.currency] || myConstants.currencies.EUR,
}))(MedicalPlayerProfile);
