import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import tfb_brand from "./../../../../app/assets/icons/aside-menu-svg/tfb_brand.svg";

export function Brand(props) {
  return (
    <>
      {/* begin::Brand */}
      <div
        id="tfb-logo-brand"
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto`}
      >
        {/* begin::Logo */}
        <Link to="" className="brand-logo">
          <img
            alt="logo"
            src={window.clubCustomization.logo}
            className="h-logo-px"
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
