import React from "react";
import "./MatchPlayerStats.scss";
import axios from "axios";
import { Checkbox, Button, Icon, Input, Popup } from "semantic-ui-react";
import { connect } from "react-redux";

class ParametersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: [],
      initialParameters: [],
      selectedParameters: [],
    };
  }

  componentDidMount = () => {
    this.getParameters();
  };

  getParameters = () => {
    axios
      .get(
        `instat/get_parameters_list?id_match=${this.props.id_match}&id_user=${this.props.id_user}&lang=${this.props.lang}`
      )
      .then((res) =>
        this.setState({
          initialParameters: res.data.parameters,
          parameters: res.data.parameters,
          selectedParameters: res.data.selected_parameters,
        })
      )
      .catch((err) => console.log(err));
  };

  handleSearchbarChange = (e) => {
    const filtered = this.state?.initialParameters?.filter((item) => {
      return item.parameter_name_translated
        .toLowerCase()
        .includes(e.target.value);
    });
    this.setState({
      parameters: filtered,
    });
  };

  handleChangeCheckbox = (id) => {
    if (this.state?.selectedParameters?.includes(id)) {
      this.setState({
        selectedParameters: this.state?.selectedParameters?.filter(
          (item) => item != id
        ),
      });
    } else {
      if (this.state?.selectedParameters?.length < 7) {
        this.setState({
          selectedParameters: this.state?.selectedParameters?.concat(id),
        });
      }
    }
  };

  submitParameters = () => {
    const payload = {
      id_user: this.props.id_user,
      parameters_list: this.state?.selectedParameters,
    };
    axios
      .post("instat/submit_parameters_list", payload)
      .then(() => {
        this.props.refreshParameters();
        this.props.closePopup();
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <div className="parameters-list-container">
        <div className="parameters-list-header">
          <div className="parameters-list-title">
            Lista de parametri afisati
          </div>
          <Icon
            name="close"
            size="large"
            onClick={() => this.props.closePopup()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Input
          icon={{ name: "search", circular: true, link: true }}
          placeholder="Cauta parametru"
          className="search-field"
          style={{ width: "100%", marginBottom: 20 }}
          onChange={this.handleSearchbarChange}
        />
        <div className="parameters-list">
          {this.state?.parameters?.map((item, index) => {
            return (
              <div
                className="parameters-list-row"
                key={index}
                style={{
                  backgroundColor: index % 2 == 0 ? "#fafafa" : "white",
                }}
              >
                <Checkbox
                  onChange={() => this.handleChangeCheckbox(item.id_parameter)}
                  checked={this.state?.selectedParameters?.includes(
                    item.id_parameter
                  )}
                  className="param-list-checkbox"
                />
                <div className="param-name">
                  {item.parameter_name_translated !== ""
                    ? item.parameter_name_translated
                    : "-"}
                </div>
                {item.description && (
                  <Popup
                    content={<div>{item.description}</div>}
                    basic
                    className="param-details-content"
                    position="right center"
                    size="small"
                    trigger={
                      <Icon
                        name="info circle"
                        size="small"
                        className="info-icon"
                      />
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="parameters-list-text">
          {this.state?.selectedParameters?.length}
          {this.state?.selectedParameters?.length === 1
            ? " parametru selectat"
            : " parametri selectati"}{" "}
          (maxim 7)
        </div>
        <div className="parameters-list-button-row">
          <Button
            className="parameters-list-save-button"
            onClick={this.submitParameters}
          >
            Salveaza modificari{" "}
            <Icon
              name="check"
              size="small"
              className="param-list-button-icon"
            />
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  lang: layoutService.lang,
  currentUser: auth.user,
}))(ParametersList);
