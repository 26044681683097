import React, { Component } from "react";
import { connect } from "react-redux";
import "./ViewOrder.scss";
import axios from "axios";
import { store } from "react-notifications-component";
import TFBDialog from "../../../components/design-system/Dialog/TFBDialog";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";

class ViewOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delivery_status: "",
    };
  }

  confirmOrder = () => {
    const { languagePack, closePopup, orderData, refreshList, token } = this.props;

    const payload = {
      set_status_delivery: 1,
      id_delivery_status: 3,
      id_delivery: orderData.id_delivery,
      token: token,
    };

    axios
      .post("/delivery", payload)
      .then(res => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.orders,
            message: languagePack.success_confirm_order,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.orders,
            message: languagePack.fail_confirm_order,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch(e => console.log(e))
  };

  cancelOrder = () => {
    const { languagePack, closePopup, orderData, refreshList, token } = this.props;

    const payload = {
      set_status_delivery: 1,
      id_delivery_status: 5,
      id_delivery: orderData.id_delivery,
      token: token,
    };

    axios
      .post("/delivery", payload)
      .then(res => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.orders,
            message: languagePack.success_cancel_order,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.orders,
            message: languagePack.fail_cancel_order,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch(e => console.log(e))
  };

  deliverOrder = () => {
    const { languagePack, closePopup, orderData, refreshList, token } = this.props;

    const payload = {
      set_status_delivery: 1,
      id_delivery_status: 4,
      id_delivery: orderData.id_delivery,
      token: token,
    };

    axios
      .post("/delivery", payload)
      .then(res => {
        if (res.data.success == 1) {
          refreshList();
          store.addNotification({
            title: languagePack.orders,
            message: languagePack.success_deliver_order,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: languagePack.orders,
            message: languagePack.fail_deliver_order,
            type: "danger",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
        closePopup();
      })
      .catch(e => console.log(e))
  };

  renderActionButtons = (status) => {
    const { languagePack, closePopup } = this.props;

    let buttonsProps = {};

    switch (status) {
      case "1":
      case "6": buttonsProps = {
        btnLeft: () => (
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="check" />}
            onClick={this.confirmOrder}
          >
            {languagePack.confirm_order}
          </TFBButton>
        ),
        btnCenter: () => (
          <TFBButton
            color="red"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={this.cancelOrder}
          >
            {languagePack.cancel_order}
          </TFBButton>
        ),
        btnRight: () => (
          <TFBButton
            color="lightGray"
            renderIcon={() => <TFBIcon name="close-popup" color="gray" />}
            onClick={closePopup}
          >
            {languagePack.close_order}
          </TFBButton>
        )
      };
        break;
      case "2": buttonsProps = {
        btnLeft: () => (
          <TFBButton
            color="red"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={this.cancelOrder}
          >
            {languagePack.cancel_order}
          </TFBButton>
        ),
        btnRight: () => (
          <TFBButton
            color="lightGray"
            renderIcon={() => <TFBIcon name="close-popup" color="gray" />}
            onClick={closePopup}
          >
            {languagePack.close_order}
          </TFBButton>
        )
      };
        break;
      case "3": buttonsProps = {
        btnLeft: () => (
          <TFBButton
            color="blue"
            renderIcon={() => <TFBIcon name="check" />}
            onClick={this.deliverOrder}
          >
            {languagePack.deliver_order}
          </TFBButton>
        ),
        btnCenter: () => (
          <TFBButton
            color="red"
            renderIcon={() => <TFBIcon name="cancel" />}
            onClick={this.cancelOrder}
          >
            {languagePack.cancel_order}
          </TFBButton>
        ),
        btnRight: () => (
          <TFBButton
            color="lightGray"
            renderIcon={() => <TFBIcon name="close-popup" color="gray" />}
            onClick={closePopup}
          >
            {languagePack.close_order}
          </TFBButton>
        )
      };
        break;
      default: buttonsProps = {
        btnRight: () => (
          <TFBButton
            color="lightGray"
            renderIcon={() => <TFBIcon name="close-popup" color="gray" />}
            onClick={closePopup}
          >
            {languagePack.close_order}
          </TFBButton>
        )
      };
    }
    return buttonsProps;
  }

  render() {
    const { languagePack, closePopup, isOpenPopup, orderData } = this.props;

    return (
      <TFBDialog
        className="pop-up-dialog-box prevent-select-text"
        title={languagePack.order_details}
        open={isOpenPopup}
        closePopup={closePopup}
        {...this.renderActionButtons(orderData.current_status)}
      >
        <div className="view-order-container">
          <div className="view-order-row">
            {languagePack.order_customer}:
            <span className="span-order-data">{orderData.client_name}</span>
          </div>
          <div className="view-order-row">
            {languagePack.order_phone}:
            <span className="span-order-data">{orderData.client_phone}</span>
          </div>
          <div className="view-order-row">
            {languagePack.order_mail}:
            <span className="span-order-data">{orderData.client_email}</span>
          </div>
          <div className="view-order-row">
            {languagePack.order_address}:
            <span className="span-order-data">{orderData.client_address}</span>
          </div>
          <div className="view-order-row">
            {languagePack.order_status}:
            <span className="span-order-data">{orderData.order_status}</span>
          </div>
          <div className="view-order-row">
            {languagePack.order_message}:
            <span className="span-order-data">{orderData.message}</span>
          </div>
          <div className="view-order-row">
            {languagePack.order_payment_type}:
            <span className="span-order-data">{languagePack.order_payment_types[orderData.payment_type]}</span>
          </div>
          <div className="view-order-row">
            <table className="table order-lines">
              <thead style={{ fontWeight: "bold" }}>
                <tr>
                  <td>{languagePack.order_product}</td>
                  <td>{languagePack.order_quantity}</td>
                  <td>{languagePack.order_unit_price}</td>
                  <td>{languagePack.order_wrapper}</td>
                  <td>{languagePack.order_vat}</td>
                  <td>{languagePack.order_subtotal}</td>
                </tr>
              </thead>
              <tbody>
                {orderData.delivery_lines.map((item, index) => {
                  return (
                    <tr key={`Order-${index}-${item.id_delivery}-${item.id_product}`}>
                      <td>{item.product_name}<br />{item?.size_data && <b className="size">{` (${item?.size_data?.product_name})`}</b>}</td>
                      <td>{item.product_qty}</td>
                      <td>{Number(item.product_price).toFixed(2)}</td>
                      <td>{"0"}</td>
                      <td>{Number(item.delivery_line_tax).toFixed(2)}</td>
                      <td>{Number(item.delivery_line_total).toFixed(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="order-totals">
              <div className="order-delivery">
                {`${languagePack.order_delivery_cost}: ${orderData.total_delivery} RON`}
              </div>
              <div className="order-discount"></div>
              <div className="order-total-price">{`Total: ${orderData.total_order} RON`}</div>
            </div>
          </div>
        </div>
      </TFBDialog>
    );
  }

}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
  token: auth?.user?.token,
}))(ViewOrder);
