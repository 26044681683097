export const initialOptionsShots = [
    { name: "Suturi pe poarta", name_en: "Shots on target", id: 4010 },
    { name: "Suturi pe langa poarta", name_en: "Shots wide", id: 4020 },
    { name: "Suturi in bara", name_en: "Shots off the post", id: 4030 },
    {
        name: "Suturi blocate",
        name_en: "Shots blocked",
        id: 4040,
    },
    { name: "Goluri", name_en: "Goals", id: 8010 },
];

export const initialOptionsDefensive = [
    {
        name: "Tackle-uri",
        name_en: "Tackles",
        id: 2030,
        personalized_pin: false,
        color: "blue",
    },
    {
        name: "Dueluri pierdute",
        name_en: "Lost challenges ",
        id: 2040,
        personalized_pin: false,
        color: "red",
    },
    {
        name: "Recuperari ale mingii",
        name_en: "Ball recoveries",
        id: 2060,
        personalized_pin: false,
        color: "orange",
    },
    { name: "Faulturi", name_en: "Fouls", id: 3010, personalized_pin: true },
];

export const initialOptionsTechnical = [
    {
        name: "Dribblinguri reusite",
        name_en: "Successful dribbles",
        id: 2051,
        personalized_pin: true,
    },
    {
        name: "Dribblinguri nereusite",
        name_en: "Unsuccessful dribbles",
        id: 2052,
        personalized_pin: false,
        color: "brown",
    },
    {
        name: "Pase cheie precise",
        name_en: "Accurate key passes",
        id: 1031,
        personalized_pin: false,
    },
    {
        name: "Pase cheie imprecise",
        name_en: "Inaccurate key passes",
        id: 1032,
        personalized_pin: false,
    },
    { name: "Assisturi", name_en: "Assists", id: 1040, personalized_pin: false },
    {
        name: "Assisturi cheie",
        name_en: "Key assists",
        id: 1050,
        personalized_pin: false,
    },
];

export const initialOptionsGoalkeeper = [
    { name: "Interceptii reusite", name_en: "Good interceptions", id: 13011 },
    { name: "Interceptii gresite", name_en: "Bad interceptions", id: 13012 },
    { name: "Degajare precisa", name_en: "Attacking pass accurate", id: 1011 },
    {
        name: "Degajare imprecisa",
        name_en: "Attacking pass inaccurate",
        id: 1012,
    },
];

export const pinColors = [
    { id: 2030, color: "blue" },
    { id: 2040, color: "red" },
    { id: 2060, color: "orange" },
    { id: 2052, color: "brown" },
];