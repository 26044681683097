import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "./Categories.scss";
import AddCategory from "./AddCategory";

import TFBCard from "../../../components/design-system/Card/TFBCard";
import TFBCardHeader from "../../../components/design-system/Card/TFBCardHeader";
import TFBButton from "../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../components/design-system/Icon/TFBIcon";
import TFBCardBody from "../../../components/design-system/Card/TFBCardBody";
import TFBPlaceholder from "../../../components/design-system/NoDataPlaceholder/TFBPlaceholder";
import { TFBTableCell, sxStickyColumn } from "../../../components/reusable/useStyles";
import TFBIconButton from "../../../components/design-system/IconButton/TFBIconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import EditCategory from "./EditCategory";
import DeleteCategory from "./DeleteCategory";
import TFBImageAvatar from "../../../components/design-system/ImageAvatar/TFBImageAvatar";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      isFetching: true,

      open_add_dialog: false,

      open_edit_dialog: false,
      editing_category: {},

      open_delete_dialog: false,
      deleting_category: {},
    };
  }

  getCategories = () => {
    axios
      .get(`/category/${this.props.currentUser.id_club}`)
      .then((res) => this.setState({ categories: res.data, isFetching: false }))
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    this.getCategories();
  }

  openAddCategory = () => {
    this.setState({ open_add_dialog: true })
  }

  closeAddCategory = () => {
    this.setState({ open_add_dialog: false })
  }

  openEditCategory = (category) => {
    this.setState({ open_edit_dialog: true, editing_category: category })
  }

  closeEditCategory = () => {
    this.setState({ open_edit_dialog: false, editing_category: {} })
  }

  openDeleteCategory = (category) => {
    this.setState({ open_delete_dialog: true, deleting_category: category })
  }

  closeDeleteCategory = () => {
    this.setState({ open_delete_dialog: false, deleting_category: {} })
  }

  render() {
    const { languagePack } = this.props;

    return (
      <TFBCard>
        <TFBCardHeader title={languagePack.categories}>
          <TFBButton
            color="green"
            renderIcon={() => <TFBIcon name="add" />}
            onClick={this.openAddCategory}
          >
            {languagePack.add_category}
          </TFBButton>
        </TFBCardHeader>
        <TFBCardBody>
          {this.state.categories.length > 0 ?
            <TableContainer>
              <Table faria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TFBTableCell sx={sxStickyColumn}>
                      {languagePack.category_image}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.category_name}
                    </TFBTableCell>
                    <TFBTableCell>
                      {languagePack.product_status}
                    </TFBTableCell>
                    <TFBTableCell align="right">
                      {languagePack.product_actions}
                    </TFBTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.categories.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                    >
                      <TFBTableCell
                        sx={sxStickyColumn}
                        style={{ backgroundColor: index % 2 == 0 ? "#fafafa" : "white" }}
                      >
                        {row.category_image == null ?
                          languagePack.without_image :
                          <TFBImageAvatar src={row.category_image} />
                        }
                      </TFBTableCell>
                      <TFBTableCell>
                        {row.category_name}
                      </TFBTableCell>
                      <TFBTableCell>
                        {{ 1: languagePack.active, 0: languagePack.inactive }[row.category_status]}
                      </TFBTableCell>
                      <TFBTableCell>
                        <div className="actions-btns-container" style={{ justifyContent: "flex-end" }}>
                          <TFBIconButton
                            name="edit"
                            color="darkGray"
                            onClick={() => this.openEditCategory(row)}
                          />
                          <TFBIconButton
                            name="delete"
                            color="darkGray"
                            onClick={() => this.openDeleteCategory(row)}
                          />
                        </div>
                      </TFBTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer> :
            <TFBPlaceholder text={languagePack.no_categories} />
          }
        </TFBCardBody>

        {/* ------------------- ADD CATEGORY ---------------- */}
        {this.state.open_add_dialog &&
          <AddCategory
            isOpenPopup={this.state.open_add_dialog}
            closePopup={this.closeAddCategory}
            refreshList={this.getCategories}
          />
        }

        {/* ------------------- EDIT CATEGORY ---------------- */}
        {this.state.open_edit_dialog &&
          <EditCategory
            isOpenPopup={this.state.open_edit_dialog}
            closePopup={this.closeEditCategory}
            refreshList={this.getCategories}
            categoryData={this.state.editing_category}
          />
        }

        {/* ------------------- DELETE CATEGORY ---------------- */}
        {this.state.open_delete_dialog &&
          <DeleteCategory
            isOpenPopup={this.state.open_delete_dialog}
            closePopup={this.closeDeleteCategory}
            refreshList={this.getCategories}
            categoryData={this.state.deleting_category}
          />
        }
      </TFBCard>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(Categories);
