import React from "react";
import { TShape } from "../types";
import styles from "./TFBShape.module.scss";

const TFBShape = ({
    name = "square",
    color = "green",
    text = "",
    className,
    style,
    textStyle,
    backgroundColor = null,
}: TShape) => {

    return (
        <div
            style={{ ...style, ...(backgroundColor ? { backgroundColor: backgroundColor } : {}) }}
            className={`${styles.shapeContainer} ${styles[name]} ${styles[color]} ${className}`}
        >
            <div className={styles.shapeText} style={textStyle}>
                {text}
            </div>
        </div>
    )
};

export default TFBShape;
