import React, { useEffect, useReducer } from "react";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import _ from "lodash";

import "./AddTeam.scss";
import TFBInput from "../../../../components/design-system/Input/TFBInput";
import {
  showNotification,
  useAxiosPost,
} from "../../../../components/customHooks/useAxiosPost";
import {
  baseUrl,
  removeAgeGroupFromTeamName,
} from "../../../../utils/utilFunctions";
import useAxiosGet from "../../../../components/customHooks/useAxiosGet";
import ExternTeamLogos from "../AddMatch/ExternTeamLogos";
import TFBCheckbox from "../../../../components/design-system/Checkbox/TFBCheckbox";
import TFBButton from "../../../../components/design-system/Button/TFBButton";
import TFBIcon from "../../../../components/design-system/Icon/TFBIcon";

function AddTeam({
  languagePack,
  closePopup,
  getTeams,
  updateCompetitionDetails,
  currentUser,
  id_competition,
}) {
  const [state, setState] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      teamOption: "new",
      newTeamName: "",
      teamName: "",
      clubTeams: [],
      group: "",
      id_team: "",
      initialPoints: "0",
      pseudoTeamName: "",
      new_team_logos: [],
      new_team_selected_logo: 0,
      show_new_team_logos: false,
      loading_logos: false,
      logos_checkbox: false,
      new_team_uploaded_logo: "",
    }
  );

  const { data: clubTeamsData } = useAxiosGet(
    baseUrl(`matches/get_club_teams?id_club=${currentUser.id_club}`)
  );

  const {
    loading: loadingLogos,

    postData: postOpponentLogo,
  } = useAxiosPost(baseUrl("matches/get_team_logo"));

  const { postData: postTeam } = useAxiosPost(baseUrl("matches/add_team_2"));

  useEffect(() => {
    setState({ clubTeams: clubTeamsData ?? [] });
  }, [clubTeamsData]);

  const getOpponentLogo = () => {
    const payload = {
      query: "logo " + removeAgeGroupFromTeamName(state.newTeamName),
    };

    const responseHandler = (res) => {
      setState({
        new_team_logos: res?.slice(0, 3),
      });
    };

    postOpponentLogo(payload, responseHandler);
  };

  const handleSearchTeamLogo = () => {
    if (_.size(state.newTeamName) >= 3) {
      getOpponentLogo();
      setState({ show_new_team_logos: true });
    }
  };

  const handleChangeSelectedLogo = (i) => {
    setState({ new_team_selected_logo: i });
  };

  const getImageFromFile = (files, file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setState({ new_team_uploaded_logo: reader.result });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleChangUploadedLogo = (e) => {
    if (_.size(e.target.files) > 0) {
      getImageFromFile(e.target.files, e.target.files[0]);
    } else {
      setState({ new_team_uploaded_logo: "" });
    }
  };

  const handleRemoveUploadedLogo = () => {
    setState({ new_team_uploaded_logo: "" });
  };

  const addTeam = () => {
    const payload = {
      id_competition: id_competition,
      team_name:
        state.teamOption === "new" ? state.newTeamName : state.pseudoTeamName,
      initial_points: state.initialPoints,
      own_team: state.teamOption === "new" ? "0" : "1",
      id_competition_group: state.group,
      id_team: state.teamOption === "new" ? "" : state.id_team,
      logo_type:
        state.show_new_team_logos &&
          !state.logos_checkbox &&
          state.new_team_uploaded_logo === ""
          ? "url"
          : state.new_team_uploaded_logo !== "" &&
            state.show_new_team_logos &&
            !state.logos_checkbox
            ? "base_64"
            : null,
      logo_url: state.show_new_team_logos
        ? state.logos_checkbox
          ? null
          : state.new_team_logos[state.new_team_selected_logo]?.thumbnail_url
        : null,
      logo_base_64: state.show_new_team_logos
        ? state.new_team_uploaded_logo !== "" && !state.logos_checkbox
          ? state.new_team_uploaded_logo
          : null
        : null,
    };

    const responseHandler = (res) => {
      if (Number(res.success) === 1) {
        closePopup();
        getTeams();
        updateCompetitionDetails();
        showNotification(
          "success",
          languagePack.competitions,
          languagePack.teams_notification_success
        );
      } else {
        showNotification(
          "danger",
          languagePack.competitions,
          languagePack.teams_notification_fail
        );
      }
    };

    postTeam(payload, responseHandler);
  };

  return (
    <div
      className="add-team-manual-container"
      style={{ paddingBottom: "12px" }}
    >
      <div className="add-team-manual-title">{languagePack["add_team"]}</div>
      <div className="add-team-manual-options">
        <div
          className="add-team-option"
          onClick={() => setState({ teamOption: "new" })}
          style={{
            backgroundColor: state.teamOption === "new" ? "#fafafa" : "",
          }}
        >
          <div>{languagePack["new_team"]}</div>
        </div>
        <div
          className="add-team-option"
          onClick={() =>
            setState({ teamOption: "club", show_new_team_logos: false })
          }
          style={{
            backgroundColor: state.teamOption === "club" ? "#fafafa" : "",
          }}
        >
          <div>{languagePack["clubs_team"]}</div>
        </div>
      </div>
      <div className="add-team-container-input">
        {state.teamOption === "new" ? (
          <div>
            <div className={"team-name-logo-div"}>
              <TFBInput
                fullWidth
                value={state.newTeamName}
                onChange={(e) => setState({ newTeamName: e.target.value })}
                placeholder={languagePack["team_name"]}
                label={languagePack.team_name}
              />
              <TFBButton
                size="sm"
                style={{ height: "54px", width: "200px" }}
                onClick={handleSearchTeamLogo}
                renderIcon={() => <TFBIcon name="search" />}
                color="green"
                disabled={_.size(state.newTeamName) < 3}
              >
                {languagePack.search_logo}
              </TFBButton>
            </div>
            <TFBInput
              value={state.initialPoints}
              style={{ width: "502px", marginBottom: "20px" }}
              onChange={(e) => setState({ initialPoints: e.target.value })}
              placeholder={languagePack.initial_points}
              label={languagePack.initial_points}
            />
          </div>
        ) : (
          <div className="club-team-dropdown">
            <select
              id="team-select"
              value={state.teamName}
              onChange={(e) => {
                setState({
                  id_team: e.target.selectedOptions[0].getAttribute("id-team"),
                  teamName: e.target.value,
                  pseudoTeamName: e.target.value,
                });
              }}
            >
              <option value="" disabled>
                {languagePack["choose_team"]}
              </option>
              {state.clubTeams?.map((item) => {
                return (
                  <option
                    value={item.team_name}
                    key={item.id_team}
                    id-team={item.id_team}
                  >
                    {item.team_name}
                  </option>
                );
              })}
            </select>

            <TFBInput
              label={languagePack.team_name}
              style={{ width: "502px", marginBottom: "20px" }}
              fullWidth
              placeholder={languagePack.team_name}
              value={state.pseudoTeamName}
              onChange={(e) => setState({ pseudoTeamName: e.target.value })}
            />

            <TFBInput
              value={state.initialPoints}
              style={{ width: "502px", marginBottom: "20px" }}
              onChange={(e) => setState({ initialPoints: e.target.value })}
              placeholder={"Punctaj initial"}
            />
          </div>
        )}

        {state.show_new_team_logos && (
          <>
            {!state.logos_checkbox && (
              <ExternTeamLogos
                uploadedLogo={state.new_team_uploaded_logo}
                onRemoveUploadedLogo={handleRemoveUploadedLogo}
                onChangeFile={handleChangUploadedLogo}
                loading={loadingLogos}
                setSelectedLogo={handleChangeSelectedLogo}
                selectedLogo={state.new_team_selected_logo}
                backgroundColor="#fafafa"
                thumbnails={_.map(
                  state.new_team_logos,
                  (logo) => logo.thumbnail_url
                )}
              />
            )}
            <div className="add-checkbox-input extern-team-checkbox">
              <TFBCheckbox
                label={languagePack.continue_without_external_team_logo}
                style={{ marginBottom: "20px" }}
                checked={state.logos_checkbox}
                onChange={(e) =>
                  setState({
                    logos_checkbox: e.target.checked,
                  })
                }
                mode="light"
              />
            </div>
          </>
        )}

        <div className="add-team-manual-buttons">
          <Button
            className="add-team-button cancel-button"
            onClick={() => closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="cancel" className="white-icon" />
          </Button>
          <Button
            className="add-team-button"
            disabled={
              state.teamOption === "new"
                ? state.newTeamName === ""
                : state.teamName === ""
            }
            onClick={addTeam}
          >
            {languagePack["admin_add"]}
            <Icon name="add" className="white-icon" />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddTeam);
