import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { connect } from "react-redux";

class AccountBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [],
      options: {
        chart: {
          type: "line",
          height: 330,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#666666"],
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        yaxis: {
          labels: {
            formatter: function (y) {
              return y.toLocaleString();
            },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            rotate: -90,
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.getChartData();
  };

  getChartData = () => {
    axios
      .get(
        `thinkout/monthly_balance_chart?id_club=${this.props.currentUser.id_club}&lang=${this.props.currentUser.lang}`
      )
      .then((res) => {
        this.setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.xaxis,
              categories: res.data.labels,
            },
          },
          series: res.data.amounts,
        }));
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;
    return (
      <div className="financial-chart-dashboard-container">
        <div className="financial-chart-title">
          {languagePack["end_of_month_sold"]}
        </div>
        <div id="chart">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type="line"
            height={330}
          />
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AccountBalance);
