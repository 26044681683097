import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import axios from "axios";
import { store } from "react-notifications-component";

class AddSponsor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      site_url: "",
      logo: "",
    };
  }

  onFileChange = (e) => {
    this.setState({
      logo: e.target.files[0],
    });
  };

  addSponsor = () => {
    const data = new FormData();
    data.append("sponsor_name", this.state.name);
    data.append("sponsor_website", this.state.site_url);
    data.append("id_club", this.props.id_club);
    data.append("logo", this.state.logo);
    axios
      .post("site/add_sponsor", data)
      .then((res) => {
        if (res.data.success == 1) {
          this.props.closePopup();
          this.props.refreshList();
          store.addNotification({
            title: this.props.languagePack["sponsor_site_notif_title"],
            message: this.props.languagePack["sponsor_site_notif_success"],
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        } else {
          store.addNotification({
            title: this.props.languagePack["sponsor_site_notif_title"],
            message: res.data.errors,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
            },
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { languagePack } = this.props;

    return (
      <div className="add-partner-site-form">
        <div className="add-partner-row">
          <input
            type="text"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
            placeholder={languagePack["sponsor_name_form"]}
          />
        </div>
        <div className="add-partner-row">
          <input
            type="text"
            value={this.state.site_url}
            onChange={(e) => this.setState({ site_url: e.target.value })}
            placeholder={languagePack["sponsor_site_form"]}
          />
        </div>
        <div className="add-partner-form-row">
          <input
            type="file"
            className="add-file-form-file-input"
            id="customFile"
            onChange={this.onFileChange}
          />
          <label className="add-file-form-file-label" htmlFor="customFile">
            {this.state.logo !== ""
              ? this.state.logo.name
              : languagePack["sponsor_logo_form"]}
          </label>
          <Icon
            name="arrow up"
            className="cusor-pointer grey-button-icon"
            size="small"
          />
        </div>
        <div className="add-partner-buttons-row">
          <Button
            className="add-partner-button"
            style={{ backgroundColor: "#666666" }}
            onClick={() => this.props.closePopup()}
          >
            {languagePack["admin_cancel"]}
            <Icon name="ban" size="small" className="add-partner-icon" />
          </Button>
          <Button
            className="add-partner-button"
            style={{ backgroundColor: "#00d948" }}
            onClick={this.addSponsor}
          >
            {languagePack["admin_save"]}
            <Icon name="check" size="small" className="add-partner-icon" />
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(({ layoutService, auth }) => ({
  languagePack: layoutService.languagePack,
  currentUser: auth.user,
}))(AddSponsor);
