import React from "react";
import MatchBasicDetails from "./MatchBasicDetails";
import MatchEvents from "./MatchEvents";
import MatchStatistics from "../../instat/match/MatchTeamStats";
import MatchLineup from "./MatchLineup";
import MatchLineups from "../../instat/match/MatchLineups";
import PolarSessionResults from "../../polar/PolarSessionResults";
import MatchPlayerStats from "../../instat/match/MatchPlayerStats";
import MatchEventsPitch from "../../instat/match/MatchEventsPitch";
import axios from "axios";

class MatchDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: [],
      team_home_name: "",
      team_away_name: "",
      is_loaded: false,
    };
  }

  componentDidMount = () => {
    this.getPolarMatchData();
  };

  getPolarMatchData = () => {
    axios
      .get(
        `matches/get_polar_match_data?id_match=${this.props.match.params.id}`
      )
      .then((res) =>
        this.setState({
          parameters: res.data.parameters,
          team_home_name: res.data.team_home ?? "Poli Iasi",
          team_away_name: res.data.team_away ?? "UTA Arad",
          is_loaded: true,
        })
      )
      .catch((err) => console.log(err));
  };

  render() {
    const id_match_football_api = this.props.match.params.id;
    return (
      <div className="football-api-match-page-container">
        {this.state.is_loaded && (
          <div className="football-api-match-title">
            {this.state.team_home_name} vs {this.state.team_away_name}
          </div>
        )}
        <div className="row">
          <div className="col-lg-6">
            <MatchBasicDetails id_match={id_match_football_api} />
            <MatchEvents id_match={id_match_football_api} />
          </div>
          <div className="col-lg-6">
            {/* <MatchStatistics id_match={id_match_football_api} /> */}

            <MatchLineup id_match={id_match_football_api} />
            {/* <MatchLineups id_match={id_match_football_api} /> */}
          </div>
        </div>
        {/* {
          <div className="row" style={{ marginTop: 20 }}>
            <div className="col-lg-12">
              <PolarSessionResults parameters={this.state.parameters} />
            </div>
          </div>
        }
        <div className="row" style={{ marginTop: 25 }}>
          <div className="col-lg-12">
            <MatchEventsPitch id_match={id_match_football_api} />
          </div>
        </div>
        <div className="row" style={{ marginTop: 25 }}>
          <div className="col-lg-12">
            <MatchPlayerStats id_match={id_match_football_api} />
          </div>
        </div> */}
      </div>
    );
  }
}

export default MatchDetails;
