import React from "react";

const CurrencyPicker = ({ name, checked, onClick }) => {

    const styles = {
        "currency-container": {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        },
        "checkbox-background": {
            backgroundColor: "#fafafa",
            width: 23,
            height: 23,
            borderRadius: "5px",
            marginRight: "5px",
        },
        "currency-checkbox": {
            backgroundColor: "#fafafa",
            width: 10,
            height: 10,
            borderRadius: "3px",
            marginRight: "15px",
        },
        "checked-center": {
            position: "relative",
            left: 6,
            top: 6,
        },
        "currency-checkbox-selected": {
            backgroundColor: "#666666",
        },
        "currency-name": {
            fontSize: 14,
            fontWeight: 700,
            color: "#b2b2b2",
        },
    }

    return (
        <div
            style={styles['currency-container']}
            onClick={onClick}
        >
            <div style={styles['checkbox-background']}>
                <div
                    className={`currency-checkbox checked-center ${checked && 'currency-checkbox-selected'}`}
                    style={{ ...styles['currency-checkbox'], ...styles['checked-center'], ...(checked ? styles['currency-checkbox-selected'] : {}) }}
                />
            </div>
            <div style={styles['currency-name']}>{name}</div>
        </div>
    )
}

export default CurrencyPicker;