import React from "react";
import "./Auth.scss";
import axios from "axios";
import Loader from "react-loader-spinner";
import ro from "../../app/utils/lang/ro";
import es from "../../app/utils/lang/es";
import en from "../../app/utils/lang/en";
import it from "../../app/utils/lang/it";

const CODE_LENGTH = new Array(6).fill(0);

class SmsAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      focused: false,
      error: "",
      isWaiting: false,
    };
  }

  input = React.createRef();

  componentDidMount = () => {
    this.input.current.focus();
  };

  handleClick = () => {
    this.input.current.focus();
  };
  handleFocus = () => {
    this.setState({ focused: true });
  };
  handleBlur = () => {
    this.setState({
      focused: false,
    });
  };

  handleChange = (e) => {
    const value = e.target.value;

    this.setState((state) => {
      if (state.value.length >= CODE_LENGTH.length) return null;
      return {
        value: (state.value + value).slice(0, CODE_LENGTH.length),
      };
    }, this.checkCode);
  };

  handleKeyUp = (e) => {
    if (e.key === "Backspace") {
      this.setState((state) => {
        return {
          value: state.value.slice(0, state.value.length - 1),
        };
      });
    }
  };

  getTranslation = (key) => {
    switch (this.props.lang) {
      case "ro":
        return ro[key];
      case "en":
        return en[key];
      case "es":
        return es[key];
      case "it":
          return it[key];  
      default:
        return en[key];
    }
  };

  checkCode = () => {
    const payload = {
      email: this.props.email,
      input: this.state.value,
    };

    if (this.state.value.length === 6) {
      axios.post("user/check_sms_code", payload).then((res) => {
        if (res.data.success == 1) {
          this.setState({ isWaiting: true });
          this.props.onSubmit();
        } else {
          this.setState({ error: res.data.error, value: "" });
        }
      });
    }
  };

  render() {
    const { value, focused } = this.state;
    const values = value.split("");
    const selectedIndex =
      values.length < CODE_LENGTH.length
        ? values.length
        : CODE_LENGTH.length - 1;

    const hideInput = !(values.length < CODE_LENGTH.length);
    return (
      <div className="sms-popup-container">
        <div className="sms-popup-header">
          <div className="sms-popup-title">
            {this.getTranslation("verification_code")}
          </div>
        </div>
        <div className="sms-popup-text">
          {this.getTranslation("insert_verification_code")}
        </div>
        <div className="wrap" onClick={this.handleClick}>
          {CODE_LENGTH.map((v, index) => {
            const selected = values.length === index;
            const filled =
              values.length === CODE_LENGTH.length &&
              index === CODE_LENGTH.length - 1;
            return (
              <div className="display" key={index}>
                {values[index]}{" "}
                {(selected || filled) && focused && <div className="shadows" />}
              </div>
            );
          })}
          <input
            value=""
            ref={this.input}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyUp={this.handleKeyUp}
            className="input"
            onChange={this.handleChange}
            style={{
              width: "32px",
              top: "0px",
              bottom: "0px",
              left: `${selectedIndex * 40}px`,
              opacity: hideInput ? 0 : 1,
            }}
          />
        </div>
        {this.state.error.length !== "" && (
          <div className="sms-popup-error-row">
            {this.getTranslation(this.state.error)}
          </div>
        )}
        {this.state.isWaiting && (
          <div className="loader-container">
            <Loader type="TailSpin" color={window.clubCustomization.primary_color} height={30} width={30} />
          </div>
        )}
        {/* <div className="sms-popup-footer">
          <div className="footer-row">
            {this.getTranslation("verification_code_no_receive_message")}
            <div className="resend-text">
              {this.getTranslation("verification_code_send_again")}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

export default SmsAuth;
